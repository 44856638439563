import { Box, Divider, Typography } from '@material-ui/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useThemeQueries } from 'views/theme';
import { ResultadoRevendaModel } from 'model/api/gestao/relatorios/resultado-revenda-model';
import { useStyles } from '../saldo/saldo-styles';
import { RelatorioChartModel, RelatorioUnitarioChartModel, saldoRevendaName } from 'model/api/gestao/relatorios/relatorio-model';
import { competenciaMesAnterior, subtrairCompetencia } from 'utils/converter-competencia';
import ChartContratos from '../../../accordion-relatorio-saurus/components/charts/chart-contratos';

interface DashboardSaldoProps {
    resultadoRevenda: ResultadoRevendaModel[];
    meses: number;
}

export default function SaldoRevenda({ resultadoRevenda, meses }: DashboardSaldoProps) {
    const { theme } = useThemeQueries()

    const chartContainerRef = useRef<HTMLDivElement>(null)

    const classes = useStyles({ showOverflow: meses >= 24, chartContainerRef });

    const [dadosContratos, setDadosContratos] = useState<RelatorioChartModel[]>([])

    const tratarDados = useCallback(() => {
        try {
            let arr: RelatorioChartModel[] = [
                new RelatorioChartModel('contratosSaldo', []),
                new RelatorioChartModel('cnpJsSaldo', []),
                new RelatorioChartModel('terminaisSaldo', [])
            ]
            const competenciaAtual = competenciaMesAnterior();

            for (let i = 0; i <= meses; i++) {
                const competenciaSubtraida = subtrairCompetencia(competenciaAtual, i)
                const foundItem = resultadoRevenda.find(element => element.anoMes === competenciaSubtraida)
                if (foundItem) {
                    arr = arr.map(element => {
                        const property = element.name as saldoRevendaName

                        element.data.push(new RelatorioUnitarioChartModel(competenciaSubtraida, foundItem[property] < 0 ? 0 : foundItem[property],))
                        return element
                    })
                }
            }

            setDadosContratos(arr.map(item => {
                item.data = item.data.sort((a, b) => a.x - b.x)
                switch (item.name) {
                    case 'cnpJsSaldo':
                        item.name = 'Novos CNPJs'
                        break;
                    case 'contratosSaldo':
                        item.name = 'Novos Contratos'
                        break;
                    case 'terminaisSaldo':
                        item.name = 'Novos Terminais'
                        break;
                }
                return item
            }))
        } catch (e) {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultadoRevenda])

    useEffect(() => {
        tratarDados()
    }, [tratarDados])


    return (
        <>
            <Box display='flex' flexDirection='column' width='100%' gridGap={theme.spacing(1)}>
                <Box display='flex' justifyContent='space-between' alignItems='center' flexWrap='wrap' gridGap={5}>
                    <Typography color='primary' variant='h6'>Saldo</Typography>
                </Box>
                <Divider />
                <div className={classes.chartContainer} ref={chartContainerRef}>
                    <ChartContratos list={dadosContratos} width={
                        meses >= 24 ? meses * 100 : undefined
                    } />
                </div>
            </Box>
        </>
    );
}