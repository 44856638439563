import { Tooltip, Typography } from '@material-ui/core';
import { CardSistemasProps } from './card-sistemas-props';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { CaracteresString } from 'utils/caracteres-string';
import { SituacaoSistemaMock } from 'data/mocks/situacao-sistema-mock';
import { useMemo } from 'react';
import { EnumSituacaoSistema } from 'model/enums/enum-situacao-sistema';
import { useThemeQueries } from 'views/theme';
import {useStyles} from './card-sistemas-style'

export const CardSistemas = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardSistemasProps) => {
  const classes = useDefaultCardStyles();
  const sitemasClasses = useStyles();
  const { theme } = useThemeQueries()

  const img = (imagemUrl: string) => {
    try {
      if (imagemUrl.length > 0) {
        const url = atob(imagemUrl);
        return <img src={url} className={sitemasClasses.cardImg} alt={model.nome} />;
      }

      return (
        <img src={SemImagem} className={sitemasClasses.cardImg} alt={model.nome} />
      );
    } catch (err) {
      return (
        <img src={SemImagem} className={sitemasClasses.cardImg} alt={model.nome} />
      );
    }
  };

  const retornarCorStatus = useMemo((): string => {
    switch (model.situacao) {
      case EnumSituacaoSistema.Ativo:
        return theme.palette.success.main
      case EnumSituacaoSistema.Bloqueado:
        return theme.palette.warning.main
      case EnumSituacaoSistema.Desativado:
        return theme.palette.error.main
      default:
        return theme.palette.primary.main
    }
  }, [model.situacao, theme.palette.error.main, theme.palette.primary.main, theme.palette.success.main, theme.palette.warning.main]);

  const descricao = SituacaoSistemaMock.filter(
    (x) => x.Key === model.situacao,
  )[0].Value;

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model.id);
        }}
      >
        <Tooltip arrow title={descricao} placement="right">
          <div
            className={classes.tagStatus}
            style={{
              background: retornarCorStatus,
            }}
          ></div>
        </Tooltip>
        <div style={{
          padding: 8,
          maxHeight: 135
        }}>
          <div style={{
            display: 'flex',
            marginBottom: 8
          }}>

            <div style={{
              paddingRight: 8
            }}
            >
              {img(model.urlImagem)}
            </div>
            <div>
              <Typography
                color="textSecondary"
                variant="body1"
                style={{
                  fontWeight: 600
                }}
              >
                {model.nome}
              </Typography>
            </div>
          </div>
          <div>
            <Typography
              style={{
                fontWeight: 600,
                fontSize: 12
              }}
              color="textSecondary"
              variant="body2"
            >
              {CaracteresString(model.descricao, 50)}
            </Typography>
            <Typography
              style={{
                fontWeight: 600,
                fontSize: 12
              }}
              color="textSecondary"
              variant="body2"
            >
              Módulos: <span style={{
                fontSize: 18
              }}>{model.qtdModulos}</span>
            </Typography>
          </div>
        </div>
      </DefaultCard>
    </>
  );
};
