
import { DefaultModal } from 'views/components/modals/components/default-modal';
import { PrecificacaoCadastro } from "./components/precificacao-cadastro/precificacao-cadastro";

type Props = {
  openned: boolean;
  id: string;
}

export const PrecificacaoModal = ({ openned }: Props) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={openned || false}
      variant={"temporary"}
      anchor="right"
    >
      <PrecificacaoCadastro />
    </DefaultModal>
  );
};
