import React, { useCallback, FocusEvent, useState, useEffect, useRef } from "react";
import {
  retornoAutoComplete,
  AutocompleteSaurus,
} from "../autocomplete-saurus/autocomplete-saurus";
import { useGetPessoa, useGetPessoaById } from "data/api/gestao/pessoa";
import { PessoasModel } from "model/api/gestao/pessoa/pessoas-model";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { isEmpty } from "lodash";

export interface AutocompleteIndicadorProps {
  label?: string;
  loadingExterno?: boolean;
  name?: string;
  value?: string;
  error?: boolean;
  helperText?: string | undefined;
  placeholder?: string;
  allowSubmit?: boolean;
  disabled?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onChange?: (value: retornoAutoComplete) => any;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
  fetchOnLoad?: boolean;
  query?: string;
  focus?: boolean;
  onClick?: (e?: any) => any;
}

export const AutocompleteIndicador = ({
  onChange,
  loadingExterno,
  fullWidth,
  ...props
}: AutocompleteIndicadorProps) => {
  const [indicadorState, setIndicadorState] = useState<Array<PessoasModel>>(
    new Array<PessoasModel>()
  );
  const { getPessoa, carregando: carregandoPessoas } = useGetPessoa()
  const {getPessoaById, carregando: carregandoPessoa} = useGetPessoaById()


  const [nomeValor, setNomeValor] = useState<string>('')

  const allowFetch = useRef<boolean>(true)

  const getIndicadorById = useCallback(async (id: string) => {
    try{
      const res = await getPessoaById(id)

      if (res.erro) throw res.erro

      if(!res.resultado) return
      allowFetch.current = false
      setIndicadorState([res.resultado.data])
    }catch(e: any){

    }
  }, [getPessoaById])


  const getRevendasWrapper = useCallback(async (termo: string) => {
    try {

      const ret = await getPessoa(1, `pageSize=100&tipoPessoa=${EnumTipoPessoas.AgenteExterno}&${termo.length > 0 ? `query=${termo}` : ''}`);
      if (ret.erro) throw ret.erro;
      let indicador: Array<PessoasModel> = new Array<PessoasModel>();
      if (!ret.resultado) return
      if (ret.resultado?.data?.list?.length > 0) {
        indicador = ret.resultado?.data?.list as Array<PessoasModel>;
      }
      setIndicadorState(indicador)
    } catch (e: any) { }
  }, [getPessoa]);

  const onChangeWrapper = useCallback(
    (value: retornoAutoComplete) => {
      if (onChange) onChange(value);
    },
    [onChange]
  );

  const carregando = carregandoPessoas || carregandoPessoa || loadingExterno

  const onPesquisa = useCallback(
    async (termo: string) => {
      await getRevendasWrapper(termo);
    },
    [getRevendasWrapper]
  );

  useEffect(() => {
    if (!isEmpty(props.value) && props.fetchOnLoad && allowFetch.current) {
      getIndicadorById(props.value!)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value])


  useEffect(() => {
    if (props.value) {
      const valor = indicadorState.find(item =>
        item.id === props.value
      )
      setNomeValor(valor?.nomeComercial ?? '')
      return
    }
    setNomeValor('')
  }, [indicadorState, props, props.fetchOnLoad, props.value])


  return (
    <AutocompleteSaurus
      disabled={props.disabled}
      name={props.name}
      loading={carregando}
      onChange={onChangeWrapper}
      onPesquisa={onPesquisa}
      onClick={props.onClick}
      opcoes={indicadorState}
      optionLabelKey="nomeComercial"
      optionValueKey="nomeComercial"
      onBlur={props.onBlur}
      noOptionsText="Indicador não localizado."
      value={nomeValor}
      allowSubmit={props.allowSubmit}
      label={props.label}
      helperText={props.helperText}
      error={props.error}
      placeholder={props.placeholder}
      fullWidth={fullWidth}
      size={props.size}
      focus={props.focus}
    />
  );
};