import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
    TextFieldSaurus,

} from 'views/components/controles/inputs';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { picker } from 'utils/picker';
import { KeyValueModel } from 'model';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { toDateStringApi } from 'utils/to-date';
import { ComissaoPutModel } from 'model/api/gestao/comissao/comissao-put-model';
import { useFormComissaoEditarValidation } from './form-comissao-editar-validation';

interface FormComissaoEditarProps extends DefaultFormProps<ComissaoPutModel> {
    aberto: boolean;
    block?: boolean;
}


export const FormComissaoEditar = forwardRef<
    DefaultFormRefs<ComissaoPutModel>,
    FormComissaoEditarProps
>(
    (
        { loading, onSubmit, aberto, block, ...props }: FormComissaoEditarProps,
        ref,
    ) => {
        const { isMobile } = useThemeQueries();
        const utilClasses = makeUtilClasses();
        const refInputNome = useRef<HTMLInputElement>(null);
        const {FormComissaoEditarValidationYup} = useFormComissaoEditarValidation();
        const [modelForm, setModelForm] = useState<ComissaoPutModel>(new ComissaoPutModel())


        const {
            handleSubmit,
            control,
            formState: { errors },
            reset,
        } = useForm<ComissaoPutModel>({
            defaultValues: { ...modelForm },
            resolver: yupResolver(FormComissaoEditarValidationYup),
            criteriaMode: 'all',
            mode: 'onBlur' && 'onChange',
        });

        useImperativeHandle(ref, () => ({
            submitForm: () => {
                handleSubmit(submitEnviar)();
            },
            resetForm: () => {
                reset();
                if (!isMobile) refInputNome.current?.focus();
            },
            fillForm: (model: ComissaoPutModel) => {
                setModelForm({ ...model })
                reset({ ...model });
                if (!isMobile) refInputNome.current?.focus();
            },
        }));

        const carregando = loading

        const submitEnviar = (values: ComissaoPutModel) => {
            const ComissaoPutModel = picker<ComissaoPutModel>(values, modelForm)

            const date = new Date(ComissaoPutModel.dataPagamento)
            ComissaoPutModel.dataPagamento = toDateStringApi(date).split('T')[0]

            onSubmit(ComissaoPutModel, modelForm);
        };

        return (
            <>
                <Box my={2}>
                    <div className={utilClasses.formContainer}>
                        {carregando && props.showLoading && (
                            <CircularLoading tipo="FULLSIZED" />
                        )}

                        <form
                            onSubmit={handleSubmit(submitEnviar)}
                            className={loading ? utilClasses.controlLoading : ''}
                        >
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12}>
                                    <Controller
                                        name="observacao"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                disabled={loading}
                                                readOnly={block}
                                                allowSubmit={false}
                                                id="observacao"
                                                label="Observações"
                                                fullWidth
                                                autoComplete={'off'}
                                                helperText={
                                                    errors.observacao
                                                        ? errors.observacao.message
                                                        : undefined
                                                }
                                                error={Boolean(errors.observacao && errors.observacao.message)}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Controller
                                        name="contabilizaRelatorios"
                                        control={control}
                                        render={({ field }) => (
                                            <SelectSaurus
                                                disabled={loading || block}
                                                conteudo={[
                                                    new KeyValueModel(true, 'Sim'),
                                                    new KeyValueModel(false, 'Não')
                                                ]}
                                                allowSubmit={false}
                                                label="Contabiliza Relatórios"
                                                fullWidth
                                                autoComplete={'off'}
                                                helperText={
                                                    errors.contabilizaRelatorios
                                                        ? errors.contabilizaRelatorios.message
                                                        : undefined
                                                }
                                                error={Boolean(errors.contabilizaRelatorios && errors.contabilizaRelatorios.message)}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Controller
                                        name="valorTotal"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                disabled={loading}
                                                readOnly={block}
                                                allowSubmit={false}
                                                id="valorTotal"
                                                label="Valor Total"
                                                tipo='DECIMAL'
                                                showStartAdornment
                                                manterMascara
                                                positivo
                                                fullWidth
                                                autoComplete={'off'}
                                                helperText={
                                                    errors.valorTotal
                                                        ? errors.valorTotal.message
                                                        : undefined
                                                }
                                                error={Boolean(errors.valorTotal && errors.valorTotal.message)}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Button style={{ display: 'none' }} type="submit"></Button>
                        </form>
                    </div>
                </Box>
            </>
        );
    },
);