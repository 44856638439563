import { useCallback, useMemo, useRef, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useThemeQueries } from 'views';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { PessoasModel } from 'model/api/gestao/pessoa/pessoas-model';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { useHistory } from 'react-router-dom';
import { EditIndicacaoIcon } from 'views/components/icons/edit-indicacao-icon';
import { isEmpty } from 'lodash';
import { formatarCEP } from 'utils/formatar-cep';
import { useStyles } from './accordion-cliente-list-styles';

interface AccordionIndicacaoClienteProps {
    pessoa: PessoasModel;
}

export const AccordionIndicacaoCliente = ({
    pessoa,
}: AccordionIndicacaoClienteProps) => {
    const refInputNome = useRef<HTMLInputElement>(null);
    const { theme } = useThemeQueries()
    const classes = useStyles();
    const history = useHistory()
    const [openAccordion, setOpenAccordion] = useState<boolean>(true)

    const redirectEditCliente = useCallback(() => {
        history.push({
            pathname: ('/clientes/') + pessoa.id,
            state: {
                from: {
                    pathname: history.location.pathname
                }
            }
        })
    }, [history, pessoa.id])

    const retornaEndereco = useMemo(() => {
        const { bairro, cep, municipio, numero, complemento, logradouro, uf } = pessoa?.informacoes ?? new PessoasModel()

        if (isEmpty(pessoa?.informacoes)) {
            return ''
        }

        return `${logradouro ?? ''}, ${numero ?? 0} - ${bairro ?? ''}${municipio ? `, ${municipio}` : ''}${uf ? ` - ${uf}` : ''}${cep ? `, ${formatarCEP(cep ?? '')}` : ''}${complemento ? `, ${complemento}` : ''}`
    }, [pessoa?.informacoes])

    return (
        <>
            <div>
                <AccordionSaurus
                    labelPrimary="Dados do Cliente"
                    tipoExpand="bold"
                    noPaperRoot={false}
                    heightDivider={2}
                    showDivider={openAccordion}
                    colorDivider={theme.palette.primary.main}
                    colorExpand={theme.palette.primary.main}
                    expanded={openAccordion}
                    onChange={() => setOpenAccordion(prev => !prev)}
                >
                    <div className={classes.content}>
                        <div>
                            <Grid container spacing={2} style={{
                                position: 'relative'
                            }}>

                                <Grid item xs={12} md={4}>
                                    <TextFieldSaurus
                                        readOnly
                                        allowSubmit={false}
                                        inputRef={refInputNome}
                                        label="CPF/CNPJ"
                                        value={formatarCPFCNPJ(pessoa.cpfCnpj)}
                                        fullWidth
                                        autoComplete={'off'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <TextFieldSaurus
                                        readOnly
                                        allowSubmit={false}
                                        value={pessoa.nomeComercial}
                                        inputRef={refInputNome}
                                        label="Razão Social"
                                        fullWidth
                                        autoComplete={'off'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <TextFieldSaurus
                                        readOnly
                                        allowSubmit={false}
                                        inputRef={refInputNome}
                                        label="E-mail"
                                        value={pessoa.informacoes ? pessoa.informacoes.email : 'Não informado'}
                                        fullWidth
                                        autoComplete={'off'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextFieldSaurus
                                        readOnly
                                        allowSubmit={false}
                                        inputRef={refInputNome}
                                        label="Telefone"
                                        value={pessoa.informacoes ? pessoa.informacoes.telefone : 'Não informado'}
                                        fullWidth
                                        autoComplete={'off'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldSaurus
                                        readOnly
                                        allowSubmit={false}
                                        inputRef={refInputNome}
                                        label="Endereço"
                                        value={retornaEndereco}
                                        multiline
                                        minRows={1}
                                        fullWidth
                                        size='medium'
                                        autoComplete={'off'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="flex-end">
                                        <Grid item xs={12} md={5} lg={4}>
                                            <Button variant="contained" color='primary' size='large' fullWidth style={{  }}
                                                onClick={redirectEditCliente}
                                            >
                                                <EditIndicacaoIcon tipo='BUTTON_PRIMARY' />
                                                Editar Cliente
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </AccordionSaurus>
            </div>
        </>
    );
}