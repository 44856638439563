import { Button, Grid } from '@material-ui/core';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import {
  SalvarIcon,
  SalvarNovoIcon,
  VoltarIcon,
} from 'views/components/icons';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useCallback, useRef } from 'react';
import { picker } from 'utils/picker';
import classNames from 'classnames';
import { PlanoModel } from 'model/api/gestao/plano/plano-model';
import {
  useGetPlanoById,
  usePostAdicionarPlano,
} from 'data/api/gestao/planos';
import { PlanoFormModel } from 'model/app/forms/plano/plano-form-model';
import { FormPlanoCadastro } from 'views/components/form/master/plano/plano-cadastro/form-plano-cadastro';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { useCadastros } from 'services/app';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { guidEmpty } from 'utils/guid-empty';

export const IndicacaoCadastro = () => {
  const classes = useModalStyles();

  const { showToast } = useToastSaurus();
  const {usuario, tipoUsuario} = useSessaoAtual()

  const { carregando: carregandoGet } = useGetPlanoById();
  const { postAdicionarPlano, carregando: carregandoPost } =
    usePostAdicionarPlano();

  const { fecharEditarIndicacao } = useCadastros();

  const redirect = useRef<boolean>(false);

  const carregando = carregandoPost || carregandoGet;

  const cadNovoPlanoFormRef = useRef<DefaultFormRefs<PlanoFormModel>>(null);

  const saveNewPlano = useCallback(
    async (model: PlanoModel) => {
      const ret = await postAdicionarPlano(model);

      if (ret.erro) {
        throw ret.erro;
      }

      return ret.resultado?.data;
    },
    [postAdicionarPlano],
  );

  const handleSubmit = useCallback(
    async (model: PlanoFormModel) => {
      try {
        const NovoPlanoToCreate = picker<PlanoModel>(model, new PlanoModel());

        NovoPlanoToCreate.modulos = model.modulos;

        const moduloIncorreto = NovoPlanoToCreate.modulos.filter(
          (item) =>
            item.quantidadeContratada < item.quantidadeMinima ||
            item.quantidadeContratada > item.quantidadeMaxima,
        );

        if (moduloIncorreto.length > 0) {
          throw new Error(
            'Um dos módulos está com a quantidade incorreta, arrume antes de enviar.',
          );
        }

        NovoPlanoToCreate.revendaId = tipoUsuario() === EnumTipoPessoas.Revendedor ?
                                                        usuario!.Id : guidEmpty()

        await saveNewPlano(NovoPlanoToCreate);

        showToast('success', 'Novo Plano Adicionado!');

        if (redirect.current) {
          fecharEditarIndicacao()
          return
        }

        cadNovoPlanoFormRef.current?.resetForm()
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [fecharEditarIndicacao, saveNewPlano, showToast, tipoUsuario, usuario],
  );

  const onCloseClick = useCallback(() => {
    fecharEditarIndicacao();
  }, [fecharEditarIndicacao]);

  return (
    <div className={classes.root}>
      <ModalHeader
        title={'Nova Indicação'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <Grid className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}
        >
          <FormPlanoCadastro
            ref={cadNovoPlanoFormRef}
            showLoading={true}
            loading={carregando}
            onSubmit={handleSubmit}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                disabled={carregando}
                onClick={() => {
                  cadNovoPlanoFormRef.current!.submitForm();
                }}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarNovoIcon tipo="BUTTON" />
                Salvar e Adicionar Novo
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={carregando}
                onClick={() => {
                  redirect.current = true
                  cadNovoPlanoFormRef.current!.submitForm();
                }}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarIcon tipo="BUTTON_PRIMARY" />
                Salvar
              </Button>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};
