import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    fieldContainer: {
        padding: '1rem',
        borderRadius: theme.shape.borderRadius,
        marginBottom: 10,
        '& .MuiFormControl-root': {
            background: '#fff'
        },
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 8
    },
    endButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 8
    },
    buttonRemover: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
        '& svg': {
            fill: theme.palette.error.main
        }
    },
}));
