import { Grid, makeStyles, Tooltip, Typography, useTheme } from '@material-ui/core';
import { CardClientesProps } from './card-funcionarios-props';
import { DefaultCard } from '../components/default-card';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { useCallback } from 'react';
import { EnumSituacaoPessoa } from 'model/enums/enum-situacao-pessoa';
import { useThemeQueries } from 'views/theme';
import { EditarIcon } from 'views/components/icons';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => (
  {
    cardRightButton: {
      width: "50px",
      borderLeftWidth: 1,
      borderLeftStyle: "solid",
      borderLeftColor: theme.palette.divider,
      margin: '0px 0px 0px 10px',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      "& svg": {
        margin: 0,
        width: "22px",
        height: "22px",
        fill: theme.palette.primary.main,
      },
    },
    container: {
      padding: '8px',
      "& .MuiTypography-body1": {
        fontSize: 12
      }
    }
  }
))

export const CardFuncionarios = ({
  model,
  onClick,
  onCheck,
  selected,
  tooltipMessage
}: CardClientesProps) => {
  const { palette } = useTheme()
  const { isMobile } = useThemeQueries()

  const classes = useStyles()

  const statusColor = useCallback(() => {
    switch (model.status) {
      case 0:
        return palette.success.main
      case 1:
        return palette.warning.main
      case 2:
        return palette.error.main
    }
  }, [model.status, palette.error.main, palette.success.main, palette.warning.main])

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model.id);
        }}
      >
        <Tooltip title={EnumSituacaoPessoa[model.status]} placement='right'>
          <div style={{
            backgroundColor: statusColor(),
            width: '5px',
            borderRadius: '5px',
            alignSelf: 'stretch',
            margin: '7px'
          }} />
        </Tooltip>
        <Grid container spacing={1} className={classes.container} alignItems='center'>
          <Grid item xs={12} md={6} lg={isEmpty(model.revendaId) ? 5 : 3}>
            <Typography color="textPrimary" variant="caption">
              CPF/CNPJ: {formatarCPFCNPJ(model.cpfCnpj)}
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              <b>{model.nomeComercial}</b>
            </Typography>
          </Grid>
          {model.revendaId && <Grid item xs={6} md={6} lg={2}>
            <Typography color="textPrimary" variant="caption">
              Representante
            </Typography>
            <Typography color="textPrimary" variant="body1"
              style={{ fontWeight: 500 }}
            >
              {model.revendaNome}
            </Typography>
          </Grid>}
          <Grid item lg={3} md={6} xs={6}>
            <Typography color="textPrimary" variant="caption">
              Endereço:
            </Typography>
            <Typography color="textPrimary" variant="body1"
            >
              {(!model.informacoes || !model.informacoes.logradouro || !model.informacoes.numero) ? 'Não informado' :
                <>
                  {model.informacoes.logradouro}, {model.informacoes.numero}{' '}
                  {model.informacoes.bairro || ''} - {model.informacoes.uf || ''}
                </>}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={3}>
            <Typography color='textPrimary' variant='caption' style={{fontSize: 11}}>
              Email
            </Typography>
            <Typography color='textPrimary' variant='body1' style={{
              wordBreak: 'break-word',
              paddingRight: '1rem'
            }}>
              {!model.informacoes || !model.informacoes.email ? 'Não informado' : model.informacoes.email}
            </Typography>
          </Grid>
          {!isMobile && <Grid md={6} xs={2} lg={1}>
            <Typography color='textPrimary' variant='caption'>
              Telefone
            </Typography>
            <Typography color='textPrimary' variant='body1'>
              {!model.informacoes || !model.informacoes.telefone ? '-' : model.informacoes.telefone}
            </Typography>
          </Grid>}
        </Grid>
        {!isMobile &&
          <Tooltip arrow title={tooltipMessage || 'Editar Funcionário'}>
            <div className={classes.cardRightButton}>
              <EditarIcon tipo="BUTTON" />
            </div>
          </Tooltip>
        }
      </DefaultCard>
    </>
  );
};