import { Box, Button, Grid } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { TerminalModel } from 'model/api/gestao/terminal/terminal-model';
import { AccordionTerminalForm } from './components/accordion-terminal-form/accordion-terminal-form';
import AccordionTerminalHistorico from './components/accordion-terminal-historico/accordion-terminal-historico';
import { useThemeQueries } from 'views/theme';
import { CadeadoIcon, OkIcon, SalvarEditarIcon, VoltarIcon } from 'views/components/icons';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { RetornarIcon } from 'views/components/icons/retornar-icon';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { useGetTerminalById } from 'data/api/gestao/terminal/get-terminal-by-id';
import { usePutReutilizarTerminal } from 'data/api/gestao/terminal/put-reutilizar-terminal';
import { useStyles } from './dialog-terminal-styles'
import { useConfirm } from 'material-ui-confirm';
import { EnumTerminalStatus } from 'model/enums/enum-status-terminal';
import { usePutBloquearTerminal } from 'data/api/gestao/terminal/put-bloquear-terminal';
import { usePutAtivarTerminal } from 'data/api/gestao/terminal/put-ativar-terminal';
import { usePutDesativarTerminal } from 'data/api/gestao/terminal/put-desativar-terminal';
import { SituacaoTerminalMock } from 'data/mocks/situacao-terminal-mock';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';

interface DialogTerminalProps {
  openned: boolean;
  closeDialog: () => void;
  setRefresh?: React.Dispatch<React.SetStateAction<boolean>>;
  model: TerminalModel
  carregando: boolean
}

export const DialogTerminal = ({
  openned,
  closeDialog,
  model,
  setRefresh,
  carregando: carregandoExterno,
}: DialogTerminalProps) => {
  const { isMobile, theme, xs } = useThemeQueries();
  const classes = useModalStyles();
  const terminalClasses = useStyles();

  const { showToast } = useToastSaurus()
  const { tipoUsuario } = useSessaoAtual()

  const confirm = useConfirm()

  const formRef = useRef<DefaultFormRefs<TerminalModel>>(null)

  const { getTerminalById, carregando: carregandoGet } = useGetTerminalById();
  const { putReutilizarTerminal, carregando: carregandoPut } = usePutReutilizarTerminal()
  const { putBloquearTerminal, carregando: carregandoPutBloquear } = usePutBloquearTerminal()
  const { putAtivarTerminal, carregando: carregandoPutAtivar } = usePutAtivarTerminal()
  const {
    // putDesativarTerminal,
    carregando: carregandoPutDesativar
  } = usePutDesativarTerminal()

  const carregando =
    [
      carregandoExterno,
      carregandoPut,
      carregandoGet,
      carregandoPutAtivar,
      carregandoPutBloquear,
      carregandoPutDesativar
    ].includes(true)

  const [modelForm, setModelForm] = useState<TerminalModel>(new TerminalModel())

  const terminalWrapper = useCallback(async () => {
    try {
      const res = await getTerminalById(model.id)

      if (res.erro) throw res.erro
      if (!res.resultado?.data) throw new Error('Erro ao requisitar dados do terminal.')

      setModelForm({
        ...res.resultado?.data,
      })
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [getTerminalById, model.id, showToast])

  const handleReutilizar = useCallback(async () => {
    try {
      const res = await putReutilizarTerminal(modelForm.id)

      if ((res).erro) throw res.erro

      showToast('success', 'Terminal Reutilizado com sucesso')

      terminalWrapper();
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [modelForm, putReutilizarTerminal, showToast, terminalWrapper])

  const handleAtivar = useCallback(async () => {
    try {
      const res = await putAtivarTerminal(modelForm)

      if ((res).erro) throw res.erro

      showToast('success', 'Terminal Reutilizado com sucesso')

      terminalWrapper();
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [modelForm, putAtivarTerminal, showToast, terminalWrapper])

  const handleBloquear = useCallback(async () => {
    try {
      const res = await putBloquearTerminal(modelForm.id)

      if ((res).erro) throw res.erro

      showToast('success', 'Terminal Reutilizado com sucesso')

      terminalWrapper();
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [modelForm.id, putBloquearTerminal, showToast, terminalWrapper])

  // const handleDesativar = useCallback(async () => {
  //   try {
  //     const res = await putDesativarTerminal(modelForm.id)

  //     if ((res).erro) throw res.erro

  //     showToast('success', 'Terminal Reutilizado com sucesso')

  //     terminalWrapper();
  //   } catch (e: any) {
  //     showToast('error', e.message)
  //   }
  // }, [modelForm.id, putDesativarTerminal, showToast, terminalWrapper])

  const confirmarReutilizar = useCallback(() => {
    confirm({
      confirmationText: 'Confirmar',
      cancellationText: 'Cancelar',
      title: 'Confirmação',
      description: 'Tem certeza que deseja reutilizar esse terminal?'
    }).then(() => handleReutilizar())
  }, [confirm, handleReutilizar])

  const confirmarAtivar = useCallback(() => {
    confirm({
      confirmationText: 'Confirmar',
      cancellationText: 'Cancelar',
      title: 'Confirmação',
      description: 'Tem certeza que deseja reativar esse terminal?'
    }).then(() => handleAtivar())
  }, [confirm, handleAtivar])

  // const confirmarDesativar = useCallback(() => {
  //   confirm({
  //     confirmationText: 'Confirmar',
  //     cancellationText: 'Cancelar',
  //     title: 'Confirmação',
  //     description: 'Tem certeza que deseja desativar esse terminal?'
  //   }).then(() => handleDesativar())
  // }, [confirm, handleDesativar])

  const confirmarBloquear = useCallback(() => {
    confirm({
      confirmationText: 'Confirmar',
      cancellationText: 'Cancelar',
      title: 'Confirmação',
      description: 'Tem certeza que deseja bloquear esse terminal?'
    }).then(() => handleBloquear())
  }, [confirm, handleBloquear])

  const statusColor = useMemo(() => {
    switch (modelForm.status) {
      case EnumTerminalStatus.Disponivel:
        return '#1F9CE4'
      case EnumTerminalStatus.Ativo:
        return '#10C822'
      case EnumTerminalStatus.Bloqueado:
        return '#FFBC00'
      case EnumTerminalStatus.Desativado:
        return '#F44336'
    }
  }, [modelForm])

  useEffect(() => {
    if (openned)
      terminalWrapper();
  }, [openned, terminalWrapper])

  return (
    <DialogSaurus
      aberto={openned}
      titulo="Terminal"
      tamanho='md'
      fullScreen={isMobile}
      endAdornment={
        <Box
          width={"100px"}
          display={"flex"}
          justifyContent={"center"}
          color={"#FFF"}
          fontWeight={600}
          borderRadius={theme.shape.borderRadius}
          padding={"4px 8px"}
          style={{
            backgroundColor: statusColor
          }}>
          {SituacaoTerminalMock.find(x => x.Key === modelForm.status)?.Value}
        </Box>
      }
      bottomArea={
        <div className={classes.acoes}>
          <Grid container spacing={2} justifyContent='flex-end'>
            {(![EnumTerminalStatus.Desativado, EnumTerminalStatus.Disponivel].includes(modelForm.status) || (
              modelForm.status === EnumTerminalStatus.Desativado && tipoUsuario() === EnumTipoPessoas.SoftwareHouse
            )) && (
                <>
                  {modelForm.status !== EnumTerminalStatus.Disponivel && (
                    <Grid item xs={6} md={2}>
                      <Button
                        onClick={confirmarReutilizar}
                        className={terminalClasses.buttonReutilizar}
                        variant='contained'
                        color="primary"
                        fullWidth
                      >
                        <RetornarIcon tipo='BUTTON_PRIMARY' />
                        Reutilizar
                      </Button>
                    </Grid>
                  )}
                  {modelForm.status !== EnumTerminalStatus.Desativado && <>
                    {modelForm.status !== EnumTerminalStatus.Ativo && (
                      <Grid item xs={6} md={2}>
                        <Button
                          onClick={confirmarAtivar}
                          className={terminalClasses.btnAtivar}
                          variant='contained'
                          color="primary"
                          fullWidth
                        >
                          <OkIcon tipo='BUTTON_PRIMARY' />
                          Reativar
                        </Button>
                      </Grid>
                    )}
                    {modelForm.status !== EnumTerminalStatus.Bloqueado && (
                      <Grid item xs={6} md={2}>
                        <Button
                          onClick={confirmarBloquear}
                          className={terminalClasses.btnBloquear}
                          variant='contained'
                          color="primary"
                          fullWidth
                        >
                          <CadeadoIcon tipo='BUTTON_PRIMARY' />
                          Bloquear
                        </Button>
                      </Grid>
                    )}
                  </>}
                </>
              )}
            {!xs && (
              <Grid item md={modelForm.status === EnumTerminalStatus.Desativado && tipoUsuario() === EnumTipoPessoas.SoftwareHouse ? 6 : 4}>
              </Grid>
            )}
            <Grid item xs={12} md={2}>
              <Button
                disabled={carregando}
                onClick={closeDialog}
                variant='outlined'
                color='primary'
                fullWidth
              >
                <VoltarIcon tipo='BUTTON' />
                Voltar
              </Button>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                disabled={carregando}
                onClick={() => {
                  formRef.current!.submitForm()
                }
                }
                variant="contained"
                color="primary"
                fullWidth
              >
                <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                Salvar
              </Button>
            </Grid>
          </Grid>
        </div>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AccordionTerminalForm
            setRefresh={setRefresh}
            model={modelForm}
            carregando={carregando}
            openned={openned}
            formRef={formRef}
          />
        </Grid>
        <Grid item xs={12}>
          <AccordionTerminalHistorico
            terminalId={modelForm.id}
            openned={openned}
          />
        </Grid>
      </Grid>
    </DialogSaurus>
  );
};