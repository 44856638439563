import { Box, Button, Grid, Tooltip, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { CloseIcon } from 'views/components/icons/close-icon';
import { FaturaAGerarListData } from './components/faturas-a-gerar-list-data';
import FaturasAGerarContador from './components/faturas-a-gerar-contador/faturas-a-gerar-contador';
import { useThemeQueries } from 'views/theme';
import { useCallback, useEffect, useState } from 'react';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { CircularLoading } from 'views/components/utils';
import { useGetFaturasPendentes } from 'data/api/gestao/faturas/get-faturas-licencas-em-aberto';
import { FaturaAGerarModel } from 'model/api/gestao/fatura/faturas-a-gerar-model';
import { ExportarExcelIcon } from 'views/components/icons/exportar-excel-icon';
import { converterDateToCompetencia, dateParaCompetencia, formatarCompetenciaDate } from 'utils/converter-competencia';
import { useGetFaturasPendentesExcel } from 'data/api/gestao/faturas/get-faturas-licencas-em-aberto-excel';
import { usePostFaturaEmAbertoGerar } from 'data/api/gestao/faturas/post-fatura-em-aberto-gerar';
import { useHistory } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { useGetRevendaDesempenho } from 'data/api/gestao/resultados/get-revenda-desempenho';
import { RevendaDesempenhoModel } from 'model/api/gestao/revendedor/revenda-desempenho-model';
import { EnumTipoAvaliacaoDesempenho } from 'model/enums/enum-tipo-avaliacao-desempenho';

interface DialogFaturasAGerarProps {
    openned: boolean;
    closeDialog: () => void;
}

const compProximoMes = dateParaCompetencia(new Date(new Date().setMonth(new Date().getMonth() + 1)))

export const DialogFaturasAGerar = ({
    openned,
    closeDialog,
}: DialogFaturasAGerarProps) => {
    const [faturas, setFaturas] = useState<FaturaAGerarModel[]>([]);
    const [revendaDesempenho, setRevendaDesempenho] = useState<RevendaDesempenhoModel>(new RevendaDesempenhoModel())
    const { isMobile, theme } = useThemeQueries()
    const { showToast } = useToastSaurus()
    const { usuario } = useSessaoAtual()

    const history = useHistory()
    const confirm = useConfirm()

    const { getFaturasPendentes, carregando: carregandoGet } = useGetFaturasPendentes()
    const { getFaturasPendentesExcel, carregando: carregandoExcel } = useGetFaturasPendentesExcel()
    const { postFaturaEmAbertoGerar, carregando: carregandoGerar } = usePostFaturaEmAbertoGerar()
    const { getRevendaDesempenho, carregando: carregandoDesempenho } = useGetRevendaDesempenho();

    const carregando = carregandoGet || carregandoExcel || carregandoGerar || carregandoDesempenho

    const exportarExcel = useCallback(async () => {
        try {
            const res = await getFaturasPendentesExcel(usuario!.Id, compProximoMes)

            if (res.erro) throw (res.erro)
            if (!res.resultado) return;

            const blob = new Blob([res.resultado?.data], { type: 'application/vnd.ms-excel' },);

            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a')

            anchor.href = url
            anchor.download = `Licenças-${compProximoMes}.xls`
            anchor.click()
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getFaturasPendentesExcel, showToast, usuario])


    const getProximasFaturas = useCallback(async () => {
        try {
            const res = await getFaturasPendentes(usuario!.Id, compProximoMes)
            if (res.erro) throw new Error(res.erro)
            setFaturas(res.resultado?.data?.list ?? [])
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getFaturasPendentes, usuario, showToast])

    const getDesempenho = useCallback(async () => {
        try {
            const searchParams = new URLSearchParams()
            searchParams.append('revendaId', usuario!.Id)
            const res = await getRevendaDesempenho(converterDateToCompetencia(new Date()), searchParams.toString())

            if (res.erro) throw res.erro

            if(res.resultado?.data.length === 0) return

            setRevendaDesempenho(res.resultado!.data[0])

        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getRevendaDesempenho, showToast, usuario])

    useEffect(() => {
        const searchFaturas = async () => {
            await getProximasFaturas();
            await getDesempenho();
        }
        searchFaturas();
        return () => setFaturas([]);
    }, [getDesempenho, getProximasFaturas]);

    const gerarFatura = useCallback(async () => {
        try{
            const res = await postFaturaEmAbertoGerar(usuario!.Id, compProximoMes)
            if(res.erro) throw res.erro
            
            showToast('success', 'Fatura Gerada!')
            closeDialog()
            history.push(`/revenda/notas-fiscais/${res.resultado?.data.id}`)
        }catch(e: any){
            showToast('error', e.message)
        }
    }, [closeDialog, history, postFaturaEmAbertoGerar, showToast, usuario])

    const confirmGerar = useCallback(() => {
        confirm({
            title: 'Gerar Fatura',
            description: <Box display='flex' flexDirection='column' gridGap={4}>
                <Typography>Deseja gerar a fatura do mês {formatarCompetenciaDate(compProximoMes)}?</Typography>
                <Typography style={{fontWeight: 500}}>OBS: Seu desconto vigente será considerado ao gerar a fatura e não poderá ser alterado posteriormente.</Typography>
            </Box>,
            confirmationText: 'Gerar',
            cancellationText: 'Cancelar'
        }).then(() => gerarFatura())
    }, [confirm, gerarFatura])

    const retornarMensagem = useCallback(() => {
        if(revendaDesempenho.descontoAtual === revendaDesempenho.desconto){
            return {
                cor: 'rgb(51 180 251)',
                mensagem: `Informativo: Seu desconto nesta fatura será de ${Math.abs(revendaDesempenho.descontoAtual)}%!`
            }
        }else if( revendaDesempenho.descontoAtual > revendaDesempenho.desconto){
            return {
                cor: theme.palette.success.light,
                mensagem: `Parabéns pelo empenho! Seu desconto nesta fatura aumentou para ${Math.abs(revendaDesempenho.desconto)}%.`
            }
        }else{
            return {
                cor: theme.palette.warning.light,
                mensagem: `Atenção! Seu desconto será diminuido para ${Math.abs(revendaDesempenho.desconto)}% devido o desempenho neste período.`
            }
        }
    }, [revendaDesempenho.desconto, revendaDesempenho.descontoAtual, theme.palette.success.light, theme.palette.warning.light])

    return (
        <DialogSaurus
            aberto={openned}
            titulo="Próximas Faturas"
            tamanho='md'
            fullScreen={isMobile}
            endAdornment={
                <Box display='flex' alignItems='center' gridGap={5}>
                    <Tooltip arrow title="Exportar Excel">
                        <Button color="primary" onClick={() => exportarExcel()}>
                            <ExportarExcelIcon tipo="BUTTON_EXPORT_MOBILE" />
                        </Button>
                    </Tooltip>
                    <Button onClick={closeDialog} style={{ margin: '5px 0' }}>
                        <CloseIcon tipo='BUTTON' style={{ margin: 0 }} />
                    </Button>
                </Box>
            }
            zIndex='1299'
            bottomArea={<FaturasAGerarContador faturas={faturas} onClickGerar={confirmGerar}/>}
        >
            {carregando && <CircularLoading tipo='FULLSIZED' />}
            <Grid container style={{ marginBottom: '15px' }}>
                {(!carregandoGet && !carregandoDesempenho && revendaDesempenho.avaliarDesempenhoRevenda !== EnumTipoAvaliacaoDesempenho.NaoAvalia) && <Grid item xs={12}>
                    <Box bgcolor={retornarMensagem().cor}
                        borderRadius={theme.shape.borderRadius} padding={1} ml={1} mb={1}>
                        <Typography style={{color: theme.palette.background.paper}}>
                            {retornarMensagem().mensagem}
                        </Typography>
                </Box>
                </Grid>}
                <Grid item xs={12}>
                    <FaturaAGerarListData
                        list={faturas.sort((a, b) => a.pagadorNome.localeCompare(b.pagadorNome))}
                        onClick={() => { }}
                    />
                </Grid>
            </Grid>
        </DialogSaurus>
    );
};


