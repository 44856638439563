import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetFaturasPendentes() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getFaturasPendentes = useCallback(
        async (revendaId: string, anoMesFinal: number) => {
            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/fatura/revenda/${revendaId}/pendente/${anoMesFinal}`,
                method: 'GET',
                enviarTokenUsuario: true,
            })
        }
        , [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getFaturasPendentes,
    };
}
