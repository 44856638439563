
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormPessoaComissaoValidation = () => {


  const FormPessoaComissaoValidationYup = useMemo(() => {
    return Yup.object().shape({
      comissionadoId: Yup.string().required('Comissionado é obrigatório.'),
      sistemaId: Yup.string().required('Sistema é obrigatório.'),
      valorComissao: Yup.string().required('Valor da comissão é obrigatório.')
    });
  }, [])

  return {
    FormPessoaComissaoValidationYup,
  }
}

