import { Button, Divider, Grid, Slide, Typography } from "@material-ui/core";
import classNames from "classnames";
import { PessoaInfoModel } from "model/api/gestao/pessoa/pessoa-info-model";
import { useEffect, useRef } from "react";
import { useSessaoAtual } from "services/app";
import { FormPessoaInfo } from "views/components/form/master/pessoa/pessoa-info/form-pessoa-info";
import { DefaultFormRefs } from "views/components/form/utils";
import { AvancarIcon, SalvarNovoIcon, VoltarIcon } from "views/components/icons";
import { useModalStyles } from "views/components/modals/utils/modal-styles";

interface Props {
    carregando: boolean
    handleSubmit: (model: PessoaInfoModel) => void
    revenda: boolean
    pessoaInfoForm: PessoaInfoModel
    fase: 1 | 2 | 3
    setFase: (value: 1 | 2 | 3) => void
}

export const PessoaCadastroInformacoes = ({ carregando, handleSubmit, revenda, fase, pessoaInfoForm, setFase }: Props) => {
    const classes = useModalStyles();
    const { usuario } = useSessaoAtual()
    const cadFormRef =
        useRef<DefaultFormRefs<PessoaInfoModel>>(null);

    useEffect(() => {
        cadFormRef.current?.fillForm(
            pessoaInfoForm
        );
    }, [pessoaInfoForm]);

    return (
        <>
            <Slide in={fase === 2} direction="right">
                <div style={{
                    height: '100%',
                    display: 'flex',
                    flex: 1
                }}>
                    <div className={classes.content}>
                        <div
                            className={classNames(
                                classes.contentForms,
                                carregando ? classes.contentFormsLoading : undefined,
                            )}
                        >
                            <Typography variant="h6" color="primary">
                                Informações
                            </Typography>
                            <Divider style={{ marginBottom: 40 }} />
                            <FormPessoaInfo
                                ref={cadFormRef}
                                showLoading={false}
                                loading={carregando}
                                revenda={usuario?.Tipo.toLowerCase() === 'revenda' || revenda}
                                onSubmit={handleSubmit}
                                modal={true}
                            />
                        </div>
                        <div className={classes.acoes}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Button
                                        disabled={carregando}
                                        onClick={() => {
                                            setFase(1)
                                        }
                                        }
                                        variant="outlined"
                                        color="primary"
                                        size="large"
                                        fullWidth
                                    >
                                        <VoltarIcon tipo="BUTTON" />
                                        Voltar
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        disabled={carregando}
                                        onClick={() => {
                                            cadFormRef.current!.submitForm()
                                        }
                                        }
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth
                                    >
                                        {revenda ? <>
                                            <AvancarIcon tipo='BUTTON_PRIMARY' />
                                            Continuar
                                        </> : <>
                                            <SalvarNovoIcon tipo="BUTTON_PRIMARY" />
                                            Salvar
                                        </>}
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </Slide>
        </>
    )
}