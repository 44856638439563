export enum EnumStatusIndicacao {
    //STATUS ABERTO/DISPONIVEL
    Disponivel = 0,
    InteracaoIniciada = 10,

    //STATUS NEGOCIACAO
    RetornoInterno = 20,
    ContatoRealizado = 21,
    VisitaMarcada = 22,
    VisitaRealizada = 23,
    PropostaEnviada = 24, 

    //STATUS DE VALIDACAO
    AguardandoValidacao = 1,
    PropostaRecusada = 100,
    PropostaAceita = 101,
    CancelamentoSolicitado = 102,
    InvalidezSolicitada = 103,

    //STATUS FINALIZADOS 
    Finalizado = 120,
    Invalidado = 121,
    Cancelado = 122
}