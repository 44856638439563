import { Button, Grid, Typography } from '@material-ui/core';
import { DialogSaurus } from '../../dialog-saurus/dialog-saurus';
import { SATIcon, VoltarIcon } from 'views/components/icons';
import { guidEmpty } from 'utils/guid-empty';

interface DialogPixErrorProps {
    openned: boolean;
    closeDialog: () => void;
    selectFormaDePagamento: JSX.Element | null;
    formaPagamentoId: string;
    pagarOutraFormaPagamento: () => Promise<void>;
}

export const DialogPixError = ({
    openned,
    closeDialog,
    selectFormaDePagamento,
    formaPagamentoId,
    pagarOutraFormaPagamento
}: DialogPixErrorProps) => {


    return (
        <DialogSaurus
            aberto={openned}
            titulo='Erro ao Gerar Pix'
            tamanho="sm"
        >
            <Grid container spacing={2} style={{ marginBottom: 8 }}>
                <Grid item xs={12}>
                    <Typography>Estamos passando por instabilidades parar gerar o PIX. Deseja pagar a fatura usando outra forma de pagamento ou tentar novamente mais tarde?</Typography>
                </Grid>
                <Grid item xs={12}>
                    {selectFormaDePagamento}
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button variant='outlined' color='primary' fullWidth onClick={closeDialog}>
                        <VoltarIcon tipo='BUTTON' />
                        Voltar
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button variant='contained' color='primary' fullWidth disabled={formaPagamentoId === guidEmpty()}
                        onClick={async () => {
                            await pagarOutraFormaPagamento();
                            closeDialog()
                        }}>
                        <SATIcon tipo='BUTTON_PRIMARY' />
                        Trocar
                    </Button>
                </Grid>
            </Grid>
        </DialogSaurus >
    );
};