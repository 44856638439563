import { Grid, Typography } from '@material-ui/core';
import { CardPessoaSocioProps } from './card-pessoa-sistema-props';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { EditarIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import {useStyles} from './card-pessoa-sistema-styles'
import { toDecimalString } from 'utils/to-decimal';
import { toDateString } from 'utils/to-date';
import { useSessaoAtual } from 'services/app';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';

export const CardPessoaSistema = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardPessoaSocioProps) => {
  const classes = useDefaultCardStyles();
  const bgClasses = useStyles();
  const {md} = useThemeQueries()

  const {tipoUsuario} = useSessaoAtual();

  const isFuncionario = tipoUsuario() === EnumTipoPessoas.FuncionarioSoftwareHouse;

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          if(isFuncionario) return
          onClick(model);
        }}
        className={bgClasses.cardBackground}
      >
        <Grid container spacing={md ? 1 : 2} className={`${classes.cardContent} ${bgClasses.container}`}>
          <Grid item xs={6} md={4}>
            <Typography color="textPrimary" variant="caption">
              Sistema
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              <b>{model.sistema.nome}</b>
            </Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography color="textPrimary" variant="caption">
              Tabela Preço
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {model.tabelaPreco.nome}
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography color="textPrimary" variant="caption">
              Última Alteração
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {toDateString(model.dataDeAlteracao)}
            </Typography>
          </Grid>
          {!isFuncionario && <Grid item xs={6} md={2}>
            <Typography color="textPrimary" variant="caption">
              % Over Tabela
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {toDecimalString(model.percentualOverTabela)}%
            </Typography>
          </Grid>}
        </Grid>
        {!md && !isFuncionario ? <div className={bgClasses.editIcon}>
          <EditarIcon tipo='BUTTON' />
        </div> : null}
      </DefaultCard>
    </>
  );
};
