export function calcularDiferencaEmDias(data1: Date, data2: Date): number {
    const umDiaEmMilissegundos = 24 * 60 * 60 * 1000; // 1 dia em milissegundos

    const tempoInicio = data1.getTime();
    const tempoFim = data2.getTime();

    const diffEmMilissegundos = tempoFim - tempoInicio;
    const diffEmDias = Math.ceil(diffEmMilissegundos / umDiaEmMilissegundos);

    return diffEmDias;
}