import { PesquisaHeaderProps } from './pesquisa-indicacoes-disponiveis-props';
import { useStyles } from './pesquisa-indicacoes-disponiveis-styles';
import { Button, Grid, Fade, useTheme } from '@material-ui/core';
import { useCallback, useRef, useState } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { ProcurarIcon } from 'views/components/icons';

export const PesquisaIndicacoesDisponiveis = (props: PesquisaHeaderProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const refInput = useRef<HTMLDivElement | null>(null);

  const [termoPesquisar, setTermoPesquisar] = useState<string>('')


  //CHAMADA DO PESQUISAR
  const pesquisar = useCallback(
    (event: any) => {
      event.preventDefault();
      props.onPesquisar(termoPesquisar);
    },
    [props, termoPesquisar],
  );

  return (
    <Fade in={props.isOpened} mountOnEnter unmountOnExit>
      <form onSubmit={pesquisar}>
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={12}>
            <div className={classes.containerFinal}>
              <TextFieldSaurus
                inputRef={refInput}
                fullWidth
                size="small"
                allowSubmit={true}
                value={termoPesquisar}
                placeholder="Pesquisar"
                onChange={(e: any) => setTermoPesquisar(e.target.value)}
                adornmentColor={theme.palette.text.disabled}
                endAdornmentButton={
                  <Button
                    color="primary"
                    type="submit"
                    className={classes.btnPesquisa}
                    fullWidth={true}
                  >
                    <ProcurarIcon tipo="BUTTON" />
                  </Button>
                }
              />
            </div>
          </Grid>
        </Grid>

      </form>
    </Fade>
  );
};