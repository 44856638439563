import { useState, useCallback, useEffect } from "react";

import { Grid } from "@material-ui/core";
import { useStyles } from "./precificacao-list-styles";
import { PrecificacaoListData } from "./precificacao-list-data";
import { Paginacao } from "views/components/paginacao";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import { useCadastros, useToastSaurus } from "services/app";
import { PrecificacaoListSearchProps } from "./precificacao-list-search-props";
import { useGetTabelaPreco } from "data/api/gestao/tabela-preco";
import { TabelaPrecosModel } from "model/api/gestao/tabela-preco/tabela-precos-model";
import { useHistory, useLocation } from "react-router-dom";
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles";

export interface PreficicacaoListProps {
  searchProps: PrecificacaoListSearchProps;
}

export const PreficicacaoList = (props: PreficicacaoListProps) => {
  const classes = useStyles();
  const listClasses = useDefaultCadastroStyles();
  const history = useHistory()
  const location = useLocation();


  const { getTabelaPreco, carregando } = useGetTabelaPreco();

  const { cadastroPrecificacaoState } = useCadastros()

  const { showToast } = useToastSaurus();

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<TabelaPrecosModel>(),
  });

  const [selectedList] = useState<Array<string>>([]);

  const fillResult = useCallback(
    (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<TabelaPrecosModel>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    []
  );

  const search = useCallback(
    async (newPage: number) => {
      try {
        const res = await getTabelaPreco(newPage, `pageSize=14&query=${props.searchProps.termo}`);
        if (res.erro) throw res.erro;

        if (!res.resultado) {
          return;
        }

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          await search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list
        );

        return res;
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [getTabelaPreco, props.searchProps.termo, fillResult, showToast]
  );

  const onCardSelected = useCallback((id: string) => {
    history.push({
      pathname: `/tabela-precos/${id}`,
      state: {
        from: {
          pathname: history.location.pathname,
          page: queryStatus.page
        }
      }
    });
  }, [history, queryStatus.page])


  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        await search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  useEffect(() => {
    if (!cadastroPrecificacaoState.aberto) {
      let page = Number(new URLSearchParams(location.search).get('page'));
      search(page || queryStatus.page);
      location.search = ''
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStatus.page, search, cadastroPrecificacaoState.aberto]);

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid container className={listClasses.listContainer}>
          <Paginacao
            pageChanged={pageChanged}
            totalPages={queryStatus.totalPages}
            totalRegisters={queryStatus.totalResults}
            currentPage={queryStatus.page}
          />
          <Grid container spacing={1}>
            <PrecificacaoListData
              carregando={carregando}
              list={queryStatus.list}
              selectedList={selectedList}
              onCardSelected={onCardSelected}
              onCardChecked={() => { }}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
