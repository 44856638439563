import { picker } from 'utils/picker';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { useToastSaurus, useCadastros, useSessaoAtual } from 'services/app';
import {
  VoltarIcon,
} from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { PessoaModel } from 'model/api/gestao/pessoa/pessoa-model';
import { usePostAdicionarPessoa } from 'data/api/gestao/pessoa';
import { PessoaInfoModel } from 'model/api/gestao/pessoa/pessoa-info-model';
import { usePostAdicionarPessoaInfo } from 'data/api/gestao/pessoa-info';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { PessoaPostModel } from 'model/api/gestao/pessoa/pessoa-post-model';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { imagemForUpload } from 'utils/imagem-for-upload';
import { usePostImagemBase64 } from 'data/api/imagem/post-imagem';
import { newGuid } from 'utils/new-guid';
import { EnumRetornoApiBase } from 'data/api/base/api-base-response';
import { PessoaCadastroPrimeiraFase } from './components/pessoa-cadastro-primeira-fase';
import { PessoaCadastroInformacoes } from './components/pessoa-cadastro-informacoes';
import { useConsultaCnpj } from 'data/api/wsmaster';
import { stringNumeros } from 'utils/string-numeros';
import { useGetSegmentoCnae } from 'data/api/gestao/segmentos';
import { PessoaSocioModel } from 'model/api/gestao/pessoa/pessoa-socio-model';
import { usePostAdicionarPessoaSocio } from 'data/api/gestao/pessoa-socio';
import { guidEmpty } from 'utils/guid-empty';
import { LocalizacaoModel } from 'model/api/gestao/localizacao/localizacao-model';
import { PessoaCadastroSocios } from './components/pessoa-cadastro-socio';

type ClienteCadastroProps = {
  revenda: boolean
  isCadastroRevenda?: boolean
}

export const ClienteCadastro = ({ revenda }: ClienteCadastroProps) => {

  const { postAdicionarPessoa, carregando: carregandoPostPessoa } = usePostAdicionarPessoa();
  const { postAdicionarPessoaInfo, carregando: carregandoPostInfo } = usePostAdicionarPessoaInfo()
  const { postAdicionarPessoaSocio, carregando: carregandoPostSocio } = usePostAdicionarPessoaSocio()
  const { postImagemBase64, carregando: carregandoPostImagem } = usePostImagemBase64()
  const { getSegmentoCnae, carregando: carregandoGetCnae } = useGetSegmentoCnae()
  const { consultarCNPJ, carregando: carregandoCNPJ } = useConsultaCnpj();

  const { usuario } = useSessaoAtual()

  const [fase, setFase] = useState<1 | 2 | 3>(1)
  const [mostrarFases, setMostrarFases] = useState<1 | 2 | 3>(1)

  const history = useHistory()

  const carregando =
    carregandoPostInfo ||
    carregandoPostPessoa ||
    carregandoPostImagem ||
    carregandoCNPJ ||
    carregandoGetCnae ||
    carregandoPostSocio

  const [pessoaInfoForm, setPessoaInfoForm] = useState<PessoaInfoModel>(new PessoaInfoModel())
  const [localizacao, setLocalizacao] = useState<LocalizacaoModel | undefined>()

  const pessoaForm = useRef<PessoaPostModel>(new PessoaPostModel())
  const socioEncontrado = useRef<PessoaSocioModel>(new PessoaSocioModel())

  const { fecharCadastroPessoa } =
    useCadastros();
  const classes = useModalStyles();

  const { showToast } = useToastSaurus();

  const enviarImagem = useCallback(
    async (imagemUrl: string) => {
      let imagem = '';

      const imgUpload = imagemForUpload(imagemUrl);
      if (imgUpload.length > 0) {
        const retImagem = await postImagemBase64(
          imgUpload,
          `licenciamento/pessoas/${usuario?.Id}/`,
          newGuid(),
        );
        if (retImagem.tipoRetorno !== EnumRetornoApiBase.Sucesso) {
          throw new Error('Erro ao processar  a Imagem selecionada.');
        }
        if (retImagem.resultado?.data.data.status === 2) {
          throw new Error(
            'Erro ao processar a Imagem selecionada.Detalhe: ' +
            retImagem.resultado?.data?.data?.retorno,
          );
        }
        imagem =
          retImagem.resultado?.data?.data?.url_blob +
          '?timestamp=' +
          new Date().getTime();
      }

      return imagem.length > 0 ? btoa(imagem) : imagem;
    },
    [postImagemBase64, usuario?.Id],
  );

  const saveNewCliente = useCallback(
    async (model: PessoaPostModel) => {

      if (!isEmpty(model.urlImagem))
        model.urlImagem = await enviarImagem(model.urlImagem)

      const ret = await postAdicionarPessoa(model);
      if (ret.erro) {
        throw ret.erro;
      }

      return ret.resultado?.data as PessoaModel
    },
    [enviarImagem, postAdicionarPessoa],
  );

  const saveNewInfo = useCallback(async (pessoaId: string, model: PessoaInfoModel) => {
    const usePicker = picker<PessoaInfoModel>(model, new PessoaInfoModel())
    usePicker.pessoaId = pessoaId

    const res = await postAdicionarPessoaInfo(pessoaId, model)

    if (res.erro) throw res.erro
  }, [postAdicionarPessoaInfo])

  const saveNewSocio = useCallback(async (pessoaId: string, model: PessoaSocioModel) => {
    const usePicker = picker<PessoaSocioModel>(model, new PessoaSocioModel())
    usePicker.pessoaId = pessoaId

    const res = await postAdicionarPessoaSocio(pessoaId, model)

    if (res.erro) throw res.erro
  }, [postAdicionarPessoaSocio])

  const handleSubmit = useCallback(
    async (model: PessoaPostModel) => {
      try {
        const cpfCnpj = stringNumeros(model.cpfCnpj)
        let cnpjEncontrado = true
        let pessoaInfo = new PessoaInfoModel()
        let pessoaSocio: Array<PessoaSocioModel> = []
        if (cpfCnpj.length === 14) {
          try {
            const empresa = await consultarCNPJ(model.cpfCnpj, localizacao)

            setPessoaInfoForm(prev => {
              const obj = {
                ...prev,
                bairro: empresa.bairro,
                cep: empresa.cep,
                codMunicipio: Number(empresa.cMunicipio),
                complemento: empresa.complemento,
                uf: empresa.uf,
                logradouro: empresa.logradouro,
                municipio: empresa.municipio,
                fantasia: empresa.nomeFantasia,
                xNome: empresa.razaoSocial,
                numero: empresa.numero,
                cnae: empresa.cnae,
                ie: empresa.ie,
                isComercial: true
              }
              pessoaInfo = obj

              if (empresa.socios && empresa.socios.length > 0) {
                pessoaSocio = empresa.socios.map(socio => ({
                  cpf: '',
                  email: '',
                  fone: '',
                  id: guidEmpty(),
                  nome: socio.nome,
                  pessoaId: guidEmpty(),
                  bairro: '',
                  cep: '',
                  complemento: '',
                  dataNascimento: '',
                  logradouro: '',
                  municipio: '',
                  nomeMae: '',
                  numero: '',
                  uf: ''
                }))
              }

              return obj
            })
          } catch (err: any) {
            showToast('error', err.message)
            setPessoaInfoForm(prev => ({
              ...prev,
              xNome: model.nomeComercial,
              fantasia: model.nomeComercial
            }))
            cnpjEncontrado = false
          }
          if (pessoaInfo.cnae.length > 0) {
            try {
              const ret = await getSegmentoCnae(pessoaInfo.cnae)

              if (ret.erro) throw ret.erro

              if (ret.resultado?.data.list.length > 0) {
                pessoaInfo.segmentoId = ret.resultado?.data.list[0].id
              }
            } catch (err: any) {

            }
          }
        } else if (cpfCnpj.length === 11) {
          setPessoaInfoForm(prev => ({
            ...prev,
            xNome: model.nomeComercial,
            fantasia: model.nomeComercial
          }))
          cnpjEncontrado = false
        }

        const novaPessoaCriar = { ...model, tipoCobrancaRevenda: Number(model.tipoCobrancaRevenda) }

        if (revenda) novaPessoaCriar.tipo = EnumTipoPessoas.Revendedor

        try {
          if (pessoaSocio.length > 0) {
            const socioPost = pessoaSocio.map(socio => ({
              ...socio,
              pessoaId: res.id
            }))

            socioEncontrado.current = socioPost[0]
          }
        } catch (err: any) {

        }

        if (revenda) {
          novaPessoaCriar.melhorDia = 5
        }

        if (!cnpjEncontrado || revenda) {
          pessoaForm.current = novaPessoaCriar
          setFase(2)
          return
        }

        const res = await saveNewCliente(novaPessoaCriar);

        try {
          await saveNewInfo(res.id, pessoaInfo)
        }
        catch (err: any) {
          showToast('error', err.message)
        }
        try {
          if (pessoaSocio.length > 0) {
            const socioPost = pessoaSocio.map(socio => ({
              ...socio,
              pessoaId: res.id
            }))

            for (let i = 0; i < pessoaSocio.length; i++) {
              await saveNewSocio(res.id, socioPost[i])
            }
          }
        } catch (err: any) {

        }

        fecharCadastroPessoa(revenda ? '/revenda' : '')

        showToast('success', revenda ? 'Novo Revendedor Adicionado' : "Novo Cliente Adicionado!")

        history.push(`/${revenda ? 'revendedor' : 'clientes'}/${res.id}`)

        return true
      } catch (e: any) {
        showToast('error', e.message);
        return false
      }
    },
    [revenda, saveNewCliente, fecharCadastroPessoa, showToast, history, localizacao, consultarCNPJ, getSegmentoCnae, saveNewInfo, saveNewSocio],
  );

  const handleSubmitInfo = useCallback(async (model: PessoaInfoModel) => {
    if (!model.isComercial && !model.isFinanceiro && !model.isJuridico) {
      showToast('error', 'O endereço deve ser financeiro, comercial ou jurídico.')
      return
    }
    if (!revenda) {
      try {

        const res = await saveNewCliente(pessoaForm.current);

        await saveNewInfo(res.id, model)

        fecharCadastroPessoa()

        showToast('success', "Novo Cliente Adicionado!")

        history.push(`/clientes/${res.id}`)

        return true
      } catch (err: any) {
        showToast('error', err.message)
        return false
      }
    }
    setPessoaInfoForm(model)
    setFase(3)

  }, [fecharCadastroPessoa, history, pessoaForm, revenda, saveNewCliente, saveNewInfo, showToast])

  const handleSubmitSocio = useCallback(async (model: PessoaSocioModel) => {
    try {
      const res = await saveNewCliente(pessoaForm.current);

      await saveNewInfo(res.id, pessoaInfoForm)
      await saveNewSocio(res.id, model)

      fecharCadastroPessoa()

      showToast('success', 'Novo Revendedor Adicionado')

      history.push(`/revendedor/${res.id}`)
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [fecharCadastroPessoa, history, pessoaForm, pessoaInfoForm, saveNewCliente, saveNewInfo, saveNewSocio, showToast])

  const onCloseClick = useCallback(() => {
    fecharCadastroPessoa(revenda ? '/revendedor' : '')
  }, [fecharCadastroPessoa, revenda]);

  useEffect(() => {
    if (fase === 2 && mostrarFases !== 2) {
      setTimeout(() => {
        setMostrarFases(2)
      }, 300)
    } else if (fase === 1 && mostrarFases !== 1) {
      setTimeout(() => {
        setMostrarFases(1)
      }, 300)
    } else if (fase === 3 && mostrarFases !== 3) {
      setTimeout(() => {
        setMostrarFases(3)
      }, 300)
    }

  }, [fase, mostrarFases])

  useEffect(() => {
    if ('geolocation' in navigator) {
      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setLocalizacao(
            new LocalizacaoModel(position.coords.latitude, position.coords.longitude)
          );
        },
        function (error) {

        },
        options,
      );
    }
  }, [usuario]);

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={revenda ? 'Novo Revendedor' : 'Novo Cliente'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      {mostrarFases === 1 && (
        <PessoaCadastroPrimeiraFase
          pessoaForm={pessoaForm.current}
          handleSubmit={handleSubmit}
          carregando={carregando}
          revenda={revenda}
          fase={fase}
        />
      )}
      {mostrarFases === 2 && (
        <PessoaCadastroInformacoes
          pessoaInfoForm={pessoaInfoForm}
          handleSubmit={handleSubmitInfo}
          carregando={carregando}
          revenda={revenda}
          fase={fase}
          setFase={setFase}
        />
      )}
      {mostrarFases === 3 && (
        <PessoaCadastroSocios
          pessoaSocioForm={socioEncontrado.current}
          fase={fase}
          carregando={carregando}
          handleSubmit={handleSubmitSocio}
          setFase={setFase}
        />
      )}
    </div>
  );
};
