import Dexie, { Table } from 'dexie';
import { TabelaClientes } from './interfaces/interface-tabela-clientes';
import { TabelaIndicacoes } from './interfaces/interface-tabela-indicacoes';
import { TabelaResultadoRevenda } from './interfaces/interface-tabela-resultado-revenda';

export class LicenciamentoDatabase extends Dexie {
    clientes!: Table<TabelaClientes>;
    revendedores!: Table<TabelaClientes>; //OBJETO DE REVENDEDORES NO GET DO MAPA É IGUAL DE CLIENTE :)
    indicacoes!: Table<TabelaIndicacoes>;
    resultadoRevenda!: Table<TabelaResultadoRevenda>;

    constructor(dbName: string) {
        super(dbName);
        this.version(77).stores({
            clientes: `++idIndexed, latitude, longitude, urlImagem, nomeComercial, documento, revendaId, revendaNome, revendaDocumento, pessoaInfo, contratos`,
            revendedores: `++idIndexed, latitude, longitude, urlImagem, nomeComercial, documento, revendaId, revendaNome, revendaDocumento, pessoaInfo, contratos`,
            resultadoRevenda: `++idIndexed, anoMes, gerente, gerenteCpfCnpj, gerenteId, revenda, revendaCpfCnpj, revendaId, tipoFaturamento, contratosNovos, contratosCancelados, contratosSaldo, contratosSaldoPosicao, contratosSaldoMediaSaurus, contratosAtivos, contratosAtivosSaurus, contratosAtivosMediaSaurus, terminaisNovos, terminaisCancelados, terminaisSaldo, terminaisSaldoPosicao, terminaisSaldoMediaSaurus, terminaisAtivos, terminaisAtivosSaurus, terminaisAtivosMediaSaurus, cnpJsNovos, cnpJsCancelados, cnpJsSaldo, cnpJsSaldoPosicao, cnpJsSaldoSaurus, cnpJsAtivos, cnpJsAtivosSaurus, cnpJsAtivosMediaSaurus, licencasQtdAberta, licencasQtdAbertaParticipacao, licencasQtdBonificada, licencasQtdBonificadaParticipacao, licencasQtdCancelada, licencasQtdCanceladaParticipacao, licencasQtdPaga, licencasQtdPagaParticipacao, licencasQtdFaturada, licencasQtdFaturadaParticipacao, licencasQtdTotal, licencasQtdTotalParticipacao, licencasValorAberta, licencasValorAbertaParticipacao, licencasValorBonificada, licencasValorBonificadaParticipacao, licencasValorCancelada, licencasValorCanceladaParticipacao, licencasValorPaga, licencasValorPagaParticipacao, licencasValorFaturado, licencasValorFaturadaParticipacao, licencasValorTotal, licencasValorTotalParticipacao, ticketMedioAberta, ticketMedioBonificada, ticketMedioCancelada, ticketMedioPaga, ticketMedioFaturado, ticketMedioTotal, comissaoGerente, comissaoRevenda, comissaoOutros`,
            indicacoes: `++idIndexed, id, responsavel, cliente, indicador, sistema, data, nivelDeInteresse, instrucao, contato, observacaoFinalizacao, faturamentoEstimado, status, statusGeral,avaliacaoIndicador, dataUltimoRetorno, dataUltimaVisita, dataFinalizacao, dataLimite, motivoAvaliacaoIndicador, avaliacaoResponsavel, motivoAvaliacaoResponsavel`
        });
    }

    limparDados = async () => {
        await this.clientes.clear();
        await this.revendedores.clear();
        await this.indicacoes.clear();
        await this.resultadoRevenda.clear();
    }

    limparCampo = async (campo: 'clientes' | 'revendedores' | 'indicacoes' | 'resultadoRevenda') => {
        await this[campo].clear();
    }
}

export const LicenciamentoDBPrimary = new LicenciamentoDatabase('licenciamentov1.24');