import { PesquisaHeaderProps } from './pesquisa-clientes-props';
import { useStyles } from './pesquisa-clientes-styles';
import { Button, Grid, Fade, useTheme } from '@material-ui/core';
import { useCallback, ChangeEvent, useRef } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { ProcurarIcon } from 'views/components/icons';

export const PesquisaClientes = (props: PesquisaHeaderProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const refInput = useRef<HTMLDivElement | null>(null);


  //CHAMADA DO PESQUISAR
  const pesquisar = useCallback(
    (event: any) => {
      event.preventDefault();
      props.onPesquisar();
    },
    [props],
  );

  //ALTERAR A PESQUISA
  const onChangePesquisa = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      props.setPesquisa(prev => ({
        ...prev,
        termo: event.target.value
      }))
    },
    [props],
  );

  return (
    <Fade in={props.isOpened} mountOnEnter unmountOnExit>
      <form onSubmit={pesquisar}>
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={12}>
            <div className={classes.containerFinal}>
              <TextFieldSaurus
                inputRef={refInput}
                fullWidth
                size="small"
                allowSubmit={true}
                value={props.pesquisa.termo}
                placeholder="Pesquisar"
                onChange={onChangePesquisa}
                adornmentColor={theme.palette.text.disabled}
                endAdornmentButton={
                  <Button
                    color="primary"
                    type="submit"
                    className={classes.btnPesquisa}
                    fullWidth={true}
                  >
                    <ProcurarIcon tipo="BUTTON" />
                  </Button>
                }
              />
            </div>
          </Grid>
        </Grid>

      </form>
    </Fade>
  );
};
