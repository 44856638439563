import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetFaturasExportarExcel() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getFaturasExportarExcel = useCallback(
        async (page: number, queryBusca: string) => {
            let queryPage = "";
            
            if (page) {
                queryPage = "page=" + page.toString();
            }
            const queryFinal = `${queryPage?.length > 0 || queryBusca?.length > 0 ? "?" : ""
                }${queryPage}${queryPage.length > 0 ? "&" : ""}${queryBusca}`;

            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Fatura/excel${queryFinal}`,
                method: 'GET',
                enviarTokenUsuario: true,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/vnd.ms-excel",
                    "Content-Disposition": "attachment; filename=faturas.xls; filename*=UTF-8''faturas.xls "
                },
                timeout: 60000
            })
        }
        , [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getFaturasExportarExcel,
    };
}
