import { AppEventEnum } from "model/enums/enum-app-event";
import React, { useRef } from "react";

export function useEventTools() {

    const eventListeners = useRef<any>({});

    const callEvent = React.useCallback((e: AppEventEnum, object: any) => {
        const event = new CustomEvent(e.toString(), { detail: object });
        window.dispatchEvent(event);
    }, []);

    const addHandler = React.useCallback((e: AppEventEnum, callback: any) => {
        const event = (ret: any) => callback(ret.detail)
        window.addEventListener(e.toString(), event, false);

        eventListeners.current = {
            ...eventListeners.current,
            [e.toString()]: event
        };
    }, [])

    const removeHandler = React.useCallback((e: AppEventEnum, callback: any) => {
        const event = eventListeners.current[e.toString()];

        if (event) {
            window.removeEventListener(e.toString(), event);
            delete eventListeners.current[e.toString()]
        }
    }, [eventListeners])

    return {
        callEvent,
        addHandler,
        removeHandler
    }
}