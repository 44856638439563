import { useCallback, FocusEvent, useState, useEffect } from "react";
import { useGetSegmentos } from "data/api/gestao/segmentos";
import { SegmentoModel } from "model/api/gestao/master/segmento";
import {
  retornoAutoComplete,
  AutocompleteSaurus,
} from "../autocomplete-saurus/autocomplete-saurus";

export interface AutocompleteSegmentosProps {
  label?: string;
  loadingExterno?: boolean;
  name?: string;
  value?: string | any;
  error?: boolean;
  helperText?: string | undefined;
  placeholder?: string;
  allowSubmit?: boolean;
  disabled?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onChange?: (value: retornoAutoComplete) => any;
  fullWidth?: boolean
  aberto?: boolean
}

export const AutocompleteSegmento = ({
  onChange,
  loadingExterno,
  aberto = false,
  ...props
}: AutocompleteSegmentosProps) => {
  const { getSegmentos, carregando: carregandoSegmentos } = useGetSegmentos();
  const [segmentosState, setSegmentosState] = useState<Array<SegmentoModel>>(
    new Array<SegmentoModel>()
  );
  const [nomeValor, setNomeValor] = useState<string>('')

  const getSegmentosWrapper = useCallback(
    async (termo: string) => {
      try {
        const ret = await getSegmentos(termo, `&pageSize=30`);
        if (ret.erro) throw ret.erro;
        let segmentos: Array<SegmentoModel> = new Array<SegmentoModel>();
        if (ret.resultado?.data?.list?.length > 0) {
          segmentos = ret.resultado?.data?.list as Array<SegmentoModel>;
        }
        setSegmentosState(segmentos);
        if (props.value) {
          const valor = segmentos.find(item =>
            item.id === (typeof props.value === 'string' ? props.value : props.value.value.id)
          )
          setNomeValor(valor?.descricao ?? '')
        }else{
          setNomeValor('')
        }
      } catch (e: any) { }
    },
    [getSegmentos, props.value]
  );

  useEffect(() => {
    if (aberto) {
      getSegmentosWrapper('')
    }
  }, [aberto, getSegmentosWrapper])

  useEffect(() => {
    if (props.value) {
      const valor = segmentosState.find(item =>
        item.id === (typeof props.value === 'string' ? props.value : props.value.value.id)
      )
      setNomeValor(valor?.descricao ?? '')
    }
  }, [props.value, segmentosState])

  const onChangeWrapper = useCallback(
    (value: retornoAutoComplete) => {
      if (onChange) onChange(value);
    },
    [onChange]
  );
  const onPesquisa = useCallback(
    (termo: string) => {
      getSegmentosWrapper(termo);
    },
    [getSegmentosWrapper]
  );
  return (
    <AutocompleteSaurus
      disabled={props.disabled}
      name={props.name}
      permiteNovo
      loading={(carregandoSegmentos)}
      onPesquisa={onPesquisa}
      onChange={onChangeWrapper}
      opcoes={segmentosState}
      optionLabelKey="descricao"
      optionValueKey="id"
      onBlur={props.onBlur}
      value={nomeValor}
      allowSubmit={props.allowSubmit}
      noOptionsText={"Segmento não Localizado"}
      textoNovoItem=""
      label={props.label}
      helperText={props.helperText}
      error={props.error}
      fullWidth={props.fullWidth}
      placeholder={props.placeholder}
    />
  );
};
