import { makeStyles, Theme } from "@material-ui/core";

export interface StyleProps {
    ativo: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    card: {
        background: '#F8F8F8',
        padding: 5
    },
    statusPin: {
        backgroundColor: ({ ativo }) => ativo ? '#10C822' : '#F44336',
        width: '5px',
        borderRadius: '5px',
        alignSelf: 'stretch',
        marginBottom: '5px'
    },
    valores: {
        [theme.breakpoints.up('lg')]: {
            textAlign: 'right'
        }
    },
    data: {
        [theme.breakpoints.up('lg')]: {
            textAlign: 'center'
        }
    },
    editIcon: {
        borderLeft: '2px solid #E6E6E6',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 10px',
        margin: '10px 0',
    }
}))