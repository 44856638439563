
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormTabelaPrecoValidation = () => {


  const FormTabelaPrecoValidationYup = useMemo(() => {
    return Yup.object().shape({
      nome: Yup.string().required("O nome da tabela é obrigatório.").transform(nome => nome.length > 100 ? null : nome).typeError('Limite de caracteres excedido.')
    });
  }, [])

  return {
    FormTabelaPrecoValidationYup,
  }
}

