
import { useMemo } from 'react';
import * as Yup from 'yup';


export const useFormAmbienteValidation = () => {


  const FormAmbienteValidationYup = useMemo(() => {
    return Yup.object().shape({
      nome: Yup.string().required('O Nome é obrigatório'),
      valor: Yup.string().required('Este campo é obrigatório')
    });
  }, [])

  return {
    FormAmbienteValidationYup,
  }
}
