import { Typography } from '@material-ui/core';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { toDateString } from 'utils/to-date';
import { useStyles } from '../../dialog/dialog-filial/dialog-filial-styles'
import { CardTerminalHistoricoProps } from './card-terminal-historico-props';

export const CardTerminalHistorico = ({
  model,
  onClick
}: CardTerminalHistoricoProps) => {
  const classes = useDefaultCardStyles();
  const dialogClasses = useStyles();

  return (
    <>
      <DefaultCard
        className={dialogClasses.noPosition}
        onClick={() => onClick(model)}
      >
        <div className={classes.cardContent}>
          <div className={classes.celulaGrid}>
            <Typography color="textPrimary" variant="caption">
              Data de acesso:
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {toDateString(new Date(model.dataAcesso + 'Z'), 'DD/MM/yyyy HH:mm')}
            </Typography>
          </div>
          <div className={classes.celulaGrid}>
            <Typography color="textPrimary" variant="caption">
              Versão:
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {model.versao}
            </Typography>
          </div>
          <div className={classes.celulaGrid}>
            <Typography color="textPrimary" variant="caption">
              Sistema Operacional:
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {model.sistemaOperacional}
            </Typography>
          </div>
        </div>
      </DefaultCard>
    </>
  );
};
