import { Box, Button, Divider, Grid, Tooltip, Typography } from "@material-ui/core"
import { PessoaPostModel } from "model/api/gestao/pessoa/pessoa-post-model"
import { useEffect, useState } from "react"
import { useToastSaurus } from "services/app"
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { useThemeQueries } from "views/theme"
import { CopiarIcon, QrCodeIcon, SalvarEditarIcon } from "views/components/icons"
import { TextFieldSaurus } from "views/components/controles/inputs"
import { LinkIcon } from "views/components/icons/link-icon"
import { DialogQRCode } from "views/components/dialog/dialog-qrcode/dialog-qrcode"
import { useStyles } from './accordion-parametros-sistemas-styles'
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas"
import { CircularLoading } from "views/components"

interface Props {
    model: PessoaPostModel
    loading: boolean;
    onSubmit: (model: PessoaPostModel) => Promise<boolean>
}

export const AccordionParametrosSistemas = ({ model, loading, onSubmit }: Props) => {

    const { theme } = useThemeQueries()
    const classes = useStyles()
    const [openAccordion, setOpenAccordion] = useState<boolean>(false)
    const [openQRCode, setOpenQRCode] = useState<boolean>(false)

    const [hide, setHide] = useState<boolean[]>([true, true])

    const { showToast } = useToastSaurus()

    const [senhaTecnica, setSenhaTecnica] = useState<string>(model.senhaTecnicaParaSistemas)

    useEffect(() => {
        setSenhaTecnica(model.senhaTecnicaParaSistemas)
    }, [model.senhaTecnicaParaSistemas])


    return (
        <AccordionSaurus
            labelPrimary="Parâmetros de Sistemas"
            tipoExpand="bold"
            noPaperRoot={false}
            heightDivider={2}
            showDivider={openAccordion}
            colorDivider={theme.palette.primary.main}
            colorExpand={theme.palette.primary.main}
            expanded={openAccordion}
            onChange={() => setOpenAccordion(prev => !prev)}
        >
            {loading && <CircularLoading tipo='FULLSIZED' />}
            <Box p={1} pr={0} display='flex' flexDirection='column' gridGap={32} width='100%'>
                {model.tipo !== EnumTipoPessoas.SoftwareHouse && <Grid container spacing={2} className={classes.sistemaContainer}>
                    <Grid item xs={12}>
                        <Box display='flex' alignItems='center'>
                            <Typography variant='h6' color='primary'>TouchOne</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider style={{ background: theme.palette.primary.main, marginBottom: 10 }} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldSaurus
                            allowSubmit={false}
                            label="Chave de Ativação"
                            readOnly
                            multiline
                            maxRows={2}
                            showStartAdornment
                            fullWidth
                            value={!hide[0] ? model.chaveAtivacao : '•••••••••••••••••••••••••••••••••••••••••••••••••••••'}
                            autoComplete={'off'}
                            endAdornmentButton={
                                <div style={{ display: 'flex', gap: 5 }}>
                                    <Tooltip title={hide[0] ? 'Mostrar' : 'Ocultar'}>
                                        <Button onClick={() => {
                                            setHide(prev => [!prev[0], prev[1]])

                                        }} style={{ color: theme.palette.primary.main }}>
                                            {hide[0] ? 'Mostrar' : 'Ocultar'}
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title='Copiar Chave de Ativação'>
                                        <Button onClick={() => {
                                            navigator.clipboard.writeText(model.chaveAtivacao)

                                        }}>
                                            <CopiarIcon tipo='BUTTON' />
                                        </Button>
                                    </Tooltip>
                                </div>
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-end" spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Button fullWidth variant='outlined' color='primary' onClick={() => setOpenQRCode(true)}>
                                    <QrCodeIcon tipo='BUTTON' />
                                    Abrir QRCode
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Tooltip title='Copiar Link para criação de conta no TouchOne'>
                                    <Button fullWidth variant='outlined' color='primary' onClick={() => {
                                        navigator.clipboard.writeText(`https://app.touchone.com.br/novo-contrato?chaveAtivacao=${model.chaveAtivacao}`)
                                        showToast('success', 'Link Copiado')
                                    }}>
                                        <LinkIcon tipo='BUTTON' />
                                        Copiar Link
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}

                <Grid container spacing={2} className={classes.sistemaContainer}>
                    <Grid item xs={12}>
                        <Box display='flex' alignItems='center'>
                            <Typography variant='h6' color='primary'>Senha Técnica</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider style={{ background: theme.palette.primary.main, marginBottom: 10 }} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldSaurus
                            value={senhaTecnica}
                            className={classes.textfieldSenhaSync}
                            label='Senha para acesso master aos sistemas'
                            tipo={hide[1] ? 'PASSWORD' : 'TEXTO'}
                            onChange={(e) => setSenhaTecnica(e.target.value)}
                            endAdornmentButton={<Tooltip title={hide[1] ? 'Mostrar' : 'Ocultar'}>
                                <Button onClick={() => {
                                    setHide(prev => [prev[0], !prev[1]])

                                }} style={{ color: theme.palette.primary.main }}>
                                    {hide[1] ? 'Mostrar' : 'Ocultar'}
                                </Button>
                            </Tooltip>}
                        />
                        <Typography variant='caption'>
                            Esta senha pode ser utilizado funções para cálculos automáticos e para o usuário não conseguir replicá-la depois do fim do acesso. Exemplos:
                        </Typography>
                        <Typography variant='caption' style={{display: 'block', marginTop: theme.spacing(1)}}>
                            Senha Syn Antiga: <span style={{fontWeight: 500}}>"@([dd] + [MM])([HH] + 10)"</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-end" spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Button fullWidth variant='contained' color='primary' onClick={() => onSubmit({
                                    ...model,
                                    senhaTecnicaParaSistemas: senhaTecnica
                                })}>
                                    <SalvarEditarIcon tipo='BUTTON_PRIMARY' />
                                    Salvar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {openAccordion && <DialogQRCode
                openned={openQRCode}
                closeDialog={() => setOpenQRCode(false)}
                value={`https://app.touchone.com.br/novo-contrato?chaveAtivacao=${model.chaveAtivacao}`}
                descricao='Leia o QRCode para ser redirecionado ao cadastro no TouchOne'
                titulo='Ativação TouchOne'
            />}
        </AccordionSaurus >
    )
}