import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePostPoliticaAceitar() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postPoliticaAceitar = useCallback(
        (id: string) =>
            invocarApi({
                url: `/${VariaveisAmbiente.apiVersion}/Politica/${id}/aceitar`,
                method: 'POST',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postPoliticaAceitar,
    };
}
