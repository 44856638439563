import { useCallback } from 'react';
import {
  ClientesIcon,
  LogoutIcon,
  SistemasIcon,
  PrecificacaoIcon,
  PlanosIcon,
  NotaFiscalIcon,
  DashboardIcon,
  TerminaisIcon,
  RevendedorIcon,
  FaturamentoIcon,
  ConfiguracaoIcon,
  DinheiroIcon,
} from '../icons';
import { EnumMenu } from 'model';
import { IconsHelperProps } from './icons-helper-props';
import { ComissoesIcon } from '../icons/comissoes-icon';
import { IndicacaoIcon } from '../icons/indicacao-icon';
import { GerenteIcon } from '../icons/gerente-icon';
import { ContratoIcon } from '../icons/contrato-icon';
import { AgenteExternoIcon } from '../icons/agente-externo-icon';
import { RepresentanteIcon } from '../icons/representante-icon';
import { ClassificacaoIcon } from '../icons/classificacao-icon';
import { LicençaIcon } from '../icons/licença-icon';
import { GraficoIcon } from '../icons/grafico-icon';
import { PerguntaIcon } from '../icons/pergunta-icon';

export const IconsHelper = (props: IconsHelperProps) => {
  const selectIcon = useCallback(() => {
    switch (props.codigoModulo) {
      case EnumMenu.MASTER_SAIR:
        return <LogoutIcon {...props} />;
      case EnumMenu.SOFTWAREHOUSE_SISTEMAS:
        return <SistemasIcon {...props} />;
      case EnumMenu.REVENDEDORES_PLANOS:
        return <PlanosIcon {...props} />;
      case EnumMenu.REVENDEDORES_CLIENTES:
        return <ClientesIcon {...props} />;
      case EnumMenu.SOFTWAREHOUSE_TABELAPRECO:
        return <PrecificacaoIcon {...props} />;
      case EnumMenu.SOFTWAREHOUSE_FORMAPAGAMENTO:
        return <DinheiroIcon {...props} />;
      case EnumMenu.REVENDEDORES_INDICACOES:
        return <IndicacaoIcon {...props} />;
      case EnumMenu.DASHBOARD:
        return <DashboardIcon {...props} />;
      case EnumMenu.REVENDEDORES_TERMINAIS:
        return <TerminaisIcon {...props} />;
      case EnumMenu.SOFTWAREHOUSE_REVENDEDORES:
        return <RevendedorIcon {...props} />;
      case EnumMenu.REVENDEDORES_CLIENTEFATURAS:
        return <FaturamentoIcon {...props} />
      case EnumMenu.REVENDEDORES_NOTASFISCAISREVENDA:
        return <NotaFiscalIcon {...props} />
      case EnumMenu.SOFTWAREHOUSE_COMISSOES:
        return <ComissoesIcon {...props} />
      case EnumMenu.REVENDEDORES_COMISSOES:
        return <ComissoesIcon {...props} />
      case EnumMenu.SOFTWAREHOUSE_GERENTES:
        return <GerenteIcon {...props} />
      case EnumMenu.REVENDEDORES_FUNCIONARIOS:
        return <ClientesIcon {...props} />
      case EnumMenu.SOFTWAREHOUSE_AGENTE_EXTERNO:
        return <AgenteExternoIcon {...props} />
      case EnumMenu.REVENDEDORES_SIMULACAO:
        return <ContratoIcon {...props} />
      case EnumMenu.SOFTWAREHOUSE_REPRESENTANTES:
        return <RepresentanteIcon {...props}/>
        case EnumMenu.REVENDEDORES_REPRESENTANTES:
          return <RepresentanteIcon {...props}/>
      case EnumMenu.CONFIGURACOES:
        return <ConfiguracaoIcon {...props} />
      case EnumMenu.SOFTWAREHOUSE_NF_REJEITADAS:
        return <NotaFiscalIcon {...props}/>
      case EnumMenu.SOFTWAREHOUSE_CLASSIFICACOES:
        return <ClassificacaoIcon {...props}/>
      case EnumMenu.REVENDEDORES_LICENÇAS:
        return <LicençaIcon {...props}/>
      case EnumMenu.REVENDEDORES_DESEMPENHO:
        return <GraficoIcon {...props}/>
      case EnumMenu.SOFTWAREHOUSE_PERGUNTAS:
        return <PerguntaIcon {...props} />
      default:
        return <></>;
    }
  }, [props]);

  return <>{selectIcon()}</>;
};
