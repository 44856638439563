import { useCallback, useState } from 'react';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles/buttons';
import { DesfazerIcon, FiltroIcon, MenuIcon, VoltarIcon } from 'views/components/icons';
import { useMenuPrincipal } from 'services/app';
import { useThemeQueries } from 'views';
import { useHistory } from 'react-router-dom';
import { MapaClientesPesquisa } from './components/mapa-clientes-pesquisa/mapa-clientes-pesquisa';
import { KeyValueModel } from 'model';
import { FiltroMapaModel } from 'model/app/forms/filtro-mapa/filtro-mapa-model';

export interface MapaClienteHeaderProps {
  titulo: string;
  sistemas: KeyValueModel[];
  onSubmit: (filtro: FiltroMapaModel) => void;
}

export const MapaClienteHeader = ({ titulo, sistemas, onSubmit }: MapaClienteHeaderProps) => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();
  const history = useHistory();

  const [openPesquisa, setOpenPesquisa] = useState<boolean>(true)

  const leftArea = useCallback(
    () =>
      isMobile ? (
        <ButtonPrivateHeader
          icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
          tooltip="Menu"
          onClick={abrirMenu}
        ></ButtonPrivateHeader>
      ) : (
        <ButtonPrivateHeader
          icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
          tooltip="Voltar"
          onClick={() => history.push('/dashboard')}
        />
      ),
    [isMobile, abrirMenu, history],
  );

  const rightArea = useCallback(
    () => titulo === "Mapa de Clientes" ? (
      <ButtonPrivateHeader
        icon={
          openPesquisa ? (
            <DesfazerIcon tipo="PRIVATE_HEADER"></DesfazerIcon>
          ) : (
            <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>
          )
        }
        tooltip={!openPesquisa ? "Pesquisar" : "Voltar"}
        onClick={() => setOpenPesquisa(!openPesquisa)}
      ></ButtonPrivateHeader>
    ) : null,
    [titulo, openPesquisa, setOpenPesquisa]
  );

  return (
    <>
      <PrivatePageHeader title={titulo} leftArea={leftArea()} rightArea={rightArea()}
       bottomArea={<MapaClientesPesquisa
        onSubmit={onSubmit}
        onCloseSearch={() => {}}
        sistemas={sistemas}
        isOpenned={openPesquisa}
      />} />
    </>
  );
};
