import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { useFormPessoaSistemaValidation } from './form-pessoa-sistema-validation';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  TextFieldSaurus,

} from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { picker } from 'utils/picker';
import { PessoaSistemaCreateModel } from 'model/api/gestao/pessoa/pessoa-sistema-create-model';
import { useGetSistemas } from 'data/api/gestao/sistemas';
import { useGetTabelaPreco } from 'data/api/gestao/tabela-preco';
import { KeyValueModel } from 'model';
import { useToastSaurus } from 'services/app';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';

interface PessoaFormModelProps extends DefaultFormProps<PessoaSistemaCreateModel> {
  revenda?: boolean;
  aberto: boolean;
  block?: boolean;
}


export const FormPessoaSistema = forwardRef<
  DefaultFormRefs<PessoaSistemaCreateModel>,
  PessoaFormModelProps
>(
  (
    { loading, onSubmit, revenda = false, aberto, block, ...props }: PessoaFormModelProps,
    ref,
  ) => {
    const { isMobile } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const { showToast } = useToastSaurus()
    const refInputNome = useRef<HTMLInputElement>(null);
    const { FormPessoaSistemaValidationYup } = useFormPessoaSistemaValidation()
    const [modelForm, setModelForm] = useState<PessoaSistemaCreateModel>(new PessoaSistemaCreateModel())
    const [sistemasMock, setSistemasMock] = useState<KeyValueModel[]>([])
    const [tabelaPrecoMock, setTabelaPrecoMock] = useState<KeyValueModel[]>([])

    const { getSistemas, carregando: carregandoGetSistemas } = useGetSistemas()
    const { getTabelaPreco, carregando: carregandoGetTabelaPreco } = useGetTabelaPreco()

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
    } = useForm<PessoaSistemaCreateModel>({
      defaultValues: { ...modelForm },
      resolver: yupResolver(FormPessoaSistemaValidationYup),
      criteriaMode: 'all',
      mode: 'onBlur' && 'onChange',
    });

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitEnviar)();
      },
      resetForm: () => {
        reset();
        if (!isMobile) refInputNome.current?.focus();
      },
      fillForm: (model: PessoaSistemaCreateModel) => {
        setModelForm({ ...model })
        reset({ ...model });
        if (!isMobile) refInputNome.current?.focus();
      },
    }));

    const carregando = loading || carregandoGetTabelaPreco || carregandoGetSistemas

    const submitEnviar = (values: PessoaSistemaCreateModel) => {
      const pessoaSistema = picker<PessoaSistemaCreateModel>(values, modelForm)
      pessoaSistema.id = modelForm.id
      pessoaSistema.pessoaId = modelForm.pessoaId

      onSubmit(pessoaSistema, modelForm);
    };

    const getSistemasWrapper = useCallback(async () => {
      const res = await getSistemas(1, '&pageSize=0&situacao=0')

      if (res.erro) throw res.erro

      if (!res.resultado) return

      const list = res.resultado.data.list

      setSistemasMock(list.map(item => {
        return new KeyValueModel(item.id, item.nome)
      }))
    }, [getSistemas])

    const getTabelaPrecoWrapper = useCallback(async () => {
      const res = await getTabelaPreco(1)

      if (res.erro) throw res.erro

      if (!res.resultado) return

      const list = res.resultado.data.list

      setTabelaPrecoMock(list.map(item => {
        return new KeyValueModel(item.id, item.nome)
      }))
    }, [getTabelaPreco])

    useEffect(() => {
      if (aberto) {
        getSistemasWrapper()
          .catch(err => showToast('error', err.message))
        getTabelaPrecoWrapper()
          .catch(err => showToast('error', err.message))
      }
    }, [aberto, getSistemasWrapper, getTabelaPrecoWrapper, showToast])

    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {carregando && props.showLoading && (
              <CircularLoading tipo="FULLSIZED" />
            )}

            <form
              onSubmit={handleSubmit(submitEnviar)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="percentualOverTabela"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={loading}
                        readOnly={block}
                        allowSubmit={false}
                        tipo="DECIMAL"
                        casasDecimais={4}
                        manterMascara
                        label="% Over Tabela"
                        fullWidth
                        autoComplete={'off'}
                        helperText={
                          touchedFields.percentualOverTabela && errors.percentualOverTabela
                            ? errors.percentualOverTabela.message
                            : undefined
                        }
                        error={Boolean(errors.percentualOverTabela && errors.percentualOverTabela.message)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="sistemaId"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        disabled={loading || block}
                        conteudo={sistemasMock}
                        allowSubmit={false}
                        label="Selecione o Sistema"
                        fullWidth
                        autoComplete={'off'}
                        helperText={
                          touchedFields.sistemaId && errors.sistemaId
                            ? errors.sistemaId.message
                            : undefined
                        }
                        error={Boolean(errors.sistemaId && errors.sistemaId.message)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="tabelaPrecoId"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        disabled={loading || block}
                        conteudo={tabelaPrecoMock}
                        allowSubmit={false}
                        label="Selecione a Tabela de Preço"
                        fullWidth
                        autoComplete={'off'}
                        helperText={
                          touchedFields.tabelaPrecoId && errors.tabelaPrecoId
                            ? errors.tabelaPrecoId.message
                            : undefined
                        }
                        error={Boolean(errors.tabelaPrecoId && errors.tabelaPrecoId.message)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
