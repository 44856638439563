import { Button, Grid } from "@material-ui/core";
import { ContratoFormModel } from "model/app/forms/contrato/contrato-form-model";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormContratoCadastroPrimeiraFase } from "views/components/form/master/contrato/contrato-cadastro/form-contrato-cadastro-primeira-fase";
import { FormSimulacaoContrato } from "views/components/form/master/simulacao-contrato/simulacao-contrato-form";
import { DefaultFormRefs } from "views/components/form/utils";
import { AvancarIcon, VoltarIcon } from "views/components/icons";
import { useThemeQueries } from "views/theme";
import { useStyles } from './simulacao-contrato-styles'
import { useGetPessoa } from "data/api/gestao/pessoa";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { isEmpty } from "lodash";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";

export const SimulacaoContrato = () => {

    const cadFormRef =
        useRef<DefaultFormRefs<ContratoFormModel>>(null);
    const pessoaIdRef = useRef<string>('')

    const {getPessoa, carregando} = useGetPessoa();

    const {isMobile} = useThemeQueries();
    const {showToast} = useToastSaurus();
    const {tipoUsuario} = useSessaoAtual();

    const [fase, setFase] = useState<number>(1)
    const [sistemaId, setSistemaId] = useState<string>('')
    const [sistemaNome, setSistemaNome] = useState<string>('')
    const [erro, setErro] = useState<string | null>(null)

    const classes = useStyles({fase})

    const handleSistema = useCallback((model: ContratoFormModel) => {
        setSistemaId(model.sistemaId)
        setFase(2)
        cadFormRef.current?.fillForm(model)
    }, [])

    const getPessoasWrapper = useCallback(async () => {
        try{
            const res = await getPessoa(1, 'pageSize=100')

            if(res.erro) throw res.erro
            if(!res.resultado) throw new Error('Erro ao carregar dados.')

            if(isEmpty(res.resultado.data.list)){
                setErro('Esse recurso está indisponível.')
                setFase(2)
                return
            }

            if(tipoUsuario() === EnumTipoPessoas.Representante){
                pessoaIdRef.current = res.resultado.data.list[0].id
            }else{
                pessoaIdRef.current = res.resultado.data.list.find(cliente => isEmpty(cliente.vendedorId))?.id || res.resultado.data.list[0].id
            }
        }catch(e: any){
            showToast('error', e.message)
        }
    }, [getPessoa, showToast, tipoUsuario])

    useEffect(() => {
        getPessoasWrapper();
    }, [getPessoasWrapper])

    return (
        <div className={classes.container}>
            <Grid container spacing={1} className={classes.card}>
                {fase < 2 && (
                    <Grid item xs={12}>
                        <FormContratoCadastroPrimeiraFase
                            loading={carregando}
                            showLoading={carregando}
                            onSubmit={handleSistema}
                            ref={cadFormRef}
                            description='Escolha um sistema para consultar os valores e custos de um contrato.'
                            revendaId=""
                            setSistemaNome={setSistemaNome}
                        />
                    </Grid>
                )}
                {fase > 1 && (
                    <FormSimulacaoContrato
                        erro={erro}
                        loading={carregando}
                        onSubmit={() => { }}
                        setErro={setErro}
                        showLoading={carregando}
                        sistemaId={sistemaId}
                        sistemaNome={sistemaNome}
                        ref={cadFormRef}
                        pessoaId={pessoaIdRef.current}
                    />
                )}
                <Grid item xs={12} className={classes.acoes}>
                    {fase > 1 && (
                        <Button variant='outlined' color='primary' onClick={() => setFase(1)} fullWidth={isMobile} disabled={!isEmpty(erro)}>
                            <VoltarIcon tipo='BUTTON' />
                            Voltar
                        </Button>
                    )}
                    {fase < 2 && (
                        <Button variant='contained' color='primary' onClick={() => cadFormRef.current?.submitForm()} fullWidth={isMobile}
                        style={{marginTop: 10}}>
                            <AvancarIcon tipo='BUTTON_PRIMARY' />
                            Continuar
                        </Button>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};
