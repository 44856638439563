import { AutorizacaoRotaModel } from 'model/app/master/autorizacao-rota';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';

export const RotasMock: AutorizacaoRotaModel[] = [
  new AutorizacaoRotaModel('/termos', [EnumTipoPessoas.Revendedor, EnumTipoPessoas.Funcionario, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto, EnumTipoPessoas.GerenteComercial]),
  new AutorizacaoRotaModel('/dashboard', [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.Revendedor, EnumTipoPessoas.GerenteComercial, EnumTipoPessoas.Funcionario,  EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto, EnumTipoPessoas.Representante, EnumTipoPessoas.FuncionarioSoftwareHouse]),
  new AutorizacaoRotaModel('/dashboard/mapa-clientes', [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.Revendedor, EnumTipoPessoas.GerenteComercial, EnumTipoPessoas.Funcionario, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto]),
  new AutorizacaoRotaModel('/sistemas', [EnumTipoPessoas.SoftwareHouse]),
  new AutorizacaoRotaModel('/planos', [EnumTipoPessoas.Revendedor, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto]),
  new AutorizacaoRotaModel('/clientes', [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FuncionarioSoftwareHouse, EnumTipoPessoas.Revendedor, EnumTipoPessoas.GerenteComercial, EnumTipoPessoas.Funcionario, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto, EnumTipoPessoas.Representante]),
  new AutorizacaoRotaModel('/revendedor', [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.GerenteComercial, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto, EnumTipoPessoas.FuncionarioSoftwareHouse]),
  new AutorizacaoRotaModel('/funcionarios', [EnumTipoPessoas.Revendedor, EnumTipoPessoas.SoftwareHouse]),
  new AutorizacaoRotaModel('/comissoes', [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.Revendedor, EnumTipoPessoas.GerenteComercial, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto]),
  new AutorizacaoRotaModel('/tabela-precos', [EnumTipoPessoas.Revendedor, EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto]),
  new AutorizacaoRotaModel('/formas-pagamento', [EnumTipoPessoas.Revendedor, EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto]),
  new AutorizacaoRotaModel('/terminais', [EnumTipoPessoas.Revendedor, EnumTipoPessoas.FuncionarioSoftwareHouse, EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto, EnumTipoPessoas.Funcionario]),
  new AutorizacaoRotaModel('/faturas', [EnumTipoPessoas.Revendedor, EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto, EnumTipoPessoas.Representante]),
  new AutorizacaoRotaModel('/licenças', [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto]),
  new AutorizacaoRotaModel('/revenda/notas-fiscais', [EnumTipoPessoas.Revendedor, EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto, EnumTipoPessoas.GerenteComercial]),
  new AutorizacaoRotaModel('/configuracoes', [EnumTipoPessoas.Revendedor, EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.GerenteComercial, EnumTipoPessoas.Funcionario, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto, EnumTipoPessoas.Representante]),
  new AutorizacaoRotaModel('/gerentes', [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto]),
  new AutorizacaoRotaModel('/indicacoes', [EnumTipoPessoas.GerenteComercial, EnumTipoPessoas.Revendedor, EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto]),
  new AutorizacaoRotaModel('/simulacao-contrato', [EnumTipoPessoas.Revendedor, EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto, EnumTipoPessoas.Representante]),
  new AutorizacaoRotaModel('/representantes', [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto, EnumTipoPessoas.Revendedor]),
  new AutorizacaoRotaModel('/agente-externo', [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto]),
  new AutorizacaoRotaModel('/notas-fiscais-rejeitadas', [EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto, EnumTipoPessoas.SoftwareHouse]),
  new AutorizacaoRotaModel('/classificacoes', [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto]),
  new AutorizacaoRotaModel('/cliente-ideal', [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto]),
  new AutorizacaoRotaModel('/desempenho-revendedores', [EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.GerenteComercial, EnumTipoPessoas.Revendedor, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto])
];
