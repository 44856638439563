import { Button, Grid } from '@material-ui/core';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import {
    SalvarNovoIcon,
    VoltarIcon,
} from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import classNames from 'classnames';
import { useCallback, useEffect, useRef } from 'react';
import { useCadastros, useToastSaurus } from 'services/app';
import { DefaultFormRefs } from 'views/components/form/utils';
import { AmbienteSistemaModel } from 'model/api/gestao/ambiente-sistema/ambiente-sistema-model';
import { usePostAmbienteSistema } from 'data/api/gestao/ambiente-sistema';
import { FormAmbiente } from 'views/components/form/master/sistemas/ambiente/form-ambiente';

type Props = {
    sistemaId: string,
}

export const AmbienteCadastro = ({ sistemaId, ...props }: Props) => {


    const classes = useModalStyles();
  
    const refForm = useRef<DefaultFormRefs<AmbienteSistemaModel>>(null)
    const refNovo = useRef<boolean>(true)
  
    const { postAmbienteSistema, carregando } = usePostAmbienteSistema()

    const { fecharCadastroAmbiente } = useCadastros()
  
    const { showToast } = useToastSaurus()
  
    useEffect(() => {
      refForm.current?.fillForm(new AmbienteSistemaModel())
    }, [])
  
  
    const saveNew = useCallback(async (model: AmbienteSistemaModel) => {
      const res = await postAmbienteSistema(sistemaId, model)
  
      if (res.erro) throw res.erro
  
      showToast('success', 'Ambiente adicionado!')
    }, [postAmbienteSistema, showToast, sistemaId])
  
    const handleSubmit = useCallback(async (model: AmbienteSistemaModel) => {
      try {
        model.sistemaId = sistemaId
          await saveNew(model)
  
        if (refNovo.current) {
          refForm.current?.resetForm()
          return
        }
        
        fecharCadastroAmbiente()
  
      }
      catch (err: any) {
        showToast('error', err.message)
      }
    }, [fecharCadastroAmbiente, saveNew, showToast, sistemaId])  


    return (
        <div className={classes.root}>
            {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
            <ModalHeader
                title='Cadastrar Ambiente'
                leftArea={
                    <ButtonModalHeader
                        tooltip="Voltar"
                        icon={<VoltarIcon tipo="MODAL_HEADER" />}
                        onClick={() => fecharCadastroAmbiente()}
                    />
                }
            />
            <div className={classes.content}>
                <div
                    className={classNames(
                        classes.contentForms,
                        carregando ? classes.contentFormsLoading : undefined,
                    )}
                >
                    <FormAmbiente
                        ref={refForm}
                        loading={carregando}
                        showLoading={carregando}
                        onSubmit={handleSubmit}
                    />
                </div>
                <div className={classes.acoes}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={7}>
                            <Button
                                disabled={carregando}
                                onClick={() => {
                                    refNovo.current = true
                                    refForm.current?.submitForm()
                                }}
                                variant="outlined"
                                color="primary"
                                size="large"
                                fullWidth
                            >
                                <SalvarNovoIcon tipo="BUTTON" />
                                Salvar e Adicionar Novo
                            </Button>
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <Button
                                disabled={carregando}
                                onClick={() => {
                                    refNovo.current = false
                                    refForm.current?.submitForm()
                                }}
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                            >
                                <SalvarNovoIcon tipo="BUTTON_PRIMARY" />
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
};