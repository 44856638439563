import { VariaveisAmbiente } from 'config';
import { PessoasMapaModel } from 'model/api/gestao/pessoa/pessoa-mapa-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetPessoaMapa() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<PessoasMapaModel[]>();

    const getPessoaMapa = useCallback(
        async (queryBusca: string) => {
            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/pessoa/mapa?${queryBusca}`,
                method: 'GET',
                enviarTokenUsuario: true,
                timeout: 60000
            })
        }
        , [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getPessoaMapa,
    };
}
