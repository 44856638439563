import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    margin: '1rem 6rem',
    [theme.breakpoints.down('md')]: {
        margin: '1rem 4rem'
    },
    [theme.breakpoints.down('sm')]: {
        margin: '1rem'
    },
    [theme.breakpoints.down('xs')]:{
      margin: '5px'
    }
  },
}));
