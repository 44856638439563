import { Button, Grid } from '@material-ui/core';
import { picker } from 'utils/picker';
import { usePostAdicionarSistema } from 'data/api/gestao/sistemas';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useRef, useCallback, useEffect } from 'react';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { useToastSaurus, useCadastros, useSessaoAtual } from 'services/app';
import { SistemaModel } from 'model/api/gestao/sistemas/sistema-model';
import {
  SalvarEditarIcon,
  SalvarNovoIcon,
  VoltarIcon,
} from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import classNames from 'classnames';
import { NovoSistemaCadastroFormModel } from 'model/app/forms/sistemas/novo-sistema-cadastro-form-model';
import { FormNovoSistemaCadastro } from 'views/components/form/master/sistemas/novo-sistema/form-novo-sistema';
import { newGuid } from 'utils/new-guid';
import { EnumRetornoApiBase } from 'data/api/base/api-base-response';
import { usePostImagemBase64 } from 'data/api/imagem/post-imagem';
import { imagemForUpload } from 'utils/imagem-for-upload';
import { isEmpty } from 'lodash';

const NovoSistemaCadastro = () => {
  const { postAdicionarSistema, carregando } = usePostAdicionarSistema();
  const { fecharCadastroNovoSistema, abrirCadastroNovoSistema } =
    useCadastros();
  const cadNovoSistemaFormRef =
    useRef<DefaultFormRefs<NovoSistemaCadastroFormModel>>(null);
  const classes = useModalStyles();
  const { usuario } = useSessaoAtual()
  const { postImagemBase64 } = usePostImagemBase64()

  const redirect = useRef<boolean>(false)

  useEffect(() => {
    cadNovoSistemaFormRef.current?.fillForm(
      new NovoSistemaCadastroFormModel(),
    );
  }, []);


  const { showToast } = useToastSaurus();

  const enviarImagem = useCallback(
    async (imagemUrl: string) => {
      let imagem = '';

      const imgUpload = imagemForUpload(imagemUrl);
      if (imgUpload.length > 0) {
        const retImagem = await postImagemBase64(
          imgUpload,
          `licenciamento/sistemas/${usuario?.Id}/`,
          newGuid(),
        );
        if (retImagem.tipoRetorno !== EnumRetornoApiBase.Sucesso) {
          throw new Error('Erro ao processar  a Imagem selecionada.');
        }
        if (retImagem.resultado?.data.data.status === 2) {
          throw new Error(
            'Erro ao processar a Imagem selecionada.Detalhe: ' +
            retImagem.resultado?.data?.data?.retorno,
          );
        }
        imagem =
          retImagem.resultado?.data?.data?.url_blob +
          '?timestamp=' +
          new Date().getTime();
      }

      return imagem.length > 0 ? btoa(imagem) : imagem;
    },
    [postImagemBase64, usuario?.Id],
  );

  const saveNewSistema = useCallback(
    async (model: SistemaModel) => {
      try {
        if (!isEmpty(model.urlImagem)) {
          model.urlImagem = await enviarImagem(model.urlImagem)
        }
        const ret = await postAdicionarSistema(model);
        if (ret.erro) {
          throw ret.erro;
        }

        showToast(
          'success',
          'Novo sistema adicionado!',
          2000,
          'bottom-center',
          60,
        );

       if(redirect.current){
        abrirCadastroNovoSistema(ret.resultado?.data.id, '', false)
        return
       }

       cadNovoSistemaFormRef.current?.resetForm();
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [postAdicionarSistema, showToast, enviarImagem, abrirCadastroNovoSistema],
  );
  const handleSubmit = useCallback(
    async (model: NovoSistemaCadastroFormModel) => {
      const NovoSistemaToCreate = picker<SistemaModel>(
        model,
        new SistemaModel(),
      );

      NovoSistemaToCreate.modulos = []

      return saveNewSistema(NovoSistemaToCreate);
    },
    [saveNewSistema],
  );

  const submitForm = useCallback(() => {
    cadNovoSistemaFormRef.current?.submitForm();
  }, []);

  const onCloseClick = useCallback(() => {
    fecharCadastroNovoSistema();
  }, [fecharCadastroNovoSistema]);

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={'Novo Sistema'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}
        >
          <FormNovoSistemaCadastro
            ref={cadNovoSistemaFormRef}
            showLoading={false}
            loading={carregando}
            onSubmit={handleSubmit}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7}>
              <Button
                disabled={carregando}
                onClick={submitForm}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarNovoIcon tipo="BUTTON" />
                Salvar e Adicionar Novo
              </Button>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Button
                disabled={carregando}
                onClick={() => {
                  redirect.current = true
                  submitForm()
                }}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                Salvar e Editar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div >
  );
};
export default NovoSistemaCadastro;
