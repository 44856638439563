import { useStyles } from './pesquisa-avancada-styles';
import {
  Button,
  Grid,
  Fade,
  useTheme,
} from '@material-ui/core';
import { useCallback } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { ProcurarIcon } from 'views/components/icons';
import { PesquisaAvancadaHeaderProps } from './pesquisa-avancada-cliente-props';
import { useSessaoAtual } from 'services/app';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';

export const PesquisaAvancadaDesempenho = (props: PesquisaAvancadaHeaderProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { tipoUsuario } = useSessaoAtual();

  const isGerente = tipoUsuario() === EnumTipoPessoas.GerenteComercial
  const isSW = tipoUsuario() === EnumTipoPessoas.SoftwareHouse

  //CHAMADA DO PESQUISAR
  const pesquisar = useCallback(
    (event: any) => {
      event.preventDefault();
      props.onPesquisar();
    },
    [props],
  );

  const changeValue = useCallback((ev: any, value?: any) => {
    ev.preventDefault()
    props.setPesquisa(prev => ({
      ...prev,
      [ev.target.name]: value || ev.target.value
    }))
  }, [props])

  const valorAnoMes = `${props.pesquisa.anoMesApuracao.toString().substring(0, 4)}-${props.pesquisa.anoMesApuracao.toString().substring(4, 6)}`

  console.log(valorAnoMes)
  return (
    <Fade in={props.isOpened} mountOnEnter unmountOnExit>
      <form onSubmit={pesquisar}>
        <Grid container className={classes.root} justifyContent='space-between' spacing={2}>
          {(isSW || isGerente) && <Grid item xs={12} md={isGerente ? 5 : 3}>
            <TextFieldSaurus
              label='Revendedor'
              name='revendaNome'
              placeholder='Nome do revendedor...'
              size='small'
              fullWidth
              onChange={changeValue}
              adornmentColor={theme.palette.text.disabled}
              value={props.pesquisa.revendaNome}
            />
          </Grid>}
          {isSW && <Grid item xs={12} md={3}>
            <TextFieldSaurus
              size='small'
              name='gerenteNome'
              label='Gerente'
              placeholder='Nome do gerente...'
              onChange={changeValue}
              adornmentColor={theme.palette.text.disabled}
              value={props.pesquisa.gerenteNome}
            />
          </Grid>}
          <Grid item xs={12} md={isGerente ? 5 : 3}>
          <TextFieldSaurus
              size='small'
              name='anoMesApuracao'
              label='Mês'
              placeholder='Nome do gerente...'
              tipo='DATA_MES_ANO'
              onChange={(e) => {
                console.log(e.target.value)
                const competencia = Number(e.target.value.replace('-', ''))
                changeValue(e, competencia)
              }}
              adornmentColor={theme.palette.text.disabled}
              value={valorAnoMes}
            />
          </Grid>
          <Grid item md={isGerente ? 2 : 3} xs={12}>
            <Button color="primary" variant="contained" type="submit" fullWidth>
              <ProcurarIcon tipo="BUTTON_PRIMARY" />
              Pesquisar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Fade >
  );
};
