import { useCallback, useState } from "react";
import { PrivatePageHeader } from "views/components/headers";
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { DesfazerIcon, FiltroIcon, MenuIcon, VoltarIcon } from "views/components/icons";
import { useMenuPrincipal } from "services/app";
import { useThemeQueries } from "views";
import { useHistory} from "react-router-dom";
import { DesempenhoRevendaSearchProps } from "../desempenho-revendas-list/desempenho-revenda-search-props";
import { PesquisaAvancadaDesempenho } from "./components/pesquisa-avancada/pesquisa-avancada-desempenho";
import { SetaCimaIcon } from "views/components/icons/seta-cima-icon";
import { useHeaderStyles } from "views/components/headers/header-private-page/header-styles";
import { Box } from "@material-ui/core";
import { dateParaCompetencia } from "utils/converter-competencia";

export interface RevendedorHeaderProps {
  openPesquisa: boolean;
  setOpenPesquisa: (state: boolean) => any;
  setSearchProps: React.Dispatch<React.SetStateAction<DesempenhoRevendaSearchProps>>;
  titulo: string,
  revendaId: string,
  mostrarFiltros: boolean,
}

export interface LocationProps {
  from: {
    pathname: string,
    page?: number;
  }
}

export const DesempenhoRevendedaHeader = ({
  openPesquisa,
  setOpenPesquisa,
  setSearchProps,
  titulo,
  revendaId,
  mostrarFiltros
}: RevendedorHeaderProps) => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();
  const history = useHistory()
  const classes = useHeaderStyles();
  const { theme } = useThemeQueries();

  const [pesquisaFiltro, setPesquisaFiltro] = useState<DesempenhoRevendaSearchProps>({
    anoMesApuracao: dateParaCompetencia(new Date(new Date().setMonth(new Date().getMonth() - 1))),
    gerenteId: '',
    gerenteNome: '',
    revendaId: '',
    revendaNome: ''
  })

  const leftArea = useCallback(
    () =>
      isMobile ? ((titulo === 'Desempenho dos Revendedores' || titulo === 'Desempenho') ?
        (
          <ButtonPrivateHeader
            icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
            tooltip="Menu"
            onClick={abrirMenu}
          ></ButtonPrivateHeader>
        ) : <ButtonPrivateHeader
          icon={<VoltarIcon tipo="PRIVATE_HEADER"></VoltarIcon>}
          tooltip="Voltar"
          onClick={() => {
            if (titulo.includes('Detalhes de')) {
              history.push('/desempenho-revendedores/' + revendaId + '/mes')
              return
            }
            history.push('/desempenho-revendedores')
          }}
        ></ButtonPrivateHeader>) : ((titulo !== 'Desempenho dos Revendedores' && titulo !== 'Desempenho')) ? (
          <ButtonPrivateHeader
            icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
            tooltip="Voltar"
            onClick={() => {
              if (titulo.includes('Detalhes de')) {
                history.push('/desempenho-revendedores/' + revendaId + '/mes')
                return
              }
              history.push('/desempenho-revendedores')
            }}
          />
        ) : null,
    [isMobile, titulo, abrirMenu, history, revendaId]
  );

  const rightArea = useCallback(
    () => (titulo === 'Desempenho dos Revendedores' || titulo === 'Desempenho') ? (
      <ButtonPrivateHeader
        icon={
          openPesquisa ? (
            <DesfazerIcon tipo="PRIVATE_HEADER"></DesfazerIcon>
          ) : (
            <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>
          )
        }
        tooltip={!openPesquisa ? "Pesquisa Avançada" : "Fechar Filtro"}
        onClick={() => setOpenPesquisa(!openPesquisa)}
      ></ButtonPrivateHeader>
    ) : null,
    [titulo, openPesquisa, setOpenPesquisa]
  );

  const closePesquisaWrapper = useCallback(() => {
    setOpenPesquisa(false);
  }, [setOpenPesquisa]);

  const onPesquisar = useCallback(
    () => {
      setSearchProps({ ...pesquisaFiltro });
    },
    [pesquisaFiltro, setSearchProps]
  );

  const pesquisaAvancada = useCallback(() => (titulo === 'Desempenho dos Revendedores' || titulo === 'Desempenho') ? (
    <PesquisaAvancadaDesempenho
      isOpened={openPesquisa}
      onCloseSearch={closePesquisaWrapper}
      onPesquisar={onPesquisar}
      pesquisa={pesquisaFiltro}
      setPesquisa={setPesquisaFiltro}

    />
  ) : <></>, [closePesquisaWrapper, onPesquisar, openPesquisa, pesquisaFiltro, titulo])

  const pesquisa = useCallback(() => (titulo === 'Desempenho dos Revendedores' || titulo === 'Desempenho') ? (
    <>
      <Box display='flex' alignItems='center'>
        <Box>
          <ButtonPrivateHeader
            className={classes.pesquisaBox}
            icon={
              openPesquisa ? (
                <SetaCimaIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main}></SetaCimaIcon>
              ) : (
                <FiltroIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main}></FiltroIcon>
              )
            }
            tooltip={!openPesquisa ? "Pesquisa Avançada" : "Fechar Filtro"}
            text={!openPesquisa ? 'Filtros' : 'Fechar Filtros'}
            onClick={() => setOpenPesquisa(!openPesquisa)}
          ></ButtonPrivateHeader>
        </Box>
      </Box>
    </>
  ) : <></>, [classes.pesquisaBox, openPesquisa, setOpenPesquisa, theme.palette.primary.main, titulo])

  return (
    <>
      <PrivatePageHeader
        title={titulo}
        leftArea={leftArea()}
        rightArea={(mostrarFiltros) && (isMobile ? rightArea() : pesquisa())}
        bottomArea={(mostrarFiltros) && pesquisaAvancada()}
      />
    </>
  );
};
