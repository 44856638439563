import { Chip, Grid, Paper, Typography, useTheme } from "@material-ui/core";
import { useThemeQueries } from "views/theme";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus";
import { PessoaClienteModel } from "model/api/gestao/cliente-ideal/pessoa-cliente-model";
import { useStyles } from "./dialog-info-respostas-styles";
import { useMemo } from "react";
import { useSessaoAtual } from "services/app";
import { podeVerPerguntasInternas } from "views/components/cliente-ideal/data/clienteIdealAccessData";

interface DialogInfoRespostasProps {
    aberto: boolean;
    close: () => void;
    respostas: (PessoaClienteModel & { interna?: boolean })[]
}


const InfoRespostaCard = (props: {
    resposta: PessoaClienteModel & { interna?: boolean }
}) => {
    const classes = useStyles({
        pontos: props.resposta.pontos
    });
    const theme = useTheme()
    const color = useMemo(() => {

        if ((props.resposta.pontos / props.resposta.maxPontos) * 100 < 40) {
            return theme.palette.error.main
        }
        if ((props.resposta.pontos / props.resposta.maxPontos) * 100 < 80) {
            return theme.palette.warning.main
        }
        return theme.palette.success.main
    }, [
        props.resposta.pontos,
        theme.palette,
        props.resposta.maxPontos
    ])
    return <Paper elevation={3} className={classes.container}>
        <Grid container alignItems="center" spacing={1} justifyContent="space-between">
            <Grid item>
                <Typography className={classes.pergunta}>
                    {
                        props.resposta.pergunta
                    }
                </Typography>
                <Typography>
                    {props.resposta.resposta}
                </Typography>
            </Grid>

            <Grid item>
                <Chip style={{
                    background: color,
                    color: theme.palette.common.white,
                    fontWeight: 'bold'
                }} label={`${props.resposta.pontos} / ${props.resposta.maxPontos}`} />

            </Grid>
        </Grid>
    </Paper>
}


export const DialogInfoRespostas = ({
    aberto,
    close,
    respostas

}: DialogInfoRespostasProps) => {

    const { isMobile } = useThemeQueries();
    const { tipoUsuario } = useSessaoAtual()


    return (
        <DialogSaurus
            aberto={aberto}
            titulo={`Cliente ideal- Detalhamento`}
            fullScreen={isMobile}
            tamanho='sm'
            isButtonTitleClose
            handleClickClose={close}
        >
            {
                respostas.length > 0 ? (
                    <Grid container spacing={1}>
                        {
                            respostas.filter((item) => {
                                if (!podeVerPerguntasInternas.includes(tipoUsuario()) && item.interna) {
                                    return false
                                } else {
                                    return true
                                }
                            }).map((item, index) => {
                                return <Grid item xs={12} key={index}>
                                    <InfoRespostaCard resposta={item} />
                                </Grid>
                            })
                        }

                    </Grid>
                ) : <Typography>
                    Nenhuma resposta registrada.
                </Typography>
            }

        </DialogSaurus>
    )
}