import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    valoresContent: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    valoresContentUltimo: {
        marginTop: theme.spacing(1)
    },
    buttonRemover: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.error.dark
        },
        "&:disabled": {
            backgroundColor: theme.palette.error.light
        },
        marginRight: 8,
    },
}))