import { Button, Grid } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useCallback, useEffect, useRef, useState } from 'react';
import { PlanoFormModel } from 'model/app/forms/plano/plano-form-model';
import { EnumSituacaoPlano } from 'model/enums/enum-status-plano';
import { PlanoModel } from 'model/api/gestao/plano/plano-model';
import { useCadastros, useToastSaurus } from 'services/app';
import { useGetPlanoById, usePutAtualizarPlano } from 'data/api/gestao/planos';
import { DefaultFormRefs } from 'views/components/form/utils';
import { FormPlanoEdicao } from 'views/components/form/master/plano/plano-edicao/form-plano-edicao';
import { SalvarEditarIcon, VoltarIcon } from 'views/components/icons';
import { MenuSaurusModel } from 'model/app/components/menu-saurus/menu-saurus-model';
import { MenuOptions } from 'views/components/menu-options/menu-options';
import { useThemeQueries } from 'views/theme';

interface DialogPlanoProps {
    openned: boolean;
    id: string;
}

export const DialogPlano = ({
    openned,
    id,
}: DialogPlanoProps) => {
    const { showToast } = useToastSaurus();
    const { isMobile } = useThemeQueries();

    const { fecharEdicaoPlano } = useCadastros();

    const { putAtualizarPlano, carregando: carregandoPut } =
        usePutAtualizarPlano();
    const { getPlanoById, carregando: carregandoGet } = useGetPlanoById();
    const [preenchendoTela, setPreechendoTela] = useState<boolean>(false);

    const carregando = carregandoGet || carregandoPut || preenchendoTela;

    const cadPlanoFormRef = useRef<DefaultFormRefs<PlanoFormModel>>(null);
    const refPlanoModel = useRef<PlanoModel>(new PlanoModel());
    const [planoForm, setPlanoForm] = useState<PlanoFormModel>(
        new PlanoFormModel(),
    );

    const recarregarForm = useCallback((model: PlanoFormModel) => {
        cadPlanoFormRef.current?.fillForm(model);
    }, []);

    useEffect(() => {
        recarregarForm(planoForm);
    }, [planoForm, recarregarForm]);

    const getPlanoByIdWrapper = useCallback(async () => {
        const res = await getPlanoById(id);

        if (res.erro) throw res.erro;

        const ret = res.resultado?.data as PlanoModel;

        refPlanoModel.current = ret;

        return ret;
    }, [getPlanoById, id]);

    useEffect(() => {
        if (openned)
            (async () => {
                try {
                    setPreechendoTela(true);
                    const plano = await getPlanoByIdWrapper();
                    setPlanoForm(plano);
                } catch (e: any) {
                    showToast('error', e.message);
                } finally {
                    setPreechendoTela(false);
                }
            })();
    }, [getPlanoByIdWrapper, showToast, id, openned]);

    const saveChangePlano = useCallback(
        async (model: PlanoFormModel) => {
            const ret = await putAtualizarPlano(model);

            if (ret.erro) {
                throw ret.erro;
            }
        },
        [putAtualizarPlano],
    );

    const changeStatus = useCallback(
        async () => {
            try {
                const status = planoForm.status === EnumSituacaoPlano.Ativado ?
                    EnumSituacaoPlano.Desativado : EnumSituacaoPlano.Ativado;

                const planoAtual = { ...planoForm, status };

                await saveChangePlano(planoAtual)

                setPlanoForm(planoAtual);

                showToast('success', 'Situação alterada com Sucesso');
            } catch (e: any) {
                showToast('error', e.message)
            }

        },
        [planoForm, saveChangePlano, showToast]
    )

    const handleSubmit = useCallback(
        async (model: PlanoFormModel, beforeModel: PlanoFormModel) => {
            try {
                // const NovoPlanoToCreate = picker<PlanoFormModel>(
                //     model,
                //     new PlanoFormModel(),
                // );
                await saveChangePlano(model);

                showToast('success', 'Plano Atualizado!');
            } catch (e: any) {
                showToast('error', e.message);
            }
        },
        [saveChangePlano, showToast],
    );

    return (
        <DialogSaurus
            aberto={openned}
            titulo="Editar Plano"
            tamanho='md'
            endAdornment={
                <>
                    <MenuOptions
                        dark
                        options={[
                            new MenuSaurusModel(planoForm.status === EnumSituacaoPlano.Ativado ?
                                'Desativar' : 'Ativar', <></>, changeStatus)
                        ]}
                    />
                </>
            }
            fullScreen={isMobile}
        >
            <Grid container spacing={2}>
                <FormPlanoEdicao
                    loading={carregando}
                    onSubmit={handleSubmit}
                    showLoading={carregando}
                    ref={cadPlanoFormRef}
                    aberto={openned}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2} justifyContent='flex-end'>
                    <Grid item md={2} xs={12}>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => fecharEdicaoPlano()}
                            fullWidth
                        >
                            <VoltarIcon tipo='BUTTON' />
                            Voltar
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Button
                            disabled={carregando}
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => cadPlanoFormRef.current?.submitForm()}
                        >
                            <SalvarEditarIcon tipo='BUTTON_PRIMARY' />
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </DialogSaurus>
    );
};
