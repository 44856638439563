import { IndicacoesEditarTabs } from "./indicacoes-editar-tabs/indicacoes-editar-tabs";

type Props = {
  id: string;
  status: string;
}

export const IndicacoesEditar = ({ id, status }: Props) => {

  return (
    <IndicacoesEditarTabs id={id} status={status} />
  );
};
