import { Box, Divider, Typography } from '@material-ui/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useThemeQueries } from 'views/theme';
import { SwitchSaurus } from 'views/components/controles/switches/switch-saurus';
import { ResultadoRevendaModel } from 'model/api/gestao/relatorios/resultado-revenda-model';
import { useStyles } from '../../../accordion-revenda/components/saldo/saldo-styles';
import ChartContratos from '../charts/chart-contratos';
import { RelatorioChartModel, RelatorioUnitarioChartModel, ativosMediaName } from 'model/api/gestao/relatorios/relatorio-model';
import { competenciaMesAnterior, subtrairCompetencia } from 'utils/converter-competencia';

interface DashboardSaldoProps {
    resultadoRevenda: ResultadoRevendaModel[];
    meses: number;
}

export default function MediaAtivosSaurus({ resultadoRevenda, meses }: DashboardSaldoProps) {
    const { theme } = useThemeQueries()

    const [stacked, setStacked] = useState<boolean>(false)
    const chartContainerRef = useRef<HTMLDivElement>(null)

    const classes = useStyles({ showOverflow: meses >= 24, chartContainerRef });

    const [dadosContratos, setDadosContratos] = useState<RelatorioChartModel[]>([])

    const tratarDados = useCallback(() => {
        try {
            let arr: RelatorioChartModel[] = [
                new RelatorioChartModel('contratosAtivosMediaSaurus', []),
                new RelatorioChartModel('cnpJsAtivosMediaSaurus', []),
                new RelatorioChartModel('terminaisAtivosMediaSaurus', [])
            ]
            const competenciaAtual = competenciaMesAnterior()

            for (let i = 0; i <= meses; i++) {
                const competenciaSubtraida = subtrairCompetencia(competenciaAtual, i)
                const foundItem = resultadoRevenda.find(element => element.anoMes === competenciaSubtraida)
                if (foundItem) {
                    arr = arr.map(element => {
                        const property = element.name as ativosMediaName

                        element.data.push(new RelatorioUnitarioChartModel(competenciaSubtraida, foundItem[property],))
                        return element
                    })
                }
            }

            setDadosContratos(arr.map(item => {
                item.data = item.data.sort((a, b) => a.x - b.x)
                switch (item.name) {
                    case 'cnpJsAtivosMediaSaurus':
                        item.name = 'Média CNPJs Ativos'
                        break;
                    case 'terminaisAtivosMediaSaurus':
                        item.name = 'Média Terminais Ativos'
                        break;
                    case 'contratosAtivosMediaSaurus':
                        item.name = 'Média Contratos Ativos'
                        break;
                }
                return item
            }))
        } catch (e) {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultadoRevenda])

    useEffect(() => {
        tratarDados()
    }, [tratarDados])


    return (
        <>
            <Box display='flex' flexDirection='column' width='100%' gridGap={theme.spacing(1)}>
                <Box display='flex' justifyContent='space-between' alignItems='center' flexWrap='wrap' gridGap={5}>
                    <Typography color='primary' variant='h6'>Média Saldo</Typography>
                    <Box display='flex' gridGap={8} alignItems='center'>
                        <Box display='flex' alignItems='center'>
                            <Typography
                                variant='caption'
                                color='textSecondary'
                            >
                                Empilhado:
                            </Typography>
                            <SwitchSaurus
                                value={stacked}
                                onChange={() => setStacked(prev => !prev)}
                                variant='LIGHT'
                            />
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <div className={classes.chartContainer} ref={chartContainerRef}>
                    <ChartContratos list={dadosContratos} stacked={stacked} width={
                        meses >= 24 ? meses * 100 : undefined
                    } />
                </div>
            </Box>
        </>
    );
}