import { Grid, Typography } from '@material-ui/core';
import { CardPessoaInfoProps } from './card-pessoa-info-props';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useCallback } from 'react';
import { EditarIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import {useStyles} from './card-pessoa-info-styles'

export const CardPessoaInfo = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardPessoaInfoProps) => {
  const classes = useDefaultCardStyles();
  const pessoaClasses = useStyles();
  const {md} = useThemeQueries()

  const tipoInformacao = useCallback(() => {
    if (model.isComercial) {
      return "Comercial"
    } else if (model.isFinanceiro) {
      return "Financeiro"
    } else {
      return "Jurídico"
    }
  }, [model.isComercial, model.isFinanceiro])

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model);
        }}
        className={pessoaClasses.card}
      >
        <Grid container spacing={md ? 1 : 2} className={`${classes.cardContent} ${pessoaClasses.gridContainer}`}>
          <Grid item xs={12} md={6} className={pessoaClasses.nome}>
            {!md && <Typography color="textPrimary" variant="caption">
              Nome
            </Typography>}
            <Typography
              color='textPrimary'
              variant="body1"
            >
              <b>{model.xNome}</b>
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography color="textPrimary" variant="caption">
              Tipo
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {tipoInformacao()}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography color="textPrimary" variant="caption">
              Telefone
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {model.telefone || 'Não informado'}
            </Typography>
          </Grid>
        </Grid>
        {!md ? <div className={pessoaClasses.editIcon}>
          <EditarIcon tipo='BUTTON' />
        </div> : null}
      </DefaultCard>
    </>
  );
};
