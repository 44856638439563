import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { PessoaWhitelabelModel } from 'model/api/gestao/pessoa/pessoa-whitelabel-model';

export function useGetPessoaWhitelabelById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<PessoaWhitelabelModel>();

    const getPessoaWhitelabelById = useCallback(
        (pessoaId: string, id: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/pessoa/${pessoaId}/Whitelabel/${id}`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getPessoaWhitelabelById,
    };
}
