import { EnumGraficosDashboard } from "model/enums/enum-graficos-dashboard";
import { useMemo, useState } from "react";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus";
import { useThemeQueries } from "views/theme";
import { RelatorioSearchPropsModel } from "model/api/gestao/relatorios/relatorio-search-props-model";
import { ResultadoRevendaModel } from "model/api/gestao/relatorios/resultado-revenda-model";
import { Grid } from "@material-ui/core";
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus";
import { GraficosSaurusMock } from "data/mocks/graficos-mock";
import TotalContratos from "./components/total-contratos/total-contratos";
import { contarMesesEntreCompetencias } from "utils/converter-competencia";
import TotalValores from "./components/total-valores/total-valores";
import TotalRegistros from "./components/total-registros/total-registros";
import MediaSaldoSaurus from "./components/media-saldo-saurus/media-saldo-saurus";
import MediaAtivosSaurus from "./components/media-ativos-saurus/media-ativos-saurus";

type Props = {
    resultadoRevenda: ResultadoRevendaModel[];
    searchProps: RelatorioSearchPropsModel;
}

export const AccordionRelatorioSaurus = ({ resultadoRevenda, searchProps, ...props }: Props) => {

    const { theme } = useThemeQueries();

    const [switchGrafico, setSwitchGrafico] = useState<EnumGraficosDashboard>(EnumGraficosDashboard.CarteiraSaurus)

    const [openAccordion, setOpenAccordion] = useState<boolean>(false)


    const displayGrafico = useMemo(() => {
        switch (switchGrafico) {
            case EnumGraficosDashboard.CarteiraSaurus:
                return (
                    <TotalContratos
                        meses={contarMesesEntreCompetencias(searchProps.anoMesInicial, searchProps.anoMesFinal)}
                        resultadoRevenda={resultadoRevenda}
                    />
                )
            case EnumGraficosDashboard.TotalValores:
                return (
                    <TotalValores
                        meses={contarMesesEntreCompetencias(searchProps.anoMesInicial, searchProps.anoMesFinal)}
                        resultadoRevenda={resultadoRevenda}
                    />
                )
            case EnumGraficosDashboard.TotalRegistros:
                return (
                    <TotalRegistros
                        meses={contarMesesEntreCompetencias(searchProps.anoMesInicial, searchProps.anoMesFinal)}
                        resultadoRevenda={resultadoRevenda}
                    />
                )
            case EnumGraficosDashboard.SaldoSaurus:
                return (
                    <MediaSaldoSaurus
                        meses={contarMesesEntreCompetencias(searchProps.anoMesInicial, searchProps.anoMesFinal)}
                        resultadoRevenda={resultadoRevenda}
                    />
                )
            case EnumGraficosDashboard.AtivosSaurus:
                return (
                    <MediaAtivosSaurus
                        meses={contarMesesEntreCompetencias(searchProps.anoMesInicial, searchProps.anoMesFinal)}
                        resultadoRevenda={resultadoRevenda}
                    />
                )

        }
    }, [resultadoRevenda, searchProps, switchGrafico])

    return (
        <>
            <AccordionSaurus
                labelPrimary="Relatórios"
                tipoExpand="bold"
                noPaperRoot={false}
                heightDivider={2}
                showDivider={openAccordion}
                colorDivider={theme.palette.primary.main}
                colorExpand={theme.palette.primary.main}
                expanded={openAccordion}
                onChange={() => setOpenAccordion(prev => !prev)}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SelectSaurus
                            label='Relatório'
                            conteudo={GraficosSaurusMock}
                            value={switchGrafico}
                            onChange={(e: any) => {
                                const item = GraficosSaurusMock.find(element => element.Key === e.target.value)
                                if (item) {
                                    setSwitchGrafico(item.Key)
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {openAccordion && displayGrafico}
                    </Grid>
                </Grid>
            </AccordionSaurus>
        </>
    );
};