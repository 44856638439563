import { KeyValueModel } from "model/api";
import { EnumCRT } from "model/enums/enum-crt";


export const CRTMock: KeyValueModel[] = [
  new KeyValueModel(EnumCRT.SimplesNacional, 'Simples Nacional'),
  new KeyValueModel(EnumCRT.SimplesNacionalExcessoSublimite, 'Simples Nacional Excesso Sub-limite'),
  new KeyValueModel(EnumCRT.RegimeNormalPresumido, 'Regime Normal Presumido'),
  new KeyValueModel(EnumCRT.RegimeNormalReal, 'Regime Normal Real'),
  new KeyValueModel(EnumCRT.MEI, 'MEI'),
  new KeyValueModel(EnumCRT.NaoSeAplica, 'Não se Aplica'),
];
