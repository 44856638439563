import { VariaveisAmbiente } from 'config';
import { AmbienteSistemaModel } from 'model/api/gestao/ambiente-sistema/ambiente-sistema-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePutAmbienteSistema() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putAmbienteSistema = useCallback(
        (sistemaId: string, ambiente: AmbienteSistemaModel) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/sistema/${sistemaId}/ambiente`,
                method: 'PUT',
                data: ambiente,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putAmbienteSistema,
    };
}
