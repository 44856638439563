import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.grey[100],
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflowX: 'hidden',
  },
  header: {
    flex: '0 1 auto',
  },
  marker: {
    backgroundColor: '#fff',
    padding: '5px',
    borderRadius: '5px',
    marginBottom: '60px',
  },
  mapContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  filter: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '7px',
    padding: '5px 7px',
    margin: '10px',
    width: '70%',
    zIndex: 1,
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    top: '15px',
    right: 0,
    left: 0,
    [theme.breakpoints.down('lg')]: {
      width: '85%',
      padding: '10px',
    },
    [theme.breakpoints.down('md')]:{
      width: '95%',
    },
    [theme.breakpoints.down('sm')]: {
      top: 'unset',
      bottom: '15px',
    },
  },
  hideButton: {
    position: 'absolute',
    right: 0,
    left: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: 2,
    width: 'fit-content',
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  revImagem: {
    alignSelf: 'center',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    marginBottom: 10
  },
  infoWindow: {
    maxWidth: '300px',
    display: 'flex',
    flexDirection: 'column'
  }
}));
