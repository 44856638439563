import { useCallback } from "react";
import { PrivatePageHeader } from "views/components/headers";
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { MenuIcon } from "views/components/icons";
import { useMenuPrincipal } from "services/app";
import { useThemeQueries } from "views";

export interface ConfiguracoesHeaderProps {
  titulo: string,
}

export interface LocationProps {
  from: {
    pathname: string
  }
}

export const ConfiguracoesHeader = ({
  titulo
}: ConfiguracoesHeaderProps) => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();

  const leftArea = useCallback(
    () =>
      isMobile ? (
        <ButtonPrivateHeader
          icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
          tooltip="Menu"
          onClick={abrirMenu}
        ></ButtonPrivateHeader>
      ) : null,
    [isMobile, abrirMenu]
  );

  return (
    <>
      <PrivatePageHeader
        title={titulo}
        leftArea={leftArea()}
      />
    </>
  );
};
