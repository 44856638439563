import { DefaultModal } from 'views/components/modals/components/default-modal';
import { AmbienteCadastro } from './components/ambiente-cadastro/ambiente-cadastro';

type Props = {
  openned: boolean;
  id: string;
}

export const AmbienteModal = ({ id, openned }: Props) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={openned || false}
      variant={"temporary"}
      anchor="right"
    >
        <AmbienteCadastro sistemaId={id}/>
    </DefaultModal>
  );
};
