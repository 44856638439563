import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    formContainer: {
    }, btnPesquisa: {
        marginLeft: theme.spacing(1),
        width: "60px",
        minWidth: "60px",
        padding: 0,
        "& svg": {
            margin: 0,
        }
    },
    modulosContainer: {
        padding: '1rem',
        borderRadius: '15px',
        background: '#f8f8f8',
        maxHeight: '300px',
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'unset',
            padding: 0
        }
    },
    camposContainer: {
        background: '#F8F8F8',
        padding: '1rem',
        borderRadius: '15px',
        boxShadow: '0px 4px 8px rgba(96, 97, 112, 0.16)',
        '& .MuiFormControl-root': {
            background: '#fff'
        }
    },
    infoCard: {
        position: 'absolute',
        left: theme.spacing(1),
        bottom: theme.spacing(1),
        padding: theme.spacing(1),
        border: theme.shape.borderRadius,
        background: theme.palette.background.paper,
        boxShadow: '0px 4px 8px rgba(96, 97, 112, 0.16)',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.divider
    },
    valorDescontoBox: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        '& span': {
            textDecoration: 'line-through',
            fontSize: 13,
            color: theme.palette.warning.dark,
            alignSelf: 'flex-start'
        }
    }
}));