import { ClienteIdealPerguntaModel } from "model/api/gestao/cliente-ideal/cliente-ideal-pergunta-model";
import { useCallback } from "react";
import { CardNaoEncontrado } from "views/components/cards";
import { CardApuracaoPergunta } from "views/components/cards/card-apuracao-pergunta/card-apuracao-pergunta";
import { InformacaoIcon } from "views/components/icons";

export interface PerguntasApuracaoListDataProps {
    list: Array<ClienteIdealPerguntaModel>
    carregando: boolean;
    onClick: (model: ClienteIdealPerguntaModel) => void;
}

export const PerguntasApuracaoListData = (props: PerguntasApuracaoListDataProps) => {

    const onClick = useCallback((model: ClienteIdealPerguntaModel) => {
        props.onClick(model)
    }, [props])

    return (
        <>
            {props.list.length === 0 && !props.carregando && (
                <CardNaoEncontrado
                    mensagem={'Nenhuma Pergunta cadastrada.'}
                    icon={<InformacaoIcon tipo="GERAL" />}
                />
            )}
            {props?.list.length > 0 &&
                props.list.map((pergunta, index) => {
                    return (
                        <CardApuracaoPergunta
                            model={pergunta}
                            onClick={onClick}
                        />
                    );
                })}
        </>
    );
};