import { Grid } from "@material-ui/core";
import { FuncionarioEdit } from "./components/funcionarios-edit/funcionarios-edit";

type Props = {
  id: string
}

export const FuncionariosEditar = ({ id }: Props) => {

  return (
    <div style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    }}>
      <Grid container spacing={1}
        style={{
          maxWidth: 1400,
          padding: 16,
        }}>
        <FuncionarioEdit id={id} />
      </Grid>
    </div>
  );
};
