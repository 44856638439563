import { Button, Grid } from '@material-ui/core';
import { picker } from 'utils/picker';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useRef, useCallback } from 'react';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { useToastSaurus, useCadastros } from 'services/app';
import {
  SalvarEditarIcon,
  SalvarNovoIcon,
  VoltarIcon,
} from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import classNames from 'classnames';
import { TabelaPrecoFormModel } from 'model/app/forms/tabela-preco/tabela-preco-form-model';
import { FormTabelaPrecoCadastro } from 'views/components/form/master/tabela-preco/tabela-preco-cadastro/form-tabela-preco-cadastro';
import { usePostAdicionarTabelaPreco } from 'data/api/gestao/tabela-preco';

export const PrecificacaoCadastro = () => {

  const { postAdicionarTabelaPreco, carregando } = usePostAdicionarTabelaPreco();
  const { fecharCadastroPrecificacao } =
    useCadastros();
  const cadFormRef = useRef<DefaultFormRefs<TabelaPrecoFormModel>>(null)
  const classes = useModalStyles();
  const redirect = useRef<boolean>(true)

  const { showToast } = useToastSaurus();

  const saveNewPrecificacao = useCallback(
    async (model: TabelaPrecoFormModel) => {

      const ret = await postAdicionarTabelaPreco(model);
      if (ret.erro) {
        throw ret.erro;
      }
      return ret.resultado?.data
    },
    [postAdicionarTabelaPreco],
  );


  const handleSubmit = useCallback(
    async (model: TabelaPrecoFormModel) => {
      try {

        const novaTabelaPraCriar = picker<TabelaPrecoFormModel>(
          model,
          new TabelaPrecoFormModel(),
        );

        novaTabelaPraCriar.sistemas = []

        const res = await saveNewPrecificacao(novaTabelaPraCriar);

        showToast('success', "Nova Nova Tabela Adicionada!")

        if (redirect.current) {
          fecharCadastroPrecificacao(`/tabela-precos/${res?.id}`)
          return
        }

        cadFormRef.current?.resetForm();

      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [fecharCadastroPrecificacao, saveNewPrecificacao, showToast]
  );


  const onSubmit = useCallback((value: boolean) => {
    redirect.current = value
    cadFormRef.current!.submitForm()
  }, [])

  const onCloseClick = useCallback(() => {
    fecharCadastroPrecificacao()
  }, [fecharCadastroPrecificacao]);

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={'Nova Tabela de Preço'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}
        >
          <FormTabelaPrecoCadastro
            ref={cadFormRef}
            showLoading={false}
            loading={carregando}
            onSubmit={handleSubmit}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
          <Grid item xs={12} lg={7}>
              <Button
                disabled={carregando}
                onClick={() => {
                  onSubmit(false)
                }}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarNovoIcon tipo="BUTTON" />
                Salvar e Adicionar Novo
              </Button>
            </Grid>
            <Grid item  xs={12} lg={5}>
              <Button
                disabled={carregando}
                onClick={() => {
                  onSubmit(true)
                }}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                Salvar e Editar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
