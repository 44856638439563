import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Grid, Button, Typography, Divider } from '@material-ui/core';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { picker } from 'utils/picker';
import { TerminalModel } from 'model/api/gestao/terminal/terminal-model';
import { toDateString } from 'utils/to-date';
import { useStyles } from './form-terminal-styles'
import { EnumTerminalStatus } from 'model/enums/enum-status-terminal';
import { useFormTerminalValidation } from './form-terminal-validation';
import { yupResolver } from '@hookform/resolvers/yup';

interface Props extends DefaultFormProps<TerminalModel> {
  changeRadio: (event: React.ChangeEvent<HTMLInputElement>) => void;
  acao: string;
  model: TerminalModel;
}

export const FormTerminal = forwardRef<
  DefaultFormRefs<TerminalModel>,
  Props
>(
  (
    { loading, onSubmit, changeRadio, model, acao, ...props }: Props,
    ref,
  ) => {
    const { isMobile } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const classes = useStyles();
    const refInputNome = useRef<HTMLInputElement>(null);
    const { theme } = useThemeQueries()
    const [modelForm, setModelForm] = useState<TerminalModel>(
      new TerminalModel(),
    );
    const { FormTerminalValidationYup } = useFormTerminalValidation(model.status === EnumTerminalStatus.Disponivel)

    const {
      handleSubmit,
      control,
      formState: { errors },
      getValues,
      reset,
    } = useForm<TerminalModel>({
      defaultValues: { ...modelForm },
      resolver: yupResolver(FormTerminalValidationYup),
      criteriaMode: 'all',
      mode: 'onBlur' && 'onChange',
    });

    const submitEnviar = (values: TerminalModel) => {
      const socio = picker<TerminalModel>(values, modelForm);
      onSubmit(socio, modelForm);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitEnviar)();
      },
      resetForm: () => {
        reset();
        if (!isMobile) refInputNome.current?.focus();
      },
      fillForm: (model: TerminalModel) => {
        setModelForm({ ...model });
        reset({ ...model });
        if (!isMobile) refInputNome.current?.focus();
      },
    }));

    return (
      <>
        <Box>
          <div className={utilClasses.formContainer}>
            <form
              onSubmit={handleSubmit(submitEnviar)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Grid container spacing={2} >
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                      <Controller
                        name="chave"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            inputRef={refInputNome}
                            readOnly={getValues('status') !== EnumTerminalStatus.Disponivel}
                            allowSubmit={false}
                            id="chave"
                            label="Chave"
                            fullWidth
                            autoComplete={'off'}
                            size='small'
                            helperText={
                              errors.chave
                                ? errors.chave.message
                                : undefined
                            }
                            error={Boolean(errors.chave && errors.chave.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Controller
                        name="tipo"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            inputRef={refInputNome}
                            readOnly
                            allowSubmit={false}
                            id="tipo"
                            label="Tipo"
                            size='small'
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.tipo
                                ? errors.tipo.message
                                : undefined
                            }
                            error={Boolean(errors.tipo && errors.tipo.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Controller
                        name="clienteDoc"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            inputRef={refInputNome}
                            readOnly
                            allowSubmit={false}
                            id="clienteDoc"
                            label="Documento"
                            tipo='CNPJ_CPF'
                            size='small'
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.clienteDoc
                                ? errors.clienteDoc.message
                                : undefined
                            }
                            error={Boolean(errors.clienteDoc && errors.clienteDoc.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Controller
                        name="clienteNome"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            inputRef={refInputNome}
                            readOnly
                            allowSubmit={false}
                            id="clienteNome"
                            label="Razão Social"
                            size='small'
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.clienteNome
                                ? errors.clienteNome.message
                                : undefined
                            }
                            error={Boolean(errors.clienteNome && errors.clienteNome.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Controller
                        name="senhaOffline"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            inputRef={refInputNome}
                            readOnly
                            allowSubmit={false}
                            id="senhaOffline"
                            label="Senha Offline"
                            size='small'
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.clienteNome
                                ? errors.clienteNome.message
                                : undefined
                            }
                            error={Boolean(errors.clienteNome && errors.clienteNome.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider style={{ background: theme.palette.divider }} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container>
                    <Grid item xs={12} className={classes.camposContainer} style={{ marginBottom: 15 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography>Máquina</Typography>
                          <Divider style={{ background: theme.palette.divider }} />
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Controller
                            name="ipLocal"
                            control={control}
                            render={({ field }) => (
                              <TextFieldSaurus
                                readOnly
                                allowSubmit={false}
                                label="IP Local"
                                size='small'
                                fullWidth
                                autoComplete={'off'}
                                helperText={
                                  errors.ipLocal
                                    ? errors.ipLocal.message
                                    : undefined
                                }
                                error={Boolean(errors.ipLocal && errors.ipLocal.message)}
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Controller
                            name="ipPublico"
                            control={control}
                            render={({ field }) => (
                              <TextFieldSaurus
                                inputRef={refInputNome}
                                readOnly
                                allowSubmit={false}
                                id="ipPublico"
                                size='small'
                                label="IP Público"
                                fullWidth
                                autoComplete={'off'}
                                helperText={
                                  errors.ipPublico
                                    ? errors.ipPublico.message
                                    : undefined
                                }
                                error={Boolean(errors.ipPublico && errors.ipPublico.message)}
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Controller
                            name="maquina"
                            control={control}
                            render={({ field }) => (
                              <TextFieldSaurus
                                readOnly
                                allowSubmit={false}
                                label="Máquina"
                                size='small'
                                fullWidth
                                autoComplete={'off'}
                                helperText={
                                  errors.maquina
                                    ? errors.maquina.message
                                    : undefined
                                }
                                error={Boolean(errors.maquina && errors.maquina.message)}
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Controller
                            name="serialHd"
                            control={control}
                            render={({ field }) => (
                              <TextFieldSaurus
                                inputRef={refInputNome}
                                readOnly
                                allowSubmit={false}
                                id="serialHd"
                                label="Nro Série HD"
                                size='small'
                                fullWidth
                                autoComplete={'off'}
                                helperText={
                                  errors.serialHd
                                    ? errors.serialHd.message
                                    : undefined
                                }
                                error={Boolean(errors.serialHd && errors.serialHd.message)}
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <Controller
                            name="sistemaOperacional"
                            control={control}
                            render={({ field }) => (
                              <TextFieldSaurus
                                readOnly
                                allowSubmit={false}
                                label="Sistema Operacinal"
                                fullWidth
                                size='small'
                                autoComplete={'off'}
                                helperText={
                                  errors.sistemaOperacional
                                    ? errors.sistemaOperacional.message
                                    : undefined
                                }
                                error={Boolean(
                                  errors.sistemaOperacional &&
                                  errors.sistemaOperacional.message,
                                )}
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider style={{ background: theme.palette.divider }} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>

                    <Grid item xs={12} className={classes.camposContainer}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography>Sistema</Typography>
                          <Divider style={{ background: theme.palette.divider }} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Controller
                            name="versao"
                            control={control}
                            render={({ field }) => (
                              <TextFieldSaurus
                                readOnly
                                allowSubmit={false}
                                label="Versão"
                                fullWidth
                                autoComplete={'off'}
                                size='small'
                                helperText={
                                  errors.versao
                                    ? errors.versao.message
                                    : undefined
                                }
                                error={Boolean(
                                  errors.versao && errors.versao.message,
                                )}
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <Controller
                            name="descricao"
                            control={control}
                            render={({ field }) => (
                              <TextFieldSaurus
                                readOnly={getValues('status') !== EnumTerminalStatus.Disponivel}
                                allowSubmit={false}
                                label="Descrição"
                                size='small'
                                fullWidth
                                autoComplete={'off'}
                                helperText={
                                  errors.descricao
                                    ? errors.descricao.message
                                    : undefined
                                }
                                error={Boolean(
                                  errors.descricao && errors.descricao.message,
                                )}
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Controller
                            name="primeiroAcesso"
                            control={control}
                            render={({ field }) => (
                              <TextFieldSaurus
                                readOnly
                                allowSubmit={false}
                                label="Primeiro Acesso"
                                size='small'
                                fullWidth
                                autoComplete={'off'}
                                helperText={
                                  errors.primeiroAcesso
                                    ? errors.primeiroAcesso.message
                                    : undefined
                                }
                                error={Boolean(errors.primeiroAcesso && errors.primeiroAcesso.message)}
                                {...field}
                                value={getValues('primeiroAcesso') ? toDateString(new Date(getValues('primeiroAcesso') + 'Z'), 'DD/MM/yyyy HH:mm') : '-'}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Controller
                            name="ultimoAcesso"
                            control={control}
                            render={({ field }) => (
                              <TextFieldSaurus
                                readOnly
                                allowSubmit={false}
                                label="Último Acesso"
                                size='small'
                                fullWidth
                                autoComplete={'off'}
                                helperText={
                                  errors.ultimoAcesso
                                    ? errors.ultimoAcesso.message
                                    : undefined
                                }
                                error={Boolean(errors.ultimoAcesso && errors.ultimoAcesso.message)}
                                {...field}
                                value={getValues('ultimoAcesso') ? toDateString(new Date(getValues('ultimoAcesso') + 'Z'), 'DD/MM/yyyy HH:mm') : '-'}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Controller
                            name="qAcessos"
                            control={control}
                            render={({ field }) => (
                              <TextFieldSaurus
                                readOnly
                                allowSubmit={false}
                                label="Total de Acessos"
                                size='small'
                                fullWidth
                                autoComplete={'off'}
                                helperText={
                                  errors.qAcessos
                                    ? errors.qAcessos.message
                                    : undefined
                                }
                                error={Boolean(errors.qAcessos && errors.qAcessos.message)}
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  });
