import { KeyValueModel } from "model/api";
import { EnumSistemas } from "model/enums/enum-sistemas";


export const SistemaWhiteLabelMock: KeyValueModel[] = [
  new KeyValueModel(EnumSistemas.PDV_APP, 'PDV APP'),
  new KeyValueModel(EnumSistemas.RETAGUARDA_APP, 'Retaguarda APP'),
  new KeyValueModel(EnumSistemas.TOUCHONE, 'Touchone'),
  new KeyValueModel(EnumSistemas.MOBILE, 'PDV Mobile'),
];

export const SistemaWhitelabelArray = [
  EnumSistemas.MOBILE,
  // EnumSistemas.RETAGUARDA_APP,
  EnumSistemas.TOUCHONE,
  EnumSistemas.PDV_APP,
] as string[]
