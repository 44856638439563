import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Grid, Button, Tooltip } from '@material-ui/core';
import { useFormPessoaComissaoValidation } from './form-pessoa-comissao-validation';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
    TextFieldSaurus,

} from 'views/components/controles/inputs';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { picker } from 'utils/picker';
import { KeyValueModel } from 'model';
import { useToastSaurus } from 'services/app';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { PessoaComissaoFormModel } from 'model/api/gestao/pessoa/pessoa-comissao-form-model';
import { useGetPessoaSistema } from 'data/api/gestao/pessoa-sistema';
import { AvisoIcon } from 'views/components/icons/aviso-icon';
import { AutocompleteComissionado } from 'views/components/controles/autocompletes/autocomplete-comissionado/autocomplete-comissionado';

interface PessoaFormModelProps extends DefaultFormProps<PessoaComissaoFormModel> {
    aberto: boolean;
    id: string;
    comissionadoNome?: string;
}


export const FormPessoaComissao = forwardRef<
    DefaultFormRefs<PessoaComissaoFormModel>,
    PessoaFormModelProps
>(
    (
        { loading, onSubmit, id, aberto, comissionadoNome, ...props }: PessoaFormModelProps,
        ref,
    ) => {
        const { isMobile } = useThemeQueries();
        const utilClasses = makeUtilClasses();
        const { showToast } = useToastSaurus()
        const refInputNome = useRef<HTMLInputElement>(null);
        const { FormPessoaComissaoValidationYup } = useFormPessoaComissaoValidation()
        const [modelForm, setModelForm] = useState<PessoaComissaoFormModel>(new PessoaComissaoFormModel())
        const [sistemasMock, setSistemasMock] = useState<KeyValueModel[]>([])
        const [isPercentual, setIsPercentual] = useState<boolean>(true)

        const { getPessoaSistema, carregando: carregandoGet } = useGetPessoaSistema()

        const {
            handleSubmit,
            control,
            formState: { errors },
            reset,
            setValue,
            getValues
        } = useForm<PessoaComissaoFormModel>({
            defaultValues: { ...modelForm },
            resolver: yupResolver(FormPessoaComissaoValidationYup),
            criteriaMode: 'all',
            mode: 'onBlur' && 'onChange',
        });

        useImperativeHandle(ref, () => ({
            submitForm: () => {
                handleSubmit(submitEnviar)();
            },
            resetForm: () => {
                reset();
                if (!isMobile) refInputNome.current?.focus();
            },
            fillForm: (model: PessoaComissaoFormModel, name?: string) => {
                setModelForm({ ...model })
                reset({ ...model });
                if (!isMobile) refInputNome.current?.focus();
            },
        }));

        const carregando = loading || carregandoGet

        const submitEnviar = (values: PessoaComissaoFormModel) => {
            const pessoaComissao = picker<PessoaComissaoFormModel>(values, modelForm)

            onSubmit(pessoaComissao, modelForm);
        };

        const getSistemasWrapper = useCallback(async () => {
            const res = await getPessoaSistema(id, 1, '&pageSize=0')

            if (res.erro) throw res.erro

            if (!res.resultado) return

            const list = res.resultado.data.list

            setSistemasMock(list.map(item => {
                return new KeyValueModel(item.sistema.id, item.sistema.nome)
            }))
        }, [getPessoaSistema, id])

        useEffect(() => {
            if (aberto) {
                getSistemasWrapper()
                    .catch(err => showToast('error', err.message))
            }
        }, [aberto, getSistemasWrapper, showToast])

        return (
            <>
                <Box my={2}>
                    <div className={utilClasses.formContainer}>
                        {carregando && props.showLoading && (
                            <CircularLoading tipo="FULLSIZED" />
                        )}

                        <form
                            onSubmit={handleSubmit(submitEnviar)}
                            className={loading ? utilClasses.controlLoading : ''}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={sistemasMock.length === 0 ? 11 : 12}>
                                    <Controller
                                        name="sistemaId"
                                        control={control}
                                        render={({ field }) => (
                                            <SelectSaurus
                                                disabled={carregando || sistemasMock.length === 0}
                                                conteudo={sistemasMock}
                                                allowSubmit={false}
                                                label="Selecione o Sistema"
                                                fullWidth
                                                autoComplete={'off'}
                                                helperText={
                                                    errors.sistemaId
                                                        ? errors.sistemaId.message
                                                        : undefined
                                                }
                                                error={Boolean(errors.sistemaId && errors.sistemaId.message)}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                {sistemasMock.length === 0 && <Grid item xs={1} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <Tooltip title='Não há sistemas disponíveis para esta revenda.'>
                                        <div>
                                            <AvisoIcon tipo='BUTTON' />
                                        </div>
                                    </Tooltip>
                                </Grid>}
                                <Grid item xs={12}>
                                    <Controller
                                        name="comissionadoId"
                                        control={control}
                                        render={({ field }) => (
                                            <AutocompleteComissionado
                                                label='Comissionado'
                                                fetchOnLoad
                                                value={getValues('comissionadoId')}
                                                query={comissionadoNome}
                                                onChange={(e) => {
                                                    setValue('comissionadoId', e.value.id)
                                                }}
                                                error={Boolean(errors.comissionadoId)}
                                                helperText={errors.comissionadoId ? errors.comissionadoId.message : undefined}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="isPercentual"
                                        control={control}
                                        render={({ field }) => (
                                            <SelectSaurus
                                                disabled={loading}
                                                conteudo={[
                                                    new KeyValueModel(true, 'Porcentagem %'),
                                                    new KeyValueModel(false, 'Valor Fixo')
                                                ]}
                                                allowSubmit={false}
                                                label="Tipo de Cálculo"
                                                fullWidth
                                                autoComplete={'off'}
                                                helperText={
                                                    errors.isPercentual
                                                        ? errors.isPercentual.message
                                                        : undefined
                                                }
                                                error={Boolean(errors.isPercentual && errors.isPercentual.message)}
                                                {...field}
                                                onChange={(e: any) => {
                                                    setValue('isPercentual', e.target.value)
                                                    setIsPercentual(e.target.value)
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="valorComissao"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                label='Valor da Comissão'
                                                tipo='DECIMAL'
                                                positivo
                                                fullWidth
                                                casasDecimais={4}
                                                showStartAdornment={!isPercentual}
                                                manterMascara
                                                helperText={
                                                    errors.valorComissao
                                                        ? errors.valorComissao.message
                                                        : undefined
                                                }
                                                error={Boolean(errors.valorComissao && errors.valorComissao.message)}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Button style={{ display: 'none' }} type="submit"></Button>
                        </form>
                    </div>
                </Box>
            </>
        );
    },
);
