import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  TextFieldSaurus,

} from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { useSessaoAtual } from 'services/app';
import { picker } from 'utils/picker';
import { PessoaPostModel } from 'model/api/gestao/pessoa/pessoa-post-model';
import { isEmpty } from 'lodash';
import { useFormFuncionarioCadastroValidation } from './form-funcionario-validation';
import { guidEmpty } from 'utils/guid-empty';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { FuncionarioMock } from 'data/mocks/funcionarios-mock';

interface PessoaFormModelProps extends DefaultFormProps<PessoaPostModel> {
  isRevendedor?: boolean;
}

export const FormFuncionarioCadastro = forwardRef<
  DefaultFormRefs<PessoaPostModel>,
  PessoaFormModelProps
>(
  (
    { loading, onSubmit, isRevendedor, ...props }: PessoaFormModelProps,
    ref,
  ) => {
    const { isMobile } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);
    const { FormFuncionarioCadastroValidationYup } = useFormFuncionarioCadastroValidation(isRevendedor)
    // const [pessoaMock, setPessoaMock] = useState<KeyValueModel[]>([])
    const [modelForm, setModelForm] = useState<PessoaPostModel>(new PessoaPostModel())
    const { usuario, tipoUsuario } = useSessaoAtual()

    const {
      handleSubmit,
      control,
      formState: { errors },
      reset,
    } = useForm<PessoaPostModel>({
      resolver: yupResolver(FormFuncionarioCadastroValidationYup),
      defaultValues: modelForm,
      criteriaMode: 'all',
      mode: 'onBlur' && 'onChange',
    });

    const carregando =
      loading

    const submitAlterarDadosConta = (values: PessoaPostModel) => {
      const pessoa = picker<PessoaPostModel>(values, new PessoaPostModel())
      pessoa.id = guidEmpty();
      onSubmit(pessoa);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitAlterarDadosConta)();
      },
      resetForm: () => {
        reset();
        if (!isMobile) refInputNome.current?.focus();
      },
      fillForm: (model: PessoaPostModel) => {
        if (isRevendedor && tipoUsuario() !== EnumTipoPessoas.SoftwareHouse) {
          model.revendaId = usuario?.Id || ''
        } else if (isEmpty(model.revendaId)) {
          model.revendaId = null
        }
        setModelForm(model)
        reset({ ...model });
        if (!isMobile) refInputNome.current?.focus();
      },
    }));

    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {carregando && props.showLoading && (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="FULLSIZED" />
              </div>
            )}
            <form
              onSubmit={handleSubmit(submitAlterarDadosConta)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <Controller
                    name="cpfCnpj"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        inputRef={refInputNome}
                        disabled={loading}
                        allowSubmit={false}
                        tipo="CPF"
                        label="CPF"
                        fullWidth
                        manterMascara
                        autoComplete={'off'}
                        helperText={
                          errors.cpfCnpj
                            ? errors.cpfCnpj.message
                            : undefined
                        }
                        error={Boolean(errors.cpfCnpj && errors.cpfCnpj.message)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="nomeComercial"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={loading}
                        allowSubmit={false}
                        id="nome"
                        label="Nome"
                        fullWidth
                        autoComplete={'off'}
                        helperText={
                          errors.nomeComercial
                            ? errors.nomeComercial.message
                            : undefined
                        }
                        error={Boolean(errors.nomeComercial && errors.nomeComercial.message)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="tipo"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        disabled={loading}
                        conteudo={FuncionarioMock}
                        fullWidth
                        variant="outlined"
                        label='Tipo de Funcionário'
                        helperText={
                          errors.tipo
                            ? errors.tipo.message
                            : undefined
                        }
                        error={Boolean(errors.tipo && errors.tipo.message)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
