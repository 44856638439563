import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { useThemeQueries } from 'views/theme';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useStyles } from './card-desempenho-revenda-styles'
import { CardRevendaDesempenhoProps } from './card-desempenho-revenda-props';
import { useCallback, useMemo } from 'react';
import { toDecimalString } from 'utils/to-decimal';
import { useSessaoAtual } from 'services/app';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import classNames from 'classnames';
import { toCurrency } from 'utils/toCurrency';
import { TipoAvaliacaoDesempenhoMock } from 'data/mocks/tipo-avaliacao-desempenho-mock';
import { InformacaoIcon } from 'views/components/icons';
import { toDateString } from 'utils/to-date';
import { competenciaParaDate } from 'utils/converter-competencia';
import { DetalharIcon } from 'views/components/icons/detalhar-icon';
import { EnumTipoAvaliacaoDesempenho } from 'model/enums/enum-tipo-avaliacao-desempenho';

export const CardDesempenhoRevenda = ({
    model,
    onClick
}: CardRevendaDesempenhoProps) => {
    const { tipoUsuario } = useSessaoAtual();
    const isRevenda = tipoUsuario() === EnumTipoPessoas.Revendedor

    const classes = useDefaultCardStyles();
    const listClasses = useStyles({ isRevenda });
    const { theme, isMobile } = useThemeQueries();

    const retornarClasseSaldo = useCallback((saldo: number) => {
        if (saldo === 0) return ''
        if (saldo > 0) return listClasses.saldoPositivo
        return listClasses.saldoNegativo
    }, [listClasses.saldoNegativo, listClasses.saldoPositivo])

    const retornarAvaliacaoDesempenho = useCallback(() => {
        const valueAvaliaDesempenho = TipoAvaliacaoDesempenhoMock.find(element => element.Key === model.avaliarDesempenhoRevenda)?.Value
        return valueAvaliaDesempenho
    }, [model.avaliarDesempenhoRevenda])

    const retornarTooltip = useCallback(() => {
        switch (model.curva) {
            case 'A':
                return {
                    color: theme.palette.success.main,
                    title: 'Curva A'
                }
            case 'B':
                return {
                    color: theme.palette.success.dark,
                    title: 'Curva B'
                }
            case 'C':
                return {
                    color: theme.palette.warning.main,
                    title: 'Curva C'
                }
            case 'D':
                return {
                    color: theme.palette.warning.dark,
                    title: 'Curva D'
                }
            default:
                return {
                    color: theme.palette.error.main,
                    title: 'Curva E'
                }
        }
    }, [model.curva, theme.palette.error.main, theme.palette.success.dark, theme.palette.success.main, theme.palette.warning.dark, theme.palette.warning.main])


    //REGRAS PARA MOSTRAR O DESCONTO
    const avaliaDesempenho = model.avaliarDesempenhoRevenda !== EnumTipoAvaliacaoDesempenho.NaoAvalia
    const mostrarInfo = model.descontoAtual < model.desconto || tipoUsuario() === EnumTipoPessoas.SoftwareHouse
    // const mostrarDesconto = model.desconto !== model.descontoAtual && model.avaliarDesempenhoRevenda === EnumTipoAvaliacaoDesempenho.NaoAvalia

    const descontoField = useMemo(() => (
        <div>
            <Typography color="textPrimary" variant="caption">
                {avaliaDesempenho ? 'Desconto' : 'Desconto Atual'}
            </Typography>
            <Typography
                color="textPrimary"
                variant="body1"
                className={classNames(listClasses.textIcon, listClasses.semiBold)}
            >
                {toDecimalString(Math.abs(model.descontoAtual))}%
                {(mostrarInfo || avaliaDesempenho) && <InformacaoIcon class={listClasses.miniIcon} tipo='GERAL' />}
            </Typography>
        </div>
    ), [avaliaDesempenho, listClasses.miniIcon, listClasses.semiBold, listClasses.textIcon, model.descontoAtual, mostrarInfo])

    const tooltipDesconto = useMemo(() => (
        <Tooltip
            title={(
                <div style={{ display: 'flex', flexDirection: 'column', background: theme.palette.background.paper }}>
                    <div>
                        <Typography variant='caption' color='textPrimary'>Desconto Atual: </Typography>
                        <Typography variant='caption' color='textPrimary' className={
                            listClasses.semiBold
                        }>{Math.abs(model.descontoAtual)}%</Typography>
                    </div>
                    <div>
                        <Typography variant='caption' color='textPrimary'>Desconto Correto por Tabela: </Typography>
                        <Typography variant='caption' color='textPrimary' className={
                            listClasses.semiBold
                        }>{Math.abs(model.desconto)}%</Typography>
                    </div>
                    <div style={{ paddingTop: theme.spacing(1) }}>
                        <Typography variant='caption' color='textPrimary'>
                            Atenção o desconto atual está sendo oferecido como cortesia, assim que possível faça mais pontos para manter o desconto acordado.
                        </Typography>
                    </div>
                </div>
            )}
            classes={listClasses}>
                {descontoField}
        </Tooltip>
    ), [descontoField, listClasses, model.desconto, model.descontoAtual, theme])

    return (
        <>
            <DefaultCard
                onClick={() => {
                    onClick(model.revendaId);
                }}
            >
                {tipoUsuario() === EnumTipoPessoas.SoftwareHouse && <Tooltip title={retornarTooltip().title} placement='right'>
                    <div style={{
                        backgroundColor: retornarTooltip().color,
                        width: '5px',
                        borderRadius: '5px',
                        alignSelf: 'stretch',
                        margin: '7px'
                    }} />
                </Tooltip>}
                <div className={`${classes.cardContent} ${listClasses.container}`}>
                    <Grid container spacing={isMobile ? 1 : 2} justifyContent='space-between'>
                        <Grid item xs={4} md={3} lg={2}>
                            <Typography color="textPrimary" variant="caption">
                                Revendedor
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                className={listClasses.semiBold}
                            >
                                {model.revenda}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={3} lg={2}>
                            <Typography color="textPrimary" variant="caption">
                                Gerente
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                className={listClasses.semiBold}
                            >
                                {model.gerente}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={3} lg={1}>
                            <Typography color="textPrimary" variant="caption">
                                <Box display='flex' gridGap={5}>
                                    Meses Ativo
                                </Box>
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {model.mesesAtivos} Meses
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={3} lg={1} className={listClasses.center}>
                            <Tooltip title={
                                <div>
                                    <Typography variant='caption' color='textPrimary'>Média: </Typography>
                                    <Typography variant='caption' color='textPrimary' className={listClasses.bold}>{model.pontosMedia}</Typography>
                                </div>
                            } classes={listClasses}>
                                <div>
                                    <Typography color="textPrimary" variant="caption">
                                        <Box display='flex' gridGap={5}>
                                            Pontos
                                        </Box>
                                    </Typography>
                                    <Typography
                                        color="textPrimary"
                                        variant="body1"
                                        className={listClasses.textIcon}
                                    >
                                        {model.pontosAcumulado}
                                        <InformacaoIcon class={listClasses.miniIcon} tipo='GERAL' />
                                    </Typography>
                                </div>
                            </Tooltip>

                        </Grid>
                        <Grid item xs={4} md={3} lg={isRevenda ? 2 : 'auto'} className={isRevenda ? listClasses.centerTerminais : undefined}>
                            <Tooltip
                                title={(
                                    <div style={{ display: 'flex', flexDirection: 'column', background: theme.palette.background.paper }}>
                                        <div>
                                            <Typography variant='caption' color='textPrimary'>Terminais Adicionados: </Typography>
                                            <Typography variant='caption' color='textPrimary' className={
                                                classNames(listClasses.semiBold, retornarClasseSaldo(1))
                                            }>{model.terminaisAdicionados}</Typography>
                                        </div>
                                        <div>
                                            <Typography variant='caption' color='textPrimary'>Terminais Desativados: </Typography>
                                            <Typography variant='caption' color='textPrimary' className={
                                                classNames(listClasses.semiBold, retornarClasseSaldo(-1))
                                            }>{model.terminaisDesativados}</Typography>
                                        </div>
                                    </div>
                                )}
                                classes={listClasses}>
                                <div>
                                    <Typography color="textPrimary" variant="caption">
                                        Saldo de Terminais
                                    </Typography>
                                    <Typography
                                        color="textPrimary"
                                        variant="body1"
                                        className={classNames(
                                            listClasses.bold,
                                            retornarClasseSaldo(model.terminaisSaldo),
                                            listClasses.textIcon
                                        )}
                                    >
                                        {model.terminaisSaldo}
                                        <InformacaoIcon class={listClasses.miniIcon} tipo='GERAL' />
                                    </Typography>
                                </div>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={4} md={3} lg={isRevenda ? 2 : 'auto'}>
                            <Tooltip
                                title={(
                                    <div style={{ display: 'flex', flexDirection: 'column', background: theme.palette.background.paper }}>
                                        <div>
                                            <Typography variant='caption' color='textPrimary'>Clientes Adicionados: </Typography>
                                            <Typography variant='caption' color='textPrimary' className={
                                                classNames(listClasses.semiBold, retornarClasseSaldo(1))
                                            }>{model.cnpjAdicionados}</Typography>
                                        </div>
                                        <div>
                                            <Typography variant='caption' color='textPrimary'>Clientes Desativados: </Typography>
                                            <Typography variant='caption' color='textPrimary' className={
                                                classNames(listClasses.semiBold, retornarClasseSaldo(-1))
                                            }>{model.cnpjDesativados}</Typography>
                                        </div>
                                    </div>
                                )}
                                classes={listClasses}>
                                <div>
                                    <Typography color="textPrimary" variant="caption">
                                        Saldo de Clientes
                                    </Typography>
                                    <Typography
                                        color="textPrimary"
                                        variant="body1"
                                        className={classNames(
                                            listClasses.bold,
                                            retornarClasseSaldo(model.cnpjSaldo),
                                            listClasses.textIcon
                                        )}
                                    >
                                        {model.cnpjSaldo}
                                        <InformacaoIcon class={listClasses.miniIcon} tipo='GERAL' />
                                    </Typography>
                                </div>
                            </Tooltip>
                        </Grid>
                        {!isRevenda ? <Grid item xs={4} md={3} lg={1}>
                            <Tooltip title={
                                <div style={{ display: 'flex', flexDirection: 'column', background: theme.palette.background.paper }}>
                                    <div>
                                        <Typography variant='caption' color='textPrimary'>Fatura Inicial: </Typography>
                                        <Typography variant='caption' color='textPrimary' className={listClasses.bold}>{toCurrency(model.faturaInicial)}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant='caption' color='textPrimary'>Última Fatura: </Typography>
                                        <Typography variant='caption' color='textPrimary' className={listClasses.bold}>{toCurrency(model.faturaUltima)}</Typography>
                                    </div>
                                </div>
                            } classes={listClasses}>
                                <div>
                                    <Typography color="textPrimary" variant="caption">
                                        Crescimento
                                    </Typography>
                                    <Typography
                                        color="textPrimary"
                                        variant="body1"
                                        className={classNames(
                                            listClasses.semiBold,
                                            retornarClasseSaldo(model.faturaCrescimento),
                                            listClasses.textIcon
                                        )}
                                    >
                                        {model.faturaCrescimento}%
                                        <InformacaoIcon class={listClasses.miniIcon} tipo='GERAL' />
                                    </Typography>
                                </div>
                            </Tooltip>
                        </Grid> : null}
                        <Grid item xs={4} md={3} lg={isRevenda ? 2 : 'auto'} className={classNames(listClasses.descontoBox)}>
                            {(avaliaDesempenho || mostrarInfo) ? (
                                tooltipDesconto
                            ) : (
                                descontoField
                            )}
                        </Grid>
                    </Grid>
                </div>
                <Box display='flex'>
                    {tipoUsuario() === EnumTipoPessoas.SoftwareHouse && <Tooltip arrow title={
                        <div style={{ display: 'flex', flexDirection: 'column', background: theme.palette.background.paper }}>
                            <div>
                                <Typography variant='caption' color='textPrimary'>Avalia Desempenho: </Typography>
                                <Typography variant='caption' color='textPrimary' className={listClasses.bold}>{retornarAvaliacaoDesempenho()}</Typography>
                            </div>
                            {model.dataPrimeiroPagamento ? <div>
                                <Typography variant='caption' color='textPrimary'>Data do Primeiro Pagamento: </Typography>
                                <Typography variant='caption' color='textPrimary' className={listClasses.bold}>{toDateString(competenciaParaDate(model.dataPrimeiroPagamento), 'MM/yyyy')}</Typography>
                            </div> : null}
                        </div>
                    } classes={listClasses}>
                        <div className={classes.cardRightButton} style={{ margin: '0px 0px 0px 10px' }}>
                            <InformacaoIcon tipo="BUTTON" fill={theme.palette.primary.main} />
                        </div>
                    </Tooltip>}
                    <Tooltip title='Clique para ver mais detalhes.'>
                        <div className={classes.cardRightButton} style={{ margin: '0px 0px 0px 10px' }}>
                            <DetalharIcon tipo="BUTTON" fill={theme.palette.primary.main} />
                        </div>
                    </Tooltip>
                </Box>
            </DefaultCard>
        </>
    );
};