import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { Button, Divider, Grid } from '@material-ui/core';
import { RelatorioRevendaModel } from 'model/api/gestao/relatorios/relatorio-revenda-model';
import { CardRelatorioRevenda } from 'views/components/cards/card-relatorio-revendas/card-relatorio-revendas';
import { RetornarIcon } from 'views/components/icons/retornar-icon';
import { useThemeQueries } from 'views/theme';
import { useMemo, useState } from 'react';
import { AddIcon } from 'views/components/icons/add-icon';

export interface RelatorioRevendasListProps {
  list: Array<RelatorioRevendaModel>;
  carregando: boolean;
}

export const RevendasRelatorioListData = (props: RelatorioRevendasListProps) => {

  const { theme } = useThemeQueries()

  const [desbloquearMais, setDesbloquearMais] = useState<boolean>(false)

  const list = useMemo(() => {
    if (desbloquearMais) {
      return props.list
    }

    return props.list.slice(0, 30)
  }, [desbloquearMais, props.list])

  return (
    <>
      {(props.list.length === 0 || props.carregando) && (
        <Grid item xs={12}>
          <CardNaoEncontrado
            mensagem={props.carregando ? 'Carregando...' : 'Nenhum relatório encontrado.'}
            icon={props.carregando ? <RetornarIcon tipo='GERAL' /> : <InformacaoIcon tipo="GERAL" />}
          />
        </Grid>
      )}
      {list.length > 0 &&
        <>
          {list.map((revenda, index) => {
            return (
              <>
                {index === 20 && <Grid item xs={12}>
                  <Divider style={{ background: theme.palette.divider }} />
                </Grid>}
                <Grid item xs={12}>
                  <CardRelatorioRevenda
                    model={revenda}
                    index={index + 1}
                  />
                </Grid>
              </>
            );
          })}
          {!desbloquearMais && <Grid item xs={12}>
            <Button variant='contained' color='primary' fullWidth onClick={() => setDesbloquearMais(true)}>
              <AddIcon tipo='BUTTON_PRIMARY' />
              Mostrar Todos
            </Button>
          </Grid>}
        </>
      }
    </>
  );
};
