import { VariaveisAmbiente } from 'config';
import { ContratoModel } from 'model/api/gestao/contrato/contrato-model';
import { ContratoFormModel } from 'model/app/forms/contrato/contrato-form-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePostAdicionarContrato() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ContratoModel>();

    const postAdicionarContrato = useCallback(
        (contrato: ContratoFormModel) =>
            invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/Contrato`,
                data: contrato,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postAdicionarContrato,
    };
}
