import { Divider, Grid, Typography } from "@material-ui/core"
import { SituacaoMock } from "data/mocks/situacao-mock"
import { FaturasModel } from "model/api/gestao/fatura/faturas-model"
import { FaturaFormModel } from "model/app/forms/fatura/fatura-form-model"
import { EnumSituacao } from "model/enums/enum-situacao"
import { forwardRef, useCallback } from "react"
import { formatarCPFCNPJ } from "utils/cpfcnpj-format"
import { DefaultFormRefs } from "views/components/form/utils"
import { useThemeQueries } from "views/theme"
import { useStyles } from '../consulta-fatura-styles'

interface Props {
    model: FaturasModel
    carregando: boolean
}

export const ConsultaFaturaInfo = forwardRef<
    DefaultFormRefs<FaturaFormModel>,
    Props
>(({ model, carregando }: Props, ref) => {

    const classes = useStyles()
    const { theme } = useThemeQueries()

    const retornarCorStatus = useCallback((): string => {
        switch (model.situacao) {
            case EnumSituacao.Aberta:
                return theme.palette.info.main
            case EnumSituacao.Paga:
                return theme.palette.success.main
            case EnumSituacao.Atraso:
                return theme.palette.warning.main
            case EnumSituacao.Bonificada:
                return '#D7096C'
            case EnumSituacao.Cancelada:
                return theme.palette.error.main
            case EnumSituacao.Fechada:
                return '#8346a6'
        }
        return theme.palette.info.main
    }, [model.situacao, theme.palette.error.main, theme.palette.info.main, theme.palette.success.main, theme.palette.warning.main]);
    const valorTotal = model.valor + model.encargo + model.multaJuros

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} style={{ width: '100%' }}>
                <div style={{
                    display: 'flex',
                    width: '100%'
                }}>
                    <div style={{
                        width: '100%',
                        marginRight: 8
                    }}>
                        <div style={{
                            padding: '12px 4px',
                            width: '100%',
                            border: retornarCorStatus() + ' 2px solid',
                            textAlign: 'center',
                            color: retornarCorStatus(),
                            fontWeight: 600,
                            borderRadius: 4,
                        }}>
                            {SituacaoMock.find(item => item.Key === model.situacao)?.Value}
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='caption' color="primary">
                    Descrição
                </Typography>
                <Typography variant='body1'>
                    {model.descricao}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='caption' color="primary">
                    Nosso Número
                </Typography>
                <Typography variant='body1'>
                    {model.nossoNumero}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>

            {/*----------------------------- Valores --------------------------------*/}

            <Grid item xs={12}>
                <Typography variant='body2' color="primary">
                    Valores
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.valoresContent}>
                    <Typography variant='body2' color="textSecondary">
                        Encargos
                    </Typography>
                    <Typography variant='body2' color="textSecondary">
                         {model.encargo.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                    </Typography>
                </div>
                <div className={classes.valoresContent}>
                    <Typography variant='body2' color="textSecondary">
                        Multa e Juros
                    </Typography>
                    <Typography variant='body2' color="textSecondary">
                         {model.multaJuros.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                    </Typography>
                </div>
                <div className={classes.valoresContent}>
                    <Typography variant='body2' color="textSecondary">
                        Valor
                    </Typography>
                    <Typography variant='body2' color="textSecondary">
                         {model.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                    </Typography>
                </div>
                <div className={`${classes.valoresContent} ${classes.valoresContentUltimo}`}>
                    <Typography variant='body2' color="textPrimary" style={{
                        fontWeight: 600
                    }}>
                        Valor Total
                    </Typography>
                    <Typography variant='body2' color="textPrimary" style={{
                        fontWeight: 600
                    }}>
                         {valorTotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>

            {/*----------------------------- Beneficiário --------------------------------*/}

            <Grid item xs={12}>
                <Typography variant="body2" color="primary">
                    Beneficiário
                </Typography>
            </Grid>
            <Grid item xs={7}>
                <Typography variant="caption" color="primary">
                    Nome
                </Typography>
                <Typography>
                    {model.beneficiarioNome}
                </Typography>
            </Grid>
            <Grid item xs={5} style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row-reverse'
            }}>
                <div>
                    <Typography variant="caption" color="primary">
                        CPF/CNPJ
                    </Typography>
                    <Typography>
                        {formatarCPFCNPJ(model.beneficiarioCpfCnpj)}
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>

            {/*----------------------------- Pagador --------------------------------*/}

            <Grid item xs={12}>
                <Typography variant="body2" color="primary">
                    Pagador
                </Typography>
            </Grid>
            <Grid item xs={7}>
                <Typography variant="caption" color="primary">
                    Nome
                </Typography>
                <Typography>
                    {model.pagadorNome}
                </Typography>
            </Grid>
            <Grid item xs={5} style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row-reverse'
            }}>
                <div>
                    <Typography variant="caption" color="primary">
                        CPF/CNPJ
                    </Typography>
                    <Typography>
                        {formatarCPFCNPJ(model.pagadorCpfCnpj)}
                    </Typography>
                </div>
            </Grid>
        </Grid>
    )
})