import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { useFormTabelaPrecoValidation } from './form-tabela-preco-validation';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  TextFieldSaurus,

} from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TabelaPrecoFormModel } from 'model/app/forms/tabela-preco/tabela-preco-form-model';
import { AutocompleteVendedor } from 'views/components/controles/autocompletes/autocomplete-vendedor/autocomplete-vendedor';
import { useSessaoAtual } from 'services/app';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';

export const FormTabelaPrecoCadastro = forwardRef<
  DefaultFormRefs<TabelaPrecoFormModel>,
  DefaultFormProps<TabelaPrecoFormModel>
>(
  (
    { loading, onSubmit, ...props }: DefaultFormProps<TabelaPrecoFormModel>,
    ref,
  ) => {
    const { isMobile } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);
    const { FormTabelaPrecoValidationYup } = useFormTabelaPrecoValidation()

    const { tipoUsuario, usuario } = useSessaoAtual();


    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
      getValues,
      setValue
    } = useForm<TabelaPrecoFormModel>({
      resolver: yupResolver(FormTabelaPrecoValidationYup),
      criteriaMode: 'all',
      mode: 'onBlur' && 'onChange',
    });

    const submitAlterarDadosConta = (values: TabelaPrecoFormModel) => {
      onSubmit(values);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitAlterarDadosConta)();
      },
      resetForm: () => {
        reset(new TabelaPrecoFormModel());
        if (!isMobile) refInputNome.current?.focus();
      },
      fillForm: (model: TabelaPrecoFormModel) => {
        reset({ ...model });
        if (!isMobile) refInputNome.current?.focus();
      },
    }));

    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {loading && props.showLoading ? (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="NORMAL" />
              </div>
            ) : null}

            <form
              onSubmit={handleSubmit(submitAlterarDadosConta)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Box my={3}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Controller
                      name="nome"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          inputRef={refInputNome}
                          disabled={loading}
                          allowSubmit
                          id="nome"
                          label="Nome da Tabela"
                          fullWidth
                          autoComplete={'off'}
                          helperText={
                            touchedFields.nome && errors.nome
                              ? errors.nome.message
                              : undefined
                          }
                          error={Boolean(errors.nome && errors.nome.message)}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  {tipoUsuario() !== EnumTipoPessoas.SoftwareHouse && (
                    <Grid item xs={12}>
                      <Controller
                        name="vendedorId"
                        control={control}
                        render={({ field }) => (
                          <AutocompleteVendedor
                            label='Vendedor (Opcional)'
                            revendedorId={usuario?.Id}
                            value={getValues('vendedorId')}
                            fetchOnLoad
                            onChange={(e: any) => {
                              setValue('vendedorId', e.value.id)
                            }}
                            helperText={
                              'Vincule um vendedor que poderá usar essa tabela de preços.'
                            }
                            error={Boolean(errors.vendedorId && errors.vendedorId.message)}
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
