
import { useMemo } from 'react';
import * as Yup from 'yup';


export const useFormTerminalValidation = (disponivel: boolean) => {

  const FormTerminalValidationYup = useMemo(() => {
    if (!disponivel) return Yup.object().shape({

    })

    return Yup.object().shape({
      chave: Yup.string().required('Chave é obrigatória').typeError('Chave é obrigatória.'),
      descricao: Yup.string().required('Descrição é obrigatório').typeError('Descrição é obrigatório.'),
    });
  }, [disponivel])

  return {
    FormTerminalValidationYup,
  }
}
