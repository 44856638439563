
import { useMemo } from 'react';
import { useValidationYupDocumento } from 'views/components/form-validations';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

export const useFormFuncionarioCadastroValidation = (revenda: boolean = false) => {

  const { documentoYup } = useValidationYupDocumento(false)

  const FormFuncionarioCadastroValidationYup = useMemo(() => {
    return Yup.object().shape<AnyObject>({
      nomeComercial: Yup.string().required('O nome comercial é obrigatório.'),
      cpfCnpj: documentoYup('CPF'),
      revendaId: Yup.string().nullable().notRequired(),
    });
  }, [documentoYup])

  return {
    FormFuncionarioCadastroValidationYup,
  }
}

