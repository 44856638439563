import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePutBloquearTerminal() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putBloquearTerminal = useCallback(
        (id: string) =>
            invocarApi({
                method: 'PUT',
                url: `/${VariaveisAmbiente.apiVersion}/terminal/bloquear?id=${id}`,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putBloquearTerminal,
    };
}
