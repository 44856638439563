import { makeStyles, Theme } from "@material-ui/core";

interface StylesProps{
    ativo: boolean;
}

export const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
    ellipsisText: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%'
    },
    boldText: {
        fontWeight: 600
    },
    status: {
        backgroundColor: ({ativo}) => ativo ? theme.palette.success.main : theme.palette.error.main,
        width: '5px',
        borderRadius: '5px',
        alignSelf: 'stretch',
        margin: '7px'
    },
    card: {
        '& p': {
            fontSize: 13
        },
    }
}));