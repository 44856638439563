import { Box, MenuItem, MenuList } from "@material-ui/core";
import { useStyles } from "./menu-list-saurus-styles";
import { MenuListSaurusProps } from "./menu-list-saurus-props";
import { AvancarIcon } from "../icons";

export const MenuListSaurus = ({
    options,
    setaAvancar,
    onCloseClick,
}: MenuListSaurusProps) => {

    const classes = useStyles();

    const wrapperClick = (click: () => any) => {
        click();
        onCloseClick();
    };

    return (
        <>
            <MenuList
                className={classes.menu}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} style={{
                        justifyContent: 'space-between',
                        minWidth: setaAvancar ? 200 : 'auto',
                        alignItems: 'center'
                    }} onClick={() => wrapperClick(option.click!)}>
                        <Box display={"flex"} alignItems={"center"}>
                            {option.icon}
                            {option.label}
                        </Box>
                        {setaAvancar && (
                            <div className={classes.icon}>
                                <AvancarIcon tipo="BUTTON" />
                            </div>
                        )}
                    </MenuItem>
                ))}
            </MenuList>
        </>
    );
}