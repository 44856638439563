import { ModuloPlanoModel } from "model/api/gestao/plano/modulo-plano-model";
import { guidEmpty } from "utils/guid-empty";

export class PlanoFormModel {
  constructor(
    public id: string = guidEmpty(),
    public revendaId: string = guidEmpty(),
    public nome: string = "",
    public descricao: string = "",
    public diasValidade: number = 0,
    public sistemaId: string = guidEmpty(),
    public valorDe: number = 0,
    public status: number = 0,
    public valorPor: number = 0,
    public modulos: ModuloPlanoModel[] = new Array<ModuloPlanoModel>()
  ) { }
}
