import { makeStyles } from "@material-ui/core";

export const useUtilStyles = makeStyles((theme) => ({
    mudanca: {
        width: '100%',
        textAlign: 'center',
        border: '1px solid ' + theme.palette.success.main,
        backgroundColor: 'rgb(16 200 34 / 10%)',
        color: theme.palette.success.main,
        padding: '4px',
        borderRadius: 4,
        '& p': {
            fontWeight: 500
        }
    },
    defaultContainer: {
        position: "relative",
        width: '100%',
        margin: '0 11rem',
        borderRadius: 8,
        overflowY: "auto",
        overflowX: "hidden",
        [theme.breakpoints.down('lg')]: {
          margin: '0 10rem',
        },
        [theme.breakpoints.down('md')]: {
          margin: '0 1rem',
        },
        [theme.breakpoints.down('sm')]: {
          margin: '0 .5rem'
        },
        [theme.breakpoints.down('xs')]: {
          margin: '0'
        }
      },
}))