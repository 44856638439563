import { NotaFiscalModel } from "model/api/gestao/nota-fiscal/nota-fiscal-model";
import { CardNaoEncontrado } from "views/components/cards";
import { CardNFRejeitadas } from "views/components/cards/card-nf-rejeitadas/card-nf-rejeitadas";
import { InformacaoIcon } from "views/components/icons";

export interface NotasFiscaisRevendaGridProps {
  list: Array<NotaFiscalModel>;
  carregando: boolean;
  onCardClick: (model: NotaFiscalModel) => any;
}

export const NotasFiscaisRejeitadasListData = (props: NotasFiscaisRevendaGridProps) => {
  const onCardSelected = (model: NotaFiscalModel) => {
    props.onCardClick(model);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhuma Nota Fiscal Rejeitada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
   
      {props.list.length > 0 &&
        props.list.map((nf, index) => {
          return (
            <CardNFRejeitadas
              onClick={onCardSelected}
              model={nf}
              key={index}
            />
          );
        })}
    </>
  );
};