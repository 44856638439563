import { TerminalHistoricoModel } from "model/api/gestao/terminal/terminal-historico-model";
import { CardNaoEncontrado } from "views/components/cards";
import { CardTerminalHistorico } from "views/components/cards/card-terminal-historico/card-terminal-historico";
import { InformacaoIcon } from "views/components/icons";

export interface TerminalHistoricoListProps {
  list: Array<TerminalHistoricoModel>;
  carregando: boolean;
  onClick: (model: TerminalHistoricoModel) => void
}

export const TerminalHistoricoList = (props: TerminalHistoricoListProps) => {


  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhum Histórico Encontrado."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props?.list.length > 0 &&
        props.list.map((items, index) => {
          return (
            <CardTerminalHistorico
              model={items}
              key={index}
              onClick={props.onClick}
            />
          );
        })}
    </>
  );
};
