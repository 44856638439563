import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    margin: '1rem 6rem',
    [theme.breakpoints.down('md')]: {
        margin: '1rem 4rem'
    },
    [theme.breakpoints.down('sm')]: {
        margin: '1rem'
    }
  },
  botoes: {
    marginTop: '25px',
    width: "100%",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      width: '100%',
      marginTop: '53px'
    }
  },
}));
