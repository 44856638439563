import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetComissaoByIdExportarExcel() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getComissaoByIdExportarExcel = useCallback(
        (id: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/comissao/${id}/excel`,
                method: 'GET',
                enviarTokenUsuario: true,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/vnd.ms-excel",
                    "Content-Disposition": "attachment; filename=Comissoes.xls; filename*=UTF-8''Comissoes.xls"
                },
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getComissaoByIdExportarExcel,
    };
}
