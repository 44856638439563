import { VariaveisAmbiente } from 'config';
import { ContratoCancelamentoModel } from 'model/app/forms/contrato-cancelamento/contrato-cancelamento-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useDeleteDeletarContrato() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const deleteDeletarContrato = useCallback(
        (contrato: ContratoCancelamentoModel) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/contrato`,
                method: 'DELETE',
                data: contrato,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        deleteDeletarContrato,
    };
}
