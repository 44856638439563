import { useCallback } from "react";
import { PrivatePageHeader } from "views/components/headers";
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { FiltroIcon, MenuIcon, VoltarIcon } from "views/components/icons";
import { PesquisaFaturas } from "./components/pesquisa-faturas/pesquisa-notas-fiscais-revenda";
import { useMenuPrincipal } from "services/app";
import { useThemeQueries } from "views";
import { NotasFiscaisRevendaListSearchProps } from "../notas-fiscais-revenda-list/notas-revenda-fiscais-revenda-list-search-props";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import { FiltroProps } from "views/pages/private/revendedor/components/accordion-revendedor-faturas-list/accordion-notas-fiscais-revenda-list";
import { useHeaderStyles } from "views/components/headers/header-private-page/header-styles";
import { SetaCimaIcon } from "views/components/icons/seta-cima-icon";

export interface NotasFiscaisRevendaHeaderProps {
  openPesquisa: boolean;
  titulo: string;
  setOpenPesquisa: (state: boolean) => any;
  setSearchProps: (props: NotasFiscaisRevendaListSearchProps) => any;
}

export interface FaturaLocationProps {
  from: {
    pathname: string,
    page?: number;
    faturasIds?: string[];
    comissionadoId: string;
    searchProps?: FiltroProps;
    pageFatura?: number;
  }
}

export const NotasFiscaisRevendaHeader = ({
  openPesquisa,
  setOpenPesquisa,
  setSearchProps,
  titulo
}: NotasFiscaisRevendaHeaderProps) => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile, theme } = useThemeQueries();
  const classes = useHeaderStyles();
  const history = useHistory()
  const { state } = useLocation<FaturaLocationProps>()

  const leftArea = useCallback(
    () =>
      isMobile ? (titulo === 'Faturas de Revendas' || titulo === 'Minhas Faturas') ? (
        <ButtonPrivateHeader
          icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
          tooltip="Menu"
          onClick={abrirMenu}
        ></ButtonPrivateHeader>
      ) : <ButtonPrivateHeader
        icon={<VoltarIcon tipo="PRIVATE_HEADER"></VoltarIcon>}
        tooltip="Voltar"
        onClick={() => {
          if (!state?.from?.pathname || isEmpty(state.from.pathname)) {
            history.push('/revenda/notas-fiscais')
            return
          }
          if (state.from.page) {
            history.push('/revenda/notas-fiscais?page=' + state.from.page)
            return
          }
          if (state.from.comissionadoId) {
            history.push({
              pathname: `/comissoes/disponiveis/`,
              state: {
                from: {
                  comissionadoId: state.from.comissionadoId,
                  faturasIds: state.from.faturasIds,
                },
              }

            })
            return
          }
          if (state.from.pathname.includes('/comissoes/realizadas/')) {
            history.push(state.from.pathname)
            return
          }
          if (state.from.pathname.includes('/revendedor/')) {
            history.push({
              pathname: state.from.pathname,
              state: {
                from: {
                  searchProps: state.from.searchProps,
                  pageFatura: state.from.pageFatura
                }
              }
            })
            return
          }
          if(state.from.pathname.includes('/notas-fiscais-rejeitadas')){
            history.push(state.from.pathname)
            return
          }
          history.goBack()
        }}
      ></ButtonPrivateHeader> : (titulo !== 'Faturas de Revendas' && titulo !== 'Minhas Faturas') ? (
        <ButtonPrivateHeader
          icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
          tooltip="Voltar"
          onClick={() => {
            if (!state?.from?.pathname || isEmpty(state.from.pathname)) {
              history.push('/revenda/notas-fiscais')
              return
            }
            if (state.from.page) {
              history.push('/revenda/notas-fiscais?page=' + state.from.page)
              return
            }
            if (state.from.comissionadoId) {
              history.push({
                pathname: `/comissoes/disponiveis/`,
                state: {
                  from: {
                    comissionadoId: state.from.comissionadoId,
                    faturasIds: state.from.faturasIds,
                  },
                }
              })
              return
            }
            if (state.from.pathname.includes('/comissoes/realizadas/')) {
              history.push(state.from.pathname)
              return
            }
            if (state.from.pathname.includes('/revendedor/')) {
              history.push({
                pathname: state.from.pathname,
                state: {
                  from: {
                    searchProps: state.from.searchProps,
                    pageFatura: state.from.pageFatura
                  }
                }
              })
              return
            }
            if(state.from.pathname.includes('/notas-fiscais-rejeitadas')){
              history.push(state.from.pathname)
              return
            }
            history.goBack()
          }}
        />
      ) : null,
    [isMobile, titulo, abrirMenu, state, history]
  );

  const rightArea = useCallback(
    () => (titulo === 'Faturas de Revendas' || titulo === 'Minhas Faturas') ? (
      isMobile ? (
        <ButtonPrivateHeader
          icon={
            openPesquisa ? (
              <SetaCimaIcon tipo="PRIVATE_HEADER"></SetaCimaIcon>
            ) : (
              <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>
            )
          }
          tooltip={!openPesquisa ? "Pesquisar" : "Fechar Pesquisa"}
          onClick={() => setOpenPesquisa(!openPesquisa)}
        ></ButtonPrivateHeader>
      ) :
        <ButtonPrivateHeader
          className={classes.pesquisaBox}
          text={!openPesquisa ? 'Filtros' : 'Fechar Filtros'}
          icon={
            openPesquisa ? (
              <SetaCimaIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main}></SetaCimaIcon>
            ) : (
              <FiltroIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main}></FiltroIcon>
            )
          }
          tooltip={!openPesquisa ? "Pesquisar" : "Fechar Pesquisa"}
          onClick={() => setOpenPesquisa(!openPesquisa)}
        ></ButtonPrivateHeader>) : null,
    [classes.pesquisaBox, isMobile, openPesquisa, setOpenPesquisa, theme.palette.primary.main, titulo]
  );

  const closePesquisaWrapper = useCallback(() => {
    setOpenPesquisa(false);
  }, [setOpenPesquisa]);

  const onPesquisar = useCallback(
    (props: NotasFiscaisRevendaListSearchProps) => {
      setSearchProps({ ...props, atualizarPesquisa: !props.atualizarPesquisa });
    },
    [setSearchProps]
  );

  const pesquisa = (
    <PesquisaFaturas
      isOpened={openPesquisa!}
      onCloseSearch={closePesquisaWrapper}
      onPesquisar={onPesquisar}
    />
  );

  return (
    <>
      <PrivatePageHeader
        title={titulo}
        leftArea={leftArea()}
        bottomArea={pesquisa}
        rightArea={rightArea()}
      />
    </>
  );
};
