export enum AppEventEnum {
    ProdutoAlterado = 0,
    MovAtualAlterada = 10,
    MovAtualProdAlterado = 11,
    MovAtualStatusAlterado = 12,
    PermiteEntradaDigitada = 20,

    ExcluirPergunta = 21,
    SolicitarPerguntas = 22,
    ReceberPerguntas = 23,
}