import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetFaturasPendentesExcel() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getFaturasPendentesExcel = useCallback(
        async (revendaId: string, anoMesFinal: number) => {
            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/fatura/revenda/${revendaId}/pendente/${anoMesFinal}/excel`,
                method: 'GET',
                enviarTokenUsuario: true,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/vnd.ms-excel",
                    "Content-Disposition": "attachment; filename=faturas.xls; filename*=UTF-8''faturas.xls "
                },
            })
        }
        , [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getFaturasPendentesExcel,
    };
}
