import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-opcoes-consulta-cnpj-styles';
import { CircularLoading } from 'views';
import { useCallback, useEffect, useState } from 'react';
import { useToastSaurus } from 'services/app';
import { ConsultaCNPJModel } from 'model';
import { useGetPessoaSocio, usePostAdicionarPessoaSocio, usePutAtualizarPessoaSocio } from 'data/api/gestao/pessoa-socio';
import { useGetPessoaInfo, usePostAdicionarPessoaInfo, usePutAtualizarPessoaInfo } from 'data/api/gestao/pessoa-info';
import { PessoaSocioModel } from 'model/api/gestao/pessoa/pessoa-socio-model';
import { PessoaInfoModel } from 'model/api/gestao/pessoa/pessoa-info-model';
import { useGetSegmentoCnae } from 'data/api/gestao/segmentos';
import { SegmentoModel } from 'model/api/gestao/master/segmento';
import { AtualizarIcon, VoltarIcon } from 'views/components/icons';
import { guidEmpty } from 'utils/guid-empty';

interface DialogTerminalProps {
  openned: boolean;
  closeDialog: () => void;
  consultaCnpjModel: ConsultaCNPJModel;
  pessoaId: string;
  setAtualizar: (valor: boolean) => void;
}

export const DialogOpcoesConsultaCnpj = ({
  openned,
  closeDialog,
  consultaCnpjModel,
  pessoaId,
  setAtualizar
}: DialogTerminalProps) => {
  const classes = useStyles();

  const { showToast } = useToastSaurus()
  const { postAdicionarPessoaSocio, carregando: carregandoPostSocio } = usePostAdicionarPessoaSocio()
  const { putAtualizarPessoaSocio, carregando: carregandoPutSocio } = usePutAtualizarPessoaSocio()
  const { putAtualizarPessoaInfo, carregando: carregandoPutInfo } = usePutAtualizarPessoaInfo()
  const { postAdicionarPessoaInfo, carregando: carregandoPostInfo } = usePostAdicionarPessoaInfo()
  const { getPessoaInfo, carregando: carregandoGetInfo } = useGetPessoaInfo()
  const { getPessoaSocio, carregando: carregandoGetSocio } = useGetPessoaSocio()
  const { getSegmentoCnae, carregando: carregandoSegmentoCnae } = useGetSegmentoCnae()

  const [socioList, setSocioList] = useState<Array<PessoaSocioModel>>([])
  const [infoList, setInfoList] = useState<Array<PessoaInfoModel>>([])
  const [segmento, setSegmento] = useState<SegmentoModel>(new SegmentoModel())

  const [atualizarSocio, setAtualizarSocio] = useState<boolean>(consultaCnpjModel.socios.length > 0)
  const [atualizarInfo, setAtualizarInfo] = useState<boolean>(true)

  const carregando =
    carregandoPostSocio ||
    carregandoPostInfo ||
    carregandoPutInfo ||
    carregandoGetInfo ||
    carregandoGetSocio ||
    carregandoSegmentoCnae ||
    carregandoPutSocio

  const getInfoWrapper = useCallback(async (): Promise<Array<PessoaInfoModel>> => {
    const res = await getPessoaInfo(pessoaId, 1, `pageSize=0`)

    if (res.erro) throw res.erro

    return res.resultado?.data.list ?? []
  }, [getPessoaInfo, pessoaId])

  const getSocioWrapper = useCallback(async (): Promise<Array<PessoaSocioModel>> => {
    const res = await getPessoaSocio(pessoaId, 1, `pageSize=0`)

    if (res.erro) throw res.erro

    return res.resultado?.data.list ?? []
  }, [getPessoaSocio, pessoaId])

  const getSegmentoCnaeWrapper = useCallback(async () => {
    const res = await getSegmentoCnae(consultaCnpjModel.cnae)

    if (res.erro) throw res.erro

    return res.resultado?.data
  }, [consultaCnpjModel.cnae, getSegmentoCnae])

  const preencherTela = useCallback(async () => {
    try {
      const infos = await getInfoWrapper()
      setInfoList(infos)

      const socios = await getSocioWrapper()
      setSocioList(socios)

      const segmento = await getSegmentoCnaeWrapper()
      setSegmento(segmento)
    } catch (err: any) {
      showToast('error', err.message)
    }
  }, [getInfoWrapper, getSegmentoCnaeWrapper, getSocioWrapper, showToast])

  useEffect(() => {
    if(openned){
      setAtualizarSocio(consultaCnpjModel.socios.length > 0)
      preencherTela()
    }
  }, [consultaCnpjModel.socios.length, openned, preencherTela])

  const atualizarInformacao = useCallback(async () => {
    try {
      const temComercial = infoList.filter(item => item.isComercial)
      const empresa = consultaCnpjModel

      const pessoaInfo: PessoaInfoModel = {
        ...(temComercial.length > 0 ? temComercial[0] : new PessoaInfoModel()),
        bairro: empresa.bairro,
        cep: empresa.cep,
        cnae: empresa.cnae,
        codMunicipio: parseInt(empresa.cMunicipio),
        complemento: empresa.complemento,
        fantasia: empresa.nomeFantasia,
        ie: empresa.ie,
        isComercial: true,
        isFinanceiro: false,
        isJuridico: false,
        logradouro: empresa.logradouro,
        municipio: empresa.municipio,
        numero: empresa.numero,
        pessoaId: pessoaId,
        segmentoId: segmento.id,
        uf: empresa.uf,
        xNome: empresa.nomeFantasia,
        latitude: 0,
        longitude: 0
      }

      if (temComercial.length > 0) {
        const res = await putAtualizarPessoaInfo(pessoaInfo, pessoaId)

        if (res.erro) throw res.erro

        return true
      }

      const res = await postAdicionarPessoaInfo(pessoaId, pessoaInfo)

      if (res.erro) throw res.erro
      return true

    } catch (err: any) {
      return false
    }
  }, [consultaCnpjModel, infoList, pessoaId, postAdicionarPessoaInfo, putAtualizarPessoaInfo, segmento.id])

  const atualizarSocios = useCallback(async () => {
    try {
      const sociosCnpj = consultaCnpjModel.socios

      let atualizarPessoas: PessoaSocioModel[] = []
      if (sociosCnpj.length > 0) {
        const mesmoSocio =
          socioList.filter(
            item => {
              if (
                (sociosCnpj.map(socio =>
                  (socio.nome.toLowerCase())
                )).includes(item.nome.normalize('NFD')
                  .replaceAll('/[\u0300-\u036f]/g', '').toLowerCase())
              ) {
                return true
              }
              else if (
                (sociosCnpj.map(socio =>
                  (socio.nome.split(' ')[0].toLowerCase())
                )).includes(item.nome.split(' ')[0].normalize('NFD')
                  .replaceAll('/[\u0300-\u036f]/g', '').toLowerCase())
              ) {
                return true
              }
              return false
            }
          )


        if (mesmoSocio.length > 0) {
          atualizarPessoas = mesmoSocio.map(item => {
            const socioAtualizar = sociosCnpj.filter(
              socio => {
                if (
                  socio.nome.toLowerCase()
                    .includes(item.nome.normalize('NFD')
                      .replaceAll('/[\u0300-\u036f]/g', '').toLowerCase())
                ) {
                  return true
                }
                else if (
                  socio.nome.split(' ')[0].toLowerCase()
                    .includes(item.nome.split(' ')[0].normalize('NFD')
                      .replaceAll('/[\u0300-\u036f]/g', '').toLowerCase())
                ) {
                  return true
                }
                return false
              }
            )
            return {
              ...item,
              nome: socioAtualizar[0].nome
            }
          })
        }

        const sociosDiferentes =
          sociosCnpj.filter(
            item => {
              if (
                !(socioList.map(socio =>
                (socio.nome.normalize('NFD')
                  .replaceAll('/[\u0300-\u036f]/g', '').toLowerCase())
                )).includes(item.nome.toLowerCase())
              ) {
                return true
              }
              else if (
                !(socioList.map(socio =>
                (socio.nome.split(' ')[0].normalize('NFD')
                  .replaceAll('/[\u0300-\u036f]/g', '').toLowerCase())
                )).includes(item.nome.split(' ')[0].toLowerCase())
              ) {
                return true
              }
              return false
            }
          )

        const botarSociosDiferentes: PessoaSocioModel[] = sociosDiferentes
          .map(item => ({
            ...new PessoaSocioModel(),
            nome: item.nome
          }))

        atualizarPessoas = [
          ...atualizarPessoas,
          ...botarSociosDiferentes
        ]

        for (let i = 0; i < atualizarPessoas.length; i++) {
          if (atualizarPessoas[i].id === guidEmpty()) {
            const res = await postAdicionarPessoaSocio(pessoaId, atualizarPessoas[i])

            if (res.erro) throw res.erro
          } else {
            const res = await putAtualizarPessoaSocio(pessoaId, atualizarPessoas[i])

            if (res.erro) throw res.erro
          }
        }

        return true
      }

      return false
    } catch (err: any) {
      return false
    }
  }, [consultaCnpjModel.socios, pessoaId, postAdicionarPessoaSocio, putAtualizarPessoaSocio, socioList])

  const atualizar = useCallback(async () => {
    let infoAtualizada = false;
    let socioAtualizado = false;
    if(atualizarInfo){
      infoAtualizada = await atualizarInformacao()
    }
    if(atualizarSocio){
      socioAtualizado = await atualizarSocios()
    }

    if(socioAtualizado && infoAtualizada){
      showToast('success', 'Sócios e Informações atualizadas.')
    }else if(socioAtualizado){
      showToast('success', 'Sócios foram atualizados.')
    }else if(infoAtualizada){
      showToast('success', 'Informações foram atualizadas.')
    }else{
      showToast('error', 'Ocorreu um problema para atualizar seus dados.')
    }
    setAtualizar(true)
    closeDialog()
  }, [atualizarInfo, atualizarInformacao, atualizarSocio, atualizarSocios, closeDialog, setAtualizar, showToast])

  return (
    <DialogSaurus
      aberto={openned}
      titulo="Atualizações CNPJ"
      isButtonTitleClose
      handleClickClose={closeDialog}
      tamanho="sm"
    >
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Grid
              container
              spacing={1}
            >
              <Grid item xs={12}>
                <Typography>
                  Escolha as opções que conseguimos encontrar pelo CNPJ.
                </Typography>
              </Grid>
              {consultaCnpjModel.socios.length < 1 && !carregando && <Grid item xs={12}>
                <Typography variant='caption' color='error'>* Não foi possível localizar sócios através da consulta.</Typography>
              </Grid>}
              {!consultaCnpjModel.cnae && !carregando && <Grid item xs={12}>
                <Typography variant='caption' color='error'>* Não foi possível localizar informações através da consulta.</Typography>
              </Grid>}
              <Grid item xs={12}>
                <FormGroup row style={{ display: 'flex', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Checkbox color='primary' checked={atualizarInfo} />}
                    value={atualizarInfo} onChange={() => setAtualizarInfo(prev => !prev)}
                    label='Informação'
                  />
                  <FormControlLabel
                    control={<Checkbox color='primary' checked={atualizarSocio} />}
                    value={atualizarSocio} onChange={() => setAtualizarSocio(prev => !prev)}
                    label='Sócios'
                    disabled={consultaCnpjModel.socios.length < 1}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth variant='contained' color="primary" size="large" onClick={atualizar}
                disabled={!atualizarSocio && !atualizarInfo}>
                  <AtualizarIcon tipo="BUTTON_PRIMARY" />
                  Atualizar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button size="large" variant='outlined' color="primary" onClick={closeDialog} fullWidth>
                  <VoltarIcon tipo="BUTTON" />
                  Voltar
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
