import { useEffect, useState, useCallback, useRef } from "react";
import { Grid } from "@material-ui/core";
import { useStyles } from "./consulta-faturas-list-styles";
import { ConsultaFaturaListData } from "./consulta-faturas-list-data";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import { useToastSaurus } from "services/app";
import { useGetFaturasCobranca } from "data/api/gestao/faturas/get-faturas-cobranca";
import { FaturasModel } from "model/api/gestao/fatura/faturas-model";
import { ConsultaFaturaModal } from "views/components/modals/consulta-fatura/consulta-fatura-modal";
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles";
import { EnumEstadoConsultaFatura } from "model/enums/enum-estado-consulta-fatura";
import { EnumSituacao } from "model/enums/enum-situacao";
import { isEmpty } from "lodash";

interface ConsultaFaturaListProps {
  estado: EnumEstadoConsultaFatura;
  setEstado: React.Dispatch<React.SetStateAction<EnumEstadoConsultaFatura>>;
}

export const ConsultaFaturasList = ({ estado, setEstado }: ConsultaFaturaListProps) => {
  const classes = useStyles();
  const listClasses = useDefaultCadastroStyles();

  const { getFaturasCobranca, carregando } = useGetFaturasCobranca();

  const { showToast } = useToastSaurus();

  const [faturas, setFaturas] = useState<FaturasModel[]>(Array<FaturasModel>());
  const [selectedFatura, setSelectedFatura] = useState<FaturasModel>(new FaturasModel())
  const [openModal, setOpenModal] = useState<boolean>(false)

  const queryString = window.location.search

  const urlParams = new URLSearchParams(queryString)

  const codCliente = urlParams.get('codCliente')
  const doc = urlParams.get('doc')
  const faturaId = useRef<string | null>(urlParams.get('faturaId'))

  const [selectedList, setSelectedList] = useState<Array<string>>([]);

  const search = useCallback(
    async () => {
      try {
        const query =
          `${'?contratoId=' + codCliente
          }${'&documento=' + doc
          }`

        const res = await getFaturasCobranca(1, query);
        if (res.erro) throw res.erro;

        if (!res.resultado) return new Array<FaturasModel>()

        const retFaturas = res.resultado.data.map(ft => {
          if (ft.formasPagamentosDisponiveis === null) {
            return {
              ...ft,
              formasPagamentosDisponiveis: []
            }
          }
          return ft
        })

        setFaturas(retFaturas)

        return retFaturas;
      } catch (e: any) {
        showToast("error", e.message);
        return new Array<FaturasModel>()
      }
    },
    [codCliente, doc, getFaturasCobranca, showToast]
  );

  const onCardSelected = (fatura: FaturasModel) => {
    setSelectedFatura(fatura)
    setOpenModal(true)
  };

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  useEffect(() => {
    const getFaturas = async () => {
      if (!openModal) {
        const ret = await search();
        if (!isEmpty(faturaId.current)) {
          const fatura = ret.find(fatura => fatura.id === faturaId.current) || new FaturasModel()
          onCardSelected(fatura)

          if (fatura.situacao !== EnumSituacao.Aberta &&
            fatura.situacao !== EnumSituacao.Fechada &&
            fatura.situacao !== EnumSituacao.EmPagamento &&
            fatura.situacao !== EnumSituacao.Atraso) {

            setEstado(EnumEstadoConsultaFatura.FINALIZADAS)
          }
        }
      }
    }
    getFaturas();
  }, [openModal, search, setEstado]);

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid container className={listClasses.listContainer}>
          <Grid item xs={12} className={classes.listContainer}>
            <ConsultaFaturaListData
              carregando={carregando}
              list={faturas.filter(fatura => {
                const isAberto = (
                  fatura.situacao === EnumSituacao.Aberta ||
                  fatura.situacao === EnumSituacao.Fechada ||
                  fatura.situacao === EnumSituacao.EmPagamento ||
                  fatura.situacao === EnumSituacao.Atraso
                )
                if (estado === EnumEstadoConsultaFatura.ABERTAS) {
                  return isAberto
                } else {
                  return !isAberto
                }
              })}
              selectedList={selectedList}
              onCardSelected={onCardSelected}
              onCardChecked={onCardChecked}
            />
          </Grid>
        </Grid>
      </div>
      <ConsultaFaturaModal
        id={selectedFatura.id}
        fatura={selectedFatura}
        openned={openModal}
        closeModal={() => {
          faturaId.current = null
          setOpenModal(false)
        }}
      />
    </>
  );
};
