import { VariaveisAmbiente } from 'config';
import { PessoaComissaoFormModel } from 'model/api/gestao/pessoa/pessoa-comissao-form-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePutAtualizarPessoaComissao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putAtualizarPessoaComissao = useCallback(
        (pessoaId: string, model: PessoaComissaoFormModel) =>
            invocarApi({
                method: 'PUT',
                url: `/${VariaveisAmbiente.apiVersion}/pessoa/${pessoaId}/comissao`,
                data: model,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putAtualizarPessoaComissao,
    };
}