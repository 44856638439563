import { VariaveisAmbiente } from 'config';
import { TerminalModel } from 'model/api/gestao/terminal/terminal-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetTerminalById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<TerminalModel>();

    const getTerminalById = useCallback(
        (id: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/terminal/${id}`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getTerminalById,
    };
}
