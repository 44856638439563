import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
} from '@material-ui/core';
import { useSessaoAtual } from 'services/app/providers';
import { FormLogin } from 'views/components/form/master/login/form-login';
import { LoginFormModel } from 'model/app';
import { CircularLoading } from 'views';
import { DefaultFormRefs } from 'views/components/form/utils/form-default-props';
import { LoginIcon } from 'views/components/icons';
import { useToastSaurus } from 'services/app';
import { makeUtilClasses } from '../../../theme/util-make-styles';
import { ApiError } from '../../../../model/app/errors/api-erros';
import { useStyles } from './login-page-styles'

import Logo from '../../../../assets/img/logo-licenciamento.svg'
import { PublicPageHeader } from 'views/components/headers';

const LoginPage = () => {
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const history = useHistory();
  const { logar, carregando } = useSessaoAtual();
  const [bloqueado, setBloqueado] = useState(false);
  const loginRefs = useRef<DefaultFormRefs<LoginFormModel>>(null);

  useEffect(() => {
    if (loginRefs) loginRefs.current?.fillForm(new LoginFormModel('', ''));
  }, []);

  const efetuarLogin = async (model: LoginFormModel) => {
    try {
      await logar(model.login, model.senha);
      setBloqueado(true);
      history.push('/termos');
    } catch (e: any) {
      if (e.constructor.name === ApiError.name) {
        history.push(`/ativar-email?email=${model.login}`);
        return;
      } else {
        showToast('error', e.message);
        loginRefs.current?.resetForm();
      }
    }
  };

  return (
    <>
      <PublicPageHeader topOpacity='0.8' />
      <div className={`${utilClasses.contentCenter} ${classes.rootPadding}`}>
        <img src={Logo} alt='Logotipo Licenciamento' className={classes.logo} />
        <Grid container className={utilClasses.rootContainer}>
          {carregando || bloqueado ? (
            <CircularLoading tipo="FULLSIZED" />
          ) : null}
          <Grid item xs={12}>
            <Card className={classes.loginCard}>
              <Grid container direction="column">
                <Grid item xs={12} className={classes.textContainer}>
                  <Typography align="left" variant="h4" color="primary">
                    Login
                  </Typography>
                  <Typography variant='body1' color='textSecondary'>
                    Por favor, entre digitando as informações abaixo para poder continuar.
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ width: '100%' }}>
                  <FormLogin
                    showLoading={false}
                    ref={loginRefs}
                    loading={carregando}
                    onSubmit={(model) => {
                      efetuarLogin(model);
                    }}
                  />
                  <Box mt={7}>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth={true}
                        disabled={carregando || bloqueado}
                        onClick={() => {
                          loginRefs.current?.submitForm();
                        }}
                      >
                        <LoginIcon tipo="BUTTON_PRIMARY" />
                        Acessar
                      </Button>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LoginPage;
