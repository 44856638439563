import React from 'react';
import { createContext, useContext } from 'react';
import { RecoilState } from 'recoil';
import { useMenuGlobalState } from 'services/app/use-cases/global-states/menu-global-state';
import { MenuProviderModel } from '../menu-principal-provider/menu-principal-provider-model';

interface GlobalStatesContextValue {
    menuGlobalState: RecoilState<MenuProviderModel> | undefined,
}
const GlobalStatesContext = createContext<GlobalStatesContextValue>({
    menuGlobalState: undefined,
});

export interface GlobalStatesProviderProps {
    children: React.ReactNode;
}

export const useGlobalStates = () => {
    return useContext(GlobalStatesContext);
};

export const GlobalStatesProvider = ({ children }: GlobalStatesProviderProps) => {
    const { menuGlobalState } = useMenuGlobalState();

    return (
        <GlobalStatesContext.Provider
            value={{
                menuGlobalState,
            }}
        >
            {children}
        </GlobalStatesContext.Provider>
    );
};
