import { VariaveisAmbiente } from 'config';
import { ApiListModel } from 'model/api/gestao/api-list-model/api-list-model';
import { PagamentoModel } from 'model/api/gestao/pagamento/pagamento-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetPagamento() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ApiListModel<PagamentoModel>>();

    const getPagamento = useCallback(
        (newPage: number, query?: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/FormaPagamento?page=${newPage}&${query}`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getPagamento,
    };
}
