import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { ClassificacaoModel } from 'model/api/gestao/classificacao/classificacao-model';

export function usePostPessoaClassificacao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postAPessoaClassificacao = useCallback(
        (model: ClassificacaoModel) =>
            invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/pessoa-classificacao`,
                data: model,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postAPessoaClassificacao,
    };
}
