import { Button, Grid } from "@material-ui/core";
import { useGetPessoaSistema, usePostAdicionarPessoaSistema, usePutAtualizarPessoaSistema } from "data/api/gestao/pessoa-sistema";
import { useDeletePessoaSistema } from "data/api/gestao/pessoa-sistema/delete-pessoa-sistema";
import { ApiListModel } from "model/api/gestao/api-list-model/api-list-model";
import { PessoaSistemaCreateModel } from "model/api/gestao/pessoa/pessoa-sistema-create-model";
import { PessoaSistemaModel } from "model/api/gestao/pessoa/pessoa-sistema-model";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { useCallback, useEffect, useState } from "react";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { guidEmpty } from "utils/guid-empty";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus";
import { DialogPessoaSistema } from "views/components/dialog/dialog-pessoa-sistema/dialog-pessoa-sistema";
import { NovoIcon } from "views/components/icons";
import { Paginacao } from "views/components/paginacao";
import { useThemeQueries } from "views/theme";
import { AccordionRevendedorPrecosListData } from "./accordion-revendedor-precos-list-data";

type Props = {
  id: string;
}

export const AccordionRevendedorPrecosList = ({ id }: Props) => {
  const { theme } = useThemeQueries()
  const [openAccordion, setOpenAccordion] = useState<boolean>(false)
  const [modelPreco, setModelPreco] = useState<PessoaSistemaCreateModel>(new PessoaSistemaCreateModel())
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const { showToast } = useToastSaurus()

  const {tipoUsuario} = useSessaoAtual()

  const { getPessoaSistema, carregando: carregandoGet } = useGetPessoaSistema()
  const { postAdicionarPessoaSistema, carregando: carregandoPost } = usePostAdicionarPessoaSistema()
  const { putAtualizarPessoaSistema, carregando: carregandoPut } = usePutAtualizarPessoaSistema()
  const {deletePessoaSistema, carregando: carregandoDelete} = useDeletePessoaSistema()

  const [queryStatus, setQueryStatus] = useState<ApiListModel<PessoaSistemaModel>>({
    pageIndex: 1,
    totalPages: 0,
    totalResults: 0,
    pageSize: 0,
    list: Array<PessoaSistemaModel>(),
  });

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<PessoaSistemaModel>
    ) => {
      setQueryStatus({
        pageIndex: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
        pageSize: 0
      });
    },
    []
  );

  const carregando = carregandoGet || carregandoPost || carregandoPut || carregandoDelete

  const onClickDialog = useCallback((model: PessoaSistemaModel) => {

    setModelPreco({
      id: model.id,
      tabelaPrecoId: model.tabelaPreco.id,
      pessoaId: model.pessoaId,
      sistemaId: model.sistema.id,
      percentualOverTabela: model.percentualOverTabela
    })
    setOpenDialog(true)
  }, [])

  const saveNewPessoaSistema = useCallback(async (model: PessoaSistemaCreateModel) => {
    const res = await postAdicionarPessoaSistema(id, model)

    if (res.erro) throw res.erro

    showToast('success', 'Nova Informação Adicionada!')
  }, [id, postAdicionarPessoaSistema, showToast])

  const saveChangeSocio = useCallback(async (model: PessoaSistemaCreateModel) => {
    const res = await putAtualizarPessoaSistema(id, model)

    if (res.erro) throw res.erro

    showToast('success', 'Informação Atualizada!')
  }, [id, putAtualizarPessoaSistema, showToast])

  const handleSubmit = useCallback(async (model: PessoaSistemaCreateModel) => {
    try {
      model.pessoaId = id
      if (model.id === guidEmpty()) {
        await saveNewPessoaSistema(model)
        return true
      }

      await saveChangeSocio(model)

      return true
    }
    catch (err: any) {
      showToast('error', err.message)

      return false
    }
  }, [id, saveChangeSocio, saveNewPessoaSistema, showToast])

  const handleDelete = useCallback(async (model: PessoaSistemaCreateModel) => {
    try {
      const res = await deletePessoaSistema(model.pessoaId, model.id)

      if(res.erro) throw res.erro

      showToast('success', 'Preço excluído.')
      setOpenDialog(false)
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [deletePessoaSistema, showToast])

  const getPessoaSistemaWrapper = useCallback(async (newPage: number) => {
    const res = await getPessoaSistema(id, newPage, '')

    if (res.erro) throw res.erro

    if (res.resultado?.data === undefined) {
      return
    }

    //se o index for maior que as paginas ele busca a ultima
    if (
      res.resultado?.data.pageIndex > res.resultado?.data.totalPages &&
      res.resultado?.data.totalResults > 0
    ) {
      getPessoaSistemaWrapper(res.resultado.data.totalPages);
      return;
    }

    fillResult(
      res.resultado?.data.pageIndex,
      res.resultado?.data.totalPages,
      res.resultado?.data.totalResults,
      res.resultado?.data.list
    );

  }, [fillResult, getPessoaSistema, id])

  useEffect(() => {
    if (openAccordion && !openDialog) {
      getPessoaSistemaWrapper(queryStatus.pageIndex)
        .catch(err => {
          showToast('error', err.message)
        })
    }
  }, [getPessoaSistemaWrapper, openAccordion, openDialog, queryStatus.pageIndex, showToast])

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        getPessoaSistemaWrapper(newPage);
      }
    },
    [queryStatus.totalPages, getPessoaSistemaWrapper]
  );

  const onCardSelected = useCallback((model: PessoaSistemaModel) => {
    onClickDialog(model)
  }, [onClickDialog])

  const onClickAdicionar = useCallback(() => {
    onClickDialog(new PessoaSistemaModel())
  }, [onClickDialog])

  const isFuncionario = tipoUsuario() === EnumTipoPessoas.FuncionarioSoftwareHouse

  return (
    <>
      <AccordionSaurus
        labelPrimary="Preços"
        tipoExpand="bold"
        noPaperRoot={false}
        heightDivider={2}
        showDivider={openAccordion}
        colorDivider={theme.palette.primary.main}
        colorExpand={theme.palette.primary.main}
        expanded={openAccordion}
        onChange={() => setOpenAccordion(prev => !prev)}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paginacao
              pageChanged={pageChanged}
              totalPages={queryStatus.totalPages}
              totalRegisters={queryStatus.totalResults}
              currentPage={queryStatus.pageIndex}
            />
          </Grid>
          <Grid item xs={12}>
            <AccordionRevendedorPrecosListData
              list={queryStatus.list}
              carregando={carregando}
              onCardSelected={onCardSelected}
              onCardChecked={() => { }}
              selectedList={[]}
            />
          </Grid>
          {(!isFuncionario) && <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={5} lg={4}>
                <Button variant="contained" color='primary' fullWidth
                  onClick={onClickAdicionar}>
                  <NovoIcon tipo='BUTTON_PRIMARY' />
                  NOVO PREÇO
                </Button>
              </Grid>
            </Grid>
          </Grid>}
        </Grid>
      </AccordionSaurus>
      <DialogPessoaSistema
        openned={openDialog}
        model={modelPreco}
        carregando={carregando}
        enviarModel={async model => await handleSubmit(model)}
        closeModal={() => setOpenDialog(false)}
        handleDelete={handleDelete}
      />
    </>
  );
};
