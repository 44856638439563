import { guidEmpty } from "utils/guid-empty";
import { PlanoModel } from "../plano/plano-model";
import { PrecoModulos } from "../sistemas/sistema-tabela-precos-model";
import { EnumTipoCobrancaRevenda } from "model/enums/enum-tipo-cobranca-revenda";

export class ContratoDadosModel {
    constructor(
        public precos: PrecoContratoModel = new PrecoContratoModel(),
        public pagamentos: PagamentoContratoModel[] = [],
        public planos: PlanoModel[] = [],
        public ambientes: AmbienteContratoModel[] = [],
        public tipoCobrancaRevenda: EnumTipoCobrancaRevenda = EnumTipoCobrancaRevenda.ClienteFinal
    ) { }
}

export class PrecoContratoModel{
    constructor(
        public id: string = guidEmpty(),
        public nome: string = '',
        public tabelas: TabelaPrecoContratoModel[] = []
    ){}
}

export class TabelaPrecoContratoModel{
    constructor(
        public tabelaPrecoId: string = guidEmpty(),
        public nome: string = '',
        public precoModulos: PrecoModulos[] = []
    ){}
}
export class PagamentoContratoModel{
    constructor(
        public id: string = guidEmpty(),
        public nome: string = '',
    ){}
}

export class AmbienteContratoModel{
    constructor(
        public id: string = guidEmpty(),
        public nome: string = '',
        public revendaId: string = ''
    ){}
}