import { VariaveisAmbiente } from 'config';
import { NotaFiscalModel } from 'model/api/gestao/nota-fiscal/nota-fiscal-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { ApiListModel } from 'model/api/gestao/api-list-model/api-list-model';

export function useGetNotasFiscaisRejeitadas() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ApiListModel<NotaFiscalModel>>();

    const getNotasFiscaisRejeitadas = useCallback(
        (page: number, query: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/nota-fiscal/rejeitadas?page=${page}&${query}`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getNotasFiscaisRejeitadas,
    };
}