
import { isEmpty } from "lodash";

import { DefaultModal } from 'views/components/modals/components/default-modal';
import { ModalProps } from 'views/components/modals/utils/modal-props';
import NovoSistemaCadastro from "./components/novo-sistema-cadastro/novo-sistema-cadastro";

export const NovoSistemaModal = (props: ModalProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {props.openned && isEmpty(props.id) && <NovoSistemaCadastro />}
    </DefaultModal>
  );
};
