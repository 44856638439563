import { VariaveisAmbiente } from 'config';
import { ApiListSomatorioModel } from 'model/api/gestao/api-list-somatorio-model/api-list-model';
import { FaturasModel } from 'model/api/gestao/fatura/faturas-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetFaturas() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ApiListSomatorioModel<FaturasModel>>();

    const getFaturas = useCallback(
        async (page: number, queryBusca: string) => {
            let queryPage = "";
            if (page) {
                queryPage = "pageSize=50&page=" + page.toString();
            }
            const queryFinal = `${queryPage?.length > 0 || queryBusca?.length > 0 ? "?" : ""
                }${queryPage}${queryBusca}`;

            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Fatura${queryFinal}`,
                method: 'GET',
                enviarTokenUsuario: true
            })
        }
        , [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getFaturas,
    };
}
