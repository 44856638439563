import { useCallback, useState } from "react";
import { PrivatePageHeader } from "views/components/headers";
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { DesfazerIcon, FiltroIcon, MenuIcon, VoltarIcon } from "views/components/icons";
import { PesquisaClientes } from "./components/pesquisa-clientes/pesquisa-clientes";
import { useMenuPrincipal } from "services/app";
import { useThemeQueries } from "views";
import { ClientesListSearchProps } from "../clientes-list/clientes-list-search-props";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import { Box } from "@material-ui/core";
import { PesquisaAvancadaCliente } from "./components/pesquisa-avancada-clientes/pesquisa-avancada-clientes";
import { EnumSituacaoPessoa } from "model/enums/enum-situacao-pessoa";
import { SetaCimaIcon } from "views/components/icons/seta-cima-icon";
import { useHeaderStyles } from "views/components/headers/header-private-page/header-styles";

export interface ClientesHeaderProps {
  openPesquisa: boolean;
  setOpenPesquisa: (state: boolean) => any;
  setSearchProps: (props: ClientesListSearchProps) => any;
  titulo: string,
}

export interface LocationProps {
  from: {
    pathname: string,
    page?: number;
  }
}

export const ClientesHeader = ({
  openPesquisa,
  setOpenPesquisa,
  setSearchProps,
  titulo,
}: ClientesHeaderProps) => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();
  const history = useHistory()
  const { state } = useLocation<LocationProps>()
  const classes = useHeaderStyles();
  const {theme} = useThemeQueries();

  const [pesquisaFiltro, setPesquisaFiltro] = useState<ClientesListSearchProps>({
    documento: '',
    dominio: '',
    revenda: '',
    status: EnumSituacaoPessoa.Ativo,
    termo: '',
    atualizarPesquisa: false
  })

  const leftArea = useCallback(
    () =>
      isMobile ? (titulo === 'Clientes' ?
        (
          <ButtonPrivateHeader
            icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
            tooltip="Menu"
            onClick={abrirMenu}
          ></ButtonPrivateHeader>
        ) : <ButtonPrivateHeader
          icon={<VoltarIcon tipo="PRIVATE_HEADER"></VoltarIcon>}
          tooltip="Voltar"
          onClick={() => {
            if (!state?.from?.pathname || isEmpty(state.from.pathname)) {
              history.push('/clientes')
              return
            }
            if (state.from.page) {
              history.push('/clientes?page=' + state.from.page)
              return
            }
            if(state.from.pathname.includes('/notas-fiscais-rejeitadas')){
              history.push(state.from.pathname)
              return
            }
            history.goBack()
          }}
        ></ButtonPrivateHeader>) : (titulo !== "Clientes") ? (
          <ButtonPrivateHeader
            icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
            tooltip="Voltar"
            onClick={() => {
              if (!state?.from?.pathname || isEmpty(state.from.pathname)) {
                history.push('/clientes')
                return
              }
              if (state.from.page) {
                history.push('/clientes?page=' + state.from.page)
                return
              }
              if(state.from.pathname.includes('/notas-fiscais-rejeitadas')){
                history.push(state.from.pathname)
                return
              }
              history.goBack()
            }}
          />
        ) : null,
    [isMobile, abrirMenu, titulo, state, history]
  );

  const rightArea = useCallback(
    () => titulo === "Clientes" ? (
      <ButtonPrivateHeader
        icon={
          openPesquisa ? (
            <DesfazerIcon tipo="PRIVATE_HEADER"></DesfazerIcon>
          ) : (
            <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>
          )
        }
        tooltip={!openPesquisa ? "Pesquisa Avançada" : "Fechar Filtro"}
        onClick={() => setOpenPesquisa(!openPesquisa)}
      ></ButtonPrivateHeader>
    ) : null,
    [titulo, openPesquisa, setOpenPesquisa]
  );

  const closePesquisaWrapper = useCallback(() => {
    setOpenPesquisa(false);
  }, [setOpenPesquisa]);

  const onPesquisar = useCallback(
    () => {
      setSearchProps({ ...pesquisaFiltro, atualizarPesquisa: !pesquisaFiltro.atualizarPesquisa });
    },
    [pesquisaFiltro, setSearchProps]
  );

  const pesquisaAvancada = useCallback(() => titulo === 'Clientes' ? (
    <PesquisaAvancadaCliente
      isOpened={openPesquisa}
      onCloseSearch={closePesquisaWrapper}
      onPesquisar={onPesquisar}
      pesquisa={pesquisaFiltro}
      setPesquisa={setPesquisaFiltro}

    />
  ) : <></>, [closePesquisaWrapper, onPesquisar, openPesquisa, pesquisaFiltro, titulo])

  const pesquisa = useCallback(() => titulo === 'Clientes' ? (
    <>
      <Box display='flex' alignItems='center'>
        <PesquisaClientes
          isOpened={!isMobile || openPesquisa!}
          onCloseSearch={closePesquisaWrapper}
          onPesquisar={onPesquisar}
          pesquisa={pesquisaFiltro}
          setPesquisa={setPesquisaFiltro}
        />

        <Box>
          <ButtonPrivateHeader
            className={classes.pesquisaBox}
            icon={
              openPesquisa ? (
                <SetaCimaIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main}></SetaCimaIcon>
              ) : (
                <FiltroIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main}></FiltroIcon>
              )
            }
            tooltip={!openPesquisa ? "Pesquisa Avançada" : "Fechar Filtro"}
            text={!openPesquisa ? 'Filtros' : 'Fechar Filtros'}
            onClick={() => setOpenPesquisa(!openPesquisa)}
          ></ButtonPrivateHeader>
        </Box>
      </Box>
    </>
  ) : <></>, [classes.pesquisaBox, closePesquisaWrapper, isMobile, onPesquisar, openPesquisa, pesquisaFiltro, setOpenPesquisa, theme.palette.primary.main, titulo])

  return (
    <>
      <PrivatePageHeader
        title={titulo}
        leftArea={leftArea()}
        rightArea={isMobile ? rightArea() : pesquisa()}
        bottomArea={pesquisaAvancada()}
      />
    </>
  );
};
