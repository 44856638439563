import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { PessoaClienteIdealPutModel } from 'model/api/gestao/cliente-ideal/pessoa-cliente-ideal-put-model';

export function usePutPessoaClienteIdeal() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putPessoaClienteIdeal = useCallback(
        (pessoaId: string = '', models: PessoaClienteIdealPutModel[]) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/pessoa/${pessoaId}/cliente-ideal`,
                method: 'PUT',
                enviarTokenUsuario: true,
                data: {
                    respostas: models
                }
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putPessoaClienteIdeal,
    };
}
