import { EnumTipoComunicacao } from "model/enums";
import { EnumAmbientePagamento } from "model/enums/enum-ambiente-pagamento";
import { EnumInstituicao } from "model/enums/enum-instituicao";
import { guidEmpty } from "utils/guid-empty";

export class PagamentoFormModel {
  constructor(
    public id: string = guidEmpty(),
    public nome: string = '',
    public tipoComunicacao: EnumTipoComunicacao = EnumTipoComunicacao.Boleto,
    public instituicao: EnumInstituicao = EnumInstituicao.Itau,
    public credenciais: CredenciamentoItauBoleto | CredenciamentoItauPix | CredenciamentoSafraPix | Record<string, string> | null = null,
  ) { }
}

export class CredenciamentoItauBoleto {
  constructor(
    public instituicao: EnumInstituicao = EnumInstituicao.Itau,
    public tipo: EnumTipoComunicacao = EnumTipoComunicacao.Boleto,
    public agencia: string = '',
    public conta: string = '',
    public digitoAgencia: string = '',
    public digitoConta: string = '',
    public clientId: string = guidEmpty(),
    public clientSecret: string = '',
    public cpfCnpj: string = '',
    public razaoSocial: string = '',
    public ambiente: EnumAmbientePagamento = EnumAmbientePagamento.Sandbox,
    public certificadoSecret: string = '',
    public certificadoPfx: string = '',
  ) {

  }
}

export class CredenciamentoItauPix {
  constructor(
    public instituicao: EnumInstituicao = EnumInstituicao.Itau,
    public tipo: EnumTipoComunicacao = EnumTipoComunicacao.Boleto,
    public agencia: string = '',
    public conta: string = '',
    public digitoAgencia: string = '',
    public digitoConta: string = '',
    public razaoSocial: string = '',
    public cnpj: string = '',
    public clienteId: string = '',
    public clientSecret: string = '',
    public chave: string = '',
    public acao: string = '',
    public mensagem: string = ''
  ) {

  }
}

export class CredenciamentoSafraPix {
  constructor(
    public instituicao: EnumInstituicao = EnumInstituicao.Safra,
    public tipo: EnumTipoComunicacao = EnumTipoComunicacao.Pix,
    public razaoSocial: string = '',
    public cnpj: string = '',
    public merchantId: string = '',
    public merchantToken: string = '',
    public ambiente: EnumAmbientePagamento = EnumAmbientePagamento.Sandbox,
    public chave: string = ''
  ) {

  }
}
