
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormFaturaValidation = () => {


  const FormFaturaValidationYup = useMemo(() => {
    return Yup.object().shape({
      nossoNumero: Yup.number().required('Número da fatura é obrigatório').typeError('Número da fatura é obrigatório'),
      dataVencimento: Yup.string().required('Campo obrigatório'),
      formaPagamentoId: Yup.string().required('Forma de Pagamento é obrigatória.').typeError('Forma de Pagamento é obrigatório.'),
      valor: Yup.string().required('Valor é obrigatório').typeError('Valor é obrigatório'),
      valorCusto: Yup.string().required('Custo é obrigatório.').typeError('Custo é obrigatório.'),
      multaJuros: Yup.string().required('Multa e Juros é obrigatório.').typeError('Multa e Juros é obrigatório.'),
      encargo: Yup.string().required('Encargo é obrigatório.').typeError('Encargo é obrigatório')

    });
  }, [])

  return {
    FormFaturaValidationYup,
  }
}

