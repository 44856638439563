import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  rootAccordionNoPaper: {
    boxShadow: "unset",
    paddingLeft: "0px",
    paddingRight: "0px",
    background: 'transparent',

  },
  rootAccordion: {
    boxShadow: '1px 2px 3px 1px #00000030',
  },
  containerTitle: {
    "& svg": {
      width: 24,
    },
    paddingLeft: '19px',
  },
  title: {
    fontSize: '20px',
    color: '#7A7A7A',
    fontWeight: 600
  },
  accordionContent: {},
  accordionContentNoPadding: {
    paddingLeft: "0px",
    paddingRight: "0px",
    textTransform: "capitalize",
  },
  accordionSumaryContent: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),

  },
  accordionSumaryContentNoPadding: {},
  expanded: {
    height: "100%",
  },
  divider: {
    width: '100%',
    height: '2px',
    marginTop: '10px',
    marginBottom: '10px',
    backgroundColor: theme.palette.primary.main
  },
  iconWithoutRotation: {
    "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
      // transform: "rotate(0deg)",
    }
  }
}));
