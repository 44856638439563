import {
  useCallback,
  FocusEvent,
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  retornoAutoComplete,
  AutocompleteSaurus,
} from '../autocomplete-saurus/autocomplete-saurus';
import { PessoasModel } from 'model/api/gestao/pessoa/pessoas-model';
import { isEmpty } from 'lodash';
import { useGetPessoaById } from 'data/api/gestao/pessoa';
import { useGetAllComissionados } from 'data/api/gestao/pessoa/get-comissionado';

export interface AutocompleteComissionadoProps {
  label?: string;
  loadingExterno?: boolean;
  name?: string;
  value?: string;
  error?: boolean;
  helperText?: string | undefined;
  placeholder?: string;
  allowSubmit?: boolean;
  disabled?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onChange?: (value: retornoAutoComplete) => any;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
  fetchOnLoad?: boolean;
  query?: string;
  focus?: boolean;
  onClick?: (e?: any) => any;
}

export const AutocompleteComissionado = ({
  onChange,
  loadingExterno,
  fullWidth,
  ...props
}: AutocompleteComissionadoProps) => {
  // STATES E REFS
  const isGetCommisionados = useRef<boolean>(false);

  const [comissionadoState, setComissionadoState] = useState<
    Array<PessoasModel>
  >(new Array<PessoasModel>());
  const [comissionadoAuxState, setComissionadoAuxState] = useState<
    Array<PessoasModel>
  >(new Array<PessoasModel>());

  const [nomeValor, setNomeValor] = useState<string>('');
  const allowFetch = useRef<boolean>(true);

  // CHAMADAS API
  const { getPessoaById, carregando: carregandoPessoa } = useGetPessoaById();
  const { getAllComissionados, carregando: carregandoComissionados } =
    useGetAllComissionados();

  const getComissionadoById = useCallback(
    async (id: string) => {
      try {
        const res = await getPessoaById(id);

        if (res.erro) throw res.erro;

        if (!res.resultado) return;
        allowFetch.current = false;
        setComissionadoState([res.resultado.data]);
      } catch (e: any) {}
    },
    [getPessoaById],
  );

  const getComissionadosWrapper = useCallback(
    async () => {
      try {
        const ret = await getAllComissionados();
        if (ret.erro) throw ret.erro;

        let comissionados: Array<PessoasModel> = new Array<PessoasModel>();
        if (!ret.resultado) return;

        if (ret.resultado?.data?.length > 0) {
          comissionados = ret.resultado?.data as Array<PessoasModel>;
        }

        setComissionadoState(comissionados);
        setComissionadoAuxState(comissionados);
      } catch (e: any) {}
    },
    [getAllComissionados],
  );

  const searchComissionados = useCallback(
    (termo?: string) => {
      try {
        if (termo) {
          setComissionadoAuxState(() =>
            comissionadoState.filter((comissionado) =>
              comissionado.nomeComercial
                .toLocaleLowerCase()
                .includes(termo.toLocaleLowerCase()),
            ),
          );
        } else {
          setComissionadoAuxState(comissionadoState);
        }
      } catch (e: any) {}
    },
    [comissionadoState],
  );

  const onChangeWrapper = useCallback(
    (value: retornoAutoComplete) => {
      if (onChange) onChange(value);
    },
    [onChange],
  );

  const carregando =
    carregandoPessoa || loadingExterno || carregandoComissionados;

  const onPesquisa = useCallback(
    (termo?: string) => {
      searchComissionados(termo);
    },
    [searchComissionados],
  );

  useEffect(() => {
    if (!isEmpty(props.value) && props.fetchOnLoad && allowFetch.current) {
      getComissionadoById(props.value!);
    }

    if (!isGetCommisionados.current) {
      const getComissionados = async () => {
        await getComissionadosWrapper();
      };
      getComissionados();
      isGetCommisionados.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  useEffect(() => {
    if (props.value) {
      const valor = comissionadoState.find((item) => item.id === props.value);
      setNomeValor(valor?.nomeComercial ?? '');
      return;
    }
    setNomeValor('');
  }, [comissionadoState, props, props.fetchOnLoad, props.value]);

  return (
    <AutocompleteSaurus
      disabled={props.disabled}
      name={props.name}
      loading={carregando}
      onChange={onChangeWrapper}
      onPesquisa={onPesquisa}
      onClick={props.onClick}
      opcoes={comissionadoAuxState}
      optionLabelKey="nomeComercial"
      optionValueKey="nomeComercial"
      onBlur={props.onBlur}
      noOptionsText="Comissionado não localizado."
      value={nomeValor}
      allowSubmit={props.allowSubmit}
      label={props.label}
      helperText={props.helperText}
      error={props.error}
      placeholder={props.placeholder}
      fullWidth={fullWidth}
      size={props.size}
      focus={props.focus}
    />
  );
};
