import { useCallback } from "react";
import { EnumMenu } from 'model';
import { ModulosMock } from 'data/mocks';
import { useSessaoAtual } from "services/app";
import { EnumTipoCobrancaRevenda } from "model/enums/enum-tipo-cobranca-revenda";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";

export const useGetMenu = () => {
  const { tipoUsuario, usuario } = useSessaoAtual()

  const getMenu = useCallback((isMovAberta: boolean, modulos?: EnumMenu[]) => {

    let modulos_filtered = ModulosMock
      //FILTRO MODULOS QUE PEDIRAM PARA ESCONDER
      .filter(x => !modulos?.includes(x.codigoPai))
      //FILTRO MODULOS SEM PERMISSAO
      .filter(x => {
        if (tipoUsuario() === EnumTipoPessoas.Revendedor || tipoUsuario() === EnumTipoPessoas.Funcionario || tipoUsuario() === EnumTipoPessoas.FuncionarioFinanceiroSemCusto || tipoUsuario() === EnumTipoPessoas.FuncionarioFinanceiro || tipoUsuario() === EnumTipoPessoas.Representante) {
          if (usuario?.TipoCobrancaRevenda === EnumTipoCobrancaRevenda.ClienteFinal) {
            if (x.codigo === EnumMenu.REVENDEDORES_NOTASFISCAISREVENDA)
              return false
          }
          else {
            if (x.codigo === EnumMenu.REVENDEDORES_CLIENTEFATURAS)
              return false
          }
        }
        return true
      })
      .filter(x => x.permissoes.includes(tipoUsuario()))
      // .filter(x => { return validaModuloNasPermissoes(perfilAtual, x) })
      .sort((firstEl, secondEl) => firstEl.posicao - secondEl.posicao);

    //TIRO OS PAIS SEM FILHOS


    return modulos_filtered;
  }, [tipoUsuario, usuario?.TipoCobrancaRevenda]);

  return { getMenu };
};
