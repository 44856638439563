import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        position: "relative",
    },
    cardContent: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
    },
    label: {
        fontSize: '0.9rem',
        color: '#9D9D9D',
        lineHeight: '14,1px',
        textAlign: 'justify',
        marginRight: '3px'
    },
    qrcode: {
        display: 'flex',
        flexDirection: "column",
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(1)
    },
    qrText: {
        textAlign: 'center',
    },
    addForm: {
        alignSelf: 'stretch',
        display: 'flex',
        gap: '15px'
    },
    editContainer: {
        position: 'absolute',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        zIndex: 3
    },
    noPosition: {
        position: 'unset'
    },
    btnPesquisa: {
        marginLeft: theme.spacing(1),
        width: "40px",
        minWidth: "40px",
        height: "40px",
        padding: 0,
        "& svg": {
            margin: 0,
        }
    }
}));
