import { Grid, Tooltip, Typography, useTheme } from '@material-ui/core';
import { DefaultCard } from '../components/default-card';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { useCallback } from 'react';
import { EnumSituacaoPessoa } from 'model/enums/enum-situacao-pessoa';
import { useThemeQueries } from 'views/theme';
import { EditarIcon } from 'views/components/icons';
import { CardAgenteExternoProps } from './card-agente-externo-props';
import { useStyles } from './card-agente-externo-styles';

export const CardAgenteExterno = ({
    model,
    onClick,
    onCheck,
    selected,
}: CardAgenteExternoProps) => {
    const { palette } = useTheme()
    const { isMobile } = useThemeQueries()

    const classes = useStyles()

    const statusColor = useCallback(() => {
        switch (model.status) {
            case 0:
                return palette.success.main
            case 1:
                return palette.warning.main
            case 2:
                return palette.error.main
        }
    }, [model.status, palette.error.main, palette.success.main, palette.warning.main])

    return (
        <>
            <DefaultCard
                isSelected={selected}
                onClick={() => {
                    onClick(model.id);
                }}
            >
                <Tooltip title={EnumSituacaoPessoa[model.status]} placement='right'>
                    <div style={{
                        backgroundColor: statusColor(),
                        width: '5px',
                        borderRadius: '5px',
                        alignSelf: 'stretch',
                        margin: '7px'
                    }} />
                </Tooltip>
                <Grid container spacing={1} className={classes.container} alignItems='center'>
                    <Grid item xs={12} md={6} lg={3}>
                        <Typography color="textPrimary" variant="caption">
                            CPF/CNPJ: {formatarCPFCNPJ(model.cpfCnpj)}
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                        >
                            <b>{model.nomeComercial}</b>
                        </Typography>
                    </Grid>
                    {model.revendaId && <Grid item xs={6} md={6} lg={2}>
                        <Typography color="textPrimary" variant="caption">
                            Representante
                        </Typography>
                        <Typography color="textPrimary" variant="body1"
                            style={{ fontWeight: 500 }}
                        >
                            {model.revendaNome}
                        </Typography>
                    </Grid>}
                    <Grid item lg={4} md={6} xs={6}>
                        <Typography color="textPrimary" variant="caption">
                            Endereço:
                        </Typography>
                        <Typography color="textPrimary" variant="body1"
                        >
                            {(!model.informacoes || !model.informacoes.logradouro || !model.informacoes.numero) ? 'Não informado' :
                                <>
                                    {model.informacoes.logradouro}, {model.informacoes.numero}{' '}
                                    {model.informacoes.bairro || ''} - {model.informacoes.uf || ''}
                                </>}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={6} lg={model.revendaId ? 2 : 3}>
                        <Typography color='textPrimary' variant='caption' style={{ fontSize: 11 }}>
                            Email
                        </Typography>
                        <Typography color='textPrimary' variant='body1' style={{
                            wordBreak: 'break-word',
                            paddingRight: '1rem'
                        }}>
                            {!model.informacoes || !model.informacoes.email ? 'Não informado' : model.informacoes.email}
                        </Typography>
                    </Grid>
                    {!isMobile && <Grid md={6} xs={2} lg={model.revendaId ? 1 : 2}>
                        <Typography color='textPrimary' variant='caption'>
                            Telefone
                        </Typography>
                        <Typography color='textPrimary' variant='body1'>
                            {!model.informacoes || !model.informacoes.telefone ? 'Não informado' : model.informacoes.telefone}
                        </Typography>
                    </Grid>}
                </Grid>
                {!isMobile &&
                    <Tooltip arrow title={'Editar Agente Externo'}>
                        <div className={classes.cardRightButton}>
                            <EditarIcon tipo="BUTTON" />
                        </div>
                    </Tooltip>
                }
            </DefaultCard>
        </>
    );
};
