import { makeStyles } from "@material-ui/core";
import ImagemMapa from 'assets/img/mapa.png'

export const useStyles = makeStyles((theme) => ({
  mapContainer: {
    cursor: 'pointer',
    padding: '7px',
    border: '2px solid #D9D9D9',
    '& hr': {
        margin: '10px 0',
        background: theme.palette.primary.main
    },
    '& img': {
      width: '100%',
      height: '100%',
    }
  },
  mapContainerExpanded: {
    position: 'absolute',
    width: '100%',
    top: '15px',
    bottom: 0,
    left: 0,
    right: 0
  },
  mapImage: {
    backgroundImage: `url(${ImagemMapa})`,
    width: '100%',
    height: 'calc(100% - 50px)',
    maxHeight: '510px',
    backgroundPosition: 'center',
    backgroundSize: 'cover', 
    backgroundRepeat: 'no-repeat',
    boxShadow: '0px 0px 17px 10px inset rgb(255 255 255 / 50%)',
    border: '1px solid #ddd'
  }
}));
