import MenuItem from "@material-ui/core/MenuItem";
import { KeyValueModel } from "model/api";
import { Box, Divider, TextField, TextFieldProps, Typography } from "@material-ui/core";
import { useCallback, useState } from "react";
import { useStyles } from "./select-saurus-styles"
import { useThemeQueries } from "views/theme";
import { isEmpty } from "lodash";
import { CadeadoIcon } from "views/components/icons";
export type SelectSaurusProps = TextFieldProps & {
  conteudo: KeyValueModel[];
  divisoria?: boolean;
  allowSubmit?: boolean;
  readOnly?: boolean;
};

export const SelectSaurus = ({ conteudo, allowSubmit, divisoria, readOnly, ...props }: SelectSaurusProps) => {
  const [open, setOpen] = useState(false);
  const { theme } = useThemeQueries()
  const classes = useStyles({
    disabled: props.disabled
  });

  const onKeyDownWrapper = useCallback(
    (event: any) => {
      if (event.keyCode === 13) {
        if (open) {
          const input = event.currentTarget.parentElement.getElementsByTagName("input")[0];

          const form = input.form;
          const index = Array.prototype.indexOf.call(form.querySelectorAll("input,select,button"), input);

          const focusableElements = 'a:not([disabled]), button:not([disabled]), input:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';

          event.currentTarget.className = event.currentTarget.className.replace("Mui-focused", "");

          if (index === form.querySelectorAll(focusableElements)) {
            setTimeout(() => {
              form.querySelectorAll(focusableElements)[0].focus();
            }, 10);
          } else {
            setTimeout(() => {
              let inputTarget = form.querySelectorAll(focusableElements)[index + 1];
              if (inputTarget === undefined || inputTarget === null) {
                inputTarget = form.querySelectorAll(focusableElements)[index];
              }

              if (inputTarget?.type === "submit" && allowSubmit) {
                inputTarget?.click();
              } else {
                inputTarget?.focus();
              }
            }, 10);
          }
        }
      }
    },
    [open, allowSubmit]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const selecionado = conteudo.find(x => x.Key === props.value);
  return (
    <TextField
      select
      SelectProps={{
        value: props.value,
        open: open,
        onClose: handleClose,
        onOpen: handleOpen,
        style: props.style,
        className: classes.disabledSelect,
        readOnly: readOnly,
        endAdornment: props.disabled ? <CadeadoIcon tipo="INPUT" fill={theme.palette.text.disabled} /> : undefined
      }}
      onKeyDown={onKeyDownWrapper}
      label={props.label}
      inputProps={{ MenuProps: { disableScrollLock: true } }}
      {...props}
      value={selecionado?.Value || ''}
      style={{ position: 'relative' }}
    >
      {conteudo.map((item) => {
        if (item.Key === -5) {
          return (
            <Box m={1}>
              {!isEmpty(item.Value) && <Typography variant='caption' color='primary'>{item.Value}</Typography>}
              <Divider style={{ background: theme.palette.primary.light }} />
            </Box>
          )
        }
        return (
          <MenuItem className={classes.menuItem} key={item.Key} value={item.Key}>
            {item.Value}
          </MenuItem>
        );
      })}
    </TextField>
  );
};
