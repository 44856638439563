import { useCallback } from "react";
import { PrivatePageHeader } from "views/components/headers";
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { FiltroIcon, MenuIcon, VoltarIcon } from "views/components/icons";
import { useMenuPrincipal } from "services/app";
import { useThemeQueries } from "views";
import { useHistory, useLocation } from "react-router-dom";
import { IndicacoesListSearchProps } from "../indicacoes-tabs/components/indicacoes-list-seach-props";
import { SetaCimaIcon } from "views/components/icons/seta-cima-icon";
import { useHeaderStyles } from "views/components/headers/header-private-page/header-styles";
import { PesquisaIndicacoesDisponiveis } from "./components/pesquisa-indicacoes-disponiveis/pesquisa-indicacoes-disponiveis";
import { isEmpty } from "lodash";

export interface IndicacoesHeaderProps {
  openPesquisa: boolean;
  setOpenPesquisa: (state: boolean) => any;
  setSearchProps: React.Dispatch<React.SetStateAction<IndicacoesListSearchProps>>;
  titulo: string;
  status: string;
}

export interface LocationProps {
  from: {
    pathname: string,
    page?: number;
  }
}

export const IndicacoesHeader = ({
  openPesquisa,
  setOpenPesquisa,
  setSearchProps,
  titulo,
  status
}: IndicacoesHeaderProps) => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile, theme } = useThemeQueries();
  const history = useHistory()
  const classes = useHeaderStyles();
  const { state } = useLocation<LocationProps>()

  const onPesquisar = useCallback((termo: string) => {
    setSearchProps(prev => ({
      ...prev,
      termo
    }))
  }, [setSearchProps])

  const leftArea = useCallback(
    () =>
      isMobile ?
        (titulo === 'Indicações') ? (
          <ButtonPrivateHeader
            icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
            tooltip="Menu"
            onClick={abrirMenu}
          ></ButtonPrivateHeader>
        ) : (
          <ButtonPrivateHeader
            icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
            tooltip="Voltar"
            onClick={() => {
              if (!state?.from?.pathname || isEmpty(state.from.pathname)) {
                history.push('/indicacoes/' + status)
                return
              }
              if (state.from.page) {
                history.push('/indicacoes/' + status + '?page=' + state.from.page)
                return
              }
              history.goBack()
            }}
          />
        )
        : (titulo !== "Indicações") ? (
          <ButtonPrivateHeader
            icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
            tooltip="Voltar"
            onClick={() => {
              if (!state?.from?.pathname || isEmpty(state.from.pathname)) {
                history.push('/indicacoes/' + status)
                return
              }
              if (state.from.page) {
                history.push('/indicacoes/' + status + '?page=' + state.from.page)
                return
              }
              history.goBack()
            }}
          />
        ) : null,
    [isMobile, titulo, abrirMenu, state, history, status]
  );

  const rightArea = useCallback(
    () => titulo === "Indicações" && (status === 'aceitas' || status === 'em-validacao' || status === 'finalizadas') ? (
      isMobile ? (
        <ButtonPrivateHeader
          icon={
            openPesquisa ? (
              <SetaCimaIcon tipo="PRIVATE_HEADER"></SetaCimaIcon>
            ) : (
              <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>
            )
          }
          tooltip={!openPesquisa ? "Pesquisar" : "Fechar Pesquisa"}
          onClick={() => setOpenPesquisa(!openPesquisa)}
        ></ButtonPrivateHeader>
      ) :
        <ButtonPrivateHeader
          className={classes.pesquisaBox}
          text={!openPesquisa ? 'Filtros' : 'Fechar Filtros'}
          icon={
            openPesquisa ? (
              <SetaCimaIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main}></SetaCimaIcon>
            ) : (
              <FiltroIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main}></FiltroIcon>
            )
          }
          tooltip={!openPesquisa ? "Pesquisar" : "Voltar"}
          onClick={() => setOpenPesquisa(!openPesquisa)}
        ></ButtonPrivateHeader>
    ) : titulo === 'Indicações' ? isMobile ? (
      <ButtonPrivateHeader
        icon={
          openPesquisa ? (
            <SetaCimaIcon tipo="PRIVATE_HEADER"></SetaCimaIcon>
          ) : (
            <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>
          )
        }
        tooltip={!openPesquisa ? "Pesquisar" : "Fechar Pesquisa"}
        onClick={() => setOpenPesquisa(!openPesquisa)}
      ></ButtonPrivateHeader>
    ) : (
      <PesquisaIndicacoesDisponiveis
        isOpened={!isMobile}
        onCloseSearch={() => { }}
        onPesquisar={onPesquisar}
      />
    ) : null,
    [titulo, status, isMobile, openPesquisa, classes.pesquisaBox, theme.palette.primary.main, onPesquisar, setOpenPesquisa]
  );

  const bottomArea = useCallback(() => status === 'disponiveis' ? (
    <PesquisaIndicacoesDisponiveis
      isOpened={openPesquisa}
      onCloseSearch={() => { }}
      onPesquisar={onPesquisar}
    />
  ) : null, [onPesquisar, openPesquisa, status])

  return (
    <>
      <PrivatePageHeader
        title={titulo}
        leftArea={leftArea()}
        rightArea={rightArea()}
        bottomArea={isMobile ? bottomArea() : undefined}
      />
    </>
  );
};
