import { KeyValueModel } from "model/api";
import { EnumInstituicao } from "model/enums/enum-instituicao";


export const InstituicaoPagamentoMock: KeyValueModel[] = [
  new KeyValueModel(EnumInstituicao.Itau, 'Itaú'),
  new KeyValueModel(EnumInstituicao.Safra, 'Safra')
  // new KeyValueModel(EnumInstituicao.BANCO_DO_BRASIL, 'Banco do Brasil')
];

export const InstituicaoPagamentoTodosMock: KeyValueModel[] = [
  new KeyValueModel(EnumInstituicao.Itau, 'Itaú'),
  new KeyValueModel(EnumInstituicao.Safra, 'Safra'),
  new KeyValueModel(EnumInstituicao.BANCO_DO_BRASIL, 'Banco do Brasil')
]
