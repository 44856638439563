import { Grid, Tooltip, Typography } from '@material-ui/core';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useThemeQueries } from 'views/theme';
import { EditarIcon } from 'views/components/icons';
import { ClienteIdealPerguntaModel } from 'model/api/gestao/cliente-ideal/cliente-ideal-pergunta-model';
import { useStyles } from './card-apuracao-pergunta-styles'

interface CardApuracaoPerguntaProps {
    model: ClienteIdealPerguntaModel;
    onClick: (model: ClienteIdealPerguntaModel) => void;
}

export const CardApuracaoPergunta = ({
    model,
    onClick,
}: CardApuracaoPerguntaProps) => {
    const classes = useDefaultCardStyles();
    const cardClasses = useStyles({
        ativo: model.ativo
    });
    const { theme } = useThemeQueries()

    return (
        <>
            <DefaultCard
                onClick={() => {
                    onClick(model);
                }}
                className={cardClasses.card}
            >
                <Tooltip title={model.ativo ? 'Pergunta Ativa' : 'Pergunta Desativada'} placement='right'>
                    <div className={cardClasses.status} />
                </Tooltip>
                <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={6} md={2}>
                        <Typography color="primary" variant="caption">
                            Pergunta
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                            className={cardClasses.boldText}
                        >
                            {model.pergunta}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Typography color="primary" variant="caption">
                            Detalhes
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                            className={cardClasses.ellipsisText}
                        >
                            {model.detalhe}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Typography color="primary" variant="caption">
                            Perguntas
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                        >
                            {model.respostas.length}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Typography color="primary" variant="caption">
                            Máximo de Pontos
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                        >
                            {model.maxPontos}
                        </Typography>
                    </Grid>
                </Grid>
                <Tooltip arrow title="Editar Pergunta">
                    <div className={classes.cardRightButton} style={{ marginLeft: '10px' }}>
                        <EditarIcon tipo="BUTTON" fill={theme.palette.primary.main} />
                    </div>
                </Tooltip>
            </DefaultCard>
        </>
    );
};