import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { MenuOptionsProps } from "./menu-options-props";
import { Box, Divider, Typography } from "@material-ui/core";
import { AvancarIcon } from "../icons";
import { useStyles } from "./menu-options-styles";
import { useThemeQueries } from "views/theme";

export const MenuOptions = ({
  icon,
  iconButton,
  titulo,
  descricao,
  options,
  headerPage = false,
  dark = false,
  setaAvancar = false,
}: MenuOptionsProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const { theme } = useThemeQueries();

  const onClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const wrapperClick = (click: () => any) => {
    click();
    handleClose();
  };

  return (
    <div>
      {iconButton &&
        <IconButton aria-label="more" aria-haspopup="true" onClick={onClick}>
          {icon && (
            icon
          )}
        </IconButton>
      }

      {anchorEl &&
        <Box className={classes.layer}>
        </Box>
      }

      <div className={open ? classes.menu : ''}>
        <Menu
          anchorEl={anchorEl}
          keepMounted={false}
          open={open}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handleClose}
          PaperProps={{
            style: {
              boxShadow: theme.shadows[0],
              transform: 'translateX(0px) translateY(-120px)',
              zIndex: 60,
            }
          }}
        >

          {titulo && (
            <>
              <div style={{
                paddingLeft: '16px',
                paddingRight: '16px',
                paddingBottom: '16px',
              }}>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  style={{ paddingTop: '8px' }}
                >
                  <Box fontWeight={600}>
                    {titulo}
                  </Box>
                </Typography>

                {descricao && (
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    style={{ paddingBottom: '8px' }}
                  >
                    {descricao}
                  </Typography>
                )}

                <Divider variant="fullWidth" style={{ background: theme.palette.grey[400] }} />
              </div>
            </>
          )}

          {options.map((option, index) => (
            <MenuItem key={index} style={{
              justifyContent: 'space-between',
              minWidth: setaAvancar ? 200 : 'auto',
              alignItems: 'center'
            }} onClick={() => wrapperClick(option.click!)}>
              <Box display={"flex"} alignItems={"center"}>
                {option.icon}
                {option.label}
              </Box>
              {setaAvancar && (
                <div className={classes.icon}>
                  <AvancarIcon tipo="BUTTON" />
                </div>
              )}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </div>
  );
};
