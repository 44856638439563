import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { guidEmpty } from "utils/guid-empty";
import { PessoaInfoModel } from "./pessoa-info-model";

export class PessoasModel {
    constructor(
        public id: string = guidEmpty(),
        public tipo: EnumTipoPessoas = EnumTipoPessoas.Cliente,
        public nomeComercial: string = '',
        public responsavel: string = '',
        public cpfCnpj: string = '',
        public status: number = 0,
        public revendaId: string = guidEmpty(),
        public revendaNome: string = '',
        public informacoes: PessoaInfoModel = new PessoaInfoModel(),
        public gerenteId: string = '',
        public vendedorId: string = '',
        public gerenteNome: string = '',
        public vendedorNome: string = '',
    ) { }
}