import { Button, Grid } from '@material-ui/core';
import { useState, useImperativeHandle, forwardRef } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses } from 'views';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { picker } from 'utils/picker';
import { Controller, useForm } from 'react-hook-form';
import { ContratoCancelamentoModel } from 'model/app/forms/contrato-cancelamento/contrato-cancelamento-model';
import { MotivoCancelamentoMock } from 'data/mocks/motivo-cancelamento-mock';
import { EnumMotivoCancelamento } from 'model/enums/enum-motivo-cancelamento';
import { useStyles } from './form-cancelar-contrato-styles';
import classNames from 'classnames';

export const FormCancelarContrato = forwardRef<
  DefaultFormRefs<ContratoCancelamentoModel>,
  DefaultFormProps<ContratoCancelamentoModel>
>(({ loading, ...props }: DefaultFormProps<ContratoCancelamentoModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const [modelForm, setModelForm] = useState<ContratoCancelamentoModel>(
    new ContratoCancelamentoModel(),
  );
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    setValue,
    getValues,
    reset,
  } = useForm<ContratoCancelamentoModel>({
    defaultValues: { ...modelForm },
    criteriaMode: 'all',
    mode: 'onChange',
  });
  const [, setUpdate] = useState<boolean>(false)

  const onSubmit = async (values: ContratoCancelamentoModel) => {
    const model = picker<ContratoCancelamentoModel>(values, new ContratoCancelamentoModel());
    props.onSubmit(model, modelForm);
  };

  const opcaoSelecionada = Number(getValues('indCancelamento')) as EnumMotivoCancelamento;
  const opcoesOutroMotivo = [
    EnumMotivoCancelamento.TrocaOutroSistema,
    EnumMotivoCancelamento.FaltaRecursoSaurus,
    EnumMotivoCancelamento.Outros,
  ]

  const clickOpcao = (opcao: EnumMotivoCancelamento) => {
    setValue('indCancelamento', opcao);
    setUpdate(prev => !prev);

    if (!opcoesOutroMotivo.includes(opcao)) {
      setValue('motivoCancelamento', MotivoCancelamentoMock[opcao].Value);
      handleSubmit(onSubmit)();
    }
  }

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
    },
    fillForm: (model: ContratoCancelamentoModel) => {
      setModelForm(model);
      reset({ ...model });
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {loading && props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={loading ? utilClasses.controlLoading : ''}
        >
          <Grid container >
            {!opcaoSelecionada && (
              <>
                {MotivoCancelamentoMock.map((motivo, index) => {
                  return <Grid item xs={12} md={12}>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classNames(classes.botaoMotivo, motivo.Key === opcaoSelecionada ? classes.botaoMotivoSelecionado : '')}
                      fullWidth
                      onClick={() => {
                        clickOpcao(motivo.Key as EnumMotivoCancelamento);
                      }}
                    >
                      {Number(motivo.Key)} - {motivo.Value}
                    </Button>
                  </Grid>

                })}
              </>
            )}
            {
              opcoesOutroMotivo.includes(opcaoSelecionada) && (
                <Grid item xs={12}>
                  <Controller
                    name="motivoCancelamento"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="TEXTO"
                        fullWidth
                        allowSubmit={true}
                        disabled={loading}
                        label={
                          opcaoSelecionada === EnumMotivoCancelamento.FaltaRecursoSaurus ? "Informe o Recurso que era Necessário" :
                            opcaoSelecionada === EnumMotivoCancelamento.TrocaOutroSistema ? "Informe para qual Sistema foi Trocado" :
                              "Informe o motivo do cancelamento"}
                        multiline
                        minRows={3}
                        variant="outlined"
                        error={Boolean(errors.motivoCancelamento && errors.motivoCancelamento.message)}
                        helperText={
                          touchedFields.motivoCancelamento || errors.motivoCancelamento
                            ? errors.motivoCancelamento?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
              )
            }
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
