
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormPagamentoComissaoValidation = () => {


  const FormPagamentoComissaoValidationYup = useMemo(() => {
    return Yup.object().shape({
      
    });
  }, [])

  return {
    FormPagamentoComissaoValidationYup,
  }
}

