import { Button, Fade, Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { AutocompleteComissionado } from 'views/components/controles/autocompletes/autocomplete-comissionado/autocomplete-comissionado';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { ProcurarIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import { ComissoesListSearchProps } from '../../comissoes-tabs/comissoes-list-search-props';
import { useStyles } from './pesquisa-comissoes-styles';

interface PesquisaHeaderProps {
  onPesquisar: (props: ComissoesListSearchProps) => any;
  onCloseSearch: () => void;
  searchProps: ComissoesListSearchProps;
  isOpened: boolean;
}

export default function PesquisaComissoesPagas(props: PesquisaHeaderProps) {
  const { showToast } = useToastSaurus();
  const classes = useStyles();
  const { theme, isMobile } = useThemeQueries();
  const location = useLocation();
  const { tipoUsuario } = useSessaoAtual();


  const pesquisaInicial = useMemo<ComissoesListSearchProps>(() => {
    return {
      comissionadoId: '',
      comissionadoCpfCnpj: '',
      dPagFinal: '',
      dPagInic: '',
    };
  }, []);

  const [pesquisa, setPesquisa] =
    useState<ComissoesListSearchProps>(pesquisaInicial);

  const onChangePesquisa = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPesquisa((prev) => {
        return {
          ...prev,
          [event.target.name]: event.target.value,
        };
      });
    },
    [],
  );

  const pesquisar = useCallback(
    (event?: any, searchProps?: ComissoesListSearchProps) => {
      if (event) event.preventDefault();

      if (new Date(pesquisa.dPagInic) > new Date(pesquisa.dPagFinal)) {
        showToast('error', 'Data Inicial é maior do que a data final.');
        return;
      }
      props.onPesquisar(searchProps || pesquisa);
    },
    [pesquisa, props, showToast],
  );

  useEffect(() => {
    setPesquisa({
      comissionadoId: '',
      comissionadoCpfCnpj: '',
      dPagFinal: '',
      dPagInic: '',
    });
  }, [location.pathname]);

  const isSoftwareHouse = (
    ([EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto].includes(tipoUsuario()))
  )

  return (
    <Fade in={props.isOpened} mountOnEnter unmountOnExit>
      <form onSubmit={pesquisar} className={classes.root}>
        <Grid container spacing={2}>
          {(isSoftwareHouse) && <Grid item xs={12} md={4}>
            <AutocompleteComissionado
              value={
                isEmpty(pesquisa.comissionadoId)
                  ? props.searchProps.comissionadoId
                  : pesquisa.comissionadoId
              }
              label="Comissionado"
              size="small"
              name="comissionadoId"
              onChange={(e) => {
                setPesquisa((prev) => ({
                  ...prev,
                  comissionadoId: e.value.id,
                }));
              }}
            />
          </Grid>}

          <Grid item xs={6} md={3}>
            <TextFieldSaurus
              name="dPagInic"
              fullWidth
              label="Data de Pgto. Inicial"
              size="small"
              tipo="DATA"
              allowSubmit={true}
              value={pesquisa.dPagInic}
              onChange={onChangePesquisa}
              adornmentColor={theme.palette.text.disabled}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <TextFieldSaurus
              name="dPagFinal"
              fullWidth
              label="Data de Pgto. Final"
              size="small"
              tipo="DATA"
              allowSubmit={true}
              value={pesquisa.dPagFinal}
              onChange={onChangePesquisa}
              adornmentColor={theme.palette.text.disabled}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={(isSoftwareHouse) || isMobile ? 2 : 6}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button color="primary" variant="contained" type="submit" fullWidth={(isSoftwareHouse) || isMobile}>
              <ProcurarIcon tipo="BUTTON_PRIMARY" />
              Pesquisar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Fade>
  );
}
