import { Grid } from "@material-ui/core";
import { ApiListModel } from "model/api/gestao/api-list-model/api-list-model";
import { useCallback, useEffect, useState } from "react";
import { useCadastros, useToastSaurus } from "services/app";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus";
import { useThemeQueries } from "views/theme";
import { useGetPessoaWhitelabel } from "data/api/gestao/pessoa-whitelabel";
import { PessoaWhitelabelModel } from "model/api/gestao/pessoa/pessoa-whitelabel-model";
import { CircularLoading } from "views/components";
import { useGetPessoaSistema } from "data/api/gestao/pessoa-sistema";
import { AccordionWhitelabelListData } from "./accordion-whitelabel-list-data";
import { PessoaSistemaModel } from "model/api/gestao/pessoa/pessoa-sistema-model";
import { SistemaWhitelabelArray } from "data/mocks/sistema-id-mock";

type Props = {
    id: string;
}

export const AccordionWhitelabel = ({ id }: Props) => {
    const { theme } = useThemeQueries()
    const [openAccordion, setOpenAccordion] = useState<boolean>(false)
    const { showToast } = useToastSaurus()
    const { abrirCadastroWhitelabel, cadastroWhitelabelState } = useCadastros();

    const { getPessoaWhitelabel, carregando: carregandoGet } = useGetPessoaWhitelabel()
    const { getPessoaSistema, carregando: carregandoPessoaSistema } = useGetPessoaSistema();

    const [queryStatus, setQueryStatus] = useState<ApiListModel<PessoaSistemaModel>>({
        pageIndex: 1,
        totalPages: 0,
        totalResults: 0,
        pageSize: 0,
        list: Array<PessoaSistemaModel>(),
    });
    const [whitelabels, setWhitelabels] = useState<PessoaWhitelabelModel[]>([])

    const fillResult = useCallback(
        async (
            page: number,
            totalPages: number,
            totalResults: number,
            list: Array<PessoaSistemaModel>
        ) => {
            setQueryStatus({
                pageIndex: page,
                list: list,
                totalResults: totalResults,
                totalPages: totalPages,
                pageSize: 0
            });
        },
        []
    );

    const carregando = carregandoGet || carregandoPessoaSistema

    const getWhitelabelWrapper = useCallback(async () => {
        const res = await getPessoaWhitelabel(id, 1, '&pageSize=0')

        if (res.erro) throw res.erro
        if (!res.resultado) return

        setWhitelabels(res.resultado.data.list)

    }, [getPessoaWhitelabel, id])

    const getSistemasWrapper = useCallback(async () => {

        const res = await getPessoaSistema(id, 1, '&pageSize=0');

        if (res.erro) throw res.erro

        if (res.resultado?.data === undefined) {
            return
        }

        fillResult(
            res.resultado?.data.pageIndex,
            res.resultado?.data.totalPages,
            res.resultado?.data.totalResults,
            res.resultado.data.list.filter((x) => {
                return SistemaWhitelabelArray.includes(x.sistema.id)
            })
        );

    }, [fillResult, getPessoaSistema, id])

    useEffect(() => {
        if (openAccordion && !cadastroWhitelabelState.aberto) {
            (async () => {
                await getSistemasWrapper()
                    .catch(err => {
                        showToast('error', err.message)
                    })
                await getWhitelabelWrapper().catch(err => showToast('error', err.message))
            })()
        }
    }, [cadastroWhitelabelState.aberto, getPessoaWhitelabel, getSistemasWrapper, getWhitelabelWrapper, openAccordion, queryStatus.pageIndex, showToast])

    const onClickCard = useCallback((model: PessoaSistemaModel, existe: boolean) => {
        if (existe) {
            const wlId = whitelabels.find(x => x.sistemaId === model.sistema.id)?.id;
            abrirCadastroWhitelabel(wlId || '', id, model.sistema.id)
            return
        }

        abrirCadastroWhitelabel('', id, model.sistema.id);
    }, [abrirCadastroWhitelabel, id, whitelabels])

    return (
        <>
            <AccordionSaurus
                labelPrimary="Configuração Whitelabel"
                tipoExpand="bold"
                noPaperRoot={false}
                heightDivider={2}
                showDivider={openAccordion}
                colorDivider={theme.palette.primary.main}
                colorExpand={theme.palette.primary.main}
                expanded={openAccordion}
                onChange={() => setOpenAccordion(prev => !prev)}
            >
                {carregando && <CircularLoading tipo='FULLSIZED' />}
                <Grid container spacing={1}>
                    <AccordionWhitelabelListData
                        carregando={carregando}
                        list={queryStatus.list}
                        onCardClick={onClickCard}
                        whitelabels={whitelabels}
                    />
                </Grid>
            </AccordionSaurus>
        </>
    );
};