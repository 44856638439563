import { PlanosModel } from "model/api/gestao/plano/planos-model";
import { CardNaoEncontrado } from "views/components/cards";
import { CardPlanos } from "views/components/cards/card-planos";
import { InformacaoIcon } from "views/components/icons";

export interface PlanosGridProps {
  list: Array<PlanosModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (id: string) => any;
  onCardChecked: (id: string) => any;
}

export const PlanosListData = (props: PlanosGridProps) => {

  const onCardSelected = (id: string) => {
    props.onCardSelected(id);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhum Plano Encontrado."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props?.list.length > 0 &&
        props.list.map((plano, index) => {
          return (
            <CardPlanos
              selected={
                props.selectedList.filter((item) => item === plano.id).length >
                  0
                  ? true
                  : false
              }
              onCheck={onCardChecked}
              onClick={onCardSelected}
              model={plano}
              key={index}
            />
          );
        })}
    </>
  );
};
