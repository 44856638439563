import { Button, Grid, Fade } from '@material-ui/core';
import { useCallback, useRef, useEffect } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { ProcurarIcon } from 'views/components/icons';
import { useThemeQueries } from 'views';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { PesquisaHeaderProps } from './mapa-clientes-pesquisa-props';
import { useStyles } from './mapa-clientes-pesquisa-styles'
import { Controller, useForm } from 'react-hook-form';
import { FiltroMapaModel } from 'model/app/forms/filtro-mapa/filtro-mapa-model';
import { KeyValueModel } from 'model';
import { guidEmpty } from 'utils/guid-empty';
import { TipoBuscaMapaMock } from 'data/mocks/tipo-busca-mapa-mock';
import { EnumTipoBuscaMapa } from 'model/enums/enum-tipo-busca-mapa';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { useSessaoAtual, useToastSaurus } from 'services/app';

export const MapaClientesPesquisa = (props: PesquisaHeaderProps) => {
    const {tipoUsuario} = useSessaoAtual()
    const {showToast} = useToastSaurus()

    const isSoftwareHouse = tipoUsuario() === EnumTipoPessoas.SoftwareHouse || tipoUsuario() === EnumTipoPessoas.GerenteComercial || tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouse || tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto;

    const classes = useStyles({isSoftwareHouse})
    const { isMobile, md } = useThemeQueries();
    const refInput = useRef<HTMLDivElement | null>(null);
    const sistemasMock = [new KeyValueModel(guidEmpty(), 'Todos'), ...props.sistemas];

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        setValue,
        getValues,
    } = useForm<FiltroMapaModel>({
        defaultValues: {
            dataAdesaoFinal: '',
            dataAdesaoInicial: '',
            sistemaId: sistemasMock[0].Key,
            tipoBuscaMapa: EnumTipoBuscaMapa.Clientes,
        },
        criteriaMode: 'all',
        mode: 'onChange',
    });

    //FOCO AO FICAR VISIVEL
    useEffect(() => {
        if (!isMobile) refInput.current?.focus();
    }, [isMobile]);

    const onSubmit = useCallback((values: FiltroMapaModel) => {
        if(new Date(values.dataAdesaoInicial) > new Date(values.dataAdesaoFinal)){
            showToast('error', 'Data de Adesão Inicial é maior do que a Data de Adesão Final.')
            return
        }
        props.onSubmit(values)
    }, [props, showToast])

    //CHAMADA DO PESQUISAR
    const pesquisar = useCallback(
        (event: any) => {
            event.preventDefault();
            handleSubmit(onSubmit)();
        },
        [handleSubmit, onSubmit],
    );

    return (
        <Fade in={props.isOpenned} mountOnEnter unmountOnExit>
        <form onSubmit={pesquisar}>
            <Grid container className={classes.root} spacing={2}>
                {(!md && !isSoftwareHouse) && <Grid item lg={2}></Grid>}
                <Grid xs={6} lg={2}>
                    <Controller
                        name="sistemaId"
                        control={control}
                        render={({ field }) => (
                            <SelectSaurus
                                conteudo={sistemasMock}
                                {...field}
                                variant="outlined"
                                label="Sistema"
                                fullWidth
                                size='small'
                                value={getValues('sistemaId')}
                                onChange={(event) => {
                                    if (event) {
                                        const item = props.sistemas.filter(
                                            (item) => item.Key === event.target.value,
                                        )[0];
                                        if (item) {
                                            return setValue('sistemaId', item.Key);
                                        }
                                        setValue('sistemaId', guidEmpty());
                                    }
                                }}
                                helperText={
                                    touchedFields.sistemaId && errors.sistemaId
                                        ? errors.sistemaId.message
                                        : undefined
                                }
                                error={Boolean(
                                    errors.sistemaId && errors.sistemaId.message,
                                )}
                            />
                        )}
                    />
                </Grid>
                {isSoftwareHouse && <Grid xs={6} lg={2}>
                    <Controller
                        name='tipoBuscaMapa'
                        control={control}
                        render={({ field }) => (
                            <SelectSaurus
                                conteudo={TipoBuscaMapaMock}
                                {...field}
                                variant="outlined"
                                label="Buscar por"
                                fullWidth
                                size='small'
                                value={getValues('tipoBuscaMapa')}
                                onChange={(event) => {
                                    if (event) {
                                        const item = TipoBuscaMapaMock.filter(
                                            (item) => item.Key === event.target.value,
                                        )[0];
                                        if (item) {
                                            return setValue('tipoBuscaMapa', item.Key);
                                        }
                                        setValue('tipoBuscaMapa', EnumTipoBuscaMapa.Clientes);
                                    }
                                }}
                                helperText={
                                    touchedFields.tipoBuscaMapa && errors.tipoBuscaMapa
                                        ? errors.tipoBuscaMapa.message
                                        : undefined
                                }
                                error={Boolean(
                                    errors.tipoBuscaMapa && errors.tipoBuscaMapa.message,
                                )}
                            />
                        )}
                    />
                </Grid>}
                <Grid xs={6} lg={3}>
                    <Controller
                        name="dataAdesaoInicial"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                fullWidth
                                size='small'
                                tipo="DATA"
                                label="Adesão Inicial"
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid xs={6} lg={3}>
                    <Controller
                        name="dataAdesaoFinal"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                size='small'
                                tipo="DATA"
                                fullWidth
                                label="Adesão Final"
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid xs={isSoftwareHouse ? 12 : 6} lg={2} style={{display: 'flex', flexDirection: 'column'}}>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        className={classes.btnPesquisa}
                        fullWidth={true}
                    >
                        <ProcurarIcon tipo="BUTTON_PRIMARY" />
                        Pesquisar
                    </Button>
                </Grid>
            </Grid>
        </form>
        </Fade >
    );
};
