import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { makeUtilClasses } from 'views';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useForm, Controller } from 'react-hook-form';
import { picker } from 'utils/picker';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormAmbienteValidation } from './form-ambiente-validation';
import { AutocompleteRevendas } from 'views/components/controles/autocompletes/autocomplete-revendas/autocomplete-revendas';
import { AmbienteSistemaModel } from 'model/api/gestao/ambiente-sistema/ambiente-sistema-model';

export const FormAmbiente = forwardRef<
  DefaultFormRefs<AmbienteSistemaModel>,
  DefaultFormProps<AmbienteSistemaModel>
>(
  (
    { loading, onSubmit, ...props }: DefaultFormProps<AmbienteSistemaModel>,
    ref,
  ) => {
    const { FormAmbienteValidationYup } = useFormAmbienteValidation();
    const [model, setModel] = useState<AmbienteSistemaModel>(new AmbienteSistemaModel())

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
      setValue,
      getValues
    } = useForm<AmbienteSistemaModel>({
      resolver: yupResolver(FormAmbienteValidationYup),
      defaultValues: { ...model },
      criteriaMode: 'all',
      mode: 'onChange',
    });
    const utilClasses = makeUtilClasses();

    const submitNovoModulo = useCallback(
      (data: AmbienteSistemaModel) => {
        const model = picker<AmbienteSistemaModel>(data,
          new AmbienteSistemaModel())
        onSubmit(model);
      },
      [onSubmit],
    );

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitNovoModulo)();
      },
      resetForm: () => {

        reset();
      },
      fillForm: (values: AmbienteSistemaModel) => {
        setModel(values)
        reset({ ...values });
      },
    }));

    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer} style={{ position: 'relative' }}>
            {loading && props.showLoading ? (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="FULLSIZED" />
              </div>
            ) : null}

            <form
              onSubmit={handleSubmit(submitNovoModulo)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <Controller
                    name="nome"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="Nome"
                        tipo="TEXTO"
                        autoComplete='new-password'
                        allowSubmit
                        fullWidth={true}
                        error={Boolean(
                          errors.nome && errors.nome.message,
                        )}
                        helperText={
                          touchedFields.nome || errors.nome
                            ? errors.nome?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="valor"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="Valor"
                        tipo="TEXTO"
                        allowSubmit
                        fullWidth={true}
                        error={Boolean(
                          errors.valor && errors.valor.message,
                        )}
                        helperText={
                          touchedFields.valor || errors.valor
                            ? errors.valor?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="revendaId"
                      control={control}
                      render={({ field }) => (
                        <AutocompleteRevendas
                        label='Revendedor'
                        value={getValues('revendaId')}
                        defaultValue={model.revendaId}
                        fetchOnLoad
                        onChange={(e) => {
                          setValue('revendaId', e.value.id)
                        }}
                        helperText={
                              errors.revendaId
                                ? errors.revendaId.message
                                : undefined
                            }
                        error={Boolean(errors.revendaId && errors.revendaId.message)}
                        />
                      )}
                    />
                  </Grid>
              </Grid>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
