import { Theme, makeStyles } from "@material-ui/core";

interface StylesProps{
  expirado?: boolean;
}

export const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  cardContent: {
    padding: theme.spacing(1),
  },
  cardRightButton: {
    width: "50px",
    borderLeftWidth: 1,
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.divider,
    margin: '5px 0px 5px 10px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "& svg": {
      margin: 0,
      width: "22px",
      height: "22px",
      fill: theme.palette.primary.main,
    },
  },
  container: {
    '& p': {
      fontSize: 13
    }
  },
  item: {
    marginLeft: 7,
    minWidth: 100,
    maxWidth: 200,
    [theme.breakpoints.down('md')]: {
      margin: '4px 0',
      marginRight: theme.spacing(2)
    }
  },
  statusText: {
    borderRadius: '5px',
    padding: '5px 0px',
    textAlign: 'center',
    background: 'rgb(16, 200, 34)',
    color: 'rgb(255, 255, 255)',
    marginRight: '8px',
    fontSize: '11px',
    height: 'fit-content'
  },
  dividerMobile: {
    height: '2px',
  },
  warningIcon: {
    fill: ({expirado}) => expirado ? theme.palette.error.main : theme.palette.warning.dark,
    width: 20,
    height: 30,
    marginLeft: theme.spacing(1)
  }
}))