import { VariaveisAmbiente } from 'config';
import { NotaFiscalFormModel } from 'model/api/gestao/nota-fiscal/emitir-nota-fiscal-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { NotaFiscalModel } from 'model/api/gestao/nota-fiscal/nota-fiscal-model';

export function usePostEmitirNotaFiscal() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<NotaFiscalModel>();

    const postEmitirNotaFiscal = useCallback(
        (id: string, model: NotaFiscalFormModel) =>

            invocarApi({
                url: `/${VariaveisAmbiente.apiVersion}/fatura/${id}/nota-fiscal/emitir`,
                method: 'POST',
                data: model,
                enviarTokenUsuario: true,
                timeout: 100000
            }),

        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postEmitirNotaFiscal,
    };
}
