import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { RevendaDesempenhoAnoMesModel } from 'model/api/gestao/revendedor/revenda-desempenho-anomes-model';

export function useGetRevendaDesempenhoAnoMes() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<RevendaDesempenhoAnoMesModel[]>();

    const getRevendaDesempenhoAnoMes = useCallback(
        (anoMesApuracao: number, revendaId: string, anoMes: string, query?: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/resultados/revendas-desempenho/apuracao/${anoMesApuracao}/${revendaId}/mes/${anoMes}?${query || ''}`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getRevendaDesempenhoAnoMes,
    };
}
