import { guidEmpty } from "utils/guid-empty";
import { PagamentoModel } from "../pagamento/pagamento-model";

export class FaturasModel {
    constructor(
        public id: string = guidEmpty(),
        public contratoId: string = guidEmpty(),
        public faturaPaiId: string = guidEmpty(),
        public nossoNumero: number = 0,
        public situacao: number = 0,
        public sistemaId: string = guidEmpty(),
        public sistemaDescricao: string = '',
        public tipoFatura: number = 0,
        public dataEmissao: string = '',
        public dataVencimento: string = '',
        public dataRegistro: string = '',
        public dataPagamento: string = '',
        public competenciaAnoMes: number = 0,
        public descricao: string = '',
        public encargo: number = 0,
        public multaJuros: number = 0,
        public valor: number = 0,
        public valorCusto: number = 0,
        public valorPago: number = 0,
        public boletoNumero: number = 0,
        public boletoUrl: string = '',
        public pixCodigo: string = '',
        public dhExpiracaoPix: string = '',
        public pagamentoUrl: string = '',
        public formaPagamentoId: string = guidEmpty(),
        public formaPagamentoDescricao: string = '',
        public formasPagamentosDisponiveis: PagamentoModel[] = [],
        public pagadorId: string = guidEmpty(),
        public pagadorCpfCnpj: string = '',
        public pagadorNome: string = '',
        public beneficiarioId: string = guidEmpty(),
        public beneficiarioCpfCnpj: string = '',
        public beneficiarioNome: string = ''
    ) { }
}