import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import {
    DefaultFormProps, DefaultFormRefs} from 'views/components/form/utils/form-default-props';
import { makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { picker } from 'utils/picker';
import { EnumTipoComunicacao, KeyValueModel } from 'model';
import { useToastSaurus } from 'services/app';
import { useGetPagamento } from 'data/api/gestao/pagamento';
import { FaturaLiquidarModel } from 'model/api/gestao/fatura/fatura-liquidar-model';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { useFormFaturaLiquidarValidation } from './form-fatura-liquidar-validation';
import { yupResolver } from '@hookform/resolvers/yup';

interface FaturaRefs extends DefaultFormRefs<FaturaLiquidarModel> {

}

interface FaturaProps extends DefaultFormProps<FaturaLiquidarModel> {

}


export const FormFaturaLiquidar = forwardRef<
    DefaultFormRefs<FaturaLiquidarModel> | FaturaRefs,
    FaturaProps
>(
    (
        { loading, onSubmit, ...props }: FaturaProps,
        ref,
    ) => {
        const utilClasses = makeUtilClasses();
        const { showToast } = useToastSaurus();

        const { getPagamento, carregando: carregandoGet } = useGetPagamento()

        const { FormFaturaLiquidarValidationYup } = useFormFaturaLiquidarValidation()

        const carregando = loading || carregandoGet

        const model = useMemo(() => (
            props.model || new FaturaLiquidarModel()
        ), [props.model])

        const [pagamentoMock, setPagamentoMock] = useState<KeyValueModel[]>([])

        const {
            handleSubmit,
            control,
            formState: { errors },
            reset,
            getValues,
            setValue,
        } = useForm<FaturaLiquidarModel>({
            defaultValues: { ...model },
            resolver: yupResolver(FormFaturaLiquidarValidationYup),
            criteriaMode: 'all',
            mode: 'onBlur' && 'onChange',
        });

        const formaPagamentoWrapper = useCallback(async () => {
            try {
                const res = await getPagamento(1, 'pageSize=0')

                if (res.erro) throw res.erro
                if (!res.resultado) throw new Error('Erro ao carregar formas de pagamento.')

                setPagamentoMock(res.resultado.data.list.filter(pag => (pag.tipoComunicacao === EnumTipoComunicacao.Dinheiro || pag.tipoComunicacao === EnumTipoComunicacao.Transferencia)).map(pag => new KeyValueModel(pag.id, pag.nome)))
            } catch (e: any) {
                showToast('error', e.message)
            }
        }, [getPagamento, showToast])

        const submit = (values: FaturaLiquidarModel) => {
            const pagamento = picker<FaturaLiquidarModel>(values, new FaturaLiquidarModel())
            onSubmit(pagamento, model);
        };

        useImperativeHandle(ref, () => ({
            submitForm: () => {
                handleSubmit(submit)();
            },
            resetForm: () => {
                reset({ ...model });
            },
            fillForm: (values: FaturaLiquidarModel) => {
                reset({ ...values })
            },
        }));

        useEffect(() => {
            formaPagamentoWrapper()
        }, [formaPagamentoWrapper])

        return (
            <>
                <Box mb={2}>
                    <div className={utilClasses.formContainer}>
                        <form
                            onSubmit={handleSubmit(submit)}
                            className={carregando ? utilClasses.controlLoading : ''}
                        >
                            <Box mb={3}>
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={12}>
                                        <Controller
                                            name="formaPagamentoId"
                                            control={control}
                                            render={({ field }) => (
                                                <SelectSaurus
                                                    variant='outlined'
                                                    disabled={pagamentoMock.length < 1}
                                                    label="Forma de Pagamento"
                                                    allowSubmit
                                                    fullWidth
                                                    conteudo={pagamentoMock || []}
                                                    autoComplete={'off'}
                                                    helperText={
                                                        errors.formaPagamentoId
                                                            ? errors.formaPagamentoId.message
                                                            : undefined
                                                    }
                                                    error={Boolean(
                                                        errors.formaPagamentoId && errors.formaPagamentoId.message,
                                                    )}
                                                    {...field}
                                                    value={getValues('formaPagamentoId')}
                                                    onChange={ev => {
                                                        const item =
                                                            pagamentoMock
                                                                .filter(item => item.Key === ev.target.value)

                                                        setValue('formaPagamentoId', item[0].Key)
                                                    }}

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            name="valor"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={carregando}
                                                    label="Valor"
                                                    tipo="DECIMAL"
                                                    showStartAdornment
                                                    allowSubmit
                                                    manterMascara
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    helperText={
                                                        errors.valor
                                                            ? errors.valor.message
                                                            : undefined
                                                    }
                                                    error={Boolean(
                                                        errors.valor && errors.valor.message,
                                                    )}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            name="dataHora"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={carregando}
                                                    label="Data / Hora"
                                                    tipo="DATA_HORA"
                                                    allowSubmit
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    helperText={
                                                        errors.dataHora
                                                            ? errors.dataHora.message
                                                            : undefined
                                                    }
                                                    error={Boolean(
                                                        errors.dataHora && errors.dataHora.message,
                                                    )}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Button style={{ display: 'none' }} type="submit"></Button>
                        </form>
                    </div>
                </Box>
            </>
        );
    },
);
