
import { useMemo } from 'react';
import { useValidationYupDocumento, useValidationYupFone, useValidationYupSenha } from 'views/components/form-validations';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

export const useFormPessoaEditValidation = (revenda: boolean = false, configuracoes: boolean = false, isSW: boolean = false) => {

  const { documentoYup } = useValidationYupDocumento(false)
  const telefoneYup = useValidationYupFone()
  const { senhaYup } = useValidationYupSenha()

  const FormPessoaEditValidationYup = useMemo(() => {
    return Yup.object().shape<AnyObject>({
      nomeComercial: Yup.string().required('Nome é obrigatório'),
      cpfCnpj: documentoYup(),
      diasLimiteAtrasoClientes:
        revenda
          ? Yup
            .number()
            .nullable(true)
            .min(1, 'Mínimo é 1')
            .max(360, 'Máximo é 360')
            .typeError('Valor precisa ser um número')
            .transform((_, val) => val === ''
              ? null
              : Number(val))
          : Yup.number().nullable(),

      valorMinimoFatura: revenda ? Yup.string().required('Valor mínimo é obrigatório') : Yup.string().nullable(),
      login: revenda ? Yup.string().required('Usuário é obrigatório.').typeError('Usuário é obrigatório.') : Yup.string().nullable(),
      senha: senhaYup().transform(val => val === '' ? null : val).notRequired().nullable(true),
      telefoneSuporte: revenda ? telefoneYup().required('Telefone é obrigatório.').typeError('Telefone é obrigatório') : Yup.string().nullable().notRequired(),
      gerenteId: revenda ? Yup.string().required('Gerente Comercial é obrigatório.').typeError('Gerente Comercial é obrigatório.') : Yup.string().nullable().notRequired(),
      vendedorId: Yup.string().nullable().notRequired(),
      horaInicioCobranca: isSW ? Yup.number()
        .min(0, 'Horário mínimo é 00:00')
        .max(23, 'Horáro máximo é 11:00 PM')
        .test({
          name: 'maiorQueFim',
          exclusive: false,
          params: {},
          message: 'Horário de Início não pode ser maior que o horário de fim',
          test: (value, context) => (value || 0) < parseInt(context.parent.horaFimCobranca)
        })
        .typeError('Horário de Início da Cobrança é obrigatório') : Yup.number().notRequired().nullable(true),
      
        horaFimCobranca: isSW ? Yup.number()
        .min(0, 'Horário mínimo é 00:00 AM (0)')
        .max(23, 'Horáro máximo é 11:00 PM (23)')
        .test({
          name: 'maiorQueInicio',
          exclusive: false,
          params: {},
          message: 'Horário de Fim não pode ser menor que o horário de início',
          test: (value, context) => (value || 0) > parseInt(context.parent.horaInicioCobranca)
        })
        .typeError('Horário de Fim da Cobrança é obrigatório') : Yup.number().notRequired().nullable(true),


    });
  }, [documentoYup, isSW, revenda, senhaYup, telefoneYup])

  return {
    FormPessoaEditValidationYup,
  }
}

