import { guidEmpty } from "utils/guid-empty";
import { ClienteIdealRespostaModel } from "./cliente-ideal-resposta-model";

export class ClienteIdealPerguntaModel {
    constructor(
        public id: string = guidEmpty(),
        public pergunta: string = '',
        public detalhe: string = '',
        public interna: boolean = false,
        public ativo: boolean = true,
        public maxPontos: number = 10,
        public respostas: ClienteIdealRespostaModel[] = []
    ) { }
}