import { PessoasModel } from "model/api/gestao/pessoa/pessoas-model";
import { CardNaoEncontrado } from "views/components/cards";
import { CardFuncionarios } from "views/components/cards/card-funcionarios/card-funcionarios";
import { InformacaoIcon } from "views/components/icons";

export interface FuncionarioGridProps {
    list: Array<PessoasModel>;
    carregando: boolean;
    selectedList: Array<string>;
    onCardSelected: (id: string) => any;
    onCardChecked: (id: string) => any;
    label: string
    tooltipMessage?: string,
    emptyMessage?: string,
}

export const FuncionarioListData = (props: FuncionarioGridProps) => {

    const onCardSelected = (id: string) => {
        props.onCardSelected(id);
    };

    const onCardChecked = (id: string) => {
        props.onCardChecked(id);
    };

    return (
        <>
            {props.list.length === 0 && !props.carregando && (
                <CardNaoEncontrado
                    mensagem={props.emptyMessage || `Nenhum ${props.label} Encontrado.`}
                    icon={<InformacaoIcon tipo="GERAL" />}
                />
            )}
            {props?.list.length > 0 &&
                props.list.map((clientes, index) => {
                    return (
                        <CardFuncionarios
                            selected={
                                props.selectedList.filter((item) => item === clientes.id).length >
                                    0
                                    ? true
                                    : false
                            }
                            onCheck={onCardChecked}
                            onClick={onCardSelected}
                            model={clientes}
                            tooltipMessage={props.tooltipMessage}
                            key={index}
                        />
                    );
                })}
        </>
    );
};