import { DefaultIconProps } from "./default-icon/default-icon-props";
export const FinalizadoIcon = (props: DefaultIconProps) => {
    return (
        <>
            <svg width="250" height="250" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z" fill="#9DE7A5" />
                <path d="M43.8414 68.0871C42.427 69.474 40.161 69.468 38.754 68.0735L27.2626 56.6837C25.8066 55.2406 25.8369 52.8779 27.3294 51.4725L28.2276 50.6268L28.7185 50.22C30.1402 49.0418 32.2206 49.1213 33.5483 50.4045L38.6784 55.3625C40.1201 56.7558 42.4193 56.7134 43.8087 55.2678L65.594 32.5993C66.9957 31.1407 69.3202 31.1125 70.7569 32.5365L72.7908 34.5524C74.2263 35.9752 74.2201 38.2975 72.777 39.7127L43.8414 68.0871Z" fill="#10C822" />
            </svg>
        </>
    );
};
