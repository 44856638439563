import { useCallback } from 'react';
import * as Yup from 'yup';


export const useValidationYupFone = () => {
    const telefoneYup = useCallback(() => {
        return (
            Yup.string().nullable().optional().min(14, 'Telefone inválido.')
        )
    }, [])

    return (
        telefoneYup
    )
}