import { VariaveisAmbiente } from 'config';
import { FaturasModel } from 'model/api/gestao/fatura/faturas-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetFaturasCobranca() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<FaturasModel[]>();

    const getFaturasCobranca = useCallback(
        async (page: number, queryBusca: string) => {

            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Fatura/cobranca${queryBusca}`,
                method: 'GET',
                enviarTokenUsuario: false
            })
        }
        , [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getFaturasCobranca,
    };
}
