import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"
export const CopiarIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
            <path d="M35.6842 2H8.52632C6.03684 2 4 3.88182 4 6.18182V35.4545H8.52632V6.18182H35.6842V2ZM42.4737 10.3636H17.5789C15.0895 10.3636 13.0526 12.2455 13.0526 14.5455V43.8182C13.0526 46.1182 15.0895 48 17.5789 48H42.4737C44.9632 48 47 46.1182 47 43.8182V14.5455C47 12.2455 44.9632 10.3636 42.4737 10.3636ZM42.4737 43.8182H17.5789V14.5455H42.4737V43.8182Z"/>
            </DefaultIcon>
        </>
    );
};
<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
</svg>

