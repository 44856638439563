import { VariaveisAmbiente } from 'config';
import { PessoaSocioModel } from 'model/api/gestao/pessoa/pessoa-socio-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePutAtualizarPessoaSocio() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putAtualizarPessoaSocio = useCallback(
        (pessoaId: string, socio: PessoaSocioModel) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/pessoa/${pessoaId}/socio`,
                method: 'PUT',
                data: socio,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putAtualizarPessoaSocio,
    };
}
