

import { useMemo } from 'react';
import * as Yup from 'yup';


interface Props{
    isRetaguarda: boolean;
    isTipoRevenda: boolean;
    ambiente: boolean;
}


export const useFormContratoSegundaFaseValidation = ({isRetaguarda, isTipoRevenda, ambiente}: Props) => {

    const FormContratoSegundaFaseValidationYup = useMemo(() => {
        return (
            Yup.object().shape({
                dominio: isRetaguarda ? Yup.string().required('Domínio é obrigatório.').typeError('Domínio é obrigatório.') : Yup.string().notRequired().nullable(true),
                formaPagamentoId: isTipoRevenda ? Yup.string().notRequired().nullable(true) : Yup.string().required('Forma de Pagamento é obrigatória.').typeError('Forma de Pagamento é obrigatória.'),
                ambienteId: ambiente ? Yup.string().required('Ambiente é obrigatório').typeError('Ambiente é obrigatório') : Yup.string().notRequired().nullable(true)
            })
        )
    }, [ambiente, isRetaguarda, isTipoRevenda])

    return {
        FormContratoSegundaFaseValidationYup
    }
}

