import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { ResultadoRevendaModel } from 'model/api/gestao/relatorios/resultado-revenda-model';

export function useGetResultadosRevenda() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ResultadoRevendaModel[]>();

    const getResultadosRevenda = useCallback(
        async (queryBusca: string) => {
            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/resultados/revendas?${queryBusca}`,
                method: 'GET',
                enviarTokenUsuario: true,
                timeout: 60000
            })
        }
        , [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getResultadosRevenda,
    };
}