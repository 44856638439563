import { EnumTipoControleModulo } from "model/enums/enum-tipo-controle-modulo";
import { guidEmpty } from "utils/guid-empty";

export class ContratoModuloModel {
    constructor(
        public id: string = guidEmpty(),
        public codigo: number = 0,
        public nome: string = '',
        public valorOriginal: number = 0,
        public valorCustoOriginal: number = 0,
        public quantidadeContratada: number = 0,
        public valorFinal: number = 0,
        public percAdicional: number = 0,
        public moduloId: string = '',
        public contratoId: string = '',
        public quantidadeMaxima: number = 0,
        public quantidadeMinima: number = 0,
        public quantidadePadrao: number = 0,
        public valorCusto: number = 0,
        public percAdicionalCusto: number = 0,
        public codModuloBaseCalculo: string = '',
        public tipoCalculo: number = 0,
        public tipoControle: EnumTipoControleModulo = EnumTipoControleModulo.NaoControla,
        public avisoValor: boolean = false,
        public valorAnterior?: number,
        public editavel: boolean = false,
    ) { }
}