import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { Grid } from '@material-ui/core';
import { ModuloModel } from 'model/api/gestao/sistemas/modulo-model';
import { CardModulos } from 'views/components/cards/card-modulos';

export interface ModulosGridProps {
  list: ModuloModel[];
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (modulo: ModuloModel) => any;
  onCardChecked: (id: string) => any;
}

export const ModulosListData = (props: ModulosGridProps) => {
  const onCardSelected = (modulo: ModuloModel) => {
    props.onCardSelected(modulo);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhum Módulo Encontrado."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((modulos, index) => {
          return (
            <Grid xs={12}>
              <CardModulos
                selected={
                  false
                }
                onCheck={onCardChecked}
                onClick={onCardSelected}
                model={modulos}
                key={index}
              />
            </Grid>
          );
        })}
    </>
  );
};
