import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  defaultContainer: {
    position: "relative",
    maxWidth: 900,
    background: '#FFF',
    borderRadius: 8,
    overflowY: "auto",
    overflowX: "hidden",
  },
  listContainer: {
    "& .card": {
      margin: theme.spacing(1),
    }
  },
  list: {
    padding: 16,
    overflowX: "hidden",
    overflow: "auto",
    position: "relative",
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  contentForms: {
    flex: "1 1 auto",
    padding: theme.spacing(2),
    overflowX: "hidden",
    overflowY: "auto",
    "& * > form": {
      visibility: "visible",
    },
    "&  .tabs-class": {
      visibility: "visible",
    },
  },
  contentFormsLoading: {
    "& * > form": {
      visibility: "hidden",
    },

    "&  .tabs-class": {
      visibility: "hidden",
    },
  },
  acoes: {
    flex: "0 0 auto",
    padding: theme.spacing(2),
  },
  switchInput: {
    "&.MuiFormControlLabel-labelPlacementStart": {
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: 0,
      fontWeight: '600!important'
    },
  },
  switchInputContainer: {
    backgroundColor: '#F4F4F4',
    padding: '4px 16px',
    borderRadius: theme.shape.borderRadius,
    marginBottom: 10
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem',
    borderRadius: theme.shape.borderRadius,
    marginBottom: 10,
    gap: 8,
    backgroundColor: '#F4F4F4',
    '& .MuiFormControl-root': {
      backgroundColor: '#fff'
    }
  }
}));
