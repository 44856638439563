import { FilialModel } from "model/api/gestao/contrato/contrato-filial-model";
import { CardNaoEncontrado } from "views/components/cards";
import { CardFilial } from "views/components/cards/card-filial/card-filial";
import { InformacaoIcon } from "views/components/icons";

export interface FilialListProps {
  list: Array<FilialModel>;
  carregando: boolean;
  updateList: () => void;
  onClick: (selectedModel: FilialModel) => void
}

export const FilialList = (props: FilialListProps) => {

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhuma Empresa Encontrada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props?.list.length > 0 &&
        props.list.map((items, index) => {
          return (
            <CardFilial
              updateList={props.updateList}
              model={items}
              key={index}
              onClick={props.onClick}
            />
          );
        })}
    </>
  );
};
