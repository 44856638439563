import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useStyles } from './card-desempenho-revenda-styles'
import { CardRevendaDesempenhoByIdProps } from './card-desempenho-revenda-by-id-props';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useSessaoAtual } from 'services/app';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { toCurrency } from 'utils/toCurrency';
import { useThemeQueries } from 'views/theme';
import { DetalharIcon } from 'views/components/icons/detalhar-icon';

export const CardDesempenhoRevendaById = ({
    model,
    onClick
}: CardRevendaDesempenhoByIdProps) => {
    const classes = useDefaultCardStyles();
    const listClasses = useStyles({});
    const { tipoUsuario } = useSessaoAtual();
    const { isMobile, theme } = useThemeQueries();

    const isAllowed = tipoUsuario() === EnumTipoPessoas.SoftwareHouse || tipoUsuario() === EnumTipoPessoas.GerenteComercial

    const retornarClasseSaldo = useCallback((saldo: number) => {
        if (saldo === 0) return ''
        if (saldo > 0) return listClasses.saldoPositivo
        return listClasses.saldoNegativo
    }, [listClasses.saldoNegativo, listClasses.saldoPositivo])

    if (isAllowed && !isMobile) {
        return (
            <>
                <DefaultCard
                    onClick={() => {
                        onClick(model.anoMes);
                    }}
                >
                    <div className={classNames(listClasses.container, listClasses.anoMes)}>
                        <Typography color="textPrimary" variant="caption">
                            Período
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                            className={listClasses.semiBold}
                        >
                            {(() => {
                                const anoMesString = model.anoMes.toString()
                                return `${anoMesString.substring(4)}/${anoMesString.substring(0, 4)}`
                            })()}
                        </Typography>
                    </div>
                    <div className={`${classes.cardContent} ${listClasses.container}`}>
                        <Grid container spacing={1} className={listClasses.cardSaldos}>
                            <Grid>
                                <Typography color="textPrimary" variant="caption">
                                    Novos Clientes
                                </Typography>
                                <Typography
                                    color="textPrimary"
                                    variant="body1"
                                    className={retornarClasseSaldo(model.cnpjAdicionados)}
                                >
                                    {model.cnpjAdicionados}
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography color="textPrimary" variant="caption">
                                    <Box display='flex' gridGap={5}>
                                        Clientes Desativados
                                    </Box>
                                </Typography>
                                <Typography
                                    color="textPrimary"
                                    variant="body1"
                                    className={retornarClasseSaldo(-model.cnpjDesativados)}
                                >
                                    {model.cnpjDesativados}
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography color="textPrimary" variant="caption">
                                    Saldo De Clientes
                                </Typography>
                                <Typography
                                    color="textPrimary"
                                    variant="body1"
                                    className={classNames(
                                        listClasses.bold,
                                        retornarClasseSaldo(model.cnpjSaldo)
                                    )}
                                >
                                    {model.cnpjSaldo}
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography color="textPrimary" variant="caption">
                                    Novos Terminais
                                </Typography>
                                <Typography
                                    color="textPrimary"
                                    variant="body1"
                                    className={retornarClasseSaldo(model.terminaisAdicionados)}
                                >
                                    {model.terminaisAdicionados}
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography color="textPrimary" variant="caption">
                                    Terminais Desativados
                                </Typography>
                                <Typography
                                    color="textPrimary"
                                    variant="body1"
                                    className={retornarClasseSaldo(-model.terminaisDesativados)}
                                >
                                    {model.terminaisDesativados}
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography color="textPrimary" variant="caption">
                                    Saldo de Terminais
                                </Typography>
                                <Typography
                                    color="textPrimary"
                                    variant="body1"
                                    className={classNames(
                                        listClasses.bold,
                                        retornarClasseSaldo(model.terminaisSaldo)
                                    )}
                                >
                                    {model.terminaisSaldo}
                                </Typography>
                            </Grid>
                            <Grid className={listClasses.cardSaldoValorPago}>
                                <Typography color="textPrimary" variant="caption">
                                    Total Pago
                                </Typography>
                                <Typography
                                    color="textPrimary"
                                    variant="body1"
                                    className={classNames(
                                        listClasses.bold,
                                    )}
                                >
                                    {toCurrency(model.totalPago)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                    <Tooltip title='Clique para ver mais detalhes.'>
                    <div className={classes.cardRightButton} style={{ margin: '0px 0px 0px 10px' }}>
                        <DetalharIcon tipo="BUTTON" fill={theme.palette.primary.main} />
                    </div>
                </Tooltip>
                </DefaultCard>
            </>
        )
    }

    return (
        <>
            <DefaultCard
                onClick={() => {
                    onClick(model.anoMes);
                }}
            >
                <div className={classNames(listClasses.container, listClasses.anoMes)}>
                    <Typography color="textPrimary" variant="caption">
                        Período
                    </Typography>
                    <Typography
                        color="textPrimary"
                        variant="body1"
                        className={listClasses.semiBold}
                    >
                        {(() => {
                            const anoMesString = model.anoMes.toString()
                            return `${anoMesString.substring(4)}/${anoMesString.substring(0, 4)}`
                        })()}
                    </Typography>
                </div>
                <div className={`${classes.cardContent} ${listClasses.container}`}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} md={2}>
                            <Typography color="textPrimary" variant="caption">
                                Novos Clientes
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                className={retornarClasseSaldo(model.cnpjAdicionados)}
                            >
                                {model.cnpjAdicionados}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Typography color="textPrimary" variant="caption">
                                <Box display='flex' gridGap={5}>
                                    Clientes Desativados
                                </Box>
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                className={retornarClasseSaldo(-model.cnpjDesativados)}
                            >
                                {model.cnpjDesativados}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Typography color="textPrimary" variant="caption">
                                Saldo De Clientes
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                className={classNames(
                                    listClasses.bold,
                                    retornarClasseSaldo(model.cnpjSaldo)
                                )}
                            >
                                {model.cnpjSaldo}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Typography color="textPrimary" variant="caption">
                                Novos Terminais
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                className={retornarClasseSaldo(model.terminaisAdicionados)}
                            >
                                {model.terminaisAdicionados}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Typography color="textPrimary" variant="caption">
                                Terminais Desativados
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                className={retornarClasseSaldo(-model.terminaisDesativados)}
                            >
                                {model.terminaisDesativados}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Typography color="textPrimary" variant="caption">
                                Saldo de Terminais
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                className={classNames(
                                    listClasses.bold,
                                    retornarClasseSaldo(model.terminaisSaldo)
                                )}
                            >
                                {model.terminaisSaldo}
                            </Typography>
                        </Grid>
                        {isAllowed && isMobile && <Grid item xs={6}>
                            <Typography color="textPrimary" variant="caption">
                                Total Pago
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                className={classNames(
                                    listClasses.bold,
                                )}
                            >
                                {toCurrency(model.totalPago)}
                            </Typography>
                        </Grid>}
                    </Grid>
                </div>
                <Tooltip title='Clique para ver mais detalhes.'>
                    <div className={classes.cardRightButton} style={{ margin: '0px 0px 0px 10px' }}>
                        <DetalharIcon tipo="BUTTON" fill={theme.palette.primary.main} />
                    </div>
                </Tooltip>
            </DefaultCard>
        </>
    );
};