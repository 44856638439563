import { useMemo, useState } from 'react';
import { PrecificacaoHeader } from './components/precificacao-header/precificacao-header';
import { PreficicacaoList } from './components/precificacao-list/precificacao-list';
import { Grid } from '@material-ui/core';
import { PrecificacaoListSearchProps } from './components/precificacao-list/precificacao-list-search-props';
import { ButtonFab } from 'views/components/controles';
import { NovoIcon } from 'views/components/icons';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { useCadastros } from 'services/app';
import { useHistory, useParams } from 'react-router-dom';
import { PrecificacaoEditar } from './components/precificacao-editar/precificacao-editar';
import { useThemeQueries } from 'views/theme';

interface paramProps {
  parametro: string
}

export const PrecificacaoPage = () => {
  const classes = useDefaultCadastroStyles();
  const history = useHistory()
  const {isMobile} = useThemeQueries();

  const [openPesquisa, setOpenPesquisa] = useState(isMobile);
  const { abrirCadastroPrecificacao } = useCadastros()
  const [searchProps, setSearchProps] = useState<PrecificacaoListSearchProps>({
    termo: '',
  });
  const { parametro } = useParams<paramProps>()

  const pageSwitch = useMemo(() => {
    switch (parametro) {
      case undefined:
        return (
          <>
            <Grid className={classes.list}>
              <PreficicacaoList searchProps={searchProps} />
            </Grid>
            <ButtonFab
              tooltip="Nova Tabela de Preço"
              icon={<NovoIcon tipo="BUTTON_FAB" />}
              onClick={() => abrirCadastroPrecificacao('', history.location.pathname, true)}
            />
          </>
        )
      case 'adicionar':
        return (
          <>
            <Grid className={classes.list}>
              <PreficicacaoList searchProps={searchProps} />
            </Grid>
            <ButtonFab
              tooltip="Nova Tabela de Preço"
              icon={<NovoIcon tipo="BUTTON_FAB" />}
              onClick={() => abrirCadastroPrecificacao('', history.location.pathname, true)}
            />
          </>
        )
      default:
        return (
          <PrecificacaoEditar id={parametro}/>
        )
    }
  }, [abrirCadastroPrecificacao, classes.list, history.location.pathname, parametro, searchProps])

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <PrecificacaoHeader
          openPesquisa={openPesquisa}
          setSearchProps={setSearchProps}
          setOpenPesquisa={setOpenPesquisa}
          titulo={parametro === undefined
            ? 'Tabela de Preço' :
            'Editar Tabela de Preço'
          }
        />
      </Grid>
      {pageSwitch}
    </Grid>
  );
};

export default PrecificacaoPage;
