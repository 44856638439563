import { makeStyles } from "@material-ui/core";
import { ArquivoInputProps } from "./arquivo-input-props";

export const useStyles = makeStyles((theme) => ({
    root: ({ width, height }: ArquivoInputProps) => ({
        position: 'relative',
        display: 'flex',
        width: width || '150px',
        height: height || '150px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        "& .MuiFab-primary": {
            right: 0,
            bottom: 0,
            "& svg": {
                width: 25,
                height: 25,
            }
        }
    }),
    selecionarArquivo: ({helperText}: ArquivoInputProps) => ({
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.text.primary,
        padding: '14px 12px',
        wordBreak: 'break-word',
        border: 2,
        width: '100%',
        '& span': {
            textAlign: 'center'
        },
        borderColor: helperText ? theme.palette.error.main : theme.palette.primary.main,
        borderStyle: 'dashed',
        '&:hover': {
            backgroundColor: '#ECEDED'
        },
    }),
    trocarArquivo: () => ({
        display: 'block',
        textAlign: "right"
    }),
}));