import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useDeletePessoaSistema() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const deletePessoaSistema = useCallback(
        (pessoaId: string, id: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/pessoa/${pessoaId}/sistema?id=${id}`,
                method: 'DELETE',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        deletePessoaSistema,
    };
}
