import { useCallback, useMemo } from "react";
import { PrivatePageHeader } from "views/components/headers";
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { DesfazerIcon, FiltroIcon, MenuIcon, VoltarIcon } from "views/components/icons";
import { PesquisaInstituicao } from "./components/pesquisa-precificacao/pesquisa-precificacao";
import { useMenuPrincipal } from "services/app";
import { useThemeQueries } from "views";
import { PrecificacaoListSearchProps } from "../precificacao-list/precificacao-list-search-props";
import { useHistory, useLocation } from "react-router";
import { LocationProps } from "views/pages/private/revendedor/components/revendedor-header/renvededor-header";

export interface PrecificacaoHeaderProps {
  openPesquisa: boolean;
  setOpenPesquisa: (state: boolean) => any;
  setSearchProps: (props: PrecificacaoListSearchProps) => any;
  titulo: string;
}

export const PrecificacaoHeader = ({
  openPesquisa,
  setOpenPesquisa,
  setSearchProps,
  titulo
}: PrecificacaoHeaderProps) => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();
  const history = useHistory()
  const { state } = useLocation<LocationProps>()

  const leftArea = useCallback(
    () =>
      isMobile ? (titulo === 'Tabela de Preço' ?
        (
          <ButtonPrivateHeader
            icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
            tooltip="Menu"
            onClick={abrirMenu}
          ></ButtonPrivateHeader>
        ) : <ButtonPrivateHeader
          icon={<VoltarIcon tipo="PRIVATE_HEADER"></VoltarIcon>}
          tooltip="Voltar"
          onClick={() => {
            if (state && state.from && state.from.page) {
              history.push('/tabela-precos?page=' + state.from.page)
              return
            }
            history.push('/tabela-precos')
            return
          }}
        ></ButtonPrivateHeader>) : (titulo === 'Editar Tabela de Preço') ?
        (
          <ButtonPrivateHeader
            icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
            tooltip="Voltar"
            onClick={() => {
              if (state && state.from && state.from.page) {
                history.push('/tabela-precos?page=' + state.from.page)
                return
              }
              history.push('/tabela-precos')
              return
            }}
          />
        ) : null,
    [isMobile, abrirMenu, titulo, state, history]
  );

  const rightArea = useCallback(
    () => titulo === 'Tabela de Preço' ? (
      <ButtonPrivateHeader
        icon={
          openPesquisa ? (
            <DesfazerIcon tipo="PRIVATE_HEADER"></DesfazerIcon>
          ) : (
            <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>
          )
        }
        tooltip={!openPesquisa ? "Pesquisar" : "Voltar"}
        onClick={() => setOpenPesquisa(!openPesquisa)}
      ></ButtonPrivateHeader>
    ) : null,
    [titulo, openPesquisa, setOpenPesquisa]
  );

  const closePesquisaWrapper = useCallback(() => {
    setOpenPesquisa(false);
  }, [setOpenPesquisa]);

  const onPesquisar = useCallback(
    (props: PrecificacaoListSearchProps) => {
      setSearchProps(props);
    },
    [setSearchProps]
  );

  const pesquisa = useMemo(() => titulo === 'Tabela de Preço' ?
    (
      <PesquisaInstituicao
        isOpened
        onCloseSearch={closePesquisaWrapper}
        onPesquisar={onPesquisar}
      />
    ) : undefined, [closePesquisaWrapper, onPesquisar, titulo])

  const bottomArea = useCallback(() => (
    <PesquisaInstituicao
      isOpened={openPesquisa}
      onCloseSearch={closePesquisaWrapper}
      onPesquisar={onPesquisar}
    />
  ), [closePesquisaWrapper, onPesquisar, openPesquisa])

  return (
    <>
      <PrivatePageHeader
        title={titulo}
        leftArea={leftArea()}
        rightArea={isMobile ? rightArea() : pesquisa}
        bottomArea={isMobile ? bottomArea() : undefined}
      />
    </>
  );
};
