import { Grid, Typography } from '@material-ui/core';
import { DefaultCard, useDefaultCardStyles } from '../components/default-card';
import { BotoesAddESub } from './components/button-add-e-sub';
import { ContratoModuloModel } from 'model/api/gestao/contrato/contrato-modulo-model';
import { EnumTipoCalculo } from 'model';
import { useCallback, useEffect } from 'react';
import { calcPercent } from 'utils/calc-percent';
import { EnumTipoControleModulo } from 'model/enums/enum-tipo-controle-modulo';

interface CardModulosContratoProps{
  model: ContratoModuloModel,
  alterarQtd: (model: ContratoModuloModel, qtde: number) => void;
  hasPlanoId: boolean;
  modulos: ContratoModuloModel[];
}

export const CardModuloContratoFuncionario = ({
  model,
  alterarQtd,
  hasPlanoId,
  modulos
}: CardModulosContratoProps) => {


    const classes = useDefaultCardStyles();

    const calcularValor = useCallback(
      (valor: number, quantidade: number, percAdicional: number, custo?: boolean) => {
        if (model.tipoControle !== EnumTipoControleModulo.Filial) {
  
          let quantidadeMult = valor;
  
          if (quantidade === -1) {
            return 0
          }
  
          for (let i = 1; i <= quantidade; i++) {
            if (quantidade > 1) {
              quantidadeMult = (quantidade - 1) * calcPercent(valor, percAdicional);
            }
          }
  
          if (quantidade === 0) {
            return 0;
          }
  
          if (quantidade > 1) {
            valor = valor + quantidadeMult
          }
        }
  
        if (model.tipoCalculo === EnumTipoCalculo.Modulo && model.tipoControle === EnumTipoControleModulo.Filial) {
          model.codModuloBaseCalculo = model.codModuloBaseCalculo.toString()
          const codsBase = model.codModuloBaseCalculo.includes(',') ? model.codModuloBaseCalculo.split(',').map(cod => Number(cod)) : model.codModuloBaseCalculo.split('-').map(cod => Number(cod))
          const moduloBase = modulos.filter(mod => codsBase.includes(mod.codigo))
  
          const valorBase = moduloBase.reduce((prev, curr) => {
            if (curr.quantidadeContratada === 0) return prev
            if (curr.quantidadeContratada === 1) return prev + curr.valorOriginal
            return (curr.quantidadeContratada - 1) * calcPercent(curr.valorOriginal, curr.percAdicional) + curr.valorOriginal + prev
          }, 0)
  
          const custoBase = moduloBase.reduce((prev, curr) => {
            if(curr.quantidadeContratada === 0) return prev
            if(curr.quantidadeContratada === 1) return prev + curr.valorCusto
            return (curr.quantidadeContratada - 1) * calcPercent(curr.valorCusto, curr.percAdicionalCusto) + curr.valorCusto + prev
          }, 0)
  
  
  
          valor = moduloBase ? ((model.quantidadeContratada) * calcPercent(custo ? custoBase : valorBase, percAdicional)) : valor
        } else if (model.tipoCalculo === EnumTipoCalculo.Modulo) {
          const codBase = Number(model.codModuloBaseCalculo)
          const moduloBase = modulos.find(mod => mod.codigo === codBase)
  
          valor = moduloBase ? valor * (moduloBase?.quantidadeContratada || 1) : valor
        }
  
        return valor
      },
      [model, modulos],
    );

    useEffect(() => {
      calcularValor(model.valorCusto, model.quantidadeContratada, model.percAdicionalCusto, true)
      calcularValor(model.valorOriginal, model.quantidadeContratada, model.percAdicional)
    }, [calcularValor, model.percAdicional, model.percAdicionalCusto, model.quantidadeContratada, model.valorCusto, model.valorOriginal])

  return (
    <Grid item xs={12}>
      <DefaultCard>
        <div className={classes.cardContent}>
          <Grid container justifyContent='space-between' spacing={2}>
            <Grid item xs={6}>
              <Typography color='textSecondary' variant='caption'>
                Nome:
              </Typography>
              <Typography
                color="textPrimary"
                variant="body2"
                style={{ fontWeight: 600 }}
              >
                {model.nome}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <BotoesAddESub
                model={model}
                hasPlanoId={hasPlanoId}
                alterarQtd={alterarQtd}
              />
            </Grid>
          </Grid>
        </div>
      </DefaultCard>
    </Grid>
  );
};
