import { Grid, Tooltip, Typography } from '@material-ui/core';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useThemeQueries } from 'views/theme';
import { EditarIcon } from 'views/components/icons';
import { ClienteIdealRespostaModel } from 'model/api/gestao/cliente-ideal/cliente-ideal-resposta-model';
import {useStyles} from '../card-apuracao-pergunta/card-apuracao-pergunta-styles'

interface CardApuracaoRespostaProps {
    model: ClienteIdealRespostaModel;
    onClick: (index: number) => void;
    index: number;
}

export const CardApuracaoResposta = ({
    model,
    onClick,
    index
}: CardApuracaoRespostaProps) => {
    const classes = useDefaultCardStyles();
    const cardClasses = useStyles({
        ativo: model.ativo
    });
    const { theme } = useThemeQueries()

    return (
        <>
            <DefaultCard
                onClick={() => {
                    onClick(index);
                }}
                className={cardClasses.card}
            >
                <Tooltip title={model.ativo ? 'Pergunta Ativa' : 'Pergunta Desativada'} placement='right'>
                    <div className={cardClasses.status} />
                </Tooltip>
                <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={6}>
                        <Typography color="primary" variant="caption">
                            Resposta
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                            className={cardClasses.boldText}
                        >
                            {model.resposta}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="primary" variant="caption">
                            Pontos
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                            className={cardClasses.ellipsisText}
                        >
                            {model.pontos}
                        </Typography>
                    </Grid>
                </Grid>
                <Tooltip arrow title="Editar Resposta">
                    <div className={classes.cardRightButton} style={{ marginLeft: '10px' }}>
                        <EditarIcon tipo="BUTTON" fill={theme.palette.primary.main} />
                    </div>
                </Tooltip>
            </DefaultCard>
        </>
    );
};