import { VariaveisAmbiente } from 'config';
import { ApiListModel } from 'model/api/gestao/api-list-model/api-list-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { IndicacoesInteracoesModel } from 'model/api/gestao/indicacoes/indicacoes-interacoes-model';

export function useGetIndicacoesInteracoes() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ApiListModel<IndicacoesInteracoesModel>>();

    const getIndicacoesInteracoes = useCallback(
        (id: string, newPage: number, query?: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Indicacao/${id}/interacoes?page=${newPage}${query}`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getIndicacoesInteracoes,
    };
}
