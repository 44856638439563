import { Button, Grid } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useCallback, useEffect, useRef } from 'react';
import { SalvarIcon, VoltarIcon } from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { useStyles } from './dialog-editar-competencia-styles';
import { FaturaModel } from 'model/api/gestao/fatura/fatura-model';
import { useToastSaurus } from 'services/app';
import { DefaultFormRefs } from 'views/components/form/utils';
import { FormEditarCompetencia } from 'views/components/form/master/editar-competencia/form-editar-competencia';
import { FaturaCompetenciaModel } from 'model/api/gestao/fatura/fatura-competencia-model';
import { usePutAlterarCompetencia } from 'data/api/gestao/faturas/put-alterar-competencia';
import { guidEmpty } from 'utils/guid-empty';

interface DialogEditarCompetenciaProps {
  openned: boolean;
  closeModal: () => void;
  model: FaturaModel;
  carregando: boolean;
  preencherTela: () => Promise<void>;
}

export const DialogEditarCompetencia = ({
  openned,
  closeModal,
  model,
  carregando,
  preencherTela
}: DialogEditarCompetenciaProps) => {
  const classes = useStyles();
  const { acoes } = useModalStyles()
  const { showToast } = useToastSaurus()

  const {putAlterarCompetencia, carregando: carregandoPut} = usePutAlterarCompetencia()

  const cadEditarCompetencia =
    useRef<DefaultFormRefs<FaturaCompetenciaModel>>(null);

  const handleSubmit = useCallback(
    async (values: FaturaCompetenciaModel) => {
      try {
        const res = await putAlterarCompetencia(model.id, values)

        if(res.erro) throw res.erro

        if(res.statusCode === 204){
          showToast('success', 'Competência Alterada')
          await preencherTela();
          closeModal();
        }
      } catch (e: any) {
        showToast('error', e.message)
      }
    },
    [closeModal, model.id, preencherTela, putAlterarCompetencia, showToast],
  );

  useEffect(() => {
    if(model.id !== guidEmpty()){
      cadEditarCompetencia.current?.fillForm(new FaturaCompetenciaModel(model.competenciaAnoMes, model.dataVencimento))
    }
  }, [model.competenciaAnoMes, model.dataVencimento, model.id])



  return (
    <DialogSaurus
      aberto={openned}
      titulo='Editar Competência'
      tamanho="sm">
      <div className={acoes}>
        <Grid container spacing={2} className={classes.content}>
          <Grid item xs={12} md={12}>
            <FormEditarCompetencia
              ref={cadEditarCompetencia}
              showLoading={false}
              loading={carregandoPut}
              onSubmit={handleSubmit}
              model={model}
            />
          </Grid>
        </Grid>
        <div className={classes.divider} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Button
              variant='outlined'
              color='primary'
              onClick={closeModal}
              fullWidth
            >
              <VoltarIcon tipo='BUTTON' />
              Voltar
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              disabled={carregando}
              variant="contained"
              color="primary"
              fullWidth
              onClick={cadEditarCompetencia?.current?.submitForm}
            >
              <SalvarIcon tipo='BUTTON_PRIMARY' />
              Salvar
            </Button>
          </Grid>
        </Grid>
      </div>
    </DialogSaurus>
  );
};
