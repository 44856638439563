import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { FaturaCompetenciaModel } from 'model/api/gestao/fatura/fatura-competencia-model';

export function usePutAlterarCompetencia() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putAlterarCompetencia = useCallback(
        (faturaId: string, model: FaturaCompetenciaModel) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Fatura/${faturaId}/alterar-competencia`,
                method: 'PUT',
                enviarTokenUsuario: true,
                data: model
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putAlterarCompetencia,
    };
}
