import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const TagIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon
                tipo={props.tipo}
                class={props.class}
                style={props.style}
                fill={props.fill}
            >
                <path d="M47.8738 13.6657C47.8738 16.0893 47.8773 18.5111 47.8703 20.9347C47.8686 21.4218 47.8407 21.9107 47.7937 22.3961C47.7084 23.257 47.3617 23.9816 46.7364 24.6067C39.2692 32.08 31.8125 39.5655 24.3523 47.0475C23.0895 48.3134 21.3442 48.3186 20.0849 47.0562C14.37 41.3255 8.65505 35.5949 2.94188 29.8624C1.70519 28.621 1.70171 26.8557 2.93317 25.6212C10.3812 18.1514 17.8344 10.6886 25.2703 3.20657C25.9618 2.50988 26.7508 2.12399 27.7071 2.06637C28.3655 2.02621 29.0256 2.0105 29.6858 2.0105C34.4827 2.00351 39.2797 2.00526 44.0767 2.00002C44.8378 1.99827 45.5711 2.11002 46.2208 2.53258C47.3495 3.26768 47.8738 4.32756 47.8738 5.66682C47.8721 8.33136 47.8721 10.9994 47.8738 13.6657ZM41.882 11.0221C41.8907 9.34584 40.5965 8.0223 38.9366 8.00833C37.2505 7.99436 35.9128 9.31615 35.9128 11.0029C35.911 12.6564 37.2365 13.9887 38.8913 13.9957C40.546 14.0027 41.8733 12.6826 41.882 11.0221Z" />
            </DefaultIcon>
        </>
    );
};
