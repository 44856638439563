
import { useMemo } from 'react';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

export const useDialogMotivoConfirmacaoIndicacaoValidation = () => {

    const dialogMotivoConfirmacaoIndicacoValidation = useMemo(() => {
        return Yup.object().shape<AnyObject>({
            descricao: Yup.string().required('A descrição do motivo é obrigátorio.'),
        });
    }, [])

    return {
        dialogMotivoConfirmacaoIndicacoValidation,
    }
}