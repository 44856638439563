import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.text.primary,
        fontSize: '17px',
        fontWeight: 600,
        padding: '0 15px 10px'
    },
    content: {
        paddingBottom: '15px'
    },
    divider: {
        background: theme.palette.primary.light,
        height: '1px',
        margin: '17px 0'
    },
    loadingAntecipacao: {
        display: 'flex',
        position: 'absolute',
        top: 0,
        left: 0,
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        background: theme.palette.background.default,
        height: '100%',
        width: '100%',
        zIndex: 2,
    },
    fontAntecipacao: {
        fontSize: '1.1rem',
        fontWeight: 500
    }
}));