import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {

  },
  menu: {
    content: "",
    width: '20px',
    height: '20px',
    position: 'absolute',
    borderLeft: '15px solid transparent',
    borderRight: '15px solid #FFF',
    borderTop: '15px solid #FFF',
    borderBottom: '15px solid transparent',
    top: '-20px',
    zIndex: 60,
    transition: '0.1 seconds'
  },
  icon: {
    '& svg': {
      margin: 0,
      marginLeft: theme.spacing(3)
    },
    display: 'flex'
  },
  layer: {
    backgroundColor: 'rgba(0, 0, 0, .2)',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'fixed',
    zIndex: 50,
    width: '100vw',
    height: '100vh'
  },
  divider: {
    borderTop: 'thin solid ' + theme.palette.secondary.main
  },
  menuRoot: {
    height: 50
  },
}));
