import { RevendaDesempenhoAnoMesModel } from "model/api/gestao/revendedor/revenda-desempenho-anomes-model";
import { CardNaoEncontrado } from "views/components/cards";
import { CardDesempenhoRevendaAnoMesModel } from "views/components/cards/card-desempenho-revenda/card-desempenho-anomes";
import { InformacaoIcon } from "views/components/icons";

export interface DesempenhoRevendaAnoMesListDataProps {
  list: Array<RevendaDesempenhoAnoMesModel>;
  carregando: boolean;
}

export const DesempenhoRevendaAnoMesListData = (props: DesempenhoRevendaAnoMesListDataProps) => {
  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem='Nenhum Resultado Encontrado para este Período.'
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props?.list.length > 0 &&
        props.list.map((model, index) => {
          return (
            <CardDesempenhoRevendaAnoMesModel
              model={model}
            />
          );
        })}
    </>
  );
};