import { Theme, makeStyles } from "@material-ui/core";

export interface StyleProps{
    showOverflow: boolean;
    chartContainerRef: React.RefObject<HTMLDivElement>;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  chartContainer: {
    overflowY: 'hidden',
    overflowX: ({showOverflow}: StyleProps) => showOverflow ? 'auto' : 'unset',
    '& .apexcharts-legend': {
        display: 'flex',
        maxWidth: ({chartContainerRef}: StyleProps) => chartContainerRef.current?.clientWidth,
        flexWrap: 'wrap'
    }
  }
}));
