import { PropTypes } from "@material-ui/core";
import { useButtonFabMenuStyles } from "./button-fab-menu-styles";
import { SpeedDial, SpeedDialAction } from "@material-ui/lab";
import { ButtonFabMenuModel } from "model/app/components/button-fab-menu/button-fab-menu";

interface ButtonFabMenuProps {
    icon: JSX.Element;
    open: boolean;
    values: ButtonFabMenuModel[];
    size?: 'small' | 'medium' | 'large';
    color?: PropTypes.Color;
    style?: React.CSSProperties;
    onOpen: () => any;
    onClose: () => any;
    carregando?: boolean;
}

export const ButtonFabMenu = (props: ButtonFabMenuProps) => {
    const classes = useButtonFabMenuStyles();
    return (
        <>
            <SpeedDial
                ariaLabel='SpeedDial Saurus'
                className={classes.root}
                icon={props.icon}
                open={props.open}
                onOpen={props.onOpen}
                onClose={props.onClose}
                style={props.style}
            >
                {props.values.map((item) => {
                    return <SpeedDialAction
                        disableHoverListener
                        icon={item.icon}
                        tooltipTitle={item.tittle}
                        onClick={item.onClick}
                    />
                })}
            </SpeedDial>
        </>
    );
};
