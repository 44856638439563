import { toDateString } from "./to-date"

export const converterDateToCompetencia = (data: Date) => {
  return Number(toDateString(data, 'yyyyMM'))
}

export const subtrairCompetencia = (competencia: number, meses: number): number => {
  const year = Math.floor(competencia / 100);
  const month = competencia % 100;
  const totalMonths = year * 12 + month;
  const newTotalMonths = totalMonths - meses;

  let newYear = Math.floor(newTotalMonths / 12);
  let newMonth = newTotalMonths % 12;

  if (newMonth <= 0) {
    newYear--;
    newMonth += 12;
  }

  return newYear * 100 + newMonth;
}

export const contarMesesEntreCompetencias = (startDate: number, endDate: number): number => {
  const startYear = Math.floor(startDate / 100);
  const startMonth = startDate % 100;
  const endYear = Math.floor(endDate / 100);
  const endMonth = endDate % 100;

  const startTotalMonths = startYear * 12 + startMonth;
  const endTotalMonths = endYear * 12 + endMonth;

  return endTotalMonths - startTotalMonths;
}

export const competenciaMesAnterior = () => {
  const data = new Date();
  data.setDate(1);
  data.setMonth(data.getMonth() - 1);
  const competencia = Number(toDateString(data, 'yyyyMM'))
  return competencia
}

export const competenciaParaDate = (competencia: number): Date => {
  const competenciaString = competencia.toString();
  const date = new Date(`${competenciaString.substring(0, 4)}-${competenciaString.substring(4)}`)
  return date
}

export const dateParaCompetencia = (date: Date): number => {
  const ano = date.getFullYear();
  const mes = date.getMonth() + 1;
  let mesStr;
  if(mes < 10){
    mesStr = `0${mes}`
  }else{
    mesStr = `${mes}`
  }

  return Number(`${ano}${mesStr}`)
}

export const formatarCompetenciaDate = (competencia: number): string => {
  return `${competencia.toString().substring(4, 6)}/${competencia.toString().substring(0, 4)}`
}