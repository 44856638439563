import { useMemo } from 'react';
import * as Yup from 'yup';


export const useFormContratoPrimeiraFaseValidation = () => {

    const FormContratoPrimeiraFaseValidationYup = useMemo(() => {
        return (
            Yup.object().shape({
                sistemaId: Yup.string().required('Precisa ser selecionado.'),
            })
        )
    }, [])

    return {
        FormContratoPrimeiraFaseValidationYup
    }
}

