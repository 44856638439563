import { guidEmpty } from "utils/guid-empty";

export class PessoaSocioModel {
    constructor(
        public id: string = guidEmpty(),
        public pessoaId: string = guidEmpty(),
        public nome: string = '',
        public cpf: string = '',
        public fone: string = '',
        public email: string = '',
        public nomeMae: string = '',
        public dataNascimento: string = '',
        public logradouro: string = '',
        public numero: string = '',
        public complemento: string = '',
        public cep: string = '',
        public bairro: string = '',
        public municipio: string = '',
        public uf: string = ''
    ) { }
}