import { Button, Grid } from "@material-ui/core";
import { useToastSaurus } from "services/app";
import { SalvarEditarIcon } from "views/components/icons";
import { usePutAtualizarPessoa } from "data/api/gestao/pessoa";
import { DefaultFormRefs } from "views/components/form/utils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PessoaModel } from "model/api/gestao/pessoa/pessoa-model";
import { picker } from "utils/picker";
import classNames from "classnames";
import { useGetPessoaById } from './../../../../../../../../data/api/gestao/pessoa/get-pessoa-by-id';
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus";
import { useThemeQueries } from "views/theme";
import { PessoaPostModel } from "model/api/gestao/pessoa/pessoa-post-model";
import { AccordionClienteInformacoesList } from "views/pages/private/clientes/components/clientes-editar/components/accordion-cliente-informacoes-list/accordion-cliente-informacoes-list";
import { isEqual } from "lodash";
import { useStyles } from "./agente-externo-styles";
import { FormAgenteExternoEdicao } from "views/components/form/master/agente-externo/form-agente-externo";
import { LocalizacaoModel } from "model/api/gestao/localizacao/localizacao-model";
import { useConsultaCnpj } from "data/api/wsmaster";
import { ConsultaCNPJModel } from "model";
import { DialogOpcoesConsultaCnpj } from "views/components/dialog/dialog-opcoes-consulta-cnpj/dialog-opcoes-consulta-cnpj";

type Props = {
    id: string,
}

export const AgenteExternoEdit = ({ id }: Props) => {
    const classes = useStyles();

    const { showToast } = useToastSaurus();
    // const { tipoUsuario, deslogar, logarIntegracao } = useSessaoAtual()
    // const confirm = useConfirm()
    // const history = useHistory()

    const { putAtualizarPessoa, carregando: carregandoPut } = usePutAtualizarPessoa();
    const { getPessoaById, carregando: carregandoGet } = useGetPessoaById()
    const { consultarCNPJ, carregando: carregandoCNPJ } = useConsultaCnpj()
    // const { postIdentidadeAutenticar, carregando: carregandoPost } = usePostIdentidadeAutenticar()

    const [preenchendoTela, setPreechendoTela] = useState<boolean>(false)
    const { theme } = useThemeQueries()
    const [openAccordion, setOpenAccordion] = useState<boolean>(true)

    const carregando = carregandoGet || carregandoPut || preenchendoTela || carregandoCNPJ

    const cadClienteFormRef =
        useRef<DefaultFormRefs<PessoaPostModel>>(null);
    const refClienteModel = useRef<PessoaModel>(new PessoaModel())
    const [funcionarioForm, setFuncionarioForm] = useState<PessoaPostModel>(new PessoaPostModel())
    const [atualizar, setAtualizar] = useState<boolean>(false)

    const [cnpjEmpresa, setCnpjEmpresa] = useState<ConsultaCNPJModel>(new ConsultaCNPJModel())
    const [isAbertodialog, setIsAbertoDialog] = useState<boolean>(false)


    const [localizacao, setLocalizacao] = useState<LocalizacaoModel | undefined>()

    const recarregarForm = useCallback((model: PessoaPostModel) => {
        cadClienteFormRef.current?.fillForm(model);
    }, []);

    useEffect(() => {
        recarregarForm(funcionarioForm)
    }, [funcionarioForm, recarregarForm]);

    const getContratoByIdWrapper = useCallback(async () => {
        const res = await getPessoaById(id)

        if (res.erro) throw res.erro

        const ret = res.resultado?.data as PessoaModel

        refClienteModel.current = ret

        const cliente = picker<PessoaPostModel>(ret, new PessoaPostModel())

        return cliente
    }, [getPessoaById, id])

    const consultarCNPJWrapper = useCallback(async () => {
        try {
            const empresa = await consultarCNPJ(funcionarioForm.cpfCnpj, localizacao)
            setCnpjEmpresa(empresa)

            setIsAbertoDialog(true)
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [consultarCNPJ, funcionarioForm.cpfCnpj, localizacao, showToast])

    useEffect(() => {
        (async () => {
            try {
                setPreechendoTela(true)
                const funcionario = await getContratoByIdWrapper()
                setFuncionarioForm(funcionario)
            } catch (e: any) {
                showToast('error', e.message)
            } finally {
                setPreechendoTela(false)
            }
        })()
    }, [getContratoByIdWrapper, showToast])

    const saveChangeCliente = useCallback(
        async (model: PessoaPostModel) => {

            const ret = await putAtualizarPessoa(model);

            if (ret.erro) {
                throw ret.erro;
            }
        },
        [putAtualizarPessoa],
    );


    const handleSubmit = useCallback(
        async (model: PessoaPostModel) => {
            try {
                if (isEqual(model, funcionarioForm)) {
                    throw new Error('Nenhuma informação alterada')
                }
                await saveChangeCliente(model);
                showToast('success', "Agente Externo Atualizado!")

                setFuncionarioForm(model)
                return true
            } catch (e: any) {
                showToast('error', e.message);
                cadClienteFormRef.current?.resetForm()
                return false
            }
        },
        [funcionarioForm, saveChangeCliente, showToast],
    );

    // const handleLogin = useCallback(async () => {
    //     try {
    //         deslogar();
    //         // await logarIntegracao(funcionarioForm.id, funcionarioForm.cpfCnpj);
    //         await postIdentidadeAutenticar({ login: funcionarioForm.cpfCnpj, senha: funcionarioForm.senha! });
    //        
    //         window.location.href ='https://indicacao.saurus.net.br';
    //     } catch (e: any) {
    //         showToast('error', e.message)
    //     }
    // }, [deslogar, funcionarioForm.cpfCnpj, funcionarioForm.senha, postIdentidadeAutenticar, showToast])

    // const confirmLogin = useCallback(() => {
    //     confirm({
    //         title: 'Login',
    //         description: 'Tem certeza que deseja entrar na conta de ' + funcionarioForm.nomeComercial + '? Você será desconectado da sua sessão atual.',
    //         confirmationText: 'Entrar',
    //         cancellationText: 'Cancelar'
    //     }).then(() => handleLogin())
    // }, [confirm, funcionarioForm.nomeComercial, handleLogin])

    useEffect(() => {
        if ('geolocation' in navigator) {
            var options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            };
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    setLocalizacao(
                        new LocalizacaoModel(position.coords.latitude, position.coords.longitude)
                    );
                },
                function (error) {

                },
                options,
            );
        }
    }, []);

    const formFuncionarioEdicao = useMemo(() =>
        <div>
            <AccordionSaurus
                labelPrimary='Agente Externo'
                tipoExpand="bold"
                noPaperRoot={false}
                heightDivider={2}
                showDivider={openAccordion}
                colorDivider={theme.palette.primary.main}
                colorExpand={theme.palette.primary.main}
                expanded={openAccordion}
                onChange={() => setOpenAccordion(prev => !prev)}
            >
                <div className={classes.content}>
                    <div
                        className={classNames(
                            classes.contentForms,
                            carregando ? classes.contentFormsLoading : undefined,
                        )}
                    >
                        <FormAgenteExternoEdicao
                            loading={carregando}
                            onSubmit={handleSubmit}
                            showLoading={carregando}
                            ref={cadClienteFormRef}
                            consultarCnpj={consultarCNPJWrapper}
                        />
                    </div>
                    <div className={classes.acoes}>
                        <Grid container spacing={2} justifyContent={'flex-end'}>
                            {/* {tipoUsuario() === EnumTipoPessoas.SoftwareHouse && (
                                <Grid item xs={12} md={3}>
                                    <Tooltip title='Entrar na conta do agente externo'>
                                        <Button
                                            disabled={carregando}
                                            onClick={confirmLogin}
                                            variant="outlined"
                                            color="primary"
                                            size="large"
                                            fullWidth
                                        >
                                            <LoginIcon tipo="BUTTON" />
                                            Logar
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            )} */}
                            <Grid item xs={12} md={4}>
                                <Button
                                    disabled={carregando}
                                    onClick={() => {
                                        cadClienteFormRef.current!.submitForm();
                                    }}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                >
                                    <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                                    Salvar
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </AccordionSaurus>
            <DialogOpcoesConsultaCnpj
                openned={isAbertodialog}
                setAtualizar={setAtualizar}
                closeDialog={() => setIsAbertoDialog(false)}
                consultaCnpjModel={cnpjEmpresa}
                pessoaId={id}
            />
        </div>
        , [openAccordion, theme.palette.primary.main, classes.content, classes.contentForms, classes.contentFormsLoading, classes.acoes, carregando, handleSubmit, consultarCNPJWrapper, isAbertodialog, cnpjEmpresa, id])

    const accordionClienteInformacoesList = useMemo(() => (
        <AccordionClienteInformacoesList atualizar={atualizar} setAtualizar={setAtualizar} id={id} tipoPessoa={funcionarioForm.tipo}/>
    ), [atualizar, funcionarioForm.tipo, id])

    return (
        <Grid container spacing={2} className={classes.defaultContainer}>
            <Grid item xs={12}>
                {formFuncionarioEdicao}
            </Grid>
            <Grid item xs={12}>
                {accordionClienteInformacoesList}
            </Grid>
        </Grid>
    );
};
