import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { FaturaPagamentoModel } from 'model/api/gestao/fatura/fatura-pagamento-model';

export function usePostFaturaRegistrar() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<FaturaPagamentoModel>();

    const postFaturaRegistrar = useCallback(
        (faturaId: string, formaPagamentoId: string, documento: string, codCliente: string) =>
            invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/Fatura/${faturaId}/registrar?formaPagamentoId=${formaPagamentoId}`,
                enviarTokenUsuario: false,
                headers: {
                    documento: documento,
                    contratoId: codCliente
                }
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postFaturaRegistrar,
    };
}
