import { useState, useCallback, useEffect, useRef } from "react";
import { Box, Button, Card, Grid, Tooltip } from "@material-ui/core";
import { useStyles } from "../../../clientes/components/clientes-list/clientes-list-styles";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles";
import { useToastSaurus } from "services/app";
import { DesempenhoRevendaAnoMesListData } from "./desempenho-revenda-anomes-data";
import { useGetRevendaDesempenhoAnoMes } from "data/api/gestao/resultados/get-resultado-revenda-ano-mes";
import { RevendaDesempenhoAnoMesModel } from "model/api/gestao/revendedor/revenda-desempenho-anomes-model";
import { useGetRevendaDesempenhoAnoMesExcel } from "data/api/gestao/resultados/excel/get-resultado-revenda-excel-ano-mes";
import { ExportarExcelIcon } from "views/components/icons/exportar-excel-icon";
import { useThemeQueries } from "views/theme";

interface DesempenhoRevendaAnoMesListProps {
  id: string;
  anoMes: string;
  anoMesApuracao: number;
  setNome: (name: string) => any;
}

export const DesempenhoRevendaAnoMesList = ({ id, anoMes, anoMesApuracao }: DesempenhoRevendaAnoMesListProps) => {
  const classes = useStyles();
  const listClasses = useDefaultCadastroStyles();
  const {theme} = useThemeQueries();

  const { showToast } = useToastSaurus();
  const { getRevendaDesempenhoAnoMes, carregando: carregandoGet } = useGetRevendaDesempenhoAnoMes();
  const { getRevendaDesempenhoAnoMesExcel, carregando: carregandoExcel } = useGetRevendaDesempenhoAnoMesExcel()

  const carregando = carregandoExcel || carregandoGet

  const nomeRef = useRef<string>('')
  const [list, setList] = useState<RevendaDesempenhoAnoMesModel[]>([]);


  const search = useCallback(async (newPage: number) => {
    try {

      const res = await getRevendaDesempenhoAnoMes(anoMesApuracao, id, anoMes);
      if (res.erro) throw res.erro;

      if (res.resultado?.data === undefined) {
        return
      }

      setList(res.resultado.data)
      nomeRef.current = res.resultado.data[0].revenda

      return res;
    } catch (e: any) {
      showToast("error", e.message);
    }
  }, [getRevendaDesempenhoAnoMes, anoMesApuracao, id, anoMes, showToast])

  const getExcel = useCallback(async () => {
    try {
      const res = await getRevendaDesempenhoAnoMesExcel(anoMesApuracao, id, anoMes)
      if (res.erro) throw (res.erro)
      if (!res.resultado) return;

      const blob = new Blob([res.resultado?.data], { type: 'application/vnd.ms-excel' });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a')

      anchor.href = url
      anchor.download = `Desempenho-${nomeRef.current}-${anoMes}.xls`
      anchor.click()

    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [anoMes, anoMesApuracao, getRevendaDesempenhoAnoMesExcel, id, showToast])

  useEffect(() => {
    search(1)
  }, [search])

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Box display='flex' flexDirection='column' height='100%' justifyContent='space-between' flex={1} overflow='auto'>
          <Grid container className={listClasses.listContainer}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: theme.spacing(1) }}>
              <Card style={{ marginRight: 8, marginLeft: 24, display: 'flex', alignItems: 'center', maxHeight: '40px' }}>
                <Tooltip arrow title="Exportar Excel">
                  <Button color="primary" onClick={() => getExcel()}>
                    <ExportarExcelIcon tipo="BUTTON_EXPORT_MOBILE" />
                  </Button>
                </Tooltip>
              </Card>
            </Grid>
            <Grid item xs={12} className={classes.listContainer}>
              <DesempenhoRevendaAnoMesListData
                list={list}
                carregando={carregando}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};