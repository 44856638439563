import { makeStyles } from "@material-ui/core";

export const useInteracaoChatStyles = makeStyles((theme) => ({
    containerBalao: {
        maxWidth: 'calc(100% - 150px)',
        [theme.breakpoints.down("xs")]: {
            maxWidth: 'calc(100% - 100px)'
        },
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    balaoCinza: {
        background: theme.palette.grey[300]
    },
    balaoDourado: {
        background: "rgb(229 217 178)"
    },
    balaoAviso: {
        background: '#f3f3f3',
        padding: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        boxShadow: 'none',
    },
    speechPrimeiro: {
        content: "",
        width: '0px',
        height: '0px',
        position: 'absolute',
        border: '0 solid transparent',
        borderTop: `15px solid transparent`,
        borderBottom: `15px solid rgb(229 217 178)`,
        borderRight: `15px solid transparent`,
        borderLeft: `15px solid rgb(229 217 178)`,
        right: '-10px',
        bottom: '5px',
    },
    speechSegundo: {
        content: "",
        width: '0px',
        height: '0px',
        position: 'absolute',
        border: '0 solid transparent',
        borderTop: '10px solid transparent',
        borderBottom: `15px solid ${theme.palette.grey[300]}`,
        borderRight: `15px solid ${theme.palette.grey[300]}`,
        borderLeft: '15px solid transparent',
        left: '-10px',
        bottom: '5px',
    }
}))