import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        gap: theme.spacing(2),
        width: '100%',
        paddingTop: theme.spacing(2)
    },
    formFieldsContainer: {
        height: '350px',
        maxHeight: '100%',
        overflowY: 'auto'
    }
}))