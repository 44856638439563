import { useState } from 'react';
import { ConsultaFaturasHeader } from './components/consulta-faturas-header/consultas-faturas-header';
import { ConsultaFaturasList } from './components/consulta-faturas-list/consulta-faturas-list';
import { Grid } from '@material-ui/core';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { EnumEstadoConsultaFatura } from 'model/enums/enum-estado-consulta-fatura';

export const ConsultaFaturasPage = () => {
  const classes = useDefaultCadastroStyles();
  
  const [estado, setEstado] = useState<EnumEstadoConsultaFatura>(EnumEstadoConsultaFatura.ABERTAS)

  return (
    <Grid className={classes.root} style={{minHeight: '100vh'}}>
      <Grid className={classes.header}>
        <ConsultaFaturasHeader
          setEstado={setEstado}
          estado={estado}
        />
      </Grid>
      <Grid className={classes.list}>
        <ConsultaFaturasList estado={estado} setEstado={setEstado}/>
      </Grid>
    </Grid>
  );
};

export default ConsultaFaturasPage;
