import { KeyValueModel } from "model/api";
import { EnumSituacao } from "model/enums/enum-situacao";

export const SituacaoMock: KeyValueModel[] = [
    new KeyValueModel(EnumSituacao.Aberta, "Aberta"),
    new KeyValueModel(EnumSituacao.Atraso, "Atrasada"),
    new KeyValueModel(EnumSituacao.Bonificada, "Bonificada"),
    new KeyValueModel(EnumSituacao.Cancelada, "Cancelada"),
    new KeyValueModel(EnumSituacao.EmPagamento, "Em Pagamento"),
    new KeyValueModel(EnumSituacao.Fechada, "Fechada"),
    new KeyValueModel(EnumSituacao.Paga, "Paga"),
    new KeyValueModel(EnumSituacao.BonificadoRevenda, "Bonificado Revenda")
];

export const SituacaoMockTodos: KeyValueModel[] = [
    new KeyValueModel(EnumSituacao.Aberta, "Aberta"),
    new KeyValueModel(EnumSituacao.Bonificada, "Bonificada"),
    new KeyValueModel(EnumSituacao.Cancelada, "Cancelada"),
    new KeyValueModel(EnumSituacao.EmPagamento, "Em Pagamento"),
    new KeyValueModel(EnumSituacao.Fechada, "Fechada"),
    new KeyValueModel(EnumSituacao.Paga, "Paga"),
    new KeyValueModel(-1, "Todos"),
];