import { Button, Grid, Fade, useTheme } from '@material-ui/core';
import { useState, useCallback, ChangeEvent, useRef, useEffect } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { ProcurarIcon } from 'views/components/icons';
import { useThemeQueries } from 'views';
import { PesquisaClassificacoesProps } from './pesquisa-classificacao-props';
import { useStyles } from './pesquisa-classificacoes-styles';

export const PesquisaClassificacoes = (props: PesquisaClassificacoesProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { isMobile } = useThemeQueries();
  const [termoPesquisar, setTermoPesquisar] = useState('');
  const refInput = useRef<HTMLDivElement | null>(null);

  //FOCO AO FICAR VISIVEL
  useEffect(() => {
    setTermoPesquisar('');
  }, [isMobile, props.isOpened]);

  //CHAMADA DO PESQUISAR
  const pesquisar = useCallback(
    (event: any) => {
      event.preventDefault();
      props.onPesquisar(termoPesquisar);
    },
    [props, termoPesquisar],
  );

  //ALTERAR A PESQUISA
  const onChangePesquisa = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTermoPesquisar(event.target.value);
    },
    [],
  );

  return (
    <Fade in={props.isOpened} mountOnEnter unmountOnExit>
      <form onSubmit={pesquisar}>
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <div className={classes.containerFinal}>
              <TextFieldSaurus
                inputRef={refInput}
                fullWidth
                size="small"
                allowSubmit={true}
                value={termoPesquisar}
                placeholder="Pesquisar"
                onChange={onChangePesquisa}
                adornmentColor={theme.palette.text.disabled}
                endAdornmentButton={
                  <Button
                    color="primary"
                    type="submit"
                    className={classes.btnPesquisa}
                    fullWidth={true}
                  >
                    <ProcurarIcon tipo="BUTTON" />
                  </Button>
                }
              />
            </div>
          </Grid>
        </Grid>

      </form>
    </Fade>
  );
};
