import { VariaveisAmbiente } from 'config';
import { ApiListModel } from 'model/api/gestao/api-list-model/api-list-model';
import { IndicacoesModel } from 'model/api/gestao/indicacoes/indicacoes-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetIndicacoes() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ApiListModel<IndicacoesModel>>();

    const getIndicacoes = useCallback(
        (newPage: number, query?: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Indicacao?page=${newPage}${query}`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getIndicacoes,
    };
}
