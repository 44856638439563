import { Tooltip, Typography } from '@material-ui/core';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { EnumSituacaoPlano } from 'model/enums/enum-status-plano';
import { useCallback } from 'react';
import { useThemeQueries } from 'views/theme';
import { EditarIcon } from 'views/components/icons';
import {useStyles} from './card-planos-styles'
import { CardPlanosProps } from './card-planos-props';

export const CardPlanos = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardPlanosProps) => {
  const classes = useDefaultCardStyles();
  const planosStyles = useStyles();
  const { theme } = useThemeQueries()

  const retornarCorStatus = useCallback((): string => {
    switch (model.status) {
      case EnumSituacaoPlano.Ativado:
        return theme.palette.success.main
      case EnumSituacaoPlano.Desativado:
        return theme.palette.error.main
    }
    return theme.palette.primary.main
  }, [model.status, theme.palette.error.main, theme.palette.primary.main, theme.palette.success.main]);

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model.id);
        }}
      >
        <Tooltip arrow title={model.status === EnumSituacaoPlano.Ativado ? 'Ativado' : 'Desativado'} placement="right">
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        <div className={`${classes.cardContent} ${planosStyles.container}`}>
          <div className={`${classes.celulaGrid} ${planosStyles.item}`} style={{minWidth: 120, maxWidth: 200}}>
            <Typography color="primary" variant="caption">
              Nome
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              <b>{model.nome}</b>
            </Typography>
          </div>
          <div className={`${classes.celulaGrid} ${planosStyles.item}`} style={{minWidth: '150px'}}>
            <Typography color="primary" variant="caption">
              Sistema
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
              align='left'
            >
              <b>{model.sistemaNome}</b>
            </Typography>
          </div>
          <div className={`${classes.celulaGrid} ${planosStyles.item}`}>
            <Typography color="primary" variant="caption">
              Expiração
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              <b>{model.diasValidade}</b>
            </Typography>
          </div>
        </div>
        <Tooltip arrow title="Editar Plano">
          <div className={classes.cardRightButton} style={{marginLeft: '10px'}}>
            <EditarIcon tipo="BUTTON" fill={theme.palette.primary.main}/>
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
