import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePostCancelarNotaFiscal() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postCancelarNotaFiscal = useCallback(
        async (faturaId: string, id: string) =>
            invocarApi({
                url: `/${VariaveisAmbiente.apiVersion}/fatura/${faturaId}/nota-fiscal/${id}/cancelar`,
                method: 'POST',
                enviarTokenUsuario: true,
                timeout: 100000
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postCancelarNotaFiscal,
    };
}
