import { Box, Button, Divider, Grid, Paper, Typography } from "@material-ui/core";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { SalvarEditarIcon } from "views/components/icons";
import { DefaultFormRefs } from "views/components/form/utils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { picker } from "utils/picker";
import classNames from "classnames";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { useConsultaCnpj } from "data/api/wsmaster";
import { CircularLoading } from "views/components";
import { useStyles } from "./indicacoes-editar-dados-styles";
import { FormIndicacaoEdicao } from "views/components/form/master/indicacao/indicacao-edit/form-indicacao-edit";
import { useGetIndicacaoById } from "data/api/gestao/indicacoes/get-indicacoes-by-id";
import { IndicacoesModel } from "model/api/gestao/indicacoes/indicacoes-model";
import { usePutIndicacoes } from "data/api/gestao/indicacoes/put-indicacoes";
import { IndicacoesPutModel } from "model/api/gestao/indicacoes/indicacoes-put-model";
import { isEmpty } from "lodash";
import { AccordionIndicacaoCliente } from "./accordion-cliente/accordion-cliente-list";
import { AccordionIndicacaoInteresse } from "./accordion-interesse/accordion-interesse-list";
import { AccordionIndicacaoAvaliacaoAgente } from "./accordion-agente-data/accordion-agente-data";
import { AccordionIndicacaoAvaliacaoRevenda } from "./accordion-revenda-data/accordion-revenda-data";
import { AccordionIndicacaoIndicador } from "./accordion-indicador/accordion-indicacao-indicador";
import { EnumStatusIndicacao } from "model/enums/enum-status-indicacao";
import { useRetornarCorStatus } from "services/app/use-cases/retornar-cor-status";
import { AccordionClienteIdeal } from "views/pages/private/clientes/components/clientes-editar/components/accordion-cliente-ideal/accordion-cliente-ideal";


type Props = {
  id: string,
  abaSelecionada: number;
}

export const IndicacoesEditarDados = ({ id, abaSelecionada }: Props) => {
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const { retornarCorStatus } = useRetornarCorStatus();

  // REQUISIÇÕES E CONSULTAS
  const { putIndicacoes, carregando: carregandoPut } = usePutIndicacoes();
  const { getIndicacaoById, carregando: carregandoIndicacaoGet } = useGetIndicacaoById()
  const { carregando: carregandoConsulta } = useConsultaCnpj()
  const { tipoUsuario } = useSessaoAtual()

  // REFS E STATES
  const cadIndicacaoFormRef = useRef<DefaultFormRefs<IndicacoesPutModel>>(null);
  const refIndicacaoModel = useRef<IndicacoesModel>(new IndicacoesModel())
  const [indicacaoForm, setIndicacaoForm] = useState<IndicacoesPutModel>(new IndicacoesPutModel())
  const [indicacaoDados, setIndicacaoDados] = useState<IndicacoesModel>(new IndicacoesModel())

  const carregando = carregandoIndicacaoGet || carregandoPut || carregandoConsulta


  const recarregarForm = useCallback((model: IndicacoesPutModel) => {
    cadIndicacaoFormRef.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(indicacaoForm)
  }, [indicacaoForm, recarregarForm]);


  //GET BY ID INDICAÇÃO
  const getIndicacaoByIdWrapper = useCallback(async () => {
    const res = await getIndicacaoById(id)

    if (res.erro) throw res.erro

    const ret = res.resultado?.data as IndicacoesModel;
    setIndicacaoDados(ret);
    refIndicacaoModel.current = ret

    const indicacao = picker<IndicacoesPutModel>(ret, new IndicacoesPutModel())

    return indicacao
  }, [getIndicacaoById, id])

  const preencherTela = useCallback(async () => {
    try {
      const indicacao = await getIndicacaoByIdWrapper();
      setIndicacaoForm(indicacao);
    } catch (e: any) {
      showToast('error', e.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //RENDER GETS
  useEffect(() => {
    if (abaSelecionada === 1)
      preencherTela()
  }, [getIndicacaoByIdWrapper, preencherTela, showToast, abaSelecionada])

  // SALVAR ALTERAÇÕES DE INDICAÇÕES
  const saveChangeIndicacoes = useCallback(
    async (model: IndicacoesPutModel) => {
      const ret = await putIndicacoes(model);
      if (ret.erro) {
        throw ret.erro;
      }
    },
    [putIndicacoes],
  );

  const retornaStatus = useCallback((): string => {
    switch (indicacaoDados.status) {
        case EnumStatusIndicacao.InteracaoIniciada:
            return 'Interação Iniciada'
        case EnumStatusIndicacao.Disponivel:
            return 'Disponivel'
        case EnumStatusIndicacao.RetornoInterno:
            return 'Retorno Interno'
        case EnumStatusIndicacao.ContatoRealizado:
            return 'Contrato Realizado'
        case EnumStatusIndicacao.VisitaMarcada:
            return 'Visita Marcada'
        case EnumStatusIndicacao.VisitaRealizada:
            return 'Visita Realizada'
        case EnumStatusIndicacao.PropostaEnviada:
            return 'Proposta Enviada'
        case EnumStatusIndicacao.PropostaAceita:
            return 'Proposta Aceita'
        case EnumStatusIndicacao.PropostaRecusada:
            return 'Proposta Recusada'
        case EnumStatusIndicacao.InvalidezSolicitada:
            return 'Invalidez Solicitada'
        case EnumStatusIndicacao.CancelamentoSolicitado:
            return 'Cancelamento Solicitado'
        case EnumStatusIndicacao.Cancelado:
            return 'Cancelado'
        case EnumStatusIndicacao.Finalizado:
            return 'Finalizado'
        case EnumStatusIndicacao.Invalidado:
            return 'Inválida'
        case EnumStatusIndicacao.AguardandoValidacao:
            return 'Aguardando Validação'
    }
    return ''
}, [indicacaoDados.status])

  const handleSubmit = useCallback(
    async (model: IndicacoesPutModel) => {
      try {
        model.responsavelId = !isEmpty(model.responsavelId) ? model.responsavelId : indicacaoDados.responsavel?.id || null
        model.instrucao = isEmpty(model.instrucao) ? indicacaoDados.instrucao : model.instrucao
        model.motivoAvaliacaoIndicador =
          isEmpty(model.motivoAvaliacaoIndicador)
            ? indicacaoDados.motivoAvaliacaoIndicador
            : model.motivoAvaliacaoIndicador
        model.sistemaId = isEmpty(model.sistemaId) ? indicacaoDados.sistema?.id || null : model.sistemaId
        model.avaliacaoResponsavel =
          model.avaliacaoResponsavel === indicacaoDados.avaliacaoResponsavel
            ? indicacaoDados.avaliacaoResponsavel
            : model.avaliacaoResponsavel
        model.avaliacaoIndicador =
          model.avaliacaoIndicador === indicacaoDados.avaliacaoIndicador
            ? indicacaoDados.avaliacaoIndicador
            : model.avaliacaoIndicador
        model.nivelDeInteresse =
          model.nivelDeInteresse === indicacaoDados.nivelDeInteresse
            ? indicacaoDados.nivelDeInteresse
            : model.nivelDeInteresse
        model.observacaoFinalizacao = indicacaoDados.observacaoFinalizacao
        model.id = indicacaoDados.id

        await saveChangeIndicacoes(model);
        showToast('success', "Indicação Atualizada!")
        await preencherTela()
      } catch (e: any) {
        showToast('error', e.message);
        cadIndicacaoFormRef.current?.resetForm()
      }
    },
    [indicacaoDados.avaliacaoIndicador, indicacaoDados.avaliacaoResponsavel, indicacaoDados.id, indicacaoDados.instrucao, indicacaoDados.motivoAvaliacaoIndicador, indicacaoDados.nivelDeInteresse, indicacaoDados.observacaoFinalizacao, indicacaoDados.responsavel?.id, indicacaoDados.sistema?.id, preencherTela, saveChangeIndicacoes, showToast],
  );

  // ACCORDION INICIAL
  const formIndicacaoEdit = useMemo(() =>
    <div>
      <Paper
        style={{
          padding: '10px',
          boxShadow: '1px 2px 3px 1px #00000030'
        }}
      >
        <div>
          <Box display={'flex'} justifyContent="space-between" width={'100%'}>
            <Grid style={{
              display: 'flex',
              alignItems: 'center'
            }}>
              <Box>
                <Typography
                  variant="h6"
                >
                  Dados da Indicação
                </Typography>
              </Box>
            </Grid>
            <Grid style={{
              display: 'flex',
              alignItems: 'center',
            }}>
              <Typography
                variant="body2"
                style={{
                  borderRadius: 5,
                  padding: '5px 10px',
                  textAlign: 'center',
                  background: retornarCorStatus(indicacaoDados.status), width: '100%', color: '#FFF',
                  display: 'flex',
                }}
              >
                <Box fontWeight={500}>
                  {retornaStatus()}
                </Box>
              </Typography>
            </Grid>
          </Box>
          <div style={{
            padding: '5px 0px'
          }}>
            <Divider variant="fullWidth" className={classes.divider} />
          </div>
          <div className={classes.content}>
            {carregandoConsulta && <CircularLoading tipo="FULLSIZED" />}
            <div
              className={classNames(
                classes.contentForms,
                carregando ? classes.contentFormsLoading : undefined,
              )}
            >
              <FormIndicacaoEdicao
                ref={cadIndicacaoFormRef}
                showLoading={true}
                indicacao={indicacaoDados}
                loading={carregando}
                onSubmit={handleSubmit}
                tipoRevenda={tipoUsuario() === EnumTipoPessoas.Revendedor}
                tipoFormPessoa={EnumTipoPessoas.Cliente}
              />
            </div>
            <div className={classes.acoes}>
              <Grid container spacing={2} justifyContent='flex-end'>
                <Grid item xs={12} md={4}>
                  <Button
                    disabled={carregando}
                    onClick={() => {
                      cadIndicacaoFormRef.current!.submitForm()
                    }}
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                  >
                    <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Paper>
    </div>
    , [retornarCorStatus, retornaStatus, classes.divider, classes.content, classes.contentForms, classes.contentFormsLoading, classes.acoes, carregandoConsulta, carregando, indicacaoDados, handleSubmit, tipoUsuario])


  // ACCORIONS SECUNDARIOS
  const accordionIndicacaoCliente = useMemo(() => (
    <AccordionIndicacaoCliente pessoa={indicacaoDados.cliente} />
  ), [indicacaoDados.cliente])

  const accordionIndicacaoInteresse = useMemo(() => (
    <AccordionIndicacaoInteresse onSubmit={handleSubmit} indicacoes={indicacaoDados} carreagando={carregando} hasValor={indicacaoDados.nivelDeInteresse > 0 || (indicacaoDados.instrucao ?? '').length > 0} />
  ), [carregando, handleSubmit, indicacaoDados])

  const accordionIndicacaoAvaliacaoAgente = useMemo(() => (
    <AccordionIndicacaoAvaliacaoAgente onSubmit={handleSubmit} indicacoes={indicacaoDados} carreagando={carregando} hasValor={indicacaoDados.avaliacaoIndicador > 0 || (indicacaoDados.motivoAvaliacaoIndicador ?? '').length > 0} />
  ), [carregando, handleSubmit, indicacaoDados])

  const accordionIndicacaoAvaliacaoRevenda = useMemo(() => (
    <AccordionIndicacaoAvaliacaoRevenda onSubmit={handleSubmit} indicacoes={indicacaoDados} carreagando={carregando} hasValor={indicacaoDados.avaliacaoResponsavel > 0 || (indicacaoDados.motivoAvaliacaoResponsavel ?? '').length > 0} />
  ), [carregando, handleSubmit, indicacaoDados])

  const accordionIndicacaoIndicador = useMemo(() => (
    <AccordionIndicacaoIndicador id={indicacaoDados.indicador.id} pessoa={indicacaoDados.indicador} />
  ), [indicacaoDados.indicador])

  const accordionClienteIdeal = useMemo(() => (
    <AccordionClienteIdeal id={indicacaoDados.cliente.id} defaultState/>
  ), [indicacaoDados.cliente.id])

  return (
    <>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Grid container spacing={2} className={classes.defaultContainer} style={{ margin: 0 }}>
        <Grid item xs={12}>
          {formIndicacaoEdit}
        </Grid>
        <Grid item xs={12}>
          {accordionIndicacaoCliente}
        </Grid>
        <Grid item xs={12}>
          {accordionIndicacaoIndicador}
        </Grid>
        <Grid item xs={12}>
          {accordionIndicacaoInteresse}
        </Grid>
        <Grid item xs={12}>
          {accordionIndicacaoAvaliacaoAgente}
        </Grid>
        <Grid item xs={12}>
          {accordionIndicacaoAvaliacaoRevenda}
        </Grid>
        <Grid item xs={12}>
          {accordionClienteIdeal}
        </Grid>
      </Grid>
    </>
  );
};
