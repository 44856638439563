import { Box, Typography } from '@material-ui/core';
import { CardPessoaPagamentoProps } from './card-pessoa-pagamento-props';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import {useStyles} from './card-pessoa-pagamento-styles'
import { useThemeQueries } from 'views/theme';
import { EditarIcon } from 'views/components/icons';

export const CardPessoaPagamento = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardPessoaPagamentoProps) => {
  const classes = useDefaultCardStyles();
  const bgStyles = useStyles();
  const {isMobile} = useThemeQueries();

  return (
    <>
      <DefaultCard isSelected={selected} onClick={() => onClick(model)} className={bgStyles.cardBackground}>
          <div className={`${classes.cardContent} ${bgStyles.container}`}>
            <Box>
              <Typography color="textPrimary" variant="caption">
                Nome
              </Typography>
              <Typography color="textPrimary" variant="body1">
                <b>{model.formaPagamento.nome}</b>
              </Typography>
            </Box>
            <Box mr={2}>
              <Typography color="textPrimary" variant="caption">
                Padrão
              </Typography>
              <Typography color="textPrimary" variant="body1">
                <b>{model.padrao ? 'Sim' : 'Não'}</b>
              </Typography>
            </Box>
          </div>
          {!isMobile ? <div className={bgStyles.editIcon}>
          <EditarIcon tipo='BUTTON' />
        </div> : null}
      </DefaultCard>
    </>
  );
};
