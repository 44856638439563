import { VariaveisAmbiente } from 'config';
import { ApiListModel } from 'model/api/gestao/api-list-model/api-list-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { ClassificacaoModel } from 'model/api/gestao/classificacao/classificacao-model';

export function useGetPessoaClassificacao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ApiListModel<ClassificacaoModel>>();

    const getPessoaClassificacao = useCallback(
        async (page: number, query?: string) => {

            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/pessoa-classificacao?page=${page}${query}`,
                method: 'GET',
                enviarTokenUsuario: true
            })
        }
        , [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getPessoaClassificacao,
    };
}
