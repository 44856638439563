
import { useMemo } from 'react';
import * as Yup from 'yup';


export const useFormEditarSistemaValidation = () => {


  const FormEditarSistemaValidationYup = useMemo(() => {
    return Yup.object().shape({
      nome: Yup.string().required('Nome é obrigatório'),
      descricao: Yup.string().required('Descrição é obrigatório'),
      quantidadeMinima: Yup.number().min(-1, 'Quantidade mínima é -1').required('Quantidade mínima é obrigatória.')
      .typeError('Informe um valor numérico.')
    });
  }, [])

  return {
    FormEditarSistemaValidationYup,
  }
}
