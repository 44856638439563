import { VariaveisAmbiente } from 'config';
import { NotaFiscalModel } from 'model/api/gestao/nota-fiscal/nota-fiscal-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetNotaFiscal() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<NotaFiscalModel>();

    const getNotaFiscal = useCallback(
        (id: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/fatura/${id}/nota-fiscal`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getNotaFiscal,
    };
}
