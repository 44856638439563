import { useCallback } from "react";
import { PrivatePageHeader } from "views/components/headers";
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { DesfazerIcon, FiltroIcon, MenuIcon, VoltarIcon } from "views/components/icons";
import { PesquisaPlanos } from "./components/pesquisa-planos/pesquisa-planos";
import { useMenuPrincipal } from "services/app";
import { useThemeQueries } from "views";
import { PlanosListSearchProps } from "../planos-list/planos-list-search-props";
import { useHistory } from "react-router-dom";
import { useHeaderStyles } from "views/components/headers/header-private-page/header-styles";
import { SetaCimaIcon } from "views/components/icons/seta-cima-icon";

export interface PlanosHeaderProps {
  openPesquisa: boolean;
  setOpenPesquisa: (state: boolean) => any;
  setSearchProps: (props: PlanosListSearchProps) => any;
  titulo: string
}

export const PlanosHeader = ({
  openPesquisa,
  setOpenPesquisa,
  setSearchProps,
  titulo
}: PlanosHeaderProps) => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile, theme } = useThemeQueries();
  const history = useHistory()
  const classes = useHeaderStyles();

  const leftArea = useCallback(
    () =>
      isMobile ? (
        <ButtonPrivateHeader
          icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
          tooltip="Menu"
          onClick={abrirMenu}
        ></ButtonPrivateHeader>
      ) : (titulo !== "Planos") ? (
        <ButtonPrivateHeader
          icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
          tooltip="Voltar"
          onClick={() => history.push('/planos')}
        />
      ) : null,
    [isMobile, abrirMenu, titulo, history]
  );

  const rightArea = useCallback(
    () => titulo === "Planos" ? (isMobile ?
      <ButtonPrivateHeader
        icon={
          openPesquisa ? (
            <DesfazerIcon tipo="PRIVATE_HEADER"></DesfazerIcon>
          ) : (
            <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>
          )
        }
        tooltip={!openPesquisa ? "Pesquisar" : "Voltar"}
        onClick={() => setOpenPesquisa(!openPesquisa)}
      ></ButtonPrivateHeader>
      : (
        <ButtonPrivateHeader
          className={classes.pesquisaBox}
          text={!openPesquisa ? 'Filtros' : 'Fechar Filtros'}
          icon={
            openPesquisa ? (
              <SetaCimaIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main}></SetaCimaIcon>
            ) : (
              <FiltroIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main}></FiltroIcon>
            )
          }
          tooltip={!openPesquisa ? "Pesquisar" : "Voltar"}
          onClick={() => setOpenPesquisa(!openPesquisa)}
        ></ButtonPrivateHeader>
      )) : null,
    [titulo, isMobile, openPesquisa, classes.pesquisaBox, theme.palette.primary.main, setOpenPesquisa]
  );

  const closePesquisaWrapper = useCallback(() => {
    setOpenPesquisa(false);
  }, [setOpenPesquisa]);

  const onPesquisar = useCallback(
    (props: PlanosListSearchProps) => {
      setSearchProps(props);
    },
    [setSearchProps]
  );

  const pesquisa = (
    <PesquisaPlanos
      isOpened={openPesquisa!}
      onCloseSearch={closePesquisaWrapper}
      onPesquisar={onPesquisar}
    />
  );

  return (
    <>
      <PrivatePageHeader
        title={titulo}
        leftArea={leftArea()}
        bottomArea={pesquisa}
        rightArea={rightArea()}
      />
    </>
  );
};
