import { useEffect, useState, useCallback } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useStyles } from './planos-list-styles';
import { PlanosListData } from './planos-list-data';
import { Paginacao } from 'views/components/paginacao';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useCadastros, useToastSaurus } from 'services/app';
import { useGetPlanos } from 'data/api/gestao/planos';
import { PlanosModel } from 'model/api/gestao/plano/planos-model';
import { PlanosListSearchProps } from './planos-list-search-props';
import { useHistory } from 'react-router';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';


export interface PlanosListProps {
  searchProps: PlanosListSearchProps;
}


export const PlanosList = (props: PlanosListProps) => {
  const classes = useStyles();
  const listClasses = useDefaultCadastroStyles();

  const { getPlanos, carregando } = useGetPlanos();

  const { showToast } = useToastSaurus();

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<PlanosModel>(),
  });

  const [selectedList, setSelectedList] = useState<Array<string>>([]);

  const { cadastroPlanoState, abrirEdicaoPlano, edicaoPlanoState } = useCadastros()

  const { location } = useHistory();

  const fillResult = useCallback(
    (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<PlanosModel>,
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    [],
  );

  const search = useCallback(
    async (newPage: number) => {
      try {
        const res = await getPlanos(newPage, `query=${props.searchProps.termo}
          ${props.searchProps.situacao > -1 ? `&situacao=${props.searchProps.situacao}` : ''}`);
        if (res.erro) throw res.erro;

        if (!res.resultado) return;

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data.pageIndex > res.resultado?.data.totalPages &&
          res.resultado?.data.totalResults > 0
        ) {
          search(res.resultado.data.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data.pageIndex,
          res.resultado?.data.totalPages,
          res.resultado?.data.totalResults,
          res.resultado?.data.list,
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [getPlanos, props.searchProps.termo, props.searchProps.situacao, fillResult, showToast],
  );

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        await search(newPage);
      }
    },
    [search, queryStatus.totalPages],
  );

  const onCardSelected = useCallback(
    (id: string) => {
      abrirEdicaoPlano(id, location.pathname, true)
    },
    [abrirEdicaoPlano, location.pathname],
  );

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  useEffect(() => {
    if (!edicaoPlanoState.aberto && !cadastroPlanoState.aberto) {
      search(queryStatus.page);
    }
  }, [queryStatus.page, search, cadastroPlanoState.aberto, edicaoPlanoState.aberto]);

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Box display='flex' flexDirection='column' height='100%' justifyContent='space-between' flex={1} overflow='auto' >
          <Grid container className={listClasses.listContainer}>
            <Grid item xs={12}>
              <Paginacao
                pageChanged={pageChanged}
                totalPages={queryStatus.totalPages}
                totalRegisters={queryStatus.totalResults}
                currentPage={queryStatus.page}
              />
            </Grid>
            <Grid item xs={12} className={classes.listContainer}>
              <PlanosListData
                carregando={carregando}
                list={queryStatus.list}
                selectedList={selectedList}
                onCardSelected={onCardSelected}
                onCardChecked={onCardChecked}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};
