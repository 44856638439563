import { guidEmpty } from "utils/guid-empty";

export class PessoaClienteModel {
    constructor(
        public pessoaId: string = guidEmpty(),
        public perguntaId: string = guidEmpty(),
        public pergunta: string = '',
        public respostaId: string = guidEmpty(),
        public resposta: string = '',
        public pontos: number = 0,
        public maxPontos: number = 0
    ) { }
}

export class PessoaClienteGetModel {
    constructor(
        public respostas: PessoaClienteModel[] = [],
        public pontos: number = 0,
        public maxPontos: number = 0
    ) { }
}