import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePostPessoaLiberacaoProvisoria() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postLiberacaoProvisoria = useCallback(
        (id: string) =>
            invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/Pessoa/${id}/liberacao-provisoria`,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postLiberacaoProvisoria,
    };
}