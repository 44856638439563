import {
    forwardRef,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { KeyValueModel } from 'model';
import { PessoaTributoModel } from 'model/api/gestao/pessoa/pessoa-tributo-model';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormPessoaTributoValidation } from './form-pessoa-tributo-validation';
import { picker } from 'utils/picker';
import { useStyles } from './form-pessoa-tributo-styles'
import { TpTributacaoMock } from 'data/mocks/tipo-tributacao-mock';

interface PessoaTributoFormModelProps
    extends DefaultFormProps<PessoaTributoModel> { }

export const FormPessoaTributo = forwardRef<
    DefaultFormRefs<PessoaTributoModel>,
    PessoaTributoFormModelProps
>(({ loading, onSubmit, ...props }: PessoaTributoFormModelProps, ref) => {
    const { isMobile } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const classes = useStyles();

    const { FormPessoaTributoValidationYup } = useFormPessoaTributoValidation()

    const refInputNome = useRef<HTMLInputElement>(null);

    const [modelForm, setModelForm] = useState<PessoaTributoModel>(
        new PessoaTributoModel(),
    );

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        reset,
    } = useForm<PessoaTributoModel>({
        defaultValues: { ...modelForm },
        resolver: yupResolver(FormPessoaTributoValidationYup),
        criteriaMode: 'all',
        mode: 'onBlur' && 'onChange',
    });

    const carregando = loading;

    const submitEnviar = (values: PessoaTributoModel) => {
        const pessoaTributo = picker<PessoaTributoModel>(values, new PessoaTributoModel())
        onSubmit(pessoaTributo, modelForm);
    };

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(submitEnviar)();
        },
        resetForm: () => {
            reset();
            if (!isMobile) refInputNome.current?.focus();
        },
        fillForm: (model: PessoaTributoModel) => {
            setModelForm({ ...model });
            reset({ ...model });
            if (!isMobile) refInputNome.current?.focus();
        },
    }));

    return (
        <>
            <div className={utilClasses.formContainer}>
                {carregando && props.showLoading && (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="FULLSIZED" />
                    </div>
                )}

                <form
                    onSubmit={handleSubmit(submitEnviar)}
                    className={loading ? utilClasses.controlLoading : ''}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="aliquotaCOFINS"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        disabled={loading}
                                        allowSubmit={false}
                                        tipo="DECIMAL"
                                        manterMascara
                                        casasDecimais={3}
                                        positivo
                                        label="Aliquota COFINS"
                                        fullWidth
                                        autoComplete={'off'}
                                        helperText={
                                            touchedFields.aliquotaCOFINS && errors.aliquotaCOFINS
                                                ? errors.aliquotaCOFINS.message
                                                : undefined
                                        }
                                        error={Boolean(touchedFields.aliquotaCOFINS && errors.aliquotaCOFINS && errors.aliquotaCOFINS.message)}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="aliquotaDeducao"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        disabled={loading}
                                        allowSubmit={false}
                                        tipo="DECIMAL"
                                        manterMascara
                                        casasDecimais={3}
                                        positivo
                                        label="Aliquota Dedução"
                                        fullWidth
                                        autoComplete={'off'}
                                        helperText={
                                            touchedFields.aliquotaDeducao && errors.aliquotaDeducao
                                                ? errors.aliquotaDeducao.message
                                                : undefined
                                        }
                                        error={Boolean(touchedFields.aliquotaDeducao && errors.aliquotaDeducao && errors.aliquotaDeducao.message)}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="aliquotaISS"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        disabled={loading}
                                        allowSubmit={false}
                                        tipo="DECIMAL"
                                        manterMascara
                                        casasDecimais={3}
                                        positivo
                                        label="Aliquota ISS"
                                        fullWidth
                                        autoComplete={'off'}
                                        helperText={
                                            touchedFields.aliquotaISS && errors.aliquotaISS
                                                ? errors.aliquotaISS.message
                                                : undefined
                                        }
                                        error={Boolean(touchedFields.aliquotaISS && errors.aliquotaISS && errors.aliquotaISS.message)}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="aliquotaPIS"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        disabled={loading}
                                        allowSubmit={false}
                                        tipo="DECIMAL"
                                        manterMascara
                                        casasDecimais={3}
                                        positivo
                                        label="Aliquota PIS"
                                        fullWidth
                                        autoComplete={'off'}
                                        helperText={
                                            touchedFields.aliquotaPIS && errors.aliquotaPIS
                                                ? errors.aliquotaPIS.message
                                                : undefined
                                        }
                                        error={Boolean(touchedFields.aliquotaPIS && errors.aliquotaPIS && errors.aliquotaPIS.message)}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="aliquotaINSS"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        disabled={loading}
                                        allowSubmit={false}
                                        tipo="DECIMAL"
                                        manterMascara
                                        casasDecimais={3}
                                        positivo
                                        label="Aliquota INSS"
                                        fullWidth
                                        autoComplete={'off'}
                                        helperText={
                                            touchedFields.aliquotaINSS && errors.aliquotaINSS
                                                ? errors.aliquotaINSS.message
                                                : undefined
                                        }
                                        error={Boolean(touchedFields.aliquotaINSS && errors.aliquotaINSS && errors.aliquotaINSS.message)}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="aliquotaIR"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        disabled={loading}
                                        allowSubmit={false}
                                        tipo="DECIMAL"
                                        manterMascara
                                        casasDecimais={3}
                                        positivo
                                        label="Aliquota IR"
                                        fullWidth
                                        autoComplete={'off'}
                                        helperText={
                                            touchedFields.aliquotaIR && errors.aliquotaIR
                                                ? errors.aliquotaIR.message
                                                : undefined
                                        }
                                        error={Boolean(touchedFields.aliquotaIR && errors.aliquotaIR && errors.aliquotaIR.message)}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="aliquotaCSLL"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        disabled={loading}
                                        allowSubmit={false}
                                        tipo="DECIMAL"
                                        manterMascara
                                        casasDecimais={3}
                                        positivo
                                        label="Aliquota CSLL"
                                        fullWidth
                                        autoComplete={'off'}
                                        helperText={
                                            touchedFields.aliquotaCSLL && errors.aliquotaCSLL
                                                ? errors.aliquotaCSLL.message
                                                : undefined
                                        }
                                        error={Boolean(touchedFields.aliquotaCSLL && errors.aliquotaCSLL && errors.aliquotaCSLL.message)}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="pTotTrib"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        disabled={loading}
                                        allowSubmit={false}
                                        tipo="DECIMAL"
                                        manterMascara
                                        casasDecimais={3}
                                        positivo
                                        label="% Tributário (pTotTrib)"
                                        fullWidth
                                        autoComplete={'off'}
                                        helperText={
                                            touchedFields.pTotTrib && errors.pTotTrib
                                                ? errors.pTotTrib.message
                                                : undefined
                                        }
                                        error={Boolean(touchedFields.pTotTrib && errors.pTotTrib && errors.pTotTrib.message)}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="issRetido"
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        disabled={loading}
                                        allowSubmit={false}
                                        conteudo={[
                                            new KeyValueModel(true, 'Retido'),
                                            new KeyValueModel(false, 'Não Retido')
                                        ]}
                                        label="ISS Retido"
                                        fullWidth
                                        autoComplete={'off'}
                                        helperText={
                                            errors.issRetido
                                                ? errors.issRetido.message
                                                : undefined
                                        }
                                        error={Boolean(errors.issRetido && errors.issRetido.message)}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="tipoTributacao"
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        disabled={loading}
                                        allowSubmit={false}
                                        label="Tipo de Tributação"
                                        conteudo={TpTributacaoMock}
                                        fullWidth
                                        autoComplete={'off'}
                                        helperText={
                                            errors.tipoTributacao
                                                ? errors.tipoTributacao.message
                                                : undefined
                                        }
                                        error={Boolean(errors.tipoTributacao && errors.tipoTributacao.message)}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="serieRPS"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        disabled={loading}
                                        allowSubmit={false}
                                        tipo="TEXTO"
                                        label="Série RPS"
                                        fullWidth
                                        autoComplete={'off'}
                                        helperText={
                                            errors.serieRPS
                                                ? errors.serieRPS.message
                                                : undefined
                                        }
                                        error={Boolean(errors.serieRPS && errors.serieRPS.message)}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box className={classes.fieldContainer}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={8}>
                                        <Controller
                                            name="textoServico"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={loading}
                                                    allowSubmit={false}
                                                    tipo="TEXTO"
                                                    label="Texto de Serviço"
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    helperText={
                                                        errors.textoServico
                                                            ? errors.textoServico.message
                                                            : undefined
                                                    }
                                                    error={Boolean(errors.textoServico && errors.textoServico.message)}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Controller
                                            name="codigoServico"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={loading}
                                                    allowSubmit={false}
                                                    tipo="TEXTO"
                                                    label="Código de Serviço"
                                                    max={10}
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    helperText={
                                                        errors.codigoServico
                                                            ? errors.codigoServico.message
                                                            : undefined
                                                    }
                                                    error={Boolean(errors.codigoServico && errors.codigoServico.message)}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </>
    );
});
