import { useMemo, useState } from 'react';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { DesempenhoRevendedaHeader } from './components/desempenho-revendas-header/desempenho-revendas-header';
import { DesempenhoRevendaList } from './components/desempenho-revendas-list/desempenho-revenda-list';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { DesempenhoRevendaAnoMesList } from './components/desempenho-revenda-anomes-list/desempenho-revenda-anomes-list';
import { DesempenhoRevendaByIdList } from './components/desempenho-revenda-by-id-list/desempenho-revenda-by-id-list';
import { DesempenhoRevendaSearchProps } from './components/desempenho-revendas-list/desempenho-revenda-search-props';
import { useSessaoAtual } from 'services/app';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { dateParaCompetencia } from 'utils/converter-competencia';

interface paramProps {
  revendaId: string;
  anoMes: string;
}

export const DesempenhoRevendasPage = () => {
  const classes = useDefaultCadastroStyles();
  const {tipoUsuario} = useSessaoAtual();

  const isRevendedor = tipoUsuario() === EnumTipoPessoas.Revendedor;

  const [openPesquisa, setOpenPesquisa] = useState(false);
  const [searchProps, setSearchProps] = useState<DesempenhoRevendaSearchProps>({
    anoMesApuracao: dateParaCompetencia(new Date()),
    gerenteId: '',
    gerenteNome: '',
    revendaId: '',
    revendaNome: ''
  });

  const [nomeRevenda, setNomeRevenda] = useState<string>('')

  const { revendaId, anoMes } = useParams<paramProps>()

  const switchPage = useMemo(() => {
    if (!isEmpty(anoMes)) {
      return (
        <DesempenhoRevendaAnoMesList
          anoMes={anoMes}
          anoMesApuracao={searchProps.anoMesApuracao}
          id={revendaId}
          setNome={setNomeRevenda}
        />
      )
    }
    if (!isEmpty(revendaId)) {
      return (
        <DesempenhoRevendaByIdList
          id={revendaId}
          setNome={setNomeRevenda}
          anoMesApuracao={searchProps.anoMesApuracao}
        />
      )
    }

    return (
      <DesempenhoRevendaList
        searchProps={searchProps}
      />
    )
  }, [anoMes, revendaId, searchProps])

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <DesempenhoRevendedaHeader
          titulo={!isEmpty(anoMes) ? `Detalhes de ${anoMes} - ${nomeRevenda}` : !isEmpty(revendaId) ? `Desempenho de ${nomeRevenda}` : isRevendedor ? 'Desempenho' : 'Desempenho dos Revendedores'}
          openPesquisa={openPesquisa}
          setSearchProps={setSearchProps}
          setOpenPesquisa={setOpenPesquisa}
          mostrarFiltros={isEmpty(revendaId) && isEmpty(anoMes)}
          revendaId={revendaId}
        />
      </div>
      {switchPage}
    </div>
  );
};

export default DesempenhoRevendasPage;