import { VariaveisAmbiente } from 'config';
import { SistemaModel } from 'model/api/gestao/sistemas/sistema-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePutAtualizarSistema() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putAtualizarSistema = useCallback(
        (sistema: SistemaModel) => {

            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Sistema`,
                method: 'PUT',
                data: sistema,
                enviarTokenUsuario: true
            })
        },
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putAtualizarSistema,
    };
}
