import { KeyValueModel } from "model/api";
import { EnumSituacaoSistema } from "model/enums/enum-situacao-sistema";

export const SituacaoSistemaMock: KeyValueModel[] = [
    new KeyValueModel(EnumSituacaoSistema.Ativo, "Ativo"),
    new KeyValueModel(EnumSituacaoSistema.Bloqueado, "Bloqueado"),
    new KeyValueModel(EnumSituacaoSistema.Desativado, "Desativado"),
];

export const SituacaoSistemaMockTodos: KeyValueModel[] = [
    new KeyValueModel(EnumSituacaoSistema.Ativo, "Ativo"),
    new KeyValueModel(EnumSituacaoSistema.Bloqueado, "Bloqueado"),
    new KeyValueModel(EnumSituacaoSistema.Desativado, "Desativado"),
    new KeyValueModel(-1, "Todos"),
];