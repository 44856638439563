import { useThemeQueries } from "views/theme";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus";
import { Grid, Typography } from "@material-ui/core";
import { FinalizadoIcon } from "views/components/icons/finalizado-icon";

interface Props {
    open: boolean;
}

export const DialogFinalizacaoIndicacao = ({
    open,
}: Props) => {

    const { theme } = useThemeQueries();

    return (
        <>
            <DialogSaurus
                aberto={open}
                titulo="Finalizado"
                tamanho="xs"
                centralizarTitulo
                colorTitulo={theme.palette.primary.main}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography
                            variant="body2"
                            color="textPrimary"
                        >
                            O seu atendimento a este cliente foi concluído.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', paddingBottom: '8px'}}>
                        <FinalizadoIcon tipo="GERAL" />
                    </Grid>
                </Grid>
            </DialogSaurus>
        </>
    );
}