import { VariaveisAmbiente } from 'config';
import { ApiListModel } from 'model/api/gestao/api-list-model/api-list-model';
import { PessoasModel } from 'model/api/gestao/pessoa/pessoas-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetPessoa() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ApiListModel<PessoasModel>>();

    const getPessoa = useCallback(
        async (page: number, queryBusca: string) => {
            let queryPage = "";
            if (page) {
                queryPage = "page=" + page.toString();
            }
            const queryFinal = `${queryPage?.length > 0 || queryBusca?.length > 0 ? "?" : ""
                }${queryPage}${queryPage.length > 0 ? "&" : ""}${queryBusca}`;

            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Pessoa${queryFinal}`,
                method: 'GET',
                enviarTokenUsuario: true
            })
        }
        , [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getPessoa,
    };
}
