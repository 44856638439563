import { VariaveisAmbiente } from 'config';
import { PessoaComissaoModel } from 'model/api/gestao/pessoa/pessoa-comissao-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetPessoaComissaoById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<PessoaComissaoModel>();

    const getPessoaComissaoById = useCallback(
        async (id: string, pessoaId: string) => {

            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/pessoa/${pessoaId}/comissao/${id}`,
                method: 'GET',
                enviarTokenUsuario: true
            })
        }
        , [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getPessoaComissaoById,
    };
}
