import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const NotaFiscalIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} >
                <path d="M42.7027 14.1532C38.8251 10.2893 34.9476 6.42548 31.0803 2.55135C30.7296 2.19168 30.3687 2.03754 29.8531 2.03754C26.3055 2.05809 22.7579 2.05809 19.2 2.04782C16.3537 2.04782 13.5177 2.02726 10.6713 2.01699C9.73288 1.98616 8.79442 2.00671 7.85596 2.00671C7 2.00671 7 2.00671 7 2.83908C7 17.6265 7 32.4243 7 47.2117C7 48.0955 7 48.0955 7.87658 48.0955C19.365 48.0955 30.8637 48.0955 42.3521 48.0955C42.6099 48.0955 42.8781 48.1571 43.1256 48.0338C43.1359 47.9516 43.1462 47.8797 43.1462 47.7975C43.1462 36.915 43.1462 26.0428 43.1565 15.1603C43.1565 14.7287 42.9915 14.4409 42.7027 14.1532ZM31.1112 5.59311C33.9575 8.42934 36.711 11.1834 39.4955 13.9579C39.4439 14.1224 39.2583 14.0299 39.1242 14.0299C36.6698 14.0401 34.2153 14.0299 31.7609 14.0299C31.4206 14.0299 31.0906 14.1018 31.1009 13.5469C31.1215 10.9367 31.1112 8.3163 31.1112 5.59311ZM40.3411 46.0505C30.1831 46.03 20.025 46.03 9.86694 46.0505C9.14505 46.0505 9.03161 45.8347 9.04192 45.1873C9.06255 38.4667 9.05224 31.7563 9.05224 25.0357C9.05224 18.315 9.06255 11.6047 9.04192 4.88405C9.04192 4.27775 9.12443 4.0414 9.82569 4.05168C15.9927 4.0825 22.1494 4.0825 28.3165 4.05168C29.0383 4.05168 29.1724 4.2572 29.1621 4.91488C29.1208 7.07288 29.1208 9.24116 29.1621 11.3992C29.1724 11.9541 28.9971 12.0877 28.4608 12.0774C24.2532 12.0568 20.0559 12.0774 15.8483 12.0568C15.2915 12.0568 15.0233 12.1185 15.0543 12.7865C15.1161 14.0401 15.0646 14.0401 16.3537 14.0401C20.3447 14.0401 24.346 14.0504 28.3371 14.0299C28.9765 14.0299 29.193 14.1737 29.1621 14.8417C29.1105 16.0543 29.1518 16.0543 30.379 16.0543C33.6997 16.0543 37.0101 16.0748 40.3308 16.044C40.9496 16.0337 41.1765 16.157 41.1765 16.8353C41.1558 26.3099 41.1558 35.7949 41.1765 45.2695C41.1661 45.9169 40.9805 46.0505 40.3411 46.0505Z" />
                <path d="M28.6876 22.066C30.647 22.066 32.5962 22.0865 34.5556 22.0557C35.0506 22.0454 35.1537 22.1893 35.1537 22.662C35.1434 24.0801 35.164 24.0801 33.7202 24.0801C30.1211 24.0801 26.5219 24.0698 22.9331 24.0904C22.3143 24.0904 22.0668 23.9979 22.0978 23.3094C22.1597 22.066 22.1081 22.066 23.3869 22.066C25.14 22.066 26.9138 22.066 28.6876 22.066Z" />
                <path d="M28.6155 30.0608C26.6561 30.0608 24.6967 30.0402 22.7476 30.0711C22.2526 30.0813 22.0876 29.9683 22.0979 29.4442C22.1185 28.0467 22.0875 28.0467 23.4695 28.0467C27.1202 28.0467 30.7709 28.0569 34.4319 28.0364C35.0094 28.0364 35.1847 28.17 35.1641 28.7557C35.1229 30.0608 35.1538 30.0608 33.8647 30.0608C32.1115 30.0608 30.3584 30.0608 28.6155 30.0608Z" />
                <path d="M28.5434 36.062C26.6149 36.062 24.6865 36.0517 22.758 36.0722C22.2836 36.0825 22.0773 36.0003 22.0876 35.4557C22.1083 34.0684 22.0773 34.0684 23.4592 34.0684C27.11 34.0684 30.7607 34.0787 34.4011 34.0581C34.9373 34.0581 35.1745 34.1198 35.1539 34.7569C35.1023 36.062 35.1436 36.0722 33.8545 36.0722C32.0807 36.062 30.3172 36.062 28.5434 36.062Z" />
                <path d="M16.5909 22.0659C16.7249 22.0659 16.8487 22.0659 16.9827 22.0659C18.0759 22.0659 18.0243 22.0659 18.0965 23.1654C18.1378 23.9053 17.9521 24.2239 17.1787 24.0903C16.9518 24.0492 16.7146 24.08 16.4774 24.08C15.044 24.08 15.0749 24.08 15.0646 22.6516C15.0646 22.1686 15.1883 21.994 15.673 22.0556C15.9618 22.0967 16.2815 22.0659 16.5909 22.0659Z" />
                <path d="M16.5496 30.061C16.4155 30.061 16.2917 30.061 16.1577 30.061C15.0645 30.061 15.1161 30.061 15.0542 28.9614C15.013 28.2215 15.1986 27.9029 15.972 28.0365C16.1989 28.0776 16.4361 28.0468 16.6733 28.0468C18.0965 28.0468 18.0758 28.0468 18.0862 29.4752C18.0862 29.9479 17.9727 30.1329 17.488 30.0712C17.1786 30.0301 16.8589 30.061 16.5496 30.061Z" />
                <path d="M16.5599 34.0583C16.7661 34.0583 16.9827 34.0891 17.1786 34.048C17.9624 33.9041 18.1274 34.2535 18.0758 34.9728C18.014 36.0518 18.0552 36.0518 16.9517 36.0518C16.5392 36.0518 16.1164 36.021 15.7039 36.0621C15.2295 36.1032 15.0233 36.0005 15.0336 35.4455C15.0748 34.0583 15.0439 34.0583 16.5599 34.0583Z" />
            </DefaultIcon>
        </>
    );
};
