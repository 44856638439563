import { useMemo, useCallback } from "react";
import classNames from "classnames";
import { IconsHelper } from "views/components/icons-helper";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useStyles } from "./list-modulo-filho-styles";
import { useAcoesMenu } from "./acoes-menu/acoes-menu";
import { ListModuloFilhoProps } from "./list-modulo-filho-props";
import { Tooltip } from "@material-ui/core";
import React from "react";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";

export const ListModuloFilho = (props: ListModuloFilhoProps) => {
  const { ...listItemProps } = useMemo(() => {
    return {
      ...props,
    };
  }, [props]);

  const classes = useStyles();
  const { selectAction } = useAcoesMenu();
  const {pathname} = useLocation();
  const onClickItem = useCallback(() => {
    selectAction(listItemProps.codigoModulo, listItemProps.rota);
  }, [
    listItemProps.rota,
    listItemProps.codigoModulo,
    selectAction
  ]);

  const itemSelecionado = useMemo(() => {
    if(!isEmpty(pathname.split('/')[2])){
      return pathname.split('/')[1].includes(props.rota.split('/')[1])
    }

    return pathname.includes(props.rota)
  }, [pathname, props.rota])
  
  const component = React.useMemo(() => {
    return (<>
      <ListItem className={props.isFavorite ? classes.favoriteRoot : classes.root} button selected={itemSelecionado} onClick={onClickItem}>
        <Tooltip arrow title={listItemProps.nomeModulo} placement="right">
          <ListItemIcon>
            <IconsHelper
              codigoModulo={listItemProps.codigoModulo} class={props.isFavorite ? classes.favoriteIcon : classes.icon} tipo="GERAL"
            />
          </ListItemIcon>
        </Tooltip>
        {props.modeloAtual === "Completo" && (
          <ListItemText
            classes={{
              primary: classNames(
                classes.text,
                props.isFavorite &&
                classes.favoriteText
              ),
            }}
            primary={listItemProps.nomeModulo}
          />
        )}
      </ListItem>
    </>)
  }, [classes.favoriteIcon, classes.favoriteRoot, classes.favoriteText, classes.icon, classes.root, classes.text, itemSelecionado, listItemProps.codigoModulo, listItemProps.nomeModulo, onClickItem, props.isFavorite, props.modeloAtual]);

  return component;
};
