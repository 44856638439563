import { useCallback, FocusEvent, useState, useRef, useEffect } from "react";

import { useStyles } from "./autocomplete-saurus-styles";
import { TipoField } from "../../inputs/text-field-saurus/text-field-saurus-types";
import { mask } from "../../inputs/text-field-saurus/text-field-saurus-masks";
import { CircularLoading } from "../../../utils/circular-loading/circular-loading";
import React from "react";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { TextField, useTheme } from "@material-ui/core";
import { isEmpty } from "lodash";
import classNames from "classnames";
import { PesquisaIcon } from "views/components/icons/pesquisa-icon";
import { CadeadoSenhaIcon } from "views/components/icons";

export interface retornoAutoComplete {
  value: any;
  isString: boolean;
  isNewVal: boolean;
}
export interface AutocompleteSaurusProps {
  name?: string;
  value?: any;
  label?: string;
  placeholder?: string;
  error?: boolean;
  helperText?: string | undefined;
  textoNovoItem?: string;
  opcoes?: Array<any>;
  optionLabelKey?: string;
  optionValueKey?: string;
  secondaryLabelKey?: string;
  disabledValues?: Array<any>;
  disabledValueKey?: string;
  tipoTextField?: TipoField;
  loading?: boolean;
  permiteNovo?: boolean;
  noOptionsText?: string;
  inputRef?: React.Ref<any>;
  allowSubmit?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
  onPesquisa?: (termo: string) => any;
  onChange?: (value: retornoAutoComplete) => any;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onClick?: () => any;
  fullWidth?: boolean;
  focus?: boolean;
}

const filter = createFilterOptions<{
  nomeDisplay: string;
  inputValue: string | undefined;
}>();

export const AutocompleteSaurus = ({
  label,
  placeholder,
  error,
  helperText,
  onPesquisa,
  onChange,
  name,
  value,
  onBlur,
  opcoes,
  loading,
  optionLabelKey,
  secondaryLabelKey,
  optionValueKey,
  disabledValues,
  disabledValueKey,
  textoNovoItem,
  permiteNovo,
  tipoTextField,
  noOptionsText,
  inputRef,
  allowSubmit,
  disabled,
  fullWidth,
  size,
  focus,
  onClick
}: AutocompleteSaurusProps) => {
  const [inputValue, setInputValue] = useState("");
  const timer = useRef({} as NodeJS.Timeout);
  const [carregando, setCarregando] = useState(false);
  const [open, setOpen] = useState(false);
  const ultimoTermoPesquisado = useRef("");
  const classes = useStyles();
  const theme = useTheme();

  const showloading = carregando || loading;

  useEffect(() => {
    setCarregando(loading || false);
  }, [focus, loading]);

  useEffect(() => {
    if (onPesquisa) {
      clearTimeout(timer.current);
      if (open && (ultimoTermoPesquisado.current !== value || inputValue.length === 0)) {
        setCarregando(true);
        timer.current = setTimeout(() => {
          onPesquisa(inputValue);
        }, 1000);
        setCarregando(false);
      }
      return () => {
        clearTimeout(timer.current);
      };
    }
  }, [inputValue, onPesquisa, open, value]);

  const onChangeWrap = useCallback(
    (event: any, value: any) => {
      if (!onChange) {
        return;
      }

      let valor = value != null ? (value.inputValue ? value.inputValue : value) : "";
      let isString = typeof valor === "string";
      let isNewVal = false;

      if (value?.inputValue) {
        isNewVal = true;
      }

      onChange({ value: valor, isString: isString, isNewVal: isNewVal });
    },
    [onChange]
  );

  const onInputChange = useCallback(
    (event, value) => {
      let valor = mask(value as string, tipoTextField);
      ultimoTermoPesquisado.current = valor;
      setInputValue(valor);

      try {
        const ret = opcoes?.find((x) => x[optionValueKey || ""].toString() === valor);
        if (!isEmpty(ret)) {
          if (onChange) {
            onChange({ value: ret, isString: false, isNewVal: false });
            setOpen(false);
          }
        }
      } catch { }
    },
    [tipoTextField, opcoes, optionValueKey, onChange]
  );

  const getOptionLbl = useCallback(
    (valor) => {
      if (typeof valor === "string") {
        return valor;
      }

      if (valor.nomeDisplay) {
        return valor.nomeDisplay;
      }
      if (valor.inputValue) {
        return valor.inputValue;
      }
      if((secondaryLabelKey && valor[secondaryLabelKey]) && optionLabelKey && valor[optionLabelKey]){
        return `${valor[optionLabelKey]} - ${valor[secondaryLabelKey]}`
      }
      if (valor[optionLabelKey || ""]) return valor[optionLabelKey || ""];
      return "";
    },
    [optionLabelKey, secondaryLabelKey]
  );

  const onKeyDownWrapper = useCallback(
    (event: any) => {
      if (event.keyCode === 13) {
        if (!allowSubmit) {
          const form = event.target.form;
          const focusableElements =
            'a:not([disabled]), button:not([disabled]), input:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
          const index = Array.prototype.indexOf.call(
            form.querySelectorAll(focusableElements),
            event.target
          );
          if (index === form.querySelectorAll(focusableElements)) {
            form.querySelectorAll(focusableElements)[0].focus();
          } else {
            form.querySelectorAll(focusableElements)[index + 1].focus();
          }
        }
        if (!allowSubmit) {
          event.preventDefault();
        }
      }
    },
    [allowSubmit]
  );
  return (
    <Autocomplete
      disabled={disabled}
      size={size || 'medium'}
      classes={{
        noOptions: noOptionsText && !showloading ? classes.display : classes.displayNone,
        loading: classes.displayNone,
        endAdornment: classes.displayNone,
        listbox: classes.list,
        inputRoot: classNames(classes.input, permiteNovo ? classes.inputComNovo : undefined, disabled ? classes.disabled : undefined),
        paper: classes.paper,
      }}
      autoComplete={false}
      options={opcoes || []}
      getOptionSelected={(option: any, value: any) => {
        return option[optionValueKey || ""] === value;
      }}
      loading={showloading}
      loadingText=""
      filterOptions={(options) => {
        try {
          const valor = inputValue || "";
          const filtered = filter(options, { inputValue: valor, getOptionLabel: getOptionLbl });

          if (valor !== "" && filtered.length <= 0 && permiteNovo && !carregando) {
            filtered.push({
              nomeDisplay: `${textoNovoItem} "${valor}"`,
              inputValue: valor,
            });
          }

          return filtered;
        } catch {
          return options;
        }
      }}
      onOpen={() => {
        setOpen(true);
      }}
      fullWidth={fullWidth}
      onClose={() => {
        setOpen(false);
      }}
      onChange={onChangeWrap}
      onInputChange={onInputChange}
      getOptionLabel={getOptionLbl}
      inputValue={inputValue}
      value={value}
      noOptionsText={noOptionsText ? noOptionsText : ""}
      clearOnBlur
      selectOnFocus
      freeSolo={permiteNovo}
      renderInput={(params) => (
        <TextField
          inputRef={inputRef}
          {...params}
          name={name}
          disabled={disabled}
          variant="outlined"
          onBlur={onBlur}
          autoFocus={focus}
          label={label}
          placeholder={placeholder}
          helperText={helperText}
          error={error}
          onKeyDown={onKeyDownWrapper}
          fullWidth={fullWidth}
          inputProps={{
            ...params.inputProps,
            autoComplete: "off",
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {showloading ? <CircularLoading tipo="ONAUTOCOMPLETE" /> : null}
                {!disabled &&
                  <div className={classes.pesquisaIcon} style={{ padding: size ? '6px' : '10px' }}>
                    <PesquisaIcon tipo="BUTTON" onClick={onClick} style={{ cursor: 'pointer', color: theme.palette.text.primary, height: '24px', width: '24px' }} />
                  </div>
                }
                {disabled && (
                  <div className={classes.pesquisaIcon} style={{ padding: size ? '6px' : '10px', marginRight: theme.spacing(2) }}>
                    <CadeadoSenhaIcon tipo="INPUT" />
                  </div>
                )}
              </>
            ),
          }}
          autoComplete="new-password"
        />
      )}
    />
  );
};
