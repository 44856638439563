import { Card, Grid, Tooltip, Typography } from '@material-ui/core';
import { CardTerminalProps } from './card-terminal-props';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
//import { useThemeQueries } from '../../..';
import { useCallback, useMemo } from 'react';
import { InformacaoIcon } from 'views/components/icons';
import { EnumTerminalStatus } from 'model/enums/enum-status-terminal';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { useThemeQueries } from 'views/theme';
import { isEmpty } from 'lodash';
import { useStyles } from './card-terminal-styles'
import { toDateString } from 'utils/to-date';
import { EnumSituacaoTerminal } from 'model/enums/enum-situacao-terminal';



export const CardTerminal = ({
  model,
  onClick,
  isCliente
}: CardTerminalProps) => {
  const classes = useDefaultCardStyles();
  const terminalClasses = useStyles();
  const { theme, isMobile } = useThemeQueries();

  const isDisponivel = useMemo(() => (model.status === EnumSituacaoTerminal.Disponivel), [model.status])

  //const { isMobile } = useThemeQueries();
  const statusColor = useCallback(() => {
    switch (model.status) {
      case EnumTerminalStatus.Disponivel:
        return '#1F9CE4'
      case EnumTerminalStatus.Ativo:
        return '#10C822'
      case EnumTerminalStatus.Bloqueado:
        return '#FFBC00'
      case EnumTerminalStatus.Desativado:
        return '#F44336'
    }
  }, [model])

  return (
    <>
      <Card
        style={{ display: 'flex', cursor: 'pointer' }}
        onClick={() => onClick(model)}
        className={terminalClasses.card}
      >
        <div style={{ display: 'flex', alignItems: 'center', marginRight: 7 }}>
          <Tooltip title={EnumTerminalStatus[model.status]} placement='right'>
            <div style={{
              backgroundColor: statusColor(),
              width: '5px',
              borderRadius: '5px',
              height: '90%',
              marginLeft: 5
            }} />
          </Tooltip>
        </div>
        <Grid container className={`${classes.cardContent} ${terminalClasses.container}`} spacing={1}
          style={isDisponivel ? {
            justifyContent: 'flex-start'
          } : undefined}>
          <Grid item xs={12}>
            <Grid container style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 5
            }}>
              <Grid item xs={6} md={2}>
                <Typography variant='caption' color='textPrimary'>Tipo: </Typography>
                <Typography variant='caption'><b>{!isEmpty(model.tipo) ? model.tipo : 'Indisponível'}</b></Typography>
              </Grid>
              {!isDisponivel && <>
                <Grid item xs={6} md={2}>
                  <Typography variant='caption' color='textPrimary'>Versão: </Typography>
                  <Typography variant='caption'>{!isEmpty(model.versao) ? model.versao : '-'}</Typography>
                </Grid>
                <Grid item xs={6} md={3} className={terminalClasses.datas}>
                  <Typography variant='caption' color='textPrimary'>Primeiro acesso: </Typography>
                  <Typography variant='caption' style={{ fontWeight: '500' }}>{!isEmpty(model.primeiroAcesso) ? toDateString(model.primeiroAcesso + 'Z', 'DD/MM/yyyy HH:mm') : 'Indisponível'}</Typography>
                </Grid>
                <Grid item xs={12} md={3} className={terminalClasses.datas}>
                  <Typography variant='caption' color='textPrimary'>Último acesso: </Typography>
                  <Typography variant='caption' style={{ fontWeight: '500' }}>{!isEmpty(model.ultimoAcesso) ? toDateString(model.ultimoAcesso + 'Z', 'DD/MM/yyyy HH:mm') : 'Indisponível'}</Typography>
                </Grid>
              </>}
            </Grid>
          </Grid>
          <Grid xs={12}>
            <div className={classes.divider} />
          </Grid>
          <Grid item xs={isDisponivel ? 6 : 12} md={isDisponivel ? 6 : isCliente ? 3 : 4}>
            <Typography variant='caption' color='textPrimary'>Chave: </Typography>
            <Typography variant='body1'><b>{!isEmpty(model.chave) ? model.chave : model.status === EnumSituacaoTerminal.Desativado ? 'Vazio' : 'Disponível'}</b></Typography>
          </Grid>
          {isCliente && !isDisponivel ?
            <>
              <Grid item xs={12} md={6} lg={3}>
                <Typography variant='caption' color='textPrimary'>IP Público: </Typography>
                <Typography variant='body1'>{!isEmpty(model.ipPublico) ? model.ipPublico : 'Indisponível'}</Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Typography variant='caption' color='textPrimary'>Máquina: </Typography>
                <Typography variant='body1' style={{ fontWeight: '500' }}>{!isEmpty(model.maquina) ? model.maquina : 'Indisponível'}</Typography>
              </Grid>
            </>
            :
            (!isDisponivel && <>
              <Grid item xs={12} lg={3}>
                <Typography variant='caption' color='textPrimary'>Razão Social: </Typography>
                <Typography variant='body1' style={{ fontWeight: '500' }}>{!isEmpty(model.clienteNome) ? model.clienteNome : 'Indisponível'}</Typography>
              </Grid>
              <Grid item xs={6} lg={2}>
                <Typography variant='caption' color='textPrimary'>CPF/CNPJ: </Typography>
                <Typography variant='body1' style={{ fontWeight: '500' }}>{!isEmpty(model.clienteDoc) ? formatarCPFCNPJ(model.clienteDoc) : 'Indisponível'}</Typography>
              </Grid>

            </>
            )
          }

          <Grid item xs={6} lg={isDisponivel ? 6 : 3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <div>
              <Typography variant='caption' color='textPrimary'>Domínio: </Typography>
              <Typography variant='body1' style={{ fontWeight: '500' }}>{!isEmpty(model.dominio) ? model.dominio : 'Indisponível'}</Typography>
            </div>
          </Grid>
        </Grid>
        {(!isMobile && !isDisponivel) && <div className={terminalClasses.infoIcon}>
          <Tooltip title={
            <div style={{ display: 'flex', flexDirection: 'column', background: theme.palette.background.paper }}>
              <div>
                <Typography variant='caption' color='textPrimary'>Quantidade de acessos: </Typography>
                <Typography variant='caption' color='textPrimary'>{model.qAcessos || 'Indisponível'}</Typography>
              </div>
              <div>
                <Typography variant='caption' color='textPrimary'>Acesso: </Typography>
                <Typography variant='caption' color='textPrimary' style={{ fontWeight: '500' }}>{!isEmpty(model.ultimoAcesso) ? model.ultimoAcesso : 'Indisponível'}</Typography>
              </div>
              <div>
                <Typography variant='caption' color='textPrimary'>Sistema: </Typography>
                <Typography variant='caption' color='textPrimary' style={{ fontWeight: '500' }}>{!isEmpty(model.sistemaOperacional) ? model.sistemaOperacional : 'Indisponível'}</Typography>
              </div>
            </div>
          } placement='left' classes={terminalClasses}>
            <div>
              <InformacaoIcon tipo='BUTTON' />
            </div>
          </Tooltip>
        </div>}
      </Card>
    </>
  );
};
