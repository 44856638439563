import { Button, Grid, Slide } from "@material-ui/core";
import classNames from "classnames";
import { ContratoFormAlternativoModel, ContratoFormModel } from "model/app/forms/contrato/contrato-form-model";
import React, { useEffect, useRef, useState } from "react";
import { FormContratoCadastro } from "views/components/form/master/contrato/contrato-cadastro/form-contrato-cadastro";
import { DefaultFormRefs } from "views/components/form/utils";
import { SalvarNovoIcon, VoltarIcon } from "views/components/icons";
import { useModalStyles } from "views/components/modals/utils/modal-styles";

interface ContratoSegundaFaseProps {
    carregando: boolean;
    handleSubmit: (model: ContratoFormAlternativoModel) => void;
    fase: 1 | 2;
    contratoForm: ContratoFormModel;
    setFase: (fase: 1 | 2) => void;
    pessoaId: string;
    revendaId: string;
    setBottomArea: (node: React.ReactNode) => void;
    temVendedor: boolean;
}

export const ContratoSegundaFase = ({ carregando, fase, handleSubmit, contratoForm, setFase, pessoaId, revendaId, setBottomArea,temVendedor }: ContratoSegundaFaseProps) => {

    const classes = useModalStyles()
    const [erro, setErro] = useState<string | null>(null)
    const [disable, setDisable] = useState<Array<boolean>>([false, false])

    const cadFormRef =
        useRef<DefaultFormRefs<ContratoFormModel>>(null);

    useEffect(() => {
        const contratoModel = new ContratoFormAlternativoModel()
        cadFormRef.current?.fillForm({...contratoModel, ...contratoForm})
    }, [contratoForm])

    useEffect(() => {
        setBottomArea(
            <div className={classes.acoes}>
            <Grid item xs={12}>
                <Grid container spacing={2} justifyContent='flex-end'>
                    <Grid item md={2} xs={!erro ? 6 : 12}>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => setFase(1)}
                            fullWidth
                        >
                            <VoltarIcon tipo='BUTTON' />
                            Voltar
                        </Button>
                    </Grid>
                    {!erro && <Grid item xs={6} md={2}>
                        <Button
                            disabled={carregando || disable.includes(true)}
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => cadFormRef.current?.submitForm()}
                        >
                            <SalvarNovoIcon tipo='BUTTON_PRIMARY' />
                            Salvar
                        </Button>
                    </Grid>}
                </Grid>
            </Grid>
        </div>
        )
    }, [carregando, classes.acoes, disable, setBottomArea, setFase, erro])

    return (
        <Slide in={fase === 2} direction="left">
            <div style={{
                flex: 1,
                height: "100%",
                display: 'flex',
                flexDirection: 'column'
            }}>
                <div className={classes.content}>
                    <div
                        className={classNames(
                            carregando ? classes.contentFormsLoading : undefined,
                        )}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: '1 1 auto',
                            overflow: "hidden"
                        }}
                    >
                        <FormContratoCadastro
                            ref={cadFormRef}
                            pessoaId={pessoaId}
                            revendaId={revendaId}
                            showLoading={false}
                            loading={carregando}
                            onSubmit={handleSubmit}
                            erro={erro}
                            setErro={setErro}
                            disable={disable}
                            setDisable={setDisable}
                            sistemaId={contratoForm.sistemaId}
                            temVendedor={temVendedor}
                        />
                    </div>
                </div>
            </div>
        </Slide>
    )
}