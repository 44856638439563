import { useCallback, useMemo, useState } from "react";
import { PrivatePageHeader } from "views/components/headers";
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { FiltroIcon, MenuIcon, VoltarIcon } from "views/components/icons";
import { useMenuPrincipal } from "services/app";
import { useThemeQueries } from "views";
import { PesquisaNotasFiscaisRejeitadas } from "./components/pesquisa-notas-fiscais-rejeitadas/pesquisa-notas-fiscais-rejeitadas";
import { NotasFiscaisRejeitadasListSearchProps } from "../notas-fiscais-rejeitadas-list/notas-fiscais-rejeitadas-list-search-props";
import { SetaCimaIcon } from "views/components/icons/seta-cima-icon";
import { useHeaderStyles } from "views/components/headers/header-private-page/header-styles";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";

interface NotasFiscaisRejeitadasHeaderProps {
  setSearchProps: React.Dispatch<React.SetStateAction<NotasFiscaisRejeitadasListSearchProps>>;
  titulo: string;
}

export interface LocationProps {
  from: {
    pathname: string,
    page?: number;
  }
}

export const NotasFiscaisRejeitadasHeader = ({ setSearchProps, titulo }: NotasFiscaisRejeitadasHeaderProps) => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();

  const classes = useHeaderStyles();
  const {theme} = useThemeQueries();

  const { state } = useLocation<LocationProps>();
  const history = useHistory();


  const [openSearch, setOpenSearch] = useState<boolean>(false)

  const onPesquisar = useCallback((pesquisa: NotasFiscaisRejeitadasListSearchProps) => {
    setSearchProps({ ...pesquisa })
  }, [setSearchProps])

  const leftArea = useCallback(
    () =>
      isMobile ? (titulo === 'Notas Fiscais Rejeitadas' ?
      (
        <ButtonPrivateHeader
          icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
          tooltip="Menu"
          onClick={abrirMenu}
        ></ButtonPrivateHeader>
      ) : <ButtonPrivateHeader
        icon={<VoltarIcon tipo="PRIVATE_HEADER"></VoltarIcon>}
        tooltip="Voltar"
        onClick={() => {
          if (!state?.from?.pathname || isEmpty(state.from.pathname)) {
            history.push('/notas-fiscais-rejeitadas')
            return
          }
          if (state.from.page) {
            history.push('/notas-fiscais-rejeitadas?page=' + state.from.page)
            return
          }
          history.push('/notas-fiscais-rejeitadas')
        }}
      ></ButtonPrivateHeader>) : (titulo === 'Visualizar Nota Fiscal') ? (
        <ButtonPrivateHeader
          icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
          tooltip="Voltar"
          onClick={() => {
            if (!state?.from?.pathname || isEmpty(state.from.pathname)) {
              history.push('/notas-fiscais-rejeitadas')
              return
            }
            if(state.from.page){
              history.push('/notas-fiscais-rejeitadas?page=' + state.from.page)
              return
            }
            history.push('/notas-fiscais-rejeitadas')
          }}
        />
      ) : null,
    [isMobile, abrirMenu, titulo, state, history]
  );

  const rightArea = useCallback(
    () => isMobile ? (
      <ButtonPrivateHeader
        icon={
          openSearch ? (
            <SetaCimaIcon tipo="PRIVATE_HEADER"></SetaCimaIcon>
          ) : (
            <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>
          )
        }
        tooltip={!openSearch ? "Pesquisar" : "Fechar Pesquisa"}
        onClick={() => setOpenSearch(!openSearch)}
      ></ButtonPrivateHeader>
    ) : (
      <ButtonPrivateHeader
        className={classes.pesquisaBox}
        text={!openSearch ? 'Filtros' : 'Fechar Filtros'}
        icon={
          openSearch ? (
            <SetaCimaIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main}></SetaCimaIcon>
          ) : (
            <FiltroIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main}></FiltroIcon>
          )
        }
        tooltip={!openSearch ? "Pesquisar" : "Fechar Pesquisa"}
        onClick={() => setOpenSearch(!openSearch)}
      ></ButtonPrivateHeader>
    )
    ,
    [classes.pesquisaBox, isMobile, openSearch, theme.palette.primary.main]
  )

  const pesquisa = useMemo(() => openSearch && titulo === 'Notas Fiscais Rejeitadas' ? (
    <PesquisaNotasFiscaisRejeitadas
      isOpened={openSearch}
      onCloseSearch={() => setOpenSearch(false)}
      onPesquisar={onPesquisar}
    />
  ) : null, [onPesquisar, openSearch, titulo])

  return (
    <>
      <PrivatePageHeader
        title={titulo}
        leftArea={leftArea()}
        rightArea={titulo === 'Notas Fiscais Rejeitadas' ? rightArea() : undefined}
        bottomArea={pesquisa}
      />
    </>
  );
};