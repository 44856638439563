import { useStyles } from './pesquisa-avancada-clientes-styles';
import {
  Button,
  Grid,
  Fade,
  useTheme,
  Tooltip,
  Box,
} from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { ProcurarIcon } from 'views/components/icons';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { useThemeQueries } from 'views/theme';
import { PesquisaAvancadaHeaderProps } from './pesquisa-avancada-cliente-props';
import { SituacaoPessoaTodosMock } from 'data/mocks/pessoa-situacao-mock';
import { useSessaoAtual } from 'services/app';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';

export const PesquisaAvancadaCliente = (props: PesquisaAvancadaHeaderProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { isMobile } = useThemeQueries()
  const { tipoUsuario } = useSessaoAtual();

  const campoRevendedor = useMemo<boolean>(() => (
    tipoUsuario() === EnumTipoPessoas.SoftwareHouse ||
    tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouse ||
    tipoUsuario() === EnumTipoPessoas.GerenteComercial
  ), [tipoUsuario])

  //CHAMADA DO PESQUISAR
  const pesquisar = useCallback(
    (event: any) => {
      event.preventDefault();
      props.onPesquisar();
    },
    [props],
  );

  const changeValue = useCallback((ev: any) => {
    ev.preventDefault()

    props.setPesquisa(prev => ({
      ...prev,
      [ev.target.name]: ev.target.value
    }))
  }, [props])

  return (
    <Fade in={props.isOpened} mountOnEnter unmountOnExit>
      <form onSubmit={pesquisar}>
        <Grid container className={classes.root} spacing={2}>
          {isMobile && (
            <Grid item xs={12}>
              <TextFieldSaurus
                label='Pesquisa'
                name='termo'
                size='small'
                fullWidth
                onChange={changeValue}
                adornmentColor={theme.palette.text.disabled}
                value={props.pesquisa.termo}
              />
            </Grid>
          )}
          {campoRevendedor && (
            <Grid item xs={12} md={3}>
              <TextFieldSaurus
                label='Revendedor'
                name='revenda'
                size='small'
                fullWidth
                onChange={changeValue}
                adornmentColor={theme.palette.text.disabled}
                value={props.pesquisa.revenda}
              />
            </Grid>
          )}
          <Grid item xs={12} md={campoRevendedor ? 3 : 4}>
            <TextFieldSaurus
              label='Domínio'
              name='dominio'
              size='small'
              fullWidth
              onChange={changeValue}
              adornmentColor={theme.palette.text.disabled}
              value={props.pesquisa.dominio}
            />
          </Grid>
          <Grid item xs={12} md={campoRevendedor ? 3 : 4}>
            <TextFieldSaurus
              fullWidth
              size='small'
              name='documento'
              label='Documento'
              tipo='CNPJ_CPF'
              onChange={changeValue}
              adornmentColor={theme.palette.text.disabled}
              value={props.pesquisa.documento}
            />
          </Grid>
          <Grid item xs={12} md={campoRevendedor ? 3 : 4}>
            <Box className={classes.containerFinal}>
              <SelectSaurus
                fullWidth
                size='small'
                label='Situação'
                name='status'
                onChange={changeValue}
                conteudo={SituacaoPessoaTodosMock}
                value={props.pesquisa.status}
              />
              {!isMobile && <Tooltip title='Pesquisar'>
                <Button color="primary" variant="contained" type="submit">
                  <ProcurarIcon tipo="BUTTON_PRIMARY" style={{ margin: 0 }} />
                </Button>
              </Tooltip>}
            </Box>
          </Grid>
          {isMobile && <Grid item xs={12}>
            <Button color="primary" variant="contained" type="submit" fullWidth>
              <ProcurarIcon tipo="BUTTON_PRIMARY" />
              Pesquisar
            </Button>
          </Grid>}
        </Grid>
      </form>
    </Fade >
  );
};
