import { Grid } from "@material-ui/core"
import { useState } from "react"
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { useThemeQueries } from "views/theme"

import { FaturaModel } from "model/api/gestao/fatura/fatura-model"
import { CardHistoricoFatura } from "views/components/cards/card-historico-fatura/card-historico-fatura"

interface Props {
    model: FaturaModel
}

export const AccordionHistoricoFatura = ({ model }: Props) => {

    const { theme } = useThemeQueries()

    const [openAccordion, setOpenAccordion] = useState<boolean>(false)

    return (
        <AccordionSaurus
            labelPrimary="Histórico"
            tipoExpand="bold"
            noPaperRoot={false}
            heightDivider={2}
            showDivider={openAccordion}
            colorDivider={theme.palette.primary.main}
            colorExpand={theme.palette.primary.main}
            expanded={openAccordion}
            onChange={() => setOpenAccordion(prev => !prev)}
        >
            <Grid container spacing={1}>
                {model.historico.sort((a, b) => {
                    return new Date(b.data).getTime() - new Date(a.data).getTime()
                }).map((item) => (
                    <Grid item xs={12}>
                        <CardHistoricoFatura model={item} />
                    </Grid>
                ))}
            </Grid>
        </AccordionSaurus >
    )
}