import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"
export const PesquisaIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} onClick={props.onClick} style={props.style} fill={props.fill}>
                <path d="M21 3C11.6211 3 4 10.6211 4 20C4 29.3789 11.6211 37 21 37C24.7109 37 28.1406 35.8047 30.9375 33.7812L44.0938 46.9062L46.9062 44.0938L33.9062 31.0625C36.4609 28.0859 38 24.2227 38 20C38 10.6211 30.3789 3 21 3ZM21 5C29.2969 5 36 11.7031 36 20C36 28.2969 29.2969 35 21 35C12.7031 35 6 28.2969 6 20C6 11.7031 12.7031 5 21 5Z" />
            </DefaultIcon>
        </>
    );
};
