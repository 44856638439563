import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";

export const podeVerPerguntasInternas: EnumTipoPessoas[] = [
    EnumTipoPessoas.SoftwareHouse,
    EnumTipoPessoas.FuncionarioSoftwareHouse,
    EnumTipoPessoas.GerenteComercial,
    EnumTipoPessoas.FinanceiroSoftwareHouse,
    EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto,
    EnumTipoPessoas.Revendedor,

]

export const podeResponderPerguntasIndicacao: EnumTipoPessoas[] = [
    EnumTipoPessoas.SoftwareHouse,
    EnumTipoPessoas.FinanceiroSoftwareHouse,
    EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto,
    EnumTipoPessoas.Revendedor,
    EnumTipoPessoas.FuncionarioFinanceiro,
    EnumTipoPessoas.FuncionarioFinanceiroSemCusto,
    EnumTipoPessoas.Representante,
    EnumTipoPessoas.GerenteComercial
]


export const podeResponderPerguntasInternas: EnumTipoPessoas[] = [
    EnumTipoPessoas.SoftwareHouse,
    EnumTipoPessoas.FinanceiroSoftwareHouse,
    EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto,
]
