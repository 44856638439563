import { FaturaAGerarModel } from "model/api/gestao/fatura/faturas-a-gerar-model";
import { CardNaoEncontrado } from "views/components/cards";
import { CardProximaFatura } from "views/components/cards/card-proximas-faturas/card-proximas-fatura";
import { InformacaoIcon } from "views/components/icons";

export interface FaturasGridProps {
  list: Array<FaturaAGerarModel>;
  onClick: (open: boolean) => void;
}

export const FaturaAGerarListData = (props: FaturasGridProps) => {

  return (
    <>
      {props.list.length === 0 && (
        <CardNaoEncontrado
          mensagem="Nenhuma Fatura Encontrada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((fatura, index) => {
          return (
            <CardProximaFatura
              model={fatura}
              key={index}
            />
          );
        })}
    </>
  );
};
