import { Button, Grid } from "@material-ui/core";
import { useGetPessoaSocio, usePostAdicionarPessoaSocio, usePutAtualizarPessoaSocio } from "data/api/gestao/pessoa-socio";
import { PessoaSocioModel } from "model/api/gestao/pessoa/pessoa-socio-model";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { useCallback, useEffect, useState } from "react";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { guidEmpty } from "utils/guid-empty";
import { CircularLoading } from "views/components";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus";
import { DialogPessoaSocio } from "views/components/dialog/dialog-pessoa-socio/dialog-pessoa-socio";
import { NovoIcon } from "views/components/icons";
import { Paginacao } from "views/components/paginacao";
import { useThemeQueries } from "views/theme";
import { AccordionClienteSocioListData } from "./accordion-cliente-socio-list-data";

type Props = {
  id: string;
  revenda?: boolean;
  atualizar?: boolean;
  setAtualizar?: (valor: boolean) => void;
}

export const AccordionClienteSociosList = ({ id, atualizar, setAtualizar, revenda, ...props }: Props) => {
  const { theme } = useThemeQueries()
  const [openAccordion, setOpenAccordion] = useState<boolean>(false)
  const [modelInfo, setModelSodio] = useState<PessoaSocioModel>(new PessoaSocioModel())
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const { showToast } = useToastSaurus()

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<PessoaSocioModel>(),
  });

  const { getPessoaSocio, carregando: carregandoGet } = useGetPessoaSocio()
  const { postAdicionarPessoaSocio, carregando: carregandoPost } = usePostAdicionarPessoaSocio()
  const { putAtualizarPessoaSocio, carregando: carregandoPut } = usePutAtualizarPessoaSocio()

  const {tipoUsuario} = useSessaoAtual()

  const carregando = carregandoGet || carregandoPost || carregandoPut

  const onClickDialog = useCallback((model: PessoaSocioModel) => {
    setModelSodio(model)
    setOpenDialog(true)
  }, [])

  const fillResult = useCallback(
    (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<PessoaSocioModel>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    []
  );

  const search = useCallback(
    async (newPage: number) => {
      try {
        const res = await getPessoaSocio(id, newPage, `pageSize=5`);
        if (res.erro) throw res.erro;

        if (!res.resultado) {
          return;
        }

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          await search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list
        );

        return res;
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [getPessoaSocio, id, fillResult, showToast]
  );


  const saveNewSocio = useCallback(async (model: PessoaSocioModel) => {
    const res = await postAdicionarPessoaSocio(id, model)

    if (res.erro) throw res.erro

    showToast('success', 'Nova Informação Adicionada!')
  }, [id, postAdicionarPessoaSocio, showToast])

  const saveChangeSocio = useCallback(async (model: PessoaSocioModel) => {
    const res = await putAtualizarPessoaSocio(id, model)

    if (res.erro) throw res.erro

    showToast('success', 'Informação Atualizada!')
  }, [id, putAtualizarPessoaSocio, showToast])

  const handleSubmit = useCallback(async (model: PessoaSocioModel) => {
    try {
      model.pessoaId = id
      if (model.id === guidEmpty()) {
        await saveNewSocio(model)
        return true
      }

      await saveChangeSocio(model)

      return true
    }
    catch (err: any) {
      showToast('error', err.message)

      return false
    }
  }, [id, saveChangeSocio, saveNewSocio, showToast])

  useEffect(() => {
    if (openAccordion && !openDialog) {
      search(queryStatus.page)
    }
  }, [openAccordion, openDialog, queryStatus.page, search, showToast])

  useEffect(() => {
    if (openAccordion && atualizar) {
      search(queryStatus.page)
      setAtualizar && setAtualizar(false)
    } else {
      setAtualizar && setAtualizar(false)
    }
  }, [atualizar, openAccordion, queryStatus.page, search, setAtualizar])

  const onCardSelected = useCallback((model: PessoaSocioModel) => {
    onClickDialog(model)
  }, [onClickDialog])

  const onClickAdicionar = useCallback(() => {
    onClickDialog(new PessoaSocioModel())
  }, [onClickDialog])

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        await search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  return (
    <>
      <AccordionSaurus
        labelPrimary="Sócios"
        tipoExpand="bold"
        noPaperRoot={false}
        heightDivider={2}
        showDivider={openAccordion}
        colorDivider={theme.palette.primary.main}
        colorExpand={theme.palette.primary.main}
        expanded={openAccordion}
        onChange={() => setOpenAccordion(prev => !prev)}
      >
        <Grid container spacing={1} style={{
          position: 'relative'
        }}>
          {carregando && <CircularLoading tipo="FULLSIZED" />}
          <Grid item xs={12}>
            <Paginacao
              pageChanged={pageChanged}
              totalPages={queryStatus.totalPages}
              totalRegisters={queryStatus.totalResults}
              currentPage={queryStatus.page}
            />
          </Grid>
          <Grid item xs={12}>
            <AccordionClienteSocioListData
              list={queryStatus.list}
              carregando={carregando}
              onCardSelected={onCardSelected}
              onCardChecked={() => { }}
              selectedList={[]}
            />
          </Grid>
          {tipoUsuario() !== EnumTipoPessoas.Funcionario && <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={4}>
                <Button variant="contained" color='primary' fullWidth
                onClick={onClickAdicionar}>
                  <NovoIcon tipo='BUTTON_PRIMARY'/>
                  NOVO SÓCIO
                </Button>
              </Grid>
            </Grid>
          </Grid>}
        </Grid>
      </AccordionSaurus>
      <DialogPessoaSocio
        openned={openDialog}
        model={modelInfo}
        carregando={carregando}
        enviarModel={async model => await handleSubmit(model)}
        closeModal={() => setOpenDialog(false)}
        revenda={false}
      />
    </>
  );
};
