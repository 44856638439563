import { Button, Grid } from "@material-ui/core";
import { useGetTerminal } from "data/api/gestao/terminal/get-terminais";
import { SituacaoTerminalMockTodos } from "data/mocks/situacao-terminal-mock";
import { isEmpty, isNumber } from "lodash";
import { TerminalModel } from "model/api/gestao/terminal/terminal-model";
import { EnumSituacaoTerminal } from "model/enums/enum-situacao-terminal";
import { useCallback, useEffect, useState } from "react";
import { useToastSaurus } from "services/app";
import { CircularLoading } from "views/components";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus";
import { DialogTerminal } from "views/components/dialog/dialog-terminal/dialog-terminal";
import { ProcurarIcon } from "views/components/icons";
import { Paginacao } from "views/components/paginacao";
import { useThemeQueries } from "views/theme";
import { AccordionClienteTerminaisListData } from "./accordion-cliente-list-data";
import { useStyles } from './accordion-cliente-terminais-list-styles'

type Props = {
  id: string;
}

export type FiltroTerminalProps = {
  query: string;
  chave: string;
  sistemaOperacional: string;
  situacaoTerminal: EnumSituacaoTerminal[];
  tipo: string;
  atualizarPesquisa: boolean;
}

export const AccordionClienteTerminaisList = ({ id }: Props) => {
  const { theme, isMobile } = useThemeQueries()
  const [openAccordion, setOpenAccordion] = useState<boolean>(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [selectedModel, setSelectedModel] = useState<TerminalModel>(new TerminalModel())

  const { showToast } = useToastSaurus()
  const classes = useStyles()

  const [filtro, setFiltro] = useState<FiltroTerminalProps>({
    query: '',
    chave: '',
    sistemaOperacional: '',
    situacaoTerminal: [EnumSituacaoTerminal.Ativo, EnumSituacaoTerminal.Disponivel, EnumSituacaoTerminal.Bloqueado],
    tipo: '',
    atualizarPesquisa: false
  })

  const [valoresFiltro, setValoresFiltro] = useState<FiltroTerminalProps>({
    query: '',
    chave: '',
    sistemaOperacional: '',
    situacaoTerminal: [EnumSituacaoTerminal.Ativo, EnumSituacaoTerminal.Disponivel, EnumSituacaoTerminal.Bloqueado],
    tipo: '',
    atualizarPesquisa: false
  })

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<TerminalModel>(),
  });

  const { getTerminal, carregando } = useGetTerminal()

  const fillResult = useCallback(
    (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<TerminalModel>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    []
  );

  const search = useCallback(
    async (newPage: number) => {
      try {
        const query = `&PageSize=5&ClienteId=${id}${!isEmpty(filtro.chave)
          ? "&Chave=" + filtro.chave
          : ''
          }${!isEmpty(filtro.sistemaOperacional)
            ? "&SistemaOperacional=" + filtro.sistemaOperacional
            : ''
          }${(!isEmpty(filtro.tipo)) ? "&TipoTerminal=" + filtro.tipo :
            ''
          }${!isEmpty(filtro.query) ? "&query=" + filtro.query : ''}${filtro.situacaoTerminal.includes(-1) ? '' : filtro.situacaoTerminal.reduce((prev, curr) => `&SituacaoTerminal=${curr}${prev}`, '')}`

        const res = await getTerminal(newPage, query);
        if (res.erro) throw res.erro;

        if (!res.resultado) {
          return [];
        }

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          await search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list
        );

        return res;
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [id, filtro.chave, filtro.sistemaOperacional, filtro.tipo, filtro.query, filtro.situacaoTerminal, getTerminal, fillResult, showToast]
  );

  useEffect(() => {
    if (openAccordion && !openDialog) {
      search(queryStatus.page)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAccordion, openDialog, queryStatus.page, filtro])

  const onCardSelected = useCallback((model: TerminalModel) => {
    setSelectedModel(model)
    setOpenDialog(true)
  }, [])

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        await search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  const handleFiltro = useCallback((event: any) => {
    event.preventDefault()
    setFiltro({...valoresFiltro, atualizarPesquisa: !filtro.atualizarPesquisa})
  }, [filtro.atualizarPesquisa, valoresFiltro])

  const changeValue = useCallback((ev: any) => {
    if (ev.target.name === 'situacaoTerminal') {
      if (isNumber(ev.target.value) && ev.target.value === -2) {
        setValoresFiltro(prev => ({
          ...prev,
          situacaoTerminal: [EnumSituacaoTerminal.Ativo, EnumSituacaoTerminal.Disponivel, EnumSituacaoTerminal.Bloqueado],
        }))
        return
      }

      setValoresFiltro(prev => ({
        ...prev,
        situacaoTerminal: [ev.target.value]
      }))
      return
    }
    setValoresFiltro(prev => ({
      ...prev,
      [ev.target.name]: ev.target.value
    }))
  }, [])

  return (
    <>
      <AccordionSaurus
        labelPrimary="Terminais"
        tipoExpand="bold"
        noPaperRoot={false}
        heightDivider={2}
        showDivider={openAccordion}
        colorDivider={theme.palette.primary.main}
        colorExpand={theme.palette.primary.main}
        expanded={openAccordion}
        onChange={() => setOpenAccordion(prev => !prev)}
      >
        <Grid container spacing={1} style={{
          position: 'relative'
        }}>
          {carregando && <CircularLoading tipo="FULLSIZED" />}
          <form onSubmit={handleFiltro} style={{
            width: '100%'
          }}>
            <Grid container spacing={2} className={classes.filtroContainer}>
              <Grid item xs={12} md={12}>
                <TextFieldSaurus
                  label="Chave"
                  tipo="TEXTO"
                  name="chave"
                  size="small"
                  fullWidth
                  onChange={changeValue}
                  value={valoresFiltro.chave}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <TextFieldSaurus
                    label="Sistema Operacional"
                    tipo="TEXTO"
                    name="sistemaOperacional"
                    size="small"
                    fullWidth
                    onChange={changeValue}
                    value={valoresFiltro.sistemaOperacional}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <TextFieldSaurus
                    label="Domínio"
                    tipo="TEXTO"
                    name="query"
                    size="small"
                    fullWidth
                    onChange={changeValue}
                    value={valoresFiltro.query}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectSaurus
                  conteudo={SituacaoTerminalMockTodos}
                  label="Situação"
                  name="situacaoTerminal"
                  size="small"
                  fullWidth
                  onChange={changeValue}
                  value={valoresFiltro.situacaoTerminal.length > 1 ? -2 : valoresFiltro.situacaoTerminal[0]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <TextFieldSaurus
                    label="Tipo"
                    tipo="TEXTO"
                    name="tipo"
                    size="small"
                    fullWidth
                    onChange={changeValue}
                    value={valoresFiltro.tipo}
                  />
                  {!isMobile && <Button
                    variant="contained"
                    type='submit'
                    color="primary"
                    className={classes.btnPesquisa}
                  >
                    <ProcurarIcon tipo="BUTTON_PRIMARY" />
                  </Button>}
                </div>

              </Grid>
              {isMobile && <Grid item xs={12}>
                <Button
                  variant="contained"
                  type='submit'
                  color="primary"
                  fullWidth
                >
                  <ProcurarIcon tipo="BUTTON_PRIMARY" />
                  Pesquisar
                </Button>
              </Grid>}
            </Grid>
          </form>
          <Grid item xs={12}>
            <Paginacao
              pageChanged={pageChanged}
              totalPages={queryStatus.totalPages}
              totalRegisters={queryStatus.totalResults}
              currentPage={queryStatus.page}
            />
          </Grid>
          <Grid item xs={12} style={{display: 'flex', flexDirection: 'column', gap: 10}}>
            <AccordionClienteTerminaisListData
              carregando={carregando}
              list={queryStatus.list}
              onCardChecked={onCardSelected}
            />
          </Grid>
        </Grid>
      </AccordionSaurus>
      {(openAccordion && openDialog) && <DialogTerminal
        carregando={carregando}
        closeDialog={() => setOpenDialog(false)}
        model={selectedModel}
        openned={openDialog}
      />}
    </>
  );
};
