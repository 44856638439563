
import { useMemo } from 'react';
import * as Yup from 'yup';


export const useFormParametroValidation = () => {


  const FormParametroValidationYup = useMemo(() => {
    return Yup.object().shape({
      nome: Yup.string().required('O Nome é obrigatório'),
      valor: Yup.string().notRequired().nullable(true)
    });
  }, [])

  return {
    FormParametroValidationYup,
  }
}
