
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormTabelaPrecoValidation = () => {


  const FormTabelaPrecoValidationYup = useMemo(() => {
    return Yup.object().shape({
      nome: Yup.string().required('Nome é obrigatório').transform(nome => nome.length > 100 ? null : nome).typeError('Limite de caracteres excedido.'),
      sistemas: Yup.array()
        .of(
          Yup.object().shape({
            modulos: Yup.array()
              .of(
                Yup.object().shape({
                  valor: Yup.string().nullable(),
                  valorCusto: Yup.string().nullable(),
                  percAdicional: Yup.string().typeError('').nullable(),
                  percAdicionalCusto: Yup.number().nullable()
                })
              )
          })
        )
    });
  }, [])

  return {
    FormTabelaPrecoValidationYup,
  }
}

