import { EnumTipoComunicacao } from "model/enums";
import { EnumInstituicao } from "model/enums/enum-instituicao";

export class CampoCredencialModel {
  constructor(
    public instituicao: EnumInstituicao = EnumInstituicao.Safra,
    public tipoComunicacao: EnumTipoComunicacao = EnumTipoComunicacao.Boleto,
    public campos: JSX.Element = <></>
  ) {

  }
}