import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { EnviarEmail } from 'model/api/gestao/nota-fiscal/nota-fiscal-enviar-email-danfe';

export function usePostEnviarNotaFiscalDANFE() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postEnviarNotaFiscalDANFE = useCallback(
        ( id: string, modelEmail: EnviarEmail ) =>

            invocarApi({
                url: `/${VariaveisAmbiente.apiVersion}/fatura/${id}/nota-fiscal/DANFE`,
                method: 'POST',
                data: modelEmail,
                enviarTokenUsuario: true
            }),

        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postEnviarNotaFiscalDANFE,
    };
}
