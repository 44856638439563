
import { useMemo } from 'react';
import { useValidationYupDocumento, useValidationYupFone } from 'views/components/form-validations';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

export const useFormPessoaCadastroValidation = (revenda: boolean = false) => {

  const { documentoYup } = useValidationYupDocumento(false)
  const telefoneYup = useValidationYupFone()

  const FormPessoaCadastroValidationYup = useMemo(() => {
    return Yup.object().shape<AnyObject>({
      nomeComercial: Yup.string().required('O nome comercial é obrigatório.'),
      cpfCnpj: documentoYup(),
      revendaId: revenda ? Yup.string().nullable().notRequired() : Yup.string().required('Deve escolher um revendedor.').typeError('Deve escolher um revendedor'),
      tipoCobrancaRevenda: revenda ? Yup.string().required('Tipo de Cobrança é obrigatório.').typeError('Tipo de Cobrança é obrigatório.') : Yup.string().nullable(true).notRequired(),
      telefoneSuporte: revenda ? telefoneYup().required('Telefone é obrigatório.').typeError('Telefone é obrigatório') : Yup.string().nullable(true).notRequired(),
      gerenteId: revenda ? telefoneYup().required('Gerente Comercial é obrigatório.').typeError('Gerente Comercial é obrigatório') : Yup.string().nullable(true).notRequired(),
      vendedorId: Yup.string().nullable(true).notRequired(),
    });
  }, [documentoYup, revenda, telefoneYup])

  return {
    FormPessoaCadastroValidationYup,
  }
}

