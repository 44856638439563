import { Button, Divider, Grid, makeStyles } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useToastSaurus } from 'services/app';
import { DefaultFormRefs } from 'views/components/form/utils';
import { LixoIcon, SalvarEditarIcon, VoltarIcon } from 'views/components/icons';
import { usePutAtualizarParametroSistema } from 'data/api/gestao/parametro-sistema';
import { ParametroSistemaModel } from 'model/api/gestao/parametro-sistema/parametro-sistema-model';
import { FormParametro } from 'views/components/form/master/sistemas/parametro/form-parametro';
import { useThemeQueries } from 'views/theme';
import { useDeleteParametroSistema } from 'data/api/gestao/parametro-sistema/delete-parametro-sistema';

const useStyles = makeStyles((theme) => ({
    buttonRemover: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
        '& svg': {
            margin: 5,
            fill: theme.palette.error.main
        }
    },
}));

interface DialogParametroProps {
    openned: boolean;
    closeDialog: () => void;
    sistemaId: string;
    model: ParametroSistemaModel
}

export const DialogParametro = ({
    openned,
    closeDialog,
    sistemaId,
    model
}: DialogParametroProps) => {
    const { showToast } = useToastSaurus()

    const classes = useStyles()
    const {isMobile} = useThemeQueries()

    const { putAtualizarParametroSistema, carregando: carregandoPut } = usePutAtualizarParametroSistema()
    const { deleteParametroSistema, carregando: carregandoDelete } = useDeleteParametroSistema()

    const carregando = carregandoPut || carregandoDelete

    const [modelForm, setModelForm] = useState<ParametroSistemaModel>(new ParametroSistemaModel())

    const refForm = useRef<DefaultFormRefs<ParametroSistemaModel>>(null)

    useEffect(() => {
        setModelForm(model)
    }, [model])

    useEffect(() => {
        refForm.current?.fillForm(
            modelForm
        );
    }, [modelForm]);

    const saveChange = useCallback(async (model: ParametroSistemaModel) => {
        const res = await putAtualizarParametroSistema(sistemaId, model)

        if (res.erro) throw res.erro

        showToast('success', 'Parâmetro atualizado!')
    }, [putAtualizarParametroSistema, showToast, sistemaId])

    const handleSubmit = useCallback(async (model: ParametroSistemaModel) => {
        try {
            await saveChange(model)
            closeDialog()
        }
        catch (err: any) {
            showToast('error', err.message)
        }
    }, [closeDialog, saveChange, showToast])

    const clickRemove = useCallback(async () => {
        try {
          const res = await deleteParametroSistema(sistemaId, model.id)
    
          if (res.erro) throw res.erro
    
          closeDialog()
        
          showToast('success', 'Parâmetro excluído.')
        } catch (err: any) {
          showToast('error', err.message)
        }
      }, [closeDialog, deleteParametroSistema, model.id, showToast, sistemaId])


    return (
        <DialogSaurus
            aberto={openned}
            titulo="Editar Parâmetro"
            tamanho='sm'
        >
            <Grid container spacing={2} style={{ marginBottom: '5px' }}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormParametro
                                ref={refForm}
                                loading={carregando}
                                showLoading={carregando}
                                onSubmit={handleSubmit}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider light />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent='flex-end'>
                        <Grid item xs={12} md={6}>
                            <Button variant='outlined' className={classes.buttonRemover} style={{ height: 36 }}
                                onClick={clickRemove} fullWidth={isMobile}>
                                <LixoIcon tipo='BUTTON_PRIMARY' />
                                Excluir
                            </Button>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Button
                                variant='outlined'
                                color='primary'
                                onClick={closeDialog}
                                fullWidth
                            >
                                <VoltarIcon tipo='BUTTON' />
                                Voltar
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button
                                disabled={carregando}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => refForm.current?.submitForm()}
                            >
                                <SalvarEditarIcon tipo='BUTTON_PRIMARY' />
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DialogSaurus>
    );
};
