export enum EnumSituacao {
    Todos = -1,
    Aberta = 0,
    Fechada = 1,
    EmPagamento = 2,
    Paga = 3,
    Cancelada = 4,
    Atraso = 5,
    Bonificada = 6,
    BonificadoRevenda = 7,
}