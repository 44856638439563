import { VariaveisAmbiente } from 'config';
import { SistemaModel } from 'model/api/gestao/sistemas/sistema-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePostAdicionarSistema() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postAdicionarSistema = useCallback(
        (sistema: SistemaModel) =>
            invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/Sistema`,
                data: sistema,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postAdicionarSistema,
    };
}
