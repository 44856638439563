import { Button, Grid } from '@material-ui/core';
import { useCallback, useRef } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import React from 'react';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { FaturaCompetenciaModel } from 'model/api/gestao/fatura/fatura-competencia-model';

export const FormEditarCompetencia = React.forwardRef<
  DefaultFormRefs<FaturaCompetenciaModel>,
  DefaultFormProps<FaturaCompetenciaModel>
>((props: DefaultFormProps<FaturaCompetenciaModel>, refs) => {
  const txtEmail = useRef<HTMLInputElement>();
  const utilClasses = makeUtilClasses();
  const { isMobile } = useThemeQueries();

  const {
    control,
    formState: { errors, touchedFields },
    getValues,
    reset,
    handleSubmit
  } = useForm<FaturaCompetenciaModel>({
    criteriaMode: 'all',
    mode: 'onBlur' && 'onChange',
    defaultValues:{competenciaAnoMes: props.model?.competenciaAnoMes, dataVencimento: props.model?.dataVencimento.split('T')[0]}
  });

  const onSubmit = useCallback((form: FaturaCompetenciaModel) => {
    form.dataVencimento = form.dataVencimento + 'T00:00:00'
    props.onSubmit(form);
  }, [props]);

  React.useImperativeHandle(refs, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      if (!isMobile) txtEmail?.current?.focus();
    },
    fillForm: (model: FaturaCompetenciaModel) => {
      model.dataVencimento = model.dataVencimento.split('T')[0]
      reset({ ...model });
      if (!isMobile) txtEmail?.current?.focus();
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="competenciaAnoMes"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="NUMERO"
                    fullWidth={true}
                    allowSubmit={false}
                    autoComplete={'off'}
                    label="Competêcia Ano/Mês"
                    error={Boolean(
                      errors.competenciaAnoMes && errors.competenciaAnoMes.message,
                    )}
                    helperText={
                      touchedFields.competenciaAnoMes || errors.competenciaAnoMes
                        ? errors.competenciaAnoMes?.message
                        : undefined
                    }
                    {...field}
                    value={getValues('competenciaAnoMes')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="dataVencimento"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="DATA"
                    fullWidth={true}
                    allowSubmit={false}
                    autoComplete={'off'}
                    label="Data do Vencimento"
                    error={Boolean(
                      errors.dataVencimento && errors.dataVencimento.message,
                    )}
                    helperText={
                      touchedFields.dataVencimento || errors.dataVencimento
                        ? errors.dataVencimento?.message
                        : undefined
                    }
                    {...field}
                    value={getValues('dataVencimento')}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
