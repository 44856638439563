import { VariaveisAmbiente } from 'config';
import { PessoaSistemaCreateModel } from 'model/api/gestao/pessoa/pessoa-sistema-create-model';
import { PessoaSistemaModel } from 'model/api/gestao/pessoa/pessoa-sistema-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePostAdicionarPessoaSistema() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<PessoaSistemaModel>();

    const postAdicionarPessoaSistema = useCallback(
        (pessoaId: string, sistema: PessoaSistemaCreateModel) =>
            invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/pessoa/${pessoaId}/sistema`,
                data: sistema,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postAdicionarPessoaSistema,
    };
}
