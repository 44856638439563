import { EnumTipoAvaliacaoDesempenho } from "model/enums/enum-tipo-avaliacao-desempenho";

export class RevendaDesempenhoModel {
    constructor(
        public avaliarDesempenhoRevenda: EnumTipoAvaliacaoDesempenho = EnumTipoAvaliacaoDesempenho.NaoAvalia,
        public cnpjAdicionados: number = 0,
        public cnpjDesativados: number = 0,
        public cnpjSaldo: number = 0,
        public curva: string = "",
        public gerente: string = "",
        public gerenteId: string = "",
        public pontosMedia: number = 0,
        public mesesAtivos: number = 0,
        public percentualOverTabela: number = 0,
        public pontosAcumulado: number = 0,
        public primeiroPagamento: number = 0,
        public revenda: string = "",
        public revendaId: string = "",
        public terminaisAdicionados: number = 0,
        public terminaisDesativados: number = 0,
        public terminaisSaldo: number = 0,
        public totalContrato: number = 0,
        public totalPago: number = 0,
        public desconto: number = 0,
        public descontoAtual: number = 0,
        public faturaCrescimento: number = 0,
        public faturaInicial: number = 0,
        public faturaUltima: number = 0,
        public dataPrimeiroPagamento: number = 0
      ) {}
}