import { useCallback } from "react";
import { isEmpty } from 'lodash';
import { GestaoStorageKeys, useGestaoStorage } from "./gestao-storage";
import { TokenGestaoModel } from "../../../model/api/gestao/master";
import { decodeToken } from "react-jwt";

export function useGestaoToken() {
    const { setRegistro, delRegistro, getRegistro } = useGestaoStorage();
    const tokenFromSession: boolean = false;

    const isTokenExpired = useCallback((token: TokenGestaoModel | undefined): boolean => {
        if (!token?.exp) {
            return true;
        }
        return token?.exp * 1000 <= new Date().getTime();
    }, []);

    const convertToken = useCallback((token?: string): TokenGestaoModel | undefined => {
        let gtoken: TokenGestaoModel | undefined;
        try {
            gtoken = decodeToken(token!) as TokenGestaoModel;
        } catch (e) {
            gtoken = undefined;
        }
        return gtoken;
    }, []);

    const getTokenFromStorage = useCallback((): TokenGestaoModel | undefined => {
        const token = getRegistro(GestaoStorageKeys.Token, tokenFromSession);
        if (isEmpty(token))
            return undefined;

        return (convertToken(token));
    }, [getRegistro, convertToken, tokenFromSession]);

    const isTokenValid = useCallback((token: TokenGestaoModel | undefined): boolean => {
        return !isEmpty(token) && !isTokenExpired(token);
    }, [isTokenExpired]);

    const persistToken = useCallback((token: string | undefined): void => {
        if (isEmpty(token)) {
            return delRegistro(GestaoStorageKeys.Token, tokenFromSession);
        } else {
            return setRegistro(GestaoStorageKeys.Token, token || "", tokenFromSession);
        }
    }, [delRegistro, setRegistro, tokenFromSession]);

    const getEmpresaAtual = useCallback((): string => {
        const ret = getRegistro(GestaoStorageKeys.EmpresaAtual, tokenFromSession);
        if (isEmpty(ret))
            return '';

        return ret;
    }, [getRegistro, tokenFromSession])

    const persistEmpresaSelecionada = useCallback((empresaId: string | undefined) => {
        if (empresaId === undefined)
            delRegistro(GestaoStorageKeys.EmpresaAtual, tokenFromSession)
        else
            setRegistro(GestaoStorageKeys.EmpresaAtual, empresaId.toString(), tokenFromSession);
    }, [setRegistro, delRegistro, tokenFromSession]);

    const persistTermosDeUso = useCallback((valid: boolean | undefined, statusApi: number | undefined) => {
        if (valid === undefined)
            delRegistro(GestaoStorageKeys.TermosDeUso, tokenFromSession)
        else {
            const termo = {
                termoAceito: valid,
                statusApi
            }
            setRegistro(GestaoStorageKeys.TermosDeUso, termo, tokenFromSession);
        }
    }, [setRegistro, delRegistro, tokenFromSession]);

    const getTermosDeUso = useCallback((): boolean => {
        const ret = getRegistro(GestaoStorageKeys.TermosDeUso, tokenFromSession);
        if (isEmpty(ret)) {
            return true;
        } if (ret.termoAceito === false && ret.statusApi === 400) {
            return false
        } else {
            return true;
        }
    }, [getRegistro, tokenFromSession])

    return {
        isTokenExpired,
        isTokenValid,
        persistToken,
        getTokenFromStorage,
        convertToken,
        getEmpresaAtual,
        persistEmpresaSelecionada,
        getTermosDeUso,
        persistTermosDeUso
    };
}