import { useCallback } from "react";
import { PrivatePageHeader } from "views/components/headers";
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { useMenuPrincipal } from "services/app";
import { useThemeQueries } from "views";
import { MenuIcon } from "views/components/icons";

export const PerguntasApuracaoHeader = () => {
    const { abrirMenu } = useMenuPrincipal();
    const { isMobile } = useThemeQueries();

    const leftArea = useCallback(
        () =>
            isMobile ? (<ButtonPrivateHeader
                icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
                tooltip="Menu"
                onClick={abrirMenu}
            ></ButtonPrivateHeader>) : null,
        [isMobile, abrirMenu]
    );

    return (
        <>
            <PrivatePageHeader
                title='Cliente Ideal'
                leftArea={leftArea()}
            />
        </>
    );
};