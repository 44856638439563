import { SistemasModel } from "model/api/gestao/sistemas/sistemas-model"
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { CardIndicacoesSistemas } from "views/components/cards/card-indicacoes-sistemas/card-indicacoes-sistemas";
import { CardNaoEncontradoEditar } from "views/components/cards/card-naoencontrado-editar";
import { InformacaoIcon } from "views/components/icons"

interface DialogIndicacaoSistemasDataProps {
    list: Array<SistemasModel>;
    selectedId: string;
    carregando: boolean;
    onClick: (model: SistemasModel) => any;
}

export const DialogIndicacaoSistemasData = (props: DialogIndicacaoSistemasDataProps) => {

    const history = useHistory();

    const onClickRedict = useCallback(() => {
        history.push('/sistemas/')
    }, [history])

    return (
        <>
            {props.list.length === 0 && !props.carregando && (
                <CardNaoEncontradoEditar
                    mensagem="Nenhum Sistema Encontrado."
                    icon={<InformacaoIcon tipo="GERAL" />}
                    model={props.list}
                    onClick={onClickRedict}
                />
            )}

            {props.list.length > 0 &&
                props.list.map((model, index) => {
                    return (
                        <CardIndicacoesSistemas
                            onClick={props.onClick}
                            selected={
                                model.id === props.selectedId}
                            model={model}
                            sistemaId={model.id}
                            index={index}
                        />
                    );
                })}
        </>
    );
}