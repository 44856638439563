import { guidEmpty } from 'utils/guid-empty';

export class PessoaFormaPagamentoFormModel {
  constructor(
    public id: string = guidEmpty(),
    public pessoaId: string = guidEmpty(),
    public formaPagamentoId: string = guidEmpty(),
    public padrao: boolean = false,
  ) {}
}
