import { Grid, Typography } from '@material-ui/core';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { EditarIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import { useStyles } from './card-pessoa-comissao-styles'
import { CardPessoaComissaoProps } from './card-pessoa-comissao-props';

export const CardPessoaComissao = ({
    model,
    onClick,
    onCheck,
}: CardPessoaComissaoProps) => {
    const classes = useDefaultCardStyles();
    const bgClasses = useStyles();
    const { md } = useThemeQueries()

    return (
        <>
            <DefaultCard
                onClick={() => {
                    onCheck(model.id);
                }}
                className={bgClasses.cardBackground}
            >
                <Grid container spacing={md ? 1 : 2} className={`${classes.cardContent} ${bgClasses.container}`}>
                    <Grid item xs={12} md={3}>
                        <Typography color="textPrimary" variant="caption">
                            Sistema
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                        >
                            <b>{model.sistemaNome}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography color="textPrimary" variant="caption">
                            Comissionado
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                        >
                            {model.comissionadoNome}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography color="textPrimary" variant="caption">
                            Valor
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                        >
                            {model.valorComissao.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography color="textPrimary" variant="caption">
                            Tipo de Cálculo
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                        >
                            {model.isPercentual ? 'Porcentagem %' : 'Valor Fixo'}
                        </Typography>
                    </Grid>
                </Grid>
                {!md ? <div className={bgClasses.editIcon}>
                    <EditarIcon tipo='BUTTON' />
                </div> : null}
            </DefaultCard>
        </>
    );
};
