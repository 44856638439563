import { useCallback } from "react";
import { PrivatePageHeader } from "views/components/headers"
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { FiltroIcon, MenuIcon } from "views/components/icons";
import { PesquisaSistemas } from "./components/pesquisa-pessoa/pesquisa-sistemas";
import { useMenuPrincipal } from "services/app";
import { useThemeQueries } from "views";
import { SistemasListSearchProps } from "../sistemas-list/sistemas-list-search-props";
import { useHeaderStyles } from "views/components/headers/header-private-page/header-styles";
import { SetaCimaIcon } from "views/components/icons/seta-cima-icon";

export interface SistemasHeaderProps {
  openPesquisa: boolean;
  setOpenPesquisa: (state: boolean) => any;
  setSearchProps: (props: SistemasListSearchProps) => any;
}

export const SistemasHeader = ({
  openPesquisa,
  setOpenPesquisa,
  setSearchProps,
}: SistemasHeaderProps) => {

  const { abrirMenu } = useMenuPrincipal();
  const { isMobile, theme } = useThemeQueries();
  const classes = useHeaderStyles();

  const leftArea = useCallback(
    () => (
      isMobile ?
        <ButtonPrivateHeader icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>} tooltip="Menu" onClick={abrirMenu}></ButtonPrivateHeader> :
        null
    ),
    [isMobile, abrirMenu]
  );

  const rightArea = useCallback(
    () => (
      isMobile ? (
        <ButtonPrivateHeader
          icon={
            openPesquisa ? (
              <SetaCimaIcon tipo="PRIVATE_HEADER"></SetaCimaIcon>
            ) : (
              <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>
            )
          }
          tooltip={!openPesquisa ? "Pesquisar" : "Fechar Pesquisa"}
          onClick={() => setOpenPesquisa(!openPesquisa)}
        ></ButtonPrivateHeader>
      ) :
        <ButtonPrivateHeader
          className={classes.pesquisaBox}
          text={!openPesquisa ? 'Filtros' : 'Fechar Filtros'}
          icon={
            openPesquisa ?
              <SetaCimaIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main}></SetaCimaIcon>
              :
              <FiltroIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main}></FiltroIcon>}
          tooltip={!openPesquisa ? "Pesquisar" : "Voltar"}
          onClick={() => setOpenPesquisa(!openPesquisa)}
        ></ButtonPrivateHeader>
    ),
    [isMobile, openPesquisa, classes.pesquisaBox, theme.palette.primary.main, setOpenPesquisa]
  );

  const closePesquisaWrapper = useCallback(() => {
    setOpenPesquisa(false);
  }, [setOpenPesquisa]);

  const onPesquisar = useCallback(
    (props: SistemasListSearchProps) => {
      setSearchProps(props);
    },
    [setSearchProps]
  );

  const pesquisa = <PesquisaSistemas isOpened={openPesquisa!} onCloseSearch={closePesquisaWrapper} onPesquisar={onPesquisar} />;

  return (
    <>
      <PrivatePageHeader
        title="Sistemas"
        leftArea={leftArea()}
        bottomArea={pesquisa}
        rightArea={rightArea()}
      />
    </>
  );
};
