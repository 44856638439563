import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const ContratoIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M23.4577 3.8264C28.9196 3.8264 34.4068 3.927 39.8686 3.80125C42.9171 3.72581 44.0856 5.03356 44.0602 7.90054C43.9586 17.7841 44.0348 27.6676 44.0094 37.526C44.0094 38.4063 44.2381 39.035 44.8986 39.6134C45.5591 40.1667 46.1687 40.7703 46.753 41.399C47.9216 42.6564 48.3281 44.1654 47.7184 45.7749C47.1595 47.309 45.9655 48.1389 44.3397 48.2898C43.1457 48.4156 42.1041 47.9629 41.2404 47.1581C40.2243 46.2024 39.2081 45.2468 38.2682 44.2408C37.7601 43.6875 37.2012 43.5366 36.4645 43.5366C26.4808 43.5618 16.5225 43.5618 6.58962 43.5618C6.36099 43.5618 6.13235 43.5618 5.90372 43.5618C3.97303 43.5115 2.77905 42.3546 2.77905 40.4433C2.77905 36.2937 2.77905 32.119 2.77905 27.9694C2.77905 21.0786 2.77905 14.1878 2.77905 7.29696C2.77905 4.98326 3.89682 3.8767 6.20857 3.8767C11.9498 3.8264 17.6911 3.8264 23.4577 3.8264ZM41.8501 36.6207C41.9263 36.5955 41.9771 36.5704 42.0533 36.5452C42.0533 36.2937 42.0533 36.0674 42.0533 35.8159C42.0533 26.3096 42.0533 16.8033 42.0533 7.29696C42.0533 5.93892 41.8501 5.73773 40.4529 5.73773C29.072 5.73773 17.7165 5.73773 6.33558 5.73773C4.96378 5.73773 4.73515 5.93892 4.73515 7.29696C4.73515 18.2116 4.73515 29.1011 4.73515 40.0158C4.73515 41.3738 4.96378 41.6002 6.31018 41.6002C15.735 41.6002 25.1598 41.6002 34.5846 41.6002C34.8132 41.6002 35.0673 41.575 35.4483 41.5499C35.1689 41.2481 34.9911 41.0721 34.7878 40.8709C32.3999 38.532 30.0373 36.2183 27.6748 33.8794C27.3953 33.6028 27.1159 33.2507 27.0143 32.8986C26.4808 31.1131 25.9727 29.3275 25.4646 27.5419C25.1598 26.4353 25.7441 25.8821 26.8619 26.1839C28.5385 26.6114 30.2406 27.0389 31.9172 27.5419C32.4253 27.6928 32.9334 27.9946 33.3144 28.3467C35.0419 29.9813 36.7185 31.6412 38.4206 33.301C39.5638 34.4327 40.7069 35.5393 41.8501 36.6207ZM44.3905 42.3798C40.2243 38.1296 36.0072 33.8291 31.7394 29.4784C30.774 30.5095 29.9103 31.4651 29.0974 32.3454C33.289 36.5201 37.5569 40.7703 41.7739 44.9701C42.6884 44.0648 43.6284 43.1343 44.3905 42.3798Z" />
                <path d="M23.3815 12.5531C27.0905 12.5531 30.8248 12.5531 34.5338 12.5531C34.8132 12.5531 35.1689 12.4777 35.3467 12.6034C35.6515 12.8298 36.058 13.1819 36.058 13.4836C36.058 13.7854 35.6769 14.1627 35.3721 14.389C35.1943 14.5399 34.8386 14.4645 34.5592 14.4645C27.0905 14.4645 19.6217 14.4645 12.153 14.4645C12.0514 14.4645 11.9498 14.4645 11.8482 14.4645C11.0098 14.4393 10.578 14.1124 10.6034 13.4836C10.6288 12.8801 11.0352 12.5531 11.899 12.5531C15.735 12.5531 19.5709 12.5531 23.3815 12.5531Z" />
                <path d="M23.407 19.3433C27.0906 19.3433 30.7995 19.3433 34.4831 19.3433C34.7117 19.3433 34.9403 19.3433 35.169 19.3433C35.7787 19.3936 36.1089 19.7206 36.1343 20.299C36.1343 20.9026 35.7533 21.2043 35.169 21.2546C34.9911 21.2798 34.8133 21.2546 34.6355 21.2546C27.1414 21.2546 19.6473 21.2546 12.1785 21.2546C12.0261 21.2546 11.8737 21.2546 11.7213 21.2546C11.1116 21.2295 10.7051 20.978 10.6543 20.3241C10.6035 19.7457 11.0354 19.3433 11.7467 19.3433C12.9152 19.3182 14.0838 19.3433 15.227 19.3433C17.9452 19.3433 20.6634 19.3433 23.407 19.3433Z" />
                <path d="M16.5988 26.1084C18.1231 26.1084 19.6473 26.1084 21.1461 26.1084C21.959 26.1084 22.4163 26.4605 22.3909 27.0892C22.3909 27.6676 21.959 28.0449 21.1715 28.0449C18.0469 28.0449 14.9476 28.0449 11.8229 28.0449C11.0354 28.0449 10.6289 27.6928 10.6035 27.0892C10.6035 26.4856 11.0354 26.1084 11.8483 26.1084C13.4488 26.1084 15.0238 26.1084 16.5988 26.1084Z" />
            </DefaultIcon>
        </>
    );
};

