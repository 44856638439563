import { VariaveisAmbiente } from 'config';
import { PessoaSistemaCreateModel } from 'model/api/gestao/pessoa/pessoa-sistema-create-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePutAtualizarPessoaSistema() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putAtualizarPessoaSistema = useCallback(
        (pessoaId: string, pessoa: PessoaSistemaCreateModel) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/pessoa/${pessoaId}/sistema`,
                method: 'PUT',
                data: pessoa,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putAtualizarPessoaSistema,
    };
}
