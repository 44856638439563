
import { useMemo } from 'react';
import { useValidationYupDocumento } from 'views/components/form-validations';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

export const useFormPessoaCadastroValidation = (revenda: boolean = false) => {

  const { documentoYup } = useValidationYupDocumento(false)

  const FormPessoaCadastroValidationYup = useMemo(() => {
    return Yup.object().shape<AnyObject>({
      nomeComercial: Yup.string().required('O nome é obrigatório.'),
      cpfCnpj: documentoYup(),
    });
  }, [documentoYup])

  return {
    FormPessoaCadastroValidationYup,
  }
}