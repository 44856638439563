import { VariaveisAmbiente } from 'config';
import { SistemaModel } from 'model/api/gestao/sistemas/sistema-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetSistemasById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<SistemaModel>();

    const getSistemasById = useCallback(
        (id: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Sistema/${id}`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getSistemasById,
    };
}
