import { Theme, makeStyles } from "@material-ui/core";

interface StylesProps {
  descontoMaior: boolean;
  descontoMenor: boolean;
}

export const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  container: {
    '& p': {
      fontSize: '12px !important'
    }
  },
  item: {
    marginLeft: 7,
    minWidth: 100,
    maxWidth: 200,
    [theme.breakpoints.down('md')]: {
      margin: '4px 0',
      marginRight: theme.spacing(2)
    }
  },
  campoDesconto: {
    textDecoration: 'line-through',
    fontSize: 15,
    color: ({ descontoMaior, descontoMenor }) => {
      if (descontoMaior) {
        return theme.palette.success.main
      }
      return theme.palette.error.main
    },

  }
}))