import { Button, Grid } from "@material-ui/core";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { useStyles } from '../../dialog-classificacoes-styles'
import { SalvarIcon, VoltarIcon } from "views/components/icons";
import { DefaultFormRefs } from "views/components/form/utils";
import { ClassificacaoModel } from "model/api/gestao/classificacao/classificacao-model";
import { useCallback, useRef } from "react";
import { useToastSaurus } from "services/app";
import { usePostPessoaClassificacao } from "data/api/gestao/pessoa-classificacao/post-pessoa-classificacao";
import { FormClassificacao } from "views/components/form/master/classificacao/form-classificacao";

interface Props {
    closeDialog: () => void;
}

export const ClassificacoesCriar = ({ closeDialog }: Props) => {

    const { acoes } = useModalStyles();
    const classes = useStyles();
    const { showToast } = useToastSaurus();

    const { postAPessoaClassificacao, carregando } = usePostPessoaClassificacao();

    const criarClassificacoesRef = useRef<DefaultFormRefs<ClassificacaoModel>>(null);

    const handleSubmit = useCallback(async (model: ClassificacaoModel) => {
        try {
            const res = await postAPessoaClassificacao(model)

            if (res.erro) throw res.erro
            if (!res.resultado) throw new Error('Erro ao salvar Classificação')

            showToast('success', 'Classificação cadastrada.')
            closeDialog();
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [closeDialog, postAPessoaClassificacao, showToast])

    return (
        <div className={acoes}>
            <Grid container spacing={2} className={classes.content}>
                <Grid item xs={12} md={12}>
                    <FormClassificacao
                        loading={carregando}
                        onSubmit={handleSubmit}
                        showLoading={carregando}
                        ref={criarClassificacoesRef}
                    />
                </Grid>
            </Grid>
            <div className={classes.divider} />
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={closeDialog}
                        fullWidth
                    >
                        <VoltarIcon tipo='BUTTON' />
                        Voltar
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button
                        disabled={carregando}
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={criarClassificacoesRef.current?.submitForm}
                    >
                        <SalvarIcon tipo='BUTTON_PRIMARY' />
                        Cadastrar
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};