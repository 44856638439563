import { makeStyles } from "@material-ui/core";

export const useHeaderStyles = makeStyles((theme) => ({
    pesquisaBox: {
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        border: `1px solid #c4c4c4`,
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
        height: 40,
        padding: 8,
        whiteSpace: 'nowrap',
        marginTop: theme.spacing(1),
        '& svg': {
            height: 23
        },
        [theme.breakpoints.down('sm')]:{
            border: `1px solid ${theme.palette.background.paper}`,
            background: theme.palette.primary.main,
            color: theme.palette.background.paper
        }
    },
}));
