import { guidEmpty } from "utils/guid-empty";

export class PessoaTributoModel {
    constructor(
        public id: string = guidEmpty(),
        public pessoaId: string = guidEmpty(),
        public aliquotaDeducao: number = 0,
        public aliquotaISS: number = 0,
        public aliquotaPIS: number = 0,
        public aliquotaCOFINS: number = 0,
        public aliquotaINSS: number = 0,
        public aliquotaIR: number = 0,
        public aliquotaCSLL: number = 0,
        public pTotTrib: number = 0,
        public tipoTributacao: string = '',
        public codigoServico: string = '',
        public textoServico: string = '',
        public issRetido: boolean = false,
        public serieRPS: number = 0,
    ) {

    }
  }