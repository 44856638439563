import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { TabelaPrecoFormModel } from 'model/app/forms/tabela-preco/tabela-preco-form-model';
import { VariaveisAmbiente } from 'config';

export function usePostAdicionarTabelaPreco() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<TabelaPrecoFormModel>();

    const postAdicionarTabelaPreco = useCallback(
        (tabelaPreco: TabelaPrecoFormModel) =>
            invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/tabelapreco`,
                data: tabelaPreco,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postAdicionarTabelaPreco,
    };
}
