import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    buttonConsulta: {
        '& svg': {
            padding: 0,
            margin: 0
        }
    },
    loginContainer: {
        background: '#F8F8F8',
        padding: '.5rem 1rem',
        marginTop: '1rem',
        borderRadius: '15px',
        '& .MuiFormControl-root': {
            background: '#fff'
        }
    },
}));