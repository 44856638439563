import classNames from "classnames";
import { ButtonIcon } from "views/components/controles";
import { VoltarIcon } from "views/components/icons";

import { MenuPrincipalTopProps } from "./menu-principal-top-props";
import { useStyles } from "./menu-principal-top-styles";

export const MenuPrincipalTop = (
  props: MenuPrincipalTopProps
) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.drawerDefaultTopButtonsContainer}>
        <div className={classes.pinButton}>
          <ButtonIcon
            onClick={() => { props.pinClicked() }}
            icon={<VoltarIcon class={classNames(classes.pinButtonIcon, (props.modeloAtual === "Mini" ? classes.pinButtonMini : undefined))} tipo="GERAL" />} />
        </div>
      </div>
    </>
  );
};
