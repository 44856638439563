import { Box, Button, Grid, Tooltip } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { CloseIcon } from 'views/components/icons/close-icon';
import { FaturaFilhaModel } from 'model/api/gestao/fatura/faturas-filhas-model';
import { FaturaFilhaListData } from './components/faturas-filhas-list-data';
import FaturasFilhasContador from './components/faturas-filhas-contador/faturas-filhas-contador';
import { useThemeQueries } from 'views/theme';
import { useCallback } from 'react';
import { useToastSaurus } from 'services/app';
import { useGetFaturasFilhasExportarExcel } from 'data/api/gestao/faturas/get-faturas-filhas-exportar-excel';
import { ExportarExcelIcon } from 'views/components/icons/exportar-excel-icon';
import { CircularLoading } from 'views/components/utils';

interface DialogFaturasFilhasProps {
    openned: boolean;
    closeDialog: () => void;
    faturas: FaturaFilhaModel[];
    onClickCard?: (open: boolean) => void;
    faturaId: string;
}

export const DialogFaturasFilhas = ({
    openned,
    closeDialog,
    faturas,
    onClickCard,
    faturaId,
}: DialogFaturasFilhasProps) => {

    const { isMobile } = useThemeQueries()
    const { showToast } = useToastSaurus()

    const { getFaturasFilhasExportarExcel, carregando } = useGetFaturasFilhasExportarExcel()

    const exportarExcel = useCallback(async () => {
        try {
            const res = await getFaturasFilhasExportarExcel(faturaId)

            if (res.erro) throw (res.erro)
            if (!res.resultado) return;

            const blob = new Blob([res.resultado?.data], { type: 'application/vnd.ms-excel' },);

            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a')

            anchor.href = url
            anchor.download = `Licenças.xls`
            anchor.click()
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [faturaId, getFaturasFilhasExportarExcel, showToast])
   
    return (
        <DialogSaurus
            aberto={openned}
            titulo="Faturas"
            tamanho='md'
            fullScreen={isMobile}
            endAdornment={
                <Box display='flex' alignItems='center' gridGap={5}>
                    <Tooltip arrow title="Exportar Excel">
                        <Button color="primary" onClick={() => exportarExcel()}>
                            <ExportarExcelIcon tipo="BUTTON_EXPORT_MOBILE" />
                        </Button>
                    </Tooltip>
                    <Button onClick={closeDialog} style={{ margin: '5px 0' }}>
                        <CloseIcon tipo='BUTTON' style={{ margin: 0 }} />
                    </Button>
                </Box>
            }
            zIndex='1299'
            bottomArea={<FaturasFilhasContador faturas={faturas} />}
        >
            {carregando && <CircularLoading tipo='FULLSIZED' />}
            <Grid container style={{ marginBottom: '15px' }}>
                <Grid item xs={12}>
                    <FaturaFilhaListData
                        list={faturas}
                        onClick={onClickCard!}
                    />
                </Grid>
            </Grid>
        </DialogSaurus>
    );
};
