import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { CircularLoading } from 'views';
import { ComissaoPendenteModel } from 'model/api/gestao/comissao/comissao-pendente-model';
import { CardComissaoPendente } from 'views/components/cards/card-comissao-pendente/card-comissao-pendente';
import { isEmpty, isEqual } from 'lodash';
import { ComissoesListSearchProps } from '../comissoes-list-search-props';
import { PesquisaComissionado } from './pesquisa-comissionado';

export interface ComandasPendentesListDataProps {
  list: Array<ComissaoPendenteModel>;
  carregando: boolean;
  onCardClicked: (model: ComissaoPendenteModel) => any;
  onCardSelected: (model: ComissaoPendenteModel) => any;
  selectedList: Array<ComissaoPendenteModel>;
  setSearchProps: React.Dispatch<React.SetStateAction<ComissoesListSearchProps>>;
  searchProps: ComissoesListSearchProps;
}

export const ComissoesPendentesListData = (props: ComandasPendentesListDataProps) => {

  const onCardSelected = (model: ComissaoPendenteModel) => {
    props.onCardSelected(model);
  };

  if (isEmpty(props.searchProps.comissionadoId) && props.list.length === 0) return (
    <PesquisaComissionado />
  )

  return (
    <>
      {props.carregando && <CircularLoading tipo="FULLSIZED" />}
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhuma comissão pendente encontrada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((model, index) => {
          return (
            <CardComissaoPendente
              selected={
                props.selectedList.filter((item) => isEqual(model, item)).length >
                  0
                  ? true
                  : false
              }
              model={model}
              onClick={onCardSelected}
              onSelect={onCardSelected}
            />
          );
        })}
    </>
  );
};
