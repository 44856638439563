import { useCallback } from "react";
import { PrivatePageHeader } from "views/components/headers";
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { DesfazerIcon, FiltroIcon, MenuIcon, VoltarIcon } from "views/components/icons";
import { useMenuPrincipal } from "services/app";
import { useThemeQueries } from "views";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import { FuncionariosListSearchProps } from "../funcionarios-list/funcionarios-list-search-props";
import { PesquisaFuncionarios } from "./components/pesquisa-funcionarios/pesquisa-funcionarios";

export interface FuncionariosHeaderProps {
  openPesquisa: boolean;
  setOpenPesquisa: (state: boolean) => any;
  setSearchProps: (props: FuncionariosListSearchProps) => any;
  titulo: string,
}

export interface LocationProps {
  from: {
    pathname: string,
    page?: number;
  }
}

export const FuncionariosHeader = ({
  openPesquisa,
  setOpenPesquisa,
  setSearchProps,
  titulo,
}: FuncionariosHeaderProps) => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();
  const history = useHistory()
  const { state } = useLocation<LocationProps>()

  const leftArea = useCallback(
    () =>
      isMobile ? (titulo === 'Funcionários' ?
        (
          <ButtonPrivateHeader
            icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
            tooltip="Menu"
            onClick={abrirMenu}
          ></ButtonPrivateHeader>
        ) : <ButtonPrivateHeader
          icon={<VoltarIcon tipo="PRIVATE_HEADER"></VoltarIcon>}
          tooltip="Voltar"
          onClick={() => {
            if (!state?.from?.pathname || isEmpty(state.from.pathname)) {
              history.push('/funcionarios/')
              return
            }
            if (state && state.from.page) {
              history.push(`/funcionarios/?page=${state.from.page}`)
              return
            }
            history.goBack()
          }}
        ></ButtonPrivateHeader>) : (titulo === "Editar Funcionário") ? (
          <ButtonPrivateHeader
            icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
            tooltip="Voltar"
            onClick={() => {
              if (!state?.from?.pathname || isEmpty(state.from.pathname)) {
                history.push('/funcionarios/')
                return
              }
              if (state && state.from.page) {
                history.push(`/funcionarios?page=${state.from.page}`)
                return
              }
              history.goBack()
            }}
          />
        ) : null,
    [isMobile, titulo, abrirMenu, state, history]
  );

  const rightArea = useCallback(
    () => titulo === "Funcionários" ? (
      <ButtonPrivateHeader
        icon={
          openPesquisa ? (
            <DesfazerIcon tipo="PRIVATE_HEADER"></DesfazerIcon>
          ) : (
            <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>
          )
        }
        tooltip={!openPesquisa ? "Pesquisar" : "Voltar"}
        onClick={() => setOpenPesquisa(!openPesquisa)}
      ></ButtonPrivateHeader>
    ) : null,
    [titulo, openPesquisa, setOpenPesquisa]
  );

  const closePesquisaWrapper = useCallback(() => {
    setOpenPesquisa(false);
  }, [setOpenPesquisa]);

  const onPesquisar = useCallback(
    (props: FuncionariosListSearchProps) => {
      setSearchProps(props);
    },
    [setSearchProps]
  );

  const pesquisa = useCallback(() => titulo === 'Funcionários' ? (
    <PesquisaFuncionarios
      isOpened={!isMobile || openPesquisa!}
      onCloseSearch={closePesquisaWrapper}
      onPesquisar={onPesquisar}
    />
  ) : <></>, [closePesquisaWrapper, isMobile, onPesquisar, openPesquisa, titulo])

  return (
    <>
      <PrivatePageHeader
        title={titulo}
        leftArea={leftArea()}
        rightArea={isMobile ? rightArea() : pesquisa()}
        bottomArea={isMobile ? pesquisa() : undefined}
      />
    </>
  );
};