import { makeStyles, Theme } from '@material-ui/core';

interface StylesProps {
  pontos: number
}

export const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
    container: {
        padding: theme.spacing(2)
    },
    pergunta: {
        fontWeight: 'bold'
    }
//   roundBox: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     flexDirection: 'column',
//     gap: theme.spacing(2),
//     background: theme.palette.common.white,
//     borderWidth: 4,
//     borderStyle: 'solid',
//     borderRadius: '50%',
//     padding: theme.spacing(2),
//     height: 150,
//     width: 150,
//     borderColor: ({ status }) => {
//       switch (status) {
//         case EnumStatusClienteIdeal.IDEAL:
//           return theme.palette.success.main;
//         case EnumStatusClienteIdeal.INTERMEDIARIO:
//           return theme.palette.warning.main;
//         case EnumStatusClienteIdeal.NAO_IDEAL:
//           return theme.palette.error.main;
//         default:
//           return theme.palette.text.secondary;
//       }
//     }
//   }
}));