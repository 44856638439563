export type labelName = 'contratosAtivosSaurus' | 'cnpJsAtivosSaurus' | 'terminaisAtivosSaurus'
export type valorName = 'licencasValorAberta' | 'licencasValorCancelada' | 'licencasValorPaga' | 'licencasValorTotal' |
                        'licencasValorFaturado' | 'comissao' | 'licencasValorBonificada'

export type registroName = 'licencasQtdAberta' | 'licencasQtdBonificada' | 'licencasQtdCancelada' | 'licencasQtdPaga' |
                           'licencasQtdFaturada' | 'licencasQtdTotal'

export type saldoRevendaName = 'cnpJsSaldo' | 'contratosSaldo' | 'terminaisSaldo'

export type saldoRevendaPosicaoName = 'cnpJsSaldoPosicao' | 'contratosSaldoPosicao' | 'terminaisSaldoPosicao'

export type saldoMediaName = 'terminaisSaldoMediaSaurus' | 'contratosSaldoMediaSaurus' | 'cnpJsSaldoSaurus'

export type ativosMediaName = 'cnpJsAtivosMediaSaurus' | 'terminaisAtivosMediaSaurus' | 'contratosAtivosMediaSaurus'

export class RelatorioUnitarioChartModel{
    constructor(
        public x: any = 0,
        public y: number = 0,
        public label: string = '',
    ) { }
}

export class RelatorioChartModel {
    constructor(
        public name: string = '',
        public data: RelatorioUnitarioChartModel[] = []
    ) { }
}

export class RelatorioChartMixedModel {
    constructor(
        public name: string = '',
        public type: "line" | "area" | "bar" | "pie" | "donut" | "radialBar" | "scatter" | "bubble" | "heatmap" | "candlestick" | "boxPlot" | "radar" | "polarArea" | "rangeBar" | "rangeArea" | "treemap",
        public data: RelatorioUnitarioChartModel[] = []
    ) { }
}