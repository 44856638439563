import { useMemo, useState } from 'react';
import { RevendedorList } from './components/revendedor-list/revendedor-list';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { useParams } from 'react-router-dom';
import { RevendedorEditar } from './components/revendedor-editar';
import { RevendedorHeader } from './components/revendedor-header/renvededor-header';
import { RevendedorListSearchProps } from './components/revendedor-list/revendedor-list-search-props';

interface paramProps {
  parametro: string
}

export const RevendedorPage = () => {
  const classes = useDefaultCadastroStyles();

  const [openPesquisa, setOpenPesquisa] = useState(false);
  const [searchProps, setSearchProps] = useState<RevendedorListSearchProps>({
    termo: '',
  });

  const { parametro } = useParams<paramProps>()

  const pageSwitch = useMemo(() => {
    setOpenPesquisa(false)
    switch (parametro) {
      case undefined:
        return <RevendedorList searchProps={searchProps} />
      default:
        return (
          <div className={classes.defaultContainer}>
            <RevendedorEditar id={parametro} />
          </div>
        )
    }
  }, [classes.defaultContainer, parametro, searchProps])

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <RevendedorHeader
          titulo={parametro === undefined
            ? 'Revendedores'
            : parametro === 'adicionar'
              ? 'Adicionar Revendedor'
              : 'Editar Revendedor'}
          openPesquisa={openPesquisa}
          setSearchProps={setSearchProps}
          setOpenPesquisa={setOpenPesquisa}
        />
      </div>
      {pageSwitch}
    </div>
  );
};

export default RevendedorPage;
