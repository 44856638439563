import { Grid } from '@material-ui/core';
import { RelatorioRevendaModel } from 'model/api/gestao/relatorios/relatorio-revenda-model';
import { RevendasRelatorioListData } from './components/revenda-relatorio-list-data';
import { ResultadoRevendaModel } from 'model/api/gestao/relatorios/resultado-revenda-model';
import { useMemo, useState } from 'react';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { useThemeQueries } from 'views/theme';
import { RelatorioSearchPropsModel } from 'model/api/gestao/relatorios/relatorio-search-props-model';

interface DashboardSistemasRelatorioProps {
    resultadoRevenda: ResultadoRevendaModel[];
    carregando: boolean;
    searchProps: RelatorioSearchPropsModel;
}


export default function DashboarRevendaRelatorio({ carregando, resultadoRevenda, searchProps }: DashboardSistemasRelatorioProps) {

    const [openAccordion, setOpenAccordion] = useState<boolean>(true)
    const { theme } = useThemeQueries();

    const rankingRevendas = useMemo<RelatorioRevendaModel[]>(() => {
        const arrAtual = resultadoRevenda.sort((a, b) => b.contratosAtivos - a.contratosAtivos)

        const total = arrAtual.reduce((prev, curr) => prev + curr.contratosAtivos, 0)

        const limiteA = 0.2; // 20% das vendas totais
        const limiteB = 0.5; // 50% das vendas totais

        // Passo 4: Atribuir cada venda ao respectivo grupo (A, B ou C)
        let vendasGrupoA = 0;
        let vendasGrupoB = 0;

        const arrTransformado = arrAtual.map((element, _, arr) => {
            let grupo: 'A' | 'B' | 'C' = 'C'

            if (vendasGrupoA < limiteA * total) {
                grupo = 'A';
                vendasGrupoA += element.contratosAtivos;
            } else if (vendasGrupoB < limiteB * total) {
                grupo = 'B';
                vendasGrupoB += element.contratosAtivos;
            } else {
                grupo = 'C';
            }

            return new RelatorioRevendaModel(element.revendaId, element.revenda, element.contratosAtivos, element.contratosCancelados, element.anoMes, grupo)
        })

        return arrTransformado.filter(element => element.anoMes === searchProps.anoMesFinal)
    }, [resultadoRevenda, searchProps.anoMesFinal])

    return (
        <Grid item xs={12}>
            <AccordionSaurus
                labelPrimary="Ranking"
                tipoExpand="bold"
                noPaperRoot={false}
                heightDivider={2}
                showDivider={openAccordion}
                colorDivider={theme.palette.primary.main}
                colorExpand={theme.palette.primary.main}
                expanded={openAccordion}
                onChange={() => setOpenAccordion(prev => !prev)}
            >
                <Grid container spacing={1} style={{ maxHeight: 400, overflowY: 'auto' }}>
                    {openAccordion && <RevendasRelatorioListData
                        list={rankingRevendas}
                        carregando={carregando}
                    />}
                </Grid>
            </AccordionSaurus>
        </Grid>
    );
}
