import { VariaveisAmbiente } from 'config';
import { IndicacoesPutModel } from 'model/api/gestao/indicacoes/indicacoes-put-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePutIndicacoes() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putIndicacoes = useCallback(
        (indicacoes: IndicacoesPutModel) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Indicacao`,
                method: 'PUT',
                data: indicacoes,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putIndicacoes,
    };
}
