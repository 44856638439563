import { VariaveisAmbiente } from 'config';
import { TabelaPrecoModel } from 'model/api/gestao/tabela-preco/tabela-preco-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetTabelaPrecoById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<TabelaPrecoModel>();

    const getTabelaPrecoById = useCallback(
        (id: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/tabelapreco/${id}/`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getTabelaPrecoById,
    };
}
