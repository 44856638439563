import { useThemeQueries } from "views/theme";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { Button, Divider, Grid, Typography } from "@material-ui/core";
import { IndicacoesInteracoesModel } from "model/api/gestao/indicacoes/indicacoes-interacoes-model";
import { useCallback, useState } from "react";
import { IndicacoesInteracoesPostModel } from "model/api/gestao/indicacoes/indicacoes-interacoes-post-model";
import { picker } from "utils/picker";
import { usePostIndicacoesInteracoes } from "data/api/gestao/indicacoes/post-indicacoes-interacoes";
import { DialogFinalizacaoIndicacao } from "../dialog-finalizacao-indicacao/dialog-finalizacao-indicacao";
import { CircularLoading } from "views/components/utils";
import { guidEmpty } from "utils/guid-empty";
import { toDateStringApi } from "utils/to-date";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { DialogMotivoIndicacao } from "../dialog-motivo-indicacao/dialog-motivo-indicacao";
import { EnumStatusIndicacao } from "model/enums/enum-status-indicacao";

interface Props {
    open: boolean;
    id: string;
    interacoes: IndicacoesInteracoesModel[];
    setDialogConclusao: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: () => void;
    getInteracoesWrapper: () => Promise<void>;
}

export const DialogConclusaoIndicacao = ({
    open,
    id,
    interacoes,
    setDialogConclusao,
    onClose,
    getInteracoesWrapper
}: Props) => {

    //AUX
    const { theme } = useThemeQueries();
    const { showToast } = useToastSaurus()

    //PROVIDERS
    const { usuario } = useSessaoAtual()

    //CHAMADAS DA API
    const { postIndicacoesInteracoes, carregando: carregandoPost } = usePostIndicacoesInteracoes();

    const carregando = carregandoPost;

    //STATES
    const [openDialogFinalizacao, setOpenDialogFinalizacao] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const salvar = useCallback(async () => {

        const model = interacoes[0];

        const modelFormatado = picker<IndicacoesInteracoesPostModel>(model, new IndicacoesInteracoesPostModel());

        modelFormatado.tipoInteracao = EnumStatusIndicacao.PropostaAceita;
        modelFormatado.descricao = '';
        modelFormatado.responsavelId = usuario?.Id ?? '';
        modelFormatado.id = guidEmpty();
        modelFormatado.dataHora = toDateStringApi(new Date())

        const res = await postIndicacoesInteracoes(id, modelFormatado);

        if (res.erro)
            throw res.erro

        setOpenDialogFinalizacao(true);

        setTimeout(() => {
            setOpenDialogFinalizacao(false);
            onClose();
        }, 5000);

        await getInteracoesWrapper();

        showToast('success', 'Status Atualizado!')
    }, [getInteracoesWrapper, id, interacoes, onClose, postIndicacoesInteracoes, showToast, usuario?.Id])

    const abrirDialog = () => {
        setOpenDialog(true);
    }

    return (
        <>
            <DialogSaurus
                aberto={open}
                titulo="Conclusão"
                colorTitulo={theme.palette.primary.main}
                centralizarTitulo={true}
                tamanho="xs"
                bottomArea={
                    <>
                        <div style={{
                            paddingTop: '8px',
                            paddingBottom: '16px',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                        }}>
                            <Divider variant="fullWidth" style={{ background: theme.palette.grey[400], marginBottom: '8px' }} />
                            <Button
                                variant="outlined"
                                onClick={onClose}
                                fullWidth
                            >
                                Voltar
                            </Button>
                        </div>
                    </>
                }
            >
                {carregando && <CircularLoading tipo="FULLSIZED" />}
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{
                        display: 'flex',
                        textAlign: 'center'
                    }}>
                        <Typography
                            variant="body2"
                            color="textPrimary"
                        >
                            O cliente da indicação aceitou a proposta?
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={abrirDialog}
                        >
                            Não
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={salvar}
                        >
                            Sim
                        </Button>
                    </Grid>
                </Grid>
            </DialogSaurus>

            {openDialog && (
                <DialogMotivoIndicacao
                    id={id}
                    interacoes={interacoes}
                    setDialogConclusao={setDialogConclusao}
                    aberto={openDialog}
                    onClose={() => setOpenDialog(false)}
                />
            )}

            {openDialogFinalizacao && (
                <DialogFinalizacaoIndicacao
                    open={openDialogFinalizacao}
                />
            )}
        </>
    )
}