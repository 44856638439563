import { KeyValueModel } from "model";
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';


export const TpPessoasMock: KeyValueModel[] = [
    new KeyValueModel(EnumTipoPessoas.SoftwareHouse, "Software House"),
    new KeyValueModel(EnumTipoPessoas.Revendedor, "Revendedor"),
    new KeyValueModel(EnumTipoPessoas.Cliente, "Cliente"),
    new KeyValueModel(EnumTipoPessoas.Funcionario, "Funcionário"),
    new KeyValueModel(EnumTipoPessoas.FuncionarioFinanceiroSemCusto, "Funcionário Financeiro (Nível 1)"),
    new KeyValueModel(EnumTipoPessoas.FuncionarioFinanceiro, "Funcionário Financeiro (Nível 2)"),
    new KeyValueModel(EnumTipoPessoas.Prospeccao, 'Prospecção'),
    new KeyValueModel(EnumTipoPessoas.GerenteComercial, "Gerente Comercial"),
    new KeyValueModel(EnumTipoPessoas.AgenteExterno, "Agente Externo"),
    new KeyValueModel(EnumTipoPessoas.FuncionarioSoftwareHouse, "Funcionário"),
    new KeyValueModel(EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto, "Funcionário Financeiro (Nível 1)"),
    new KeyValueModel(EnumTipoPessoas.FinanceiroSoftwareHouse, "Funcionário Financeiro (Nível 2)"),
    new KeyValueModel(EnumTipoPessoas.Representante, "Representante")
];
