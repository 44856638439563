import { KeyValueModel } from "model";
import { EnumSituacaoPlano } from "model/enums/enum-status-plano";

export const SituacaoPlanoMock: KeyValueModel[] = [
    new KeyValueModel(EnumSituacaoPlano.Ativado, "Ativado"),
    new KeyValueModel(EnumSituacaoPlano.Desativado, "Desativado")
];

export const SituacaoPlanoMockTodos: KeyValueModel[] = [
    new KeyValueModel(-1, 'Todos'),
    new KeyValueModel(EnumSituacaoPlano.Ativado, "Ativado"),
    new KeyValueModel(EnumSituacaoPlano.Desativado, "Desativado")
]