import { EnumTipoAdiantamento } from "model/enums/enum-tipo-adiantamento";
import { EnumTipoAvaliacaoDesempenho } from "model/enums/enum-tipo-avaliacao-desempenho";
import { EnumTipoCobrancaRevenda } from "model/enums/enum-tipo-cobranca-revenda";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { guidEmpty } from "utils/guid-empty";

export class PessoaPostModel {
    constructor(
        public id: string = guidEmpty(),
        public tipo: EnumTipoPessoas = EnumTipoPessoas.Cliente,
        public nomeComercial: string = '',
        public responsavel: string = '',
        public cpfCnpj: string = '',
        public status: number | null = 0,
        public recebeIndicacoes: number = 0,
        public diasLimiteAtrasoClientes: number = 0,
        public valorMinimoFatura: number = 0,
        public melhorDia: number | null = null,
        public login: string | null = null,
        public senha: string | null = null,
        public bloquearContratos: boolean = false,
        public mensagemAosContratos: string = '',
        public urlImagem: string = '',
        public urlImagemPin: string = '',
        public revendaId: string | null = null,
        public revendaNome?: string,
        public revendaTipoCobranca: EnumTipoCobrancaRevenda | null = null,
        public telefoneSuporte: string | null = null,
        public tipoCobrancaRevenda: EnumTipoCobrancaRevenda | null = null,
        public chaveAtivacao: string = '',
        public gerenteId: string | null = null,
        public gerenteNome?: string,
        public vendedorId: string | null = null,
        public vendedorNome?: string,
        public classificacaoId: string | null = null,
        public classificacaoAbreviacao: string = '',
        public dataUltimaLiberacao: string = '',
        public dataLimiteLiberacao: string = '',
        public horaInicioCobranca: number | null = null,
        public horaFimCobranca: number | null = null,
        public diasCancelamentoContrato: number | null = null,
        public tipoAdiantamentoFatura: EnumTipoAdiantamento | null = null,
        public avaliarDesempenhoRevenda: EnumTipoAvaliacaoDesempenho = EnumTipoAvaliacaoDesempenho.NaoAvalia,
        public senhaTecnicaParaSistemas: string = ''
    ) { }
}