import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { useFormRepresentantesCadastroValidation } from './form-representante-cadastro-validation';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  TextFieldSaurus,

} from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { picker } from 'utils/picker';
import { PessoaPostModel } from 'model/api/gestao/pessoa/pessoa-post-model';
import { guidEmpty } from 'utils/guid-empty';
import { AutocompleteRevendas } from 'views/components/controles/autocompletes/autocomplete-revendas/autocomplete-revendas';

export const FormRepresentanteCadastro = forwardRef<
  DefaultFormRefs<PessoaPostModel>,
  DefaultFormProps<PessoaPostModel>
>(
  (
    { loading, onSubmit, ...props }:  DefaultFormProps<PessoaPostModel>,
    ref,
  ) => {
    const { isMobile } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);
    const { FormRepresentanteValidationYup} = useFormRepresentantesCadastroValidation()
    // const [pessoaMock, setPessoaMock] = useState<KeyValueModel[]>([])
    const [modelForm, setModelForm] = useState<PessoaPostModel>(new PessoaPostModel())

    const {
      handleSubmit,
      control,
      formState: { errors },
      reset,
      getValues,
      setValue
    } = useForm<PessoaPostModel>({
      resolver: yupResolver(FormRepresentanteValidationYup),
      defaultValues: modelForm,
      criteriaMode: 'all',
      mode: 'onBlur' && 'onChange',
    });

    const carregando =
      loading

    const submitAlterarDadosConta = (values: PessoaPostModel) => {
      const pessoa = picker<PessoaPostModel>(values, new PessoaPostModel())
      pessoa.id = guidEmpty()
      onSubmit(pessoa);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitAlterarDadosConta)();
      },
      resetForm: () => {
        reset();
        if (!isMobile) refInputNome.current?.focus();
      },
      fillForm: (model: PessoaPostModel) => {
        setModelForm(model)
        reset({ ...model });
        if (!isMobile) refInputNome.current?.focus();
      },
    }));

    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {carregando && props.showLoading && (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="FULLSIZED" />
              </div>
            )}

            <form
              onSubmit={handleSubmit(submitAlterarDadosConta)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <Controller
                    name="cpfCnpj"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        inputRef={refInputNome}
                        disabled={loading}
                        allowSubmit={false}
                        label="CPF/CNPJ"
                        tipo='CNPJ_CPF'
                        fullWidth
                        manterMascara
                        autoComplete={'off'}
                        helperText={
                          errors.cpfCnpj
                            ? errors.cpfCnpj.message
                            : undefined
                        }
                        error={Boolean(errors.cpfCnpj && errors.cpfCnpj.message)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="nomeComercial"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={loading}
                        allowSubmit={false}
                        id="nome"
                        label="Nome"
                        fullWidth
                        autoComplete={'off'}
                        helperText={
                          errors.nomeComercial
                            ? errors.nomeComercial.message
                            : undefined
                        }
                        error={Boolean(errors.nomeComercial && errors.nomeComercial.message)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                      name="revendaId"
                      control={control}
                      render={({ field }) => (
                        <AutocompleteRevendas
                          label='Revendedor'
                          value={getValues('revendaId') || undefined}
                          fetchOnLoad
                          onChange={(e: any) => {
                            setValue('revendaId', e.value.id)
                          }}
                          helperText={
                            errors.revendaId
                              ? errors.revendaId.message
                              : undefined
                          }
                          error={Boolean(errors.revendaId && errors.revendaId.message)}
                        />
                      )}
                    />
                  </Grid>
              </Grid>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);