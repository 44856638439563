import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  tooltip: {
    background: '#fff',
    boxShadow: '1px 2px 3px 1px #00000030'
  },
  container: {
    paddingBottom: 5,
    '& p': {
      fontSize: 13
    }
  },
  infoIcon: {
    borderLeft: '1px solid ' + theme.palette.divider,
    margin: '7px 0',
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    padding: '0 10px',
    marginLeft: '5px'
  },
  card: {
    background: '#fff',
  },
  datas: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start'
    }
  }
}))