import {Button, Tooltip } from "@material-ui/core"
import { useStyles } from "./button-private-header-styles";

interface ButtonIconProps {
    icon: JSX.Element;
    tooltip: string;
    onClick: () => any;
    className?: any;
    text?: string;
    disabled?: boolean;
}

export const ButtonPrivateHeader = (props: ButtonIconProps) => {
    const classes = useStyles();
    return (
        <>
            <Tooltip arrow title={props.tooltip} placement="right">
                <Button className={`${props.className ? props.className : classes.button}`} disabled={props.disabled} onClick={props.onClick}>
                    {props.icon}
                    {props.text}
                </Button>
            </Tooltip>
        </>
    )

}

