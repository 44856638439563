import { Grid } from "@material-ui/core";
import { RevendedorEdit } from "./revendedor-edit/revendedor-edit";

type Props = {
  id: string,
}

export const RevendedorEditar = ({ id }: Props) => {


  return (
    <div style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    }}>
      <Grid container spacing={1} style={{
        maxWidth: 1400,
        padding: 16
      }}>
        <RevendedorEdit id={id} />
      </Grid>
    </div>
  );
};
