import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    inputContainer: () => ({
        position: "relative",
        "& .MuiFormLabel-root": {
            marginLeft: '-5px',
            background: theme.palette.common.white,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        "& :nth-child(2)": {
            width: "100%"
        },
        "& button": {
            width: "36px",
            height: "36px",
            position: "absolute",
            right: "0px",
            zIndex: 1,
            flex: '0 0 auto',
            marginTop: 10,
            marginRight: 10,
            marginLeft: 0,
            "& span": {
                width: "100%",
                height: "100%",
            },
            "& .MuiButton-label div": {
                width: '100%',
                height: '100%',
            }

        }
    })
}));