import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    imgTitulo: {
        width: '36px',
        height: '36px',
        marginBottom: -6,
        marginRight: 6,
        fill: theme.palette.primary.main,
        [theme.breakpoints.up('sm')]: {
            width: '36px',
            height: '36px',
            marginBottom: -6,
        },
        [theme.breakpoints.up('md')]: {
            width: '40px',
            height: '40px',
            marginBottom: -6,

        },
        [theme.breakpoints.up('lg')]: {
            width: '50px',
            height: '50px',
            marginBottom: -8,
        }
    },
    logo: {
        position: 'absolute',
        top: '30px',
        right: '25px',
        width: 341,
        height: 62,
        [theme.breakpoints.down('md')]: {
            right: 0,
            left: 0,
            marginRight: 'auto',
            marginLeft: 'auto',
            top: '5px'
        },
        '@media (max-height: 700px)': {
            display: 'none'
        }
    },
    loginCard: {
        boxShadow: '0px 52.9835px 137.757px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '52px 41px',
        borderRadius: '26.4918px',
        zIndex: 3,
        [theme.breakpoints.down('sm')]: {
            padding: '52px 21px'
        },
        '& h4': {
            fontWeight: 500
        }
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20
    },
    rootPadding: {
        padding: 20,
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    }
}));