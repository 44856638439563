import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import { CircularLoading, useThemeQueries } from 'views';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { PessoasModel } from 'model/api/gestao/pessoa/pessoas-model';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { isEmpty } from 'lodash';
import { formatarCEP } from 'utils/formatar-cep';
import { useGetPessoaInfo } from 'data/api/gestao/pessoa-info';
import { useToastSaurus } from 'services/app';
import { PessoaInfoModel } from 'model/api/gestao/pessoa/pessoa-info-model';
import { useStyles } from '../indicacoes-editar-dados-styles';

interface AccordionIndicacaoIndicadorProps {
    pessoa: PessoasModel;
    id: string;
}

export const AccordionIndicacaoIndicador = ({
    pessoa,
    id,
}: AccordionIndicacaoIndicadorProps) => {
    const refInputNome = useRef<HTMLInputElement>(null);
    const { theme } = useThemeQueries()
    const classes = useStyles();
    const [openAccordion, setOpenAccordion] = useState<boolean>(true)
    const {showToast} = useToastSaurus();

    const {getPessoaInfo, carregando} = useGetPessoaInfo();

    const [info, setInfo] = useState<PessoaInfoModel>(new PessoaInfoModel())

    const getPessoaInfoWrapper = useCallback(async () => {
        try{
            const res = await getPessoaInfo(id, 1, 'pageSize=0')

            if(res.erro) throw res.erro
            if(!res.resultado) throw new Error('Erro ao carregar informações do indicador. Tente novamente.')

            const info = res.resultado.data.list.find(informacao => (
                !isEmpty(informacao.email) ||
                !isEmpty(informacao.telefone)
            )) || res.resultado.data.list[0]

            if(info) setInfo(info)

        }catch(e: any){
            showToast('error', e.message)
        }
    }, [getPessoaInfo, id, showToast])

    useEffect(() => {
        if(openAccordion){
            getPessoaInfoWrapper();
        }
    }, [getPessoaInfoWrapper, openAccordion])


    const retornaEndereco = useMemo(() => {
        const { bairro, cep, municipio, numero, complemento, logradouro, uf } = info ?? new PessoasModel()

        if(isEmpty(logradouro)) return 'Endereço não informado'
        
        return `${logradouro ?? ''}, ${numero ?? 0} - ${bairro ?? ''}${municipio ? `, ${municipio}` : ''}${uf ? ` - ${uf}` : ''}${cep ? `, ${formatarCEP(cep ?? '')}` : ''}${complemento ? `, ${complemento}` : ''}`
    }, [info])

    return (
        <>
            <div>
                <AccordionSaurus
                    labelPrimary="Dados do Indicador"
                    tipoExpand="bold"
                    noPaperRoot={false}
                    heightDivider={2}
                    showDivider={openAccordion}
                    colorDivider={theme.palette.primary.main}
                    colorExpand={theme.palette.primary.main}
                    expanded={openAccordion}
                    onChange={() => setOpenAccordion(prev => !prev)}
                >
                    <div className={classes.content}>
                        {/* <Divider variant="fullWidth" className={classes.divider} /> */}
                        {carregando && <CircularLoading tipo='FULLSIZED'/>}
                        <div>
                            <Grid container spacing={2} style={{
                                position: 'relative'
                            }}>

                                <Grid item xs={12} md={4}>
                                    <TextFieldSaurus
                                        readOnly
                                        allowSubmit={false}
                                        inputRef={refInputNome}
                                        label="CPF/CNPJ"
                                        value={formatarCPFCNPJ(pessoa.cpfCnpj)}
                                        fullWidth
                                        autoComplete={'off'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <TextFieldSaurus
                                        readOnly
                                        allowSubmit={false}
                                        value={pessoa.nomeComercial}
                                        inputRef={refInputNome}
                                        label="Razão Social"
                                        fullWidth
                                        autoComplete={'off'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <TextFieldSaurus
                                        readOnly
                                        allowSubmit={false}
                                        inputRef={refInputNome}
                                        label="E-mail"
                                        value={info.email || 'E-mail não informado'}
                                        fullWidth
                                        autoComplete={'off'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextFieldSaurus
                                        readOnly
                                        allowSubmit={false}
                                        inputRef={refInputNome}
                                        label="Telefone"
                                        value={info.telefone || 'Telefone não informado'}
                                        fullWidth
                                        autoComplete={'off'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldSaurus
                                        readOnly
                                        allowSubmit={false}
                                        inputRef={refInputNome}
                                        label="Endereço"
                                        value={retornaEndereco}
                                        multiline
                                        min={1}
                                        size='medium'
                                        fullWidth
                                        autoComplete={'off'}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </AccordionSaurus>
            </div>
        </>
    );
}