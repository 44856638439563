import { useCallback } from "react";
import { ComissoesListSearchProps } from "../comissoes-tabs/comissoes-list-search-props";
import PesquisaComissoes from "./pesquisa-comissoes/pesquisa-comissoes";

export interface ComissoesFilterProps {
    openPesquisa: boolean;
    setSearchProps: (props: ComissoesListSearchProps) => any;
    searchProps: ComissoesListSearchProps;
}

export const ComissoesPendentesFilter = ({
    openPesquisa,
    setSearchProps,
    searchProps
}: ComissoesFilterProps) => {
    const onPesquisar = useCallback(
        (props: ComissoesListSearchProps) => {
            setSearchProps(props);
        },
        [setSearchProps]
    );

    return (
        <>
            {openPesquisa ? <PesquisaComissoes onPesquisar={onPesquisar} searchProps={searchProps}
         onCloseSearch={() => {}} isOpened={openPesquisa} /> : null}
        </>
    );
};
