export enum EnumMenu {

  DASHBOARD = 1000,

  REVENDEDORES_GROUP = 2000,
  REVENDEDORES_CLIENTES = 2001,
  REVENDEDORES_PLANOS = 2002,
  REVENDEDORES_TERMINAIS = 2003,
  REVENDEDORES_CLIENTEFATURAS = 2004,
  REVENDEDORES_NOTASFISCAISREVENDA = 2005,
  REVENDEDORES_FUNCIONARIOS = 2007,
  REVENDEDORES_INDICACOES = 2006,
  REVENDEDORES_COMISSOES = 2008,
  REVENDEDORES_SIMULACAO = 2009,
  REVENDEDORES_REPRESENTANTES = 2010,
  REVENDEDORES_LICENÇAS = 2011,
  REVENDEDORES_DESEMPENHO = 2012,

  SOFTWAREHOUSE_GROUP = 3000,
  SOFTWAREHOUSE_SISTEMAS = 3001,
  SOFTWAREHOUSE_REVENDEDORES = 3002,
  SOFTWAREHOUSE_FORMAPAGAMENTO = 3003,
  SOFTWAREHOUSE_TABELAPRECO = 3004,
  SOFTWAREHOUSE_COMISSOES = 3006,
  SOFTWAREHOUSE_GERENTES = 3007,
  SOFTWAREHOUSE_REPRESENTANTES = 3008,
  SOFTWAREHOUSE_AGENTE_EXTERNO = 3009,
  SOFTWAREHOUSE_NF_REJEITADAS = 3010,
  SOFTWAREHOUSE_CLASSIFICACOES = 3011,
  SOFTWAREHOUSE_PERGUNTAS = 3012,

  CONFIGURACOES_GROUP = 4000,
  CONFIGURACOES = 4001,

  //SAIR
  MASTER_SAIR = 99999,
}
