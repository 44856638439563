import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePatchFaturaRecalcular() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const patchFaturaRecalcular = useCallback(
        async (faturaId: string) =>
            invocarApi({
                url: `/${VariaveisAmbiente.apiVersion}/fatura/${faturaId}/recalcular`,
                method: 'PATCH',
                enviarTokenUsuario: true,
                timeout: 100000
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        patchFaturaRecalcular,
    };
}