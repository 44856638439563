
import { useMemo } from 'react';
import { useValidationYupDocumento } from 'views/components/form-validations';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

export const useFormRepresentantesEdicaoValidation = () => {

  const { documentoYup } = useValidationYupDocumento(false)

  const FormRepresentanteValidationYup = useMemo(() => {
    return Yup.object().shape<AnyObject>({
      nomeComercial: Yup.string().required('O nome é obrigatório.'),
      cpfCnpj: documentoYup(),
      revendaId: Yup.string().required('Deve escolher um revendedor.').typeError('Deve escolher um revendedor'),
    });
  }, [documentoYup])

  return {
    FormRepresentanteValidationYup,
  }
}