import { Button, Grid, useTheme } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useCallback, useEffect, useRef } from 'react';
import { useToastSaurus } from 'services/app';
import { DefaultFormRefs } from 'views/components/form/utils';
import { isEmpty } from 'lodash';
import { FormCancelarConvite } from 'views/components/form/master/cancelar-fatura/form-cancelar-fatura';
import { usePutCancelar } from 'data/api/gestao/faturas';
import { FaturaCancelamentoModel } from 'model/app/forms/fatura-cancelamento/fatura-cancelamento-model';

interface DialogCancelarFaturaProps {
    openned: boolean;
    closeDialog: () => void;
    model: FaturaCancelamentoModel;
}

export const DialogCancelarFatura = ({
    openned,
    closeDialog,
    model,
}: DialogCancelarFaturaProps) => {
    const theme = useTheme();
    const { showToast } = useToastSaurus()

    const formRef = useRef<DefaultFormRefs<FaturaCancelamentoModel>>(null)

    const { putCancelar, carregando: carregandoPutCancelar } = usePutCancelar()


    const recarregarForm = useCallback((modelForm: FaturaCancelamentoModel) => {
        formRef.current?.fillForm(modelForm)
    }, [])

    useEffect(() => {
        recarregarForm(model);
    }, [model, recarregarForm])

    const handleSubmit = useCallback(async (model: FaturaCancelamentoModel) => {
        try {
            if (isEmpty(model.motivo)) throw new Error('Motivo não informado')

            const res = await putCancelar(model.id, model.motivo)

            if (res.erro) throw res.erro

            showToast('success', 'Fatura Cancelada.')

            closeDialog()
        } catch (e: any) {
            showToast('error', e.message)
        }

    }, [closeDialog, putCancelar, showToast])

    return (
        <DialogSaurus
            colorTitulo={`${theme.palette.primary.main}`}
            centralizarTitulo={true}
            aberto={openned}
            titulo='Cancelar Fatura'
            tamanho="sm"
        >
            <Grid
                container
                spacing={2}
                style={{marginBottom: '5px'}}
            >
                <Grid item xs={12}>
                    <FormCancelarConvite
                        loading={carregandoPutCancelar}
                        onSubmit={handleSubmit}
                        model={model}
                        ref={formRef}
                        showLoading={carregandoPutCancelar}
                    />
                </Grid>
                <Grid xs={6}></Grid>
                <Grid item xs={3}>
                    {closeDialog && (
                        <Button
                            disabled={carregandoPutCancelar}
                            variant="text"
                            fullWidth
                            color="primary"
                            onClick={() => {
                                closeDialog();
                            }}
                        >
                            Cancelar
                        </Button>
                    )}
                </Grid>
                <Grid item xs={3}>
                    <Button
                        disabled={carregandoPutCancelar}
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => formRef.current?.submitForm()}
                    >
                        Confirmar
                    </Button>
                </Grid>
            </Grid>
        </DialogSaurus>
    );
};
