import { useEffect, useState, useCallback } from 'react';
import { useThemeQueries } from 'views';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { Box, Button, Divider, Grid, Slider } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { IndicacoesModel } from 'model/api/gestao/indicacoes/indicacoes-model';
import { SalvarEditarIcon } from 'views/components/icons';
import { IndicacoesPutModel } from 'model/api/gestao/indicacoes/indicacoes-put-model';
import { CardIndicacoesInteresse } from 'views/components/cards/card-indicacoes-interesse';
import { DialogIndicacaoSistemasList } from 'views/components/dialog/dialog-indicacao-sistemas/dialog-indicacao-sistemas-list';
import { yupResolver } from '@hookform/resolvers/yup';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { useSessaoAtual } from 'services/app';
import { useStyles } from './accordion-interesse-styles';
import { useAccordionindicacaoInteresseValidation } from './accorfdion-interesse-validation';
import { EnumStatusIndicacao } from 'model/enums/enum-status-indicacao';

interface AccordionIndicacaoInteresseProps {
    indicacoes: IndicacoesModel;
    carreagando: boolean;
    hasValor: boolean;
    onSubmit: (valor: IndicacoesPutModel) => Promise<void>;
}

export const AccordionIndicacaoInteresse = ({
    indicacoes,
    carreagando,
    hasValor,
    onSubmit
}: AccordionIndicacaoInteresseProps) => {
    const { theme } = useThemeQueries()
    const { FormIndicacaointeresseYupValidation } = useAccordionindicacaoInteresseValidation();
    const { tipoUsuario } = useSessaoAtual()
    const classes = useStyles();

    const [openAccordion, setOpenAccordion] = useState<boolean>(true)
    const [openDialog, setOpenDialog] = useState<boolean>(false)

    const statusAlterado = useCallback((): boolean => {
        if (tipoUsuario() === EnumTipoPessoas.Revendedor)
            return false;

        if (indicacoes.status === EnumStatusIndicacao.PropostaAceita
            || indicacoes.status === EnumStatusIndicacao.PropostaRecusada
            || indicacoes.status === EnumStatusIndicacao.Cancelado)
            return false;

        return true
    }, [indicacoes.status, tipoUsuario])

    const {
        handleSubmit,
        formState: { errors },
        control,
        reset,
        setValue,
        getValues,
    } = useForm<IndicacoesModel>({
        resolver: yupResolver(FormIndicacaointeresseYupValidation),
        defaultValues: { ...indicacoes },
        criteriaMode: 'all',
        mode: 'onBlur' && 'onChange',
    });

    useEffect(() => {
        reset({ ...indicacoes })
    }, [indicacoes, reset])

    const onCardSelected = useCallback(() => {
        setOpenDialog(true)
    }, [])

    const onCloseDialog = useCallback(() => {
        setOpenDialog(false);
    }, [])

    const submit = async (valor: IndicacoesModel) => {
        const indicacaoPut: IndicacoesPutModel = {
            indicadorId: indicacoes.indicador?.id || '',
            responsavelId: indicacoes.responsavel?.id || null,
            instrucao: valor.instrucao,
            motivoAvaliacaoIndicador: indicacoes.motivoAvaliacaoIndicador,
            motivoAvaliacaoResponsavel: indicacoes.motivoAvaliacaoResponsavel,
            avaliacaoIndicador: indicacoes.avaliacaoIndicador,
            sistemaId: indicacoes.sistema.id || null,
            avaliacaoResponsavel: indicacoes.avaliacaoResponsavel,
            nivelDeInteresse: Number(valor.nivelDeInteresse),
            observacaoFinalizacao: indicacoes.observacaoFinalizacao,
            id: indicacoes.id,
            data: indicacoes.data,
            status: indicacoes.status,
            faturamentoEstimado: indicacoes.faturamentoEstimado
        }

        await onSubmit(indicacaoPut)
    }

    const marks = [
        {
            value: 0,
            label: '0%',
        },
        {
            value: 50,
            label: '50%'
        },
        {
            value: 100,
            label: '100%',
        },
    ];

    return (
        <>
            <div>
                <AccordionSaurus
                    labelPrimary="Interesse"
                    tipoExpand="bold"
                    noPaperRoot={false}
                    heightDivider={2}
                    showDivider={openAccordion}
                    colorDivider={theme.palette.primary.main}
                    colorExpand={theme.palette.primary.main}
                    expanded={openAccordion}
                    onChange={() => setOpenAccordion(prev => !prev)}
                >
                    <form onSubmit={handleSubmit(submit)} style={{ width: '100%' }}>
                        <Grid container spacing={3} style={{
                            position: 'relative'
                        }}>
                            <Divider variant='fullWidth' />
                            <Grid item xs={12}>
                                <CardIndicacoesInteresse
                                    selected={false}
                                    onCheck={() => { }}
                                    onClick={onCardSelected}
                                    model={indicacoes}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* <Controller
                                    name="nivelDeInteresse"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            readOnly={statusAlterado()}
                                            allowSubmit
                                            tipo="NUMERO"
                                            label="Nota (0 a 10) sobre a qualidade de indicação"
                                            fullWidth
                                            helperText={
                                                errors.nivelDeInteresse
                                                    ? errors.nivelDeInteresse.message
                                                    : undefined
                                            }
                                            error={Boolean(errors.nivelDeInteresse && errors.nivelDeInteresse.message)}
                                            autoComplete={'off'}
                                            {...field}
                                        />
                                    )}
                                /> */}
                                <Box style={{ padding: '0 8px' }}>
                                    <Controller
                                        control={control}
                                        name="nivelDeInteresse"
                                        render={({ field }) => (
                                            <Slider
                                                min={0}
                                                max={100}
                                                step={10}
                                                className={classes.slider}
                                                disabled={statusAlterado()}
                                                value={getValues('nivelDeInteresse')}
                                                aria-labelledby="discrete-slider-always"
                                                valueLabelDisplay={hasValor ? "on" : "auto"}
                                                marks={marks}
                                                onChange={(ev, newValue) => setValue('nivelDeInteresse', Number(newValue))}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="instrucao"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            allowSubmit
                                            readOnly={statusAlterado()}
                                            label="Instrução para Atuação"
                                            fullWidth
                                            multiline
                                            size='medium'
                                            minRows={1}
                                            maxRows={6}
                                            helperText={
                                                errors.instrucao
                                                    ? errors.instrucao.message
                                                    : undefined
                                            }
                                            error={Boolean(errors.instrucao && errors.instrucao.message)}
                                            autoComplete={'off'}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="flex-end">
                                    <Grid item xs={12} md={5} lg={4}>
                                        <Button variant="contained" color='primary' size='large' type="submit" fullWidth>
                                            <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                                            Salvar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </AccordionSaurus>
            </div>

            <DialogIndicacaoSistemasList
                model={indicacoes}
                onSubmit={onSubmit}
                opnned={openDialog}
                onCardClick={onCardSelected}
                closeDialog={onCloseDialog}

            />
        </>
    );
}