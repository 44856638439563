import { Box, Button, Grid } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-pessoa-comissao-styles';
import { CircularLoading, useThemeQueries } from 'views';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useToastSaurus } from 'services/app';
import { DefaultFormRefs } from 'views/components/form/utils';
import { isEmpty, isEqual } from 'lodash';
import { LixoIcon, SalvarIcon, VoltarIcon } from 'views/components/icons';
import { PessoaComissaoFormModel } from 'model/api/gestao/pessoa/pessoa-comissao-form-model';
import { useGetPessoaComissaoById } from 'data/api/gestao/pessoa-comissao/get-pessoa-comissao-by-id';
import { usePostAdicionarPessoaComissao } from 'data/api/gestao/pessoa-comissao/post-adicionar-pessoa-comissao';
import { usePutAtualizarPessoaComissao } from 'data/api/gestao/pessoa-comissao/put-atualizar-pessoa-comissao';
import { FormPessoaComissao } from 'views/components/form/master/pessoa/pessoa-comissao/form-pessoa-comissao';
import { useDeletePessoaComissao } from 'data/api/gestao/pessoa-comissao/delete-excluir-pessoa-comissao';
import { useConfirm } from 'material-ui-confirm';

interface DialogPessoaComissaoProps {
    openned: boolean;
    closeModal: () => void;
    id?: string;
    revendaId: string;
    carregando: boolean
}

export const DialogPessoaComissao = ({
    openned,
    closeModal,
    id,
    revendaId,
    carregando,
}: DialogPessoaComissaoProps) => {
    const classes = useStyles();
    const { showToast } = useToastSaurus()
    const confirm = useConfirm()

    const {isMobile} = useThemeQueries();

    const { getPessoaComissaoById, carregando: carregandoGet } = useGetPessoaComissaoById();
    const { postAdicionarPessoaComissao, carregando: carregandoPost } = usePostAdicionarPessoaComissao();
    const { putAtualizarPessoaComissao, carregando: carregandoPut } = usePutAtualizarPessoaComissao();
    const {deletePessoaComissao, carregando: carregandoDelete} = useDeletePessoaComissao()

    const formRef = useRef<DefaultFormRefs<PessoaComissaoFormModel>>(null)

    const [comissaoForm, setComissaoForm] = useState<PessoaComissaoFormModel>(new PessoaComissaoFormModel());
    const [nomeComissionado, setNomeComissionado] = useState<string>();

    const recarregarForm = useCallback((modelForm: PessoaComissaoFormModel) => {
        formRef.current?.fillForm(modelForm)
    }, [])

    useEffect(() => {
        recarregarForm(comissaoForm);
    }, [comissaoForm, recarregarForm])

    const pessoaComissaoWrapper = useCallback(async (id: string) => {
        try {
            const res = await getPessoaComissaoById(id, revendaId)

            if (res.erro) throw res.erro

            if (!res.resultado?.data) throw new Error('Erro ao requisitar comissão.')

            setNomeComissionado(res.resultado.data.comissionadoNome)

            setComissaoForm(res.resultado?.data)

        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getPessoaComissaoById, revendaId, showToast])

    const handleSubmit = useCallback(async (actualModel: PessoaComissaoFormModel, beforeModel?: PessoaComissaoFormModel) => {
        if (id) {
            const equal = isEqual(actualModel, beforeModel)

            if (equal) {
                return showToast('error', 'Nenhuma informação alterada.')
            }

            const res = await putAtualizarPessoaComissao(revendaId, actualModel)

            if (res.erro) throw res.erro

            showToast('success', 'Comissão atualizada com sucesso')
            closeModal();
            return
        }
        //Se for cadastrar

        const res = await postAdicionarPessoaComissao(revendaId, actualModel)

        if (res.erro) throw res.erro

        showToast('success', 'Comissão adicionada!')
        closeModal()

    }, [closeModal, id, postAdicionarPessoaComissao, putAtualizarPessoaComissao, revendaId, showToast])

    const handleDelete = useCallback(async () => {
        try{
            const res = await deletePessoaComissao(revendaId, id!);

            if(res.erro) throw res.erro

            showToast('success', 'Comissão excluída')

            closeModal()
        }catch(e: any){
            showToast('error', e.message)
        }
    }, [closeModal, deletePessoaComissao, id, revendaId, showToast])

    const confirmDelete = useCallback(() => {
        confirm({
            title: 'Confirmação',
            description: 'Tem certeza que deseja excluir a comissão?',
            cancellationText: 'Cancelar',
            confirmationText: 'Excluir'
        }).then(() => {
            handleDelete();
        })
    }, [confirm, handleDelete])

    useEffect(() => {
        if (openned && id) {
            pessoaComissaoWrapper(id);
        }
    }, [id, openned, pessoaComissaoWrapper])

    const loading = carregando || carregandoGet || carregandoPost || carregandoPut || carregandoDelete

    return (
        <DialogSaurus
            aberto={openned}
            titulo={!id ? "Cadastrar Comissão" : "Editar Comissão"}
            tamanho="sm"
        >
            {loading && <CircularLoading tipo="FULLSIZED" />}
            <Box className={classes.cardContainer}>
                <Box className={classes.cardContent}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid item xs={12}>
                                {openned && <FormPessoaComissao
                                    ref={formRef}
                                    aberto={openned}
                                    id={revendaId}
                                    loading={loading}
                                    showLoading={loading}
                                    onSubmit={handleSubmit}
                                    comissionadoNome={!isEmpty(nomeComissionado) ? nomeComissionado : undefined}
                                />}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2} style={{ paddingBottom: '7px', borderTop: '2px solid #E6E6E6' }} justifyContent='flex-end'>
                                    {id && <Grid item xs={12} md={6}>
                                        <Button variant='outlined' className={classes.buttonRemover}
                                        fullWidth={isMobile}
                                        onClick={confirmDelete}>
                                            <LixoIcon tipo='BUTTON'/>
                                            Excluir
                                        </Button>
                                    </Grid>}
                                    <Grid item xs={12} md={3}>
                                        <Button
                                            disabled={carregando}
                                            variant="outlined"
                                            fullWidth
                                            color="primary"
                                            onClick={() => {
                                                closeModal();
                                            }}
                                        >
                                            <VoltarIcon tipo='BUTTON' />
                                            Voltar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Button
                                            disabled={carregando}
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => formRef.current?.submitForm()}
                                        >
                                            <SalvarIcon tipo='BUTTON_PRIMARY' />
                                            Salvar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </DialogSaurus>
    );
};
