import { Checkbox, Grid, Typography } from '@material-ui/core';
import { DefaultCard } from '../components/default-card';
import { useStyles } from './card-comissao-pendente-styles'
import { toDateString } from 'utils/to-date';
import { CardComissaoPendenteProps } from './card-comissao-pendente-props';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { useThemeQueries } from 'views/theme';
import { useSessaoAtual } from 'services/app';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { isEmpty } from 'lodash';

export const CardComissaoPendente = ({
    model,
    onClick,
    onSelect,
    selected
}: CardComissaoPendenteProps) => {

    const classes = useStyles()
    const { isMobile } = useThemeQueries()
    const { tipoUsuario } = useSessaoAtual();

    const temPermissao = [
        EnumTipoPessoas.SoftwareHouse,
        EnumTipoPessoas.FinanceiroSoftwareHouse,
        EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto,
    ].includes(tipoUsuario());

    return (
        <>
            <DefaultCard
                isSelected={selected}
                onClick={() => {
                    onClick(model);
                }}
            >
                {(temPermissao) && <div className={classes.checkbox}>
                    <Checkbox color='primary' checked={selected} />
                </div>}
                <Grid container spacing={2} className={classes.container}>
                    <Grid item xs={6} lg={2}>
                        <Typography color="textPrimary" variant="body1" style={{ fontSize: '0.75rem' }} align={isMobile ? 'left' : 'center'}>
                            Data do Pagamento
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                            align={isMobile ? 'left' : 'center'}
                        >
                            <b>{toDateString(model.fatura.dataPagamento)}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <Typography color="textPrimary" variant="body1" style={{ fontSize: '0.75rem' }} align={isMobile ? 'left' : 'center'}>
                            Data do Vencimento
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                            align={isMobile ? 'left' : 'center'}
                        >
                            <b>{toDateString(model.fatura.dataVencimento)}</b>
                        </Typography>
                    </Grid>
                    {!isMobile && <Grid item lg={1} xs={6}>
                        <Typography color="textPrimary" variant="body1" align={isMobile ? 'left' : 'center'} style={{ fontSize: '0.75rem' }}>
                            Competência:
                        </Typography>
                        <Typography color="textPrimary" variant="body1" align={isMobile ? 'left' : 'center'}>
                            {model.fatura.competenciaAnoMes}
                        </Typography>
                    </Grid>}
                    <Grid item xs={6} lg={2}>
                        <Typography color='textPrimary' variant='body1' style={{ fontSize: '0.75rem' }} align={isMobile ? 'left' : 'center'}>
                            {formatarCPFCNPJ(model.fatura.pagadorCpfCnpj)}
                        </Typography>
                        <Typography align={isMobile ? 'left' : 'center'} color='textPrimary' variant='body1'>
                            {model.fatura.pagadorNome}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <Typography color="textPrimary" variant="body1" style={{ fontSize: '0.75rem' }} align={isMobile ? 'left' : 'center'}>
                            Sistema
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                            align={isMobile ? 'left' : 'center'}
                        >
                            {!isEmpty(model.fatura.sistemaDescricao) ? model.fatura.sistemaDescricao : '-'}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} lg={1}>
                        <Typography color='textPrimary' variant='body1' align={isMobile ? 'left' : 'right'} style={{ fontSize: '0.75rem' }}>
                            Valor
                        </Typography>
                        <Typography color='textPrimary' variant='body1' align={isMobile ? 'left' : 'right'}>
                            {model.fatura.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <Typography color='textPrimary' variant='body1' align={isMobile ? 'left' : 'right'} style={{ fontSize: '0.75rem' }}>
                            Valor Comissão
                        </Typography>
                        <Typography color='textPrimary' variant='body1' align={isMobile ? 'left' : 'right'}>
                            <b>{model.valorComissao.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b>
                        </Typography>
                    </Grid>
                </Grid>
            </DefaultCard>
        </>
    );
};
