import { useState } from 'react';
import { useDefaultCadastroStyles } from '../../cadastros/components/default-cadastro-styles';
import { SistemasHeader } from './components/sistemas-header/sistemas-header';
import { SistemasList } from './components/sistemas-list/sistemas-list';
import { ButtonFab } from 'views/components/controles';
import { NovoIcon } from 'views/components/icons';
import { Grid } from '@material-ui/core';
import { SistemasListSearchProps } from './components/sistemas-list/sistemas-list-search-props';
import { useCadastros } from 'services/app';
import { EnumSituacaoSistema } from 'model/enums/enum-situacao-sistema';

export const SistemasPage = () => {
  const classes = useDefaultCadastroStyles();
  const [openPesquisa, setOpenPesquisa] = useState(false);
  const [searchProps, setSearchProps] = useState<SistemasListSearchProps>({
    termo: '',
    situacao: EnumSituacaoSistema.Ativo
  });
  const { abrirCadastroNovoSistema } = useCadastros()

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <SistemasHeader
          openPesquisa={openPesquisa}
          setSearchProps={setSearchProps}
          setOpenPesquisa={setOpenPesquisa}
        />
      </Grid>
      <Grid className={classes.list}>
        <SistemasList searchProps={searchProps} />
      </Grid>
      <ButtonFab
        tooltip="Novo Sistema"
        icon={<NovoIcon tipo="BUTTON_FAB" />}
        onClick={() => abrirCadastroNovoSistema('', '', false)}
      />
    </Grid>
  );
};

export default SistemasPage;
