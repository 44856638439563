import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    divider: {
        background: theme.palette.primary.light,
    },
    content: {
        flex: "1",
        display: "flex",
        flexDirection: "column",
      },
}));