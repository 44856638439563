import { VariaveisAmbiente } from 'config';
import { ApiListModel } from 'model/api/gestao/api-list-model/api-list-model';
import { PessoaSistemaModel } from 'model/api/gestao/pessoa/pessoa-sistema-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetPessoaSistema() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ApiListModel<PessoaSistemaModel>>();

    const getPessoaSistema = useCallback(
        async (pessoaId: string, page: number, queryBusca: string) => {
            let queryPage = "";
            if (page) {
                queryPage = "page=" + page.toString();
            }
            const queryFinal = `${queryPage?.length > 0 || queryBusca?.length > 0 ? "?" : ""
                }${queryPage}${queryPage.length > 0 ? "&" : ""}${queryBusca}`;

            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/pessoa/${pessoaId}/sistema${queryFinal}`,
                method: 'GET',
                enviarTokenUsuario: true
            })
        }
        , [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getPessoaSistema,
    };
}
