import { Route, Switch } from 'react-router-dom';
import LogoutPage from 'views/pages/public/logout/logout-page';
import LoginPage from 'views/pages/public/login/login-page';
import RouterMain from './components/router-main/router-main';
import { ExpiredTokenRoute } from './components/expired-token-route';
import { RecoilRoot } from 'recoil';
import { GlobalStatesProvider } from 'services/app/providers/global-states-provider/global-states-provider';
import { useMemo } from 'react';
import { ValidateRoute } from './components/validate-route';
import ConsultaFaturasPage from 'views/pages/public/consulta-faturas/consulta-faturas';
import { CadastrosProvider } from 'services/app';
import PoliticaPage from 'views/pages/private/politica/politica-page';

export const RouterApp = () => {
  const switchApp = useMemo(() =>
    <Switch>
      <Route exact path="/logout" component={LogoutPage} />
      <Route
        exact
        path="/login/:parametro?"
        component={LoginPage}
      />
      <Route
        exact
        path="/cobranca"
        component={ConsultaFaturasPage}
      />
      <Route exact path="/termos" component={PoliticaPage}/>
      <ValidateRoute>
        <CadastrosProvider>
          <ExpiredTokenRoute>
            <RouterMain />
          </ExpiredTokenRoute>
        </CadastrosProvider>
      </ValidateRoute>
    </Switch>
    , []);
  return (
    <GlobalStatesProvider>
      <RecoilRoot>
        {switchApp}
      </RecoilRoot>
    </GlobalStatesProvider>
  );
};
