import { Box, Button, Grid } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-pagar-comissao-styles';
import { useThemeQueries } from 'views';
import { useCallback, useEffect, useRef } from 'react';
import { DefaultFormRefs } from 'views/components/form/utils';
import { DinheiroIcon, VoltarIcon } from 'views/components/icons';
import { ComissaoPendenteModel } from 'model/api/gestao/comissao/comissao-pendente-model';
import { toDateString } from 'utils/to-date';
import { FormComissaoPagamento } from 'views/components/form/master/comissao/comissao-pagamento/form-comissao-pagamento';
import { useCadastros, useToastSaurus } from 'services/app';
import { FaturaListData } from 'views/pages/private/faturas/components/faturas-list/faturas-list-data';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { ComissaoPostModel } from 'model/api/gestao/comissao/comissao-post-model';
import { usePostComissao } from 'data/api/gestao/comissao/post-comissao';
import { guidEmpty } from 'utils/guid-empty';
import { useHistory } from 'react-router-dom';
import { EnumTipoFatura } from 'model/enums/enum-tipo-fatura';

interface DialogPagarComissaoProps {
    openned: boolean;
    list: ComissaoPendenteModel[];
}

export const DialogPagarComissao = ({
    openned,
    list,
}: DialogPagarComissaoProps) => {

    const classes = useStyles()
    const modalClasses = useModalStyles();
    const { isMobile } = useThemeQueries();
    const { fecharPagamentoComissao } = useCadastros();
    const { showToast } = useToastSaurus();
    const { postComissao, carregando } = usePostComissao();
    const history = useHistory()

    const closeDialog = useCallback(() => {
        fecharPagamentoComissao()
    }, [fecharPagamentoComissao])

    const formRef = useRef<DefaultFormRefs<ComissaoPostModel>>(null)

    const recarregarForm = useCallback((modelForm: ComissaoPostModel) => {
        formRef.current?.fillForm(modelForm)
    }, [])

    useEffect(() => {
        if (list.length === 0) return
        const valorTotal = list.reduce((prev, curr) => curr.valorComissao + prev, 0)
        const faturasIds = list.map(fatura => fatura.id)

        const comissaoPagamento = new ComissaoPostModel(guidEmpty(), valorTotal, '', list[0].comissionadoId, toDateString(new Date()),
            true, faturasIds)

        recarregarForm(comissaoPagamento);
    }, [list, recarregarForm])

    const handleSubmit = useCallback(async (model: ComissaoPostModel) => {
        try {
            const res = await postComissao(model)

            if (res.erro) throw res.erro

            showToast('success', 'Operação realizada com sucesso.')

            closeDialog()
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [closeDialog, postComissao, showToast])

    const redirecionar = useCallback((id: string, tipo?: EnumTipoFatura) => {
        fecharPagamentoComissao()
        const faturasIds = list.map(comissao => comissao.fatura.id)
        if (tipo === EnumTipoFatura.Cliente) {
            history.push({
                pathname: `/faturas/${id}`,
                state: {
                    from: {
                        pathname: history.location.pathname,
                        comissionadoId: list[0].comissionadoId,
                        faturasIds
                    },
                }
            })
        } else {
            history.push({
                pathname: `/revenda/notas-fiscais/${id}`,
                state: {
                    from: {
                        pathname: history.location.pathname,
                        comissionadoId: list[0].comissionadoId,
                        faturasIds
                    },
                }
            })
        }
    }, [fecharPagamentoComissao, history, list])

    return (
        <DialogSaurus
            aberto={openned}
            titulo='Pagar Comissão'
            tamanho="md"
            fullScreen={isMobile}
            zIndex='1298'
            bottomArea={
                <Box className={modalClasses.acoes}>
                    <Grid container spacing={2} style={{ paddingBottom: '7px', borderTop: '2px solid #E6E6E6' }} justifyContent='flex-end'>
                        <Grid item xs={12} md={2}>
                            <Button
                                disabled={carregando}
                                variant="outlined"
                                fullWidth
                                color="primary"
                                onClick={() => {
                                    closeDialog()
                                }}
                            >
                                <VoltarIcon tipo='BUTTON' />
                                Voltar
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button
                                disabled={carregando}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={formRef.current?.submitForm}
                            >
                                <DinheiroIcon tipo='BUTTON_PRIMARY' />
                                Confirmar Pagamento
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            }
        >
            <Box className={classes.cardContainer}>
                <Box className={classes.cardContent}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid item xs={12}>
                                <FormComissaoPagamento
                                    aberto={openned}
                                    loading={carregando}
                                    onSubmit={handleSubmit}
                                    showLoading={carregando}
                                    ref={formRef}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FaturaListData
                                    carregando={carregando}
                                    list={list.map(comissao => comissao.fatura)}
                                    onCardChecked={() => { }}
                                    onCardSelected={redirecionar}
                                    selectedList={[]}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </DialogSaurus>
    );
};
