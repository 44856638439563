import { Box, Button, Grid, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { CloseIcon } from 'views/components/icons/close-icon';
import { FaturaFormModel } from 'model/app/forms/fatura/fatura-form-model';
import { FormFaturaLiquidar } from 'views/components/form/master/fatura/fatura-liquidar/form-fatura-liquidar';
import { useCallback, useEffect, useRef } from 'react';
import { DefaultFormRefs } from 'views/components/form/utils';
import { DinheiroIcon } from 'views/components/icons';
import { usePostFaturaLiquidar } from 'data/api/gestao/faturas/post-fatura-liquidar';
import { FaturaLiquidarModel } from 'model/api/gestao/fatura/fatura-liquidar-model';
import { useToastSaurus } from 'services/app';
interface DialogLiquidarProps {
    openned: boolean;
    closeDialog: () => void;
    loading: boolean;
    model: FaturaFormModel;
    preencherTela: () => Promise<void>;
}

export const DialogLiquidar = ({
    openned,
    closeDialog,
    preencherTela,
    loading,
    model
}: DialogLiquidarProps) => {

    const formRef = useRef<DefaultFormRefs<FaturaLiquidarModel>>(null)

    const { postFaturaLiquidar, carregando: carregandoPost } = usePostFaturaLiquidar()

    const { showToast } = useToastSaurus()

    const carregando = loading || carregandoPost

    useEffect(() => {
        if (openned)
            formRef.current?.fillForm(new FaturaLiquidarModel(model.id, '', model.valor, ''))
    }, [model, openned])

    const handleSubmit = useCallback(async (model: FaturaLiquidarModel) => {
        try {
            const res = await postFaturaLiquidar(model.id, model);

            if (res.erro) throw res.erro

            preencherTela()

            showToast('success', 'Fatura Liquidada')
            closeDialog()
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [closeDialog, postFaturaLiquidar, preencherTela, showToast])

    return (
        <DialogSaurus
            aberto={openned}
            titulo='Liquidar Manual'
            tamanho="sm"
            endAdornment={
                <Button onClick={closeDialog} style={{ margin: '5px 0' }}>
                    <CloseIcon tipo='BUTTON' style={{ margin: 0 }} />
                </Button>
            }
            bottomArea={
                <Box display='flex' justifyContent='flex-end' alignItems='center' px={2} pb={2}>
                    <Button variant='contained' color='primary' onClick={formRef.current?.submitForm}>
                        <DinheiroIcon tipo='BUTTON_PRIMARY' />
                        Liquidar
                    </Button>
                </Box>
            }
        >
            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography align='center' color='textSecondary'>
                                Escolha uma forma de pagamento manual para pagar a fatura.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormFaturaLiquidar
                                loading={carregando}
                                onSubmit={handleSubmit}
                                showLoading={carregando}
                                model={new FaturaLiquidarModel(model.id, '', model.valor, '')}
                                ref={formRef}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DialogSaurus>
    );
};