import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
    defaultContainer: {
        position: "relative",
        width: "100%",
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        display: 'flex',
        flexDirection: 'column'
    },
    listContainer: {
        "& .card": {
            margin: theme.spacing(1),
        }
    },
    cardPesquisa: {
        margin: theme.spacing(1),
        borderRadius: '10px',
        padding: '2rem 0',
        background: theme.palette.background.paper,
        "& form": {
            marginTop: 20
        },
        "& .celula-grid-value": {
            whiteSpace: "normal",
            fontSize: '1rem',
            [theme.breakpoints.up("lg")]: {
                fontSize: "1.2rem",
            },
        },
        "& .info-icon-pesquisa": {
            width: "42px",
            height: "42px",
            margin: theme.spacing(1),
            fill: theme.palette.text.primary,
        }
    },
    footer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        background: '#FFF',
        borderTop: '1px solid #e0e0e0',
        maxHeight: '100px',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px',
        boxShadow: '5px 10px 18px #888888',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            display: 'flex',
            background: '#FFF',
            borderTop: '1px solid #e0e0e0',
            maxHeight: '100px',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 0,
            boxShadow: '5px 10px 18px #888888'
        },
        '& p': {
            fontSize: 13
        },
        '& footer': {
            fontSize: 11
        }
    },
    textSelecionar: {
        fontSize: '16px',
        fontWeight: 400,
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
            fontWeight: 600
        }
    }
}));