import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    fieldContainer: {
        background: '#F8F8F8',
        padding: '1rem',
        borderRadius: theme.shape.borderRadius,
        '& .MuiFormControl-root': {
            background: '#fff'
        },
    },
}));
