import { Grid } from "@material-ui/core";
import { TabelaPrecosModel } from "model/api/gestao/tabela-preco/tabela-precos-model";
import { CardNaoEncontrado } from "views/components/cards";
import { CardTabelaPreco } from "views/components/cards/card-tabela-preco";
import { InformacaoIcon } from "views/components/icons";

export interface TabelaPrecoGridProps {
  list: Array<TabelaPrecosModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (id: string) => any;
  onCardChecked: (id: string) => any;
}

export const PrecificacaoListData = (props: TabelaPrecoGridProps) => {

  const onCardSelected = (id: string) => {
    props.onCardSelected(id);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <Grid item xs={12}>
          <CardNaoEncontrado
            mensagem="Nenhuma Tabela de Preços cadastrada."
            icon={<InformacaoIcon tipo="GERAL" />}
          />
        </Grid>
      )}
      {props.list.length > 0 &&
        props.list.map((tabelaPreco, index) => {
          return (
            <Grid item xs={12} md={6}>
              <CardTabelaPreco
                selected={
                  props.selectedList.filter((item) => item === tabelaPreco.id).length >
                    0
                    ? true
                    : false
                }
                onCheck={onCardChecked}
                onClick={onCardSelected}
                model={tabelaPreco}
                key={index}
              />
            </Grid>
          );
        })}
    </>
  );
};
