import { Button, Divider, Grid, makeStyles, useTheme } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useToastSaurus } from 'services/app';
import { DefaultFormRefs } from 'views/components/form/utils';
import { LixoIcon, SalvarEditarIcon, VoltarIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import { useDeleteAmbienteSistema, usePutAmbienteSistema } from 'data/api/gestao/ambiente-sistema';
import { AmbienteSistemaModel } from 'model/api/gestao/ambiente-sistema/ambiente-sistema-model';
import { FormAmbiente } from 'views/components/form/master/sistemas/ambiente/form-ambiente';

const useStyles = makeStyles((theme) => ({
    buttonRemover: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
        '& svg': {
            margin: 5,
            fill: theme.palette.error.main
        }
    },
}));

interface DialogAmbienteProps {
    openned: boolean;
    closeDialog: () => void;
    sistemaId: string;
    model: AmbienteSistemaModel
}

export const DialogAmbiente = ({
    openned,
    closeDialog,
    sistemaId,
    model
}: DialogAmbienteProps) => {
    const theme = useTheme();
    const { showToast } = useToastSaurus()

    const classes = useStyles()
    const { isMobile } = useThemeQueries()

    const { putAmbienteSistema, carregando: carregandoPut } = usePutAmbienteSistema()
    const { deleteAmbienteSistema, carregando: carregandoDelete } = useDeleteAmbienteSistema()

    const carregando = carregandoPut || carregandoDelete

    const [modelForm, setModelForm] = useState<AmbienteSistemaModel>(new AmbienteSistemaModel())

    const refForm = useRef<DefaultFormRefs<AmbienteSistemaModel>>(null)

    useEffect(() => {
        if(openned)
            setModelForm(model)
    }, [model, openned])

    useEffect(() => {
        refForm.current?.fillForm(
            modelForm
        );
    }, [modelForm]);

    const saveChange = useCallback(async (model: AmbienteSistemaModel) => {
        const res = await putAmbienteSistema(sistemaId, model)

        if (res.erro) throw res.erro

        showToast('success', 'Ambiente atualizado')
    }, [putAmbienteSistema, sistemaId, showToast])

    const handleSubmit = useCallback(async (model: AmbienteSistemaModel) => {
        try {
            await saveChange(model)
            closeDialog()
        }
        catch (err: any) {
            showToast('error', err.message)
        }
    }, [closeDialog, saveChange, showToast])

    const clickRemove = useCallback(async () => {
        try {
            const res = await deleteAmbienteSistema(sistemaId, model.id)

            if (res.erro) throw res.erro

            closeDialog()

            showToast('success', 'Ambiente excluído.')
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [closeDialog, deleteAmbienteSistema, model.id, showToast, sistemaId])


    return (
        <DialogSaurus
            colorTitulo={`${theme.palette.primary.main}`}
            aberto={openned}
            titulo="Editar Ambiente"
            tamanho='sm'
        >
            <Grid container spacing={2} style={{ marginBottom: '5px' }}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormAmbiente
                                ref={refForm}
                                model={modelForm}
                                loading={carregando}
                                showLoading={carregando}
                                onSubmit={handleSubmit}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider light />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent='flex-end'>
                        <Grid item xs={12} md={6}>
                            <Button variant='outlined' className={classes.buttonRemover} style={{ height: 36 }}
                                onClick={clickRemove} fullWidth={isMobile}>
                                <LixoIcon tipo='BUTTON_PRIMARY' />
                                Excluir
                            </Button>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Button
                                variant='outlined'
                                color='primary'
                                onClick={closeDialog}
                                fullWidth
                            >
                                <VoltarIcon tipo='BUTTON' />
                                Voltar
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button
                                disabled={carregando}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => refForm.current?.submitForm()}
                            >
                                <SalvarEditarIcon tipo='BUTTON_PRIMARY' />
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DialogSaurus>
    );
};
