import { Box, } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-contrato-styles'
import { ContratoCadastro } from './components/contrato-cadastro/contrato-cadastro';
import { ContratoEdicao } from './components/contrato-edicao/contrato-edicao';
import React, { useState } from 'react';
import { useThemeQueries } from 'views/theme';
import { isEmpty } from 'lodash';
interface DialogContratoProps {
  openned: boolean;
  id?: string;
  pessoaId: string;
  revendaId: string;
  temVendedor: boolean;
}

export const DialogContrato = ({
  openned,
  id,
  pessoaId,
  revendaId,
  temVendedor
}: DialogContratoProps) => {
  const classes = useStyles();
  const { isMobile } = useThemeQueries()
  const [mostrarFases, setMostrarFases] = useState<1 | 2>(1)
  const [status, setStatus] = useState<boolean>(false)
  const [bottomArea, setBottomArea] = useState<React.ReactNode>(null)
  const [sistemaNome, setSistemaNome] = useState<string>('')
  const [botaoFiliais, setBotaoFiliais] = useState<React.ReactNode>(null)



  return (
    <DialogSaurus
      aberto={openned}
      titulo={id ? `Editar ${!isEmpty(sistemaNome) ? sistemaNome : 'Contrato'}` : `Criar Contrato${!isEmpty(sistemaNome) ? ` - ${sistemaNome}` : ''}`}
      tamanho={mostrarFases === 2 || id ? 'xl' : 'md'}
      fullScreen={isMobile}
      bottomArea={
        bottomArea
      }
      endAdornment={!isMobile ? <>
      {botaoFiliais}
      </> : undefined}
    >
      <Box className={classes.cardContainer} style={{ minHeight: '100%' }}>
        <Box className={classes.cardContent}>
          {!id && <ContratoCadastro pessoaId={pessoaId} mostrarFases={mostrarFases} temVendedor={temVendedor}
            setMostrarFases={setMostrarFases} setBottomArea={setBottomArea} revendaId={revendaId} setSistemaNome={setSistemaNome}/>}

          {id && <ContratoEdicao id={id} pessoaId={pessoaId} setStatus={setStatus} status={status} temVendedor={temVendedor}
            setBottomArea={setBottomArea} setSistemaNome={setSistemaNome} setBotaoFiliais={setBotaoFiliais}/>}
        </Box>
      </Box>
    </DialogSaurus>
  );
};
