import { DefaultIconProps } from "./default-icon/default-icon-props";

export const DownloadIcon = (props: DefaultIconProps) => {
    return (
        <>
            <svg width="28" height="28" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
                marginRight: 5
            }}>
                <path d="M25.6916 26.437C25.6916 25.9261 25.2774 25.512 24.7665 25.512C24.2557 25.512 23.8415 25.9261 23.8415 26.437L25.6916 26.437ZM24.1124 44.6541C24.4737 45.0154 25.0594 45.0154 25.4206 44.6541L31.3075 38.7673C31.6687 38.406 31.6687 37.8203 31.3075 37.4591C30.9462 37.0978 30.3605 37.0978 29.9993 37.4591L24.7665 42.6918L19.5338 37.4591C19.1725 37.0978 18.5868 37.0978 18.2256 37.4591C17.8643 37.8203 17.8643 38.406 18.2256 38.7673L24.1124 44.6541ZM23.8415 26.437L23.8415 44L25.6916 44L25.6916 26.437L23.8415 26.437Z" fill="white" />
                <path d="M18.5948 12.2725C20.9243 8.40741 24.3552 6 28.0583 6C33.4072 6 36.6989 9.76351 37.5218 12.2725C38.3447 14.7815 38.1967 15.5908 37.9332 17.7087C40.7815 17.3269 42.0236 17.9531 43.6936 20.6359C44.6218 22.6778 44.7498 23.9476 44.1051 26.4902C43.3919 28.4181 42.0478 29.8355 40.402 30.2537C38.7562 30.6719 30.5271 30.6719 30.5271 30.6719" stroke="white" stroke-width="2.42231" stroke-linecap="round" />
                <path d="M19.4174 30.6729C19.4174 30.6729 13.3802 30.9432 10.7769 30.6729C8.17356 30.4025 6.25057 27.3276 5.42794 25.2367C4.60531 23.1458 5.01674 21.0549 6.25085 19.3824C7.48496 17.7098 9.95395 16.4552 9.95395 16.4552C9.95395 16.4552 9.95376 14.7826 11.5998 12.6917C13.2458 10.6007 16.4152 10.5918 18.5945 12.2735" stroke="white" stroke-width="2.42231" stroke-linecap="round" />
            </svg>
        </>
    );
};