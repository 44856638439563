import { forwardRef, useImperativeHandle, useState } from 'react';
import { Box, Grid, Button, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import {
    DefaultFormProps, DefaultFormRefs
} from 'views/components/form/utils/form-default-props';
import { makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { picker } from 'utils/picker';
import { } from 'data/api/gestao/pagamento';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaturaAnteciparFormModel } from 'model/api/gestao/fatura/fatura-antecipar-model';
import { useFormFaturaAnteciparValidation } from './form-antecipar-validation';
import { competenciaMesAnterior, converterDateToCompetencia } from 'utils/converter-competencia';
import {useStyles} from './form-antecipar-fatura-styles'


enum EnumModeloAntecipacaoFatura {
    FATURA_ANUAL,
    FINAL_ANO,
    MESES,
    COMPETENCIA
}

interface FaturaRefs extends DefaultFormRefs<FaturaAnteciparFormModel> {

}

interface FaturaProps extends DefaultFormProps<FaturaAnteciparFormModel> {

}


export const FormAnteciparFatura = forwardRef<
    DefaultFormRefs<FaturaAnteciparFormModel> | FaturaRefs,
    FaturaProps
>(
    (
        { loading, onSubmit, ...props }: FaturaProps,
        ref,
    ) => {
        const utilClasses = makeUtilClasses();
        const classes = useStyles()

        const { FormFaturaAnteciparValidationYup } = useFormFaturaAnteciparValidation();

        const [modelo, setModelo] = useState<EnumModeloAntecipacaoFatura>(EnumModeloAntecipacaoFatura.FATURA_ANUAL)

        const {
            handleSubmit,
            control,
            formState: { errors },
            reset,
            setValue,
            getValues
        } = useForm<FaturaAnteciparFormModel>({
            resolver: yupResolver(FormFaturaAnteciparValidationYup),
            defaultValues: {competenciaFinal: undefined, meses: 11},
            criteriaMode: 'all',
            mode: 'onBlur' && 'onChange',
        });

        const submit = (values: FaturaAnteciparFormModel) => {
            const antecipacao = picker<FaturaAnteciparFormModel>(values, new FaturaAnteciparFormModel())
            onSubmit(antecipacao);
        };

        useImperativeHandle(ref, () => ({
            submitForm: () => {
                handleSubmit(submit)();
            },
            resetForm: () => {
                reset(new FaturaAnteciparFormModel());
            },
            fillForm: (values: FaturaAnteciparFormModel) => {
                reset({ ...values})
            },
        }));

        // const calcularCompetenciaMeses = useCallback((ev: any) => {

        //     if (ev.target.name === 'meses') {
        //         const meses = Number(ev.target.value)
        //         setValue('meses', meses)
        //         if (errors.meses) return

        //         const dataAtual = isEmpty(anoMesFaturaFilha.current) ? new Date() : new Date(
        //             `-${anoMesFaturaFilha.current.substring(4)}-01-${anoMesFaturaFilha.current.substring(0, 4)}`
        //         )

        //         const dataSelecionada = new Date(dataAtual.setMonth(dataAtual.getMonth() + meses));

        //         const competenciaCalculada = Number(toDateString(dataSelecionada, 'yyyyMM'))
        //         setValue('competenciaFinal', competenciaCalculada)
        //         return
        //     }

        //     setValue('competenciaFinal', Number(ev.target.value))
        //     if (ev.target.value.length <= 5 || Number(ev.target.value.substring(4)) > 12 || Number(ev.target.value.substring(4)) < 1) {
        //         setError('competenciaFinal', { message: 'Formato Inválido' })
        //         return
        //     } else if (errors.competenciaFinal) {
        //         clearErrors('competenciaFinal')
        //     }

        //     const dataCompetencia = new Date(`${ev.target.value.substring(4)}-01-${ev.target.value.substring(0, 4)}`)

        //     if (dataCompetencia < new Date()) {
        //         setError('competenciaFinal', { message: 'Competência inferior à data atual.' })
        //         return
        //     }

        //     const monthDiff = function monthDiff(dateFrom: Date, dateTo: Date) {
        //         return dateTo.getMonth() - dateFrom.getMonth() +
        //             (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
        //     }

        //     const meses = monthDiff(new Date(), dataCompetencia) + 1

        //     setValue('competenciaFinal', Number(ev.target.value))
        //     setValue('meses', meses)

        // }, [clearErrors, errors.competenciaFinal, errors.meses, setError, setValue])

        return (
            <>
                <Box mb={2}>
                    <div className={utilClasses.formContainer}>
                        <form
                            onSubmit={handleSubmit(submit)}
                            className={loading ? utilClasses.controlLoading : ''}
                        >
                            <Box mb={3}>
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={12}>
                                        <Typography>Selecione o modelo de antecipação que deseja fazer:</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox color='primary' checked={modelo === EnumModeloAntecipacaoFatura.FATURA_ANUAL} />}
                                            value={modelo === EnumModeloAntecipacaoFatura.FATURA_ANUAL} onChange={() => {
                                                setModelo(EnumModeloAntecipacaoFatura.FATURA_ANUAL)
                                                setValue('competenciaFinal', undefined)
                                                setValue('meses', 11)
                                            }}
                                            label='Converter em Fatura Anual (12 Licenças)'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox color='primary' checked={modelo === EnumModeloAntecipacaoFatura.FINAL_ANO} />}
                                            value={modelo === EnumModeloAntecipacaoFatura.FINAL_ANO} onChange={() => {
                                                setModelo(EnumModeloAntecipacaoFatura.FINAL_ANO)
                                                setValue('meses', undefined)
                                                const dataFinalDoAno = new Date()
                                                dataFinalDoAno.setMonth(11)
                                                setValue('competenciaFinal', converterDateToCompetencia(dataFinalDoAno))
                                            }}
                                            label={`Antecipar até o fim do ano (${new Date().getFullYear()})`}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox color='primary' checked={modelo === EnumModeloAntecipacaoFatura.MESES} />}
                                            value={modelo === EnumModeloAntecipacaoFatura.MESES} onChange={() => {
                                                setModelo(EnumModeloAntecipacaoFatura.MESES)
                                                setValue('competenciaFinal', undefined)
                                                setValue('meses', undefined)
                                            }}
                                            label={
                                                <Box display='flex' alignItems='center' gridGap={8} flexWrap='wrap'>
                                                    <Typography>Antecipar</Typography>
                                                    <Controller
                                                        name="meses"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextFieldSaurus
                                                                disabled={loading || modelo !== EnumModeloAntecipacaoFatura.MESES}
                                                                tipo="NUMERO"
                                                                size='small'
                                                                allowSubmit
                                                                autoComplete={'off'}
                                                                helperText={
                                                                    errors.meses
                                                                        ? errors.meses.message
                                                                        : undefined
                                                                }
                                                                error={Boolean(
                                                                    errors.meses && errors.meses,
                                                                )}
                                                                {...field}
                                                                className={classes.textfieldMeses}
                                                                value={modelo === EnumModeloAntecipacaoFatura.MESES ? getValues('meses') : null}
                                                            />
                                                        )}
                                                    />
                                                    <Typography>Meses</Typography>
                                                </Box>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox color='primary' checked={modelo === EnumModeloAntecipacaoFatura.COMPETENCIA} />}
                                            value={modelo === EnumModeloAntecipacaoFatura.COMPETENCIA} onChange={() => {
                                                setModelo(EnumModeloAntecipacaoFatura.COMPETENCIA)
                                                setValue('competenciaFinal', undefined)
                                                setValue('meses', undefined)
                                            }}
                                            label={
                                                <Box display='flex' alignItems='center' gridGap={8} flexWrap='wrap'>
                                                    <Typography>Antecipar até a competência</Typography>
                                                    <Controller
                                                        name="competenciaFinal"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextFieldSaurus
                                                                disabled={loading || modelo !== EnumModeloAntecipacaoFatura.COMPETENCIA}
                                                                tipo="NUMERO"
                                                                placeholder={`Ex: ${competenciaMesAnterior()}`}
                                                                allowSubmit
                                                                size='small'
                                                                autoComplete={'off'}
                                                                helperText={
                                                                    errors.competenciaFinal && modelo === EnumModeloAntecipacaoFatura.COMPETENCIA
                                                                        ? errors.competenciaFinal.message
                                                                        : undefined
                                                                }
                                                                error={Boolean(
                                                                    errors.competenciaFinal && errors.competenciaFinal,
                                                                )}
                                                                {...field}
                                                                className={classes.textfieldCompetencia}
                                                                value={modelo === EnumModeloAntecipacaoFatura.COMPETENCIA ? getValues('competenciaFinal') : null}
                                                            />
                                                        )}
                                                    />
                                                </Box>
                                            }
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} md={6}>
                                        <Controller
                                            name="meses"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={loading}
                                                    label="Meses"
                                                    tipo="NUMERO_RANGE"
                                                    showStartAdornment
                                                    allowSubmit
                                                    manterMascara
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    helperText={
                                                        errors.meses
                                                            ? errors.meses.message
                                                            : undefined
                                                    }
                                                    error={Boolean(
                                                        errors.meses && errors.meses.message,
                                                    )}
                                                    {...field}
                                                    onChange={calcularCompetenciaMeses}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            name="competenciaFinal"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={loading}
                                                    label="Competência Final (Ano/Mês)"
                                                    tipo="NUMERO"
                                                    placeholder='Ex: 202306'
                                                    allowSubmit
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    helperText={
                                                        errors.competenciaFinal
                                                            ? errors.competenciaFinal.message
                                                            : undefined
                                                    }
                                                    error={Boolean(
                                                        errors.competenciaFinal && errors.competenciaFinal,
                                                    )}
                                                    {...field}
                                                    onChange={calcularCompetenciaMeses}
                                                />
                                            )}
                                        />
                                    </Grid> */}
                                </Grid>
                            </Box>
                            <Button style={{ display: 'none' }} type="submit"></Button>
                        </form>
                    </div>
                </Box>
            </>
        );
    },
);
