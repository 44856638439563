import { Redirect, Route, Switch } from 'react-router-dom';
import { MainPage } from 'views/pages/private/main/main-page';
import { MenuPrincipalProvider } from 'services/app/providers/menu-principal-provider';
import SistemasPage from 'views/pages/private/sistemas/sistemas-list-page/sistemas-list-page';
import SistemasByIdPage from 'views/pages/private/sistemas/sistemas-by-id-page/sistemas-by-id-page';
import PagamentoPage from 'views/pages/private/pagamento/pagamento';
import ClientesPage from 'views/pages/private/clientes/clientes-page';
import FuncionariosPage from 'views/pages/private/funcionarios/funcionarios-page';
import PlanosPage from 'views/pages/private/planos/planos-page';
import PrecificacaoPage from 'views/pages/private/precificacao/precificacao-page';
import FaturasPage from 'views/pages/private/faturas/faturas-page';
import { RevendedorPage } from 'views/pages/private/revendedor/revendedor-page';
import { DashboardPage } from 'views/pages/private/dashboard/dashboard-page';
import MapaClientePage from 'views/pages/private/mapa/mapa-cliente';
import ComissoesPage from 'views/pages/private/comissoes/comissoes-page';
import { TerminalPage } from 'views/pages/private/terminal/terminal-page';
import NotasFiscaisRevendaPage from 'views/pages/private/notas-fiscais-revenda/notas-fiscais-revenda';
import ConfiguracoesPage from 'views/pages/private/configuracoes/configuracoes-page';
import GerentesPage from 'views/pages/private/gerentes/gerentes-page';
import { IndicacoesPage } from 'views/pages/private/indicacoes/indicacoes-page';
import SimulacaoContratoPage from 'views/pages/private/simulacao-contrato/simulacao-contrato-page';
import RepresentantesPage from 'views/pages/private/representantes/representantes-page';
import { AgenteExternoPage } from 'views/pages/private/agente-externo/agente-externo-page';
import NotasFiscaisRejeitadasPage from 'views/pages/private/notas-fiscais-rejeitadas/notas-fiscais-rejeitadas';
import ClassificacoesPage from 'views/pages/private/classificacoes/classificacoes-page';
import LicençasList from 'views/pages/private/licenças/licenças-page';
import DesempenhoRevendasPage from 'views/pages/private/desempenho-revendas/desempenho-revendas-page';
import PerguntasApuracaoPage from 'views/pages/private/perguntas-apuracao/perguntas-apuracao-page';

export const RouterMain = () => {
  return (
    <MenuPrincipalProvider>
      <MainPage>
        <Switch>
          <Route exact path="/dashboard" component={DashboardPage} />

          <Route exact path="/dashboard/mapa-clientes" component={MapaClientePage} />
          {/*
              Sistemas 
             */}
          <Route exact path="/sistemas" component={SistemasPage} />
          <Route
            exact
            path="/sistemas/detalhes/:id"
            component={SistemasByIdPage}
          />
          <Route path="/formas-pagamento" component={PagamentoPage} />

          {/* Clientes */}

          <Route
            path="/clientes/:parametro?"
            component={ClientesPage}
          />
          <Route
            path="/funcionarios/:parametro?"
            component={FuncionariosPage}
          />
          <Route
            path="/revendedor/:parametro?"
            component={RevendedorPage}
          />
          <Route
            path="/desempenho-revendedores/:revendaId?/:mes?/:anoMes?"
            component={DesempenhoRevendasPage}
          />
          <Route 
            path="/agente-externo/:parametro?"
            component={AgenteExternoPage}
          />
          <Route
            path="/cliente-ideal"
            component={PerguntasApuracaoPage}
          />
          {/* ---------- */}

          <Route path="/planos/" component={PlanosPage} />
          <Route path="/indicacoes/:status/:parametro?/:tela?" component={IndicacoesPage} />
          <Route path="/tabela-precos/:parametro?" component={PrecificacaoPage} />
          <Route path="/terminais" component={TerminalPage} />
          <Route path="/faturas/:parametro?" component={FaturasPage} />
          <Route path='/licenças/:parametro?' component={LicençasList}/>
          <Route path="/revenda/notas-fiscais/:parametro?" component={NotasFiscaisRevendaPage} />
          <Route path="/gerentes/:parametro?" component={GerentesPage}/>
          <Route path='/representantes/:parametro?' component={RepresentantesPage}/>
          <Route path="/comissoes" component={ComissoesPage}/>
          <Route path="/simulacao-contrato" component={SimulacaoContratoPage}/>
          <Route path="/notas-fiscais-rejeitadas/:parametro?" component={NotasFiscaisRejeitadasPage}/>
          <Route path="/classificacoes" component={ClassificacoesPage}/>
          <Route path="/configuracoes" component={ConfiguracoesPage} />
          <Route path="/">
            <Redirect to="/dashboard" />
          </Route>
        </Switch>
      </MainPage>
    </MenuPrincipalProvider>
  );
};
export default RouterMain;
