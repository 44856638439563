import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useStyles } from './card-desempenho-revenda-styles'
import { CardRevendaDesempenhoAnoMesProps } from './card-desempenho-anomes-props';
import { useThemeQueries } from 'views/theme';
import { useCallback } from 'react';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';

export const CardDesempenhoRevendaAnoMesModel = ({
    model,
}: CardRevendaDesempenhoAnoMesProps) => {
    const classes = useDefaultCardStyles();
    const listClasses = useStyles({});
    const { theme } = useThemeQueries();

    const retornarTooltip = useCallback(() => {
        switch (model.ativo) {
            case 'SIM':
                return {
                    color: theme.palette.success.main,
                    title: 'Ativo'
                }
            default:
                return {
                    color: theme.palette.error.main,
                    title: 'Inativo'
                }
        }
    }, [model.ativo, theme.palette.error.main, theme.palette.success.main])

    return (
        <>
            <DefaultCard>
                <Tooltip title={retornarTooltip().title} placement='right'>
                    <div style={{
                        backgroundColor: retornarTooltip().color,
                        width: '5px',
                        borderRadius: '5px',
                        alignSelf: 'stretch',
                        margin: '7px'
                    }} />
                </Tooltip>
                <div className={`${classes.cardContent} ${listClasses.container}`}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} md={3}>
                            <Typography color="textPrimary" variant="caption">
                                Cliente
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                style={{ fontWeight: 500 }}
                            >
                                {model.cliente}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Typography color="textPrimary" variant="caption">
                                Documento
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                style={{ fontWeight: 500 }}
                            >
                                {formatarCPFCNPJ(model.clienteCpfCnpj)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Typography color="textPrimary" variant="caption">
                                <Box display='flex' gridGap={5}>
                                    Sistema
                                </Box>
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                style={{ fontWeight: 500 }}
                            >
                                {model.sistema}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Typography color="textPrimary" variant="caption">
                                CNPJ's
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                style={{ fontWeight: 500 }}
                            >
                                {model.totalCNPJ}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Typography color="textPrimary" variant="caption">
                                Terminais Contratados
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                style={{ fontWeight: 500 }}
                            >
                                {model.terminaisContratados}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </DefaultCard>
        </>
    );
};