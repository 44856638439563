import { Grid } from "@material-ui/core";
import PrecificacaoEdit from "./components/precificacao-edit/precificacao-edit";

type Props = {
  id: string
}

export const PrecificacaoEditar = ({ id }: Props) => {

  return (
    <div style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    }}>
      <Grid container spacing={1}
        style={{
          maxWidth: 1400,
          padding: 0,
        }}>
            <PrecificacaoEdit id={id}/>
      </Grid>
    </div>
  );
};
