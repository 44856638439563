import { EnumGraficosDashboard } from "model/enums/enum-graficos-dashboard";
import { useEffect, useMemo, useState } from "react";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus";
import { useThemeQueries } from "views/theme";
import { RelatorioSearchPropsModel } from "model/api/gestao/relatorios/relatorio-search-props-model";
import { ResultadoRevendaModel } from "model/api/gestao/relatorios/resultado-revenda-model";
import { Box, Grid, Typography } from "@material-ui/core";
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus";
import { GraficosGerenteMock } from "data/mocks/graficos-mock";
import { isEmpty } from "lodash";
import ReceitaGerente from "./components/receita-gerente/receita-gerente";
import { contarMesesEntreCompetencias } from "utils/converter-competencia";
import { AutocompleteGerentes } from "views/components/controles/autocompletes/autocomplete-gerentes/autocomplete-gerentes";
import { retornoAutoComplete } from "views/components/controles/autocompletes/autocomplete-saurus/autocomplete-saurus";
import { useSessaoAtual } from "services/app";

type Props = {
    resultadoRevenda: ResultadoRevendaModel[];
    searchProps: RelatorioSearchPropsModel;
    isGerente: boolean;
    carregando: boolean;
}

export const AccordionGerente = ({ resultadoRevenda, searchProps, isGerente, carregando, ...props }: Props) => {

    const { theme } = useThemeQueries();

    const [openAccordion, setOpenAccordion] = useState<boolean>(false)
    const { usuario } = useSessaoAtual()

    const [gerenteId, setGerenteId] = useState<string>(isGerente ? usuario!.Id : (searchProps.gerenteId || ''))

    const [switchGrafico, setSwitchGrafico] = useState<EnumGraficosDashboard>(EnumGraficosDashboard.ReceitaGerente)

    const displayGrafico = useMemo(() => {
        if(carregando) return <></>
        switch (switchGrafico) {
            case EnumGraficosDashboard.ReceitaGerente:
                return isEmpty(gerenteId) ? (
                    <Box my={2}>
                        <Typography align='center' variant='h6' color='textSecondary'>Selecione um Gerente para visualizar a receita.</Typography>
                    </Box>
                ) : (
                    <ReceitaGerente
                        meses={contarMesesEntreCompetencias(searchProps.anoMesInicial, searchProps.anoMesFinal)}
                        resultadoRevenda={resultadoRevenda}
                        gerenteId={gerenteId}
                    />
                )
        }
    }, [carregando, gerenteId, resultadoRevenda, searchProps.anoMesFinal, searchProps.anoMesInicial, switchGrafico])

    useEffect(() => {
        setGerenteId(isGerente ? usuario!.Id : (searchProps.gerenteId || ''))
    }, [isGerente, searchProps.gerenteId, usuario])

    if (resultadoRevenda.length < 1 && !carregando) {
        return (
            <Typography variant='h6' align='center'>Não foram encontrados dados.</Typography>
        )
    }

    return (
        <>
            <AccordionSaurus
                labelPrimary="Relatórios"
                tipoExpand="bold"
                noPaperRoot={false}
                heightDivider={2}
                showDivider={openAccordion}
                colorDivider={theme.palette.primary.main}
                colorExpand={theme.palette.primary.main}
                expanded={openAccordion}
                onChange={() => setOpenAccordion(prev => !prev)}
            >
                <Grid container spacing={2}>
                    <Grid item xs={isGerente ? 12 : 6}>
                        <SelectSaurus
                            label='Relatório'
                            conteudo={GraficosGerenteMock}
                            value={switchGrafico}
                            onChange={(e: any) => {
                                const item = GraficosGerenteMock.find(element => element.Key === e.target.value)
                                if (item) {
                                    setSwitchGrafico(item.Key)
                                }
                            }}
                        />
                    </Grid>
                    {!isGerente && (
                        <Grid item xs={6}>
                            <AutocompleteGerentes
                                fetchOnLoad
                                value={gerenteId}
                                label='Gerente'
                                size="medium"
                                onChange={(e: retornoAutoComplete) => {
                                    setGerenteId(e.value.id)
                                }}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        {openAccordion && displayGrafico}
                    </Grid>
                </Grid>
            </AccordionSaurus>
        </>
    );
};