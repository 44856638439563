import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetIndicacoesExportarExcel() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getIndicacoesExportarExcel = useCallback(
        async (queryBusca: string) => {
            const queryFinal = `${queryBusca?.length > 0 ? "?" : ""
                }${queryBusca}`;
            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Indicacao/excel${queryFinal}`,
                method: 'GET',
                enviarTokenUsuario: true,
                responseType: 'blob',
                headers: { 
                    "Content-Type": "application/vnd.ms-excel", 
                    "Content-Disposition": "attachment; filename=IndicacaoPagas-2023-03-14.xls; filename*=UTF-8''IndicacaoPagas-2023-03-14.xls"
                },
            })
        }
        , [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getIndicacoesExportarExcel,
    };
}
