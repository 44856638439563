import { useCallback, useEffect, useState } from 'react';
import { useThemeQueries } from 'views';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { Box, Button, Divider, Grid, Slider } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { IndicacoesModel } from 'model/api/gestao/indicacoes/indicacoes-model';
import { SalvarEditarIcon } from 'views/components/icons';
import { IndicacoesPutModel } from 'model/api/gestao/indicacoes/indicacoes-put-model';
import { yupResolver } from '@hookform/resolvers/yup';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { useSessaoAtual } from 'services/app';
import { useAvaliacaoRevendaValidation } from './indicacao-revenda-validation';
import { useStyles } from './accordion-revenda-styles';
import { EnumStatusIndicacao } from 'model/enums/enum-status-indicacao';

interface AccordionIndicacaoAvaliacaoRevendaProps {
    indicacoes: IndicacoesModel;
    carreagando: boolean;
    hasValor: boolean;
    onSubmit: (valor: IndicacoesPutModel) => Promise<void>;
}

export const AccordionIndicacaoAvaliacaoRevenda = ({
    indicacoes,
    carreagando,
    hasValor,
    onSubmit
}: AccordionIndicacaoAvaliacaoRevendaProps) => {
    const { theme } = useThemeQueries()
    const { FormAvalicacaoRevendaValidationYup } = useAvaliacaoRevendaValidation();
    const { tipoUsuario } = useSessaoAtual()
    const classes = useStyles();

    const statusAlterado = useCallback((): boolean => {
        if (tipoUsuario() === EnumTipoPessoas.Revendedor)
            return true;

        if (indicacoes.status === EnumStatusIndicacao.PropostaAceita
            || indicacoes.status === EnumStatusIndicacao.PropostaRecusada
            || indicacoes.status === EnumStatusIndicacao.Cancelado)
            return false;

        return true
    }, [indicacoes.status, tipoUsuario])

    const [openAccordion, setOpenAccordion] = useState<boolean>(true)

    const {
        handleSubmit,
        formState: { errors },
        control,
        reset,
        getValues,
        setValue,
    } = useForm<IndicacoesModel>({
        defaultValues: { ...indicacoes },
        resolver: yupResolver(FormAvalicacaoRevendaValidationYup),
        criteriaMode: 'all',
        mode: 'onBlur' && 'onChange',
    });

    useEffect(() => {
        reset({ ...indicacoes })
    }, [indicacoes, reset])

    const submit = async (valor: IndicacoesModel) => {
        const indicacaoPut: IndicacoesPutModel = {
            indicadorId: indicacoes.indicador?.id || '',
            responsavelId: indicacoes.responsavel?.id || null,
            instrucao: indicacoes.instrucao,
            motivoAvaliacaoIndicador: indicacoes.motivoAvaliacaoIndicador,
            motivoAvaliacaoResponsavel: valor.motivoAvaliacaoResponsavel,
            avaliacaoIndicador: indicacoes.avaliacaoIndicador,
            sistemaId: indicacoes.sistema.id || null,
            avaliacaoResponsavel: Number(valor.avaliacaoResponsavel),
            nivelDeInteresse: indicacoes.nivelDeInteresse,
            observacaoFinalizacao: indicacoes.observacaoFinalizacao,
            id: indicacoes.id,
            data: indicacoes.data,
            status: indicacoes.status,
            faturamentoEstimado: indicacoes.faturamentoEstimado
        }

        await onSubmit(indicacaoPut)
    }

    const marks = [
        {
            value: 0,
            label: '0',
        },
        {
            value: 10,
            label: '10',
        },
    ];

    return (
        <>
            <div>
                <AccordionSaurus
                    labelPrimary="Avaliação da Revenda"
                    tipoExpand="bold"
                    noPaperRoot={false}
                    heightDivider={2}
                    showDivider={openAccordion}
                    colorDivider={theme.palette.primary.main}
                    colorExpand={theme.palette.primary.main}
                    expanded={openAccordion}
                    onChange={() => setOpenAccordion(prev => !prev)}
                >
                    <form onSubmit={handleSubmit(submit)} style={{ width: '100%' }}>
                        <Grid container spacing={3} style={{
                            position: 'relative'
                        }}>
                            <Divider variant='fullWidth' />
                            <Grid item xs={12}>
                                {/* <Controller
                                    name="avaliacaoResponsavel"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            allowSubmit
                                            tipo="NUMERO"
                                            label="Nota da Avaliação"
                                            readOnly={statusAlterado()}
                                            fullWidth
                                            helperText={
                                                errors.avaliacaoResponsavel
                                                    ? errors.avaliacaoResponsavel.message
                                                    : undefined
                                            }
                                            error={Boolean(errors.avaliacaoResponsavel && errors.avaliacaoResponsavel.message)}
                                            autoComplete={'off'}
                                            {...field}
                                        />
                                    )}
                                /> */}
                                <Box style={{ padding: '0 8px' }}>
                                    <Controller
                                        control={control}
                                        name="avaliacaoResponsavel"
                                        render={({ field }) => (
                                            <Slider
                                                min={0}
                                                max={10}
                                                className={classes.slider}
                                                disabled={statusAlterado()}
                                                value={getValues('avaliacaoResponsavel')}
                                                aria-labelledby="discrete-slider-always"
                                                valueLabelDisplay={hasValor ? "on" : "auto"}
                                                marks={marks}
                                                onChange={(ev, newValue) => setValue('avaliacaoResponsavel', Number(newValue))}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="motivoAvaliacaoResponsavel"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            allowSubmit
                                            label="Motivo da Avaliação"
                                            fullWidth
                                            readOnly={statusAlterado()}
                                            multiline
                                            size='medium'
                                            min={1}
                                            maxRows={6}
                                            helperText={
                                                errors.motivoAvaliacaoResponsavel
                                                    ? errors.motivoAvaliacaoResponsavel.message
                                                    : undefined
                                            }
                                            error={Boolean(errors.motivoAvaliacaoResponsavel && errors.motivoAvaliacaoResponsavel.message)}
                                            autoComplete={'off'}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="flex-end">
                                    <Grid item xs={12} md={5} lg={4}>
                                        <Button variant="contained" color='primary' size='large' type="submit" fullWidth>
                                            <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                                            Salvar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </AccordionSaurus>
            </div>
        </>
    );
}