
import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles<Theme, {isSoftwareHouse: boolean}>((theme) => ({
  root: {
    width: "100%",
    margin: 0,
    position: "relative",
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    background: theme.palette.common.white,
    [theme.breakpoints.up("md")]: {
      borderTopWidth: 1,
      borderTopStyle: "solid",
      borderTopColor: theme.palette.grey[300],
      background: theme.palette.grey[50],
    },
    '& > div': {
      padding: '5px'
    }
  },

  containerFinal: {
    display: "flex",
    justifyContent: "space-between",

  },
  btnPesquisa: {
    width: "100%",
    minWidth: "40px",
    height: "40px",
    padding: 0,
    "& svg": {
      margin: 0,
    },
    [theme.breakpoints.down('md')]: {
      width: props => props.isSoftwareHouse ? '35%' : '100%',
      alignSelf: 'flex-end'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }

}));