import { guidEmpty } from "utils/guid-empty";
import {PagamentoModel} from '../pagamento/pagamento-model'

export class PessoaPagamentoModel {
    constructor(
        public id: string = guidEmpty(),
        public pessoaId: string = guidEmpty(),
        public formaPagamento: PagamentoModel = new PagamentoModel(),
        public padrao: boolean = false,
    ) { }
}