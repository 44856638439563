import { useCallback, useMemo, useState } from "react";
import { PrivatePageHeader } from "views/components/headers";
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { DesfazerIcon, FiltroIcon, MenuIcon } from "views/components/icons";
import { useMenuPrincipal } from "services/app";
import { useThemeQueries } from "views";
import { PesquisaClassificacoes } from "./components/pesquisa-classificacoes";

interface ClassificacaoHeaderProps {
    setQuery: (query: string) => void;
}

export const ClassificacoesHeader = ({ setQuery }: ClassificacaoHeaderProps) => {
    const { abrirMenu } = useMenuPrincipal();
    const { isMobile } = useThemeQueries();

    const [openPesquisa, setOpenPesquisa] = useState<boolean>(false)

    const onPesquisar = useCallback((value: string) => {
        setQuery(value)
    }, [setQuery])

    const leftArea = useCallback(
        () =>
            isMobile ? (<ButtonPrivateHeader
                icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
                tooltip="Menu"
                onClick={abrirMenu}
            ></ButtonPrivateHeader>) : null,
        [isMobile, abrirMenu]
    );

    const rightArea = useMemo(() => (!isMobile ?
        <PesquisaClassificacoes
            isOpened
            onCloseSearch={() => { }}
            onPesquisar={onPesquisar}
        /> : <ButtonPrivateHeader
            icon={
                openPesquisa ? (
                    <DesfazerIcon tipo="PRIVATE_HEADER"></DesfazerIcon>
                ) : (
                    <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>
                )
            }
            tooltip={!openPesquisa ? "Pesquisar" : "Voltar"}
            onClick={() => setOpenPesquisa(!openPesquisa)}
        ></ButtonPrivateHeader>
    ), [isMobile, onPesquisar, openPesquisa])

    const bottomArea = useMemo(() => (
        <PesquisaClassificacoes
            isOpened={openPesquisa}
            onCloseSearch={() => setOpenPesquisa(false)}
            onPesquisar={onPesquisar}
        />
    ), [onPesquisar, openPesquisa])

    return (
        <>
            <PrivatePageHeader
                title='Classificações'
                leftArea={leftArea()}
                rightArea={rightArea}
                bottomArea={isMobile ? bottomArea : undefined}
            />
        </>
    );
};