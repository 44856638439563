import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"
export const IndicacaoIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} onClick={props.onClick} style={props.style} fill={props.fill}>
                <path d="M44.3438 2C43.4023 2 42.4336 2.34766 41.7188 3.0625L40.375 4.4375L45.5625 9.625C45.5586 9.62891 46.9375 8.28125 46.9375 8.28125C48.3711 6.84766 48.3711 4.49609 46.9375 3.0625C46.2188 2.34375 45.2852 2 44.3438 2ZM38.75 5.9375L16.0312 28.6562L15.9688 28.9688L15.0312 33.8125L14.7188 35.2812L16.1875 34.9688L21.0312 34.0312L21.3438 33.9688L44.0625 11.25L42.625 9.84375L20.375 32.0625L17.9375 29.625L40.1562 7.375L38.75 5.9375ZM3 10C2.44531 10 2 10.4492 2 11V47C2 47.5508 2.44531 48 3 48H39C39.5547 48 40 47.5508 40 47V18L38 20V46H4V12H30L32 10H3Z" />
            </DefaultIcon>
        </>
    );
};
