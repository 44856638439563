import { Grid, Tooltip, Typography } from '@material-ui/core';
import { useCallback } from 'react';
import { EditarIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { CardAGerarProps } from './card-a-gerar-props';
import { useStyles } from './card-a-gerar-styles'

export const CardAGerar = ({
  onClick,
}: CardAGerarProps) => {
  const classes = useDefaultCardStyles();
  const listClasses = useStyles();
  const { theme, isMobile } = useThemeQueries();

  const retornarCorStatus = useCallback((): string => {
    return theme.palette.info.main
  }, [theme.palette.info.main]);

  const getNextMonth = (): string =>  {
    const currentDate: Date = new Date();
    const nextDate: Date = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1);
    const nextMonth: string = (nextDate.getMonth() + 1).toString().padStart(2, '0');
    const nextYear: number = nextDate.getFullYear();
    return `${nextYear}${nextMonth}`;
  }

  return (
    <>
      <DefaultCard
        onClick={() => {
          onClick(true);
        }}
      >
        <Tooltip arrow title={'Visualizar as próximas faturas'} placement="right">
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        <div className={`${classes.cardContent} ${listClasses.container}`}>
          <Grid container spacing={isMobile ? 1 : 2}>
            <Grid item xs={12} md={3} lg={3}>
              <Typography color="textPrimary" variant="caption">
                Nº a gerar
              </Typography>
              <Typography
                color="textPrimary"
                variant="body1"
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                <b>Licenças à Faturar</b>
              </Typography>
            </Grid>
            <Grid item xs={4} md={1} lg={2}>
            <Typography color="textPrimary" variant="caption">
                Ano/Mês
              </Typography>
              <Typography
                color="textPrimary"
                variant="body1"
              >
                <b>{getNextMonth()}</b>
              </Typography>
            </Grid>
           <Grid item xs={4} md={2} lg={2}>
              <Typography color="textPrimary" variant="caption">
                Emissão
              </Typography>
              <Typography
                color="textPrimary"
                variant="body1"
              >
                <b>Próximo dia 02</b>
              </Typography>
            </Grid>
            <Grid item xs={4} md={2} lg={2}>
              <Typography color="textPrimary" variant="caption">
                Vencimento
              </Typography>
              <Typography
                color="textPrimary"
                variant="body1"
              >
                <b>Próximo dia 05</b>
              </Typography>
            </Grid>
            <Grid item xs={4} md={2} lg={1} className={listClasses.valores}>
              <Typography color="textPrimary" variant="caption">
                Valor
              </Typography>
              <Typography
                color="textPrimary"
                variant="body1"
              >
                <b>A gerar</b>
              </Typography>
            </Grid>
            <Grid item xs={4} md={2} lg={2} className={listClasses.valores}>
              <Typography color="textPrimary" variant="caption">
                Valor Pago
              </Typography>
              <Typography
                color="textPrimary"
                variant="body1"
              >
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Tooltip arrow title="Editar Fatura">
          <div className={classes.cardRightButton} style={{ margin: '0px 0px 0px 10px' }}>
            <EditarIcon tipo="BUTTON" fill={theme.palette.primary.main} />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
