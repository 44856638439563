import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  TextFieldSaurus,

} from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { PlanoFormModel } from 'model/app/forms/plano/plano-form-model';
import { useGetSistemas, useGetSistemasById } from 'data/api/gestao/sistemas';
import { SistemasModel } from 'model/api/gestao/sistemas/sistemas-model';
import { KeyValueModel } from 'model';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { ModuloPlanoModel } from 'model/api/gestao/plano/modulo-plano-model';
import { SistemaModel } from 'model/api/gestao/sistemas/sistema-model';
import { useToastSaurus } from 'services/app';
import { isEmpty } from 'lodash';
import { picker } from 'utils/picker';
import { guidEmpty } from 'utils/guid-empty';
import { useFormCadastrarPlanoValidation } from './form-plano-validation';
import { yupResolver } from '@hookform/resolvers/yup';

export const FormPlanoCadastro = forwardRef<
  DefaultFormRefs<PlanoFormModel>,
  DefaultFormProps<PlanoFormModel>
>(
  (
    { loading, onSubmit, ...props }: DefaultFormProps<PlanoFormModel>,
    ref,
  ) => {
    const { isMobile } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);
    const [sistemasMock, setSistemasMock] = useState<KeyValueModel[]>([])
    const [sistemaId, setSistemaId] = useState<string>('')
    const { getSistemas, carregando: carregandoGet } = useGetSistemas()
    const { getSistemasById } = useGetSistemasById()
    const [modelForm, setModelForm] = useState<PlanoFormModel>(new PlanoFormModel())
    const [modulos, setModulos] = useState<ModuloPlanoModel[]>([])
    const { showToast } = useToastSaurus()
    const { FormCadastrarPlanoValidationYup } = useFormCadastrarPlanoValidation()

    const {
      handleSubmit,
      control,
      formState: { errors },
      reset,
      setValue,
    } = useForm<PlanoFormModel>({
      resolver: yupResolver(FormCadastrarPlanoValidationYup),
      criteriaMode: 'all',
      mode: 'onBlur' && 'onChange',
    });

    const submitAlterarDados = (values: PlanoFormModel) => {
      values.modulos = modulos.map(item => picker<ModuloPlanoModel>(item, new ModuloPlanoModel()))

      onSubmit(values, modelForm);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitAlterarDados)();
      },
      resetForm: () => {
        reset(new PlanoFormModel());
        setSistemaId('')
        setValue('sistemaId', '')
        if (!isMobile) refInputNome.current?.focus();
      },
      fillForm: (model: PlanoFormModel) => {
        setModulos(model.modulos)
        setModelForm(model)
        reset({ ...model });
        if (!isMobile) refInputNome.current?.focus();
      },
    }));

    const getSistemasWrapper = useCallback(async () => {
      const res = await getSistemas(1, '&pageSize=0&situacao=0')

      if (res.erro) throw res.erro

      return res.resultado?.data.list as SistemasModel[]
    }, [getSistemas])

    const getSistemaByIdWrapper = useCallback(async () => {
      const res = await getSistemasById(sistemaId)

      if (res.erro) throw res.erro

      const ret = res.resultado?.data as SistemaModel

      const modulosPlano = ret.modulos.map(item => {
        const moduloAjustado: ModuloPlanoModel = {
          codModulo: item.codigo,
          id: guidEmpty(),
          moduloId: item.id,
          nomeModulo: item.nome,
          quantidadeContratada: item.quantidadePadrao,
          quantidadeMaxima: item.quantidadeMaxima,
          quantidadeMinima: item.quantidadePadrao,
          quantidadePadrao: item.quantidadeMinima
        }
        return moduloAjustado
      })
      setModulos(modulosPlano)

    }, [getSistemasById, sistemaId])

    useEffect(() => {
      getSistemasWrapper()
        .then(res => {
          const items = res.map(item => {
            return new KeyValueModel(item.id, item.nome)
          })
          setSistemasMock([...items])
        })
        .catch(err => showToast('error', err.message))
    }, [getSistemasWrapper, showToast])

    useEffect(() => {
      if (!isEmpty(sistemaId)) {
        getSistemaByIdWrapper()
          .catch(err => showToast('error', err.message))
      }
    }, [getSistemaByIdWrapper, showToast, sistemaId])

    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {loading && props.showLoading ? (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="FULLSIZED" />
              </div>
            ) : null}

            <form
              onSubmit={handleSubmit(submitAlterarDados)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Box my={3}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Controller
                      name="nome"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          inputRef={refInputNome}
                          disabled={loading}
                          allowSubmit={false}
                          id="nome"
                          label="Nome"
                          fullWidth
                          autoComplete={'off'}
                          helperText={
                            errors.nome
                              ? errors.nome.message
                              : undefined
                          }
                          error={Boolean(errors.nome && errors.nome.message)}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="descricao"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          disabled={loading}
                          allowSubmit={false}
                          label="Descrição"
                          fullWidth
                          autoComplete={'off'}
                          helperText={
                            errors.descricao
                              ? errors.descricao.message
                              : undefined
                          }
                          error={Boolean(errors.descricao && errors.descricao.message)}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Controller
                      name="sistemaId"
                      control={control}
                      render={({ field }) => (
                        <SelectSaurus
                          disabled={loading || carregandoGet}
                          allowSubmit={false}
                          label="Sistema"
                          fullWidth
                          autoComplete={'off'}

                          helperText={
                            errors.sistemaId
                              ? errors.sistemaId.message
                              : undefined
                          }

                          error={Boolean(errors.sistemaId && errors.sistemaId.message)}
                          {...field}
                          conteudo={sistemasMock}
                          value={
                            sistemaId
                          }
                          onChange={ev => {
                            const item = sistemasMock.filter(item => ev.target.value === item.Key)
                            setValue('sistemaId', item[0].Key)
                            setSistemaId(item[0].Key)
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
