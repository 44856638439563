import { Button, Grid } from '@material-ui/core';
import { useState, useImperativeHandle, forwardRef } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses } from 'views';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { picker } from 'utils/picker';
import { Controller, useForm } from 'react-hook-form';
import { FaturaCancelamentoModel } from 'model/app/forms/fatura-cancelamento/fatura-cancelamento-model';
import { useFormCancelamentoFaturaValidation } from './form-cancelar-fatura-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';

export const FormCancelarConvite = forwardRef<
  DefaultFormRefs<FaturaCancelamentoModel>,
  DefaultFormProps<FaturaCancelamentoModel>
>(({ loading, ...props }: DefaultFormProps<FaturaCancelamentoModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const [modelForm, setModelForm] = useState<FaturaCancelamentoModel>(
    new FaturaCancelamentoModel(),
  );

  const {FormCancelamentoFaturaYupValidation} = useFormCancelamentoFaturaValidation()

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields,  },
    reset,
  } = useForm<FaturaCancelamentoModel>({
    defaultValues: { ...modelForm },
    criteriaMode: 'all',
    resolver: yupResolver(FormCancelamentoFaturaYupValidation),
    mode: 'onChange',
  });

  const onSubmit = async (values: FaturaCancelamentoModel) => {
    const model = picker<FaturaCancelamentoModel>(values, new FaturaCancelamentoModel());
    
    if(isEmpty(model.motivo)){
      model.motivo = 'Não informado pelo usuário!';
    }
      
    props.onSubmit(model, modelForm);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
    },
    fillForm: (model: FaturaCancelamentoModel) => {
      setModelForm(model);
      reset({ ...model });
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {loading && props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={loading ? utilClasses.controlLoading : ''}
        >
          <Grid container style={{ maxWidth: '100%' }}>
            <Grid item xs={12}>
              <Controller
                name="motivo"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    fullWidth
                    disabled={loading}
                    label="Motivo do cancelamento"
                    variant="outlined"
                    error={Boolean(errors.motivo && errors.motivo.message && touchedFields.motivo)}
                    helperText={
                      touchedFields.motivo && errors.motivo
                        ? errors.motivo?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
