import { Button, Grid, useTheme } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useCallback, useEffect, useRef } from 'react';
import { useCadastros, useToastSaurus } from 'services/app';
import { DefaultFormRefs } from 'views/components/form/utils';
import { isEmpty } from 'lodash';
import { ContratoCancelamentoModel } from 'model/app/forms/contrato-cancelamento/contrato-cancelamento-model';
import { useDeleteDeletarContrato } from 'data/api/gestao/contratos';
import { FormCancelarContrato } from 'views/components/form/master/cancelar-contrato/form-cancelar-contrato';
import { useThemeQueries } from 'views/theme';
import { OkIcon, VoltarIcon } from 'views/components/icons';

interface DialogCancelarFaturaProps {
    openned: boolean;
    closeDialog: () => void;
    model: ContratoCancelamentoModel;
}

export const DialogCancelarContrato = ({
    openned,
    closeDialog,
    model,
}: DialogCancelarFaturaProps) => {
    const theme = useTheme();
    const { showToast } = useToastSaurus()

    const { isMobile } = useThemeQueries()

    const { fecharCadastroContrato } = useCadastros()

    const formRef = useRef<DefaultFormRefs<ContratoCancelamentoModel>>(null)

    const { deleteDeletarContrato, carregando: carregandoDelete } = useDeleteDeletarContrato()


    const recarregarForm = useCallback((modelForm: ContratoCancelamentoModel) => {
        formRef.current?.fillForm(modelForm)
    }, [])

    useEffect(() => {
        recarregarForm(model);
    }, [model, recarregarForm])

    const handleSubmit = useCallback(async (model: ContratoCancelamentoModel) => {
        try {
            console.log(model, 'oi');
            if (isEmpty(model.motivoCancelamento)) throw new Error('Mais informações não foi Informado.');
            if (model.indCancelamento === undefined) throw new Error('Motivo do Cancelamento não foi Informado.');

            const res = await deleteDeletarContrato(model)

            if (res.erro) throw res.erro

            showToast('success', 'Contrato Cancelado.')

            fecharCadastroContrato()

        } catch (e: any) {
            showToast('error', e.message)
        }
        closeDialog()
    }, [closeDialog, deleteDeletarContrato, fecharCadastroContrato, showToast])

    return (
        <DialogSaurus
            colorTitulo={`${theme.palette.primary.main}`}
            centralizarTitulo={true}
            aberto={openned}
            titulo='Motivo do Cancelamento'
            tamanho="xs"
        >
            <Grid
                container
                spacing={2}
                style={{ marginBottom: '5px' }}
            >
                <Grid item xs={12}>
                    <FormCancelarContrato
                        loading={carregandoDelete}
                        onSubmit={handleSubmit}
                        model={model}
                        ref={formRef}
                        showLoading={carregandoDelete}
                    />
                </Grid>
                {!isMobile && <Grid item md={2}></Grid>}
                <Grid item xs={6} md={4}>
                    {closeDialog && (
                        <Button
                            disabled={carregandoDelete}
                            variant="outlined"
                            fullWidth
                            color="primary"
                            onClick={() => {
                                closeDialog();
                            }}
                        >
                            <VoltarIcon tipo='BUTTON' />
                            Voltar
                        </Button>
                    )}
                </Grid>
                <Grid item xs={6} md={6}>
                    <Button
                        disabled={carregandoDelete}
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => formRef.current?.submitForm()}>
                        <OkIcon tipo='BUTTON_PRIMARY' />
                        Confirmar
                    </Button>
                </Grid>
            </Grid>
        </DialogSaurus>
    );
};
