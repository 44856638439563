import { guidEmpty } from "utils/guid-empty";

export class PessoaWhitelabelModel {
    constructor(
        public id: string = guidEmpty(),
        public pessoaId: string = guidEmpty(),
        public sistemaId: string = guidEmpty(),
        public nome: string = "",
        public domain: string = "",
        public urlPrincipal: string = "",
        public urlIcone: string = "",
        public urlImagemPrimaria: string = "",
        public urlImagemSecundaria: string = "",
        public emailNome: string = "",
        public emailAddress: string = "",
        public emailPassword: string = "",
        public emailSmtp: string = "",
        public emailPort: number = 0,
        public emailSSL: boolean = true,
        public corPrincipal: string = "",
        public corSecundaria: string = "",
        public corTerciaria: string = "",
        public urlCssProprio: string = "",
        public urlJsProprio: string = "",
        public urlHtmlProprio: string = ""
    ) { }
}