import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, Tooltip, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { QRCodeSVG } from 'qrcode.react';
import { useStyles } from './dialog-pix-consulta-styles'
import { CloseIcon } from 'views/components/icons/close-icon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CopiarIcon, DinheiroIcon } from 'views/components/icons';
import { toCurrency } from 'utils/toCurrency';
import { usePostFaturaRegistrar } from 'data/api/gestao/faturas';
import { EnumTipoComunicacao, KeyValueModel } from 'model';
import { isEmpty } from 'lodash';
import { useToastSaurus } from 'services/app';
import { CircularLoading } from 'views/components/utils';
import { PagamentoModel } from 'model/api/gestao/pagamento/pagamento-model';
import { toDateString } from 'utils/to-date';
import { useThemeQueries } from 'views/theme';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { FaturasModel } from 'model/api/gestao/fatura/faturas-model';
import { TipoComunicacaoMock } from 'data/mocks/tipo-comunicacao-mock';
import { stringNumeros } from 'utils/string-numeros';
import { DialogPixError } from '../dialog-pix/components/dialog-erro-pix';
import { PixDialogProps } from 'views/components/modals/consulta-fatura/components/cliente-fatura/consulta-fatura';

export interface DialogPixConsultaProps {
    openned: boolean;
    closeDialog: () => void;
    setDialogPix: React.Dispatch<React.SetStateAction<PixDialogProps>>;
    value: string;
    model: FaturasModel;
    dhExpiracaoPix: string;
    formaPagamentoAtual: string;
}

export const DialogPixConsulta = ({
    openned,
    closeDialog,
    setDialogPix,
    value,
    model,
    dhExpiracaoPix,
    formaPagamentoAtual
}: DialogPixConsultaProps) => {

    const classes = useStyles();

    const qrRef = useRef(null);
    const refreshsCount = useRef<number>(0)

    const { showToast } = useToastSaurus();
    const { theme, isMobile } = useThemeQueries();

    const { postFaturaRegistrar, carregando } = usePostFaturaRegistrar();

    const [formaPagamento, setFormaPagamento] = useState<PagamentoModel>(new PagamentoModel())
    const [concorda, setConcorda] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)

    const OutrasformasPagamento = useMemo(() => {
        const formasPagamento = model.formasPagamentosDisponiveis.filter(fp => {
            return (
                fp.tipoComunicacao === EnumTipoComunicacao.Boleto &&
                fp.id !== formaPagamentoAtual
            )
        })
        return !isEmpty(formasPagamento) ? formasPagamento : null
    }, [formaPagamentoAtual, model.formasPagamentosDisponiveis])

    const formaPagamentoPix = useMemo(() => {
        const pix = model.formasPagamentosDisponiveis.find(fp => fp.tipoComunicacao === EnumTipoComunicacao.Pix)
        return !isEmpty(pix) ? pix : null
    }, [model.formasPagamentosDisponiveis])

    const pagarOutraFormaPagamento = useCallback(async (fp: PagamentoModel) => {
        try {
            const res = await postFaturaRegistrar(model.id, fp.id, stringNumeros(model.pagadorCpfCnpj), model.contratoId)

            if (res.erro) throw res.erro
            if (!res.resultado) throw new Error('Erro ao gerar pagamento. Tente novamente.')

            const ret = res.resultado.data

            switch (fp.tipoComunicacao) {
                case EnumTipoComunicacao.Boleto:

                    window.open(res.resultado.data.url, '_blank');
                    break;
                case EnumTipoComunicacao.Pix:
                    setDialogPix({
                        aberto: true,
                        codigoDeBarras: ret.codigoDeBarras,
                        linhaDigitavel: ret.linhaDigitavel,
                        nossoNumero: ret.nossoNumero,
                        pixChave: ret.pixChave,
                        pixCopiaCola: ret.pixCopiaCola,
                        url: ret.url,
                        dhExpiracaoPix: ret.dhExpiracaoPix,
                        formaPagamentoAtual: fp.id
                    })
            }
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [model.contratoId, model.id, model.pagadorCpfCnpj, postFaturaRegistrar, setDialogPix, showToast])

    useEffect(() => {
        if (error) {
            return
        }
        if (!dhExpiracaoPix || !openned) {
            return
        }
        if ((new Date(dhExpiracaoPix) < new Date()) && formaPagamentoPix) {
            if (refreshsCount.current > 2) {
                setError(true)
                return
            }
            refreshsCount.current += 1
            pagarOutraFormaPagamento(formaPagamentoPix);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeDialog, openned])

    const selectFormaPagamento = useMemo(() => OutrasformasPagamento ? (
        <SelectSaurus
            conteudo={OutrasformasPagamento.map(fp => {
                const label = `${fp.nome} (${TipoComunicacaoMock.find(tc => tc.Key === fp.tipoComunicacao)?.Value})`
                return new KeyValueModel(fp.id, label)
            })}
            label='Outras Formas de Pagamento'
            value={formaPagamento.id}
            onChange={(e: any) => {
                const fp = OutrasformasPagamento.find(fp => fp.id === e.target.value)
                if (fp) {
                    setFormaPagamento(fp)
                }
            }}
        />
    ) : null, [OutrasformasPagamento, formaPagamento.id])

    return (
        <DialogSaurus
            aberto={openned}
            titulo='Pagamento de Fatura'
            tamanho="sm"
            fullScreen={isMobile}
            endAdornment={
                <Button onClick={closeDialog} style={{ margin: '5px 0' }}>
                    <CloseIcon tipo='BUTTON' style={{ margin: 0 }} />
                </Button>
            }
        >
            {carregando && <CircularLoading tipo='FULLSIZED' />}
            <Grid container className={classes.qrCodecontainer} spacing={2}>
                <Grid item xs={12}>
                    <Typography align='center'>
                        Leia o QR Code no aplicativo do banco para realizar o pagamento da fatura. O pagamento via PIX permite a constatação de pagamento imediata além de oferecer maior segurança no pagamento por ser uma transação registrada no BACEN em tempo real.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={5} className={classes.qrCodeInfo}>
                    <div ref={qrRef} className={classes.qrCodeBox}>
                        <QRCodeSVG value={value} size={200} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={7} className={classes.qrCodeInfo}>
                    <Box display='block'>
                        <Typography variant='caption' color='textSecondary'>Este PIX expira em: {toDateString(new Date(dhExpiracaoPix), 'DD/MM/yyyy HH:mm')}.</Typography>
                        <Typography variant='h5'>Valor: {toCurrency(model.valor)}</Typography>
                    </Box>
                    <Box display='block' width={218}>
                        <Typography variant='body2'>Pix Copia e Cola</Typography>
                        <Box display='flex' gridGap={1} width='100%' mt={0.5}>
                            <Typography variant='body2' style={{ wordBreak: 'break-all' }}>
                                {value.substring(0, 37)}...
                            </Typography>
                            <Tooltip title='Copiar Código PIX'>
                                <Button onClick={() => {
                                    navigator.clipboard.writeText(value)
                                    showToast('info', 'PIX copiado.', 1500)

                                }}>
                                    <CopiarIcon tipo='BUTTON' />
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>
                </Grid>

                {/* <Button variant='contained' color='primary' onClick={() => {
                    exportComponentAsPNG(qrRef)
                }}>
                    <DownloadIcon />
                    Baixar QRCode
                </Button> */}
                {(OutrasformasPagamento) && <>
                    <Grid item xs={12}>
                        <Divider style={{ background: theme.palette.primary.main }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box display='flex' flexDirection='column' gridGap={10} mx={1} mb={1}>
                            {selectFormaPagamento}
                            <FormControlLabel
                                control={<Checkbox color='primary' checked={concorda} onChange={(e: any) => setConcorda(e.target.checked)} />}
                                label={
                                    <Typography variant='body2' color='textPrimary'>
                                        Ao trocar a forma de pagamento, aceito que somente o PIX oferece quitação instantânea do pagamento.
                                    </Typography>
                                }
                            />
                            <Box display='flex' justifyContent='flex-end' width='100%'>
                                <Button variant='contained' color='primary' onClick={() => pagarOutraFormaPagamento(formaPagamento)} disabled={!concorda}>
                                    <DinheiroIcon tipo='BUTTON_PRIMARY' />
                                    Confirmar Troca
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </>}
            </Grid>
            {error && (
                <DialogPixError
                    closeDialog={() => {
                        setError(false)
                    }}
                    openned={error}
                    selectFormaDePagamento={selectFormaPagamento}
                    formaPagamentoId={formaPagamento.id}
                    pagarOutraFormaPagamento={async () => pagarOutraFormaPagamento(formaPagamento)}
                />
            )}
        </DialogSaurus >
    );
};
