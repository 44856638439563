import { Grid, Typography } from "@material-ui/core"
import { DefaultCard } from "../components/default-card"
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { ReactNode } from 'react';
import classNames from "classnames";
import { useStyles } from './card-naoencontrado-editar-styles';
import { useThemeQueries } from "views/theme";
import { EditarIcon } from "views/components/icons";

export interface CardNaoEncontratoProps {
    mensagem: string;
    icon: ReactNode;
    model: any;
    onClick: (model: any) => void
}

export const CardNaoEncontradoEditar = (props: CardNaoEncontratoProps) => {
    const classes = useDefaultCardStyles();
    const classesNaoEncontrado = useStyles()
    const { md } = useThemeQueries()

    return (
        <>
            <DefaultCard
                onClick={
                    () => {
                        props.onClick(props.model);
                    }
                }
            >
                <div className={classNames(classes.cardContent)}>
                    <div className={classNames(classes.celulaGridFull, classesNaoEncontrado.cardNaoEncontrado, "cardCelula")}>
                        <Grid container alignItems="center" justifyContent="center" >
                            {props.icon}
                            <Typography
                                className={"celula-grid-value"}
                                color="textPrimary"
                                align="center"
                                variant="h4">
                                {props.mensagem}
                            </Typography>
                        </Grid>
                    </div>
                </div>
                {!md ? <div className={classesNaoEncontrado.editIcon}>
                    <EditarIcon tipo='BUTTON' />
                </div> : null}
            </DefaultCard>
        </>
    )
}