import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useSessaoAtual } from '../../../services/app/providers';
import { useCallback } from 'react';
import { RotasMock } from 'data/mocks/rotas-mock';

export const ValidateRoute: React.FC<RouteProps> = ({
  children,
  ...otherProps
}) => {
  const { tipoUsuario } = useSessaoAtual();
  const { pathname } = useLocation()

  const retRoute = useCallback(() => {
    const modulo = RotasMock.find(item => pathname.includes(item.rota));
    if (modulo?.permissoes.includes(tipoUsuario())) return children
    const redirectTo = RotasMock.find(item => item.permissoes.includes(tipoUsuario()))
    return <Redirect to={redirectTo?.rota || '/dashboard'} />

  }, [children, pathname, tipoUsuario]);
  return <Route {...otherProps}>{retRoute()}</Route>;
};
