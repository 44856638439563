import { useCallback, useEffect, useRef, useState } from "react";
import { useCadastros, useSessaoAtual, useToastSaurus } from "services/app";
import { useDeletePessoaWhitelabel, useGetPessoaWhitelabelById, usePostAdicionarPessoaWhitelabel, usePutAtualizarPessoaWhitelabel } from "data/api/gestao/pessoa-whitelabel";
import { PessoaWhitelabelModel } from "model/api/gestao/pessoa/pessoa-whitelabel-model";
import { Box, Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";
import { useStyles } from './modal-whitelabel-styles'
import { LixoIcon, SalvarIcon, VoltarIcon } from "views/components/icons";
import { DefaultModal, ModalHeader } from "../components";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header";
import { CircularLoading } from "views/components/utils";
import { DefaultFormRefs } from "views/components/form/utils";
import { FormWhitelabel } from "views/components/form/master/whitelabel/form-whitelabel";
import { useModalStyles } from "../utils/modal-styles";
import classNames from "classnames";
import { EnumSistemas } from "model/enums/enum-sistemas";
import { isEmpty } from "lodash";
import { usePostImagemBase64 } from "data/api/imagem/post-imagem";
import { imagemForUpload } from "utils/imagem-for-upload";
import { newGuid } from "utils/new-guid";
import { EnumRetornoApiBase } from "data/api/base/api-base-response";
import { guidEmpty } from "utils/guid-empty";
import { SistemaWhiteLabelMock } from "data/mocks/sistema-id-mock";
import { useConfirm } from "material-ui-confirm";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { ButtonIcon } from "views/components/controles";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";

interface ModalWhitelabelProps {
    openned: boolean;
    pessoaId: string;
    id: string;
    sistemaId: string;
}

function isURL(str: string): boolean {
    const pattern = new RegExp('^(https?:\\/\\/)?'+
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+
    '((\\d{1,3}\\.){3}\\d{1,3}))'+
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
    '(\\?[;&a-z\\d%_.~+=-]*)?'+
    '(\\#[-a-z\\d_]*)?$','i');
    return pattern.test(str);
}

export const ModalWhitelabel = ({
    openned,
    id,
    pessoaId,
    sistemaId
}: ModalWhitelabelProps) => {

    const { showToast } = useToastSaurus();
    const classes = useStyles();
    const modalClasses = useModalStyles();
    const { usuario, tipoUsuario } = useSessaoAtual();
    const formRef = useRef<DefaultFormRefs<PessoaWhitelabelModel>>(null)
    const optionsRef = useRef<HTMLButtonElement>(null)
    const confirm = useConfirm();

    const whitelabelNovo = isEmpty(id) || id === guidEmpty();
    const sistemaLabel = SistemaWhiteLabelMock.find(item => item.Key === sistemaId)?.Value || ''

    const { fecharCadastroWhitelabel } = useCadastros();

    const { getPessoaWhitelabelById, carregando: carregandoGet } = useGetPessoaWhitelabelById()
    const { postPessoaWhitelabel, carregando: carregandoPost } = usePostAdicionarPessoaWhitelabel();
    const { putAtualizarPessoaWhitelabel, carregando: carregandoPut } = usePutAtualizarPessoaWhitelabel();
    const { deletePessoaWhitelabel, carregando: carregandoDelete } = useDeletePessoaWhitelabel();
    const { postImagemBase64, carregando: carregandoImg } = usePostImagemBase64();

    const carregando = carregandoGet || carregandoPost || carregandoPut || carregandoDelete || carregandoImg

    const [whitelabel, setWhitelabel] = useState<PessoaWhitelabelModel>(new PessoaWhitelabelModel())
    const [openOptions, setOpenOptions] = useState<boolean>(false)

    const getWhitelabelWrapper = useCallback(async () => {
        try {
            const res = await getPessoaWhitelabelById(pessoaId, id)

            if (res.erro) throw res.erro

            if (!res.resultado) return

            setWhitelabel(res.resultado.data)
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getPessoaWhitelabelById, id, pessoaId, showToast])

    const enviarImagem = useCallback(
        async (imagemUrl: string) => {
            let imagem = '';

            const imgUpload = imagemForUpload(imagemUrl);
            if (imgUpload.length > 0) {
                const retImagem = await postImagemBase64(
                    imgUpload,
                    `licenciamento/pessoas/${usuario?.Id}/`,
                    newGuid(),
                    true
                );
                if (retImagem.tipoRetorno !== EnumRetornoApiBase.Sucesso) {
                    throw new Error('Erro ao processar  a Imagem selecionada.');
                }
                if (retImagem.resultado?.data.data.status === 2) {
                    throw new Error(
                        'Erro ao processar a Imagem selecionada.Detalhe: ' +
                        retImagem.resultado?.data?.data?.retorno,
                    );
                }
                imagem =
                    retImagem.resultado?.data?.data?.url_blob +
                    '?timestamp=' +
                    new Date().getTime();
            }
            return imagem;
        },
        [postImagemBase64, usuario?.Id],
    )

    const postWhitelabel = useCallback(async (model: PessoaWhitelabelModel) => {
        const res = await postPessoaWhitelabel(pessoaId, model)

        if (res.erro) throw res.erro

    }, [pessoaId, postPessoaWhitelabel])

    const putWhitelabel = useCallback(async (model: PessoaWhitelabelModel) => {
        const res = await putAtualizarPessoaWhitelabel(pessoaId, model)

        if (res.erro) throw res.erro

    }, [pessoaId, putAtualizarPessoaWhitelabel])

    const deleteWhitelabel = useCallback(async () => {
        const res = await deletePessoaWhitelabel(pessoaId, id);

        if (res.erro) throw res.erro
    }, [deletePessoaWhitelabel, id, pessoaId])

    const handleSubmit = useCallback(async (model: PessoaWhitelabelModel) => {
        try {
            if (!isEmpty(model.urlImagemPrimaria) && !isURL(model.urlImagemPrimaria)) {
                model.urlImagemPrimaria = await enviarImagem(model.urlImagemPrimaria);
            }
            if (!isEmpty(model.urlImagemSecundaria) && !isURL(model.urlImagemSecundaria)) {
                model.urlImagemSecundaria = await enviarImagem(model.urlImagemSecundaria);
            }
            if (!isEmpty(model.urlIcone) && !isURL(model.urlIcone)) {
                model.urlIcone = await enviarImagem(model.urlIcone);
            }
            model.sistemaId = sistemaId;
            model.pessoaId = pessoaId;
            if (isEmpty(id)) {
                model.id = guidEmpty();
                await postWhitelabel(model);
                showToast('success', 'Whitelabel Criado com sucesso.')
            } else {
                await putWhitelabel(model)
                showToast('success', 'Whitelabel Atualizado com sucesso.')
            }

            fecharCadastroWhitelabel();
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [enviarImagem, fecharCadastroWhitelabel, id, pessoaId, postWhitelabel, putWhitelabel, showToast, sistemaId])

    useEffect(() => {
        if (openned && !whitelabelNovo) {
            getWhitelabelWrapper();
        }
    }, [getWhitelabelWrapper, openned, whitelabelNovo])

    useEffect(() => {
        if (openned) {
            formRef.current?.fillForm(whitelabel)
        }
    }, [openned, whitelabel])

    const confirmDelete = useCallback(() => {
        confirm({
            title: 'Tem certeza?',
            description: 'Deseja excluir este Whitelabel?',
            cancellationText: 'Cancelar',
            confirmationText: 'Excluir'
        }).then(async () => {
            try {
                await deleteWhitelabel();
                showToast('success', 'Whitelabel excluído!')
                fecharCadastroWhitelabel();
            } catch (e: any) {
                showToast('error', e.message)
            }
        })
    }, [confirm, deleteWhitelabel, fecharCadastroWhitelabel, showToast])

    const handleCloseOptions = (event: React.MouseEvent<EventTarget>) => {
        if (optionsRef.current && optionsRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpenOptions(false);
    };

    function handleListKeyDownOptions(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenOptions(false);
        }
    }

    const isFuncionario = tipoUsuario() === EnumTipoPessoas.FuncionarioSoftwareHouse

    return (
        <>
            <DefaultModal
                open={openned}
                variant={"temporary"}
                anchor="right"
                minWidth="400px"
            >
                <Box className={modalClasses.root} maxHeight='100vh'>
                    <Box className={classNames(modalClasses.content, classes.container)}>
                        {carregando && <CircularLoading tipo='FULLSIZED' />}
                        <ModalHeader
                            title={`${whitelabelNovo ? 'Criar' : 'Editar'} Whitelabel - ${sistemaLabel}`}
                            leftArea={
                                <ButtonModalHeader
                                    tooltip="Voltar"
                                    icon={<VoltarIcon tipo="MODAL_HEADER" />}
                                    onClick={fecharCadastroWhitelabel}
                                />
                            }
                            rightArea={!whitelabelNovo && !isFuncionario ?
                                <Box>
                                    <ButtonIcon
                                        icon={<MoreVertIcon style={{ fill: '#fff' }} innerRef={optionsRef} />}
                                        onClick={() => setOpenOptions(true)}
                                    />
                                    <Popper open={openOptions} anchorEl={optionsRef.current} placement="top" role={undefined} transition disablePortal style={{ zIndex: 999999999 }}>
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                            >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={handleCloseOptions}>
                                                        <MenuList autoFocusItem={openOptions} id="menu-list-grow" onKeyDown={handleListKeyDownOptions}>
                                                            <MenuItem onClick={() => confirmDelete()}>
                                                                <Box display='flex' alignItems='center'>
                                                                    <LixoIcon tipo='BUTTON' />
                                                                    Excluir Whitelabel
                                                                </Box>
                                                            </MenuItem>

                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </Box> : undefined
                            }
                        />
                        <Box className={classes.formContainer}>
                            <FormWhitelabel
                                loading={carregando}
                                showLoading={false}
                                onSubmit={handleSubmit}
                                ref={formRef}
                                sistema={sistemaId as EnumSistemas}
                                disableFields={isFuncionario}
                            />
                        </Box>
                        {!isFuncionario && <Box className={classes.buttonContainer}>
                            <Button
                                variant='contained'
                                color='primary'
                                fullWidth
                                size="large"
                                onClick={() => formRef.current?.submitForm()}
                            >
                                <SalvarIcon tipo='BUTTON_PRIMARY' />
                                Salvar
                            </Button>
                        </Box>}
                    </Box>
                </Box>
            </DefaultModal>
        </>
    )
}