import { Grid, Tooltip, Typography } from '@material-ui/core';
import { CardPagamentoProps } from './card-pagamento-props';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { TpComunicacaoMock } from 'data/mocks';
import { useThemeQueries } from 'views/theme';
import { EditarIcon } from 'views/components/icons';
import {useStyles} from './card-pagamento-styles'

export const CardPagamento = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardPagamentoProps) => {
  const classes = useDefaultCardStyles();
  const pagamentoClasses = useStyles();
  const {isMobile, theme} = useThemeQueries();
  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model.id);
        }}
      >
        <Grid container className={classes.cardContent}>
          <Grid item xs={12} className={pagamentoClasses.infoField}>
            <Typography color="primary" variant="caption">
              Nome:
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              <b>{model.nome}</b>
            </Typography>
          </Grid>
          <Grid item xs={12} className={pagamentoClasses.infoField}>
            <Typography color="primary" variant="caption">
              Tipo:
            </Typography>
            <Typography
              color="textPrimary"
              variant="caption"
            >
              {TpComunicacaoMock.find(item => item.Key === model.tipoComunicacao)?.Value ?? ''}
            </Typography>
          </Grid>
        </Grid>
        {!isMobile && <Tooltip arrow title='Editar Forma de Pagamento'>
          <div className={classes.cardRightButton} style={{margin: '7px 0', borderColor: theme.palette.divider}}>
            <EditarIcon tipo="BUTTON" fill={theme.palette.primary.main}/>
          </div>
        </Tooltip>}
      </DefaultCard>
    </>
  );
};
