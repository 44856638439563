import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    infoField:{
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        '& p': {
            fontSize: 13
        }
    },
}))
