import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
      '& p': {
        fontSize: '13px !important'
      }
    },
    valores:{
      [theme.breakpoints.up('lg')]:{
        textAlign: 'right'
      }
    },
    data:{
      [theme.breakpoints.up('lg')]:{
        textAlign: 'center'
      }
    },
    cardGrid:{
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0
        }
    }
  }))