import { Button, Grid, useTheme } from "@material-ui/core";
import { useGetSistemas } from "data/api/gestao/sistemas";
import { SistemasModel } from "model/api/gestao/sistemas/sistemas-model";
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useToastSaurus } from "services/app";
import { ProcurarIcon, SalvarEditarIcon, VoltarIcon } from "views/components/icons";
import { CircularLoading } from "views/components/utils";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus";
import { DialogIndicacaoSistemasData } from "./dialog-indicacao-sistemas-data";
import { useStyles } from "./dialog-indicacao-sistemas-styles";
import { IndicacoesPutModel } from "model/api/gestao/indicacoes/indicacoes-put-model";
import { IndicacoesModel } from "model/api/gestao/indicacoes/indicacoes-model";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { useThemeQueries } from "views/theme";
import { isEmpty } from "lodash";

interface DialogIndicacaoSistemasListProps {
    opnned: boolean;
    model: IndicacoesModel;
    onSubmit: (valor: IndicacoesPutModel) => Promise<void>;
    onCardClick: (model: SistemasModel) => void;
    closeDialog: () => void;
}

export const DialogIndicacaoSistemasList = ({
    opnned,
    model,
    onSubmit,
    onCardClick,
    closeDialog,
}: DialogIndicacaoSistemasListProps) => {
    const { showToast } = useToastSaurus();
    const classes = useStyles();
    const theme = useTheme();
    const { isMobile } = useThemeQueries();


    //REQUISIÇÕES
    const { getSistemas, carregando: carregandoGet } = useGetSistemas();

    //STATES E REFS
    const [selectedId, setSelectedId] = useState<string>('');
    const [termoPesquisar, setTermoPesquisar] = useState<string>('');
    const [pesquisarState, setPesquisarState] = useState<string>('');
    const refInput = useRef<HTMLDivElement | null>(null);

    //FOCO AO FICAR VISIVEL
    useEffect(() => {
        if (opnned && !isMobile) refInput.current?.focus();
        else {
            setTermoPesquisar('');
        }
    }, [isMobile, opnned]);

    const {
        handleSubmit,
        reset,
    } = useForm<SistemasModel>({
        defaultValues: { ...model },
        criteriaMode: 'all',
        mode: 'onBlur' && 'onChange',
    });

    useEffect(() => {
        reset({ ...model })
    }, [model, reset])

    const submit = async () => {
        const sistemaPut: IndicacoesPutModel = {
            indicadorId: model.indicador?.id || '',
            responsavelId: model.responsavel?.id || null,
            instrucao: model.instrucao,
            motivoAvaliacaoIndicador: model.motivoAvaliacaoIndicador,
            motivoAvaliacaoResponsavel: model.motivoAvaliacaoResponsavel,
            avaliacaoIndicador: model.avaliacaoIndicador,
            sistemaId: selectedId || null,
            avaliacaoResponsavel: model.avaliacaoResponsavel,
            nivelDeInteresse: model.nivelDeInteresse,
            observacaoFinalizacao: model.observacaoFinalizacao,
            id: model.id,
            data: model.data,
            status: model.status,
            faturamentoEstimado: model.faturamentoEstimado
        }

        await onSubmit(sistemaPut);
        closeDialog();
    }

    const addRemoveSelectedList = useCallback((model: SistemasModel) => {
        setSelectedId(model.id)
    }, [])

    const [queryStatus, setQueryStatus] = useState({
        page: 1,
        totalPages: 0,
        totalResults: 0,
        list: Array<SistemasModel>(),
    });

    const fillResult = useCallback(
        (
            page: number,
            totalPages: number,
            totalResults: number,
            list: Array<SistemasModel>,
        ) => {
            setQueryStatus({
                page: page,
                list: list,
                totalResults: totalResults,
                totalPages: totalPages,
            });
        },
        [],
    );

    const getSistemasWrapper = useCallback(async () => {
        try {
            const res = await getSistemas(1, ` &pageSize=10&${!isEmpty(pesquisarState) ? `query=${pesquisarState}` : ''}`);

            if (res.erro) throw res.erro

            if (!res.resultado) return;

            fillResult(
                res.resultado?.data.pageIndex!,
                res.resultado?.data.totalPages!,
                res.resultado?.data.totalResults!,
                res.resultado?.data.list!,
            );
        }
        catch (error: any) {
            showToast('error', error.message);
        }
    }, [fillResult, getSistemas, pesquisarState, showToast])

    useEffect(() => {
        if (opnned) {
            getSistemasWrapper()
        }
    }, [getSistemasWrapper, opnned])


    //ALTERAR A PESQUISA
    const onChangePesquisa = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setTermoPesquisar(event.target.value);
        },
        [],
    );

    //CHAMADA DO PESQUISAR
    const pesquisar = useCallback(
        (event: any) => {
            event.preventDefault();
            setPesquisarState(termoPesquisar);
        },
        [termoPesquisar],
    );

    const dialogIndicacoesData = useMemo(() =>
        <DialogIndicacaoSistemasData
            list={queryStatus.list}
            carregando={carregandoGet}
            onClick={addRemoveSelectedList}
            selectedId={selectedId}
        />
        , [addRemoveSelectedList, carregandoGet, queryStatus.list, selectedId])


    return (
        <DialogSaurus
            aberto={opnned}
            titulo="Sistemas"
            tamanho="sm"
            bottomArea={

                <div className={classes.acoes}>
                    <Grid container spacing={2} justifyContent='flex-end'>
                        <Grid item md={6} xs={12}>
                            <Button
                                variant='outlined'
                                color='primary'
                                onClick={closeDialog}
                                fullWidth
                            >
                                <VoltarIcon tipo='BUTTON' />
                                Voltar
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button
                                disabled={carregandoGet}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleSubmit(submit)}
                            >
                                <SalvarEditarIcon tipo='BUTTON_PRIMARY' />
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            }
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <form onSubmit={pesquisar}>
                        <div className={classes.containerFinal}>
                            <TextFieldSaurus
                                inputRef={refInput}
                                fullWidth
                                size="small"
                                allowSubmit={true}
                                value={termoPesquisar}
                                placeholder="Digite aqui alguma informação para procurar..."
                                onChange={onChangePesquisa}
                                adornmentColor={theme.palette.text.disabled}
                            />
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                className={classes.btnPesquisa}
                                fullWidth={true}
                            >
                                <ProcurarIcon tipo="BUTTON_PRIMARY" />
                            </Button>
                        </div>
                    </form>
                </Grid>
                <Grid item xs={12} style={{ width: '100%' }}>
                    {carregandoGet && <CircularLoading tipo="FULLSIZED" />}
                    {dialogIndicacoesData}
                </Grid>
            </Grid>
        </DialogSaurus >
    );
}