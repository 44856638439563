import { Box, Button, Grid, Typography, useTheme } from '@material-ui/core';
import { TerminalHistoricoModel } from 'model/api/gestao/terminal/terminal-historico-model';
import { toDateString } from 'utils/to-date';
import { VoltarIcon } from 'views/components/icons';
import { DialogSaurus } from '../../dialog-saurus/dialog-saurus';
import { useStyles } from '../dialog-terminal-styles'

interface DialogHistoricoTerminalProps {
  model: TerminalHistoricoModel;
  openned: boolean;
  closeDialog: () => void;
}

export default function DialogHistoricoTerminal({
  model,
  openned,
  closeDialog
}: DialogHistoricoTerminalProps) {
  const theme = useTheme();
  const classes = useStyles()

  return (
    <DialogSaurus
      colorTitulo={`${theme.palette.primary.main}`}
      aberto={openned}
      titulo="Detalhes"
      tamanho="sm"
    >
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='caption'>
                Chave
              </Typography>
              <Typography variant='body1' style={{ fontWeight: 600 }}>
                {model.chave}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='caption'>
                Serial
              </Typography>
              <Typography variant='body1' style={{ fontWeight: 600 }}>
                {model.serialHd}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant='caption'>
                Sistema Operacional
              </Typography>
              <Typography variant='body1' style={{ fontWeight: 600 }}>
                {model.sistemaOperacional}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant='caption'>
                Data de Acesso
              </Typography>
              <Typography variant='body1' style={{ fontWeight: 600 }}>
                {toDateString(new Date(model.dataAcesso + 'Z'), 'DD/MM/yyyy HH:mm')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant='caption'>
                Versão
              </Typography>
              <Typography variant='body1' style={{ fontWeight: 600 }}>
                {model.versao}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant='caption'>
                Maquina
              </Typography>
              <Typography variant='body1' style={{ fontWeight: 600 }}>
                {model.maquina}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='caption'>
                IP Local
              </Typography>
              <Typography variant='body1' style={{ fontWeight: 600 }}>
                {model.ipLocal}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='caption'>
                IP Público
              </Typography>
              <Typography variant='body1' style={{ fontWeight: 600 }}>
                {model.ipPublico}
              </Typography>
            </Grid>
          </Grid>
          <Box width='100%' marginTop='20px'>
            <Grid item md={12} xs={12}>
              <Button
                variant='outlined'
                color='primary'
                onClick={closeDialog}
                fullWidth
              >
                <VoltarIcon tipo='BUTTON' />
                Voltar
              </Button>
            </Grid>
          </Box>
        </Box>
      </Box>
    </DialogSaurus>
  );
}