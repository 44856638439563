import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { FaturaFilhaModel } from 'model/api/gestao/fatura/faturas-filhas-model';
import { useEffect, useMemo, useState } from 'react';
import { CardNaoEncontrado } from 'views/components/cards';
import { CardFaturaFilha } from 'views/components/cards/card-fatura-filha/card-fatura-filha';
import { InformacaoIcon } from 'views/components/icons';
import { AnteciparFaturaIcon } from 'views/components/icons/antecipar-fatura-icon';

export interface FaturasFilhasModel extends FaturaFilhaModel {
  periodoAnoMes?: string;
  numeroLicencas?: number;
}

interface SistemaGrupo {
  nome: string;
  list: Array<FaturasFilhasModel>;
}

export interface SelecionadosModel {
  nome: string;
  selected: Array<FaturaFilhaModel>;
}

export interface FaturasGridProps {
  list: Array<FaturaFilhaModel>;
  onClick: (open: boolean) => void;
}

export const FaturaFilhaListData = (props: FaturasGridProps) => {
  const grupos = useMemo<SistemaGrupo[]>(() => {
    let sistemas: SistemaGrupo[] = [];
    const faturasArr = props.list
      .map((x) => ({ ...x }))
      .sort((a, b) => {
        if (a.pagadorNome < b.pagadorNome) {
          return -1;
        } else if (a.pagadorNome > b.pagadorNome) {
          return 1;
        } else if (a.competenciaAnoMes < b.competenciaAnoMes) {
          return -1;
        } else if (a.competenciaAnoMes > b.competenciaAnoMes) {
          return 1;
        }
        return 0;
      }); //BUG DE REFERENCIA DE MEMORIA TEM Q COPIAR ASSIM SE N QUEBRA TD

    for (let i = 0; i < faturasArr.length; i++) {
      const sistema = sistemas.find(
        (grupo) => grupo.nome === faturasArr[i].sistemaNome,
      );

      if (sistema) {
        const index = sistemas.indexOf(sistema);
        const faturaMesmoContrato = sistema.list.find(
          (x) =>
            x.contratoId === faturasArr[i].contratoId && !isEmpty(x.loteId),
        );

        if (faturaMesmoContrato) {
          const indexMesmoContrato = sistema.list.indexOf(faturaMesmoContrato);
          var fatura = sistemas[index].list[indexMesmoContrato];

          fatura.valor += faturasArr[i].valor;
          fatura.valorCusto += faturasArr[i].valorCusto;
          fatura.numeroLicencas = !sistemas[index].list[indexMesmoContrato]
            .numeroLicencas
            ? 2
            : fatura.numeroLicencas! + 1;
          sistemas[index].list[
            indexMesmoContrato
          ].periodoAnoMes = `${fatura.competenciaAnoMes} - ${faturasArr[i].competenciaAnoMes}`;
          continue;
        }

        sistemas[index].list.push(faturasArr[i]);
        continue;
      }

      sistemas.push({
        nome: faturasArr[i].sistemaNome,
        list: [faturasArr[i]],
      });
    }
    return sistemas;
  }, [props.list]);

  const [selected, setSelected] = useState<SelecionadosModel[]>([]);

  useEffect(() => {
    setSelected(
      grupos.map((x) => {
        return {
          nome: x.nome,
          selected: [],
        };
      }),
    );
  }, [grupos]);

  return (
    <>
      {props.list.length === 0 && (
        <CardNaoEncontrado
          mensagem="Nenhuma Fatura Encontrada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {grupos.length > 0 &&
        grupos
          .sort((a, b) => {
            if (a.nome < b.nome) {
              return -1;
            }
            if (a.nome > b.nome) {
              return 1;
            }
            return 0;
          })
          .map((sistema, index) => {
            console.log('sistema-index', index);
            console.log('sistema-list', sistema.list);

            const permiteAntecipacao =
              sistema.list.filter((x) => {
                return x.sistemaPercDescontoAntecipacao >= 1;
              }).length > 0;
            console.log('permiteAntecipacao', permiteAntecipacao);

            const faturasPermitidas = sistema.list.filter((x) => {
              return (
                x.sistemaPercDescontoAntecipacao >= 1 &&
                isEmpty(x.loteId) && //AINDA NAO FOI ANTECIPADO
                [0, 1, 5].filter((y) => y === x.situacao).length > 0
              );
            });

            console.log('permitido', faturasPermitidas);

            const selecionados = selected.find(
              (x) => x.nome === sistema.nome,
            )?.selected;

            console.log('selecionados', selecionados);

            const todosSelecionados: boolean = (() => {
              const valor = selected.find((x) => x.nome === sistema.nome);

              return (
                valor?.selected.length === faturasPermitidas.length &&
                faturasPermitidas.length > 0
              );
            })();

            console.log('todosSelecionados', todosSelecionados);

            return (
              <Grid container spacing={1} style={{ marginTop: 16 }}>
                <Grid
                  item
                  xs={12}
                  style={{
                    paddingLeft: 7,
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                  }}
                >
                  <Typography variant="h6" color="primary">
                    {sistema.nome}
                  </Typography>
                  {faturasPermitidas.length > 0 && (
                    <Box display="flex" flexDirection="space-between">
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={todosSelecionados}
                          />
                        }
                        value={todosSelecionados}
                        onChange={() => {
                          setSelected((prev) => {
                            const list = [...prev];
                            const valor = list.find(
                              (x) => x.nome === sistema.nome,
                            );
                            const index = list.indexOf(valor!);

                            if (list[index].selected.length === 0) {
                              list[index].selected = faturasPermitidas;

                              return list;
                            } else if (
                              list[index].selected.length ===
                              faturasPermitidas.length
                            )
                              list[index].selected = [];
                            return list;
                          });
                        }}
                        label="Selecionar Todos"
                      />
                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        disabled={selecionados && selecionados.length <= 0}
                        onClick={() => {
                          if (selecionados) {
                            const eventArr = selecionados.map((x) => ({
                              contratoId: x.contratoId,
                              anoMesFaturaFilha: x.competenciaAnoMes,
                            }));

                            dispatchEvent(
                              new CustomEvent('setContratoIdAntecipacao', {
                                detail: eventArr,
                              }),
                            );

                            props.onClick(true);
                          }
                        }}
                      >
                        <AnteciparFaturaIcon tipo={"BUTTON"} fill={selecionados && selecionados.length <= 0 ? "#999" : undefined} />
                        Antecipar Selecionados
                      </Button>
                    </Box>
                  )}
                </Grid>
                {sistema.list.map((fatura) => {
                  return (
                    <Grid item xs={12}>
                      <CardFaturaFilha
                        model={fatura}
                        key={index}
                        onClick={props.onClick}
                        selected={
                          selected.find((x) => x.nome === sistema.nome)
                            ?.selected || []
                        }
                        setSelected={setSelected}
                        esconderCheckbox={permiteAntecipacao ?? false}
                        desabilitarCheckbox={
                          faturasPermitidas.filter((ft) => ft.id === fatura.id)
                            .length === 0
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            );
          })}
    </>
  );
};
