import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: "relative",
        overflowY: 'auto',
    },
    card: {
        maxWidth: 1400,
        padding: 16,
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: '0px 4px 8px rgba(96, 97, 112, 0.16)',
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
        }
    },
    cardContainer: {
        height: '100%',
        overflowY: 'auto',
        width: '100%',
        padding: '1rem 9rem',
        [theme.breakpoints.down('lg')]: {
            padding: '1rem 6rem',
        },
        [theme.breakpoints.down('md')]: {
            padding: '1rem 1rem',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '1rem .5rem'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '1rem 0'
        }
    },
    errorMessageContainer: {
        background: '#F8F8F8',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: '1rem',
        borderRadius: '15px',
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center',
        '& .MuiFormControl-root': {
            background: '#fff'
        },
        [theme.breakpoints.down('md')]: {
            padding: '10px',
            marginRight: 0
        }
    },
    acoes: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        margin: `${theme.spacing(2)} 0`,
        [theme.breakpoints.down('sm')]: {
            gap: theme.spacing(2),
            flexDirection: 'column'
        }
    },
    childButtons: {
        display: 'flex',
        gridGap: theme.spacing(1),
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            width: '100%'
        }
    }
}));