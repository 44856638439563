import { EnumSituacaoSistema } from "model/enums/enum-situacao-sistema";
import { guidEmpty } from "utils/guid-empty";
import { ModuloModel } from "./modulo-model";


export class SistemaModel {
  constructor(
    public id: string = guidEmpty(),
    public nome: string = "",
    public descricao: string = "",
    public urlImagem: string = "",
    public situacao: EnumSituacaoSistema = EnumSituacaoSistema.Ativo,
    public modulos: Array<ModuloModel> = [],
    public percDescontoAntecipacao: number = 0,
  ) { }
}
