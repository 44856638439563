import { useState } from 'react';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { ComissoesHeader } from './components/comissoes-header/comissoes-header';
import { ComissoesTabs } from './components/comissoes-tabs/comissoes-tabs';

export const ComissoesPage = () => {
  // STATES E REFS
  const [openPesquisa, setOpenPesquisa] = useState(true);

  // AUX
  const classes = useDefaultCadastroStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <ComissoesHeader
          openPesquisa={openPesquisa}
          setOpenPesquisa={setOpenPesquisa}
        />
      </div>
        <ComissoesTabs openSearch={openPesquisa} />
    </div>
  );
};

export default ComissoesPage;
