import { useCallback } from 'react';
import { ConsultaCEPModel } from '../../../model/api/wsmaster';
import { useWsMaster } from './base/wsmaster';


export function useConsultaCEP() {
  const { invocarApi, ...outros } = useWsMaster({
    operacao: 'retConsultaCep',
  });

  const consultaCEP = useCallback(
    async (cep: string) => {
      const xml = (senha: string) => `
              <?xml version="1.0" encoding="utf-8"?>
              <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
                <soap12:Body>
                  <retConsultaCep xmlns="http://saurus.net.br/">
                    <xXMLParametros><![CDATA[<xmlIntegracao><CEP>${cep.replace('-', '')}</CEP></xmlIntegracao>]]></xXMLParametros>
                    <xSenha>${senha}</xSenha>
                  </retConsultaCep>
                </soap12:Body>
              </soap12:Envelope>`;

      const result = await invocarApi({ xml });
      return result.consultaCEP as ConsultaCEPModel;

    },
    [invocarApi],
  );

  return {
    ...outros,
    consultaCEP,
  };
}
