import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        paddingLeft: theme.spacing(2),
        '& p': {
            fontSize: 14
        }
    },
    editIcon: {
        display: 'flex',
        alignItems: 'center',
        borderLeft: '1px solid #E6E6E6',
        paddingLeft: '10px',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        '& svg': {
            minHeight: 28,
            minWidth: 28
        }
    }
}))