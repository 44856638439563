import { Box, Button } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useMemo, useState } from 'react';
import { EnumTipoComunicacao, KeyValueModel } from 'model';
import { PagamentoModel } from 'model/api/gestao/pagamento/pagamento-model';
import {useStyles} from './dialog-confirmar-pagamento-styles'
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';


interface DialogConfirmarPagamentoProps {
    openned: boolean;
    closeDialog: () => void;
    pagarPix: (formaPagamento: PagamentoModel) => Promise<void>;
    formasPagamento: PagamentoModel[];
}

export const DialogConfirmarPagamento = ({
    openned,
    closeDialog,
    pagarPix,
    formasPagamento
}: DialogConfirmarPagamentoProps) => {

    const formasPagamentoPix = useMemo(() => {
        return formasPagamento.filter(fp => fp.tipoComunicacao === EnumTipoComunicacao.Pix)
    }, [formasPagamento])

    const [formaPagamento, setFormaPagamento] = useState<PagamentoModel>(formasPagamentoPix[0] || new PagamentoModel())
    const classes = useStyles();

    
    return (
        <DialogSaurus
            aberto={openned}
            titulo='Confirmar Forma de Pagamento'
            tamanho="sm"
            bottomArea={
                <Box className={classes.buttonsContainer}>
                    <Button onClick={closeDialog}>
                        Cancelar
                    </Button>
                    <Button color='primary' onClick={() => {
                        closeDialog()
                        pagarPix(formaPagamento)
                    }}>
                        Pagar PIX
                    </Button>
                </Box>
            }
        >
            <Box className={classes.container}>
                <SelectSaurus
                    conteudo={formasPagamentoPix.map(fp => new KeyValueModel(fp.id, fp.nome))}
                    label='Forma de Pagamento'
                    value={formaPagamento.id}
                    onChange={(e: any) => {
                        const fp = formasPagamentoPix.find(fp => fp.id === e.target.value)
                        if(fp){
                            setFormaPagamento(fp)
                        }
                    }}
                />
            </Box>
        </DialogSaurus >
    );
};
