import { makeStyles, Theme } from "@material-ui/core";

interface Props{
    fase: number;  
}

export const useStyles = makeStyles<Theme, Props>((theme) => ({
    container: {
        width: '100%',
        minHeight: 'calc(100vh - 75px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: "relative",
        padding: '0 9rem',
        overflowY: "auto",
        overflowX: "hidden",
        [theme.breakpoints.down('lg')]: {
            padding: '0 6rem',
        },
        [theme.breakpoints.down('md')]: {
            padding: '0 1rem',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 .5rem'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0'
        }
    },
    card: {
        maxWidth: 1400,
        padding: 16,
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: '0px 4px 8px rgba(96, 97, 112, 0.16)',
        overflowY: 'auto',
        width: props => props.fase === 1 ? 700 : 'unset',
        [theme.breakpoints.down('sm')]: {
            height: props => props.fase === 1 ? 'unset' : '100%',
            width: 'unset !important',
        }
    },
    acoes: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        margin: `${theme.spacing(2)} 0`
    }
}));