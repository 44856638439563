import { Grid, Tooltip, Typography } from '@material-ui/core';
import { SituacaoMock } from 'data/mocks/situacao-mock';
import { isEmpty } from 'lodash';
import { EnumSituacao } from 'model/enums/enum-situacao';
import { useCallback } from 'react';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { toDateString } from 'utils/to-date';
import { EditarIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { CardFaturaProps } from './card-fatura-comissoes-pagas-props';
import { useStyles } from './card-fatura-styles'

export const CardFaturaComissoesPagas = ({
    model,
    onClick,
    onCheck,
    selected,
}: CardFaturaProps) => {
    const classes = useDefaultCardStyles();
    const listClasses = useStyles();
    const { theme, isMobile } = useThemeQueries();


    const retornarCorStatus = useCallback((): string => {
        switch (model.situacao) {
            case EnumSituacao.Aberta:
                return theme.palette.info.main
            case EnumSituacao.Paga:
                return theme.palette.success.main
            case EnumSituacao.Atraso:
                return theme.palette.warning.main
            case EnumSituacao.Bonificada:
                return '#D7096C'
            case EnumSituacao.Cancelada:
                return theme.palette.error.main
            case EnumSituacao.Fechada:
                return '#8346a6'
        }
        return theme.palette.info.main
    }, [model.situacao, theme.palette.error.main, theme.palette.info.main, theme.palette.success.main, theme.palette.warning.main]);

    const descricao = SituacaoMock.filter(
        (x) => x.Key === model.situacao,
    )[0].Value;

    return (
        <>
            <DefaultCard
                isSelected={selected}
                onClick={() => {
                    onClick(model.id, model.tipoFatura);
                }}
            >
                <Tooltip arrow title={descricao} placement="right">
                    <div
                        className={classes.tagStatus}
                        style={{ background: retornarCorStatus() }}
                    ></div>
                </Tooltip>
                <div className={`${classes.cardContent} ${listClasses.container}`}>
                    <Grid container spacing={isMobile ? 1 : 2}>
                        <Grid item xs={6} lg={2}>
                            <Typography color="textPrimary" variant="body1" style={{ fontSize: '0.75rem' }} align={isMobile ? 'left' : 'center'}>
                                Data do Pagamento
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                align={isMobile ? 'left' : 'center'}
                            >
                                <b>{toDateString(model.dataPagamento)}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} lg={2}>
                            <Typography color="textPrimary" variant="body1" style={{ fontSize: '0.75rem' }} align={isMobile ? 'left' : 'center'}>
                                Data do Vencimento
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                align={isMobile ? 'left' : 'center'}
                            >
                                <b>{toDateString(model.dataVencimento)}</b>
                            </Typography>
                        </Grid>
                        {!isMobile && <Grid item lg={1} xs={6}>
                            <Typography color="textPrimary" variant="body1" align={isMobile ? 'left' : 'center'} style={{ fontSize: '0.75rem' }}>
                                Competência:
                            </Typography>
                            <Typography color="textPrimary" variant="body1" align={isMobile ? 'left' : 'center'}>
                                {model.competenciaAnoMes}
                            </Typography>
                        </Grid>}
                        <Grid item xs={6} lg={3}>
                            <Typography color='textPrimary' variant='body1' style={{ fontSize: '0.75rem' }} align={isMobile ? 'left' : 'center'}>
                                {formatarCPFCNPJ(model.pagadorCpfCnpj)}
                            </Typography>
                            <Typography align={isMobile ? 'left' : 'center'} color='textPrimary' variant='body1'>
                                {model.pagadorNome}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} lg={2}>
                            <Typography color="textPrimary" variant="body1" style={{ fontSize: '0.75rem' }} align={isMobile ? 'left' : 'center'}>
                                Sistema
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                align={isMobile ? 'left' : 'center'}
                            >
                                {!isEmpty(model.sistemaDescricao) ? model.sistemaDescricao : '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} lg={1}>
                            <Typography color='textPrimary' variant='body1' align={isMobile ? 'left' : 'right'} style={{ fontSize: '0.75rem' }}>
                                Valor
                            </Typography>
                            <Typography color='textPrimary' variant='body1' align={isMobile ? 'left' : 'right'}>
                                {model.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} lg={1}>
                            <Typography color='textPrimary' variant='body1' align={isMobile ? 'left' : 'right'} style={{ fontSize: '0.75rem' }}>
                                Valor Pago
                            </Typography>
                            <Typography color='textPrimary' variant='body1' align={isMobile ? 'left' : 'right'}>
                                <b>{model.valorPago.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <Tooltip arrow title="Editar Fatura">
                    <div className={classes.cardRightButton} style={{ margin: '0px 0px 0px 10px' }}>
                        <EditarIcon tipo="BUTTON" fill={theme.palette.primary.main} />
                    </div>
                </Tooltip>
            </DefaultCard>
        </>
    );
};
