import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.grey[100],
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    defaultContainer: {
        position: "relative",
        width: "100%",
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden"
    },
    listContainer: {
        "& .card": {
            margin: theme.spacing(1),
        }
    },
    list: {
        flex: "1 1 100%",
        overflowX: "hidden",
        overflow: "auto"
    },
    cardPesquisa: {
        margin: theme.spacing(1),
        borderRadius: '10px',
        padding: '2rem 0',
        background: theme.palette.background.paper,
        "& form": {
            marginTop: 20
        },
        "& .celula-grid-value": {
            whiteSpace: "normal",
            fontSize: '1rem',
            [theme.breakpoints.up("lg")]: {
                fontSize: "1.2rem",
            },
        },
        "& .info-icon-pesquisa": {
            width: "42px",
            height: "42px",
            margin: theme.spacing(1),
            fill: theme.palette.text.primary,
        }
    },
}));