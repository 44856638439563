import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { makeUtilClasses } from 'views';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useForm, Controller } from 'react-hook-form';
import { picker } from 'utils/picker';
import { ParametroSistemaModel } from 'model/api/gestao/parametro-sistema/parametro-sistema-model';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormParametroValidation } from './form-parametro-validation';

export const FormParametro = forwardRef<
  DefaultFormRefs<ParametroSistemaModel>,
  DefaultFormProps<ParametroSistemaModel>
>(
  (
    { loading, onSubmit, ...props }: DefaultFormProps<ParametroSistemaModel>,
    ref,
  ) => {
    const { FormParametroValidationYup } = useFormParametroValidation();
    const [model, setModel] = useState<ParametroSistemaModel>(new ParametroSistemaModel())

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset
    } = useForm<ParametroSistemaModel>({
      resolver: yupResolver(FormParametroValidationYup),
      defaultValues: { ...model },
      criteriaMode: 'all',
      mode: 'onChange',
    });
    const utilClasses = makeUtilClasses();

    const submitNovoModulo = useCallback(
      (data: ParametroSistemaModel) => {
        const model = picker<ParametroSistemaModel>(data,
          new ParametroSistemaModel())
        onSubmit(model);
      },
      [onSubmit],
    );

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitNovoModulo)();
      },
      resetForm: () => {

        reset();
      },
      fillForm: (values: ParametroSistemaModel) => {
        setModel(values)
        reset({ ...values });
      },
    }));

    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer} style={{ position: 'relative' }}>
            {loading && props.showLoading ? (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="FULLSIZED" />
              </div>
            ) : null}

            <form
              onSubmit={handleSubmit(submitNovoModulo)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <Controller
                    name="nome"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="Nome"
                        tipo="TEXTO"
                        autoComplete='new-password'
                        allowSubmit
                        fullWidth={true}
                        error={Boolean(
                          errors.nome && errors.nome.message,
                        )}
                        helperText={
                          touchedFields.nome || errors.nome
                            ? errors.nome?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="valor"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="Valor"
                        tipo="TEXTO"
                        allowSubmit
                        fullWidth={true}
                        error={Boolean(
                          errors.valor && errors.valor.message,
                        )}
                        helperText={
                          touchedFields.valor || errors.valor
                            ? errors.valor?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
