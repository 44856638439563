import { Grid, Typography } from '@material-ui/core';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useThemeQueries } from 'views/theme';
import { useStyles } from './card-historico-fatura-styles'
import { toDateString } from 'utils/to-date';
import { CardHistoricoFaturaProps } from './card-historico-fatura-props';
import { toCurrency } from 'utils/toCurrency';

export const CardHistoricoFatura = ({
    model,
}: CardHistoricoFaturaProps) => {
    const classes = useDefaultCardStyles();
    const pessoaClasses = useStyles();
    const { md } = useThemeQueries()


    return (
        <>
            <DefaultCard
                className={pessoaClasses.card}
            >
                <Grid container spacing={md ? 1 : 2} className={`${classes.cardContent} ${pessoaClasses.gridContainer}`}>
                    <Grid item xs={12} md={3} className={pessoaClasses.nome}>
                        <Typography color="textPrimary" variant="caption">
                            Data e Hora
                        </Typography>
                        <Typography
                            color='textPrimary'
                            variant="body1"
                        >
                            <b>{toDateString(model.data, 'DD/MM/yyyy HH:mm')}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography color="textPrimary" variant="caption">
                            Descrição
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                        >
                            {model.descricao}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography color="textPrimary" variant="caption">
                            Valor
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                        >
                            {toCurrency(model.valor)}
                        </Typography>
                    </Grid>
                </Grid>
            </DefaultCard>
        </>
    );
};