import { ContratoDadosModel } from "model/api/gestao/contrato/contrato-dados-model";
import { ContratoModuloModel } from "model/api/gestao/contrato/contrato-modulo-model";
import { PlanoModel } from "model/api/gestao/plano/plano-model";
import { ModuloModel } from "model/api/gestao/sistemas/modulo-model";
import { EnumTipoCobrancaRevenda } from "model/enums/enum-tipo-cobranca-revenda";
import { guidEmpty } from "utils/guid-empty";

export class ContratoFormPrimeiraFaseModel {
    constructor(
        public id: string = guidEmpty()
    ) { }
}

export class ContratoFormModel {
    constructor(
        public id: string = guidEmpty(),
        public clienteId: string = guidEmpty(),
        public sistemaId: string = '',
        public ambienteId: string = '',
        public ambienteNome: string = '',
        public planoId: string | null = null,
        public tabelaPrecoId: string = guidEmpty(),
        public melhorDia: number | null = null,
        public dataExpiracao: string | null = null,
        public valorCalculado: number = 0,
        public valor: number = 0,
        public dominio: string | null = null,
        public modulos: ContratoFormModuloModel[] = [],
        public formaPagamentoId: string | null = null,
    ) { }
}

export class ContratoFormAlternativoModel {
    constructor(
        public id: string = guidEmpty(),
        public clienteId: string = guidEmpty(),
        public sistemaId: string = guidEmpty(),
        public ambienteId: string = '',
        public ambienteNome: string = '',
        public planoId: string | null = null,
        public tabelaPrecoId: string = guidEmpty(),
        public melhorDia: number | null = null,
        public dataExpiracao: string | null = null,
        public valorCalculado: number = 0,
        public valorCusto: number = 0,
        public valorDesconto: number = 0,
        public valorContrato: number = 0,
        public valor: number = 0,
        public dominio: string | null = null,
        public modulos: ContratoFormModuloModel[] = [],
        public formaPagamentoId: string | null = null,
        public revendaId: string = guidEmpty(),
        public tipoCobrancaRevenda: EnumTipoCobrancaRevenda = EnumTipoCobrancaRevenda.ClienteFinal,
        public formaPagamentoNome: string = '',
        public dadosTela: ContratoDadosModel = new ContratoDadosModel()
    ) { }
}

export class ContratoPutModel {
    constructor(
        public id: string = guidEmpty(),
        public clienteId: string = guidEmpty(),
        public sistemaId: string = guidEmpty(),
        public ambienteId: string = guidEmpty(),
        public planoId: string | null = null,
        public tabelaPrecoId: string = guidEmpty(),
        public formaPagamentoId: string | null = null,
        public melhorDia: number | null = null,
        public dataExpiracao: string | null = null,
        public valorCalculado: number = 0,
        public valor: number = 0,
        public modulos: ContratoFormModuloModel[] = [],
    ) { }
}
export class ContratoFormEdicaoModel {
    constructor(
        public id: string = guidEmpty(),
        public clienteId: string = guidEmpty(),
        public sistemaId: string = guidEmpty(),
        public ambienteId : string = guidEmpty(),
        public ambienteNome : string = '',
        public sistemaNome: string = '',
        public planoId: string | null = null,
        public tabelaPrecoId: string = guidEmpty(),
        public tabelaPreco: string = '',
        public melhorDia: number | null = null,
        public dataExpiracao: string | null = null,
        public valorCalculado: number = 0,
        public valorDesconto: number = 0,
        public valorContrato: number = 0,
        public valorCusto: number = 0,
        public valor: number = 0,
        public dominio: string = '',
        public modulos: ContratoModuloModel[] = [],
        public plano: PlanoModel = new PlanoModel(),
        public formaPagamentoId: string | null = null,
        public revendaId: string = guidEmpty(),
        public tipoCobrancaRevenda: EnumTipoCobrancaRevenda = EnumTipoCobrancaRevenda.ClienteFinal,
        public formaPagamentoNome: string = '',
        public dadosTela: ContratoDadosModel = new ContratoDadosModel()
    ) { }
}

export class ContratoFormModuloModel {
    constructor(
        public id: string = guidEmpty(),
        public moduloId: string = guidEmpty(),
        public quantidadeContratada: number = 0,
        public codigo: number = 0,
    ) { }
}

export class ContratoFormModuloCardModel {
    constructor(
        public quantidadeContratada: number = 0,
        public valor: number = 0,
        public valorCustoOriginal: number = 0,
        public percAdicional: number = 0,
        public valorCusto: number = 0,
        public percAdicionalCusto: number = 0,
        public modulo: ModuloModel = new ModuloModel(),
        public editavel: boolean = false
    ) { }
}


export class ContratoFormModuloUtilModel {
    constructor(
        public moduloId: string = guidEmpty(),
        public quantidadeContratada: number = 0,
        public codigo: number = 0,
        public nome: string = '',
        public quantidadeMaxima: number = 0,
        public quantidadeMinima: number = 0,
        public quantidadePadrao: number = 0
    ) { }
}