import { isEmpty } from "lodash";

import { DefaultModal } from 'views/components/modals/components/default-modal';
import { PlanoCadastro } from "./components/plano-cadastro/plano-cadastro";

type Props = {
    openned: boolean;
    id: string;
}

export const PlanoModal = ({ id, openned }: Props) => {
    return (
        <DefaultModal
            minWidth="400px"
            open={openned || false}
            variant={"temporary"}
            anchor="right"
        >
            {openned && isEmpty(id) && <PlanoCadastro/>}
        </DefaultModal>
    );
};