import { VariaveisAmbiente } from 'config';
import { ContratoModel } from 'model/api/gestao/contrato/contrato-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetContratoById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ContratoModel>();

    const getContratoById = useCallback(
        (pessoaId: string, contratoId: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/pessoas/${pessoaId}/Contrato/${contratoId}`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getContratoById,
    };
}
