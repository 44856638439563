import { useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { NotasFiscaisRejeitadasHeader } from './components/notas-fiscais-rejeitadas-header/notas-fiscais-rejeitadas-header';
import { NotasFiscaisRejeitadasList } from './components/notas-fiscais-rejeitadas-list/notas-fiscais-rejeitadas-list';
import { NotasFiscaisRejeitadasListSearchProps } from './components/notas-fiscais-rejeitadas-list/notas-fiscais-rejeitadas-list-search-props';
import { NotasFiscaisRejeitadasEditar } from './components/notas-fiscais-rejeitadas-editar/notas-fiscais-rejeitadas-editar';
import { NotaFiscalModel } from 'model/api/gestao/nota-fiscal/nota-fiscal-model';
import { guidEmpty } from 'utils/guid-empty';
import { useParams } from 'react-router-dom';

interface paramProps {
    parametro: string
  }

export const NotasFiscaisRejeitadasPage = () => {
  const classes = useDefaultCadastroStyles();

  const [searchProps, setSearchProps] = useState<NotasFiscaisRejeitadasListSearchProps>({
    dataFinal: '',
    dataInicial: ''
  })

  const [selectedNF, setSelectedNF] = useState<NotaFiscalModel>(new NotaFiscalModel());
  const {parametro} = useParams<paramProps>()

  const nfRejeitadasPage = useMemo(() => {
    if(parametro && selectedNF.id !== guidEmpty()){
        return <NotasFiscaisRejeitadasEditar model={selectedNF}/>
    }

    return <NotasFiscaisRejeitadasList searchProps={searchProps} setSelectedNF={setSelectedNF}/>
  }, [parametro, searchProps, selectedNF])

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <NotasFiscaisRejeitadasHeader setSearchProps={setSearchProps} titulo={parametro &&  selectedNF.id !== guidEmpty()? 
        'Visualizar Nota Fiscal' : 'Notas Fiscais Rejeitadas'}/>
      </Grid>
      {nfRejeitadasPage}
    </Grid>
  );
};

export default NotasFiscaisRejeitadasPage;