import { Box, Button, Grid, } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useThemeQueries } from 'views/theme';
import { useToastSaurus } from 'services/app';
import { CircularLoading } from 'views/components/utils';
import { useStyles } from './dialog-funcionario-contrato-styles';
import { useGetContratoById, usePutAtualizarContrato } from 'data/api/gestao/contratos';
import { DefaultFormRefs } from 'views/components/form/utils';
import { ContratoFormAlternativoModel, ContratoFormEdicaoModel, ContratoPutModel } from 'model/app/forms/contrato/contrato-form-model';
import { ContratoModel } from 'model/api/gestao/contrato/contrato-model';
import { FormContratoFuncionario } from 'views/components/form/master/contrato/contrato-funcionario/form-contrato-funcionario';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { VoltarIcon } from 'views/components/icons';
import { guidEmpty } from 'utils/guid-empty';
import { isEqual } from 'utils/is-equal';
import { LinkIcon } from 'views/components/icons/link-icon';
import { DialogFilial } from '../dialog-filial/dialog-filial';

interface DialogFuncionarioContratoProps {
  openned: boolean;
  id: string;
  pessoaId: string;
  closeDialog: () => void;
}

export const DialogFuncionarioContrato = ({
  openned,
  id,
  pessoaId,
  closeDialog
}: DialogFuncionarioContratoProps) => {
  const classes = useStyles();
  const modalClasses = useModalStyles();
  const { isMobile } = useThemeQueries()
  const { showToast } = useToastSaurus()

  const { getContratoById, carregando: carregandoGet } = useGetContratoById()
  const { putAtualizarContrato, carregando: carregandoPut } = usePutAtualizarContrato();

  const carregando = carregandoGet || carregandoPut

  const refContratoModel = useRef<ContratoModel>(new ContratoModel())

  const refEditForm = useRef<DefaultFormRefs<ContratoFormAlternativoModel>>(null);
  const [modelForm, setModelForm] = useState<ContratoFormAlternativoModel>(new ContratoFormAlternativoModel());
  const preencherForm = useCallback((model: ContratoFormAlternativoModel) => {
    refEditForm.current?.fillForm(model)
  }, [])

  useEffect(() => {
    preencherForm(modelForm)
  }, [modelForm, preencherForm])

  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const saveContrato = useCallback(
    async (model: ContratoFormEdicaoModel) => {
      const contrato: ContratoPutModel = {
        clienteId: model.clienteId,
        ambienteId: model.ambienteId,
        dataExpiracao: model.dataExpiracao,
        id: model.id,
        melhorDia: model.melhorDia,
        planoId: model.planoId === guidEmpty() ? null : model.planoId,
        sistemaId: model.sistemaId,
        tabelaPrecoId: model.tabelaPrecoId,
        valor: model.valorContrato,
        valorCalculado: model.valorCalculado,
        formaPagamentoId: model.formaPagamentoId,
        modulos: model.modulos.map(item => {
          return {
            id: item.id,
            codigo: item.codigo,
            moduloId: item.moduloId,
            quantidadeContratada: item.quantidadeContratada
          }
        })
      }

      const ret = await putAtualizarContrato(contrato);

      if (ret.erro) {
        throw ret.erro;
      }
    },
    [putAtualizarContrato],
  );

  const handleSubmit = useCallback(
    async (model: ContratoFormEdicaoModel, beforeModel: ContratoFormEdicaoModel) => {
      try {

        const contratoEqual = isEqual(model, beforeModel)

        if (contratoEqual) {
          showToast('info', "Nenhuma informação foi alterada")
          return
        }

        if (model.valorCusto > model.valorContrato) {
          showToast('error', 'O valor do custo não pode ser maior que o valor do contrato')
          return
        }

        await saveContrato(model);

        closeDialog()

        showToast('success', "Contrato Editado!")
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [closeDialog, saveContrato, showToast],
  );


  const contratoWrapper = useCallback(async () => {
    try {
      const res = await getContratoById(pessoaId, id)

      if (res.erro) throw res.erro
      if (!res.resultado) return

      const ret = res.resultado.data as ContratoModel

      refContratoModel.current = ret

      const contratoAlternativo: ContratoFormEdicaoModel = {
        clienteId: ret.clienteId,
        dataExpiracao: ret.dataExpiracao,
        id: ret.id,
        melhorDia: ret.melhorDia,
        modulos: [...ret.modulos],
        planoId: ret.planoId,
        sistemaId: ret.sistemaId,
        sistemaNome: ret.sistemaNome,
        tabelaPrecoId: ret.tabelaPrecoId,
        tabelaPreco: ret.tabelaPreco,
        valor: ret.valorVigente,
        valorCalculado: ret.valorCalculado,
        valorContrato: ret.valorVigente,
        valorDesconto: ret.valorCalculado - ret.valorVigente,
        valorCusto: ret.valorCusto,
        plano: ret.plano,
        dominio: ret.dominio,
        ambienteId: ret.ambienteId,
        ambienteNome: ret.ambienteNome,
        formaPagamentoId: ret.formaPagamentoId,
        revendaId: ret.revendaId,
        tipoCobrancaRevenda: ret.tipoCobrancaRevenda,
        formaPagamentoNome: ret.formaPagamentoNome,
        dadosTela: ret.dadosTela
      }

      setModelForm(contratoAlternativo)

    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [getContratoById, id, pessoaId, showToast])

  useEffect(() => {
    if (openned)
      contratoWrapper();
  }, [contratoWrapper, openned])

  const closeDialogFilial = useCallback(() => {
    setOpenDialog(false)
  }, [])

  return (
    <DialogSaurus
      aberto={openned}
      titulo='Visualizar Contrato'
      tamanho='lg'
      fullScreen={isMobile}
      bottomArea={
        <div className={modalClasses.acoes} style={{ paddingBottom: '1rem' }}>
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent='space-between'>
              {modelForm.sistemaId === 'b2a22b5d-b681-43e1-b110-44bc2f8809b9' && <Grid item xs={12} md={6}>
                <Button variant='outlined' color='primary' fullWidth={isMobile} onClick={() => {
                  window.open(`https://retaguarda.app/${modelForm.dominio}`, '_blank');
                }}>
                  <LinkIcon tipo='BUTTON' />
                  Visitar Domínio
                </Button>
              </Grid>}
              <Grid item xs={12} md={modelForm.sistemaId === 'b2a22b5d-b681-43e1-b110-44bc2f8809b9' ? 6 : 12} style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={closeDialog}
                  fullWidth={isMobile}
                >
                  <VoltarIcon tipo='BUTTON' />
                  Voltar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      }
    >
      <Box className={classes.cardContainer} style={{ minHeight: '100%' }}>
        <Box className={classes.cardContent}>
          <FormContratoFuncionario
            ref={refEditForm}
            loading={carregando}
            onSubmit={handleSubmit}
            openDialog={() => setOpenDialog(true)}
            showLoading={carregando}
            status={refContratoModel.current.ativo}
            dataCancelamento={refContratoModel.current.dataCancelamento}
            tipoCobranca={refContratoModel.current.tipoCobrancaRevenda}
            motivoCancelamento={refContratoModel.current.motivoCancelamento}
            refContratoModel={refContratoModel}
          />
        </Box>
      </Box>
      {carregando && <CircularLoading tipo='FULLSIZED' />}
      <DialogFilial closeDialog={closeDialogFilial} contratoModel={modelForm} openned={openDialog} />
    </DialogSaurus>
  );
};
