import { Grid } from "@material-ui/core";
import { PagamentoModel } from "model/api/gestao/pagamento/pagamento-model";
import { CardNaoEncontrado } from "views/components/cards";
import { CardPagamento } from "views/components/cards/card-pagamento";
import { InformacaoIcon } from "views/components/icons";

export interface PagamentoGridProps {
  list: Array<PagamentoModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (id: string) => any;
  onCardChecked: (id: string) => any;
}

export const PagamentoListData = (props: PagamentoGridProps) => {

  const onCardSelected = (id: string) => {
    props.onCardSelected(id);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhuma Forma de Pagamento Encontrada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((instituicao, index) => {
          return (
            <Grid item xs={12} sm={6} lg={4}>
              <CardPagamento
                selected={
                  props.selectedList.filter((item) => item === instituicao.id).length >
                    0
                    ? true
                    : false
                }
                onCheck={onCardChecked}
                onClick={onCardSelected}
                model={instituicao}
                key={index}
              />
            </Grid>
          );
        })}
    </>
  );
};
