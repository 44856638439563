import { useCallback, useState, useEffect } from 'react';
import { useCadastros, useToastSaurus } from 'services/app';
import {
} from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { usePostAdicionarContrato } from 'data/api/gestao/contratos';
import { ContratoFormAlternativoModel, ContratoFormModel } from 'model/app/forms/contrato/contrato-form-model';
import { ContratoPrimeiraFase } from './components/contrato-primeira-fase';
import { ContratoSegundaFase } from './components/contrato-segunda-fase';
import { picker } from 'utils/picker';

type Props = {
  pessoaId: string;
  revendaId: string;
  mostrarFases: 1 | 2;
  temVendedor: boolean;
  setMostrarFases: (nmr: 1 | 2) => void;
  setBottomArea: (node: React.ReactNode) => void;
  setSistemaNome: (nome: string) => void;
}

export const ContratoCadastro = ({ pessoaId, mostrarFases, revendaId, setMostrarFases, setBottomArea, setSistemaNome,temVendedor }: Props) => {

  const { postAdicionarContrato, carregando } = usePostAdicionarContrato();

  const classes = useModalStyles();
  const [fase, setFase] = useState<1 | 2>(1)
  const [contratoForm, setContratoForm] = useState<ContratoFormModel>(new ContratoFormModel())

  const { showToast } = useToastSaurus();

  const {fecharCadastroContrato} = useCadastros()

  const saveNewContrato = useCallback(
    async (model: ContratoFormModel) => {

      const ret = await postAdicionarContrato(model);

      if (ret.erro) {
        throw ret.erro;
      }

      return ret.resultado?.data
    },
    [postAdicionarContrato],
  );

  const submitPrimeiraFase = useCallback(
    async (model: ContratoFormModel) => {
      
      setContratoForm(prev => {
        const object = {
          ...prev,
          sistemaId: model.sistemaId
        }

        return object
      })

      setFase(2)
    }, []);

  const handleSubmit = useCallback(async (model: ContratoFormAlternativoModel) => {
    try {

      const contrato = picker<ContratoFormModel>(model, new ContratoFormModel())

      contrato.valor = model.valorContrato
      contrato.modulos = model.modulos

      await saveNewContrato(contrato)

      fecharCadastroContrato();

      showToast('success', "Novo Contrato Adicionado.")

    } catch (err: any) {
      showToast('error', err.message)
    }

  }, [fecharCadastroContrato, saveNewContrato, showToast])

  useEffect(() => {
    if (fase === 2 && mostrarFases !== 2) {
      setTimeout(() => {
        setMostrarFases(2)
      }, 300)
    } else if (fase === 1 && mostrarFases !== 1) {
      setTimeout(() => {
        setMostrarFases(1)
      }, 300)
    }

  }, [fase, mostrarFases, setMostrarFases])

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      {mostrarFases === 1 && (
        <ContratoPrimeiraFase
          fase={fase}
          contratoForm={contratoForm}
          carregando={carregando}
          handleSubmit={submitPrimeiraFase}
          setBottomArea={setBottomArea}
          revendaId={revendaId}
          pessoaId={pessoaId}
          setSistemaNome={setSistemaNome}
        />
      )}
      {mostrarFases === 2 && (
        <ContratoSegundaFase
          contratoForm={contratoForm}
          carregando={carregando}
          fase={fase}
          setFase={setFase}
          handleSubmit={handleSubmit}
          pessoaId={pessoaId}
          revendaId={revendaId}
          setBottomArea={setBottomArea}
          temVendedor={temVendedor}
        />
      )}
    </div >
  );
};
