import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  footer: {
    width: '100%',
    display: 'flex',
    background: '#FFF',
    borderTop: '1px solid #e0e0e0',
    maxHeight: '100px',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 15px 7px 15px',
    boxShadow: '5px 10px 18px #888888',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      display: 'flex',
      background: '#FFF',
      borderTop: '1px solid #e0e0e0',
      maxHeight: '100px',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 0,
      boxShadow: '5px 10px 18px #888888'
    },
    '& p': {
      fontSize: 13
    },
    '& span': {
      fontSize: 11
    },
  },
  saldoPositivo: {
    color: theme.palette.success.dark
  },
  saldoNegativo: {
    color: theme.palette.error.main
  },
  tooltipDetalhes: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.paper
  },
  semiBold: {
    fontWeight: 500
  },
  bold: {
    fontWeight: 800
  },
  tooltip: {
    background: '#fff',
    boxShadow: '1px 2px 3px 1px #00000030'
  },
  textIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  miniIcon: {
    height: '1rem',
    width: '1rem',
    fill: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));