import { Box, Grid, GridSize, Typography } from '@material-ui/core';
import { } from 'model/api/gestao/nota-fiscal/emitir-nota-fiscal-model';
import { toDateString } from 'utils/to-date';
import { useStyles } from './cliente-fatura-styles'
import { FaturaFormModel } from 'model/app/forms/fatura/fatura-form-model';
import { toCurrency } from 'utils/toCurrency';
import { useSessaoAtual } from 'services/app';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import classNames from 'classnames';
import { EnumTipoFatura } from 'model/enums/enum-tipo-fatura';

interface FaturaValoresProps {
    model: FaturaFormModel;
    formaPagamentoNome: string;
    tipoFatura: EnumTipoFatura;
}

interface ItemProps {
    label: string;
    conteudo: string;
    traço?: boolean;
    xs?: GridSize;
}

export default function FaturaValores({
    model,
    formaPagamentoNome,
    tipoFatura
}: FaturaValoresProps) {
    const { tipoUsuario } = useSessaoAtual();
    const isLicenca = tipoFatura === EnumTipoFatura.Licença
    // const temDesconto = model.valor !== model.valorOriginal && tipoFatura === EnumTipoFatura.Revenda
    return (
        <Box>
            <Grid container spacing={1}>
                <Item label='Número da Fatura' conteudo={model.nossoNumero.toString()} />
                {!isLicenca && <Item label='Forma de Pagamento' conteudo={formaPagamentoNome} />}
                <Item label='Data de Vencimento' conteudo={toDateString(model.dataVencimento)!} />
                {!isLicenca && <>
                    <Item label='Encargo' conteudo={toCurrency(model.encargo).toString()} />
                    <Item label='Multa e Juros' conteudo={toCurrency(model.multaJuros).toString()} />
                    {/* {temDesconto && <Item label='Valor Original' conteudo={toCurrency(model.valorOriginal)} traço xs={6}/>} */}
                    <Item label='Valor' conteudo={toCurrency(model.valor).toString()} xs={6} />
                </>}
                {(tipoUsuario() === EnumTipoPessoas.SoftwareHouse || tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouse || tipoUsuario() === EnumTipoPessoas.Revendedor) && (
                    <Item label='Custo' conteudo={toCurrency(model.valorCusto).toString()} />
                )}
            </Grid>
        </Box>
    );
}

const Item = (props: ItemProps) => {
    const classes = useStyles()

    return <Grid item xs={props.xs || 12}>
        <Typography variant='caption' color='textSecondary'>{props.label}</Typography>
        <Typography variant='body1' className={classNames(classes.boldText, {
            [classes.dashText]: props.traço
        })}>{props.conteudo}</Typography>
    </Grid>
}