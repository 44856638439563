import { guidEmpty } from "utils/guid-empty";
import { ComissaoPendenteModel } from "./comissao-pendente-model";

export class ComissaoPaiModel {
    constructor(
        public id: string = guidEmpty(),
        public valorTotal: number = 0,
        public observacao: string = '',
        public comissionadoId: string = '',
        public comissionadoNome: string = '',
        public comissionadoCpfCnpj: string = '',
        public dataPagamento: string = '',
        public contabilizaRelatorios: boolean = false,
        public faturasComissoes: ComissaoPendenteModel[] = []
    ) { }
}
