import { Box, Typography } from "@material-ui/core";
import { useStyles } from './card-whitelabel-styles'
import { CadeadoIcon, EditarIcon, NovoIcon } from "views/components/icons";
import { PessoaSistemaModel } from "model/api/gestao/pessoa/pessoa-sistema-model";
import { useSessaoAtual } from "services/app";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";

interface CardWhitelabelProps {
    model: PessoaSistemaModel;
    existe: boolean;
    onClick: (model: PessoaSistemaModel, existe: boolean) => any;
}

export const CardWhitelabel = ({
    model,
    existe,
    onClick
}: CardWhitelabelProps) => {

    const classes = useStyles({
        existe
    });

    const { tipoUsuario } = useSessaoAtual();

    const isFuncionario = tipoUsuario() === EnumTipoPessoas.FuncionarioSoftwareHouse

    const imagem = atob(model.sistema.urlImagem)

    return (
        <Box className={classes.container} onClick={() => {
            if (!existe && isFuncionario) return
            onClick(model, existe)
        }}>
            <Box className={classes.card}>
                <img src={imagem} alt={model.sistema.nome} />
                <Typography align='center'>{model.sistema.nome}</Typography>
            </Box>
            {existe ? <EditarIcon class={classes.icon} /> : (!isFuncionario ? <NovoIcon class={classes.icon} /> : <CadeadoIcon class={classes.icon}/>)}
        </Box>
    )
}