import * as Yup from 'yup';

export const useFormCancelamentoFaturaValidation = () => {
    const FormCancelamentoFaturaYupValidation = Yup.object().shape({
        motivo: Yup.string().nullable(true).min(15, 'Mensagem deve conter no mínimo 15 caracteres.').transform((_, val) => val === '' ? null : val)
    });

    return {
        FormCancelamentoFaturaYupValidation
    }
}

