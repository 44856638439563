import React, { useRef, useCallback, useState } from "react";
import { FormHelperText } from "@material-ui/core";
import { isEmpty } from "lodash";
import classNames from "classnames";
import { useStyles } from "./imagem-input-styles";
import { ImagemInputProps } from "./imagem-input-props";
import { LixoIcon } from "../../../icons/lixo-icon";
import { ButtonFab } from "../../buttons/button-fab/button-fab";
import { OutrosIcon } from "../../../icons/outros-icon";

export const ImagemInput = (props: ImagemInputProps) => {
  const ImagemBlobParaBase64 = (arquivo: File): Promise<string> => {
    return new Promise((resolve) => {
      if (!arquivo) {
        resolve("");
      }

      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target?.result?.toString() || "");
      };

      reader.readAsDataURL(arquivo);
    });
  };

  const classes = useStyles(props);
  const { accept, onChange, value, className, helperText, error, name, onBlur } = props;

  const inputFileRef = useRef<HTMLInputElement>(null);
  const [carregandoImportacao, setCarregandoImportacao] = useState(false);

  const abrirSelecaoDeArquivo = useCallback(() => {
    inputFileRef.current?.click();
  }, []);

  const carregarArquivo = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isEmpty(event.target.files) || carregandoImportacao) {
        return;
      }

      setCarregandoImportacao(true);

      const arquivo = event.target.files![0];
      const base64 = await ImagemBlobParaBase64(arquivo);
      const dados = {
        arquivo,
        base64,
      };

      if (onChange) {
        onChange(dados);
      }

      setTimeout(() => {
        setCarregandoImportacao(false);
      }, 500);
    },
    [carregandoImportacao, onChange]
  );

  const limparArquivo = useCallback(() => {
    const dados = {
      arquivo: undefined,
      base64: undefined,
    };

    if (onChange) {
      onChange(dados);
    }
  }, [onChange]);

  const onBlurWrap = useCallback(
    (event?: any) => {
      if (onBlur) {
        onBlur(name || "");
      }
    },
    [onBlur, name]
  );
  const addDefaultSrc = useCallback(
    (e: any) => {
      e.target.src = props.loadErrorImage;
    },
    [props.loadErrorImage]
  );

  return (
    <div className={classNames(classes.root, className)} onBlur={onBlurWrap}>
      <input
        disabled={props.disabled}
        ref={inputFileRef}
        name={name}
        type="file"
        multiple={false}
        onChange={carregarArquivo}
        accept={accept}
        hidden
      />
      {!value && !props.disabled && (
        <ButtonFab
          size="small"
          tooltip={value ? "Importar nova imagem" : "Importar Imagem"}
          icon={<OutrosIcon tipo="BUTTON_FAB" />}
          onClick={abrirSelecaoDeArquivo}
        />
      )}
      {value && !props.disabled && (
        <ButtonFab
          size="small"
          tooltip="Remover Imagem"
          icon={<LixoIcon tipo="BUTTON_FAB" />}
          onClick={limparArquivo}
        />
      )}
      <img
        src={isEmpty(value) ? props.loadErrorImage : value}
        onError={addDefaultSrc}
        alt="Imagem Carregada"
        className={classes.img}
      />
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </div>
  );
};
