import { EnumSituacaoSistema } from "model/enums/enum-situacao-sistema";
import { guidEmpty } from "utils/guid-empty";

export class SistemasModel {
  constructor(
    public id: string = guidEmpty(),
    public nome: string = "",
    public descricao: string = "",
    public situacao: EnumSituacaoSistema = EnumSituacaoSistema.Ativo,
    public urlImagem: string = "",
    public qtdModulos: number = 0
  ) { }
}
