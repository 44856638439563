import { VariaveisAmbiente } from 'config';
import { ComissaoPutModel } from 'model/api/gestao/comissao/comissao-put-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePutComissao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putComissao = useCallback(
        (model: ComissaoPutModel) =>
            invocarApi({
                url: `/${VariaveisAmbiente.apiVersion}/comissao`,
                method: 'PUT',
                data: model,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putComissao,
    };
}
