import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column'
        }
    },
    cardImg: {
        display: 'flex',
        justifyContent: 'center',
        paddingRight: 16,
        [theme.breakpoints.down("sm")]: {
            padding: 0,
            paddingBottom: 16
        }
    }
}))