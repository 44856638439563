import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    cardNaoEncontrado: {
        margin: theme.spacing(1),
        border: '2px dashed #949494',
        borderRadius: '10px',
        padding: '2rem 0',
        "& .celula-grid-value": {
            whiteSpace: "normal",
            fontSize: '1rem',
            [theme.breakpoints.up("lg")]: {
                fontSize: "1.2rem",
            },
        },
        "& svg": {
            width: "42px",
            height: "42px",
            margin: theme.spacing(1),
            fill: theme.palette.text.primary,
        }
    },
    editIcon: {
        borderLeft: '2px solid #E6E6E6',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 10px',
        margin: '10px 0',
    }
}))