
import { useMemo } from 'react';
import * as Yup from 'yup';


export const useFormPerguntaApuracaoValidation = () => {


  const FormPerguntaApuracaoValidation = useMemo(() => {
    return Yup.object().shape({
      
    });
  }, [])

  return {
    FormPerguntaApuracaoValidation,
  }
}