import { EnumTipoControleModulo } from "model/enums/enum-tipo-controle-modulo";
import { guidEmpty } from "utils/guid-empty";

export class ModuloModel {
  constructor(
    public id: string = guidEmpty(),
    public nome: string = '',
    public tipoCalculo: number = 0,
    public codModuloBaseCalculo: string = '',
    public codigo: number = 0,
    public descricao: string = '',
    public quantidadeMinima: number = 0,
    public quantidadeMaxima: number = 0,
    public quantidadePadrao: number = 0,
    public tipoControle: EnumTipoControleModulo = EnumTipoControleModulo.NaoControla,
    public tipoTerminal: string = ''
  ) { }
}
