import { Box, Button, Grid, useTheme } from '@material-ui/core';
import { useStyles } from '../dialog-filial-styles';
import { CircularLoading } from 'views';
import { useCallback, useEffect, useRef } from 'react';
import { useToastSaurus } from 'services/app';
import { DefaultFormRefs } from 'views/components/form/utils';
import { FilialModel } from 'model/api/gestao/contrato/contrato-filial-model';
import { ContratoFormModel } from 'model/app/forms/contrato/contrato-form-model';
import { usePostAdicionarFilial } from 'data/api/gestao/filiais/post-filiais';
import { FormContratoFilial } from 'views/components/form/master/contrato/contrato-filial/form-contrato-filial';
import { SalvarIcon } from 'views/components/icons';
import { DialogSaurus } from '../../dialog-saurus/dialog-saurus';
import { FormContratoFilialEditar } from 'views/components/form/master/contrato/contrato-filial/form-contrato-filial-editar';
import { usePutAtualizarFilial } from 'data/api/gestao/filiais/put-filiais';
import { CloseIcon } from 'views/components/icons/close-icon';
import { FilialFormModel } from 'model/app/forms/filial/filial-form-model';


interface DialogFormFilialProps {
    openned: boolean;
    closeDialog: () => void;
    model: FilialModel;
    edit: boolean;
    updateList: () => void;
    contratoModel?: ContratoFormModel;
}

export const DialogFormFilial = ({
    openned,
    closeDialog,
    model,
    edit,
    updateList,
    contratoModel
}: DialogFormFilialProps) => {
    const classes = useStyles();
    const theme = useTheme();

    const { putAtualizarFilial, carregando: carregandoPut } = usePutAtualizarFilial()
    const { postAdicionarFilial, carregando: carregandoPost } = usePostAdicionarFilial()

    const carregando = carregandoPost || carregandoPut

    const { showToast } = useToastSaurus()

    const formRef = useRef<DefaultFormRefs<FilialFormModel>>(null)

    const recarregarForm = useCallback(() => {
        formRef.current?.fillForm(model)
    }, [model])


    useEffect(() => {
        if (contratoModel)
            recarregarForm();
    }, [contratoModel, recarregarForm, openned])

    const handleSubmitPut = useCallback(async (model: FilialFormModel) => {
        try {
            const res = await putAtualizarFilial(model.contratoId, model);

            if (res.erro) throw new Error(res.erro)

            showToast('success', 'Empresa Alterada')
            closeDialog()
            updateList()
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [closeDialog, putAtualizarFilial, showToast, updateList])

    const handleSubmitPost = useCallback(async (model: FilialFormModel) => {
        try {
            const res = await postAdicionarFilial(contratoModel!.id, model)

            if (res.erro) throw new Error(res.erro)

            showToast('success', 'Empresa Adicionada.')
            closeDialog()
            updateList()
            formRef.current?.resetForm()
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [closeDialog, contratoModel, postAdicionarFilial, showToast, updateList])

    return (
        <DialogSaurus
            colorTitulo={`${theme.palette.primary.main}`}
            aberto={openned}
            titulo={edit ? 'Alterar Empresa' : 'Nova Empresa'}
            tamanho="sm"
        >
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <Box className={classes.cardContainer}>
                <Box className={classes.cardContent}>

                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ margin: '5px 0' }}>
                            {edit ?
                                <FormContratoFilialEditar
                                    ref={formRef}
                                    loading={false}
                                    showLoading={false}
                                    onSubmit={handleSubmitPut}
                                    model={model}
                                />
                                :
                                <FormContratoFilial
                                    ref={formRef}
                                    loading={false}
                                    onSubmit={handleSubmitPost}
                                    showLoading={false}
                                />}
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', alignSelf: 'center', justifyContent: 'flex-end', margin: '5px 0', gap: '15px' }}>
                            <Button variant='outlined' color='primary' onClick={closeDialog}>
                                <CloseIcon tipo='BUTTON' />
                                Fechar
                            </Button>
                            <Button variant='contained' color='primary' onClick={() => {
                                formRef.current?.submitForm()
                            }}>
                                <SalvarIcon tipo='BUTTON_PRIMARY' />
                                {edit ? 'Alterar' : 'Cadastrar'}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container>
                    </Grid>
                </Box>
            </Box>
        </DialogSaurus>
    );
};
