import { VariaveisAmbiente } from 'config';
import { ApiListModel } from 'model/api/gestao/api-list-model/api-list-model';
import { PessoaInfoModel } from 'model/api/gestao/pessoa/pessoa-info-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetPessoaInfo() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ApiListModel<PessoaInfoModel>>();

    const getPessoaInfo = useCallback(
        async (pessoaId: string, page: number, queryBusca: string) => {
            let queryPage = "";
            if (page) {
                queryPage = "page=" + page.toString();
            }
            const queryFinal = `${queryPage?.length > 0 || queryBusca?.length > 0 ? "?" : ""
                }${queryPage}${queryPage.length > 0 ? "&" : ""}${queryBusca}`;

            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Pessoa/${pessoaId}/info${queryFinal}`,
                method: 'GET',
                enviarTokenUsuario: true
            })
        }
        , [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getPessoaInfo,
    };
}
