import { useCallback } from "react";
import { PrivatePageHeader } from "views/components/headers";
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { MenuIcon } from "views/components/icons";
import { useMenuPrincipal } from "services/app";
import { useThemeQueries } from "views";


export interface LocationProps {
  from: {
    pathname: string,
    page?: number;
  }
}

export const SimulacaoContratoHeader = () => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();

  const leftArea = useCallback(
    () =>
      isMobile ? (<ButtonPrivateHeader
        icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
        tooltip="Menu"
        onClick={abrirMenu}
      ></ButtonPrivateHeader>) : null,
    [isMobile, abrirMenu]
  );

  return (
    <>
      <PrivatePageHeader
        title='Simulação de Contrato'
        leftArea={leftArea()}
      />
    </>
  );
};