import { guidEmpty } from "utils/guid-empty";

export class PessoaSistemaCreateModel {
    constructor(
        public id: string = guidEmpty(),
        public tabelaPrecoId: string = guidEmpty(),
        public pessoaId: string = guidEmpty(),
        public sistemaId: string = guidEmpty(),
        public percentualOverTabela: number = 0,
    ) { }
}