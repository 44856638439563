import React, { useCallback, useRef, useState } from 'react'
import { Button, FormHelperText, Typography } from "@material-ui/core";
import { ArquivoInputProps } from './arquivo-input-props';
import { isEmpty } from 'lodash';
import classNames from "classnames";
import { useStyles } from './arquivo-input-styles';

function ArquivoInput(props: ArquivoInputProps) {
    const ArquivoBlobParaBase64 = (arquivo: File): Promise<string> => {
        return new Promise((resolve) => {
            if (!arquivo) {
                resolve("");
            }

            const reader = new FileReader();

            reader.onload = (event) => {
                resolve((event.target?.result?.toString() || "").replace("data:application/x-pkcs12;base64,", ""));
            };

            reader.readAsDataURL(arquivo);
        });
    };
    const classes = useStyles(props);
    const { accept, onChange, value, className, helperText, error, name, onBlur } = props;

    const inputFileRef = useRef<HTMLInputElement>(null);
    const [carregandoImportacao, setCarregandoImportacao] = useState(false);
    const [nomeArquivo, setNomeArquivo] = useState("")

    const abrirSelecaoDeArquivo = useCallback(() => {
        inputFileRef.current?.click();
    }, []);

    const carregarArquivo = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            if (isEmpty(event.target.files) || carregandoImportacao) {
                return;
            }

            setCarregandoImportacao(true);

            const arquivo = event.target.files![0];
            const base64 = await ArquivoBlobParaBase64(arquivo);
            const dados = {
                arquivo,
                base64,
            };

            if (onChange) {
                onChange(dados);
                setNomeArquivo(dados.arquivo.name)
            }

            setTimeout(() => {
                setCarregandoImportacao(false);
            }, 500);
        },
        [carregandoImportacao, onChange]
    );

    const onBlurWrap = useCallback(
        (event?: any) => {
            if (onBlur) {
                onBlur(name || "");
            }
        },
        [onBlur, name]
    );

    return (
        <div className={classNames(className)} onBlur={onBlurWrap}>
            <Typography variant='caption'>{props.label}</Typography>
            <input
                disabled={props.disabled}
                ref={inputFileRef}
                name={name}
                type="file"
                multiple={false}
                onChange={carregarArquivo}
                accept={accept}
                hidden
            />
            {!value && (
                <>
                    <Button
                        disabled={props.disabled}
                        variant="contained"
                        color="primary"
                        className={classes.selecionarArquivo}
                        fullWidth
                        component="label"
                        onClick={abrirSelecaoDeArquivo}
                    >
                        Selecione um <span style={{ color: '#c3ac6c', marginLeft: 4 }}>arquivo.</span>
                    </Button>
                </>
            )}
            {value && (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.selecionarArquivo}
                        fullWidth
                        component="label"
                        onClick={abrirSelecaoDeArquivo}
                    >
                        <span style={{ color: '#1F9CE4', marginLeft: 4 }}>
                            {nomeArquivo || props.nameArquivo}
                        </span>
                    </Button>

                    <div className={classes.trocarArquivo}>
                        <Button
                            component="label"
                            onClick={abrirSelecaoDeArquivo}
                        >Trocar arquivo</Button>
                    </div>

                </>
            )}
            {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
        </div>
    )
}

export default ArquivoInput
