import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDefaultCadastroStyles } from "../cadastros/components/default-cadastro-styles";
import { AgenteExternoEditar } from "./components/agente-externo-editar/agente-externo-editar";
import { AgenteExternoHeader } from "./components/agente-externo-header/agente-externo-header";
import { AgenteExternoList } from "./components/agente-externo-list/agente-externo-list";
import { AgenteExternoListSearchProps } from "./components/agente-externo-list/agente-externo-list-search-props";

interface ParamsProps {
    parametro: string;
}

export const AgenteExternoPage = () => {
    const classes = useDefaultCadastroStyles();

    const [openPesquisa, setOpenPesquisa] = useState(false);
    const [searchProps, setSearchProps] = useState<AgenteExternoListSearchProps>({
        termo: ''
    });
    const { parametro } = useParams<ParamsProps>()

    const pageSwitch = useMemo(() => {
        switch (parametro) {
            case undefined:
                return <AgenteExternoList searchProps={searchProps} />
            default:
                setOpenPesquisa(false)
                return (
                    <div className={classes.defaultContainer}>
                        <AgenteExternoEditar id={parametro} />
                    </div>
                )
        }
    }, [classes.defaultContainer, parametro, searchProps])

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <AgenteExternoHeader
                    titulo={parametro === undefined
                        ? 'Agente Externo'
                        : parametro === 'adicionar'
                            ? 'Adicionar Agente Externo'
                            : 'Editar Agente Externo'}
                    openPesquisa={openPesquisa}
                    setOpenPesquisa={setOpenPesquisa}
                    setSearchProps={setSearchProps}
                />
            </div>
            {pageSwitch}
        </div>
    );
}

export default AgenteExternoPage;