import { DefaultModal } from 'views/components/modals/components/default-modal';
import { ParametroCadastro } from './components/parametro-cadastro/parametro-cadastro';

type Props = {
  openned: boolean;
  id: string;
}

export const ParametroModal = ({ id, openned }: Props) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={openned || false}
      variant={"temporary"}
      anchor="right"
    >
      <ParametroCadastro sistemaId={id} />
    </DefaultModal>
  );
};
