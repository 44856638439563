import { useMemo, useState } from 'react';

import { PlanosHeader } from './components/planos-header/planos-header';

import { PlanosList } from './components/planos-list/planos-list';
import { PlanosListSearchProps } from './components/planos-list/planos-list-search-props';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { useCadastros } from 'services/app';
import { ButtonFab } from "views/components/controles";
import { NovoIcon } from "views/components/icons";

export const PlanosPage = () => {
  const classes = useDefaultCadastroStyles();
  const { abrirCadastroPlano } = useCadastros();

  const [openPesquisa, setOpenPesquisa] = useState(false);
  const [searchProps, setSearchProps] = useState<PlanosListSearchProps>({
    termo: '',
    situacao: -1,
  });

  const planosList = useMemo(() => <PlanosList searchProps={searchProps} />, [searchProps])

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <PlanosHeader
          titulo='Planos'
          openPesquisa={openPesquisa}
          setSearchProps={setSearchProps}
          setOpenPesquisa={setOpenPesquisa}
        />
      </div>
      {planosList}
      <ButtonFab
        tooltip="Novo Plano"
        icon={<NovoIcon tipo="BUTTON_FAB" />}
        onClick={() => abrirCadastroPlano('', '', true)}
      />
    </div>
  );
};

export default PlanosPage;
