import { Theme, makeStyles } from "@material-ui/core";

interface StyleProps{
    isRevenda?: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    container: {
        '& p': {
            fontSize: '13px !important'
        }
    },
    anoMes: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        borderRight: `1px solid ${theme.palette.divider}`,
        padding: '0 16px'
    },
    tooltip: {
        background: '#fff',
        boxShadow: '1px 2px 3px 1px #00000030'
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            alignItems: 'flex-start'
        }
    },
    semiBold: {
        fontWeight: 500
    },
    bold: {
        fontWeight: 800
    },
    saldoPositivo: {
        color: theme.palette.success.dark
    },
    saldoNegativo: {
        color: theme.palette.error.main
    },
    cardSaldos: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 8px',
        gap: theme.spacing(2)
    },
    cardSaldoValorPago: {
        minWidth: 80
    },
    textIcon: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1)
    },
    miniIcon: {
        height: '1rem',
        width: '1rem',
        fill: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    descontoBox: {
        justifyContent: 'center',
        [theme.breakpoints.up('lg')]: {
            marginLeft: (props) => props.isRevenda ? 'none' : theme.spacing(2)
        }
    },
    centerTerminais: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start'
        }
    }
}))