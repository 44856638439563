import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    containerQtde: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        margin: '10px 0'
    },
    textQCom: {
        color: theme.palette.grey[700],
        display: 'flex',
        alignItems: 'baseline',
        padding: '0px 5px',
        "& h6": {
            fontWeight: 'bold',
        },
        "& span": {
            fontWeight: 'bold',
        }
    },
    btnAlterarQuantidade: {
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        padding: '0!important'
    },
}));
