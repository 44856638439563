import { FaturasModel } from 'model/api/gestao/fatura/faturas-model';
import { DefaultModal } from 'views/components/modals/components/default-modal';
import { ConsultaFatura } from "./components/cliente-fatura/consulta-fatura";


type Props = {
  openned: boolean;
  id: string;
  fatura: FaturasModel;
  closeModal: () => void;
}

export const ConsultaFaturaModal = ({ id, openned, fatura, closeModal, ...props }: Props) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={openned || false}
      variant={"temporary"}
      anchor="right"

    >
      {openned && <ConsultaFatura id={id} model={fatura} closeModal={closeModal}/>}
    </DefaultModal>
  );
};
