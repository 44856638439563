import { Grid } from "@material-ui/core";
import { GerenteEdit } from "./gerente-edit/gerente-edit";

type Props = {
  id: string,
}

export const GerenteEditar = ({ id }: Props) => {


  return (
    <div style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    }}>
      <Grid container spacing={1} style={{
        maxWidth: 1400,
        padding: 16
      }}>
        <GerenteEdit id={id} />
      </Grid>
    </div>
  );
};
