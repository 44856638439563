import { DefaultIcon } from "./default-icon";
import { DefaultIconProps } from "./default-icon/default-icon-props";

export const EditIndicacaoIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M48 7.4875C47.9602 7.60706 48 8.0853 47.8012 8.36427C46.7276 10.0381 46.1709 12.0308 44.6997 13.4655C34.5601 23.5882 24.4205 33.7109 14.2411 43.7937C13.764 44.2719 13.2073 44.6705 12.6108 44.9096C9.98648 45.9458 7.36212 46.9023 4.69799 47.8587C4.2606 48.0181 3.5051 48.058 3.22676 47.779C2.94841 47.5399 2.94841 46.7827 3.10747 46.3443C4.06178 43.6741 5.05586 41.004 6.0897 38.3338C6.28851 37.8157 6.64638 37.2976 7.08378 36.8991C17.4222 26.5772 27.7208 16.2153 38.1388 5.97308C39.3316 4.77749 40.9619 3.90072 42.5525 3.26307C45.1768 2.22689 48 4.37896 48 7.4875ZM12.6904 42.4387C21.7166 33.392 30.5042 24.6244 39.1328 15.9762C37.8604 14.6611 36.5085 13.2264 35.2758 11.9909C26.4882 20.7187 17.7005 29.4466 8.71406 38.3737C9.23098 38.5729 9.70814 38.8918 10.1853 38.9316C12.0144 39.091 12.0144 39.0512 12.1735 40.9641C12.1735 41.4025 12.4916 41.8409 12.6904 42.4387ZM39.6498 7.60706C38.6954 8.48383 37.7411 9.3606 36.9459 10.078C38.3376 11.4728 39.6895 12.8278 41.0415 14.1828C41.8765 13.3858 42.7513 12.509 43.6658 11.6322C42.2741 10.2374 40.8824 8.84251 39.6498 7.60706ZM44.5406 9.48016C45.4552 8.7628 46.1311 8.00559 45.8528 6.69044C45.654 5.73396 45.0178 5.25573 44.143 5.09631C42.9501 4.8572 42.1548 5.57455 41.5186 6.49117C42.5922 7.52736 43.5465 8.48383 44.5406 9.48016ZM7.56093 40.4062C7.1633 41.4025 6.72591 42.4786 6.36804 43.5944C6.12946 44.2719 6.8452 44.9495 7.52117 44.7502C8.63454 44.3915 9.70814 43.9133 10.7022 43.5546C10.3841 42.6778 10.1455 41.9605 9.90696 41.2431C9.19122 40.9641 8.47548 40.725 7.56093 40.4062Z" />
            </DefaultIcon>
        </>
    );
};