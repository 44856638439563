import { Button, Grid } from '@material-ui/core';
import { useStyles } from '../../../clientes/components/clientes-editar/components/cliente-edit/cliente-edit-styles';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import {
  useGetPessoaById,
  usePutAtualizarPessoa,
} from 'data/api/gestao/pessoa';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PessoaModel } from 'model/api/gestao/pessoa/pessoa-model';
import { picker } from 'utils/picker';
import classNames from 'classnames';
import { FormPessoaEdicao } from 'views/components/form/master/pessoa/pessoa-edicao/form-pessoa-edicao';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { useThemeQueries } from 'views/theme';
import { AccordionClienteInformacoesList } from 'views/pages/private/clientes/components/clientes-editar/components/accordion-cliente-informacoes-list/accordion-cliente-informacoes-list';
import { AccordionClienteSociosList } from 'views/pages/private/clientes/components/clientes-editar/components/accordion-cliente-socio-list/accordion-cliente-socio-list';
import { PessoaPostModel } from 'model/api/gestao/pessoa/pessoa-post-model';
import { imagemForUpload } from 'utils/imagem-for-upload';
import { usePostImagemBase64 } from 'data/api/imagem/post-imagem';
import { newGuid } from 'utils/new-guid';
import { EnumRetornoApiBase } from 'data/api/base/api-base-response';
import { isEmpty } from 'lodash';
import { useUtilStyles } from './configuracoes-editar-styles';
import { AccordionConfiguracoesSenha } from '../accordion-configuracoes-senha/accordion-configuracoes-senha';
import { SalvarEditarIcon } from 'views/components/icons';
import { AccordionConfiguracoesMensagem } from '../accordion-configuracoes-mensagem/accordion-configuracoes-mensagem';
import { RevendedorPagamentoList } from 'views/pages/private/revendedor/components/accordion-revendedor-pagamento-list/accordion-revendedor-pagamento-list';
import { FormGerenteEdicao } from 'views/components/form/master/pessoa/gerente-edicao/form-gerente-edicao';
import { FormFuncionarioEdicao } from 'views/components/form/master/funcionario/funcionario-edicao/form-funcionario-edicao';
import { AccordionConfiguracoesTributo } from '../accordion-configuracoes-tributo/accordion-configuracoes-tributo';
import { TpPessoasMock } from 'data/mocks';
import { FormRepresentanteEdicao } from 'views/components/form/master/pessoa/representante-edicao/form-representante-edicao';
import { FormPessoaSoftwareHouse } from 'views/components/form/master/pessoa/pessoa-softwarehouse/form-pessoa-softwarehouse';
import { AccordionParametrosSistemas } from 'views/pages/private/revendedor/components/accordion-parametros-sistemas/accordion-parametros-sistemas';
import { AccordionWhitelabel } from 'views/pages/private/revendedor/components/accordion-whitelabel/accordion-whitelabel';

export const ConfiguracoesEditar = () => {
  const classes = useStyles();
  const utilClasses = useUtilStyles()

  const { showToast } = useToastSaurus();

  const { putAtualizarPessoa, carregando: carregandoPut } =
    usePutAtualizarPessoa();
  const { getPessoaById, carregando: carregandoGet } = useGetPessoaById();
  const [preenchendoTela, setPreechendoTela] = useState<boolean>(false);
  const { theme } = useThemeQueries();
  const [openAccordion, setOpenAccordion] = useState<boolean>(true);
  const [openAccordionConfig, setOpenAccordionConfig] = useState<boolean>(false)
  const { postImagemBase64 } = usePostImagemBase64();
  const { usuario, tipoUsuario, logarIntegracao, carregando: carregandoLogar } = useSessaoAtual();

  const id = usuario?.Id ?? ''

  const carregando = carregandoGet || carregandoPut || carregandoLogar || preenchendoTela;

  const cadPessoaFormRef = useRef<DefaultFormRefs<PessoaPostModel>>(null);
  const cadSoftwareHouseFormRef = useRef<DefaultFormRefs<PessoaPostModel>>(null);
  const refPessoaModel = useRef<PessoaModel>(new PessoaModel());
  const [pessoaForm, setPessoaForm] = useState<PessoaPostModel>(
    new PessoaPostModel(),
  );

  const recarregarForm = useCallback((model: PessoaPostModel) => {
    cadPessoaFormRef.current?.fillForm(model);
    cadSoftwareHouseFormRef.current?.fillForm(model)
  }, []);

  useEffect(() => {
    recarregarForm(pessoaForm);
  }, [pessoaForm, recarregarForm]);

  const getPessoaByIdWrapper = useCallback(async () => {
    const res = await getPessoaById(id);

    if (res.erro) throw res.erro;

    const ret = res.resultado?.data as PessoaModel;

    refPessoaModel.current = ret;

    const revenda = picker<PessoaPostModel>(ret, new PessoaPostModel());

    return revenda;
  }, [getPessoaById, id]);

  const preencherTela = useCallback(async () => {
    try {
      setPreechendoTela(true);
      const revenda = await getPessoaByIdWrapper();
      setPessoaForm(revenda);
    } catch (e: any) {
      showToast('error', e.message);
    } finally {
      setPreechendoTela(false);
    }
  }, [getPessoaByIdWrapper, showToast])

  useEffect(() => {
    preencherTela()
  }, [getPessoaByIdWrapper, preencherTela, showToast]);

  const enviarImagem = useCallback(
    async (imagemUrl: string) => {
      let imagem = '';

      const imgUpload = imagemForUpload(imagemUrl);
      if (imgUpload.length > 0) {
        const retImagem = await postImagemBase64(
          imgUpload,
          `licenciamento/pessoas/${usuario?.Id}/`,
          newGuid(),
        );
        if (retImagem.tipoRetorno !== EnumRetornoApiBase.Sucesso) {
          throw new Error('Erro ao processar  a Imagem selecionada.');
        }
        if (retImagem.resultado?.data.data.status === 2) {
          throw new Error(
            'Erro ao processar a Imagem selecionada.Detalhe: ' +
            retImagem.resultado?.data?.data?.retorno,
          );
        }
        imagem =
          retImagem.resultado?.data?.data?.url_blob +
          '?timestamp=' +
          new Date().getTime();
      }

      return imagem.length > 0 ? btoa(imagem) : imagem;
    },
    [postImagemBase64, usuario?.Id],
  );
  const saveNewPessoa = useCallback(
    async (model: PessoaPostModel, beforeModel?: PessoaPostModel) => {
      if (!isEmpty(model.urlImagem) && model.urlImagem !== beforeModel?.urlImagem)
        model.urlImagem = await enviarImagem(model.urlImagem);

      const ret = await putAtualizarPessoa(model);

      if (ret.erro) {
        throw ret.erro;
      }
    },
    [enviarImagem, putAtualizarPessoa],
  );

  const handleSubmit = useCallback(
    async (model: PessoaPostModel, beforeModel?: PessoaPostModel, config?: boolean) => {
      try {
        const NovoPessoaToCreate = picker<PessoaPostModel>(
          model,
          refPessoaModel.current,
        );

        await saveNewPessoa(NovoPessoaToCreate, beforeModel);
        await logarIntegracao(NovoPessoaToCreate.id, NovoPessoaToCreate.cpfCnpj)

        if (config) {
          showToast('success', 'Configurações Atualizadas')
          return true
        }
        showToast('success', 'Usuário Atualizado!');
        return true;
      } catch (e: any) {
        showToast('error', e.message);
        cadPessoaFormRef.current?.resetForm();
        return false;
      }
    },
    [logarIntegracao, saveNewPessoa, showToast],
  );

  const formPessoa = useCallback(async (model: PessoaPostModel, beforeModel?: PessoaPostModel) => {
    handleSubmit(model, beforeModel, false)
  }, [handleSubmit])

  const formConfiguracoes = useCallback(async (model: PessoaPostModel, beforeModel?: PessoaPostModel) => {
    handleSubmit(model, beforeModel, true)
  }, [handleSubmit])

  const tipoForm = useMemo(() => {
    switch (tipoUsuario()) {
      case EnumTipoPessoas.GerenteComercial:
        return (
          <FormGerenteEdicao
            loading={carregando}
            showLoading={carregando}
            onSubmit={formPessoa}
            ref={cadPessoaFormRef}
            configuracoes
          />
        )
      case EnumTipoPessoas.Funcionario:
        return (
          <FormFuncionarioEdicao
            ref={cadPessoaFormRef}
            showLoading={carregando}
            loading={carregando}
            onSubmit={formPessoa}
            configuracoes
          />
        )
      case EnumTipoPessoas.FuncionarioFinanceiro:
        return (
          <FormFuncionarioEdicao
            ref={cadPessoaFormRef}
            showLoading={carregando}
            loading={carregando}
            onSubmit={formPessoa}
            configuracoes
          />
        )
      case EnumTipoPessoas.FuncionarioFinanceiroSemCusto:
        return (
          <FormFuncionarioEdicao
            ref={cadPessoaFormRef}
            showLoading={carregando}
            loading={carregando}
            onSubmit={formPessoa}
            configuracoes
          />
        )
      case EnumTipoPessoas.FinanceiroSoftwareHouse:
        return (
          <FormFuncionarioEdicao
            ref={cadPessoaFormRef}
            showLoading={carregando}
            loading={carregando}
            onSubmit={formPessoa}
            configuracoes
          />
        )
      case EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto:
        return (
          <FormFuncionarioEdicao
            ref={cadPessoaFormRef}
            showLoading={carregando}
            loading={carregando}
            onSubmit={formPessoa}
            configuracoes
          />
        )
      case EnumTipoPessoas.Representante:
        return (
          <FormRepresentanteEdicao
            ref={cadPessoaFormRef}
            showLoading={carregando}
            loading={carregando}
            onSubmit={formPessoa}
            configuracoes
          />
        )
      default:
        return (
          <FormPessoaEdicao
            ref={cadPessoaFormRef}
            showLoading={true}
            loading={carregando}
            onSubmit={formPessoa}
            tipoRevenda={tipoUsuario() === EnumTipoPessoas.Revendedor}
            tipoFormPessoa={tipoUsuario() === EnumTipoPessoas.SoftwareHouse ? EnumTipoPessoas.SoftwareHouse : EnumTipoPessoas.Revendedor}
            configuracoes
          />
        )
    }
  }, [carregando, formPessoa, tipoUsuario])

  const formConfigSoftwareHouse = useMemo(() => (
    <FormPessoaSoftwareHouse loading={carregando} showLoading={carregando} onSubmit={formConfiguracoes} ref={cadSoftwareHouseFormRef} />
  ), [carregando, formConfiguracoes])

  const accordionSoftwareHouseConfiguracoes = useMemo(() => (
    <AccordionSaurus
      labelPrimary='Opções Software House'
      tipoExpand="bold"
      noPaperRoot={false}
      heightDivider={2}
      showDivider={openAccordionConfig}
      colorDivider={theme.palette.primary.main}
      colorExpand={theme.palette.primary.main}
      expanded={openAccordionConfig}
      onChange={() => setOpenAccordionConfig((prev) => !prev)}
    >
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}
        >
          {formConfigSoftwareHouse}

        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent='flex-end'>
                <Grid item xs={12} md={4}>
                  <Button
                    disabled={carregando}
                    onClick={() => {
                      cadSoftwareHouseFormRef.current!.submitForm();
                    }}
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                  >
                    <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </AccordionSaurus>
  ), [carregando, classes.acoes, classes.content, classes.contentForms, classes.contentFormsLoading, formConfigSoftwareHouse, openAccordionConfig, theme.palette.primary.main])

  const formPessoaEdicao = useMemo(() => (
    <AccordionSaurus
      labelPrimary={TpPessoasMock.find(x => x.Key === tipoUsuario())?.Value || 'Usuário'}
      tipoExpand="bold"
      noPaperRoot={false}
      heightDivider={2}
      showDivider={openAccordion}
      colorDivider={theme.palette.primary.main}
      colorExpand={theme.palette.primary.main}
      expanded={openAccordion}
      onChange={() => setOpenAccordion((prev) => !prev)}
    >
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}
        >
          {tipoForm}

        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent='flex-end'>
                <Grid item xs={12} md={4}>
                  <Button
                    disabled={carregando}
                    onClick={() => {
                      cadPessoaFormRef.current!.submitForm();
                    }}
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                  >
                    <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </AccordionSaurus>
  ), [carregando, classes.acoes, classes.content, classes.contentForms, classes.contentFormsLoading, openAccordion, theme.palette.primary.main, tipoForm, tipoUsuario])

  const accordionConfiguracoesSenha = useMemo(() => (
    <AccordionConfiguracoesSenha preencherTela={preencherTela} model={pessoaForm} />
  ), [pessoaForm, preencherTela])

  const accordionMensagemClientes = useMemo(() => (
    <AccordionConfiguracoesMensagem model={pessoaForm} preencherTela={preencherTela} />
  ), [pessoaForm, preencherTela])

  const accordionFormaPagamento = useMemo(() => (
    <RevendedorPagamentoList id={usuario!.Id} />
  ), [usuario])

  const accordionTributos = useMemo(() => (
    <AccordionConfiguracoesTributo pessoaId={usuario!.Id} />
  ), [usuario])

  const accordionAtivacaoTouchOne = useMemo(() => (
    <AccordionParametrosSistemas model={pessoaForm} loading={carregando} onSubmit={async (model) => handleSubmit(model)} />
  ), [carregando, handleSubmit, pessoaForm])

  const accordionWhitelabel = useMemo(() => (
    <AccordionWhitelabel id={usuario!.Id} />
  ), [usuario])

  // const accordionTaxas = useMemo(() => (
  //   <AccordionClienteTaxa id={usuario!.Id} tipoPessoa={tipoUsuario()} />
  // ), [tipoUsuario, usuario])


  // const exibirTaxas = [
  //   EnumTipoPessoas.SoftwareHouse,
  //   EnumTipoPessoas.Revendedor
  // ]

  return (
    <Grid container spacing={2} className={utilClasses.defaultContainer}>
      <Grid item xs={12}>
        {formPessoaEdicao}
      </Grid>
      {tipoUsuario() === EnumTipoPessoas.SoftwareHouse && <Grid item xs={12}>
        {accordionSoftwareHouseConfiguracoes}
      </Grid>}
      <Grid item xs={12}>
        {accordionConfiguracoesSenha}
      </Grid>
      {(tipoUsuario() !== EnumTipoPessoas.Funcionario && tipoUsuario() !== EnumTipoPessoas.FuncionarioFinanceiro && tipoUsuario() !== EnumTipoPessoas.FinanceiroSoftwareHouse && tipoUsuario() !== EnumTipoPessoas.Representante && tipoUsuario() !== EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto && tipoUsuario() !== EnumTipoPessoas.FuncionarioFinanceiroSemCusto) && <Grid item xs={12}>
        <AccordionClienteSociosList id={id} revenda={tipoUsuario() === EnumTipoPessoas.Revendedor} />
      </Grid>}
      {(tipoUsuario() === EnumTipoPessoas.Revendedor || tipoUsuario() === EnumTipoPessoas.SoftwareHouse) && <Grid item xs={12}>
        {accordionMensagemClientes}
      </Grid>}
      {(tipoUsuario() === EnumTipoPessoas.Revendedor) && <Grid item xs={12}>
        {accordionWhitelabel}
      </Grid>}
      {(tipoUsuario() === EnumTipoPessoas.Revendedor || tipoUsuario() === EnumTipoPessoas.SoftwareHouse) && <Grid item xs={12}>
        {accordionAtivacaoTouchOne}
      </Grid>}
      <Grid item xs={12}>
        <AccordionClienteInformacoesList id={id} tipoPessoa={pessoaForm.tipo} />
      </Grid>
      {tipoUsuario() === EnumTipoPessoas.SoftwareHouse && <Grid item xs={12}>
        {accordionFormaPagamento}
      </Grid>}
      {/* {exibirTaxas.includes(tipoUsuario()) && <Grid item xs={12}>
        {accordionTaxas}
      </Grid>} */}
      {tipoUsuario() === EnumTipoPessoas.SoftwareHouse && <Grid item xs={12}>
        {accordionTributos}
      </Grid>}
    </Grid>
  );
};
