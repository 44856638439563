import { isEmpty } from "lodash";
import { useCallback } from "react";
import { GestaoStorageKeys, useGestaoStorage } from ".";
import { MenuProviderModel } from "../providers/menu-principal-provider/menu-principal-provider-model";

export function useMenuStorage() {
    const { getRegistro, setRegistro, delRegistro } = useGestaoStorage();

    const get = useCallback((): MenuProviderModel => {
        let menu = getRegistro(GestaoStorageKeys.ConfiguracoesMenu, false) as MenuProviderModel;
        if (isEmpty(menu)) {
            menu = {
                situacao: "Fechado",
                modelo: "Completo"
            };
            setRegistro(GestaoStorageKeys.ConfiguracoesMenu, menu, false);
        }
        return menu;
    }, [getRegistro, setRegistro]);

    const persist = useCallback((model: MenuProviderModel) => {
        if (model === undefined) {
            delRegistro(GestaoStorageKeys.ConfiguracoesMenu, false);
        } else {
            setRegistro(GestaoStorageKeys.ConfiguracoesMenu, model, false);
        }
    }, [delRegistro, setRegistro]);

    return {
        get,
        persist
    };
}
