import { useMemo, useState } from 'react';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { EnumSituacao } from 'model/enums/enum-situacao';
import { useParams } from 'react-router-dom';
import { NotasFiscaisEditar } from '../notas-fiscais-revenda/components/notas-fiscais-editar/notas-fiscais-editar';
import { FaturasListSearchProps } from '../faturas/components/faturas-list/faturas-list-search-props';
import { LicençasList } from './components/licenças-list/licenças-list';
import { LicençasHeader } from './components/licenças-header/licenças-header';
import { EnumTipoFiltroData } from 'model/enums/enum-tipo-filtro-data';

interface paramProps {
  parametro: string
}

export const LicençasPage = () => {
  const classes = useDefaultCadastroStyles();  

  const [openPesquisa, setOpenPesquisa] = useState(false);
  const [searchProps, setSearchProps] = useState<FaturasListSearchProps>({
    cpfCnpjPagador: '',
      dataFinal: '',
      CompetenciaInicial: '',
      CompetenciaFinal: '',
      dataInicial: '',
      NomePagador: '',
      nossoNumero: null,
      Situacao: EnumSituacao.Aberta,
      atualizarPesquisa: false,
      tipoData: EnumTipoFiltroData.Vencimento,
      formaPagamentoId: -1
  });

  const { parametro } = useParams<paramProps>()

  const pageSwitch = useMemo(() => {
    switch (parametro) {
      case undefined:
        return (
          <LicençasList searchProps={searchProps} />
        )
      default:
        setOpenPesquisa(false)
        return (
          <NotasFiscaisEditar id={parametro} />
        )
    }
  }, [parametro, searchProps])

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <LicençasHeader
          openPesquisa={openPesquisa}
          setSearchProps={setSearchProps}
          setOpenPesquisa={setOpenPesquisa}
          titulo={parametro ? 'Visualizar Licença' : 'Licenças de Clientes'}
        />
      </div>
      {pageSwitch}
    </div>
  );
};

export default LicençasPage;
