import { KeyValueModel } from "model";
import { EnumMotivoCancelamento } from "model/enums/enum-motivo-cancelamento";

export const MotivoCancelamentoMock: KeyValueModel[] = [
    new KeyValueModel(EnumMotivoCancelamento.TrocaParaSaurus, "Trocou para outro sistema Saurus"),
    new KeyValueModel(EnumMotivoCancelamento.TrocaOutroSistema, "Trocou para outro sistema"),
    new KeyValueModel(EnumMotivoCancelamento.TrocaCNPJ, "Trocou de CNPJ"),
    new KeyValueModel(EnumMotivoCancelamento.EstabelecimentoFechou, "Estabelecimento Fechou"),
    new KeyValueModel(EnumMotivoCancelamento.ClienteInadimplente, "Inadimplente"),
    new KeyValueModel(EnumMotivoCancelamento.FaltaRecursoSaurus, "Falta de Recursos para o cliente"),
    new KeyValueModel(EnumMotivoCancelamento.Outros, "Outro Motivo"),
];