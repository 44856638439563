import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    camposContainer: {
        background: '#F8F8F8',
        padding: '1rem',
        borderRadius: '15px',
        '& .MuiFormControl-root': {
            background: '#fff'
        }
    }
}));
