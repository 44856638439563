
import { useMemo } from 'react';
import * as Yup from 'yup';


export const useFormEditarPlanoValidation = () => {


  const FormEditarPlanoValidationYup = useMemo(() => {
    return Yup.object().shape({
      nome: Yup.string().required('Nome é obrigatório').transform(nome => nome.length > 100 ? null : nome).typeError('Limite de caracteres excedido.'),
      valorDe: Yup.string().required('É necessário informar um valor.'),
      valorPor: Yup.string().required('É necessário informar um valor.'),
      diasValidade: Yup.number().required('É necessário informar um dia de validade').typeError('É necessário informar um dia de validade'),
      descricao: Yup.string().notRequired()
    });
  }, [])

  return {
    FormEditarPlanoValidationYup,
  }
}
