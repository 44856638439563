import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePutBonificar() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putBonificar = useCallback(
        (faturaId: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Fatura/${faturaId}/bonificar`,
                method: 'PUT',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putBonificar,
    };
}
