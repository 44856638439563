import { VariaveisAmbiente } from 'config';
import { ApiListModel } from 'model/api/gestao/api-list-model/api-list-model';
import { SistemasModel } from 'model/api/gestao/sistemas/sistemas-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetSistemas() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ApiListModel<SistemasModel>>();

    const getSistemas = useCallback(
        (newPage: number, query?: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Sistema?page=${newPage}${query}`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getSistemas,
    };
}
