import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { useToastSaurus } from "services/app";
import { FormAnteciparFatura } from "views/components/form/master/antecipar-fatura/form-antecipar-fatura";
import { SalvarIcon, VoltarIcon } from "views/components/icons";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus";
import { usePutAnteciparFatura } from "data/api/gestao/faturas/put-antecipar-fatura";
import { DefaultFormRefs } from "views/components/form/utils";
import { FaturaAnteciparFormModel } from "model/api/gestao/fatura/fatura-antecipar-model";
import { FaturaFilhaModel } from "model/api/gestao/fatura/faturas-filhas-model";
import { toDateString } from "utils/to-date";
import { useStyles } from './dialog-antecipar-fatura-styles'
import { CircularLoading } from "views/components/utils";

interface DialogAnteciparFaturaProps {
    openned: boolean;
    contratoId?: string;
    onClose: () => void,
    faturaPaiId?: string;
    model?: FaturaFilhaModel;
    preencherTela?: () => Promise<void>
}

export interface ContratoIdEvent {
    contratoId: string;
    anoMesFaturaFilha: number;
}

export const DialogAnteciparFatura = ({
    openned,
    faturaPaiId,
    contratoId,
    onClose,
    preencherTela,
}: DialogAnteciparFaturaProps) => {
    const modalClasses = useModalStyles();
    const classes = useStyles()
    const { showToast } = useToastSaurus();
    const [antecipando, setAntecipando] = useState<boolean>(false)
    const [estagio, setEstagio] = useState({
        tamanho: 0,
        atual: 0,
    })

    const { putAnteciparFatura, carregando: postCarregando } = usePutAnteciparFatura();

    const licencasContratoId = useRef<ContratoIdEvent[]>([])

    const ref = useRef<DefaultFormRefs<FaturaAnteciparFormModel>>(null)

    const carregando = postCarregando;

    const erros = useRef<number>(0)

    const handleSubmit = useCallback(async (model: FaturaAnteciparFormModel) => {
        if (model.meses) {
            const dataAtual = new Date();

            const dataSelecionada = new Date(dataAtual.setMonth(dataAtual.getMonth() + model.meses));

            const competenciaCalculada = Number(toDateString(dataSelecionada, 'yyyyMM'))

            model.competenciaFinal = competenciaCalculada
        } else if (model.competenciaFinal) {
            const dataCompetencia = new Date(`${model.competenciaFinal.toString().substring(4)}-01-${model.competenciaFinal.toString().substring(0, 4)}`)

            const monthDiff = function monthDiff(dateFrom: Date, dateTo: Date) {
                return dateTo.getMonth() - dateFrom.getMonth() +
                    (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
            }

            const meses = monthDiff(new Date(), dataCompetencia) + 1

            model.meses = meses
        }

        try {
            if (contratoId) {
                const res = await putAnteciparFatura(contratoId, model.competenciaFinal!, faturaPaiId);

                if (res.erro) throw res.erro

                showToast('success', 'Sua fatura foi antecipada com sucesso.');

                ref.current?.resetForm()
                if (preencherTela) await preencherTela()

                onClose();
                return
            }
            throw new Error('Erro ao antecipar a fatura.')
        } catch (error: any) {
            showToast('error', error.message);
        }
    }, [contratoId, faturaPaiId, onClose, preencherTela, putAnteciparFatura, showToast])

    const handleMultipleSubmit = useCallback(async (model: FaturaAnteciparFormModel) => {
        const loopAntecipacao = (licenca: ContratoIdEvent) => new Promise<boolean>(async (resolve) => {
            const dataAtual = new Date(`${licenca.anoMesFaturaFilha.toString().substring(4)}-01-${licenca.anoMesFaturaFilha.toString().substring(0, 4)}`);
            if (model.meses) {
                const dataSelecionada = new Date(dataAtual.setMonth(dataAtual.getMonth() + model.meses));

                const competenciaCalculada = Number(toDateString(dataSelecionada, 'yyyyMM'))

                model.competenciaFinal = competenciaCalculada
            } else if (model.competenciaFinal) {
                const dataCompetencia = new Date(`${model.competenciaFinal.toString().substring(4)}-01-${model.competenciaFinal.toString().substring(0, 4)}`)

                const monthDiff = function monthDiff(dateFrom: Date, dateTo: Date) {
                    return dateTo.getMonth() - dateFrom.getMonth() +
                        (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
                }

                const meses = monthDiff(dataAtual, dataCompetencia)

                model.meses = meses
            }
            try {
                const res = await putAnteciparFatura(licenca.contratoId, model.competenciaFinal!, faturaPaiId);

                if (res.erro) throw res.erro

                resolve(true)
                return
            } catch (error: any) {
                erros.current = erros.current + 1
                resolve(false)
            }
        })
        let res: boolean[] = []
        setAntecipando(true)
        for (let i = 0; i < licencasContratoId.current.length; i++) {
            setEstagio(prev => ({
                ...prev,
                atual: i + 1
            }))
            const response = await loopAntecipacao(licencasContratoId.current[i])
            res.push(response)
        }
        setAntecipando(false)
        const sucessos = res.filter(x => Boolean(x))
        if (sucessos.length > 0) {
            showToast(erros.current === 0 ? 'success' : 'info', `${sucessos.length} faturas foram antecipadas com sucesso. ${erros.current > 0 ? `Ocorreu um erro ao tentar antecipar ${erros.current} Faturas.` : ''}`)

            ref.current?.resetForm()
            if (preencherTela) await preencherTela()
            onClose();
            return
        }

        showToast('error', 'Ocorreu um erro ao antecipar suas faturas.')
    }, [faturaPaiId, onClose, preencherTela, putAnteciparFatura, showToast])

    useEffect(() => {
        const handleEvent = (ev: Event) => {
            const evento = ev as CustomEvent<ContratoIdEvent[]>
            licencasContratoId.current = evento.detail
            setEstagio({
                atual: 0,
                tamanho: evento.detail.length
            })
        }

        window.addEventListener('setContratoIdAntecipacao', handleEvent)

        ref.current?.resetForm();

        return () => {
            window.removeEventListener('setContratoIdAntecipacao', handleEvent)
            setEstagio({
                atual: 0,
                tamanho: 0
            })
        }
    }, [])

    return (
        <>
            <DialogSaurus
                aberto={openned}
                titulo="Antecipar Fatura"
                tamanho='sm'
                bottomArea={
                    <Box className={modalClasses.acoes}>
                        <Grid container spacing={2} style={{ paddingBottom: '7px' }} justifyContent='flex-end'>
                            <Grid item xs={12} md={3}>
                                <Button
                                    disabled={carregando}
                                    variant="outlined"
                                    fullWidth
                                    color="primary"
                                    onClick={() => {
                                        ref.current?.resetForm()
                                        onClose()
                                    }}
                                >
                                    <VoltarIcon tipo='BUTTON' />
                                    Voltar
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Button
                                    disabled={carregando}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={ref.current?.submitForm}
                                >
                                    <SalvarIcon tipo='BUTTON_PRIMARY' />
                                    Salvar
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                }
            >
                <>
                    <FormAnteciparFatura
                        ref={ref}
                        loading={carregando}
                        onSubmit={contratoId ? handleSubmit : handleMultipleSubmit}
                        showLoading={carregando}
                    />
                    {antecipando && <Box className={classes.loadingAntecipacao}>
                        <CircularLoading tipo='NORMAL'/>
                        <Typography className={classes.fontAntecipacao} color='primary'>Antecipando {estagio.atual} de {estagio.tamanho}</Typography>
                    </Box>}
                </>
            </DialogSaurus>
        </>
    );
}