import { Tooltip, Typography } from '@material-ui/core';
import { CardContratosProps } from './card-clientes-props';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { toDateString } from 'utils/to-date';
import { useStyles } from './card-contratos-styles'
import { useThemeQueries } from 'views/theme';
import { EditarIcon, OlhoAbertoIcon } from 'views/components/icons';
import { useSessaoAtual } from 'services/app';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { useMemo } from 'react';

export const CardContratos = ({
  model,
  onClick,
  onCheck,
  selected,
  isTipoRevenda
}: CardContratosProps) => {
  const defaultCardClasses = useDefaultCardStyles();
  const { isMobile } = useThemeQueries();
  const { tipoUsuario } = useSessaoAtual()

  const contratoClasses = useStyles({
    ativo: model.ativo
  });

  const podeVerCusto = useMemo(() => (
    ![
      EnumTipoPessoas.Representante,
      EnumTipoPessoas.FuncionarioFinanceiroSemCusto,
      EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto,
      EnumTipoPessoas.Funcionario,
      EnumTipoPessoas.FuncionarioSoftwareHouse,
    ].includes(tipoUsuario())
  ), [tipoUsuario])

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model.id);
        }}
        className={contratoClasses.card}
      >
        <Tooltip
          title={model.ativo ? 'Ativo' : (
            <div>
              <Typography variant='caption'>Cancelado</Typography>
              <br />
              <Typography variant='caption'>Detalhes: {model.motivoCancelamento}</Typography>
            </div>
          )}
          placement='right'
        >
          <div className={contratoClasses.statusPin} />
        </Tooltip>
        <div className={defaultCardClasses.cardContent}>
          <div className={defaultCardClasses.celulaGridFull} style={{ width: 150 }}>
            {model.sistemaId === 'b2a22b5d-b681-43e1-b110-44bc2f8809b9' && (
              <Typography variant='caption' style={{ fontWeight: 600 }}>
                {model.dominio}
              </Typography>
            )}
            <Typography variant='body1' style={{ fontWeight: 600 }}>
              {model.sistemaNome ?? 'Não encontrado'}
            </Typography>
          </div>
          <div className={`${defaultCardClasses.celulaGridFull} ${contratoClasses.data}`}>
            <Typography color="textPrimary" variant="caption">
              Adesão:
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {toDateString(model.dataAdesao)}
            </Typography>
          </div>
          {tipoUsuario() !== EnumTipoPessoas.Funcionario && (
            <>
              {podeVerCusto && <div className={`${defaultCardClasses.celulaGridFull}`}>
                <Typography color="textPrimary" variant="caption">
                  Valor Custo:
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  {model.valorCusto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </Typography>
              </div>}
              {!isTipoRevenda && <div className={`{${defaultCardClasses.celulaGridFull} ${contratoClasses.valores}}`}>
                <Typography color="textPrimary" variant="caption">
                  Valor Contrato:
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  <b>{model.valorVigente.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b>
                </Typography>
              </div>}
            </>
          )}
        </div>
        {!isMobile ?
          <div className={contratoClasses.editIcon}>
            {tipoUsuario() !== EnumTipoPessoas.Funcionario ? (
              <EditarIcon tipo='BUTTON' />
            ) : (
              <OlhoAbertoIcon tipo='BUTTON' />
            )}
          </div> : null}
      </DefaultCard>
    </>
  );
};
