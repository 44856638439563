import { guidEmpty } from "utils/guid-empty";
import { ModuloPlanoModel } from "./modulo-plano-model";

export class PlanoModel {
  constructor(
    public id: string = guidEmpty(),
    public revendaId: string = guidEmpty(),
    public nome: string = "",
    public descricao: string = "",
    public diasValidade: number = 0,
    public sistemaId: string = guidEmpty(),
    public sistemaNome: string = '',
    public valorDe: number = 0,
    public status: number = 0,
    public valorPor: number = 0,
    public modulos: ModuloPlanoModel[] = new Array<ModuloPlanoModel>()
  ) { }
}
