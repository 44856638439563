
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormPessoaSistemaValidation = () => {


  const FormPessoaSistemaValidationYup = useMemo(() => {
    return Yup.object().shape({
      percentualOverTabela: Yup.string().required('Preencha este campo.').typeError('Preencha este campo.'),
      sistemaId: Yup.string().required('Selecione um sistema.'),
      tabelaPrecoId: Yup.string().required('Selecione uma tabela de preço.'),
    });
  }, [])

  return {
    FormPessoaSistemaValidationYup,
  }
}

