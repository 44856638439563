import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { CircularLoading, useThemeQueries } from 'views';
import { useStyles } from './components/list-styles';
import { useGetComissaoPendente } from 'data/api/gestao/comissao/get-comissao-pendentes';
import { ComissaoPendenteModel } from 'model/api/gestao/comissao/comissao-pendente-model';
import { ComissaoModel } from 'model/api/gestao/comissao/comissao-model';
import { useGetComissao } from 'data/api/gestao/comissao/get-comissao';
import {
  TabSaurusContent,
  TabSaurusLabel,
  TabsSaurus,
} from 'views/components/tabs/tabs-saurus';
import { useCadastros, useSessaoAtual, useToastSaurus } from 'services/app';
import { ComissoesListData } from './components/comissoes-list-data';
import { ComissoesPendentesListData } from './components/comissoes-pendentes-list-data';
import { ComissoesListSearchProps } from './comissoes-list-search-props';
import { isEmpty } from 'lodash';
import { CoinIcon } from 'views/components/icons/coin-icon';
import { Paginacao } from 'views/components/paginacao';
import { FaturaLocationProps } from 'views/pages/private/notas-fiscais-revenda/components/notas-fiscais-revenda-header/notas-fiscais-revenda-header';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { ComissoesPendentesFilter } from '../comissoes-header/comissoes-pendentes-filter';
import { ComissoesPagasFilter } from '../comissoes-header/comissoes-pagas-filter';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { EnumTipoFatura } from 'model/enums/enum-tipo-fatura';
import { toCurrency } from 'utils/toCurrency';
import { ExportarExcelIcon } from 'views/components/icons/exportar-excel-icon';
import { useGetComissaoPendenteExExcel } from 'data/api/gestao/comissao/get-comissao-pendentes-exportar-excel';
import { useGetComissaoExportarExcel } from 'data/api/gestao/comissao/get-comissao-exportar-excel';
import { ButtonFabMenu } from 'views/components/controles/buttons/button-fab-menu';
import { MenuVerticalIcon } from 'views/components/icons';
import { ButtonFabMenuModel } from 'model/app/components/button-fab-menu/button-fab-menu';


interface ComissoesTabsProps {
  openSearch: boolean;
}

export const ComissoesTabs = ({ openSearch }: ComissoesTabsProps) => {
  // rotas
  const rotaPendentes = '/comissoes/disponiveis';
  const rotaComissoes = '/comissoes/realizadas';

  const retornaAba = (path: string) => {
    return path.indexOf(rotaComissoes) > -1 ? 1 : 0;
  };

  // AUX
  const { replace, location } = useHistory();
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const classesCadastros = useDefaultCadastroStyles();
  const { tipoUsuario, usuario } = useSessaoAtual();
  const { getComissaoPendenteExExcel } = useGetComissaoPendenteExExcel();
  const { getComissaoExportarExcel } = useGetComissaoExportarExcel();
  const { isMobile } = useThemeQueries();

  const { state } = useLocation<FaturaLocationProps>();

  const history = useHistory()

  const {
    abrirPagamentoComissao,
    abrirEditarComissao,
    editarComissaoState,
    pagamentoComissaoState,
  } = useCadastros();

  // STATES
  const [queryStatusPendentes, setQueryStatusPendentes] = useState({
    listPendentes: Array<ComissaoPendenteModel>(),
    valorTotalComissoes: 0
  });

  const [queryStatusComissoes, setQueryStatusComissoes] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    listComissoes: Array<ComissaoModel>(),
    valorTotalComissoes: 0
  });

  const [selectedList, setSelectedList] = useState<ComissaoPendenteModel[]>([]);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const handleOpen = useCallback(() => {
    setOpenMenu(true);
  }, [])

  const handleClose = useCallback(() => {
    setOpenMenu(false);
  }, [])

  const [abaSelecionada, setAbaSelecionada] = useState(
    retornaAba(location.pathname),
  );
  const [carregandoInicial, setCarregandoInicial] = useState(false);

  const [searchProps, setSearchProps] = useState<ComissoesListSearchProps>({
    comissionadoId: '',
    comissionadoCpfCnpj: '',
    dPagFinal: '',
    dPagInic: '',
    dVencIni: '',
    dVencFinal: '',
    competenciaFinal: null,
    competenciaInicial: null
  });

  // CHAMADAS API
  const { getComissaoPendente, carregando: carregandoPendente } =
    useGetComissaoPendente();
  const { getComissao, carregando: carregandoComissao } = useGetComissao();

  const carregando =
    carregandoInicial || carregandoPendente || carregandoComissao;

  const fillResultPendentes = useCallback(
    async (listPendentes: Array<ComissaoPendenteModel>, valorTotal: number) => {
      setQueryStatusPendentes({
        listPendentes: listPendentes,
        valorTotalComissoes: valorTotal
      });
    },
    [],
  );

  const handlePendentes = useCallback(
    async (newPage: number, comissionadoId?: string) => {
      if (isEmpty(searchProps.comissionadoId) && !comissionadoId) return;
      const searchParams = new URLSearchParams();
      if (searchProps.comissionadoCpfCnpj)
        searchParams.append('comissonadoCpfCnpj', searchProps.comissionadoCpfCnpj);
      if (searchProps.dPagFinal)
        searchParams.append('dPagFinal', searchProps.dPagFinal);
      if (searchProps.dPagInic)
        searchParams.append('dPagInic', searchProps.dPagInic);
      if (searchProps.dVencIni)
        searchParams.append('dVencIni', searchProps.dVencIni);
      if (searchProps.dVencFinal)
        searchParams.append('dVencFInal', searchProps.dVencFinal);
      if (searchProps.competenciaInicial)
        searchParams.append('competenciaInicial', String(searchProps.competenciaInicial));
      if (searchProps.competenciaFinal)
        searchParams.append('competenciaFinal', String(searchProps.competenciaFinal));

      searchParams.append('pageSize', '0');
      searchParams.append(
        'comissionadoId',
        searchProps.comissionadoId || comissionadoId || '',
      );

      const query = searchParams.toString();
      try {
        const res = await getComissaoPendente(newPage, query);
        if (res.erro) throw res.erro;
        if (!res.resultado) throw new Error('Erro ao carregar comissão');

        fillResultPendentes(res.resultado?.data.list || [], res.resultado.data.somatorios ? res.resultado.data.somatorios.ValorComissao : 0);

        if (state && state.from?.faturasIds) {
          setSelectedList(
            res.resultado?.data.list.filter((x) => {
              return state.from.faturasIds?.includes(x.fatura.id);
            }),
          );
          abrirPagamentoComissao(
            res.resultado?.data.list.filter((x) => {
              return state.from.faturasIds?.includes(x.fatura.id);
            }),
            '',
            false,
          );
        }
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [searchProps.comissionadoId, searchProps.comissionadoCpfCnpj, searchProps.dPagFinal, searchProps.dPagInic, searchProps.dVencIni, searchProps.dVencFinal, searchProps.competenciaInicial, searchProps.competenciaFinal, getComissaoPendente, fillResultPendentes, state, abrirPagamentoComissao, showToast],
  );

  const onClickAdd = useCallback(() => {
    abrirPagamentoComissao(selectedList, '', false);
  }, [abrirPagamentoComissao, selectedList]);

  const fillResultComissoes = useCallback(
    (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<ComissaoModel>,
      valorTotalComissoes: number,
    ) => {
      setQueryStatusComissoes({
        totalPages,
        page,
        totalResults,
        listComissoes: list,
        valorTotalComissoes
      });
    },
    [],
  );

  const handleComissoes = useCallback(
    async (newPage: number) => {
      const searchParams = new URLSearchParams();
      if (searchProps.comissionadoId)
        searchParams.append('comissionadoId', searchProps.comissionadoId);
      if (searchProps.dPagFinal)
        searchParams.append('dPagFinal', searchProps.dPagFinal);
      if (searchProps.dPagInic)
        searchParams.append('dPagInic', searchProps.dPagInic);

      searchParams.append('pageSize', '10');

      const query = searchParams.toString();
      try {
        const res = await getComissao(newPage, query);
        if (res.erro) throw res.erro;

        if (!res.resultado) return;

        fillResultComissoes(
          newPage,
          res.resultado.data.totalPages,
          res.resultado.data.totalResults,
          res.resultado.data.list,
          res.resultado.data.somatorios ? res.resultado.data.somatorios.ValorTotal : 0
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [
      searchProps.comissionadoId,
      searchProps.dPagFinal,
      searchProps.dPagInic,
      getComissao,
      fillResultComissoes,
      showToast,
    ],
  );

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatusComissoes.totalPages || newPage > 0) {
        await handleComissoes(newPage);
      }
    },
    [queryStatusComissoes.totalPages, handleComissoes],
  );

  const tabChange = (index: number) => {
    setSearchProps({
      comissionadoId: '',
      comissionadoCpfCnpj: '',
      dPagFinal: '',
      dPagInic: '',
      dVencFinal: '',
      dVencIni: '',
    });
    setQueryStatusPendentes({
      listPendentes: [],
      valorTotalComissoes: 0
    });
    setSelectedList([]);
    if (index === 0) {
      replace(rotaPendentes);
    } else {
      replace(rotaComissoes);
    }
  };

  useEffect(() => {
    const aba = retornaAba(location.pathname);
    setAbaSelecionada(aba);
  }, [location.pathname]);

  useEffect(() => {
    if (([EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto].includes(tipoUsuario())) && searchProps.comissionadoId !== usuario?.RevendaId) {
      setSearchProps(prev => ({ ...prev, comissionadoId: usuario!.RevendaId }))
      return
    } else if ((tipoUsuario() === EnumTipoPessoas.Revendedor || tipoUsuario() === EnumTipoPessoas.Representante || tipoUsuario() === EnumTipoPessoas.GerenteComercial) && searchProps.comissionadoId !== usuario?.Id) {
      setSearchProps(prev => ({ ...prev, comissionadoId: usuario!.Id }))
      return
    }
    if (abaSelecionada === 0) {
      if (!pagamentoComissaoState.aberto) {
        handlePendentes(
          1,
          state &&
            state.from?.comissionadoId &&
            isEmpty(searchProps.comissionadoId)
            ? state.from.comissionadoId
            : undefined,
        );
      }
    } else {
      if (!editarComissaoState.aberto) {
        if (location.pathname.split('/')[3]) {
          abrirEditarComissao(location.pathname.split('/')[3], '/comissoes/realizadas/', true)
          return
        }
        handleComissoes(queryStatusComissoes.page);
      }
    }
    setCarregandoInicial(false);
  }, [abaSelecionada, abrirEditarComissao, editarComissaoState, handleComissoes, handlePendentes, location.pathname, pagamentoComissaoState, queryStatusComissoes.page, searchProps.comissionadoId, setSearchProps, state, tipoUsuario, usuario]);

  const redirect = useCallback((model: ComissaoPendenteModel) => {
    if (tipoUsuario() !== EnumTipoPessoas.Revendedor) return
    if (model.fatura.tipoFatura === EnumTipoFatura.Cliente) {
      history.push({
        pathname: `/faturas/${model.fatura.id}`,
        state: {
          from: {
            pathname: history.location.pathname,
            comissionadoId: usuario?.Id,
          },
        }
      })
    } else {
      history.push({
        pathname: `/revenda/notas-fiscais/${model.fatura.id}`,
        state: {
          from: {
            pathname: history.location.pathname,
            comissionadoId: usuario?.Id
          },
        }
      })
    }
  }, [history, tipoUsuario, usuario?.Id])

  const addRemoveSelectedList = useCallback((model: ComissaoPendenteModel) => {
    setSelectedList((prev) => {
      if (prev.find((comissao) => comissao.id === model.id)) {
        return prev.filter((comissao) => comissao.id !== model.id);
      }
      return [...prev, model];
    });
  }, []);

  const selectAll = useCallback(() => {
    if (queryStatusPendentes.listPendentes.length === selectedList.length) {
      setSelectedList([]);
    } else {
      setSelectedList(queryStatusPendentes.listPendentes);
    }
  }, [queryStatusPendentes.listPendentes, selectedList.length]);

  const openComissao = useCallback(
    (id: string) => {
      abrirEditarComissao(id, history.location.pathname, true);
    },
    [abrirEditarComissao, history.location.pathname],
  );

  const vTotalComissaoSelec = useMemo(() => {
    let valorTotal = 0;

    selectedList.forEach((comissao) => {
      valorTotal += comissao.valorComissao;
    });

    return valorTotal;
  }, [selectedList]);

  const exportExcelPendente = useCallback(async () => {
    const searchParams = new URLSearchParams();
    if (searchProps.comissionadoCpfCnpj)
      searchParams.append('comissonadoCpfCnpj', searchProps.comissionadoCpfCnpj);
    if (searchProps.dPagFinal)
      searchParams.append('dPagFinal', searchProps.dPagFinal);
    if (searchProps.dPagInic)
      searchParams.append('dPagInic', searchProps.dPagInic);
    if (searchProps.dVencIni)
      searchParams.append('dVencIni', searchProps.dVencIni);
    if (searchProps.dVencFinal)
      searchParams.append('dVencFInal', searchProps.dVencFinal);
    if (searchProps.competenciaInicial)
      searchParams.append('competenciaInicial', String(searchProps.competenciaInicial));
    if (searchProps.competenciaFinal)
      searchParams.append('competenciaFinal', String(searchProps.competenciaFinal));
    const query = searchParams.toString();
    try {
      const res = await getComissaoPendenteExExcel(query)
      if (res.erro) throw (res.erro)
      if (!res.resultado) return;

      const blob = new Blob([res.resultado?.data], { type: 'application/vnd.ms-excel' });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a')

      anchor.href = url
      anchor.download = `Comissões Pendentes -${new Date().toLocaleDateString().replaceAll('/', '-')}.xls`
      anchor.click()

    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getComissaoPendenteExExcel, searchProps.comissionadoCpfCnpj, searchProps.competenciaFinal, searchProps.competenciaInicial, searchProps.dPagFinal, searchProps.dPagInic, searchProps.dVencFinal, searchProps.dVencIni, showToast])

  const exportExcel = useCallback(async () => {
    const query =
      '' +
      (!isEmpty(searchProps.comissionadoId)
        ? '&comissionadoId=' + searchProps.comissionadoId
        : '') +
      (searchProps.comissionadoCpfCnpj
        ? '&comissonadoCpfCnpj=' + searchProps.comissionadoCpfCnpj
        : '') +
      (searchProps.dPagFinal
        ? '&dPagFinal=' + searchProps.dPagFinal
        : '') +
      (searchProps.dPagInic
        ? '&dPagInic=' + searchProps.dPagInic
        : '') +
      (searchProps.dVencIni
        ? '&dVencIni=' + searchProps.dVencIni
        : '') +
      (isEmpty(searchProps.dVencFinal)
        ? ''
        : `&dVencFInal=${searchProps.dVencFinal}`) +
      (isEmpty(searchProps.competenciaInicial)
        ? ''
        : `&competenciaInicial=${searchProps.competenciaInicial}`) +
      (searchProps.competenciaFinal
        ? '&competenciaFinal=' + searchProps.competenciaFinal
        : '')

    try {
      const res = await getComissaoExportarExcel(query)
      if (res.erro) throw (res.erro)
      if (!res.resultado) return;
      const blob = new Blob([res.resultado?.data], { type: 'application/vnd.ms-excel' });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a')

      anchor.href = url
      anchor.download = `Comissões Pagas -${new Date().toLocaleDateString().replaceAll('/', '-')}.xls`
      anchor.click()

    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getComissaoExportarExcel, searchProps.comissionadoCpfCnpj, searchProps.comissionadoId, searchProps.competenciaFinal, searchProps.competenciaInicial, searchProps.dPagFinal, searchProps.dPagInic, searchProps.dVencFinal, searchProps.dVencIni, showToast])

  const onClickMenuItem = useMemo(() => {
    const itens = new Array<ButtonFabMenuModel>();

    itens.push(new ButtonFabMenuModel(<ExportarExcelIcon tipo='BUTTON_FAB' />, 'Exportar Excel', () => exportExcel()))

    return itens;
  }, [exportExcel])

  const isSoftwarehouse = ([EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto].includes(tipoUsuario()))

  return (
    <>
      <TabsSaurus
        selectedTabIndex={abaSelecionada}
        onChange={tabChange}
        tabsLabel={[
          new TabSaurusLabel('Comissões Pendentes', 0),
          new TabSaurusLabel('Comissões', 1),
        ]}
        tabsContent={[
          new TabSaurusContent(
            0,
            (
              <div className={classes.defaultContainer}>
                {carregando && <CircularLoading tipo="FULLSIZED" />}
                {openSearch &&
                  <Box flexGrow={0}>
                    <ComissoesPendentesFilter
                      openPesquisa={openSearch}
                      setSearchProps={setSearchProps}
                      searchProps={searchProps}
                    />
                  </Box>
                }
                <Box display='flex' flexDirection='column' height='100%' justifyContent='space-between' flexGrow={1} overflow='auto' >
                  <Grid container className={classesCadastros.listContainer} style={{ overflowY: 'auto' }}>
                    <Grid item xs={12}>
                      {(queryStatusPendentes.listPendentes.length > 0) && (
                        <Grid container alignItems='center'>
                          {isSoftwarehouse &&
                            <Grid item xs={12} md={8}>
                              <Typography className={classes.textSelecionar}>
                                <Checkbox
                                  onChange={selectAll}
                                  checked={
                                    selectedList.length ===
                                    queryStatusPendentes.listPendentes.length
                                  }
                                  color="primary"
                                />
                                Selecionar Todos
                              </Typography>
                            </Grid>
                          }
                          <Grid item xs={12} md={4} style={{ display: 'flex', justifyContent: isMobile ? 'space-between' : 'flex-end', padding: '8px' }}>
                            <Card className={classesCadastros.excelMobile}>
                              <Box p={1}>
                                <Grid item xs={6} md={6} >
                                  <Tooltip arrow title="Expotar Excel">
                                    <Button color="primary" onClick={() => exportExcelPendente()}>
                                      <ExportarExcelIcon tipo="BUTTON_EXPORT" />
                                    </Button>
                                  </Tooltip>
                                </Grid>
                              </Box>
                            </Card>
                            <Card>
                              <Box p={1}>
                                <Typography variant="caption">
                                  Valor Total das Comissões
                                </Typography>
                                <Typography variant="body1">
                                  <Box fontSize={14} fontWeight={600}>
                                    {toCurrency(queryStatusPendentes.valorTotalComissoes)}
                                  </Box>
                                </Typography>
                              </Box>
                            </Card>
                          </Grid>
                        </Grid>
                      )}
                      <Grid item xs={12} className={classes.listContainer}>
                        <ComissoesPendentesListData
                          list={queryStatusPendentes.listPendentes}
                          carregando={carregando}
                          onCardClicked={isSoftwarehouse ? addRemoveSelectedList : redirect}
                          onCardSelected={isSoftwarehouse ? addRemoveSelectedList : redirect}
                          selectedList={selectedList}
                          searchProps={searchProps}
                          setSearchProps={setSearchProps}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                {(queryStatusPendentes.listPendentes.length > 0 && isSoftwarehouse) && (
                  <div className={classes.footer}>
                    <Box p={1} display={'flex'} flexWrap='wrap' alignItems={'center'} justifyContent={"space-between"} width={"100%"}>
                      <Box ml={1}>
                        <Typography variant="body1">
                          <b>{selectedList.length}</b> Selecionados
                        </Typography>
                      </Box>
                      <Box ml={1} style={{ paddingRight: '30px' }}>
                        <Typography variant="caption">Valor Total</Typography>
                        <Typography variant="body1">
                          <Box fontWeight={600}>
                            {vTotalComissaoSelec.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                          </Box>
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Button
                        onClick={onClickAdd}
                        size="large"
                        variant="contained"
                        color="primary"
                        disabled={selectedList.length <= 0}
                      >
                        <CoinIcon tipo="BUTTON_PRIMARY" />
                        Pagar
                      </Button>
                    </Box>
                  </div>
                )}
              </div>
            ),
          ),
          new TabSaurusContent(
            1,
            (
              <Box display='flex' flexDirection='column' justifyContent='spacebetween'>
                {openSearch &&
                  <Grid className={classesCadastros.header}>
                    <ComissoesPagasFilter
                      openPesquisa={openSearch}
                      setSearchProps={setSearchProps}
                      searchProps={searchProps}
                    />
                  </Grid>
                }
                <div className={classes.defaultContainer}>
                  {carregando && <CircularLoading tipo="FULLSIZED" />}
                  <Box display='flex' flexDirection='column' height='100%' justifyContent='space-between' flex={1} overflow='auto'>
                    <Grid container className={classesCadastros.listContainer}>
                      <Grid item xs={12}>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'flex-end'}
                          width='100%'
                          mt={1}
                        >
                          <Grid item xs={12}>
                            <Paginacao
                              currentPage={queryStatusComissoes.page}
                              totalPages={queryStatusComissoes.totalPages}
                              totalRegisters={queryStatusComissoes.totalResults}
                              pageChanged={pageChanged}
                              exportarExcel={() => {
                                if (isMobile) {
                                  return null;
                                }
                                else {
                                  exportExcel();
                                }
                              }}
                              valorTotal={queryStatusComissoes.valorTotalComissoes}
                            />
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={12} className={classes.listContainer} style={{ overflowY: 'hidden', overflowX: 'hidden' }}>
                        <ComissoesListData
                          carregando={carregando}
                          list={queryStatusComissoes.listComissoes}
                          onCardClicked={openComissao}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </div>

                {isMobile ? (
                  <>
                    {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
                    <ButtonFabMenu
                      open={openMenu}
                      onOpen={handleOpen}
                      onClose={handleClose}
                      values={onClickMenuItem}
                      icon={<MenuVerticalIcon tipo='BUTTON_FAB' />}
                      style={{
                        zIndex: 0,
                      }}
                    />
                  </>
                ) : null}

              </Box>
            ),
          ),
        ]}
      />
    </>
  );
};