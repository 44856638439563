import { VariaveisAmbiente } from 'config';
import { FaturaFormModel } from 'model/app/forms/fatura/fatura-form-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePutFatura() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putFatura = useCallback(
        (fatura: FaturaFormModel) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Fatura`,
                method: 'PUT',
                data: fatura,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putFatura,
    };
}
