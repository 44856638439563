import { Box, Button, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useRef, useCallback, useEffect, useState, useMemo } from 'react';
import { useToastSaurus, useCadastros, useSessaoAtual } from 'services/app';
import {
  CancelarIcon,
  ConfiguracaoIcon,
  OkIcon,
  SalvarIcon,
  VoltarIcon,
} from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import classNames from 'classnames';
import { ContratoModel } from 'model/api/gestao/contrato/contrato-model';
import { useGetContratoById, usePutAtualizarContrato } from 'data/api/gestao/contratos';
import { FormContratoEditar } from 'views/components/form/master/contrato/contrato-edicao/form-contrato-editar';
import { ContratoFormAlternativoModel, ContratoFormEdicaoModel, ContratoPutModel } from 'model/app/forms/contrato/contrato-form-model';
import { DialogFilial } from 'views/components/dialog/dialog-filial/dialog-filial';
import { guidEmpty } from 'utils/guid-empty';
import { useThemeQueries } from 'views/theme';
import { LinkIcon } from 'views/components/icons/link-icon';
import { useStyles } from '../../dialog-contrato-styles';
import { DialogCancelarContrato } from 'views/components/dialog/dialog-cancelar-contrato/dialog-cancelar-contrato';
import { ContratoCancelamentoModel } from 'model/app/forms/contrato-cancelamento/contrato-cancelamento-model';
import { useConfirm } from 'material-ui-confirm';
import { usePatchReativarContrato } from 'data/api/gestao/contratos/patch-reativar-contrato';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { FilialIcon } from 'views/components/icons/filial-icon';
import { AnteciparFaturaIcon } from 'views/components/icons/antecipar-fatura-icon';
import { DialogAnteciparFatura } from 'views/components/dialog/dialog-antecipar-fatura/dialog-antecipar-fatura';
import { EnumTipoControleModulo } from 'model/enums/enum-tipo-controle-modulo';
import { EnumSistemas } from 'model/enums/enum-sistemas';

type Props = {
  id: string;
  pessoaId: string;
  setStatus: (status: boolean) => void;
  status: boolean;
  temVendedor: boolean;
  setBottomArea: (node: React.ReactNode) => void;
  setSistemaNome: (nome: string) => void;
  setBotaoFiliais: (node: React.ReactNode) => void;
}

export const ContratoEdicao = ({ id, pessoaId, setStatus, status, setBottomArea, setSistemaNome, setBotaoFiliais, temVendedor }: Props) => {

  const { putAtualizarContrato, carregando: carregandoPut } = usePutAtualizarContrato();
  const { getContratoById, carregando: carregandoGet } = useGetContratoById()
  const { patchReativarContrato, carregando: carregandoPatch } = usePatchReativarContrato();


  const [preenchendoTela, setPreechendoTela] = useState<boolean>(false)
  const [disable, setDisable] = useState<Array<boolean>>([false, false, false])
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [cancelDialog, setCancelDialog] = useState<boolean>(false)
  const [anteciparDialog, setAnteciparDialog] = useState<boolean>(false)
  const [openOptions, setOpenOptions] = useState<boolean>(false)

  const optionsRef = useRef<HTMLButtonElement>(null)

  const handleOptions = useCallback(() => setOpenOptions(prev => !prev), [])

  const [, setAtivo] = useState<boolean>(true)
  const confirm = useConfirm()

  const { isMobile } = useThemeQueries()

  const carregando = carregandoGet || carregandoPut || carregandoPatch || preenchendoTela

  const { fecharCadastroContrato } =
    useCadastros();
  const { showToast } = useToastSaurus();
  const { tipoUsuario } = useSessaoAtual();

  const refEditForm =
    useRef<DefaultFormRefs<ContratoFormAlternativoModel>>(null);
  const [contratoForm, setContratoForm] = useState<ContratoFormAlternativoModel>(new ContratoFormAlternativoModel())
  const refContratoModel = useRef<ContratoModel>(new ContratoModel())

  const isSoftwarehouse = (
    tipoUsuario() === EnumTipoPessoas.SoftwareHouse ||
    tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouse ||
    tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto
  )


  const mostrarBotoesCancelarReativar = isSoftwarehouse || status

  const isRetaguarda = useMemo(() => {
    return contratoForm.sistemaId === EnumSistemas.RETAGUARDA_APP
  }, [contratoForm.sistemaId])

  const classes = useModalStyles();
  const dialogClasses = useStyles();

  const recarregarForm = useCallback((model: ContratoFormAlternativoModel) => {
    refEditForm.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(contratoForm)
  }, [contratoForm, recarregarForm])

  const getContratoByIdWrapper = useCallback(async () => {
    const res = await getContratoById(pessoaId, id)

    if (res.erro) throw res.erro

    const ret = res.resultado?.data as ContratoModel

    setAtivo(ret.ativo)
    setStatus(ret.ativo)
    setSistemaNome(ret.sistemaNome)


    refContratoModel.current = ret

    const contratoAlternativo: ContratoFormEdicaoModel = {
      clienteId: ret.clienteId,
      dataExpiracao: ret.dataExpiracao,
      id: ret.id,
      melhorDia: ret.melhorDia,
      modulos: [...ret.modulos],
      planoId: ret.planoId,
      sistemaId: ret.sistemaId,
      ambienteNome: ret.ambienteNome,
      sistemaNome: ret.sistemaNome,
      tabelaPrecoId: ret.tabelaPrecoId,
      tabelaPreco: ret.tabelaPreco,
      valor: ret.valorVigente,
      valorCalculado: ret.valorCalculado,
      valorContrato: ret.valorVigente,
      valorDesconto: ret.valorCalculado - ret.valorVigente < 0 ? 0 : ret.valorCalculado - ret.valorVigente,
      valorCusto: ret.valorCusto,
      plano: ret.plano,
      dominio: ret.dominio,
      ambienteId: ret.ambienteId,
      formaPagamentoId: ret.formaPagamentoId,
      revendaId: ret.revendaId,
      tipoCobrancaRevenda: ret.tipoCobrancaRevenda,
      formaPagamentoNome: ret.formaPagamentoNome,
      dadosTela: ret.dadosTela

    }

    return contratoAlternativo
  }, [getContratoById, id, pessoaId, setSistemaNome, setStatus])

  useEffect(() => {
    (async () => {
      try {
        setPreechendoTela(true)
        const contrato = await getContratoByIdWrapper()
        setContratoForm(contrato)
      } catch (e: any) {
        if (e.message === 'Configure uma tabela de preço para seguir com o contrato' && temVendedor) {
          showToast('error', 'Você não possui uma tabela de preço configurada para este contrato. Crie uma tabela vinculada com o vendedor deste cliente para editar o contrato.')
          setPreechendoTela(false)
          return
        }
        showToast('error', e.message)
      } finally {
        setPreechendoTela(false)
      }
    })()
  }, [getContratoByIdWrapper, showToast, temVendedor])

  const saveContrato = useCallback(
    async (model: ContratoFormEdicaoModel) => {
      const contrato: ContratoPutModel = {
        clienteId: model.clienteId,
        ambienteId: model.ambienteId,
        dataExpiracao: model.dataExpiracao,
        id: model.id,
        melhorDia: model.melhorDia,
        planoId: model.planoId === guidEmpty() ? null : model.planoId,
        sistemaId: model.sistemaId,
        tabelaPrecoId: model.tabelaPrecoId,
        valor: model.valorContrato,
        valorCalculado: model.valorCalculado,
        formaPagamentoId: model.formaPagamentoId,
        modulos: model.modulos.map(item => {
          return {
            id: item.id,
            codigo: item.codigo,
            moduloId: item.moduloId,
            quantidadeContratada: item.quantidadeContratada
          }
        })
      }

      const ret = await putAtualizarContrato(contrato);

      if (ret.erro) {
        throw ret.erro;
      }
    },
    [putAtualizarContrato],
  );

  const onCloseClick = useCallback(() => {
    fecharCadastroContrato(`/clientes/${pessoaId}`)
  }, [fecharCadastroContrato, pessoaId]);

  const handleSubmit = useCallback(
    async (model: ContratoFormEdicaoModel, beforeModel: ContratoFormEdicaoModel) => {
      try {
        await saveContrato(model);

        onCloseClick()

        showToast('success', "Contrato Editado!")
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [onCloseClick, saveContrato, showToast],
  );

  const closeDialogFilial = useCallback(() => {
    setOpenDialog(false)
  }, [])

  function handleListKeyDownOptions(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenOptions(false);
    }
  }

  const handleCloseOptions = (event: React.MouseEvent<EventTarget>) => {
    if (optionsRef.current && optionsRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpenOptions(false);
  };


  const changeStatus = useCallback(() => {
    if (status) {
      setCancelDialog(true)
      return
    }

    confirm({
      title: 'Reativar Contrato',
      description: 'Deseja reativar este contrato?',
      confirmationText: 'Reativar',
      cancellationText: 'Cancelar',
    }).then(async () => {
      try {
        const res = await patchReativarContrato(id!)

        if (res.erro) throw res.erro

        setStatus(true)
        showToast('success', 'Contrato Reativado com sucesso')

      } catch (e: any) {
        showToast('error', e.message)
      }
    })
  }, [confirm, id, patchReativarContrato, setStatus, showToast, status])

  const showFiliais = useMemo(() => {
    return refContratoModel.current.modulos.find(mod => mod.tipoControle === EnumTipoControleModulo.Filial && mod.quantidadeContratada > 0) ||
      isSoftwarehouse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contratoForm])


  useEffect(() => {
    setBottomArea(<div className={classes.acoes} style={{ paddingBottom: '1rem', paddingTop: 0 }}>
      <Grid item xs={12} style={{ padding: '8px 0' }}>
        <Grid container spacing={2} justifyContent='flex-end'>
          {isRetaguarda && <Grid item xs={mostrarBotoesCancelarReativar ? 6 : 12}
            md={mostrarBotoesCancelarReativar ? 3 : 8} lg={mostrarBotoesCancelarReativar ? 2 : 6}>
            <Button variant='outlined' color='primary' fullWidth={isMobile || mostrarBotoesCancelarReativar} onClick={() => {
              window.open(`https://retaguarda.app/${contratoForm.dominio}`, '_blank');
            }}>
              <LinkIcon tipo='BUTTON' />
              Visitar Domínio
            </Button>
          </Grid>}
          {(isRetaguarda || mostrarBotoesCancelarReativar) && <Grid item xs={isRetaguarda ? 6 : 12} md={isRetaguarda ? 5 : 8} lg={isRetaguarda ? 6 : 8}>
            <Button variant='outlined' color='primary' onClick={handleOptions} fullWidth={isMobile} ref={optionsRef}>
              <ConfiguracaoIcon tipo='BUTTON' />
              Opções
            </Button>
            <Popper open={openOptions} anchorEl={optionsRef.current} placement="top" role={undefined} transition disablePortal style={{ zIndex: 999999999 }}>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseOptions}>
                      <MenuList autoFocusItem={openOptions} id="menu-list-grow" onKeyDown={handleListKeyDownOptions}>
                        {mostrarBotoesCancelarReativar && (
                          <MenuItem onClick={changeStatus}>
                            <Box display='flex' alignItems='center'>
                              {status ? <CancelarIcon tipo='BUTTON' /> : <OkIcon tipo='BUTTON' />}
                              {status ? 'Cancelar Contrato' : 'Reativar Contrato'}
                            </Box>
                          </MenuItem>
                        )}
                        {isSoftwarehouse && (
                          <MenuItem onClick={() => setAnteciparDialog(true)}>
                            <Box display='flex' alignItems='center'>
                              <AnteciparFaturaIcon tipo='BUTTON' />
                              Antecipar Fatura
                            </Box>
                          </MenuItem>
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>}
          {/* {(tipoUsuario() === EnumTipoPessoas.SoftwareHouse || EnumTipoPessoas.FinanceiroSoftwareHouse) && <Grid item xs={mostrarBotoesCancelarReativar ? 12 : 6} md={3} lg={2}>
            <Button
              variant='outlined'
              color='primary'
              onClick={onCloseClick}
              fullWidth
            >
              <AnteciparFaturaIcon tipo='BUTTON' />
              Antecipar Fatura
            </Button>
          </Grid>}
          {mostrarBotoesCancelarReativar && (
            status ? (
              <Grid item xs={isRetaguarda ? 6 : 12} md={isRetaguarda ? 5 : 8} lg={isRetaguarda ? 4 : 6}>
                <Button className={dialogClasses.buttonCancelar} variant='outlined' fullWidth={isMobile} disabled={carregando} onClick={changeStatus}>
                  <CancelarIcon tipo='BUTTON' />
                  {isMobile ? 'Cancelar' : 'Cancelar Contrato'}
                </Button>
              </Grid>
            ) : (
              <Grid item xs={isRetaguarda ? 6 : 12} md={isRetaguarda ? 6 : 8}>
                <Button className={dialogClasses.buttonReativar} variant='outlined' fullWidth={isMobile} disabled={carregando} onClick={changeStatus}>
                  <CancelarIcon tipo='BUTTON' />
                  {isMobile ? 'Reativar' : 'Reativar Contrato'}
                </Button>
              </Grid>
            )
          )} */}
          {!isMobile && <Grid item md={2}>
            <Button
              variant='outlined'
              color='primary'
              onClick={onCloseClick}
              fullWidth
            >
              <VoltarIcon tipo='BUTTON' />
              Voltar
            </Button>
          </Grid>}
          {isMobile && <Grid item xs={6}>
            <Button
              variant='outlined'
              color='primary'
              onClick={onCloseClick}
              fullWidth
            >
              <VoltarIcon tipo='BUTTON' />
              Voltar
            </Button>
          </Grid>}
          <Grid item xs={6} md={2}>
            <Button
              disabled={carregando || disable.includes(true) || !status}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => refEditForm.current?.submitForm()}
            >
              <SalvarIcon tipo='BUTTON_PRIMARY' />
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>)
    if (showFiliais)
      setBotaoFiliais(
        <Button variant='outlined' color='primary' onClick={() => setOpenDialog(true)} style={{ marginBottom: 5 }}>
          <FilialIcon tipo='BUTTON' />
          Empresas Vinculadas
        </Button>
      )
  }, [carregando, changeStatus, classes.acoes, contratoForm.dominio, contratoForm.sistemaId, dialogClasses.buttonCancelar, dialogClasses.buttonReativar, disable, handleOptions, isMobile, isRetaguarda, isSoftwarehouse, mostrarBotoesCancelarReativar, onCloseClick, openOptions, setBotaoFiliais, setBottomArea, showFiliais, status])

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <div className={classes.content}>
        <div
          className={classNames(
            carregando ? classes.contentFormsLoading : undefined,
          )}
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto',
            overflow: "hidden",
          }}
        >
          <FormContratoEditar
            ref={refEditForm}
            pessoaId={id}
            setDisable={setDisable}
            setOpenDialog={() => setOpenDialog(true)}
            disable={disable}
            showLoading={false}
            loading={carregando}
            onSubmit={handleSubmit}
            sistemaId={contratoForm.sistemaId}
            tipoCobranca={contratoForm.tipoCobrancaRevenda}
            dataCancelamento={refContratoModel.current.dataCancelamento}
            motivoCancelamento={refContratoModel.current.motivoCancelamento}
            indCancelamento={refContratoModel.current.indCancelamento}
            status={status}
            showFiliais={Boolean(showFiliais)}
          />
        </div>
      </div>
      <DialogCancelarContrato closeDialog={() => setCancelDialog(false)}
        model={new ContratoCancelamentoModel(id, '')}
        openned={cancelDialog}
      />
      {anteciparDialog && <DialogAnteciparFatura contratoId={contratoForm.id} onClose={() => setAnteciparDialog(false)}
        openned={anteciparDialog} />}
      {showFiliais &&
        <DialogFilial closeDialog={closeDialogFilial} contratoModel={contratoForm} openned={openDialog} />
      }
    </div>
  );
};
