import { useCallback, useState } from 'react';
import X2JS from 'x2js';
import * as pako from 'pako';
import { fetchApiComTimeout } from '../../utils/fetch-api-com-timeout';
import { VariaveisAmbiente } from '../../../../config';
import { WsMasterInvocarApiProps, WsMasterProps } from './wsmaster-props';

// tslint:disable-next-line: cognitive-complexity
export function useWsMaster<Tipo = any>(props: WsMasterProps) {
  const { operacao, xml, responseTagNome, resultadoTagNome, config } =
    props || {};
  const [carregando, setarCarregando] = useState(false);
  const [dados, setarDados] = useState<Tipo | null>(null);

  const hoje = new Date();
  const dia = hoje.getDate();
  const mes = hoje.getMonth() + 1;
  const ano = hoje.getFullYear() - 2000;
  const senhaWebService = btoa(`ophd02ophd02|@${dia + mes + ano}`);

  const invocarApi = useCallback(
    async (invocarProps: WsMasterInvocarApiProps) => {
      const {
        operacao: invocarOp,
        xml: invocarXml,
        responseTagNome: invocarResponseTagNome,
        resultadoTagNome: invocarResultadoTagNome,
      } = invocarProps || {};

      setarCarregando(true);
      let resultado: any | undefined;
      let erro: Error | undefined;
      let xmlDados: any;
      let resultadoJson: any;

      const xmlFn = invocarXml || xml;
      const body = xmlFn && xmlFn(senhaWebService);
      const operacaoAtual = invocarOp || operacao;
      const responseTag =
        invocarResponseTagNome || responseTagNome || `${operacaoAtual}Response`;
      const resultadoTag =
        invocarResultadoTagNome || resultadoTagNome || `${operacaoAtual}Result`;

      try {
        resultado = await fetchApiComTimeout(
          `${VariaveisAmbiente.saurusUtilsWebService}?op=${operacaoAtual}`,
          config?.timeout ?? 10 * 1000 /* 10 Segundos */,
          {
            body: body?.trim(),
            headers: {
              'Content-Type': 'application/soap+xml; charset=utf-8',
              Accept: '*/*',
            },
            method: 'POST',
          },
        );
        const parser = new X2JS();
        const xmlRetorno = resultado?.sucesso
          ? parser.xml2js<Tipo>(resultado?.text)
          : null;
        xmlDados = ((xmlRetorno as any)?.Envelope?.Body || {})[responseTag];
        const xmlResultado = pako.ungzip(atob(xmlDados[resultadoTag]), {
          to: 'string',
        });
        resultadoJson = parser.xml2js(xmlResultado);
        setarDados(resultadoJson as Tipo);
      } catch (e: any) {
        if (e.name.toUpperCase() === 'ABORTERROR') {
          erro = new Error(
            `O Tempo de Requisição foi Excedido (${
              (config?.timeout ?? 10000) / 1000
            }s). Tente novamente em alguns instantes`,
          );
        } else {
          erro = e;
        }
      }

      setarCarregando(false);

      if (xmlDados?.xRetNumero === '0') {
        return resultadoJson

      } else if (xmlDados?.xRetNumero === '1') {
        throw new Error(xmlDados?.xRetTexto);
      }

      throw erro;
    },
    [
      xml,
      senhaWebService,
      operacao,
      responseTagNome,
      resultadoTagNome,
      config?.timeout,
    ],
  );

  return {
    dados,
    carregando,
    invocarApi,
  };
}