import { isEmpty } from 'lodash';
import { DefaultModal } from 'views/components/modals/components/default-modal';
import { IndicacaoCadastro } from './components/indicacao-cadastro/indicacao-cadastro';

type Props = {
    openned: boolean;
    id: string;
}

export const IndicacaoModal = ({ id, openned }: Props) => {
    return (
        <DefaultModal
            minWidth="400px"
            open={openned || false}
            variant={"temporary"}
            anchor="right"
        >
            {openned && isEmpty(id) && <IndicacaoCadastro/>}
        </DefaultModal>
    );
};