import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    botaoMotivo: {
        marginBottom: 10,
        height: 50,
        borderColor: '#888 !important',
        color: "#888"
    },
    botaoMotivoSelecionado: {
    }
}
));