import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    imgTitulo: {
        width: '40px',
        height: '40px',
        marginBottom: -2,
        marginRight: 6,
        fill: theme.palette.primary.main,
        [theme.breakpoints.up('sm')]: {
            width: '40px',
            height: '40px',
            marginBottom: -2,
        },
        [theme.breakpoints.up('md')]: {
            width: '40px',
            height: '40px',
            marginBottom: -2,

        },
        [theme.breakpoints.up('lg')]: {
            width: '50px',
            height: '50px',
            marginBottom: -8,
        }
    },
    termosDeUso: {
        width: '100%',
        color: theme.palette.text.primary,
        fontSize: '1rem',
        textAlign: 'justify',
        '& td, table': {
            width: 'unset !important'
        },
        '& h2': {
            textAlign: 'center !important'
        }
    },
    localizacaoGrid: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

    },
    localizacaoGridSvg: {
        margin: theme.spacing(3),
        marginTop: theme.spacing(10),
        "& svg": {
            fill: theme.palette.primary.main,
            width: '250px',
        }
    }
}));