import { useCallback } from "react";
import { PrivatePageHeader } from "views/components/headers"
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { VoltarIcon } from "views/components/icons";
import { useHistory } from "react-router-dom";

export const SistemasByIdHeader = () => {

  const history = useHistory()

  const leftArea = useCallback(
    () => (
      <ButtonPrivateHeader icon={<VoltarIcon tipo="PRIVATE_HEADER" />} tooltip="Voltar" onClick={() => history.push('/sistemas')}></ButtonPrivateHeader>
    ), 
    [history]
  );

  return (
    <>
      <PrivatePageHeader
        title="Editar Sistema"
        leftArea={leftArea()}
      />
    </>
  );
};
