import { guidEmpty } from "utils/guid-empty";

export class PessoaComissaoFormModel {
    constructor(
        public id: string = guidEmpty(),
        public comissionadoId: string = '',
        public sistemaId: string = '',
        public isPercentual: boolean = true,
        public valorComissao: number = 0,
    ) { }
}