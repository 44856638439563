import { useEffect, useState, useCallback, useMemo } from "react";
import { Box, Grid } from "@material-ui/core";
import { useStyles } from "./clientes-list-styles";
import { ClientesListData } from "./clientes-list-data";
import { Paginacao } from "views/components/paginacao";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import { useCadastros, useSessaoAtual, useToastSaurus } from "services/app";
import { PessoasModel } from "model/api/gestao/pessoa/pessoas-model";
import { useGetPessoa } from "data/api/gestao/pessoa";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { useHistory, useLocation } from "react-router";
import { ButtonFab } from "views/components/controles";
import { MenuVerticalIcon, NovoIcon } from "views/components/icons";
import { ApiListModel } from "model/api/gestao/api-list-model/api-list-model";
import { ClientesListSearchProps } from "./clientes-list-search-props";
import { validarCPFCNPJ } from "utils/cpfcnpj-validate";
import { isEmpty } from "lodash";
import { useGetPessoaExportarExcel } from "data/api/gestao/pessoa/get-pessoa-exportar-excel";
import { SituacaoPessoaMock } from "data/mocks/pessoa-situacao-mock";
import { useThemeQueries } from "views/theme";
import { ButtonFabMenu } from "views/components/controles/buttons/button-fab-menu";
import { ExportarExcelIcon } from "views/components/icons/exportar-excel-icon";
import { ButtonFabMenuModel } from "model/app/components/button-fab-menu/button-fab-menu";
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles";

export interface ClientesListProps {
  searchProps: ClientesListSearchProps;
}

export const ClientesList = (props: ClientesListProps) => {
  const classes = useStyles();
  const listClasses = useDefaultCadastroStyles();
  const { tipoUsuario } = useSessaoAtual();
  const { getPessoa, carregando: carregandoGet } = useGetPessoa();
  const { showToast } = useToastSaurus();
  const history = useHistory();
  const location = useLocation();
  const { isMobile, theme } = useThemeQueries();
  const { abrirCadastroPessoa, cadastroPessoaState } = useCadastros();
  const { getPessoaExportarExcel, carregando: carregandoExcel } = useGetPessoaExportarExcel();

  const [queryStatus, setQueryStatus] = useState<ApiListModel<PessoasModel>>({
    pageIndex: 1,
    totalPages: 0,
    totalResults: 0,
    pageSize: 0,
    list: Array<PessoasModel>(),
  });

  const [selectedList, setSelectedList] = useState<Array<string>>([]);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const handleOpen = useCallback(() => {
    setOpenMenu(true);
  }, [])

  const handleClose = useCallback(() => {
    setOpenMenu(false);
  }, [])

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<PessoasModel>
    ) => {
      setQueryStatus({
        pageIndex: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
        pageSize: 0
      });
    },
    []
  );

  const carregando = carregandoExcel || carregandoGet

  const search = useCallback(
    async (newPage: number) => {
      let termo = props.searchProps.termo
      if (validarCPFCNPJ(termo)) {
        termo = termo.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '')
      }
      const queryPesquisa = `${!isEmpty(props.searchProps.termo) ? `&query=${props.searchProps.termo}` : ''}${!isEmpty(props.searchProps.documento) ? `&documento=${props.searchProps.documento}` : ''}${!isEmpty(props.searchProps.revenda) ? `&revenda=${props.searchProps.revenda}` : ''}${!isEmpty(props.searchProps.dominio) ? `&dominio=${props.searchProps.dominio}` : ''}${props.searchProps.status > -1 ? `&status=${props.searchProps.status}` : ''}`
      try {
        const query = `pageSize=30&TipoPessoa=${EnumTipoPessoas.Cliente}${queryPesquisa}`

        const res = await getPessoa(newPage, query);
        if (res.erro) throw res.erro;

        if (res.resultado?.data === undefined) {
          return
        }

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data.pageIndex > res.resultado?.data.totalPages &&
          res.resultado?.data.totalResults > 0
        ) {
          search(res.resultado.data.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data.pageIndex,
          res.resultado?.data.totalPages,
          res.resultado?.data.totalResults,
          res.resultado?.data.list
        );

        return res;
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [props.searchProps, getPessoa, fillResult, showToast]
  );

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  const onCardSelected = (id: string) => {
    history.push({
      pathname: `/clientes/${id}`,
      state: {
        from: {
          pathname: history.location.pathname,
          page: queryStatus.pageIndex
        }
      }
    });
  };

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  useEffect(() => {
    let page = Number(new URLSearchParams(location.search).get('page'));
    if (!cadastroPessoaState.aberto) {
      search(page || queryStatus.pageIndex);
    }
    location.search = ''
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, cadastroPessoaState]);

  const exportExcel = useCallback(async (newPage: number) => {
    const query =
      '' +
      (!isEmpty(props.searchProps.dominio)
        ? '&dominio=' + props.searchProps.dominio
        : '') +
      (props.searchProps.documento
        ? '&documento=' + props.searchProps.documento
        : '') +
      (props.searchProps.status
        ? '&status=' + props.searchProps.status
        : '') + 
        (!isEmpty(props.searchProps.revenda) ? 
        '&revenda=' + props.searchProps.revenda
        : '') +
        `&tipoPessoa=${EnumTipoPessoas.Cliente}`

    try {
      const res = await getPessoaExportarExcel(newPage, query)
      if (res.erro) throw (res.erro)
      if (!res.resultado) return;

      const blob = new Blob([res.resultado?.data], { type: 'application/vnd.ms-excel' });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a')

      anchor.href = url
      anchor.download = `Clientes(${SituacaoPessoaMock.find(x => x.Key === props.searchProps.status)?.Value})-${new Date().toLocaleDateString().replaceAll('/', '-')}.xls`
      anchor.click()

    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getPessoaExportarExcel, props.searchProps.documento, props.searchProps.dominio, props.searchProps.revenda, props.searchProps.status, showToast])

  const onClickMenuItem = useMemo(() => {
    const itens = new Array<ButtonFabMenuModel>();

    if (tipoUsuario() !== EnumTipoPessoas.GerenteComercial && tipoUsuario() !== EnumTipoPessoas.Funcionario)
      itens.push(new ButtonFabMenuModel(<NovoIcon tipo="BUTTON_FAB" fill={theme.palette.primary.main} />, 'Novo Cliente', () => abrirCadastroPessoa('', '', false, false)))

    itens.push(new ButtonFabMenuModel(<ExportarExcelIcon tipo='BUTTON_FAB' />, 'Exportar Excel', () => exportExcel(1)))

    return itens;
  }, [abrirCadastroPessoa, exportExcel, theme.palette.primary.main, tipoUsuario])

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Box display='flex' flexDirection='column' height='100%' justifyContent='space-between' flex={1} overflow='auto'>
          <Grid container className={listClasses.listContainer}>
            <Grid item xs={12}>
              <Paginacao
                pageChanged={pageChanged}
                totalPages={queryStatus.totalPages}
                totalRegisters={queryStatus.totalResults}
                currentPage={queryStatus.pageIndex}
                exportarExcel={() => exportExcel(1)}
              />
            </Grid>
            <Grid item xs={12} className={classes.listContainer}>
              <ClientesListData
                carregando={carregando}
                list={queryStatus.list}
                selectedList={selectedList}
                onCardSelected={onCardSelected}
                onCardChecked={onCardChecked}
                label='Cliente'
              />
            </Grid>
          </Grid>
        </Box>
      </div>
      {(tipoUsuario() !== EnumTipoPessoas.GerenteComercial && tipoUsuario() !== EnumTipoPessoas.Funcionario) ? (
        <>
          {isMobile ? (
            <>
              <ButtonFabMenu
                open={openMenu}
                onOpen={handleOpen}
                onClose={handleClose}
                values={onClickMenuItem}
                icon={<MenuVerticalIcon tipo='BUTTON_FAB' />}
                style={{
                  zIndex: 0,
                }}
              />
            </>
          ) : (
            <ButtonFab
              tooltip="Novo Cliente"
              icon={<NovoIcon tipo="BUTTON_FAB" />}
              onClick={() => abrirCadastroPessoa('', '', false, false)}
            />
          )}
        </>
      ) : null}
    </>
  );
};
