import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  defaultContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden"
  },
  listContainer: {
    padding: '10px 0'
  }
}));
