import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const DetalharIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M14.4613 1.71548C9.43857 3.49773 5.49601 7.11624 3.2817 11.9229C1.44544 15.9195 1.60747 22.6704 3.60575 26.775C5.49601 30.6636 8.62845 33.796 12.517 35.6863C15.1634 36.9824 16.2435 37.1985 20.1861 37.1985C24.1286 37.1985 25.2088 36.9824 27.9632 35.6323L31.0956 34.066L38.0626 41.033L45.0296 48L46.4878 46.4878L48 45.0296L41.087 38.1166L34.12 31.1496L35.7403 28.6113C41.4651 19.592 37.8466 7.38628 27.9092 2.57961C24.0206 0.63533 18.4038 0.311287 14.4613 1.71548ZM27.4231 4.73991C33.0939 7.4943 36.1183 12.517 36.1183 19.1059C36.1183 23.6966 34.9842 26.775 32.1218 30.0695C27.6931 35.0382 19.322 36.6044 13.0571 33.526C4.41586 29.2594 1.44544 18.5118 6.68418 10.3027C11.0048 3.49773 19.9701 1.1214 27.4231 4.73991Z" />
                <path d="M19.106 14.2453V18.0258H15.3255C11.923 18.0258 11.5449 18.1338 11.5449 19.1059C11.5449 20.0781 11.923 20.1861 15.3255 20.1861H19.106V23.9666C19.106 27.3691 19.214 27.7472 20.1861 27.7472C21.1583 27.7472 21.2663 27.3691 21.2663 23.9666V20.1861H25.0468C28.4493 20.1861 28.8274 20.0781 28.8274 19.1059C28.8274 18.1338 28.4493 18.0258 25.0468 18.0258H21.2663V14.2453C21.2663 10.8428 21.1583 10.4647 20.1861 10.4647C19.214 10.4647 19.106 10.8428 19.106 14.2453Z" />
            </DefaultIcon>
        </>
    );
};