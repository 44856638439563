import classNames from 'classnames'
import { VariaveisAmbiente } from 'config'
import { BackgroundWhiteBottom, BackgroundWhiteRight, BackgroundWhiteTop } from './background/background-white'
import { PublicPageHeaderProps } from './header-public-page-props'
import { useStyles } from './header-public-page-styles'
import TopLeftBackground from '../../../../assets/img/background-top-left.png'
import BottomLeftBackground from '../../../../assets/img/background-bottom-left.png'
import TopRightBackground from '../../../../assets/img/background-top-right.png'


export const PublicPageHeader = (props: PublicPageHeaderProps) => {
    const classes = useStyles();

    return (
        <>
            <div className={
                classNames(
                    classes.publicPageHeaderContainer,
                    props.variant === 'white' ? classes.publicPageHeaderContainerWhite : classes.publicPageHeaderContainerBlue
                )
            }>
                <div className={classes.publicPageHeaderTopContainer}>
                    {
                        props.variant === 'white' ?
                            <><BackgroundWhiteTop /> </> :
                            <><img src={TopLeftBackground} className={classes.pngImage} alt='Background'/><img src={TopRightBackground} className={classes.pngImage} alt='Background'/></>
                    }
                </div>
                <div className={classes.publicPageHeaderRightContainer}>
                    {
                        props.variant === 'white' ?
                            <> <BackgroundWhiteRight /> </> : null
                    }
                </div>
                <div className={classes.publicPageHeaderBottomContainer}>
                    {
                        props.variant === 'white' ?
                            <> <BackgroundWhiteBottom /> </> :
                            <>
                                <img src={BottomLeftBackground} style={{
                                    height: 257,
                                }} alt='Background'/>
                            </>
                    }
                </div>
                <div className={classes.publicPageHeaderVersionControl}>
                    <div>
                        <b>Ambiente: </b><i>{VariaveisAmbiente.environment}</i>
                    </div>
                    <div>
                        <b>Versão: </b><i>{VariaveisAmbiente.version}</i>
                    </div>
                </div>
            </div >
        </>
    )
}
