import { useState } from 'react';
import { TerminalListSearchProps } from './components/terminal-list/terminal-list-search-props';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { TerminalHeader } from './components/terminal-header/terminal-header';
import { TerminalList } from './components/terminal-list/terminal-list';
import { EnumSituacaoTerminal } from 'model/enums/enum-situacao-terminal';

export const TerminalPage = () => {
  const classes = useDefaultCadastroStyles();

  const [openPesquisa, setOpenPesquisa] = useState(false);
  const [searchProps, setSearchProps] = useState<TerminalListSearchProps>({
    chave: '',
    situacaoTerminal: [EnumSituacaoTerminal.Ativo, EnumSituacaoTerminal.Bloqueado, EnumSituacaoTerminal.Disponivel],
    query: '',
    tipo: '',
    sistemaOperacional: '',
    versao: ''
  });

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <TerminalHeader
          openPesquisa={openPesquisa}
          setSearchProps={setSearchProps}
          setOpenPesquisa={setOpenPesquisa}
        />
      </div>
      <TerminalList searchProps={searchProps} />
    </div>
  );
};

export default TerminalPage;
