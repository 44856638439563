import { Box, Grid, Typography } from '@material-ui/core';
import { DefaultCard } from '../components/default-card';
import { EditarIcon, NovoIcon } from 'views/components/icons';
import { CardIndicacoesInteresseProps } from './card-indicacoes-interesse-props';
import { useStyles } from './card-indicacoes-interesse-styles';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { useMemo } from 'react';
import { CaracteresString } from 'utils/caracteres-string';
import { isEmpty } from 'lodash';

export const CardIndicacoesInteresse = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardIndicacoesInteresseProps) => {
  const indicacoesClasses = useStyles();

  const img = useMemo(() => {
    let url: string
    try{
      url = atob(model?.sistema?.urlImagem);
    }catch(e: any){
       url = model.sistema?.urlImagem || ''
    }
    return <img src={isEmpty(url) ? SemImagem : url} className={indicacoesClasses.cardImg} alt={model?.sistema?.nome} />;
  }, [indicacoesClasses.cardImg, model?.sistema?.nome, model?.sistema?.urlImagem]);

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model);
        }}
        className={indicacoesClasses.card}
      >
        <Box p={2} display={"flex"} width="100%" style={{ boxShadow: '1px -1px 1px 1px rgb(0 0 0, 10%)' }}>
          <Grid>
            <Box mr={2}>
              <Typography>
                {img}
              </Typography>
            </Box>
          </Grid>
          {model.sistema ? <Grid>
            <Typography>
              <Box fontWeight={600}>
                {model?.sistema?.nome}
              </Box>
            </Typography>
            <Typography variant="body2">
              {CaracteresString(model?.sistema?.descricao, 300)}
            </Typography>
          </Grid> : (
            <Box display='flex' width='100%' alignItems='center' justifyContent='center'>
              <Typography variant='h6' color='textSecondary'>
                Nenhum sistema cadastrado.
              </Typography>
            </Box>
          )}
        </Box>
          <div className={indicacoesClasses.editIcon}>
            {model.sistema ? <EditarIcon tipo='BUTTON' /> : <NovoIcon tipo='BUTTON'/>}
          </div>
      </DefaultCard>
    </>
  );
};