import { guidEmpty } from "utils/guid-empty";
import { TabelaPrecoSistemaFormModel } from "./tabela-preco-sistemas-form-model";

export class TabelaPrecoFormModel {
  constructor(
    public id: string = guidEmpty(),
    public nome: string = '',
    public sistemas: TabelaPrecoSistemaFormModel[] | null = [],
    public vendedorId?: string
  ) { }
}


