import { KeyValueModel } from "model";
import { EnumGraficosDashboard } from "model/enums/enum-graficos-dashboard";

export const GraficosTodosMock: KeyValueModel[] = [
    new KeyValueModel(EnumGraficosDashboard.Saldo, "Saldo Revendedores"),
    new KeyValueModel(EnumGraficosDashboard.SaldoAtivos, "Saldo Revendedores (Ativos)"),
    new KeyValueModel(EnumGraficosDashboard.CarteiraSaurus, "Carteira Saurus"),
    new KeyValueModel(EnumGraficosDashboard.TotalRegistros, "Total Registros"),
    new KeyValueModel(EnumGraficosDashboard.TotalValores, "Total Valores"),
    new KeyValueModel(EnumGraficosDashboard.SaldoSaurus, "Média Saldo"),
    new KeyValueModel(EnumGraficosDashboard.NumerosRevenda, "Saldo"),
    new KeyValueModel(EnumGraficosDashboard.NumerosRevendaInativos, "Cancelamentos"),
    new KeyValueModel(EnumGraficosDashboard.PosicaoRevenda, "Métricas de Posição"),
    new KeyValueModel(EnumGraficosDashboard.ReceitaGerente, 'Receita do Gerente')
];

export const GraficosSaurusMock: KeyValueModel[] = [
    new KeyValueModel(EnumGraficosDashboard.CarteiraSaurus, "Carteira Saurus"),
    new KeyValueModel(EnumGraficosDashboard.TotalRegistros, "Registros de Faturas"),
    new KeyValueModel(EnumGraficosDashboard.TotalValores, "Valores de Faturas"),
    new KeyValueModel(EnumGraficosDashboard.SaldoSaurus, "Média Saldo"),
    new KeyValueModel(EnumGraficosDashboard.AtivosSaurus, 'Média Ativos')
];

export const GraficosRevendaSoftwareHouseMock: KeyValueModel[] = [
    new KeyValueModel(EnumGraficosDashboard.Saldo, "Saldo Revendedores"),
    new KeyValueModel(EnumGraficosDashboard.SaldoAtivos, "Saldo Revendedores (Ativos)"),
];

export const GraficosRevendaMock: KeyValueModel[] = [
    new KeyValueModel(EnumGraficosDashboard.NumerosRevenda, "Saldo"),
    new KeyValueModel(EnumGraficosDashboard.NumerosRevendaInativos, "Cancelamentos"),
    // new KeyValueModel(EnumGraficosDashboard.PosicaoRevenda, "Métricas de Posição"),
];

export const GraficosGerenteMock: KeyValueModel[] = [
    new KeyValueModel(EnumGraficosDashboard.ReceitaGerente, 'Receita'),
]


