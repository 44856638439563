import { Button, Grid } from '@material-ui/core';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import {
    SalvarNovoIcon,
    VoltarIcon,
} from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import classNames from 'classnames';
import { useCallback, useEffect, useRef } from 'react';
import { useCadastros, useSessaoAtual, useToastSaurus } from 'services/app';
import { DefaultFormRefs } from 'views/components/form/utils';
import { usePostAdicionarPessoa } from 'data/api/gestao/pessoa';
import { PessoaModel } from 'model/api/gestao/pessoa/pessoa-model';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { PessoaPostModel } from 'model/api/gestao/pessoa/pessoa-post-model';
import { FormFuncionarioCadastro } from 'views/components/form/master/funcionario/funcionario-cadastro/form-funcionario-cadastro';

export const FuncionarioCadastro = () => {


    const classes = useModalStyles();

    const { fecharCadastroFuncionarios } = useCadastros()

    const refForm = useRef<DefaultFormRefs<PessoaPostModel>>(null)
    const refNovo = useRef<boolean>(true)

    const { postAdicionarPessoa, carregando: carregandoPost } = usePostAdicionarPessoa();

    const { showToast } = useToastSaurus()

    const { tipoUsuario } = useSessaoAtual();

    useEffect(() => {
        refForm.current?.fillForm(new PessoaPostModel('', EnumTipoPessoas.Funcionario))
    }, [])

    const carregando = carregandoPost

    const handleSubmit = useCallback(async (model: PessoaPostModel) => {
        try {
            const res = await postAdicionarPessoa(model)

            if (res.erro) throw res.erro

            const ret = res.resultado?.data as PessoaModel
            if (refNovo.current) {
                refForm.current?.resetForm()
                showToast('success', 'Funcionário cadastrado')
                return
            }
            fecharCadastroFuncionarios(`/funcionarios/${ret.id}`)

            showToast('success', 'Funcionário cadastrado')
        }
        catch (err: any) {
            showToast('error', err.message)
        }
    }, [fecharCadastroFuncionarios, postAdicionarPessoa, showToast])


    return (
        <div className={classes.root}>
            <ModalHeader
                title='Novo Funcionário'
                leftArea={
                    <ButtonModalHeader
                        tooltip="Voltar"
                        icon={<VoltarIcon tipo="MODAL_HEADER" />}
                        onClick={() => fecharCadastroFuncionarios()}
                    />
                }
            />
            <div className={classes.content}>
                <div
                    className={classNames(
                        classes.contentForms,
                        carregando ? classes.contentFormsLoading : undefined,
                    )}
                >
                    <FormFuncionarioCadastro
                        loading={carregando}
                        showLoading={carregando}
                        onSubmit={handleSubmit}
                        isRevendedor={tipoUsuario() === EnumTipoPessoas.Revendedor}
                        ref={refForm}
                    />
                </div>
                <div className={classes.acoes}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={7}>
                            <Button
                                disabled={carregando}
                                onClick={() => {
                                    refNovo.current = true
                                    refForm.current?.submitForm()
                                }}
                                variant="outlined"
                                color="primary"
                                size="large"
                                fullWidth
                            >
                                <SalvarNovoIcon tipo="BUTTON" />
                                Salvar e Adicionar Novo
                            </Button>
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <Button
                                disabled={carregando}
                                onClick={() => {
                                    refNovo.current = false
                                    refForm.current?.submitForm()
                                }}
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                            >
                                <SalvarNovoIcon tipo="BUTTON_PRIMARY" />
                                Salvar e Editar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
};