import { VariaveisAmbiente } from 'config';
import { ContratoPutModel } from 'model/app/forms/contrato/contrato-form-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePutAtualizarContrato() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putAtualizarContrato = useCallback(
        (contrato: ContratoPutModel) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/contrato`,
                method: 'PUT',
                data: contrato,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putAtualizarContrato,
    };
}
