import { KeyValueModel } from "model/api";
import { EnumSituacaoTerminal } from "model/enums/enum-situacao-terminal";

export const SituacaoTerminalMock: KeyValueModel[] = [
    new KeyValueModel(EnumSituacaoTerminal.Ativo, 'Ativo'),
    new KeyValueModel(EnumSituacaoTerminal.Bloqueado, 'Bloqueado'),
    new KeyValueModel(EnumSituacaoTerminal.Desativado, 'Desativado'),
    new KeyValueModel(EnumSituacaoTerminal.Disponivel, 'Disponível')
];

export const SituacaoTerminalMockTodos: KeyValueModel[] = [
    new KeyValueModel(-2, "Todos Ativos"),
    new KeyValueModel(-5, ''),
    new KeyValueModel(EnumSituacaoTerminal.Ativo, 'Ativo'),
    new KeyValueModel(EnumSituacaoTerminal.Bloqueado, 'Bloqueado'),
    new KeyValueModel(EnumSituacaoTerminal.Desativado, 'Desativado'),
    new KeyValueModel(EnumSituacaoTerminal.Disponivel, 'Disponível'),
    new KeyValueModel(-1, "Todos"),
];