import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetAllComissionados() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getAllComissionados = useCallback(async () => {
    return invocarApi({
      url: `${VariaveisAmbiente.apiVersion}/Pessoa/comissionados`,
      method: 'GET',
      enviarTokenUsuario: true,
    });
  }, [invocarApi]);
  return {
    ...outrasPropriedades,
    getAllComissionados,
  };
}
