import { Grid, Tooltip, Typography } from '@material-ui/core';
import { DefaultCard } from '../components/default-card';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { useThemeQueries } from 'views/theme';
import { EditarIcon, OlhoAbertoIcon } from 'views/components/icons';
import { useStyles } from './card-comissao-styles'
import { CardComissaoProps } from './card-comissao-props';
import { toDateString } from 'utils/to-date';
import { useSessaoAtual } from 'services/app';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';

export const CardComissao = ({
    model,
    onClick,
}: CardComissaoProps) => {
    const { isMobile } = useThemeQueries()
    const {tipoUsuario} = useSessaoAtual()

    const classes = useStyles()
    return (
        <>
            <DefaultCard
                onClick={() => {
                    onClick(model.id);
                }}
            >
                <Grid container spacing={1} className={classes.container}>
                    <Grid item xs={6} md={3}>
                        <Typography color="textPrimary" variant="caption">
                            Data do Pagamento
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                        >
                            <b>{toDateString(model.dataPagamento)}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography color="textPrimary" variant="caption">
                            Documento:
                        </Typography>
                        <Typography color="textPrimary" variant="body1">
                            {formatarCPFCNPJ(model.comissionadoCpfCnpj)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography color='textPrimary' variant='caption' style={{ fontSize: 11 }}>
                            Comissionado
                        </Typography>
                        <Typography color='textPrimary' variant='body1'>
                            {model.comissionadoNome}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography color='textPrimary' variant='caption' style={{ fontSize: 11 }}>
                            Valor Total
                        </Typography>
                        <Typography color='textPrimary' variant='body1'>
                            {model.valorTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </Typography>
                    </Grid>
                </Grid>
                {!isMobile && <Tooltip arrow title={tipoUsuario() === EnumTipoPessoas.SoftwareHouse ? 'Editar Comissão' : 'Visualizar Comissão'}>
                    <div className={classes.cardRightButton}>
                        {tipoUsuario() === EnumTipoPessoas.SoftwareHouse ? <EditarIcon tipo="BUTTON" /> : <OlhoAbertoIcon tipo='BUTTON'/>}
                    </div>
                </Tooltip>}
            </DefaultCard>
        </>
    );
};
