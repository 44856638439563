import { Button, Grid, Tooltip } from '@material-ui/core';
import { useStyles } from '../../../clientes/components/clientes-editar/components/cliente-edit/cliente-edit-styles';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { LoginIcon, SalvarEditarIcon } from 'views/components/icons';
import {
  useGetPessoaById,
  usePutAtualizarPessoa,
} from 'data/api/gestao/pessoa';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PessoaModel } from 'model/api/gestao/pessoa/pessoa-model';
import { picker } from 'utils/picker';
import classNames from 'classnames';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { useThemeQueries } from 'views/theme';
import { AccordionClienteInformacoesList } from 'views/pages/private/clientes/components/clientes-editar/components/accordion-cliente-informacoes-list/accordion-cliente-informacoes-list';
import { AccordionClienteSociosList } from 'views/pages/private/clientes/components/clientes-editar/components/accordion-cliente-socio-list/accordion-cliente-socio-list';
import { PessoaPostModel } from 'model/api/gestao/pessoa/pessoa-post-model';
import { imagemForUpload } from 'utils/imagem-for-upload';
import { usePostImagemBase64 } from 'data/api/imagem/post-imagem';
import { newGuid } from 'utils/new-guid';
import { EnumRetornoApiBase } from 'data/api/base/api-base-response';
import { isEmpty } from 'lodash';
import { useConsultaCnpj } from 'data/api/wsmaster';
import { ConsultaCNPJModel } from 'model';
import { DialogOpcoesConsultaCnpj } from 'views/components/dialog/dialog-opcoes-consulta-cnpj/dialog-opcoes-consulta-cnpj';
import { CircularLoading } from 'views/components';
import { LocalizacaoModel } from 'model/api/gestao/localizacao/localizacao-model';
import { useRevendedorStyles } from './gerente-edit-styles';
import { FormGerenteEdicao } from 'views/components/form/master/pessoa/gerente-edicao/form-gerente-edicao';
import { useConfirm } from 'material-ui-confirm';
import { useHistory } from 'react-router-dom';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';

type Props = {
  id: string;
  atualizar?: boolean;
  setAtualizar?: (valor: boolean) => void;
};

export const GerenteEdit = ({ id }: Props) => {

  const { showToast } = useToastSaurus();
  const { tipoUsuario, deslogar, logarIntegracao } = useSessaoAtual()
  const confirm = useConfirm()
  const history = useHistory()


  const { putAtualizarPessoa, carregando: carregandoPut } =
    usePutAtualizarPessoa();
  const { getPessoaById, carregando: carregandoGet } = useGetPessoaById();
  const [preenchendoTela, setPreechendoTela] = useState<boolean>(false);
  const [cnpjEmpresa, setCnpjEmpresa] = useState<ConsultaCNPJModel>(new ConsultaCNPJModel())
  const { theme } = useThemeQueries();
  const [openAccordion, setOpenAccordion] = useState<boolean>(true);
  const [isAbertodialog, setIsAbertoDialog] = useState<boolean>(false)
  const { consultarCNPJ, carregando: carregandoCNPJ } = useConsultaCnpj()
  const [atualizar, setAtualizar] = useState<boolean>(false)
  const { postImagemBase64 } = usePostImagemBase64();
  const { usuario } = useSessaoAtual();

  const classes = useStyles()
  const pageStyles = useRevendedorStyles();

  const carregando = carregandoGet || carregandoPut || preenchendoTela;

  const cadClienteFormRef = useRef<DefaultFormRefs<PessoaPostModel>>(null);
  const refRevendaModel = useRef<PessoaModel>(new PessoaModel());
  const [revendaForm, setRevendaForm] = useState<PessoaPostModel>(
    new PessoaPostModel(),
  );
  const [localizacao, setLocalizacao] = useState<LocalizacaoModel | undefined>()

  const recarregarForm = useCallback((model: PessoaPostModel) => {
    cadClienteFormRef.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(revendaForm);
  }, [revendaForm, recarregarForm]);

  const getContratoByIdWrapper = useCallback(async () => {
    const res = await getPessoaById(id);

    if (res.erro) throw res.erro;

    const ret = res.resultado?.data as PessoaModel;

    refRevendaModel.current = ret;

    const revenda = picker<PessoaPostModel>(ret, new PessoaPostModel());

    return revenda;
  }, [getPessoaById, id]);

  useEffect(() => {
    (async () => {
      try {
        setPreechendoTela(true);
        const revenda = await getContratoByIdWrapper();
        setRevendaForm(revenda);
      } catch (e: any) {
        showToast('error', e.message);
      } finally {
        setPreechendoTela(false);
      }
    })();
  }, [getContratoByIdWrapper, showToast]);

  const enviarImagem = useCallback(
    async (imagemUrl: string) => {
      let imagem = '';

      const imgUpload = imagemForUpload(imagemUrl);
      if (imgUpload.length > 0) {
        const retImagem = await postImagemBase64(
          imgUpload,
          `licenciamento/pessoas/${usuario?.Id}/`,
          newGuid(),
        );
        if (retImagem.tipoRetorno !== EnumRetornoApiBase.Sucesso) {
          throw new Error('Erro ao processar  a Imagem selecionada.');
        }
        if (retImagem.resultado?.data.data.status === 2) {
          throw new Error(
            'Erro ao processar a Imagem selecionada.Detalhe: ' +
            retImagem.resultado?.data?.data?.retorno,
          );
        }
        imagem =
          retImagem.resultado?.data?.data?.url_blob +
          '?timestamp=' +
          new Date().getTime();
      }

      return imagem.length > 0 ? btoa(imagem) : imagem;
    },
    [postImagemBase64, usuario?.Id],
  );
  const saveNewCliente = useCallback(
    async (model: PessoaPostModel, beforeModel?: PessoaPostModel) => {
      if (!isEmpty(model.urlImagem) && model.urlImagem !== beforeModel?.urlImagem)
        model.urlImagem = await enviarImagem(model.urlImagem);

      const ret = await putAtualizarPessoa(model);

      if (ret.erro) {
        throw ret.erro;
      }
    },
    [enviarImagem, putAtualizarPessoa],
  );

  const consultarCNPJWrapper = useCallback(async () => {
    try {
      const empresa = await consultarCNPJ(revendaForm.cpfCnpj, localizacao)
      setCnpjEmpresa(empresa)

      setIsAbertoDialog(true)
    } catch (err: any) {
      showToast('error', err.message)
    }
  }, [consultarCNPJ, revendaForm.cpfCnpj, localizacao, showToast])

  const handleLogin = useCallback(async () => {
    try {
      deslogar();
      await logarIntegracao(revendaForm.id, revendaForm.cpfCnpj);
      history.push('/termos');
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [deslogar, revendaForm.cpfCnpj, revendaForm.id, history, logarIntegracao, showToast])

  const confirmLogin = useCallback(() => {
    confirm({
      title: 'Login',
      description: 'Tem certeza que deseja entrar na conta de ' + revendaForm.nomeComercial + '? Você será desconectado da sua sessão atual.',
      confirmationText: 'Entrar',
      cancellationText: 'Cancelar'
    }).then(() => handleLogin())
  }, [confirm, handleLogin, revendaForm.nomeComercial])

  const handleSubmit = useCallback(
    async (model: PessoaPostModel, beforeModel?: PessoaPostModel) => {
      try {
        const gerentePicker = picker<PessoaPostModel>(
          model,
          refRevendaModel.current,
        );

        //pra ter ctz q o picker n vai colocar melhor dia caso o gerente tenha um melhor dia por whatever reason
        gerentePicker.melhorDia = null
        
        await saveNewCliente(gerentePicker, beforeModel);
        showToast('success', 'Gerente Atualizado!');
        return true;
      } catch (e: any) {
        showToast('error', e.message);
        cadClienteFormRef.current?.resetForm();
        return false;
      }
    },
    [saveNewCliente, showToast],
  );

  useEffect(() => {
    if ('geolocation' in navigator) {
      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setLocalizacao(
            new LocalizacaoModel(position.coords.latitude, position.coords.longitude)
          );
        },
        function (error) {

        },
        options,
      );
    }
  }, []);

  const formPessoaEdicao = useMemo(() => (
    <>
      <AccordionSaurus
        labelPrimary={'Revendedor'}
        tipoExpand="bold"
        noPaperRoot={false}
        heightDivider={2}
        showDivider={openAccordion}
        colorDivider={theme.palette.primary.main}
        colorExpand={theme.palette.primary.main}
        expanded={openAccordion}
        onChange={() => setOpenAccordion((prev) => !prev)}
      >
        <div className={classes.content}>
          {carregandoCNPJ && <CircularLoading tipo='FULLSIZED' />}
          <div
            className={classNames(
              classes.contentForms,
              carregando ? classes.contentFormsLoading : undefined,
            )}
          >
            <FormGerenteEdicao
                loading={carregando}
                onSubmit={handleSubmit}
                showLoading={carregando}
                ref={cadClienteFormRef}
                consultarCnpj={consultarCNPJWrapper}
            />
          </div>
          <div className={classes.acoes}>
          <Grid container spacing={2} justifyContent={tipoUsuario() === EnumTipoPessoas.SoftwareHouse ? 'space-between' : 'flex-end'}>
              {tipoUsuario() === EnumTipoPessoas.SoftwareHouse && (
                <Grid item xs={12} md={3}>
                  <Tooltip title='Entrar na conta do gerente'>
                    <Button
                      disabled={carregando}
                      onClick={confirmLogin}
                      variant="outlined"
                      color="primary"
                      size="large"
                      fullWidth
                    >
                      <LoginIcon tipo="BUTTON" />
                      Logar
                    </Button>
                  </Tooltip>
                </Grid>
              )}
              <Grid item xs={12} md={4}>
                <Button
                  disabled={carregando}
                  onClick={() => {
                    cadClienteFormRef.current!.submitForm();
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </AccordionSaurus>
      <DialogOpcoesConsultaCnpj
        openned={isAbertodialog}
        setAtualizar={setAtualizar}
        closeDialog={() => setIsAbertoDialog(false)}
        consultaCnpjModel={cnpjEmpresa}
        pessoaId={id}
      />
    </>
  ), [carregando, carregandoCNPJ, classes.acoes, classes.content, classes.contentForms, classes.contentFormsLoading, cnpjEmpresa, confirmLogin, consultarCNPJWrapper, handleSubmit, id, isAbertodialog, openAccordion, theme.palette.primary.main, tipoUsuario])

  const accordioClienteSociosList = useMemo(() => (
    <AccordionClienteSociosList atualizar={atualizar} setAtualizar={setAtualizar} id={id}/>
  ), [atualizar, id])

  const accordionClienteInformacoesList = useMemo(() => (
    <AccordionClienteInformacoesList atualizar={atualizar} setAtualizar={setAtualizar} id={id} tipoPessoa={revendaForm.tipo}/>
  ), [atualizar, id, revendaForm.tipo])

  return (
    <Grid container spacing={2} className={pageStyles.defaultContainer}>
      <Grid item xs={12}>
        {formPessoaEdicao}
      </Grid>
      <Grid item xs={12}>
        {accordionClienteInformacoesList}
      </Grid>
      <Grid item xs={12}>
        {accordioClienteSociosList}
      </Grid>
    </Grid>
  );
};
