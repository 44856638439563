import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    qrCodecontainer: {
        
    },
    qrCodeBox: {
        padding: 10
    },
    qrCodeInfo:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: theme.spacing(2)
    }
}));
