import { useMemo, useState } from 'react';


import { Grid } from '@material-ui/core';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { ClassificacoesHeader } from './components/classificacoes-header/classificacoes-header';
import { ClassificacoesList } from './components/classificacoes-list/classificacoes-list';

export const ClassificacoesPage = () => {
  const classes = useDefaultCadastroStyles();

  const [query, setQuery] = useState<string>('');

  const classificacaoPage = useMemo(() => <ClassificacoesList query={query}/>, [query])

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <ClassificacoesHeader setQuery={setQuery}/>
      </Grid>
      {classificacaoPage}
    </Grid>
  );
};

export default ClassificacoesPage;