import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"
export const LogoutIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
            <path d="M 25 2 C 12.309534 2 2 12.309534 2 25 C 2 37.690466 12.309534 48 25 48 C 32.610275 48 39.366605 44.294736 43.550781 38.591797 A 1.0001 1.0001 0 1 0 41.939453 37.408203 C 38.117629 42.617264 31.961725 46 25 46 C 13.390466 46 4 36.609534 4 25 C 4 13.390466 13.390466 4 25 4 C 31.961725 4 38.117629 7.3827357 41.939453 12.591797 A 1.0001 1.0001 0 1 0 43.550781 11.408203 C 39.366605 5.7052643 32.610275 2 25 2 z M 38.990234 15.990234 A 1.0001 1.0001 0 0 0 38.292969 17.707031 L 44.585938 24 L 23 24 A 1.0001 1.0001 0 1 0 23 26 L 44.585938 26 L 38.292969 32.292969 A 1.0001 1.0001 0 1 0 39.707031 33.707031 L 47.619141 25.794922 A 1.0001 1.0001 0 0 0 47.617188 24.203125 L 39.707031 16.292969 A 1.0001 1.0001 0 0 0 38.990234 15.990234 z"/>
            </DefaultIcon>
        </>
    );
};

