import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: '100%',
        height: '100vh',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
        padding: theme.spacing(2)
    },
    formContainer: {
       overflowY: 'auto',
       padding: theme.spacing(2),
       flexGrow: 1
    }
}));