import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDefaultCadastroStyles } from "../cadastros/components/default-cadastro-styles";
import { IndicacoesEditar } from "./components/indicacoes-editar/indicacoes-editar";
import { IndicacoesHeader } from "./components/indicacoes-header/indicacoes-header";
import { IndicacoesListSearchProps } from "./components/indicacoes-tabs/components/indicacoes-list-seach-props";
import { IndicacoesTabs } from "./components/indicacoes-tabs/indicacoes-tabs";

interface paramProps {
    parametro: string;
    status: string;
}

export const IndicacoesPage = () => {
    const classes = useDefaultCadastroStyles();

    const [openPesquisa, setOpenPesquisa] = useState(false);
    const [searchProps, setSearchProps] = useState<IndicacoesListSearchProps>({
        termo: '',
        revendaId: '',
        indicadorId: '',
        dataInicial: '',
        dataFinal: '',
        statusProcesso: -1,
    });

    const { parametro, status } = useParams<paramProps>()

    const pageSwitch = useMemo(() => {
        if (parametro) {
            setOpenPesquisa(false)
            return (
                <div className={classes.defaultContainer}>
                    <IndicacoesEditar id={parametro} status={status} />
                </div>
            );
        }
        switch (status) {
            case 'disponiveis':
                return (
                    <IndicacoesTabs
                        openSearch={openPesquisa}
                        searchProps={searchProps}
                        openPesquisa={openPesquisa}
                        setOpenPesquisa={setOpenPesquisa}
                        setSearchProps={setSearchProps}
                    />
                )
            case 'aceitas':
                return (
                    <IndicacoesTabs
                        openSearch={openPesquisa}
                        searchProps={searchProps}
                        openPesquisa={openPesquisa}
                        setOpenPesquisa={setOpenPesquisa}
                        setSearchProps={setSearchProps}
                    />
                )
            case 'em-validacao':
                return (
                    <IndicacoesTabs
                        openSearch={openPesquisa}
                        searchProps={searchProps}
                        openPesquisa={openPesquisa}
                        setOpenPesquisa={setOpenPesquisa}
                        setSearchProps={setSearchProps}
                    />
                )
            case 'finalizadas':
                return (
                    <IndicacoesTabs
                        openSearch={openPesquisa}
                        searchProps={searchProps}
                        openPesquisa={openPesquisa}
                        setOpenPesquisa={setOpenPesquisa}
                        setSearchProps={setSearchProps}
                    />
                )
        }
    }, [classes.defaultContainer, openPesquisa, parametro, searchProps, status])

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <IndicacoesHeader
                    titulo={parametro === undefined
                        ? 'Indicações'
                        : parametro === 'adicionar'
                            ? 'Adicionar Indicação' : 'Editar Indicação'}
                    openPesquisa={openPesquisa}
                    setSearchProps={setSearchProps}
                    setOpenPesquisa={setOpenPesquisa}
                    status={status}
                />
            </div>
            {pageSwitch}
        </div>
    )
}

export default IndicacoesPage;