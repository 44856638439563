import { RevendaDesempenhoModel } from "model/api/gestao/revendedor/revenda-desempenho-model";
import { CardNaoEncontrado } from "views/components/cards";
import { CardDesempenhoRevenda } from "views/components/cards/card-desempenho-revenda/card-desempenho-revenda";
import { InformacaoIcon } from "views/components/icons";

export interface ClientesGridProps {
  list: Array<RevendaDesempenhoModel>;
  carregando: boolean;
  onClick: (id: string) => any;
}

export const DesempenhoRevendaListData = (props: ClientesGridProps) => {

  const onCardClick = (id: string) => {
    props.onClick(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem='Nenhum dado de desempenho disponível.'
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props?.list.length > 0 &&
        props.list.map((model, index) => {
          return (
            <CardDesempenhoRevenda
                model={model}
                onClick={onCardClick}
            />
          );
        })}
    </>
  );
};