import { DefaultModal } from 'views/components/modals/components/default-modal';
import { useThemeQueries } from 'views/theme';
import { RepresentantesCadastro } from './components/representantes-cadastro';

type Props = {
  openned: boolean;
}

export const RepresentantesModal = ({ openned }: Props) => {
  const { isMobile } = useThemeQueries()
  return (
    <DefaultModal
      minWidth={isMobile ? '0' : "600px"}
      open={openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {openned && <RepresentantesCadastro/>}
    </DefaultModal>
  );
};
