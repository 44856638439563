import { Box, Button, Divider, Grid, Typography } from "@material-ui/core";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { useThemeQueries } from "views/theme";
import { useCallback, useMemo, useState } from "react";
import { MenuSaurusModel } from "model/app/components/menu-saurus/menu-saurus-model";
import { MenuListSaurus } from "views/components/menu-list-saurus/menu-list-saurus";
import { IndicacoesInteracoesModel } from "model/api/gestao/indicacoes/indicacoes-interacoes-model";
import { DialogMotivoConfirmacaoIndicacao } from "./dialog-motivo-confirmacao-indicacao/dialog-motivo-confirmacao-indicacao";
import { EnumStatusIndicacao } from "model/enums/enum-status-indicacao";

interface Props {
    id: string;
    aberto: boolean;
    interacoes: IndicacoesInteracoesModel[];
    onClose: () => void;
    setDialogConclusao: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DialogMotivoIndicacao = ({
    id,
    aberto,
    interacoes,
    onClose,
    setDialogConclusao,
}: Props) => {

    const { theme } = useThemeQueries();

    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [model, setModel] = useState<IndicacoesInteracoesModel>(new IndicacoesInteracoesModel());

    const boxColorido = useCallback((cor: string) => (
        <Box borderRadius={theme.shape.borderRadius} width="20px" height="20px" marginRight={1} style={{ background: cor }} />
    ), [theme.shape.borderRadius])

    const abrirDialog = (value: IndicacoesInteracoesModel, status: EnumStatusIndicacao) => {
        value.tipoInteracao = status
        setModel(value);
        setOpenDialog(true);
    }

    const menuOptions = useMemo(() => {
        const options: Array<MenuSaurusModel> = [];

        const modelNovo = interacoes[0];

        options.push(new MenuSaurusModel("Proposta Recusada", boxColorido('#8F00FF'), () => abrirDialog(modelNovo, EnumStatusIndicacao.PropostaRecusada)))
        options.push(new MenuSaurusModel("Indicação Inválida", boxColorido('#464EB8'), () => abrirDialog(modelNovo, EnumStatusIndicacao.InvalidezSolicitada)))
        options.push(new MenuSaurusModel("Outros Motivos", boxColorido(theme.palette.error.main), () => abrirDialog(modelNovo, EnumStatusIndicacao.CancelamentoSolicitado)))

        return options
    }, [boxColorido, interacoes, theme.palette.error.main])

    return (
        <>
            <DialogSaurus
                aberto={aberto}
                titulo="Motivo"
                colorTitulo={theme.palette.primary.main}
                centralizarTitulo={true}
                tamanho="xs"
                bottomArea={
                    <>
                        <div style={{
                            paddingTop: '8px',
                            paddingBottom: '16px',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                        }}>
                            <Divider variant="fullWidth" style={{ background: theme.palette.grey[400], marginBottom: '8px' }} />
                            <Button
                                variant="outlined"
                                onClick={onClose}
                                fullWidth
                            >
                                Voltar
                            </Button>
                        </div>
                    </>
                }
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{
                        display: 'flex',
                        textAlign: 'center'
                    }}>
                        <Typography
                            variant="body2"
                            color="textPrimary"
                        >
                            Selecione uma da informações a baixo para finalizar
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <MenuListSaurus
                            onCloseClick={() => false}
                            setaAvancar
                            options={menuOptions}
                        />
                    </Grid>
                </Grid>
            </DialogSaurus>

            {openDialog && (
                <DialogMotivoConfirmacaoIndicacao
                    id={id}
                    model={model}
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    setDialogConclusao={setDialogConclusao}
                />
            )}

        </>
    )
}