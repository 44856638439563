import { Grid, Typography } from '@material-ui/core';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useStyles } from '../../dialog/dialog-filial/dialog-filial-styles'
import { CardRelatorioRevendasProps } from './card-relatorio-revendas-props';


export const CardRelatorioRevenda = ({
    model,
    index
}: CardRelatorioRevendasProps) => {
    const classes = useDefaultCardStyles();
    const dialogClasses = useStyles();

    return (
        <>
            <DefaultCard
                className={dialogClasses.noPosition}
            >
                <div className={classes.cardContent} style={{background: '#FCFCFC'}}>
                    <Grid container spacing={2} alignItems='center' style={{padding: '0 10px'}}>
                        {index <= 30 && <Grid item xs={1}>
                        <Typography variant='h6'>{index}º</Typography>
                        </Grid>}
                        <Grid item xs={10} md={index > 30 ? 6 : 5}>
                            <Typography color="textPrimary" variant="caption">
                                Nome
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {model.nomeComercial}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={2}>
                        <Typography color="textPrimary" variant="caption">
                                Grupo ABC
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {model.grupo}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Typography color="textPrimary" variant="caption">
                                Ativos
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {model.ativos}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Typography color="textPrimary" variant="caption">
                                Inativos
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {model.inativos}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </DefaultCard>
        </>
    );
};
