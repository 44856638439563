import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePutCancelar() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putCancelar = useCallback(
        (faturaId: string, motivo: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Fatura/${faturaId}/cancelar?motivo=${motivo}`,
                method: 'PUT',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putCancelar,
    };
}
