import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetPessoaById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getPessoaById = useCallback(
        (id: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Pessoa/${id}`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getPessoaById,
    };
}
