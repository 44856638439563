import { VariaveisAmbiente } from 'config';
import { PagamentoModel } from 'model/api/gestao/pagamento/pagamento-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { PagamentoRespostaModel } from 'model/api/gestao/pagamento/pagamento-resposta-model';

export function usePutAtualizarPagamento() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<PagamentoRespostaModel>();

    const putAtualizarPagamento = useCallback(
        (instituicao: PagamentoModel) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/FormaPagamento`,
                method: 'PUT',
                data: instituicao,
                enviarTokenUsuario: true,
                timeout: 100000
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putAtualizarPagamento,
    };
}
