
import { useMemo } from 'react';
import * as Yup from 'yup';


export const useFormEditarSistemaValidation = () => {


  const FormEditarSistemaValidationYup = useMemo(() => {
    return Yup.object().shape({
      nome: Yup.string().required('Nome é obrigatório'),
      descricao: Yup.string().notRequired(),
      percDescontoAntecipacao: Yup.string()
        .test("percDescontoAntecipacao", "O Percentual de desconto não pode ser negativo.", val => {
          if (val && val.includes('-')) {
            return false
          }
          return true
        }),
    });
  }, [])

  return {
    FormEditarSistemaValidationYup,
  }
}
