import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { } from 'model/api/gestao/nota-fiscal/emitir-nota-fiscal-model';
import { NotaFiscalModel } from 'model/api/gestao/nota-fiscal/nota-fiscal-model';
import { EnumSituacaoNotaFiscal } from 'model/enums/enum-situacao-nota-fiscal';
import { toDateString } from 'utils/to-date';
import { toDecimalString } from 'utils/to-decimal';
import { useThemeQueries } from 'views/theme';
import { useStyles } from './nota-fiscal-tributos-styles'
import { toCurrency } from 'utils/toCurrency';

interface NotaFiscalTributosProps {
    model: NotaFiscalModel;
    upperBorder?: boolean;
}

export default function NotaFiscalTributos({
    model,
    upperBorder
}: NotaFiscalTributosProps) {
    const classes = useStyles()
    const { theme } = useThemeQueries();

    return (

        <Box className={classes.cardContainer} mt={1}>
            <Box className={classes.cardContent}>
                <Grid container spacing={2}>
                    {upperBorder && <Grid item xs={12}>
                        <Divider style={{background: theme.palette.divider}}/>
                    </Grid>}
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            Código de Seviço
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {model.codigoServico}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            Número da Nota Fiscal
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {model.nroNF || 'Indisponível'}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            Valor do Serviço
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {model.valorServico.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </Typography>
                    </Grid>

                    <Grid item xs={6} md={3}>
                        {model.situacao === EnumSituacaoNotaFiscal.Autorizada &&
                            (<>
                                <Typography variant='caption'>
                                    Data de Transmissão
                                </Typography>
                                <Typography variant='body1' style={{ fontWeight: 600 }}>
                                    {toDateString(model.dataAutorizacao)}
                                </Typography>
                            </>)
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Divider style={{ background: theme.palette.divider }} />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            Aliquita Dedução
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {toDecimalString(model.aliquotaDeducao, 3)}%
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            Aliquota ISS
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {toDecimalString(model.aliquotaISS, 3)}%
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            Aliquota PIS
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {toDecimalString(model.aliquotaPIS, 3)}%
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            Aliquota COFINS
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {toDecimalString(model.aliquotaCOFINS, 3)}%
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            Aliquota INSS
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {toDecimalString(model.aliquotaINSS, 3)}%
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            Aliquota IR
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {toDecimalString(model.aliquotaIR, 3)}%
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            Aliquota CSLL
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {toDecimalString(model.aliquotaCSLL, 3)}%
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            % Tributário (pTotTrib)
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {toDecimalString(model.pTotTrib, 3)}%
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider style={{ background: theme.palette.divider }} />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            Valor Dedução
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {toCurrency(model.valorDeducao)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            Valor ISS
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {toCurrency(model.valorISS)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            Valor PIS
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {toCurrency(model.valorPIS)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            Valor COFINS
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {toCurrency(model.valorCOFINS)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            Valor INSS
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {toCurrency(model.valorINSS)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            Valor IR
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {toCurrency(model.valorIR)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            Valor CSLL
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {toCurrency(model.valorCSLL)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            Valor Tributário
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {toCurrency(model.valorTrib)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider style={{ background: theme.palette.divider }} />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            ISS Retido
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {model.issRetido ? 'Retido' : 'Não Retido'}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            Tipo de Tributação
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {model.tipoTributacao}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            Série RPS
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {model.serieRPS}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant='caption'>
                            Número RPS
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {model.nroRPS}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant='caption'>
                            Texto de Serviço
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {model.textoServico}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant='caption'>
                            Código de Serviço
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {model.codigoServico}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant='caption'>
                            Descrição
                        </Typography>
                        <Typography variant='body1' style={{ fontWeight: 600 }}>
                            {model.descricao}
                        </Typography>
                    </Grid>
                </Grid>
                {/* <Box width='100%' marginTop='20px'>
                    <Button fullWidth onClick={closeDialog} variant='text' color='primary'>
                        Voltar
                    </Button>
                </Box> */}
            </Box>
        </Box>
    );
}