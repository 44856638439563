import { useState, useCallback, useEffect } from 'react';

import { Box, Grid } from '@material-ui/core';
import { useStyles } from './terminal-list-styles';
import { TerminalListData } from './terminal-list-data';
import { Paginacao } from 'views/components/paginacao';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useToastSaurus } from 'services/app';
import { useGetTerminal } from 'data/api/gestao/terminal/get-terminais';
import { TerminalListSearchProps } from './terminal-list-search-props';
import { TerminalModel } from 'model/api/gestao/terminal/terminal-model';
import { DialogTerminal } from 'views/components/dialog/dialog-terminal/dialog-terminal';
import { isEmpty } from 'lodash';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { useGetTerminalExcel } from 'data/api/gestao/terminal/get-terminais-excel';

export interface TerminalListProps {
  searchProps: TerminalListSearchProps;
}

export const TerminalList = (props: TerminalListProps) => {
  const classes = useStyles();
  const listClasses = useDefaultCadastroStyles();
  const { getTerminal, carregando: carregandoGet } = useGetTerminal();
  const { getTerminalExcel, carregando: carregandoExcel } = useGetTerminalExcel();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedModel, setSelectedModel] = useState<TerminalModel>(
    new TerminalModel(),
  );

  const carregando = carregandoExcel || carregandoGet

  const { showToast } = useToastSaurus();

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<TerminalModel>(),
  });

  const [selectedList] = useState<Array<string>>([]);

  const fillResult = useCallback(
    (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<TerminalModel>,
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    [],
  );

  const search = useCallback(
    async (newPage: number) => {
      try {
        const situacaoTerminalQuery =
          !props.searchProps.situacaoTerminal.includes(-1)
            ? props.searchProps.situacaoTerminal.reduce((prev, curr) => `&SituacaoTerminal=${curr}${prev}`, '')
            : '';
        const chaveQuery = props.searchProps.chave
          ? `&Chave=${props.searchProps.chave}`
          : '';

        const tipoQuery = !isEmpty(props.searchProps.tipo) ?
          `&TipoTerminal=${props.searchProps.tipo}` : ''

        const queryQuery = props.searchProps.query
          ? `&query=${props.searchProps.query}`
          : '';

        const sistemaQuery = !isEmpty(props.searchProps.sistemaOperacional) ?
          `&SistemaOperacional=${props.searchProps.sistemaOperacional}`
          : '';

        const versaoQuery = !isEmpty(props.searchProps.versao) ?
          `&Versao=${props.searchProps.versao}`
          : '';

        const res = await getTerminal(
          newPage,
          `ContratoAtivo=${situacaoTerminalQuery}${queryQuery}${chaveQuery}${tipoQuery}${sistemaQuery}${versaoQuery}`,
        );
        if (res.erro) throw res.erro;

        if (!res.resultado) {
          return;
        }

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          await search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list,
        );

        return res;
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getTerminal, fillResult, showToast, props.searchProps, refresh],
  );

  const exportExcel = useCallback(async (newPage: number) => {
    const situacaoTerminalQuery =
      !props.searchProps.situacaoTerminal.includes(-1)
        ? props.searchProps.situacaoTerminal.reduce((prev, curr) => `&SituacaoTerminal=${curr}${prev}`, '')
        : '';
    const chaveQuery = props.searchProps.chave
      ? `&Chave=${props.searchProps.chave}`
      : '';

    const tipoQuery = !isEmpty(props.searchProps.tipo) ?
      `&TipoTerminal=${props.searchProps.tipo}` : ''

    const queryQuery = props.searchProps.query
      ? `&query=${props.searchProps.query}`
      : '';

    const sistemaQuery = !isEmpty(props.searchProps.sistemaOperacional) ?
      `&SistemaOperacional=${props.searchProps.sistemaOperacional}`
      : '';

    const versaoQuery = !isEmpty(props.searchProps.versao) ?
      `&Versao=${props.searchProps.versao}`
      : '';

    try {
      const res = await getTerminalExcel(newPage, 
        `ContratoAtivo=${situacaoTerminalQuery}${queryQuery}${chaveQuery}${tipoQuery}${sistemaQuery}${versaoQuery}`)
      if (res.erro) throw (res.erro)
      if (!res.resultado) return;

      const blob = new Blob([res.resultado?.data], { type: 'application/vnd.ms-excel' },);

      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a')

      anchor.href = url
      anchor.download = `Terminais-${new Date().toLocaleDateString().replaceAll('/', '-')}.xls`
      anchor.click()

    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [props.searchProps.situacaoTerminal, props.searchProps.chave, props.searchProps.tipo, props.searchProps.query, props.searchProps.sistemaOperacional, props.searchProps.versao, getTerminalExcel, showToast])

  const onClick = useCallback((model: TerminalModel) => {
    setOpenDialog(true);
    setSelectedModel(model);
  }, []);

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        await search(newPage);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search, queryStatus.totalPages, refresh],
  );

  useEffect(() => {
    search(queryStatus.page);
  }, [queryStatus.page, search, props.searchProps]);

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Box display='flex' flexDirection='column' height='100%' justifyContent='space-between' flex={1} overflow='auto'>
          <Grid container className={listClasses.listContainer}>
            <Grid item xs={12}>
              <Paginacao
                pageChanged={pageChanged}
                totalPages={queryStatus.totalPages}
                totalRegisters={queryStatus.totalResults}
                currentPage={queryStatus.page}
                exportarExcel={() => exportExcel(1)}
              />
            </Grid>
            <Grid item xs={12} className={classes.listContainer}>
              <TerminalListData
                carregando={carregando}
                list={queryStatus.list}
                selectedList={selectedList}
                onClick={onClick}
              />
            </Grid>
          </Grid>
          {openDialog && <DialogTerminal
            model={selectedModel}
            openned={openDialog}
            setRefresh={setRefresh}
            closeDialog={() => setOpenDialog(false)}
            carregando={false}
          />}
        </Box>
      </div>
    </>
  );
};
