import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Box, Grid, Button, Typography, Divider } from '@material-ui/core';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { PlanoFormModel } from 'model/app/forms/plano/plano-form-model';
import { useGetSistemas, useGetSistemasById } from 'data/api/gestao/sistemas';
import { SistemasModel } from 'model/api/gestao/sistemas/sistemas-model';
import { KeyValueModel } from 'model';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { ModuloPlanoModel } from 'model/api/gestao/plano/modulo-plano-model';
import { SistemaModel } from 'model/api/gestao/sistemas/sistema-model';
import { useToastSaurus } from 'services/app';
import { isEmpty } from 'lodash';
import { picker } from 'utils/picker';
import { guidEmpty } from 'utils/guid-empty';
import { CardModuloPlano } from 'views/components/cards/card-modulos-planos';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormEditarPlanoValidation } from './form-editar-plano-validation';
import { toDecimal } from 'utils/to-decimal';
import { useStyles } from './form-plano-edicao-styles'

interface Props extends DefaultFormProps<PlanoFormModel> {
  aberto: boolean;
}

export const FormPlanoEdicao = forwardRef<
  DefaultFormRefs<PlanoFormModel>,
  Props
>(({ loading, onSubmit, aberto, ...props }: Props, ref) => {
  const { isMobile } = useThemeQueries();
  const utilClasses = makeUtilClasses();
  const refInputNome = useRef<HTMLInputElement>(null);
  const [sistemasMock, setSistemasMock] = useState<KeyValueModel[]>([]);
  const [sistemaId, setSistemaId] = useState<string>('');
  const [selectedId, setSelectedId] = useState<string>('')
  const [selected, setSelected] = useState<number>(1)
  const { getSistemas, carregando: carregandoGet } = useGetSistemas();
  const { getSistemasById, carregando: carregandoGetById } =
    useGetSistemasById();
  const [modelForm, setModelForm] = useState<PlanoFormModel>(
    new PlanoFormModel(),
  );
  const [modulos, setModulos] = useState<ModuloPlanoModel[]>([]);
  const { showToast } = useToastSaurus();
  const { FormEditarPlanoValidationYup } = useFormEditarPlanoValidation();

  const classes = useStyles()
  const { theme } = useThemeQueries()

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm<PlanoFormModel>({
    resolver: yupResolver(FormEditarPlanoValidationYup),
    criteriaMode: 'all',
    mode: 'onBlur' && 'onChange',
  });

  const submitAlterarDados = (values: PlanoFormModel) => {
    //values.modulos = Object.values(values.modulos).map(item => item)
    values.modulos = values.modulos.map((item) =>
      picker<ModuloPlanoModel>(item, new ModuloPlanoModel()),
    );
    values.valorDe = toDecimal(values.valorDe)
    values.valorPor = toDecimal(values.valorPor)
    onSubmit(values, modelForm);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(submitAlterarDados)();
    },
    resetForm: () => {
      reset();
      if (!isMobile) refInputNome.current?.focus();
    },
    fillForm: (model: PlanoFormModel) => {
      setModulos(model.modulos);
      setModelForm(model);
      setSelected(model.status)
      reset({ ...model });
      setSelectedId(model.sistemaId)
      if (!isMobile) refInputNome.current?.focus();
    },
  }));

  const getSistemasWrapper = useCallback(async () => {
    const res = await getSistemas(1, '&pazeSize=0&situacao=0');

    if (res.erro) throw res.erro;

    return res.resultado?.data.list as SistemasModel[];
  }, [getSistemas]);

  const getSistemaByIdWrapper = useCallback(async () => {
    const res = await getSistemasById(sistemaId);

    if (res.erro) throw res.erro;

    const ret = res.resultado?.data as SistemaModel;

    if (isEmpty(ret)) return;

    const modulosPlano = ret.modulos.map((item) => {
      const moduloAjustado: ModuloPlanoModel = {
        codModulo: item.codigo,
        id: guidEmpty(),
        moduloId: item.id,
        nomeModulo: item.nome,
        quantidadeContratada: item.quantidadePadrao,
        quantidadeMaxima: item.quantidadeMaxima,
        quantidadeMinima: item.quantidadePadrao,
        quantidadePadrao: item.quantidadeMinima,
      };
      return moduloAjustado;
    });
    setValue('modulos', modulosPlano)
    setModulos(modulosPlano);
  }, [getSistemasById, setValue, sistemaId]);

  const alterarQtdModulo = useCallback(
    (model: ModuloPlanoModel) => {
      const modulo = [...modulos];
      const ret = modulo.find((item) => item.moduloId === model.moduloId);
      const index = modulo.indexOf(ret || new ModuloPlanoModel());
      modulo[index] = model;
      setValue('modulos', modulo);
      setModulos(modulo);
    },
    [modulos, setValue],
  );

  const alterarQtd = useCallback(
    (model: ModuloPlanoModel, qtde: number) => {
      const newState = Object.assign({}, model);

      newState.quantidadeContratada = qtde;
      alterarQtdModulo(newState);
    },
    [alterarQtdModulo],
  );

  useEffect(() => {
    if (aberto) {
      getSistemasWrapper()
        .then((res) => {
          const items = res.map((item) => {
            return new KeyValueModel(item.id, item.nome);
          });
          setSistemasMock([...items]);
        })
        .catch((err) => showToast('error', err.message));
    }
  }, [aberto, getSistemasWrapper, showToast]);

  useEffect(() => {
    if (!isEmpty(sistemaId)) {
      getSistemaByIdWrapper().catch((err) => showToast('error', err.message));
    }
  }, [getSistemaByIdWrapper, showToast, sistemaId]);

  return (
    <>
      <Box my={2}>
        <div className={utilClasses.formContainer}>
          {loading && props.showLoading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="FULLSIZED" />
            </div>
          ) : null}

          <form
            onSubmit={handleSubmit(submitAlterarDados)}
            className={loading ? utilClasses.controlLoading : ''}
          >
            <Grid container spacing={2} justifyContent="center">
              <Grid item md={6} xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Controller
                      name="nome"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          inputRef={refInputNome}
                          disabled={loading}
                          allowSubmit={false}
                          id="nome"
                          label="Nome"
                          fullWidth
                          autoComplete={'off'}
                          helperText={
                            errors.nome
                              ? errors.nome.message
                              : undefined
                          }
                          error={Boolean(errors.nome && errors.nome.message)}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="descricao"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          disabled={loading}
                          allowSubmit={false}
                          label="Descrição"
                          fullWidth
                          autoComplete={'off'}
                          multiline
                          maxRows={3}
                          helperText={
                            errors.descricao
                              ? errors.descricao.message
                              : undefined
                          }
                          error={Boolean(
                            errors.descricao && errors.descricao.message,
                          )}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Controller
                      name="valorDe"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          disabled={loading}
                          allowSubmit={false}
                          tipo="DECIMAL"
                          manterMascara
                          label="Valor de"
                          showStartAdornment
                          positivo
                          fullWidth
                          autoComplete={'off'}
                          helperText={
                            errors.valorDe
                              ? errors.valorDe.message
                              : undefined
                          }
                          error={Boolean(
                            errors.valorDe && errors.valorDe.message,
                          )}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Controller
                      name="valorPor"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          disabled={loading}
                          allowSubmit={false}
                          label="Valor Por"
                          tipo="DECIMAL"
                          showStartAdornment
                          positivo
                          manterMascara
                          fullWidth
                          autoComplete={'off'}
                          helperText={
                            errors.valorPor
                              ? errors.valorPor.message
                              : undefined
                          }
                          error={Boolean(
                            errors.valorPor && errors.valorPor.message,
                          )}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="diasValidade"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          disabled={loading}
                          allowSubmit={false}
                          label="Dias de Expiração"
                          tipo='NUMERO'
                          fullWidth
                          autoComplete={'off'}
                          helperText={
                            errors.diasValidade
                              ? errors.diasValidade.message
                              : undefined
                          }
                          error={Boolean(
                            errors.diasValidade && errors.diasValidade.message,
                          )}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider light />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <SelectSaurus
                          disabled={loading}
                          allowSubmit={false}
                          conteudo={[
                            new KeyValueModel(1, 'Ativo'),
                            new KeyValueModel(0, 'Desativado')
                          ]}
                          label="Status"
                          fullWidth
                          autoComplete={'off'}
                          helperText={
                            errors.status
                              ? errors.status.message
                              : undefined
                          }
                          error={Boolean(
                            errors.status && errors.status.message,
                          )}
                          {...field}
                          onChange={(e: any) => {
                            setValue('status', e.target.value)
                            setSelected(e.target.value)
                          }}
                          value={selected}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="sistemaId"
                      control={control}
                      render={({ field }) => (
                        <SelectSaurus
                          conteudo={sistemasMock}
                          disabled={loading || carregandoGet}
                          allowSubmit={false}
                          label="Sistema"
                          fullWidth
                          autoComplete={'off'}
                          helperText={
                            errors.sistemaId
                              ? errors.sistemaId.message
                              : undefined
                          }
                          error={Boolean(
                            errors.sistemaId && errors.sistemaId.message,
                          )}
                          {...field}
                          value={selectedId}
                          onChange={(ev) => {
                            const item = sistemasMock.filter(
                              (item) => ev.target.value === item.Key,
                            );
                            setValue('sistemaId', item[0].Key);
                            setSistemaId(item[0].Key);
                            setSelectedId(item[0].Key)
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12} className={classes.moduloContainer}>
                    <Typography variant="h6">Módulos</Typography>
                    <Divider style={{ background: theme.palette.primary.main, height: 2 }} />
                    {carregandoGetById && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <CircularLoading tipo="ONAUTOCOMPLETE" />
                          <Typography>Carregando Módulos</Typography>
                        </div>
                      </div>
                    )}
                    <Grid container spacing={1} style={{ marginTop: '.5rem' }}>
                      {modulos.length > 0 &&
                        modulos.map((item) => {
                          return (
                            <Grid item xs={12}>
                              <CardModuloPlano
                                model={item}
                                alterarQtd={alterarQtd}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </Box>
    </>
  );
});
