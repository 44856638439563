import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { NotaFiscalModel } from 'model/api/gestao/nota-fiscal/nota-fiscal-model';

export function usePostNotaFiscal() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<NotaFiscalModel>();

    const postNotaFiscal = useCallback(
        (id: string) =>

            invocarApi({
                url: `/${VariaveisAmbiente.apiVersion}/fatura/${id}/nota-fiscal`,
                method: 'POST',
                enviarTokenUsuario: true,
                timeout: 100000
            }),

        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postNotaFiscal,
    };
}