import { Button, Grid } from '@material-ui/core';
import { useImperativeHandle, forwardRef } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses } from 'views';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { ClassificacaoModel } from 'model/api/gestao/classificacao/classificacao-model';
import { picker } from 'utils/picker';

export const FormClassificacao = forwardRef<
  DefaultFormRefs<ClassificacaoModel>,
  DefaultFormProps<ClassificacaoModel>
>(({ loading, ...props }: DefaultFormProps<ClassificacaoModel>, ref) => {
  const utilClasses = makeUtilClasses();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
  } = useForm<ClassificacaoModel>({
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = async (values: ClassificacaoModel) => {
    const model = picker<ClassificacaoModel>(values, new ClassificacaoModel());
    props.onSubmit(model);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
    },
    fillForm: (model: ClassificacaoModel) => {
      reset({ ...model });
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {loading && props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={loading ? utilClasses.controlLoading : ''}
        >
          <Grid container style={{ maxWidth: '100%' }} spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="descricao"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    fullWidth
                    allowSubmit={true}
                    disabled={loading}
                    label="Descrição"
                    variant="outlined"
                    error={Boolean(errors.descricao && errors.descricao.message)}
                    helperText={
                      touchedFields.descricao || errors.descricao
                        ? errors.descricao?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="categoria"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    fullWidth
                    allowSubmit={true}
                    disabled={loading}
                    label="Categoria"
                    variant="outlined"
                    error={Boolean(errors.categoria && errors.categoria.message)}
                    helperText={
                      touchedFields.categoria || errors.categoria
                        ? errors.categoria?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="abreviacao"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    fullWidth
                    allowSubmit={true}
                    disabled={loading}
                    label="Abreviação"
                    variant="outlined"
                    error={Boolean(errors.abreviacao && errors.abreviacao.message)}
                    helperText={
                      touchedFields.abreviacao || errors.abreviacao
                        ? errors.abreviacao?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});