import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { ClienteIdealPerguntaModel } from 'model/api/gestao/cliente-ideal/cliente-ideal-pergunta-model';

export function usePutClienteIdeal() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ClienteIdealPerguntaModel[]>();

    const putClienteIdeal = useCallback(
        (models: ClienteIdealPerguntaModel[]) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/cliente-ideal`,
                method: 'PUT',
                enviarTokenUsuario: true,
                data: {
                    perguntas: models
                }
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putClienteIdeal,
    };
}
