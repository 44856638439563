import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { NovoSistemaModal } from 'views/components/modals/sistema/novo-sistema-modal';
import { CadastrosContextValue } from './cadastro-context-value';
import { NovoModuloModal } from 'views/components/modals/modulo/modulo-modal';
import { PagamentoModal } from 'views/components/modals/pagamento/pagamento-modal';
import { ModuloModel } from 'model/api/gestao/sistemas/modulo-model';
import { ClienteModal } from 'views/components/modals/cliente/cliente-modal';
import { PlanoModal } from 'views/components/modals/planos/plano-modal';
import { PrecificacaoModal } from 'views/components/modals/precificacao/precificacao-modal';
import { isEmpty } from 'lodash';
import { DialogContrato } from 'views/components/dialog/dialog-contrato/dialog-contrato';
import { ParametroModal } from 'views/components/modals/parametro/parametro-modal';
import { AmbienteModal } from 'views/components/modals/ambiente/ambiente-modal';
import { DialogPlano } from 'views/components/dialog/dialog-plano/dialog-plano';
import { GerenteModal } from 'views/components/modals/gerentes/gerentes-modal';
import { FuncionarioModal } from 'views/components/modals/funcionarios/funcionarios-modal';
import { DialogPagarComissao } from 'views/components/dialog/dialog-pagar-comissao/dialog-pagar-comissao';
import { IndicacaoModal } from 'views/components/modals/indicacoes/indicacao-modal';
import { ComissaoPendenteModel } from 'model/api/gestao/comissao/comissao-pendente-model';
import { DialogComissao } from 'views/components/dialog/dialog-comissao/dialog-comissao';
import { RepresentantesModal } from 'views/components/modals/representantes/representantes-modal';
import { ModalWhitelabel } from 'views/components/modals/modal-whitelabel/modal-whitelabel';
import { ClienteIdealPerguntaModel } from 'model/api/gestao/cliente-ideal/cliente-ideal-pergunta-model';
import { PerguntasApuracaoModal } from 'views/components/modals/perguntas-apuracao/perguntas-apuracao-modal';

const CadastrosContext = React.createContext<CadastrosContextValue>({

  abrirCadastroNovoSistema: (
    id: string,
    callbackUrl: string,
    trocarUrl: boolean,
  ) => {
    return new Promise<void>(() => true);
  },
  fecharCadastroNovoSistema: (urlRetorno?: string) => {
    return new Promise<void>(() => true);
  },
  cadastroNovoSistemaState: {
    id: '',
    aberto: false,
    ultimaUrl: '',
  },
  abrirCadastroPlano: (
    id: string,
    callbackUrl: string,
    trocarUrl: boolean,
  ) => {
    return new Promise<void>(() => true);
  },
  fecharCadastroPlano: (urlRetorno?: string) => {
    return new Promise<void>(() => true)
  },
  cadastroPlanoState: {
    id: '',
    aberto: false,
    ultimaUrl: '',
  },
  abrirCadastroNovoModulo: (
    modulo: ModuloModel,
    sistemaId: string,
    callbackUrl: string,
    trocarUrl: boolean,
  ) => {
    return new Promise<void>(() => true);
  },
  fecharCadastroNovoModulo: (urlRetorno?: string) => {
    return new Promise<void>(() => true);
  },
  cadastroNovoModuloState: {
    modulo: new ModuloModel(),
    sistemaId: '',
    aberto: false,
    ultimaUrl: '',
  },

  abrirCadastroPagamento: (
    id: string,
    callbackUrl: string,
    trocarUrl: boolean,
  ) => {
    return new Promise<void>(() => true);
  },
  fecharCadastroPagamento: (urlRetorno?: string) => {
    return new Promise<void>(() => true);
  },
  cadastroPagamentoState: {
    id: '',
    aberto: false,
    ultimaUrl: '',
  },

  abrirCadastroPessoa: (
    id: string,
    callbackUrl: string,
    trocarUrl: boolean,
    revenda: boolean = false,
  ) => {
    return new Promise<void>(() => true);
  },
  fecharCadastroPessoa: (urlRetorno?: string) => {
    return new Promise<void>(() => true);
  },
  cadastroPessoaState: {
    id: '',
    aberto: false,
    ultimaUrl: '',
  },

  abrirCadastroPrecificacao: (
    id: string,
    callbackUrl: string,
    trocarUrl: boolean,
  ) => {
    return new Promise<void>(() => true);
  },
  fecharCadastroPrecificacao: (urlRetorno?: string) => {
    return new Promise<void>(() => true);
  },
  cadastroPrecificacaoState: {
    id: '',
    aberto: false,
    ultimaUrl: '',
  },

  abrirCadastroContrato: (
    id: string,
    pessoaId: string,
    callbackUrl: string,
    trocarUrl: boolean,
    temVendedor: boolean,
    revendaId?: string,
  ) => {
    return new Promise<void>(() => true);
  },
  fecharCadastroContrato: (urlRetorno?: string) => {
    return new Promise<void>(() => true);
  },
  cadastroContratoState: {
    id: '',
    aberto: false,
    ultimaUrl: '',
    pessoaId: '',
  },
  abrirCadastroParametro: (
    id: string,
    callbackUrl: string,
    trocarUrl: boolean
  ) => { },
  fecharCadastroParametro: (
    urlRetorno?: string
  ) => { },
  cadastroParametroState: {
    aberto: false,
    id: '',
    ultimaUrl: '',
  },
  abrirCadastroAmbiente: (
    id: string,
    callbackUrl: string,
    trocarUrl: boolean
  ) => { },
  fecharCadastroAmbiente: (
    urlRetorno?: string
  ) => { },
  cadastroAmbienteState: {
    aberto: false,
    id: '',
    ultimaUrl: '',
  },

  abrirEdicaoPlano: (
    id: string,
    callbackUrl: string,
    trocarUrl: boolean,
  ) => { },
  fecharEdicaoPlano: (
    urlRetorno?: string
  ) => { },
  edicaoPlanoState: {
    id: '',
    aberto: false,
    ultimaUrl: ''
  },
  abrirCadastroGerentes: (
    callbackUrl: string,
    trocarUrl: boolean
  ) => { },
  fecharCadastroGerentes: (
    urlRetorno?: string
  ) => { },
  cadastroGerenteState: {
    aberto: false,
    ultimaUrl: '',
  },
  abrirPagamentoComissao: (
    list: ComissaoPendenteModel[],
    callbackUrl: string,
    trocarUrl: boolean,
  ) => { },
  fecharPagamentoComissao: (
    urlRetorno?: string
  ) => { },
  pagamentoComissaoState: {
    list: [],
    aberto: false,
    ultimaUrl: ''
  },

  abrirCadastroFuncionarios: (
    callbackUrl: string,
    trocarUrl: boolean
  ) => { },
  fecharCadastroFuncionarios: (
    urlRetorno?: string
  ) => { },
  cadastroFuncionarioState: {
    aberto: false,
    ultimaUrl: '',
  },


  abrirEditarComissao: (
    id: string,
    callbackUrl: string,
    trocarUrl: boolean,
  ) => { },
  fecharEditarComissao: (
    urlRetorno?: string
  ) => { },
  editarComissaoState: {
    id: '',
    aberto: false,
    ultimaUrl: ''
  },

  abrirEditarIndicacao: (
    id: string,
    callbackUrl: string,
    trocarUrl: boolean,
  ) => {
    return new Promise<void>(() => true);
  },
  fecharEditarIndicacao: (urlRetorno?: string) => {
    return new Promise<void>(() => true);
  },
  edicaoIndicacaoState: {
    id: '',
    aberto: false,
    ultimaUrl: '',
  },
  abrirCadastroRepresentantes: (
    callbackUrl: string,
    trocarUrl: boolean
  ) => { },
  fecharCadastroRepresentantes: (
    urlRetorno?: string
  ) => { },
  cadastroRepresentantesState: {
    aberto: false,
    ultimaUrl: '',
  },
  abrirCadastroWhitelabel: (
    id,
    pessoaId,
    sistemaId,
  ) => { },
  fecharCadastroWhitelabel: (
  ) => { },
  cadastroWhitelabelState: {
    id: '',
    pessoaId: '',
    sistemaId: '',
    aberto: false,
    ultimaUrl: '',
  },
  abrirCadastroPergunta: (
    model: ClienteIdealPerguntaModel,
    atualizar: (model: ClienteIdealPerguntaModel) => Promise<boolean>,
  ) => { },
  fecharCadastroPergunta: () => { },
  cadastroPerguntaState: {
    aberto: false,
    finalizar: async (model: ClienteIdealPerguntaModel) => true,
    model: new ClienteIdealPerguntaModel()
  }
});



export interface CadastrosProviderProps {
  children: React.ReactNode;
}

export const useCadastros = () => {
  return React.useContext(CadastrosContext);
};

export const CadastrosProvider = ({ children }: CadastrosProviderProps) => {

  const novoCadastroRoute = 'adicionar';
  const planosRoute = '/planos/';
  const indicacaoRoute = '/indicacoes/disponiveis'
  const sistemaRoute = `/sistemas/`;
  // const modulosRoute = `/sistemas/`;
  const pagamentoRoute = `/formas-pagamento/`;
  const clienteRoute = `/clientes/`;
  const revendaRoute = `/clientes/`;
  const precificacaoRoute = `/tabela-precos/`
  const consultaFaturaRoute = `/cobranca/`
  const gerentesRoute = `/gerentes/`
  const funcionariosRota = `/funcionarios/`
  const pagamentoComissaoRoute = '/comissoes/disponiveis/'
  const comissaoRoute = `/comissoes/realizadas/`

  const { push, location } = useHistory();


  const [cadastroNovoSistemaState, setCadastroNovoSistemaState] = useState({
    id: '',
    aberto: false,
    ultimaUrl: '',
  });

  const [cadastroNovoModuloState, setCadastroNovoModuloState] = useState({
    modulo: new ModuloModel(),
    sistemaId: '',
    aberto: false,
    ultimaUrl: '',
  });

  const [cadastroPagamentoState, setCadastroPagamentoState] = useState({
    id: '',
    aberto: false,
    ultimaUrl: '',
  });

  const [cadastroPessoaState, setCadastroPessoaState] = useState({
    id: '',
    aberto: false,
    ultimaUrl: '',
    revenda: false,
  });

  const [cadastroPrecificacaoState, setCadastroPrecificacaoState] = useState({
    id: '',
    aberto: false,
    ultimaUrl: '',
  });

  const [cadastroPlanoState, setCadastroPlanoState] = useState({
    id: '',
    aberto: false,
    ultimaUrl: '',
  });

  const [cadastroContratoState, setCadastroContratoState] = useState({
    id: '',
    aberto: false,
    ultimaUrl: '',
    pessoaId: '',
    revendaId: '',
    temVendedor: false,
  });

  const [cadastroParametroState, setCadastroParametroState] = useState({
    id: '',
    aberto: false,
    ultimaUrl: '',
  })

  const [cadastroAmbienteState, setCadastroAmbienteState] = useState({
    id: '',
    aberto: false,
    ultimaUrl: ''
  })

  const [edicaoPlanoState, setEdicaoPlanoState] = useState({
    id: '',
    aberto: false,
    ultimaUrl: ''
  })

  const [cadastroGerenteState, setCadastroGerenteState] = useState({
    aberto: false,
    ultimaUrl: ''
  })
  const [cadastroFuncionarioState, setCadastroFuncionarioState] = useState({
    aberto: false,
    ultimaUrl: ''
  })

  const [edicaoIndicacaoState, setEdicaoIndicacaoState] = useState({
    id: '',
    aberto: false,
    ultimaUrl: ''
  })

  const [pagamentoComissaoState, setPagamentoComissaoState] = useState({
    list: [new ComissaoPendenteModel()],
    aberto: false,
    ultimaUrl: ''
  })

  const [editarComissaoState, setEditarComissaoState] = useState({
    id: '',
    aberto: false,
    ultimaUrl: ''
  })

  const [cadastroRepresentantesState, setCadastroRepresentantesState] = useState({
    aberto: false,
    ultimaUrl: ''
  })

  const [cadastroWhitelabelState, setCadastroWhitelabelState] = useState({
    id: '',
    pessoaId: '',
    sistemaId: '',
    aberto: false,
    ultimaUrl: ''
  })

  const [cadastroPerguntaState, setCadastroPerguntaState] = useState({
    model: new ClienteIdealPerguntaModel(),
    aberto: false,
    finalizar: async (model: ClienteIdealPerguntaModel) => true,
  })

  useEffect(() => {
    if (location.pathname.indexOf("/sistemas/detalhes") < 0 && location.pathname.indexOf(sistemaRoute) === 0) {
      const posrota = location.pathname.replace(sistemaRoute, '');
      if (posrota.length > 0) {
        setCadastroNovoSistemaState({
          id: posrota.replace(novoCadastroRoute, ''),
          aberto: true,
          ultimaUrl: sistemaRoute,
        });
        return;
      }
    } else if (location.pathname.indexOf(planosRoute) === 0 && location.pathname.split('/')[2] === 'adicionar') {
      const posrota = location.pathname.replace(planosRoute, '');
      if (posrota.length > 0) {
        setCadastroPlanoState({
          id: posrota.replace(novoCadastroRoute, ''),
          aberto: true,
          ultimaUrl: planosRoute,
        });
        return;
      }
    } else if (location.pathname.indexOf(clienteRoute) === 0 && location.pathname.split('/')[3]) {
      const posrota = location.pathname.replace(clienteRoute, '');
      if (posrota.length > 0) {
        setCadastroContratoState({
          id: location.pathname.split('/')[3] === 'adicionar' ? '' : location.pathname.split('/')[3],
          aberto: true,
          ultimaUrl: clienteRoute + location.pathname.split('/')[2],
          pessoaId: location.pathname.split('/')[2],
          revendaId: '',
          temVendedor: false
        })
      }
    } else if (location.pathname.indexOf(pagamentoRoute) === 0) {
      const posrota = location.pathname.replace(pagamentoRoute, '');
      if (posrota.length > 0) {
        setCadastroPagamentoState({
          id: posrota.replace(novoCadastroRoute, ''),
          aberto: true,
          ultimaUrl: pagamentoRoute,
        });
        return;
      }
    } else if (location.pathname.indexOf(precificacaoRoute) === 0) {
      const posrota = location.pathname.replace(precificacaoRoute, '');
      if (posrota.length > 0 && posrota === 'adicionar') {
        setCadastroPrecificacaoState({
          id: posrota.replace(novoCadastroRoute, ''),
          aberto: true,
          ultimaUrl: precificacaoRoute,
        });
        return;
      }
    } else if (location.pathname.indexOf(planosRoute) === 0) {
      const posrota = location.pathname.replace(planosRoute, '');
      if (posrota.length > 0) {
        setEdicaoPlanoState({
          id: location.pathname.split('/')[2],
          aberto: true,
          ultimaUrl: planosRoute
        })
        return
      }
    } else if (location.pathname.indexOf(comissaoRoute) === 0) {
      const posrota = location.pathname.replace(comissaoRoute, '');
      if (posrota.length > 0) {
        setEditarComissaoState({
          id: location.pathname.split('/')[3],
          aberto: true,
          ultimaUrl: comissaoRoute
        })
        return
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  //----------------------------------------------------------------------------------------------------------------Sistemas
  const abrirCadastroNovoSistema = React.useCallback(
    async (
      id: string,
      callbackUrl: string,
      trocarUrl: boolean,
    ): Promise<void> => {
      let url = callbackUrl.length === 0 ? sistemaRoute : callbackUrl;
      if (cadastroNovoSistemaState.aberto) {
        url = cadastroNovoSistemaState.ultimaUrl;
      }
      setCadastroNovoSistemaState({
        id: id,
        aberto: true,
        ultimaUrl: url,
      });
      if (trocarUrl) {
        push(sistemaRoute + (id?.length === 0 ? novoCadastroRoute : id));
      }
    },
    //eslint-disable-next-line
    [location.pathname, sistemaRoute],
  );

  const fecharCadastroNovoSistema = React.useCallback(
    async (urlRetorno?: string): Promise<void> => {
      const uUrl = cadastroNovoSistemaState.ultimaUrl;
      setCadastroNovoSistemaState({
        id: '',
        aberto: false,
        ultimaUrl: '',
      }); if (!isEmpty(urlRetorno)) {
        push(urlRetorno || '');
        return;
      }
      if (!isEmpty(uUrl)) {
        push(uUrl);
        return;
      }
    },
    //eslint-disable-next-line
    [cadastroNovoSistemaState.ultimaUrl],
  );

  //----------------------------------------------------------------------------------------------------------------Planos

  const abrirCadastroPlano = React.useCallback(
    async (
      id: string,
      callbackUrl: string,
      trocarUrl: boolean,
    ): Promise<void> => {
      let url = callbackUrl.length === 0 ? planosRoute : callbackUrl;
      if (cadastroPlanoState.aberto) {
        url = cadastroPlanoState.ultimaUrl;
      }
      setCadastroPlanoState({
        id: id,
        aberto: true,
        ultimaUrl: url,
      });
      if (trocarUrl) {
        push(planosRoute + (id?.length === 0 ? novoCadastroRoute : id));
      }
    },
    //eslint-disable-next-line
    [location.pathname, sistemaRoute],
  );

  const fecharCadastroPlano = React.useCallback(
    async (urlRetorno?: string): Promise<void> => {
      const url = cadastroPlanoState.ultimaUrl;
      setCadastroPlanoState({
        id: '',
        aberto: false,
        ultimaUrl: '',
      }); if (!isEmpty(urlRetorno)) {
        push(urlRetorno || '');
        return;
      }
      if (!isEmpty(url)) {
        push(url);
        return;
      }
    },
    //eslint-disable-next-line
    [cadastroPlanoState.ultimaUrl, push],
  );

  // ---------------------------------------------------------- Cadastro Indicações
  const abrirEditarIndicacao = React.useCallback(
    async (
      id: string,
      callbackUrl: string,
      trocarUrl: boolean,
    ): Promise<void> => {
      let url = callbackUrl.length === 0 ? indicacaoRoute : callbackUrl;
      if (edicaoIndicacaoState.aberto) {
        url = edicaoIndicacaoState.ultimaUrl;
      }
      setEdicaoIndicacaoState({
        id: id,
        aberto: true,
        ultimaUrl: url,
      });
      if (trocarUrl) {
        push(indicacaoRoute + (id?.length === 0 ? novoCadastroRoute : id));
      }
    },
    //eslint-disable-next-line
    [edicaoIndicacaoState, sistemaRoute],
  )

  const fecharEditarIndicacao = React.useCallback(
    async (urlRetorno?: string): Promise<void> => {
      const url = edicaoIndicacaoState.ultimaUrl;
      setEdicaoIndicacaoState({
        id: '',
        aberto: false,
        ultimaUrl: '',
      }); if (!isEmpty(urlRetorno)) {
        push(urlRetorno || '');
        return;
      }
      if (!isEmpty(url)) {
        push(url);
        return;
      }
    },
    //eslint-disable-next-line
    [edicaoIndicacaoState.ultimaUrl, push],
  );


  //----------------------------------------------------------------------------------------------------------------Módulos
  const abrirCadastroNovoModulo = React.useCallback(
    async (
      modulo: ModuloModel,
      sistemaId: string,
      callbackUrl: string,
      trocarUrl: boolean,
    ): Promise<void> => {
      let url = callbackUrl.length === 0 ? sistemaRoute : callbackUrl;
      if (cadastroNovoModuloState.aberto) {
        url = cadastroNovoModuloState.ultimaUrl;
      }
      setCadastroNovoModuloState({
        modulo: modulo,
        sistemaId: sistemaId,
        aberto: true,
        ultimaUrl: url,
      });
      // if (trocarUrl) {
      //   push(sistemaRoute + (id?.length === 0 ? novoCadastroRoute : id));
      // }
    },
    //eslint-disable-next-line
    [location.pathname, sistemaRoute],
  );

  const fecharCadastroNovoModulo = React.useCallback(
    async (urlRetorno?: string): Promise<void> => {
      // const uUrl = cadastroNovoModuloState.ultimaUrl;
      setCadastroNovoModuloState({
        modulo: new ModuloModel(),
        sistemaId: '',
        aberto: false,
        ultimaUrl: '',
      });
    },
    //eslint-disable-next-line
    [cadastroNovoModuloState.ultimaUrl, push],
  );

  //----------------------------------------------------------------------------------------------------------------Instituição
  const abrirCadastroPagamento = React.useCallback(
    async (
      id: string,
      callbackUrl: string,
      trocarUrl: boolean,
    ): Promise<void> => {
      let url = callbackUrl.length === 0 ? pagamentoRoute : callbackUrl;
      if (cadastroPagamentoState.aberto) {
        url = cadastroPagamentoState.ultimaUrl;
      }
      setCadastroPagamentoState({
        id: id,
        aberto: true,
        ultimaUrl: url,
      });
      if (trocarUrl) {
        push(pagamentoRoute + (id?.length === 0 ? novoCadastroRoute : id));
      }
    },
    //eslint-disable-next-line
    [location.pathname, sistemaRoute],
  );

  const fecharCadastroPagamento = React.useCallback(
    async (urlRetorno?: string): Promise<void> => {
      const uUrl = cadastroPagamentoState.ultimaUrl;
      setCadastroPagamentoState({
        id: '',
        aberto: false,
        ultimaUrl: '',
      }); if (!isEmpty(urlRetorno)) {
        push(urlRetorno || '');
        return;
      }
      if (!isEmpty(uUrl)) {
        push(uUrl);
        return;
      }
    },
    //eslint-disable-next-line
    [cadastroPagamentoState.ultimaUrl, push],
  );

  //----------------------------------------------------------------------------------------------------------------Cliente
  const abrirCadastroPessoa = React.useCallback(
    async (
      id: string,
      callbackUrl: string,
      trocarUrl: boolean,
      revenda: boolean,
    ): Promise<void> => {
      let url = callbackUrl.length === 0 ? !revenda ? clienteRoute : revendaRoute : callbackUrl;
      if (cadastroPessoaState.aberto) {
        url = cadastroPessoaState.ultimaUrl;
      }
      setCadastroPessoaState({
        id: id,
        aberto: true,
        ultimaUrl: url,
        revenda
      });
      if (trocarUrl) {
        push(!revenda ? clienteRoute : revendaRoute + (id?.length === 0 ? novoCadastroRoute : id));
      }
    },
    //eslint-disable-next-line
    [location.pathname, sistemaRoute],
  );

  const fecharCadastroPessoa = React.useCallback(
    async (urlRetorno?: string): Promise<void> => {
      const uUrl = cadastroPessoaState.ultimaUrl;
      setCadastroPessoaState({
        id: '',
        aberto: false,
        ultimaUrl: '',
        revenda: false,
      }); if (!isEmpty(urlRetorno)) {
        push(urlRetorno || '');
        return;
      }
      if (!isEmpty(uUrl)) {
        push(uUrl);
        return;
      }
    },
    //eslint-disable-next-line
    [cadastroPessoaState.ultimaUrl, push],
  );

  //----------------------------------------------------------------------------------------------------------------Cliente
  const abrirCadastroPrecificacao = React.useCallback(
    async (
      id: string,
      callbackUrl: string,
      trocarUrl: boolean,
    ): Promise<void> => {
      let url = callbackUrl.length === 0 ? precificacaoRoute : callbackUrl;
      if (cadastroPrecificacaoState.aberto) {
        url = cadastroPrecificacaoState.ultimaUrl;
      }
      setCadastroPrecificacaoState({
        id: id,
        aberto: true,
        ultimaUrl: url,
      });
      if (trocarUrl) {
        push(precificacaoRoute + (id?.length === 0 ? novoCadastroRoute : id));
      }
    },
    //eslint-disable-next-line
    [location.pathname, sistemaRoute],
  );

  const fecharCadastroPrecificacao = React.useCallback(
    async (urlRetorno?: string): Promise<void> => {
      const uUrl = cadastroPrecificacaoState.ultimaUrl;
      setCadastroPrecificacaoState({
        id: '',
        aberto: false,
        ultimaUrl: '',
      });
      if (!isEmpty(urlRetorno)) {
        push(urlRetorno || '');
        return;
      }
      if (!isEmpty(uUrl)) {
        push(uUrl);
        return;
      }
    },
    //eslint-disable-next-line
    [cadastroPrecificacaoState.ultimaUrl, push],
  );

  //----------------------------------------------------------------------------------------------------------------Contrato
  const abrirCadastroContrato = React.useCallback(
    async (
      id: string,
      pessoaId: string,
      callbackUrl: string,
      trocarUrl: boolean,
      temVendedor: boolean,
      revendaId?: string,
    ): Promise<void> => {
      let url = callbackUrl.length === 0 ? clienteRoute : callbackUrl;
      if (cadastroContratoState.aberto) {
        url = cadastroContratoState.ultimaUrl;
      }
      setCadastroContratoState({
        id: id,
        aberto: true,
        ultimaUrl: url,
        pessoaId: pessoaId,
        revendaId: revendaId || '',
        temVendedor
      });
      if (trocarUrl) {
        push(url + '/' + (id?.length === 0 ? novoCadastroRoute : id));
      }
    },
    [clienteRoute, cadastroContratoState.aberto, cadastroContratoState.ultimaUrl, push],
  );

  const fecharCadastroContrato = React.useCallback(
    async (urlRetorno?: string): Promise<void> => {
      const uUrl = cadastroContratoState.ultimaUrl;
      setCadastroContratoState({
        id: '',
        aberto: false,
        ultimaUrl: '',
        pessoaId: '',
        revendaId: '',
        temVendedor: false,
      });
      if (!isEmpty(urlRetorno)) {
        push(urlRetorno || '');
        return;
      }
      if (!isEmpty(uUrl)) {
        push(uUrl);
        return;
      }
    },
    [cadastroContratoState.ultimaUrl, push],
  );

  //---------------------------------------------------------------------------------------- Parametros Sistema

  const abrirCadastroParametro = React.useCallback(
    async (
      id: string,
      callbackUrl: string,
      trocarUrl: boolean,
    ): Promise<void> => {
      let url = callbackUrl;
      if (cadastroParametroState.aberto) {
        url = cadastroParametroState.ultimaUrl;
      }
      setCadastroParametroState({
        id: id,
        aberto: true,
        ultimaUrl: url,
      });
    },
    [cadastroParametroState],
  );

  const fecharCadastroParametro = React.useCallback(
    async (urlRetorno?: string): Promise<void> => {
      setCadastroParametroState({
        id: '',
        aberto: false,
        ultimaUrl: '',
      });
    },
    //eslint-disable-next-line
    [cadastroParametroState.ultimaUrl, push],
  );


  // ------------------------------------------------------------------------------------ Ambiente Sistemas

  const abrirCadastroAmbiente = React.useCallback(
    async (
      id: string,
      callbackUrl: string,
      trocarUrl: boolean,
    ): Promise<void> => {
      let url = callbackUrl;
      if (cadastroAmbienteState.aberto) {
        url = cadastroAmbienteState.ultimaUrl;
      }
      setCadastroAmbienteState({
        id: id,
        aberto: true,
        ultimaUrl: url,
      });
    },
    //eslint-disable-next-line
    [cadastroAmbienteState, location.pathname, consultaFaturaRoute],
  );

  const fecharCadastroAmbiente = React.useCallback(
    async (urlRetorno?: string): Promise<void> => {
      setCadastroAmbienteState({
        id: '',
        aberto: false,
        ultimaUrl: '',
      });
    },
    //eslint-disable-next-line
    [cadastroAmbienteState.ultimaUrl, push],
  );

  //--------------------------------------------------------------------------------------- Edição Planos

  const abrirEdicaoPlano = React.useCallback(
    (
      id: string,
      callbackUrl: string,
      trocarUrl: boolean,
    ): void => {
      let url = callbackUrl.length === 0 ? planosRoute : callbackUrl;
      if (edicaoPlanoState.aberto) {
        url = edicaoPlanoState.ultimaUrl;
      }
      setEdicaoPlanoState({
        id: id,
        aberto: true,
        ultimaUrl: url,
      });
      if (trocarUrl) {
        push(planosRoute + (id?.length === 0 ? novoCadastroRoute : id));
      }
    },
    //eslint-disable-next-line
    [edicaoPlanoState, location.pathname, planosRoute],
  );

  const fecharEdicaoPlano = React.useCallback(
    async (urlRetorno?: string): Promise<void> => {
      const uUrl = edicaoPlanoState.ultimaUrl;
      setEdicaoPlanoState({
        id: '',
        aberto: false,
        ultimaUrl: '',
      });
      if (!isEmpty(urlRetorno)) {
        push(urlRetorno || '');
        return;
      }
      if (!isEmpty(uUrl)) {
        push(uUrl);
        return;
      }
    },
    //eslint-disable-next-line
    [edicaoPlanoState.ultimaUrl, push],
  );

  //------------------------------------------------------------------------------------------------ Cadastro Gerente

  const abrirCadastroGerentes = React.useCallback(
    async (
      callbackUrl: string,
      trocarUrl: boolean,
    ): Promise<void> => {
      let url = callbackUrl.length === 0 ? gerentesRoute : callbackUrl;
      if (cadastroPessoaState.aberto) {
        url = cadastroPessoaState.ultimaUrl;
      }
      setCadastroGerenteState({
        aberto: true,
        ultimaUrl: url,
      });
    },
    //eslint-disable-next-line
    [location.pathname, sistemaRoute],
  );
  const fecharCadastroGerentes = React.useCallback(
    async (urlRetorno?: string): Promise<void> => {
      const uUrl = cadastroGerenteState.ultimaUrl;
      setCadastroGerenteState({
        aberto: false,
        ultimaUrl: '',
      }); if (!isEmpty(urlRetorno)) {
        push(urlRetorno || '');
        return;
      }
      if (!isEmpty(uUrl)) {
        push(uUrl);
        return;
      }
    },
    //eslint-disable-next-line
    [cadastroGerenteState.ultimaUrl, push],)
  //------------------------------------------------------------------------------------------------ Funcionarios

  const abrirCadastroFuncionarios = React.useCallback(
    async (
      callbackUrl: string,
      trocarUrl: boolean,
    ): Promise<void> => {

      let url = callbackUrl.length === 0 ? funcionariosRota : callbackUrl;
      if (cadastroPessoaState.aberto) {
        url = cadastroPessoaState.ultimaUrl;
      }
      setCadastroFuncionarioState({
        aberto: true,
        ultimaUrl: url,
      });
    },
    //eslint-disable-next-line
    [location.pathname, sistemaRoute],
  );


  const fecharCadastroFuncionarios = React.useCallback(
    async (urlRetorno?: string): Promise<void> => {
      const uUrl = cadastroPessoaState.ultimaUrl;
      setCadastroFuncionarioState({
        aberto: false,
        ultimaUrl: '',
      }); if (!isEmpty(urlRetorno)) {
        push(urlRetorno || '');
        return;
      }
      if (!isEmpty(uUrl)) {
        push(uUrl);
        return;
      }
    },
    //eslint-disable-next-line
    [cadastroPessoaState.ultimaUrl, push],
  );

  //--------------------------------------------------------------------------------- Pagamento Comissão

  const abrirPagamentoComissao = React.useCallback(
    (
      list: ComissaoPendenteModel[],
      callbackUrl: string,
      trocarUrl: boolean,
    ): void => {

      let url = callbackUrl.length === 0 ? pagamentoComissaoRoute : callbackUrl;

      setPagamentoComissaoState({
        list: list,
        aberto: true,
        ultimaUrl: url
      })
      if (trocarUrl) {
        push(planosRoute + 'novo');
      }
    },
    //eslint-disable-next-line
    [pagamentoComissaoState, location.pathname, planosRoute],
  );

  const fecharPagamentoComissao = React.useCallback(
    async (urlRetorno?: string): Promise<void> => {
      const uUrl = pagamentoComissaoState.ultimaUrl;
      setPagamentoComissaoState({
        list: pagamentoComissaoState.list,
        aberto: false,
        ultimaUrl: '',
      });
      if (!isEmpty(urlRetorno)) {
        push(urlRetorno || '');
        return;
      }
      if (!isEmpty(uUrl)) {
        push(uUrl);
        return;
      }
    },
    //eslint-disable-next-line
    [pagamentoComissaoState.ultimaUrl, push],
  );

  //-------------- Editar Comissão

  const abrirEditarComissao = React.useCallback(
    (
      id: string,
      callbackUrl: string,
      trocarUrl: boolean,
    ): void => {

      let url = callbackUrl.length === 0 ? comissaoRoute : callbackUrl;
      setEditarComissaoState({
        id: id,
        aberto: true,
        ultimaUrl: url
      })
      if (trocarUrl) {
        push(comissaoRoute + id);
      }
    },
    //eslint-disable-next-line
    [editarComissaoState, location.pathname, comissaoRoute],
  );

  const fecharEditarComissao = React.useCallback(
    async (urlRetorno?: string): Promise<void> => {
      const uUrl = editarComissaoState.ultimaUrl;
      setEditarComissaoState({
        id: '',
        aberto: false,
        ultimaUrl: '',
      });
      if (!isEmpty(urlRetorno)) {
        push(urlRetorno || '');
        return;
      }
      if (!isEmpty(uUrl)) {
        push(uUrl);
        return;
      }
    },
    //eslint-disable-next-line
    [editarComissaoState.ultimaUrl, push],
  );

  //------------------------------------------------------------------------------------------------ Cadastro Representante

  const abrirCadastroRepresentantes = React.useCallback(
    async (
      callbackUrl: string,
      trocarUrl: boolean,
    ): Promise<void> => {
      let url = callbackUrl.length === 0 ? gerentesRoute : callbackUrl;
      if (cadastroPessoaState.aberto) {
        url = cadastroPessoaState.ultimaUrl;
      }
      setCadastroRepresentantesState({
        aberto: true,
        ultimaUrl: url,
      });
    },
    //eslint-disable-next-line
    [location.pathname, sistemaRoute],
  );
  const fecharCadastroRepresentantes = React.useCallback(
    async (urlRetorno?: string): Promise<void> => {
      const uUrl = cadastroGerenteState.ultimaUrl;
      setCadastroRepresentantesState({
        aberto: false,
        ultimaUrl: '',
      }); if (!isEmpty(urlRetorno)) {
        push(urlRetorno || '');
        return;
      }
      if (!isEmpty(uUrl)) {
        push(uUrl);
        return;
      }
    },
    //eslint-disable-next-line
    [cadastroGerenteState.ultimaUrl, push],)

  //------------------------------------------------------------------------------------------------ Cadastro Whitelabel

  const abrirCadastroWhitelabel = React.useCallback(
    async (
      id: string,
      pessoaId: string,
      sistemaId: string,
    ): Promise<void> => {
      setCadastroWhitelabelState({
        aberto: true,
        pessoaId,
        sistemaId,
        id,
        ultimaUrl: '',
      });
    },
    //eslint-disable-next-line
    [location.pathname]
  );

  const fecharCadastroWhitelabel = React.useCallback(
    async (): Promise<void> => {
      setCadastroWhitelabelState({
        id: '',
        pessoaId: '',
        sistemaId: '',
        aberto: false,
        ultimaUrl: '',
      })
    },
    //eslint-disable-next-line
    [],)

  //---------------------------------------------------------------------------------- Cadastro Pergunta Apuração Comercial

  const abrirCadastroPergunta = React.useCallback(
    async (
      model: ClienteIdealPerguntaModel,
      finalizar: (model: ClienteIdealPerguntaModel) => Promise<boolean>,
    ): Promise<void> => {
      setCadastroPerguntaState({
        aberto: true,
        finalizar,
        model
      })
    }, []
  )

  const fecharCadastroPergunta = React.useCallback(
    async (): Promise<void> => {
      setCadastroPerguntaState({
        aberto: false,
        finalizar: async () => false,
        model: new ClienteIdealPerguntaModel()
      })
    },
    //eslint-disable-next-line
    [],)

  return (
    <CadastrosContext.Provider
      value={{
        abrirCadastroNovoSistema,
        fecharCadastroNovoSistema,
        cadastroNovoSistemaState,

        abrirCadastroPlano,
        fecharCadastroPlano,
        cadastroPlanoState,

        abrirCadastroNovoModulo,
        fecharCadastroNovoModulo,
        cadastroNovoModuloState,

        abrirCadastroPagamento,
        fecharCadastroPagamento,
        cadastroPagamentoState,

        abrirCadastroPessoa,
        fecharCadastroPessoa,
        cadastroPessoaState,

        abrirCadastroPrecificacao,
        fecharCadastroPrecificacao,
        cadastroPrecificacaoState,

        abrirCadastroContrato,
        fecharCadastroContrato,
        cadastroContratoState,

        abrirCadastroParametro,
        fecharCadastroParametro,
        cadastroParametroState,

        abrirCadastroAmbiente,
        fecharCadastroAmbiente,
        cadastroAmbienteState,

        abrirEdicaoPlano,
        fecharEdicaoPlano,
        edicaoPlanoState,

        abrirCadastroGerentes,
        fecharCadastroGerentes,
        cadastroGerenteState,

        abrirCadastroFuncionarios,
        fecharCadastroFuncionarios,
        cadastroFuncionarioState,

        abrirEditarIndicacao,
        fecharEditarIndicacao,
        edicaoIndicacaoState,

        abrirPagamentoComissao,
        fecharPagamentoComissao,
        pagamentoComissaoState,

        abrirEditarComissao,
        fecharEditarComissao,
        editarComissaoState,

        abrirCadastroRepresentantes,
        fecharCadastroRepresentantes,
        cadastroRepresentantesState,

        abrirCadastroWhitelabel,
        fecharCadastroWhitelabel,
        cadastroWhitelabelState,

        abrirCadastroPergunta,
        fecharCadastroPergunta,
        cadastroPerguntaState,
      }}
    >
      {children}
      <NovoSistemaModal
        openned={cadastroNovoSistemaState.aberto}
        id={cadastroNovoSistemaState.id}
      />
      <PlanoModal
        openned={cadastroPlanoState.aberto}
        id={cadastroPlanoState.id}
      />
      <IndicacaoModal
        openned={edicaoIndicacaoState.aberto}
        id={edicaoIndicacaoState.id}
      />
      <NovoModuloModal
        openned={cadastroNovoModuloState.aberto}
        modulo={cadastroNovoModuloState.modulo}
        sistemaId={cadastroNovoModuloState.sistemaId}
      />
      <PagamentoModal
        openned={cadastroPagamentoState.aberto}
        id={cadastroPagamentoState.id}
      />
      <ClienteModal
        openned={cadastroPessoaState.aberto}
        id={cadastroPessoaState.id}
        revenda={cadastroPessoaState.revenda}
      />
      <PrecificacaoModal
        openned={cadastroPrecificacaoState.aberto}
        id={cadastroPrecificacaoState.id}
      />
      {cadastroContratoState.aberto && <DialogContrato
        openned={cadastroContratoState.aberto}
        id={cadastroContratoState.id}
        pessoaId={cadastroContratoState.pessoaId}
        revendaId={cadastroContratoState.revendaId}
        temVendedor={cadastroContratoState.temVendedor}
      />}

      <ParametroModal
        id={cadastroParametroState.id}
        openned={cadastroParametroState.aberto}
      />

      <AmbienteModal
        id={cadastroAmbienteState.id}
        openned={cadastroAmbienteState.aberto}
      />

      <DialogPlano
        id={edicaoPlanoState.id}
        openned={edicaoPlanoState.aberto}
      />

      <GerenteModal
        openned={cadastroGerenteState.aberto}
      />

      <RepresentantesModal
        openned={cadastroRepresentantesState.aberto}
      />

      <FuncionarioModal
        openned={cadastroFuncionarioState.aberto}
      />

      <DialogPagarComissao
        list={pagamentoComissaoState.list}
        openned={pagamentoComissaoState.aberto}
      />

      <DialogComissao
        id={editarComissaoState.id}
        openned={editarComissaoState.aberto}
      />

      <ModalWhitelabel
        id={cadastroWhitelabelState.id}
        pessoaId={cadastroWhitelabelState.pessoaId}
        openned={cadastroWhitelabelState.aberto}
        sistemaId={cadastroWhitelabelState.sistemaId}
      />

      <PerguntasApuracaoModal
        aberto={cadastroPerguntaState.aberto}
        finalizar={cadastroPerguntaState.finalizar}
        model={cadastroPerguntaState.model}
      />

    </CadastrosContext.Provider >
  );
};
