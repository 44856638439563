import { Box, Button, Grid } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-pessoa-sistema-styles';
import { CircularLoading, useThemeQueries } from 'views';
import { useCallback, useEffect, useRef } from 'react';
import { useToastSaurus } from 'services/app';
import { DefaultFormRefs } from 'views/components/form/utils';
import { isEqual } from 'lodash';
import { guidEmpty } from 'utils/guid-empty';
import { PessoaSistemaCreateModel } from 'model/api/gestao/pessoa/pessoa-sistema-create-model';
import { FormPessoaSistema } from 'views/components/form/master/pessoa/pessoa-sistema/form-pessoa-sistema';
import { LixoIcon, SalvarIcon, VoltarIcon } from 'views/components/icons';
import { useConfirm } from 'material-ui-confirm';

interface DialogPessoaSistemaProps {
  openned: boolean;
  closeModal: () => void;
  model: PessoaSistemaCreateModel
  carregando: boolean
  enviarModel: (model: PessoaSistemaCreateModel) => Promise<boolean>;
  handleDelete: (model: PessoaSistemaCreateModel) => Promise<void>
}

export const DialogPessoaSistema = ({
  openned,
  closeModal,
  model,
  carregando,
  enviarModel,
  handleDelete
}: DialogPessoaSistemaProps) => {
  const classes = useStyles();
  const { showToast } = useToastSaurus()
  const { isMobile } = useThemeQueries()

  const confirm = useConfirm()

  const formRef = useRef<DefaultFormRefs<PessoaSistemaCreateModel>>(null)

  const recarregarForm = useCallback((modelForm: PessoaSistemaCreateModel) => {
    formRef.current?.fillForm(modelForm)
  }, [])

  useEffect(() => {
    recarregarForm(model);
  }, [model, recarregarForm])

  const handleSubmit = useCallback(async (actualModel: PessoaSistemaCreateModel, beforeModel: PessoaSistemaCreateModel) => {
    const equal = isEqual(actualModel, beforeModel)

    if (equal) {
      showToast('info', 'Nenhuma informação foi alterada.')
      return
    }

    const res = await enviarModel(actualModel)

    if (!res) {
      return
    }
    closeModal()

  }, [closeModal, enviarModel, showToast])

  const confirmDelete = useCallback(() => {
    confirm({
      confirmationText: 'Excluir',
      cancellationText: 'Cancelar',
      description: 'Tem certeza que deseja desvincular este preço?'
    }).then(() => {
      handleDelete(model)
    })
  }, [confirm, handleDelete, model])

  return (
    <DialogSaurus
      aberto={openned}
      titulo={model.id === guidEmpty() ? "Cadastrar Preço" : "Editar Preço"}
      tamanho="sm"
    >
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={12}>
                <FormPessoaSistema
                  aberto={openned}
                  onSubmit={handleSubmit}
                  loading={carregando}
                  showLoading={true}
                  ref={formRef}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} style={{ paddingBottom: '7px' }}>
                    <Grid item xs={12} md={6}>
                      {model.id !== guidEmpty() && <Button variant='outlined' color='primary' fullWidth={isMobile} className={classes.buttonRemover}
                        onClick={confirmDelete}>
                        <LixoIcon tipo='BUTTON' />
                        Excluir
                      </Button>}
                    </Grid>
                  <Grid item xs={12} md={3}>
                    {closeModal && (
                      <Button
                        disabled={carregando}
                        variant="outlined"
                        fullWidth
                        color="primary"
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        <VoltarIcon tipo='BUTTON' />
                        Voltar
                      </Button>
                    )}
                  </Grid>
                    <Grid item xs={12} md={3}>
                      <Button
                        disabled={carregando}
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => formRef.current?.submitForm()}
                      >
                        <SalvarIcon tipo='BUTTON_PRIMARY' />
                        Salvar
                      </Button>
                    </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
