import { guidEmpty } from "utils/guid-empty";

export class ClienteIdealRespostaModel {
    constructor(
        public id: string = guidEmpty(),
        public perguntaIdDependente: string | null = null,
        public respostaIdDependente: string | null = null, 
        public resposta: string = '',
        public pontos: number = 0,
        public ativo: boolean = true,
    ) { }
}