import { TerminalModel } from "model/api/gestao/terminal/terminal-model";
import { CardNaoEncontrado } from "views/components/cards";
import { CardTerminal } from "views/components/cards/card-terminal/card-terminal";
import { InformacaoIcon } from "views/components/icons";

export interface ClienteTerminaisGridProps {
  list: Array<TerminalModel>;
  carregando: boolean;
  onCardChecked: (model: TerminalModel) => any;
}

export const AccordionClienteTerminaisListData = (props: ClienteTerminaisGridProps) => {

  const onCardChecked = (id: TerminalModel) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhuma Informação Encontrada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props?.list.length > 0 &&
        props.list.map((items, index) => {
          return (
            <CardTerminal
            onClick={onCardChecked}
              model={items}
              key={index}
              isCliente
              />
          );
        })}
    </>
  );
};
