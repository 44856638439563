import { useThemeQueries } from "views/theme";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { Button, Divider, Grid, Typography } from "@material-ui/core";
import { IndicacoesInteracoesModel } from "model/api/gestao/indicacoes/indicacoes-interacoes-model";
import { useCallback, useEffect, useState } from "react";
import { IndicacoesInteracoesPostModel } from "model/api/gestao/indicacoes/indicacoes-interacoes-post-model";
import { picker } from "utils/picker";
import { usePostIndicacoesInteracoes } from "data/api/gestao/indicacoes/post-indicacoes-interacoes";
import { CircularLoading } from "views/components/utils";
import { guidEmpty } from "utils/guid-empty";
import { toDateStringApi } from "utils/to-date";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { EnumStatusIndicacao } from "model/enums/enum-status-indicacao";
import { RadioSaurus } from "views/components/controles/radio/saurus-radio";

interface Props {
    open: boolean;
    id: string;
    interacoes: IndicacoesInteracoesModel[];
    onClose: () => void;
    getInteracoesWrapper: () => Promise<void>;
    validando: boolean;
}

export const DialogFinalizarIndicacao = ({
    open,
    id,
    interacoes,
    onClose,
    getInteracoesWrapper,
    validando
}: Props) => {

    //AUX
    const { theme } = useThemeQueries();
    const { showToast } = useToastSaurus()

    //PROVIDERS
    const { usuario } = useSessaoAtual()

    //CHAMADAS DA API
    const { postIndicacoesInteracoes, carregando: carregandoPost } = usePostIndicacoesInteracoes();

    const carregando = carregandoPost;

    const [status, setStatus] = useState<EnumStatusIndicacao>(EnumStatusIndicacao.Finalizado)

    const finalizacao = [
        {
            value: 'Concluir indicação como bem sucedida (contrato fechado).',
            status: EnumStatusIndicacao.Finalizado,
        },
        {
            value: 'Cancelar por negativa Comercial/Técnica.',
            status: EnumStatusIndicacao.Cancelado,
        },
        {
            value: 'Cancelado por outros motivos.',
            status: EnumStatusIndicacao.Invalidado,
        },
        {
            value: validando ? 'Disponobilizar para Revenda' : 'Disponibilizar para Retrabalho.',
            status: EnumStatusIndicacao.Disponivel,
        }
    ]

    const salvar = useCallback(async (status: EnumStatusIndicacao) => {

        try {
            const model = interacoes[0];

            const modelFormatado = picker<IndicacoesInteracoesPostModel>(model, new IndicacoesInteracoesPostModel());

            modelFormatado.tipoInteracao = status;
            modelFormatado.descricao = '';
            modelFormatado.responsavelId = usuario?.Id ?? '';
            modelFormatado.id = guidEmpty();
            modelFormatado.dataHora = toDateStringApi(new Date())

            const res = await postIndicacoesInteracoes(id, modelFormatado);

            if (res.erro)
                throw res.erro
            await getInteracoesWrapper();

            showToast('success', 'Status Atualizado!')

            onClose()
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getInteracoesWrapper, id, interacoes, onClose, postIndicacoesInteracoes, showToast, usuario?.Id])

    //Setando valor sugerido de acordo com o status atual da indicação
    useEffect(() => {
        //valor da última interação reflete no status atual da indicação
        const model = interacoes[interacoes.length - 1]

        switch (model.tipoInteracao) {
            case EnumStatusIndicacao.PropostaAceita:
                setStatus(EnumStatusIndicacao.Finalizado)
                break
            case EnumStatusIndicacao.PropostaRecusada:
                setStatus(EnumStatusIndicacao.Cancelado)
                break
            case EnumStatusIndicacao.InvalidezSolicitada:
                setStatus(EnumStatusIndicacao.Invalidado)
                break
            case EnumStatusIndicacao.CancelamentoSolicitado:
                setStatus(EnumStatusIndicacao.Disponivel)
                break
            case EnumStatusIndicacao.AguardandoValidacao:
                setStatus(EnumStatusIndicacao.Disponivel)
                break
            default:
                setStatus(EnumStatusIndicacao.Disponivel)
                break
        }
    }, [interacoes])

    return (
        <>
            <DialogSaurus
                aberto={open}
                titulo={validando ? 'Avaliar Indicação' : "Finalizar Validação"}
                colorTitulo={theme.palette.primary.main}
                centralizarTitulo={true}
                tamanho="xs"
                bottomArea={
                    <>
                        <div style={{
                            paddingTop: '8px',
                            paddingBottom: '16px',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                        }}>
                            <Divider variant="fullWidth" style={{ background: theme.palette.grey[400], marginBottom: '8px' }} />
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => salvar(status)}
                                        fullWidth
                                    >
                                        Concluir
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="outlined"
                                        onClick={onClose}
                                        fullWidth
                                    >
                                        Voltar
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </>
                }
            >
                {carregando && <CircularLoading tipo="FULLSIZED" />}
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{
                        display: 'flex',
                        textAlign: 'center'
                    }}>
                        <Typography
                            variant="body2"
                            color="textPrimary"
                        >
                            Seleicone abaixo a opção desejada para esta indicação:
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        {finalizacao
                            .filter((item) => {
                                if ((interacoes[interacoes.length - 1].tipoInteracao === EnumStatusIndicacao.AguardandoValidacao ||
                                    interacoes[interacoes.length - 1].tipoInteracao === EnumStatusIndicacao.RetornoInterno) &&
                                    item.status === EnumStatusIndicacao.Finalizado) {
                                    return false
                                }
                                return true
                            })
                            .map((item) => {
                                return (
                                    <Grid container spacing={2} onClick={() => setStatus(item.status)}>
                                        <Grid item xs={1}>
                                            <RadioSaurus
                                                style={{
                                                    padding: '0px'
                                                }}
                                                color="primary"
                                                checked={status === item.status}
                                            />
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Typography
                                                variant="body2"
                                                color="textPrimary"
                                            >
                                                {item.value}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                    </Grid>

                    {/* <Grid item xs={6}>
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => salvar(EnumStatusIndicacao.Cancelado)}
                        >
                            Cancelar
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => salvar(EnumStatusIndicacao.Finalizado)}
                        >
                            Finalizar
                        </Button>
                    </Grid> */}
                </Grid>
            </DialogSaurus>
        </>
    )
}