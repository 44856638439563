
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormFaturaAnteciparValidation = () => {


    const FormFaturaAnteciparValidationYup = useMemo(() => {
        return Yup.object().shape({
            meses: Yup.number().positive().notRequired().typeError(''),
            competenciaFinal: Yup.string().transform(value => value.length === 6 ? value : null).notRequired().typeError('Formato Inválido'),
        });
    }, [])

    return {
        FormFaturaAnteciparValidationYup,
    }
}