import { Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { useStyles } from './list-styles';
import { useDefaultCardStyles } from 'views/components/cards/components';
import { InformacaoIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';

export const PesquisaComissionado = () => {
  const classes = useDefaultCardStyles();
  const classesNaoEncontrado = useStyles();
  const { theme } = useThemeQueries();

  return (
    <>
      <div
        className={classNames(classes.cardContent)}
        style={{
          padding: 8,
          background: theme.palette.background.paper,
          margin: theme.spacing(5),
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <div
          className={classNames(
            classes.celulaGridFull,
            classesNaoEncontrado.cardPesquisa,
            'cardCelula',
          )}
        >
          <Grid container alignItems="center" justifyContent="center">
            <InformacaoIcon
              tipo="GERAL"
              class="info-icon-pesquisa"
              fill={theme.palette.primary.main}
              style={{ width: 50, height: 50 }}
            />
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              align="center"
              variant="h3"
              style={{ fontSize: 20, fontWeight: '600' }}
            >
              Informe um Comissionado para buscar por comissões pendentes.
            </Typography>
          </Grid>
        </div>
      </div>
    </>
  );
};
