import { makeStyles, Theme } from "@material-ui/core";

interface StylesProps{
    disabled?: boolean;
}

export const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
    menuItem: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'block',
        lineHeight: '40px',
        [theme.breakpoints.up("sm")]: {
            lineHeight: '30px',
        },
    },
    disabledSelect: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: ({disabled}) => disabled ? 'rgba(0, 0, 0, 0.1) !important' : 'rgba(0, 0, 0, 0.23) !important',
        }
    }
}));
