import { VariaveisAmbiente } from 'config';
import { PlanoModel } from 'model/api/gestao/plano/plano-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePostAdicionarPlano() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postAdicionarPlano = useCallback(
        (plano: PlanoModel) =>
            invocarApi({
                url: `/${VariaveisAmbiente.apiVersion}/Planos`,
                method: 'POST',
                data: plano,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postAdicionarPlano,
    };
}
