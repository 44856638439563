import { VariaveisAmbiente } from 'config';
import { ComissaoPaiModel } from 'model/api/gestao/comissao/comissao-pai-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetComissaoById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ComissaoPaiModel>();

    const getComissaoById = useCallback(
        (id: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/comissao/${id}`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getComissaoById,
    };
}
