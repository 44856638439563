import { DefaultModal } from 'views/components/modals/components/default-modal';
import { useThemeQueries } from 'views/theme';
import { FuncionarioCadastro } from './components/funcionario-cadastro';

type Props = {
  openned: boolean;
}

export const FuncionarioModal = ({ openned }: Props) => {
  const { isMobile } = useThemeQueries()
  return (
    <DefaultModal
      minWidth={isMobile ? '0' : "600px"}
      open={openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {openned && <FuncionarioCadastro/>}
    </DefaultModal>
  );
};
