import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { RevendaDesempenhoByIdModel } from 'model/api/gestao/revendedor/revenda-desempenho-by-id-model';

export function useGetRevendaDesempenhoById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<RevendaDesempenhoByIdModel[]>();

    const getRevendaDesempenhoById = useCallback(
        (anoMesApuracao: number, revendaId: string, query?: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/resultados/revendas-desempenho/apuracao/${anoMesApuracao}/${revendaId}/mes?${query || ''}`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getRevendaDesempenhoById,
    };
}
