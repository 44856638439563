import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  container: {
    '& p': {
      fontSize: '12px !important'
    }
  },
  card: {
    background: '#F8F8F8',
    borderRadius: '15px',
  }
}))