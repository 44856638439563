import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';
export const EnviarEmailIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <g clipPath="url(#clip0_1222_8724)">
          <path d="M41.5 -0.00390625L32.8066 10H35H38V19H45V10H50.1934L41.5 -0.00390625ZM41.5 3.04297L45.8066 8H43V17H40V8H37.1934L41.5 3.04297ZM0 8V44H50V12H48V36.9648L34.8555 26.5723C37.1349 24.5678 38.4917 23.3717 41.2012 21H38.2539C33.6569 25.007 29.0539 29.0185 28.3359 29.6445C26.9279 30.8705 25.534 31.002 25 31.002C24.467 31.002 23.0731 30.8696 21.6641 29.6426C20.2641 28.4226 4.123 14.3569 2 12.5059V10H30.1562L31.8945 8H0ZM2 15.1523C5.1834 17.9268 10.72 22.7507 15.1211 26.5859L2 36.9648V15.1523ZM33.3281 27.9141L48 39.5137V42H2V39.5137L16.6562 27.9238C17.6723 28.8092 20.1049 30.9301 20.3535 31.1465C22.2715 32.8165 24.243 32.998 25 32.998C25.759 32.998 27.7295 32.8184 29.6465 31.1484C29.8622 30.9607 32.2457 28.866 33.3281 27.9141Z" />
        </g>
        <defs>
          <clipPath id="clip0_1222_8724">
            <rect width="50" height="50" fill="white" />
          </clipPath>
        </defs>
      </DefaultIcon>
    </>
  );
};
