import { forwardRef, useImperativeHandle, useMemo } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import {
    TextFieldSaurus,

} from 'views/components/controles/inputs';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { picker } from 'utils/picker';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { TpTributacaoMock } from 'data/mocks/tipo-tributacao-mock';
import { KeyValueModel } from 'model';
import { NotaFiscalFormModel } from 'model/api/gestao/nota-fiscal/emitir-nota-fiscal-model';
import { useFormNotaFiscalEditarValidation } from './form-nota-fiscal-validation';
import { yupResolver } from '@hookform/resolvers/yup';


export const FormNotaFiscalEditar = forwardRef<
    DefaultFormRefs<NotaFiscalFormModel>,
    DefaultFormProps<NotaFiscalFormModel>
>(
    (
        { loading, onSubmit, ...props }: DefaultFormProps<NotaFiscalFormModel>,
        ref,
    ) => {
        const utilClasses = makeUtilClasses();

        const { FormNotaFiscalEditarValidationYup } = useFormNotaFiscalEditarValidation()

        const carregando = loading

        const model = useMemo(() => (
            props.model || new NotaFiscalFormModel()
        ), [props.model])

        const {
            handleSubmit,
            control,
            formState: { errors, touchedFields },
            reset,
        } = useForm<NotaFiscalFormModel>({
            defaultValues: { ...model },
            resolver: yupResolver(FormNotaFiscalEditarValidationYup),
            criteriaMode: 'all',
            mode: 'onBlur' && 'onChange',
        });

        const submit = (values: NotaFiscalFormModel) => {
            const notaFiscal = picker<NotaFiscalFormModel>(values, new NotaFiscalFormModel())
            onSubmit(notaFiscal);
        };

        useImperativeHandle(ref, () => ({
            submitForm: () => {
                handleSubmit(submit)();
            },
            resetForm: () => {
                reset({ ...model });
            },
            fillForm: (values: NotaFiscalFormModel) => {
                reset({ ...values })
            },
        }));

        return (
            <>
                <Box my={2}>
                    <div className={utilClasses.formContainer}>
                        <form
                            onSubmit={handleSubmit(submit)}
                            className={carregando ? utilClasses.controlLoading : ''}
                        >
                            <Box my={3}>
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={6} md={4}>
                                        <Controller
                                            name="aliquotaDeducao"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={carregando}
                                                    label="Aliquota Dedução"
                                                    tipo="DECIMAL"
                                                    manterMascara
                                                    allowSubmit
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    helperText={
                                                        touchedFields.aliquotaDeducao && errors.aliquotaDeducao
                                                            ? errors.aliquotaDeducao.message
                                                            : undefined
                                                    }
                                                    error={Boolean(
                                                        touchedFields.aliquotaDeducao && errors.aliquotaDeducao && errors.aliquotaDeducao.message,
                                                    )}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Controller
                                            name="aliquotaISS"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={carregando}
                                                    label="Aliquota ISS"
                                                    tipo="DECIMAL"
                                                    manterMascara
                                                    casasDecimais={3}
                                                    allowSubmit
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    helperText={
                                                        touchedFields.aliquotaISS && errors.aliquotaISS
                                                            ? errors.aliquotaISS.message
                                                            : undefined
                                                    }
                                                    error={Boolean(
                                                        touchedFields.aliquotaISS && errors.aliquotaISS && errors.aliquotaISS.message,
                                                    )}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Controller
                                            name="aliquotaPIS"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={carregando}
                                                    label="Aliquota PIS"
                                                    tipo="DECIMAL"
                                                    manterMascara
                                                    casasDecimais={3}
                                                    allowSubmit
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    helperText={
                                                        touchedFields.aliquotaPIS && errors.aliquotaPIS
                                                            ? errors.aliquotaPIS.message
                                                            : undefined
                                                    }
                                                    error={Boolean(
                                                        touchedFields.aliquotaPIS && errors.aliquotaPIS && errors.aliquotaPIS.message,
                                                    )}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Controller
                                            name="aliquotaCOFINS"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={carregando}
                                                    label="Aliquota COFINS"
                                                    tipo="DECIMAL"
                                                    manterMascara
                                                    casasDecimais={3}
                                                    allowSubmit
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    helperText={
                                                        touchedFields.aliquotaCOFINS && errors.aliquotaCOFINS
                                                            ? errors.aliquotaCOFINS.message
                                                            : undefined
                                                    }
                                                    error={Boolean(
                                                        touchedFields.aliquotaCOFINS && errors.aliquotaCOFINS && errors.aliquotaCOFINS.message,
                                                    )}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Controller
                                            name="aliquotaINSS"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={carregando}
                                                    label="Aliquota INSS"
                                                    tipo="DECIMAL"
                                                    manterMascara
                                                    casasDecimais={3}
                                                    allowSubmit
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    helperText={
                                                        touchedFields.aliquotaINSS && errors.aliquotaINSS
                                                            ? errors.aliquotaINSS.message
                                                            : undefined
                                                    }
                                                    error={Boolean(
                                                        touchedFields.aliquotaINSS && errors.aliquotaINSS && errors.aliquotaINSS.message,
                                                    )}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Controller
                                            name="aliquotaIR"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={carregando}
                                                    label="Aliquota IR"
                                                    tipo="DECIMAL"
                                                    manterMascara
                                                    casasDecimais={3}
                                                    allowSubmit
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    helperText={
                                                        touchedFields.aliquotaIR && errors.aliquotaIR
                                                            ? errors.aliquotaIR.message
                                                            : undefined
                                                    }
                                                    error={Boolean(
                                                        touchedFields.aliquotaIR && errors.aliquotaIR && errors.aliquotaIR.message,
                                                    )}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Controller
                                            name="aliquotaCSLL"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={carregando}
                                                    label="Aliquota CSLL"
                                                    tipo="DECIMAL"
                                                    manterMascara
                                                    casasDecimais={3}
                                                    allowSubmit
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    helperText={
                                                        touchedFields.aliquotaCSLL && errors.aliquotaCSLL
                                                            ? errors.aliquotaCSLL.message
                                                            : undefined
                                                    }
                                                    error={Boolean(
                                                        touchedFields.aliquotaCSLL && errors.aliquotaCSLL && errors.aliquotaCSLL.message,
                                                    )}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Controller
                                            name="pTotTrib"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={carregando}
                                                    label="% Tributário (pTotTrib)"
                                                    tipo="DECIMAL"
                                                    manterMascara
                                                    casasDecimais={3}
                                                    allowSubmit
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    helperText={
                                                        touchedFields.pTotTrib && errors.pTotTrib
                                                            ? errors.pTotTrib.message
                                                            : undefined
                                                    }
                                                    error={Boolean(
                                                        touchedFields.pTotTrib && errors.pTotTrib && errors.pTotTrib.message,
                                                    )}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Controller
                                            name="issRetido"
                                            control={control}
                                            render={({ field }) => (
                                                <SelectSaurus
                                                    disabled={loading}
                                                    allowSubmit={false}
                                                    conteudo={[
                                                        new KeyValueModel(true, 'Retido'),
                                                        new KeyValueModel(false, 'Não Retido')
                                                    ]}
                                                    label="ISS Retido"
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    helperText={
                                                        errors.issRetido
                                                            ? errors.issRetido.message
                                                            : undefined
                                                    }
                                                    error={Boolean(errors.issRetido && errors.issRetido.message)}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            name="tipoTributacao"
                                            control={control}
                                            render={({ field }) => (
                                                <SelectSaurus
                                                    disabled={loading}
                                                    allowSubmit={false}
                                                    label="Tipo de Tributação"
                                                    conteudo={TpTributacaoMock}
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    helperText={
                                                        errors.tipoTributacao
                                                            ? errors.tipoTributacao.message
                                                            : undefined
                                                    }
                                                    error={Boolean(errors.tipoTributacao && errors.tipoTributacao.message)}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <Controller
                                            name="serieRPS"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={carregando}
                                                    label="Série RPS"
                                                    tipo="NUMERO"
                                                    showStartAdornment
                                                    manterMascara
                                                    allowSubmit
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    helperText={
                                                        touchedFields.serieRPS && errors.serieRPS
                                                            ? errors.serieRPS.message
                                                            : undefined
                                                    }
                                                    error={Boolean(
                                                        errors.serieRPS && errors.serieRPS.message,
                                                    )}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <Controller
                                            name="nroRPS"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={carregando}
                                                    label="Número RPS"
                                                    tipo="NUMERO"
                                                    showStartAdornment
                                                    manterMascara
                                                    allowSubmit
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    helperText={
                                                        touchedFields.nroRPS && errors.nroRPS
                                                            ? errors.nroRPS.message
                                                            : undefined
                                                    }
                                                    error={Boolean(
                                                        errors.nroRPS && errors.nroRPS.message,
                                                    )}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Controller
                                            name="textoServico"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={loading}
                                                    allowSubmit={false}
                                                    tipo="TEXTO"
                                                    label="Texto de Serviço"
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    helperText={
                                                        errors.textoServico
                                                            ? errors.textoServico.message
                                                            : undefined
                                                    }
                                                    error={Boolean(errors.textoServico && errors.textoServico.message)}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Controller
                                            name="codigoServico"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={loading}
                                                    allowSubmit={false}
                                                    tipo="TEXTO"
                                                    label="Código de Serviço"
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    helperText={
                                                        errors.codigoServico
                                                            ? errors.codigoServico.message
                                                            : undefined
                                                    }
                                                    error={Boolean(errors.codigoServico && errors.codigoServico.message)}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="descricao"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={carregando}
                                                    label="Descrição"
                                                    allowSubmit
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    helperText={
                                                        touchedFields.descricao && errors.descricao
                                                            ? errors.descricao.message
                                                            : undefined
                                                    }
                                                    error={Boolean(
                                                        errors.descricao && errors.descricao.message,
                                                    )}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Button style={{ display: 'none' }} type="submit"></Button>
                        </form>
                    </div>
                </Box>
            </>
        );
    },
);
