import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Button, Grid } from '@material-ui/core';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { picker } from 'utils/picker';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { useGetPagamento } from 'data/api/gestao/pagamento';
import { PagamentoModel } from 'model/api/gestao/pagamento/pagamento-model';
import { KeyValueModel } from 'model';
import { useToastSaurus } from 'services/app';
import { PessoaFormaPagamentoFormModel } from 'model/app/forms/pessoa-forma-pagamento/pessoa-forma-pagamento-form-model';

interface PessoaFormModelProps
  extends DefaultFormProps<PessoaFormaPagamentoFormModel> { }

export const FormPessoaPagamento = forwardRef<
  DefaultFormRefs<PessoaFormaPagamentoFormModel>,
  PessoaFormModelProps
>(({ loading, onSubmit, ...props }: PessoaFormModelProps, ref) => {
  const { isMobile } = useThemeQueries();
  const utilClasses = makeUtilClasses();
  const { showToast } = useToastSaurus();

  const { getPagamento } = useGetPagamento();

  const refInputNome = useRef<HTMLInputElement>(null);
  //const { FormPessoaSocioValidationYup } = useFormPessoaSocioValidation()
  const [modelForm, setModelForm] = useState<PessoaFormaPagamentoFormModel>(
    new PessoaFormaPagamentoFormModel(),
  );

  const [formasPagamento, setFormasPagamento] = useState<KeyValueModel[]>([]);

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    getValues,
    setValue,
  } = useForm<PessoaFormaPagamentoFormModel>({
    defaultValues: { ...modelForm },
    criteriaMode: 'all',
    mode: 'onBlur' && 'onChange',
  });

  const getPagamentoWrapper = useCallback(async () => {
    const res = await getPagamento(1);

    if (res.erro) throw res.erro;

    return res.resultado?.data.list as PagamentoModel[];
  }, [getPagamento]);

  const carregando = loading;

  const submitEnviar = (values: PessoaFormaPagamentoFormModel) => {
    const formaPagamento = picker<PessoaFormaPagamentoFormModel>(
      values,
      modelForm,
    );
    onSubmit(formaPagamento, modelForm);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(submitEnviar)();
    },
    resetForm: () => {
      reset();
      if (!isMobile) refInputNome.current?.focus();
    },
    fillForm: (model: PessoaFormaPagamentoFormModel) => {
      setModelForm({ ...model });
      reset({ ...model });
      if (!isMobile) refInputNome.current?.focus();
    },
  }));

  useEffect(() => {
    getPagamentoWrapper()
      .then((pagamentos) => {
        const pagamentoKeyValue = pagamentos.map(
          (item) => new KeyValueModel(item.id, item.nome),
        );
        setFormasPagamento(pagamentoKeyValue);
      })
      .catch((err) => showToast('error', err.message));
  }, [getPagamentoWrapper, showToast]);

  return (
    <>
      <div className={utilClasses.formContainer}>
        {carregando && props.showLoading && (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="FULLSIZED" />
          </div>
        )}

        <form
          onSubmit={handleSubmit(submitEnviar)}
          className={loading ? utilClasses.controlLoading : ''}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="formaPagamentoId"
                control={control}
                render={({ field }) => (
                  <SelectSaurus
                    variant="outlined"
                    disabled={loading}
                    label="Forma de Pagamento"
                    fullWidth
                    allowSubmit
                    conteudo={formasPagamento}
                    helperText={
                      touchedFields.formaPagamentoId && errors.formaPagamentoId
                        ? errors.formaPagamentoId.message
                        : undefined
                    }
                    error={Boolean(
                      errors.formaPagamentoId && errors.formaPagamentoId.message,
                    )}
                    value={getValues('formaPagamentoId')}
                    onChange={(ev) => {
                      const item = formasPagamento.filter(
                        (item) => ev.target.value === item.Key,
                      );
                      setValue('formaPagamentoId', item[0].Key);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="padrao"
                control={control}
                render={({ field }) => (
                  <SelectSaurus
                    variant="outlined"
                    disabled={loading}
                    label="Padrão"
                    fullWidth
                    allowSubmit
                    conteudo={[
                      new KeyValueModel(true, 'Sim'),
                      new KeyValueModel(false, 'Não')
                    ]}
                    {...field}
                    helperText={
                      touchedFields.formaPagamentoId && errors.formaPagamentoId
                        ? errors.formaPagamentoId.message
                        : undefined
                    }
                    error={Boolean(
                      errors.formaPagamentoId && errors.formaPagamentoId.message,
                    )}
                    value={getValues('padrao')}
                    onChange={(ev: any) => {
                      setValue('padrao', ev.target.value)
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
