import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { CardSistemas } from 'views/components/cards/card-sistemas';
import { Grid } from '@material-ui/core';
import { SistemasModel } from 'model/api/gestao/sistemas/sistemas-model';

export interface SistemasGridProps {
  list: Array<SistemasModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (id: string) => any;
  onCardChecked: (id: string) => any;
}

export const SistemasListData = (props: SistemasGridProps) => {
  const onCardSelected = (id: string) => {
    props.onCardSelected(id);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <Grid item xs={12}>
          <CardNaoEncontrado
            mensagem="Nenhum Sistema Encontrado."
            icon={<InformacaoIcon tipo="GERAL" />}
          />
        </Grid>
      )}
      {props.list.length > 0 &&
        props.list.map((sistemas, index) => {
          return (
            <Grid item xs={12} sm={6} lg={4}>
              <CardSistemas
                selected={
                  props.selectedList.filter((item) => item === sistemas.id)
                    .length > 0
                    ? true
                    : false
                }
                onCheck={onCardChecked}
                onClick={onCardSelected}
                model={sistemas}
                key={index}
              />
            </Grid>
          );
        })}
    </>
  );
};
