import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetComissaoPendenteExExcel() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getComissaoPendenteExExcel = useCallback(
        async (queryBusca: string) => {
            const queryFinal = `${queryBusca?.length > 0 ? "?" : ""
                }${queryBusca}`;

            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/comissao/pendentes/excel${queryFinal}`,
                method: 'GET',
                enviarTokenUsuario: true,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/vnd.ms-excel",
                    "Content-Disposition": "attachment; filename=Comissoes.xls; filename*=UTF-8''Comissoes.xls"
                },
            })
        }
        , [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getComissaoPendenteExExcel,
    };
}
