import { Grid } from "@material-ui/core";
import { useParams } from "react-router-dom"
import { useDefaultCadastroStyles } from "../../cadastros/components/default-cadastro-styles";
import { SistemasByIdContent } from "./components/sistemas-by-id-content/sistemas-by-id-content";
import { SistemasByIdHeader } from "./components/sistemas-header/sistemas-by-id-header";

const SistemasByIdPage = () => {

    const { id } = useParams<{ id: string }>()
    const classes = useDefaultCadastroStyles();

    return (
        <Grid className={classes.root}>
            <Grid className={classes.header}>
                <SistemasByIdHeader />
            </Grid>
            <Grid className={classes.defaultContainer}>
                <SistemasByIdContent id={id} />
            </Grid>
        </Grid>

    )
}

export default SistemasByIdPage