import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        position: "relative",
        width: '100%',
        margin: '0 11rem',
        borderRadius: 8,
        overflowY: "auto",
        overflowX: "hidden",
        [theme.breakpoints.down('lg')]: {
            margin: '0 10rem',
        },
        [theme.breakpoints.down('md')]: {
            margin: '0 1rem',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '0 .5rem'
        },
        [theme.breakpoints.down('xs')]: {
            margin: '0'
        }
    },
    valoresContent: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    valoresContentUltimo: {
        marginTop: theme.spacing(1)
    },
    buttonRemover: {
        border: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
        "& svg": {
            fill: theme.palette.error.main
        },
        "&:disabled": {
            backgroundColor: theme.palette.error.light
        },
        marginRight: 8,
    },
    contentContainer: {
        background: '#F8F8F8',
        padding: '0 10px 1rem 10px !important',
        borderRadius: '15px',
        boxShadow: '0px 4px 8px rgba(96, 97, 112, 0.16)',
        '& .MuiFormControl-root': {
            background: '#fff'
        }
    },
    acoes: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: 7
    },
    acoesCard: {
        background: theme.palette.background.paper,
        padding: '1rem',
        margin: 'unset',
        borderRadius: theme.shape.borderRadius,
        width: '100%'
    },
    acoesCardButtonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '1rem',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'flex-start'
        }
    },
    acoesCardButtons: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        }
    },
    statusContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    data: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            alignItems: 'flex-start'
        }
    },
    boldText: {
        fontWeight: 500
    },
    icons: {
        '& svg': {
            width: 30,
            height: 30,
        }
    }
}))