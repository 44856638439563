import { Grid } from "@material-ui/core";
import { RepresentantesEdit } from "./representantes-edit/representantes-edit";

type Props = {
  id: string,
}

export const RepresentantesEditar = ({ id }: Props) => {


  return (
    <div style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    }}>
      <Grid container spacing={1} style={{
        maxWidth: 1400,
        padding: 16
      }}>
        <RepresentantesEdit id={id}/>
      </Grid>
    </div>
  );
};
