import { useCallback, useMemo, useState } from 'react';
import { NotasFiscaisRevendaHeader } from './components/notas-fiscais-revenda-header/notas-fiscais-revenda-header';
import { NotasFiscaisRevendaList } from './components/notas-fiscais-revenda-list/notas-fiscais-revenda-list';
import { NotasFiscaisRevendaListSearchProps } from './components/notas-fiscais-revenda-list/notas-revenda-fiscais-revenda-list-search-props';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { EnumSituacao } from 'model/enums/enum-situacao';
import { useParams } from 'react-router-dom';
import { NotasFiscaisEditar } from './components/notas-fiscais-editar/notas-fiscais-editar';
import { useSessaoAtual } from 'services/app';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { toDateString } from 'utils/to-date';
import { EnumTipoFiltroData } from 'model/enums/enum-tipo-filtro-data';

interface paramProps {
  parametro: string
}

export const NotasFiscaisRevendaPage = () => {
  const classes = useDefaultCadastroStyles();
  const {tipoUsuario} = useSessaoAtual();

  const isRevendedor = tipoUsuario() === EnumTipoPessoas.Revendedor || tipoUsuario() === EnumTipoPessoas.FuncionarioFinanceiro || tipoUsuario() === EnumTipoPessoas.FuncionarioFinanceiroSemCusto

  const getCurrentMonth = useCallback(() => {
    const month = new Date().getMonth() + 1
    const year = new Date().getFullYear()
    return {
      inicial: new Date(`${month}/01/${year - 1}`),
      final: new Date(`${month}/31/${year}`)
    }
  }, [])

  const [openPesquisa, setOpenPesquisa] = useState(false);
  const [searchProps, setSearchProps] = useState<NotasFiscaisRevendaListSearchProps>({
    cpfCnpjPagador: '',
    dataFinal: '',
    dataInicial: isRevendedor ? (toDateString(getCurrentMonth().inicial, 'yyyy-MM-DD') || '') : '',
    CompetenciaInicial: '',
    CompetenciaFinal: '',
    NomePagador: '',
    nossoNumero: null,
    Situacao: isRevendedor ? EnumSituacao.Todos : EnumSituacao.Aberta,
    tipoData: EnumTipoFiltroData.Vencimento,
    atualizarPesquisa: false,
    formaPagamentoId: -1
  });

  const { parametro } = useParams<paramProps>()

  const isSoftwareHouse = ([EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto].includes(tipoUsuario()))

  const pageSwitch = useMemo(() => {
    switch (parametro) {
      case undefined:
        return (
          <NotasFiscaisRevendaList searchProps={searchProps} />
        )
      default:
        setOpenPesquisa(false)
        return (
          <NotasFiscaisEditar id={parametro} />
        )
    }
  }, [parametro, searchProps])

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <NotasFiscaisRevendaHeader
          openPesquisa={openPesquisa}
          setSearchProps={setSearchProps}
          setOpenPesquisa={setOpenPesquisa}
          titulo={parametro ? (
            isSoftwareHouse ? 'Editar Fatura de Revenda' : 'Fatura'
          ) : (
            isSoftwareHouse ? 'Faturas de Revendas' : 'Minhas Faturas'
          )}
        />
      </div>
      {pageSwitch}
    </div>
  );
};

export default NotasFiscaisRevendaPage;
