import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { Grid } from '@material-ui/core';
import { ParametroSistemaModel } from 'model/api/gestao/parametro-sistema/parametro-sistema-model';
import { CardParametroSistema } from 'views/components/cards/card-parametro-sistema';

export interface ParametrosGridProps {
  list: ParametroSistemaModel[];
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (modulo: ParametroSistemaModel) => any;
  onCardChecked: (id: string) => any;
  atualizarList: () => void
}

export const ParametrosListData = (props: ParametrosGridProps) => {
  const onCardSelected = (modulo: ParametroSistemaModel) => {
    props.onCardSelected(modulo);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhum Parâmetro Encontrado."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((modulos, index) => {
          return (
            <Grid xs={12}>
              <CardParametroSistema
                selected={
                  false
                }
                atualizarList={props.atualizarList}
                onCheck={onCardChecked}
                onClick={onCardSelected}
                model={modulos}
                key={index}
              />
            </Grid>
          );
        })}
    </>
  );
};
