import { Grid } from "@material-ui/core";
import { useGetFaturaById, usePostFaturaPagar, usePutBonificar, usePutFatura, usePutReabrir } from "data/api/gestao/faturas";
import { isEqual } from "lodash";
import { useConfirm } from "material-ui-confirm";
import { EnumTipoComunicacao } from "model";
import { FaturaModel } from "model/api/gestao/fatura/fatura-model";
import { FaturaCancelamentoModel } from "model/app/forms/fatura-cancelamento/fatura-cancelamento-model";
import { FaturaFormModel } from "model/app/forms/fatura/fatura-form-model";
import { EnumTipoFatura } from "model/enums/enum-tipo-fatura";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { picker } from "utils/picker";
import { CircularLoading } from "views/components";
import { DialogCancelarFatura } from "views/components/dialog/dialog-cancelar-fatura/dialog-cancelar-fatura";
import { DialogLiquidar } from "views/components/dialog/dialog-liquidar/dialog-liquidar";
import { DefaultFormRefs } from "views/components/form/utils";
import { ClienteFaturaInfo } from "views/pages/private/faturas/components/faturas-edit/components/cliente-fatura/cliente-fatura-info";
import { NotasFiscaisRevendaInfo } from "./components/nota-fiscal-revenda/nota-fiscal-revenda";
import { useStyles } from "./notas-fiscais-editar-styles";
import { DialogEditarCompetencia } from "views/components/dialog/dialog-editar-competencia/dialog-editar-competencia";
import { DialogPix } from "views/components/dialog/dialog-pix/dialog-pix";
import { FaturaPagamentoModel } from "model/api/gestao/fatura/fatura-pagamento-model";
import { EnumSituacao } from "model/enums/enum-situacao";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { PagamentoModel } from "model/api/gestao/pagamento/pagamento-model";
import { guidEmpty } from "utils/guid-empty";
import { LicençaInfo } from "views/pages/private/faturas/components/faturas-edit/components/licença-info/licença-info";
import { usePostSincronizarERP } from "data/api/gestao/faturas/post-fatura-sincronizar-erp";
import { usePatchFaturaRecalcular } from "data/api/gestao/faturas/patch-fatura-recalcular";

interface Props {
    id: string;
}

interface FaturaRefs extends DefaultFormRefs<FaturaFormModel> {
    validate(): Promise<boolean>;
    isFormEqual(form: FaturaFormModel): boolean;
}

export interface DialogStatus {
    aberto: boolean;
}


export const NotasFiscaisEditar = ({ id }: Props) => {

    const classes = useStyles();
    const { showToast } = useToastSaurus();
    const { tipoUsuario } = useSessaoAtual();
    const confirm = useConfirm()
    const { getFaturaById, carregando: carregandoGetFatura } = useGetFaturaById()
    const { postFaturaPagar, carregando: carregandoPostPagar } = usePostFaturaPagar()
    const { putReabrir, carregando: carregandoPutReabrir } = usePutReabrir()
    const { putBonificar, carregando: carregandoPutBonificar } = usePutBonificar()
    const { putFatura, carregando: carregandoPutFatura } = usePutFatura()
    const { postSincronizarERP, carregando: carregandoPostERP} = usePostSincronizarERP()
    const { patchFaturaRecalcular, carregando: carregandoPatch } = usePatchFaturaRecalcular()

    const carregando =
        carregandoGetFatura ||
        carregandoPostPagar ||
        carregandoPutReabrir ||
        carregandoPutBonificar ||
        carregandoPutFatura ||
        carregandoPostERP ||
        carregandoPatch;

    const [fatura, setFatura] = useState<FaturaModel>(new FaturaModel())
    const [faturaForm, setFaturaForm] = useState<FaturaFormModel>(new FaturaFormModel())
    const faturaRef = useRef<FaturaModel>(new FaturaModel())

    const [openCancelar, setOpenCancelar] = useState<boolean>(false)
    const [liquidarDialog, setLiquidarDialog] = useState<boolean>(false)
    const [openCompetencia, setOpenCompetencia] = useState<boolean>(false)
    const [pixDialog, setPixDialog] = useState<FaturaPagamentoModel & DialogStatus>({
        aberto: false,
        codigoDeBarras: '',
        linhaDigitavel: '',
        nossoNumero: 0,
        pixChave: '',
        pixCopiaCola: '',
        url: '',
        dhExpiracaoPix: ''
    })

    const [reload, setReaload] = useState<boolean>(false)

    const cadForm = useRef<FaturaRefs>(null)

    const recarregarForm = useCallback((model: FaturaFormModel) => {
        cadForm.current?.fillForm(model)
    }, [])

    useEffect(() => {
        recarregarForm(faturaForm)
    }, [faturaForm, recarregarForm])


    const saveChangesFatura = useCallback(async (model: FaturaFormModel) => {
        const res = await putFatura(model)

        if (res.erro) throw res.erro

        showToast('success', 'Fatura atualizada!')
    }, [putFatura, showToast])

    const handleSubmit = useCallback(async (model: FaturaFormModel, beforeModel?: FaturaFormModel) => {
        try {
            const equal = isEqual(model, beforeModel)

            if (equal) {
                showToast('info', 'Nenhuma informação alterada')
            }

            await saveChangesFatura(model)

            setReaload(prev => !prev)
        }
        catch (err: any) {
            showToast('error', err.message)
        }

    }, [saveChangesFatura, showToast])

    const getFaturaByIdWrapper = useCallback(async () => {
        const res = await getFaturaById(id)

        if (res.erro) throw res.erro

        if (!res.resultado) return new FaturaModel()

        faturaRef.current = res.resultado?.data

        const faturaPicker = picker<FaturaFormModel>(res.resultado.data, new FaturaFormModel())

        faturaPicker.formaPagamentoId = res.resultado.data.formaPagamento.id
        faturaPicker.formasPagamentosDisponiveis = res.resultado.data.formasPagamentosDisponiveis

        if (!faturaPicker.formasPagamentosDisponiveis.find(pg => pg.id === faturaPicker.formaPagamentoId)) {
            faturaPicker.formasPagamentosDisponiveis.push(res.resultado.data.formaPagamento)
        }

        setFaturaForm(faturaPicker)

        return res.resultado.data

    }, [getFaturaById, id])

    const preencherTela = useCallback(async () => {
        try {
            const fatura = await getFaturaByIdWrapper()
            setFatura(fatura)

        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [getFaturaByIdWrapper, showToast])

    useEffect(() => {
        if (!openCancelar && !pixDialog.aberto) {
            preencherTela()
        }
    }, [preencherTela, openCancelar, reload, pixDialog.aberto])

    const checarMudancas = useCallback(() => new Promise(async (resolve) => {
        const faturaPicker = picker<FaturaFormModel>(fatura, new FaturaFormModel())
        faturaPicker.formaPagamentoId = fatura.formaPagamento.id
        faturaPicker.formasPagamentosDisponiveis = fatura.formasPagamentosDisponiveis
        const isEqual = cadForm.current?.isFormEqual(faturaPicker)
        if (!isEqual) {
            await confirm({
                title: 'Aviso',
                content: 'Você fez alterações não salvas. Deseja salvar as alterações?',
                confirmationText: 'Salvar',
                cancellationText: 'Prosseguir com pagamento'
            }).then(() => {
                cadForm.current?.submitForm()
                resolve(false)
            }).catch(() => {
                cadForm.current?.fillForm(faturaPicker)
                resolve(true)
            })
        }
        resolve(true)
    }), [confirm, fatura])

    const pagarPix = useCallback(async (formaPagamento: PagamentoModel) => {
        try {
            const res = await postFaturaPagar(fatura.id, formaPagamento.id)
            if (res.erro) throw res.erro

            if (!res.resultado) return

            const ret = res.resultado.data;

            await preencherTela();

            setPixDialog({
                aberto: true,
                codigoDeBarras: ret.codigoDeBarras,
                linhaDigitavel: ret.linhaDigitavel,
                nossoNumero: ret.nossoNumero,
                pixChave: ret.pixChave,
                pixCopiaCola: ret.pixCopiaCola,
                url: ret.url,
                dhExpiracaoPix: ret.dhExpiracaoPix

            })

        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [fatura.id, postFaturaPagar, preencherTela, showToast])

    const recalcularFatura = useCallback(async () => {
        try{
            const res = await patchFaturaRecalcular(fatura.id)

            if(res.erro) throw res.erro

            await preencherTela()
        }catch(e: any){
            showToast('error', e.message)
        }
    }, [fatura.id, patchFaturaRecalcular, preencherTela, showToast])

    const pagarFatura = useCallback(async () => {
        try {
            if (fatura.situacao !== EnumSituacao.Cancelada &&
                tipoUsuario() !== EnumTipoPessoas.Revendedor &&
                fatura.situacao !== EnumSituacao.Paga &&
                fatura.situacao !== EnumSituacao.Bonificada) {

                const prosseguir = await checarMudancas()
                if (!prosseguir) return
            }

            if (await cadForm.current?.validate()) return

            if (fatura.formaPagamento.tipoComunicacao === EnumTipoComunicacao.Dinheiro ||
                fatura.formaPagamento.tipoComunicacao === EnumTipoComunicacao.Transferencia) {
                const formaPagamento = fatura.formasPagamentosDisponiveis.find(fp => fp.tipoComunicacao === EnumTipoComunicacao.Boleto)

                if (!formaPagamento) throw new Error('Erro ao definir forma de pagamento.')

                const attRes = await putFatura({ ...faturaForm, formaPagamentoId: formaPagamento.id })
                if (attRes.erro) throw attRes.erro

                showToast('info', 'Forma de pagamento atualizada. Tente pagar novamente.')
                await preencherTela();
                return
            }

            const res = await postFaturaPagar(fatura.id, fatura.formaPagamento.id)

            if (res.erro) throw res.erro

            if (!res.resultado) return

            const ret = res.resultado?.data

            switch (fatura.formaPagamento.tipoComunicacao) {
                case EnumTipoComunicacao.Boleto:
                    window.open(res.resultado.data.url, '_blank');
                    break;
                case EnumTipoComunicacao.Pix:
                    setPixDialog({
                        aberto: true,
                        codigoDeBarras: ret.codigoDeBarras,
                        linhaDigitavel: ret.linhaDigitavel,
                        nossoNumero: ret.nossoNumero,
                        pixChave: ret.pixChave,
                        pixCopiaCola: ret.pixCopiaCola,
                        url: ret.url,
                        dhExpiracaoPix: ret.dhExpiracaoPix

                    })
                    break;
            }

            await preencherTela()
        }
        catch (err: any) {
            showToast('error', err.message)
        }
    }, [checarMudancas, fatura.formaPagamento.id, fatura.formaPagamento.tipoComunicacao, fatura.formasPagamentosDisponiveis, fatura.id, fatura.situacao, faturaForm, postFaturaPagar, preencherTela, putFatura, showToast, tipoUsuario])

    const cancelarFatura = useCallback(() => {
        setOpenCancelar(true)
        return true
    }, [])

    const reabrirFatura = useCallback(() => {
        confirm({
            title: 'Reabrir Fatura',
            description: 'Tem certeza que deseja fazer isso?',
            confirmationText: 'Confirmar',
            cancellationText: 'Cancelar'
        }).then(async () => {
            try {
                const res = await putReabrir(fatura.id)

                if (res.erro) throw res.erro

                await preencherTela()

                showToast('success', 'Fatura reaberta!')
                return true
            } catch (err: any) {
                showToast('error', err.message)
                return false
            }
        })
        return true
    }, [confirm, fatura.id, preencherTela, putReabrir, showToast])

    const bonificarFatura = useCallback(() => {
        confirm({
            title: 'Bonificar Fatura',
            description: 'Tem certeza que deseja fazer isso?',
            confirmationText: 'Confirmar',
            cancellationText: 'Cancelar'
        }).then(async () => {
            try {

                const res = await putBonificar(fatura.id)

                if (res.erro) throw res.erro

                await preencherTela()

                showToast('success', 'Fatura bonificada!')

                return true
            } catch (err: any) {
                showToast('error', err.message)
                return false
            }
        })
        return true
    }, [confirm, fatura.id, preencherTela, putBonificar, showToast])

    const sincronizarERP = useCallback(async () => {
        try {
            const res = await postSincronizarERP(fatura.id);

            if(res.erro) throw res.erro
            
            await preencherTela();
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [fatura.id, postSincronizarERP, preencherTela, showToast])

    // //MENSAGEM DE ERRO PQ N TEM TOKEN DO ITAU ATUALMENTE
    // const avisoSistema = useCallback(async () => {
    //     switch (fatura.formaPagamento.tipoComunicacao) {
    //         case EnumTipoComunicacao.Boleto:
    //             const fpPix = fatura.formasPagamentosDisponiveis.find(pagamento => pagamento.tipoComunicacao === EnumTipoComunicacao.Pix)
    //             confirm({
    //                 title: 'IMPORTANTE',
    //                 description: <Box display='flex' flexDirection='column' gridGap={8}>
    //                     <Typography>
    //                         Estamos com problemas na emissão dos boletos, devido o certificado do ITAÚ ter expirado, a equipe responsável está trabalhando para solucionar a pendência o mais breve possível.
    //                     </Typography>
    //                     <Typography>
    //                         Obs. Nenhum cliente será prejudicado, em casos de bloqueios, solicitar liberação provisória no chamado.
    //                     </Typography>
    //                     <Typography>
    //                        <span style={{fontWeight: 500}}>LEMBRETE:</span> Os pagamentos via PIX, podem ser efetivados normalmente, com quitação imediata.
    //                     </Typography>
    //                 </Box>,
    //                 confirmationText: fpPix ? 'Prosseguir com PIX' : 'Entendi',
    //                 cancellationText: 'Fechar'
    //             }).then(() => {
    //                 if(fpPix){
    //                     pagarPix(fpPix)
    //                 }
    //             })
    //             break;
    //         default:
    //             pagarFatura();
    //     }
    // }, [confirm, fatura.formaPagamento.tipoComunicacao, fatura.formasPagamentosDisponiveis, pagarFatura, pagarPix])

    const faturaInfo = useMemo(() => {
        switch (fatura.tipoFatura) {
            case EnumTipoFatura.Cliente:
                return (
                    <ClienteFaturaInfo
                        model={fatura}
                        modelForm={faturaForm}
                        cadForm={cadForm}
                        ref={cadForm}
                        funcoes={{
                            bonificarFatura: bonificarFatura,
                            cancelarFatura: cancelarFatura,
                            reabrirFatura: reabrirFatura,
                            sincronizarERP: sincronizarERP,
                            recalcularFatura: recalcularFatura
                        }}
                        carregando={carregando}
                        handleSubmit={handleSubmit}
                        pagarFatura={pagarFatura}
                        setLiquidarDialog={setLiquidarDialog}
                        setOpenCompetencia={setOpenCompetencia}
                        setPixDialog={setPixDialog}
                        pagarPix={pagarPix}
                    />
                )
            case EnumTipoFatura.Revenda:
                return (
                    <NotasFiscaisRevendaInfo
                        model={fatura}
                        modelForm={faturaForm}
                        ref={cadForm}
                        cadForm={cadForm}
                        funcoes={{
                            bonificarFatura: bonificarFatura,
                            cancelarFatura: cancelarFatura,
                            reabrirFatura: reabrirFatura,
                            preencherTela: preencherTela,
                            sincronizarERP: sincronizarERP,
                            recalcularFatura: recalcularFatura
                        }}
                        carregando={carregando}
                        handleSubmit={handleSubmit}
                        pagarFatura={pagarFatura}
                        setLiquidarDialog={setLiquidarDialog}
                        setOpenCompetencia={setOpenCompetencia}
                        setPixDialog={setPixDialog}
                        pagarPix={pagarPix}
                    />
                )
            default:
                return (
                    <LicençaInfo
                        model={fatura}
                        modelForm={faturaForm}
                        cadForm={cadForm}
                        ref={cadForm}
                        funcoes={{
                            bonificarFatura: bonificarFatura,
                            cancelarFatura: cancelarFatura,
                            reabrirFatura: reabrirFatura,
                            sincronizarERP: sincronizarERP,
                        }}
                        carregando={carregando}
                        handleSubmit={handleSubmit}
                        pagarFatura={pagarFatura}
                        setLiquidarDialog={setLiquidarDialog}
                        setOpenCompetencia={setOpenCompetencia}
                        setPixDialog={setPixDialog}
                        pagarPix={pagarPix}
                    />
                )
        }

    }, [bonificarFatura, cancelarFatura, carregando, fatura, faturaForm, handleSubmit, pagarFatura, pagarPix, preencherTela, reabrirFatura, recalcularFatura, sincronizarERP])

    return (
        <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        }}>
            {carregando && <CircularLoading tipo='FULLSIZED' />}
            <Grid container spacing={1}
                style={{
                    maxWidth: 1400,
                }} className={classes.container}>
                {(fatura.id !== guidEmpty()) && faturaInfo}
                <DialogCancelarFatura
                    openned={openCancelar}
                    closeDialog={() => setOpenCancelar(false)}
                    model={new FaturaCancelamentoModel(id, '')}
                />
                <DialogLiquidar
                    openned={liquidarDialog}
                    closeDialog={() => setLiquidarDialog(false)}
                    loading={carregando}
                    model={faturaForm}
                    preencherTela={preencherTela}
                />
                <DialogEditarCompetencia
                    closeModal={() => setOpenCompetencia(false)}
                    openned={openCompetencia}
                    model={fatura}
                    carregando={carregando}
                    preencherTela={preencherTela}
                />
                <DialogPix
                    closeDialog={() => setPixDialog(prev => ({
                        ...prev,
                        aberto: false
                    }))}
                    openned={pixDialog.aberto}
                    model={fatura}
                    value={pixDialog.pixCopiaCola}
                    pagarPix={pagarPix}
                />
            </Grid>
        </div >
    );
};
