import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    textfieldMeses: {
        width: 150,
        [theme.breakpoints.down('sm')]: {
            width: 75
        }
    },
    textfieldCompetencia: {
        width: 150,
    }
}));