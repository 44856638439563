import { Grid, Typography } from '@material-ui/core';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { toDateString } from 'utils/to-date';
import { CardFilialProps } from './card-filial-props';
import { useStyles } from '../../dialog/dialog-filial/dialog-filial-styles'
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';


export const CardFilial = ({
  model,
  updateList,
  onClick
}: CardFilialProps) => {
  const classes = useDefaultCardStyles();
  const dialogClasses = useStyles();
  
  return (
    <>
      <DefaultCard
        className={dialogClasses.noPosition}
        onClick={() => onClick(model)}
      >
        <div className={classes.cardContent}>
          <Grid container>

          <Grid item xs={12} sm={6} md={6}>
            <Typography color="textPrimary" variant="caption">
              CPF/CNPJ:
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              <b>{formatarCPFCNPJ(model.documento)}</b>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography color="textPrimary" variant="caption">
              Status:
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              <b>{model.status ? 'Ativado' : 'Desativado'}</b>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={2}>
            <Typography color="textPrimary" variant="caption">
              Ativação:
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              <b>{toDateString(model.dataAtivacao)}</b>
            </Typography>
          </Grid>
          {<Grid item xs={6} sm={6} md={2}>
            <Typography color="textPrimary" variant="caption">
              Desativação:
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              <b>{model.dataDesativacao ? toDateString(model.dataDesativacao) : '-'}</b>
            </Typography>
          </Grid>}
          </Grid>
        </div>
      </DefaultCard>
    </>
  );
};
