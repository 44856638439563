import moment from 'moment';

export function toDate(value: any): Date | undefined {
    try {
        return moment(value).isValid() ? moment(value).toDate() : undefined;
    } catch {
        return undefined;
    }
}

export function toDateString(value: any, mask: string = 'DD/MM/yyyy'): string | undefined {
    if (!value) return undefined;
    return moment(value).format(mask).toString();
}

export function toMonthString(value: any, mask: string = 'MM/yyyy'): string | undefined {
    if (!value) return undefined;
    return moment(value).format(mask).toString();
}

// export function toDateStringApi(value: Date) {
//     return value.toISOString().replace(/\.\d{3}Z$/, '');
// }

export function toDateStringApi(data: any) {
    var tzoffset = data.getTimezoneOffset() * 60000;
    var localISOTime = (new Date(data - tzoffset)).toISOString().replace(/\.\d{3}Z$/, '');
    return localISOTime;
  }