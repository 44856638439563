import * as Yup from 'yup';

export const useFormIndicacaoEditValidation = () => {

  const FormIndicacaoValidationYup = Yup.object().shape({
    indicadorId: Yup.string().required('O indicador é obrigatório.').typeError('O indicador é obrigatório.')
});

  return {
    FormIndicacaoValidationYup,
  }
}

