import { useCallback } from "react";
import { PrivatePageHeader } from "views/components/headers";
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { FiltroIcon, MenuIcon } from "views/components/icons";
import { PesquisaPagamento } from "./components/pesquisa-pagamento/pesquisa-pagamento";
import { useMenuPrincipal } from "services/app";
import { useThemeQueries } from "views";
import { PagamentoListSearchProps } from "../pagamento-list/pagamento-list-search-props";
import { useHeaderStyles } from "views/components/headers/header-private-page/header-styles";
import { SetaCimaIcon } from "views/components/icons/seta-cima-icon";

export interface PagamentoHeaderProps {
  openPesquisa: boolean;
  setOpenPesquisa: (state: boolean) => any;
  setSearchProps: (props: PagamentoListSearchProps) => any;
}

export const PagamentoHeader = ({
  openPesquisa,
  setOpenPesquisa,
  setSearchProps,
}: PagamentoHeaderProps) => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile, theme } = useThemeQueries();
  const classes = useHeaderStyles();

  const leftArea = useCallback(
    () =>
      isMobile ? (
        <ButtonPrivateHeader
          icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
          tooltip="Menu"
          onClick={abrirMenu}
        ></ButtonPrivateHeader>
      ) : null,
    [isMobile, abrirMenu]
  );

  const rightArea = useCallback(
    () => (
      isMobile ? (
        <ButtonPrivateHeader
          icon={
            openPesquisa ? (
              <SetaCimaIcon tipo="PRIVATE_HEADER"></SetaCimaIcon>
            ) : (
              <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>
            )
          }
          tooltip={!openPesquisa ? "Pesquisar" : "Fechar Pesquisa"}
          onClick={() => setOpenPesquisa(!openPesquisa)}
        ></ButtonPrivateHeader>
      ) :
        <ButtonPrivateHeader
          className={classes.pesquisaBox}
          text={!openPesquisa ? 'Filtros' : 'Fechar Filtros'}
          icon={
            openPesquisa ? (
              <SetaCimaIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main}></SetaCimaIcon>
            ) : (
              <FiltroIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main}></FiltroIcon>
            )
          }
          tooltip={!openPesquisa ? "Pesquisar" : "Voltar"}
          onClick={() => setOpenPesquisa(!openPesquisa)}
        ></ButtonPrivateHeader>
    ),
    [isMobile, openPesquisa, classes.pesquisaBox, theme.palette.primary.main, setOpenPesquisa]
  );

  const closePesquisaWrapper = useCallback(() => {
    setOpenPesquisa(false);
  }, [setOpenPesquisa]);

  const onPesquisar = useCallback(
    (props: PagamentoListSearchProps) => {
      setSearchProps(props);
    },
    [setSearchProps]
  );

  const pesquisa = (
    <PesquisaPagamento
      isOpened
      onCloseSearch={closePesquisaWrapper}
      onPesquisar={onPesquisar}
    />
  );

  const bottomArea = useCallback(() => (
    <PesquisaPagamento
      isOpened={openPesquisa!}
      onCloseSearch={closePesquisaWrapper}
      onPesquisar={onPesquisar}
    />
  ), [closePesquisaWrapper, onPesquisar, openPesquisa])

  return (
    <>
      <PrivatePageHeader
        title="Formas de Pagamento"
        leftArea={leftArea()}
        bottomArea={bottomArea()}
        rightArea={isMobile ? rightArea() : pesquisa}
      />
    </>
  );
};
