import { EnumCRT } from "model/enums/enum-crt";
import { guidEmpty } from "utils/guid-empty";

export class PessoaInfoModel {
    constructor(
        public id: string = guidEmpty(),
        public pessoaId: string = guidEmpty(),
        public xNome: string = '',
        public email: string = '',
        public telefone: string = '',
        public informacao: string = '',
        public fantasia: string = '',
        public logradouro: string = '',
        public cep: string = '',
        public endereco: string = '',
        public numero: string = '',
        public bairro: string = '',
        public municipio: string = '',
        public codMunicipio: number = 0,
        public uf: string = '',
        public complemento: string = '',
        public isComercial: boolean = false,
        public isJuridico: boolean = false,
        public isFinanceiro: boolean = false,
        public latitude: number = 0,
        public longitude: number = 0,
        public ie: string = '',
        public im: string = '',
        public cnae: string = '',
        public crt: EnumCRT = EnumCRT.NaoSeAplica,
        public segmentoId: string = '',
        public segmento: string = '',
        public obs: string = '',
        public dataAberturaEmpresa: string | null = ''
    ) { }
}