import {
  createStyles,
  Switch,
  SwitchClassKey,
  SwitchProps,
  Theme,
  withStyles,
} from "@material-ui/core";

interface SwitchSaurusStyles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
  switchBaseDark?: string;
  switchBaseLight?: string;
}

interface SwitchSaurusProps extends SwitchProps {
  classes: SwitchSaurusStyles;
  variant: 'DARK' | 'LIGHT'
}

export const SwitchSaurus = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBaseDark: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          background: "#52d869",
          borderColor: "rgba(255,255,255,0.3)",
          opacity: 1,
        },
      },
      "& + $track": {
        background: theme.palette.primary.light,
        borderColor: 'rgba(255,255,255,0.3)',
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    switchBaseLight: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#52d869",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, variant, ...props }: SwitchSaurusProps) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: variant === "DARK" ? classes.switchBaseDark : classes.switchBaseLight,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
