import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    card: {
        boxShadow: '1px 2px 3px 1px #00000030'
    },
    cardBoxContent: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 20
    },
    gridContainer: {
        maxWidth: 800,
        padding: 16,
    },
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    backgroundStatus: {
        padding: 4,
        width: 110,
        textAlign: 'center',
        borderRadius: 4,
        color: '#FFF',
        marginRight: 8
    }
}))