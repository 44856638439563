import { Button, Grid } from "@material-ui/core";
import { useGetPessoaInfo, usePostAdicionarPessoaInfo, usePutAtualizarPessoaInfo } from "data/api/gestao/pessoa-info";
import { isEmpty } from "lodash";
import { PessoaInfoModel } from "model/api/gestao/pessoa/pessoa-info-model";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { useCallback, useEffect, useState } from "react";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { guidEmpty } from "utils/guid-empty";
import { CircularLoading } from "views/components";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus";
import { DialogPessoaInfo } from "views/components/dialog/dialog-pessoa-info/dialog-pessoa-info";
import { NovoIcon } from "views/components/icons";
import { Paginacao } from "views/components/paginacao";
import { useThemeQueries } from "views/theme";
import { AccordionClienteInformacoesListData } from "./accordion-cliente-informacoes-list-data";

type Props = {
  id: string;
  tipoPessoa: EnumTipoPessoas;
  atualizar?: boolean;
  setAtualizar?: (valor: boolean) => void;
  hideButton?: boolean;
}

export const AccordionClienteInformacoesList = ({ id, atualizar, setAtualizar, hideButton, tipoPessoa, ...props }: Props) => {
  const { theme } = useThemeQueries()
  const [openAccordion, setOpenAccordion] = useState<boolean>(false)
  const [modelInfo, setModelInfo] = useState<PessoaInfoModel>(new PessoaInfoModel())
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const { showToast } = useToastSaurus()

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<PessoaInfoModel>(),
  });

  const { getPessoaInfo, carregando: carregandoGet } = useGetPessoaInfo()
  const { postAdicionarPessoaInfo, carregando: carregandoPost } = usePostAdicionarPessoaInfo()
  const { putAtualizarPessoaInfo, carregando: carregandoPut } = usePutAtualizarPessoaInfo()

  const {tipoUsuario, usuario} = useSessaoAtual();

  const carregando = carregandoGet || carregandoPost || carregandoPut

  const onClickDialog = useCallback((model: PessoaInfoModel) => {
    setModelInfo(model)
    setOpenDialog(true)
  }, [])

  const fillResult = useCallback(
    (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<PessoaInfoModel>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    []
  );

  const search = useCallback(
    async (newPage: number) => {
      try {
        const res = await getPessoaInfo(id, newPage, `pageSize=5`);
        if (res.erro) throw res.erro;

        if (!res.resultado) {
          return;
        }

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          await search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list
        );

        return res;
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [getPessoaInfo, id, fillResult, showToast]
  );

  const saveNewInfo = useCallback(async (model: PessoaInfoModel) => {
    const res = await postAdicionarPessoaInfo(id, model)

    if (res.erro) throw res.erro

    showToast('success', 'Nova Informação Adicionada!')
  }, [id, postAdicionarPessoaInfo, showToast])

  const saveChangeInfo = useCallback(async (model: PessoaInfoModel) => {
    const res = await putAtualizarPessoaInfo(model, id)

    if (res.erro) throw res.erro

    showToast('success', 'Informação Atualizada!')
  }, [id, putAtualizarPessoaInfo, showToast])

  const handleSubmit = useCallback(async (model: PessoaInfoModel) => {
    try {
      if (model.id === guidEmpty()) {
        await saveNewInfo(model)
        return true
      }

      await saveChangeInfo(model)

      return true
    }
    catch (err: any) {
      showToast('error', err.message)

      return false
    }
  }, [saveChangeInfo, saveNewInfo, showToast])

  useEffect(() => {
    if (openAccordion && !openDialog) {
      search(queryStatus.page)
    }
  }, [openAccordion, openDialog, queryStatus.page, search, showToast])

  useEffect(() => {
    if (openAccordion && atualizar) {
      search(queryStatus.page)
      setAtualizar && setAtualizar(false)
    } else {
      setAtualizar && setAtualizar(false)
    }
  }, [atualizar, openAccordion, openDialog, queryStatus.page, search, setAtualizar, showToast])

  const onCardSelected = useCallback((model: PessoaInfoModel) => {
    onClickDialog(model)
  }, [onClickDialog])

  const onClickAdicionar = useCallback(() => {
    const novaInfo = new PessoaInfoModel()
    const isJuridico = isEmpty(queryStatus.list.find(item => item.isJuridico))
    const isComercial = isEmpty(queryStatus.list.find(item => item.isComercial))
    const isFinanceiro = isEmpty(queryStatus.list.find(item => item.isFinanceiro))
    onClickDialog({...novaInfo, isJuridico, isComercial, isFinanceiro})

  }, [onClickDialog, queryStatus.list])

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        await search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  const isFuncionario = tipoUsuario() === EnumTipoPessoas.Funcionario || tipoUsuario() === EnumTipoPessoas.FuncionarioSoftwareHouse

  return (
    <>
      <AccordionSaurus
        labelPrimary="Informações"
        tipoExpand="bold"
        noPaperRoot={false}
        heightDivider={2}
        showDivider={openAccordion}
        colorDivider={theme.palette.primary.main}
        colorExpand={theme.palette.primary.main}
        expanded={openAccordion}
        onChange={() => setOpenAccordion(prev => !prev)}
      >
        <Grid container spacing={1} style={{
          position: 'relative',
        }}>
          {carregando && <CircularLoading tipo="FULLSIZED" />}
          
          <Grid item xs={12}>
            <Paginacao
              pageChanged={pageChanged}
              totalPages={queryStatus.totalPages}
              totalRegisters={queryStatus.totalResults}
              currentPage={queryStatus.page}
            />
          </Grid>
          <Grid item xs={12}>
            <AccordionClienteInformacoesListData
              list={queryStatus.list}
              carregando={carregando}
              onCardSelected={onCardSelected}
              onCardChecked={() => { }}
              selectedList={[]}
            />
          </Grid>
          {((isFuncionario ? usuario?.Id === id : true) && !hideButton) && <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={5} lg={4}>
                <Button variant="contained" color='primary' fullWidth
                onClick={onClickAdicionar}>
                  <NovoIcon tipo='BUTTON_PRIMARY'/>
                  NOVA INFORMAÇÃO
                </Button>
              </Grid>
            </Grid>
          </Grid>}
        </Grid>
      </AccordionSaurus>
      <DialogPessoaInfo
        openned={openDialog}
        model={modelInfo}
        carregando={carregando}
        enviarModel={async model => await handleSubmit(model)}
        closeModal={() => setOpenDialog(false)}
        esconderAbertura={true}
        pessoaFisica={(
          tipoPessoa !== EnumTipoPessoas.Revendedor &&
          tipoPessoa !== EnumTipoPessoas.Cliente &&
          tipoPessoa !== EnumTipoPessoas.SoftwareHouse &&
          tipoPessoa !== EnumTipoPessoas.Representante
        )}
      />
    </>
  );
};
