import { Radio, withStyles } from '@material-ui/core';

interface SaurusRadioProps{
  size?: 'medium' | 'small';
}

export const RadioSaurus = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))((props: SaurusRadioProps & any) => <Radio color="default" {...props} />);
