import { guidEmpty } from "utils/guid-empty";
import { PessoasModel } from "../pessoa/pessoas-model";
import { EnumStatusIndicacao } from "model/enums/enum-status-indicacao";

export class IndicacoesInteracoesModel {
    constructor(
        public id: string = guidEmpty(),
        public indicacaoId: string | null = null,
        public responsavel: PessoasModel = new PessoasModel(),
        public tipoInteracao: EnumStatusIndicacao = 0,
        public descricao: string = '',
        public dataHora: string = '',
        public dataInteracao: string = ''
    ) { }
}