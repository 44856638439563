import { Button, Grid } from '@material-ui/core';
import { useImperativeHandle, forwardRef, useMemo } from 'react';
import { CircularLoading, makeUtilClasses } from 'views';
import {
    DefaultFormRefs,
    DefaultFormProps,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { picker } from 'utils/picker';
import { RelatorioSearchPropsModel } from 'model/api/gestao/relatorios/relatorio-search-props-model';
import { AutocompleteRevendas } from 'views/components/controles/autocompletes/autocomplete-revendas/autocomplete-revendas';
import { AutocompleteGerentes } from 'views/components/controles/autocompletes/autocomplete-gerentes/autocomplete-gerentes';
import { useFiltroRelatorioValidation } from './form-filtro-relatorio-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { KeyValueModel } from 'model';
import { competenciaMesAnterior, subtrairCompetencia } from 'utils/converter-competencia';
import { useSessaoAtual } from 'services/app';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';

export const FormFiltroRelatorio = forwardRef<
    DefaultFormRefs<RelatorioSearchPropsModel>,
    DefaultFormProps<RelatorioSearchPropsModel>
>(({ loading, ...props }: DefaultFormProps<RelatorioSearchPropsModel>, ref) => {
    const utilClasses = makeUtilClasses();
    const { FiltroRelatorioValidation } = useFiltroRelatorioValidation();

    const { tipoUsuario } = useSessaoAtual();

    const isAllowed = tipoUsuario() === EnumTipoPessoas.SoftwareHouse || tipoUsuario() === EnumTipoPessoas.GerenteComercial

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        getValues,
        setValue,
    } = useForm<RelatorioSearchPropsModel>({
        criteriaMode: 'all',
        mode: 'onChange',
        resolver: yupResolver(FiltroRelatorioValidation)
    });

    const onSubmit = async (values: RelatorioSearchPropsModel) => {
        const model = picker<RelatorioSearchPropsModel>(values, new RelatorioSearchPropsModel());
        props.onSubmit(model);
    };

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        },
        resetForm: () => {
        },
        fillForm: (model: RelatorioSearchPropsModel) => {
            reset({ ...model });
        },
    }));

    const selectDataMock = useMemo<KeyValueModel[]>(() => {
        const competenciaAtual = competenciaMesAnterior();
        const mock = [
            new KeyValueModel(subtrairCompetencia(competenciaAtual, 2), '3 Meses'),
            new KeyValueModel(subtrairCompetencia(competenciaAtual, 5), '6 Meses'),
            new KeyValueModel(subtrairCompetencia(competenciaAtual, 12), '1 Ano'),
            new KeyValueModel(subtrairCompetencia(competenciaAtual, 24), '2 Anos'),
            new KeyValueModel(subtrairCompetencia(competenciaAtual, 36), '3 Anos'),
            new KeyValueModel(subtrairCompetencia(competenciaAtual, 48), '4 Anos'),
            new KeyValueModel(subtrairCompetencia(competenciaAtual, 60), '5 Anos'),
            new KeyValueModel(subtrairCompetencia(competenciaAtual, 72), '6 Anos'),
            new KeyValueModel(subtrairCompetencia(competenciaAtual, 84), '7 Anos'),
            new KeyValueModel(subtrairCompetencia(competenciaAtual, 96), '8 Anos'),
            new KeyValueModel(subtrairCompetencia(competenciaAtual, 108), '9 Anos'),
            new KeyValueModel(subtrairCompetencia(competenciaAtual, 120), '10 Anos'),
        ]

        setValue('anoMesInicial', mock[0].Key)

        return mock
    }, [setValue])

    return (
        <>
            <div className={utilClasses.formContainer}>
                {loading && props.showLoading ? (
                    <CircularLoading tipo="FULLSIZED" />
                ) : null}
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={loading ? utilClasses.controlLoading : ''}
                >
                    <Grid container style={{ maxWidth: '100%' }} spacing={2}>
                        <Grid item xs={6} md={3}>
                            <Controller
                                name="anoMesInicial"
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        size='small'
                                        fullWidth
                                        conteudo={selectDataMock}
                                        allowSubmit={true}
                                        disabled={loading}
                                        label="Período"
                                        variant="outlined"
                                        error={Boolean(errors.anoMesInicial && errors.anoMesInicial.message)}
                                        helperText={
                                            errors.anoMesInicial
                                                ? errors.anoMesInicial?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={(e: any) => {
                                            const item = selectDataMock.find(element => element.Key === e.target.value)
                                            if (item) {
                                                setValue('anoMesInicial', item.Key)
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        {isAllowed && <>
                            <Grid item xs={6} md={4}>
                                <Controller
                                    name="revendaId"
                                    control={control}
                                    render={({ field }) => (
                                        <AutocompleteRevendas
                                            label='Revendedor'
                                            size='small'
                                            value={getValues('revendaId') || undefined}
                                            fetchOnLoad
                                            onChange={(e) => {
                                                setValue('revendaId', e.value.id)
                                            }}
                                            helperText={
                                                errors.revendaId
                                                    ? errors.revendaId.message
                                                    : undefined
                                            }
                                            error={Boolean(errors.revendaId && errors.revendaId.message)}
                                        />
                                    )}
                                />
                            </Grid>
                            {tipoUsuario() === EnumTipoPessoas.SoftwareHouse && <Grid item xs={6} md={4}>
                                <Controller
                                    name="gerenteId"
                                    control={control}
                                    render={() => (
                                        <AutocompleteGerentes
                                            label='Gerente Comercial'
                                            size='small'
                                            value={getValues('gerenteId') || undefined}
                                            onChange={(e) => {
                                                setValue('gerenteId', e.value.id)
                                            }}
                                            helperText={
                                                errors.gerenteId
                                                    ? errors.gerenteId.message
                                                    : undefined
                                            }
                                            error={Boolean(errors.gerenteId && errors.gerenteId.message)}
                                        />
                                    )}
                                />
                            </Grid>}
                        </>}
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </>
    );
});