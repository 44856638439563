import { guidEmpty } from "utils/guid-empty";

export class ComissaoPutModel {
    constructor(
        public id: string = guidEmpty(),
        public valorTotal: number = 0,
        public observacao: string = '',
        public comissionadoId: string = '',
        public dataPagamento: string = '',
        public contabilizaRelatorios: boolean = false,
    ) { }
}
