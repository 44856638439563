import { RelatorioSistemaModel } from 'model/api/gestao/relatorios/sistema-relatorio-model';
import { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useThemeQueries } from 'views/theme';

type Props = {
    list: RelatorioSistemaModel[]
    ativos: boolean;
}

export default function ChartSistemas({list, ativos}: Props) {

    const {xs} = useThemeQueries()

    const chartOptions = useMemo(() => {
        const labels = list.map(sistema => sistema.sistema)
        const count = list.map(sistema => ativos ? sistema.ativos : sistema.inativos)
        return {
            options: {
                labels
            },
            series: count
        }
    }, [ativos, list])

  return (
    <Chart options={chartOptions.options} series={chartOptions.series} type='donut' width={xs ? 350 : 400}/>

  )
}