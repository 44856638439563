import { VariaveisAmbiente } from 'config';
import { ParametroSistemaModel } from 'model/api/gestao/parametro-sistema/parametro-sistema-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePutAtualizarParametroSistema() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putAtualizarParametroSistema = useCallback(
        (sistemaId: string, parametro: ParametroSistemaModel) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/sistema/${sistemaId}/parametro`,
                method: 'PUT',
                data: parametro,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putAtualizarParametroSistema,
    };
}
