import { forwardRef, useCallback, useImperativeHandle, useMemo } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { useFormFaturaValidation } from './form-fatura-validation';
import {
  TextFieldSaurus,

} from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { picker } from 'utils/picker';
import { FaturaFormModel } from 'model/app/forms/fatura/fatura-form-model';
import { toDateString } from 'utils/to-date';
import { KeyValueModel } from 'model';
import { isEmpty, isEqual, toString } from 'lodash';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { toDecimal } from 'utils/to-decimal';
import { PagamentoModel } from 'model/api/gestao/pagamento/pagamento-model';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { EnumTipoFatura } from 'model/enums/enum-tipo-fatura';
import { useStyles } from './form-fatura-edicao-styles';

interface FaturaRefs extends DefaultFormRefs<FaturaFormModel> {
  validate(): Promise<boolean>;
  isFormEqual(form: FaturaFormModel): boolean
}

interface FaturaProps extends DefaultFormProps<FaturaFormModel> {
  block?: boolean;
  valorOriginal: number;
  formaPagamento: PagamentoModel;
  tipoFatura: EnumTipoFatura;
}


export const FormFaturaEdicao = forwardRef<
  DefaultFormRefs<FaturaFormModel> | FaturaRefs,
  FaturaProps
>(
  (
    { loading, onSubmit, block, tipoFatura, ...props }: FaturaProps,
    ref,
  ) => {
    const utilClasses = makeUtilClasses();
    const classes = useStyles();
    const { FormFaturaValidationYup } = useFormFaturaValidation()

    const { showToast } = useToastSaurus()
    const { tipoUsuario } = useSessaoAtual()

    const carregando = loading

    const model = useMemo(() => (
      props.model || new FaturaFormModel()
    ), [props.model])

    // const temDesconto = model.valorOriginal !== model.valor && tipoFatura === EnumTipoFatura.Revenda

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
      getValues,
      setValue,
      trigger,
    } = useForm<FaturaFormModel>({
      defaultValues: { ...model, dataVencimento: toDateString(model.dataVencimento, 'yyyy-MM-DD') ?? '' },
      resolver: yupResolver(FormFaturaValidationYup),
      criteriaMode: 'all',
      mode: 'onBlur',
    });

    const pagamentoMock = useCallback(() => {
      if (isEmpty(model.formasPagamentosDisponiveis)) {
        return []
      }
      return model.formasPagamentosDisponiveis.map(pagamento => new KeyValueModel(pagamento.id, pagamento.nome))
    }, [model])

    const submit = (values: FaturaFormModel) => {
      const pagamento = picker<FaturaFormModel>(values, new FaturaFormModel())
      onSubmit(pagamento, model);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submit)();
      },
      resetForm: () => {
        reset({ ...model });
      },
      fillForm: (values: FaturaFormModel) => {
        values.dataVencimento = toDateString(values.dataVencimento, 'yyyy-MM-DD') ?? ''
        reset({ ...values })
      },
      validate: async () => {
        await trigger(['nossoNumero', 'dataVencimento', 'formaPagamentoId', 'valor'])
        return Object.values(errors).reduce((prev, curr) => {
          if (curr) {
            showToast('error', toString(curr.message) || 'Erro do formulário')
          }
          return curr || prev ? true : false
        }, false)
      },
      isFormEqual(newModel: FaturaFormModel) {
        newModel.formasPagamentosDisponiveis = []
        const currentModel = new FaturaFormModel(
          model.id,
          getValues('nossoNumero'),
          getValues('dataVencimento') + 'T00:00:00',
          getValues('formaPagamentoId'),
          getValues('valorCusto'),
          getValues('encargo'),
          getValues('multaJuros'),
          getValues('valor'),
          getValues('valorOriginal'),
          getValues('formasPagamentosDisponiveis')
        )
        currentModel.formasPagamentosDisponiveis = []
        const retorno = Object.values(currentModel).reduce((prev, curr, i) => isEqual(Object.values(newModel)[i], curr) && prev ? true : false)
        return retorno
      },
    }));

    const calcularEncargo = useCallback((e: any) => {
      if (toDecimal(e.target.value) > 100000000) {
        return
      }
      setValue(e.target.name, e.target.value)
      const multa = toDecimal(getValues('multaJuros'))
      const encargo = toDecimal(e.target.value)
      setValue('valor', (model.valor - (model.multaJuros + model.encargo)) + encargo + multa)
    }, [getValues, model.encargo, model.multaJuros, model.valor, setValue])

    const calcularMulta = useCallback((e: any) => {
      if (toDecimal(e.target.value) > 100000000) {
        return
      }
      setValue(e.target.name, e.target.value)
      const multa = toDecimal(e.target.value)
      const encargo = toDecimal(getValues('encargo'))
      setValue('valor', (model.valor - (model.multaJuros + model.encargo)) + multa + encargo)
    }, [getValues, model.encargo, model.multaJuros, model.valor, setValue])


    return (
      <>
        <Box mb={2}>
          <div className={utilClasses.formContainer}>
            <form
              onSubmit={handleSubmit(submit)}
              className={carregando ? utilClasses.controlLoading : ''}
            >
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      name="nossoNumero"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          disabled={carregando}
                          size='small'
                          allowSubmit={false}
                          label="Número da Fatura"
                          tipo='NUMERO'
                          fullWidth
                          readOnly={block}
                          autoComplete={'off'}
                          helperText={
                            touchedFields.nossoNumero && errors.nossoNumero
                              ? errors.nossoNumero.message
                              : undefined
                          }
                          error={Boolean(errors.nossoNumero && errors.nossoNumero.message)}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="formaPagamentoId"
                      control={control}
                      render={({ field }) => (
                        <SelectSaurus
                          variant='outlined'
                          size='small'
                          disabled={block || pagamentoMock().length < 1}
                          label="Forma de Pagamento"
                          allowSubmit
                          fullWidth
                          conteudo={pagamentoMock() || []}
                          autoComplete={'off'}
                          helperText={
                            touchedFields.formaPagamentoId && errors.formaPagamentoId
                              ? errors.formaPagamentoId.message
                              : undefined
                          }
                          error={Boolean(
                            errors.formaPagamentoId && errors.formaPagamentoId.message,
                          )}
                          {...field}
                          value={getValues('formaPagamentoId')}
                          onChange={ev => {
                            const item =
                              pagamentoMock()
                                .filter(item => item.Key === ev.target.value)

                            setValue('formaPagamentoId', item[0].Key)
                          }}

                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="dataVencimento"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          disabled={carregando}
                          size='small'
                          readOnly={block}
                          label="Data de Vencimento"
                          tipo="DATA"
                          allowSubmit
                          fullWidth
                          autoComplete={'off'}
                          helperText={
                            touchedFields.dataVencimento && errors.dataVencimento
                              ? errors.dataVencimento.message
                              : undefined
                          }
                          error={Boolean(
                            errors.dataVencimento && errors.dataVencimento.message,
                          )}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldSaurus
                      value={props.valorOriginal}
                      readOnly
                      label='Valor Inicial'
                      tipo='DECIMAL'
                      showStartAdornment
                      formatarMilhares
                      size='small'
                    />
                  </Grid>
                  {(tipoUsuario() !== EnumTipoPessoas.FuncionarioFinanceiroSemCusto && tipoFatura !== EnumTipoFatura.Revenda) && <Grid item xs={6}>
                    <Controller
                      name="valorCusto"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          disabled={carregando}
                          size='small'
                          label="Custo"
                          tipo="DECIMAL"
                          showStartAdornment
                          formatarMilhares
                          allowSubmit
                          manterMascara
                          readOnly={block}
                          fullWidth
                          autoComplete={'off'}
                          helperText={
                            touchedFields.valorCusto && errors.valorCusto
                              ? errors.valorCusto.message
                              : undefined
                          }
                          error={Boolean(
                            errors.valorCusto && errors.valorCusto.message,
                          )}
                          {...field}
                          onChange={(e: any) => {
                            if (toDecimal(e.target.value) > 100000000) {
                              return
                            }
                            setValue('valorCusto', e.target.value)
                          }}
                        />
                      )}
                    />
                  </Grid>}
                  <Grid item xs={6}>
                    <Controller
                      name="encargo"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          disabled={carregando}
                          size='small'
                          readOnly={block}
                          label="Encargo"
                          tipo="DECIMAL"
                          showStartAdornment
                          formatarMilhares
                          manterMascara
                          allowSubmit
                          fullWidth
                          positivo
                          autoComplete={'off'}
                          helperText={
                            touchedFields.encargo && errors.encargo
                              ? errors.encargo.message
                              : undefined
                          }
                          error={Boolean(
                            errors.encargo && errors.encargo.message,
                          )}
                          {...field}
                          onChange={calcularEncargo}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="multaJuros"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          disabled={carregando}
                          size='small'
                          readOnly={block}
                          className={classes.descontoLabel}
                          label="Multa e Juros / Desconto"
                          formatarMilhares
                          tipo="DECIMAL"
                          showStartAdornment
                          manterMascara
                          allowSubmit
                          fullWidth
                          autoComplete={'off'}
                          helperText={
                            touchedFields.multaJuros && errors.multaJuros
                              ? errors.multaJuros.message
                              : undefined
                          }
                          error={Boolean(
                            errors.multaJuros && errors.multaJuros.message,
                          )}
                          {...field}
                          onChange={calcularMulta}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={tipoFatura === EnumTipoFatura.Cliente ? 12 : 6}>
                    <Controller
                      name="valor"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          disabled={carregando}
                          size='small'
                          readOnly={block}
                          label='Valor Total'
                          tipo="DECIMAL"
                          showStartAdornment
                          formatarMilhares
                          allowSubmit
                          manterMascara
                          fullWidth
                          autoComplete={'off'}
                          helperText={
                            touchedFields.valor && errors.valor
                              ? errors.valor.message
                              : undefined
                          }
                          error={Boolean(
                            errors.valor && errors.valor.message,
                          )}
                          {...field}
                          onChange={(e: any) => {
                            if (toDecimal(e.target.value) > 100000000) {
                              return
                            }
                            if (toDecimal(e.target.value) !== props.valorOriginal) {
                              const encargos = toDecimal(getValues('encargo'))
                              setValue('multaJuros', toDecimal(e.target.value) - props.valorOriginal - encargos)
                            } else {
                              setValue('multaJuros', 0)
                              setValue('encargo', 0)
                            }
                            setValue('valor', e.target.value)
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
