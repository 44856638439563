import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetTerminalExcel() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getTerminalExcel = useCallback(
        (newPage: number, query?: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/terminal/excel?page=${newPage}&${query}`,
                method: 'GET',
                enviarTokenUsuario: true,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/vnd.ms-excel",
                    "Content-Disposition": "attachment; filename=terminais.xls; filename*=UTF-8''terminais.xls "
                },
                timeout: 60000
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getTerminalExcel,
    };
}