import { Button, Typography } from '@material-ui/core';
import { PessoasMapaModel } from 'model/api/gestao/pessoa/pessoa-mapa-model';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NovoIcon } from 'views/components/icons';
import { useStyles } from '../../mapa-cliente.styles';
import { InfoWindow, Marker } from '@react-google-maps/api';
import { useSessaoAtual } from 'services/app';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import PinPadrao from '../../../../../../assets/img/pin-padrao.png'
import { isEmpty } from 'lodash';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';

type MapMarkerProps = {
  lat: number,
  lng: number
  pessoa: PessoasMapaModel;
  isRevendedor: boolean;
};

export default function MapMarker(props: MapMarkerProps) {
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const history = useHistory();
  const classes = useStyles();
  const { tipoUsuario } = useSessaoAtual()

  const imagemBase64 = useCallback((imagem: string) => {
    try {
      return atob(imagem);
    } catch (e: any) {
      return imagem;
    }
  }, []);

  return (
    <>
      <Marker position={{
        lat: props.lat,
        lng: props.lng
      }} onClick={() => setShowInfo(current => !current)} icon={{
        url: isEmpty(props.pessoa.urlImagem) ? PinPadrao : imagemBase64(props.pessoa.urlImagem),
        scaledSize: new google.maps.Size(40, 50)
      }}/>
      {showInfo && <InfoWindow
        position={{
          lat: props.lat + 0.00004,
          lng: props.lng
        }}
        onCloseClick={() => setShowInfo(false)}
      >
        <div className={classes.infoWindow}>
          <ItemInfo label="Nome" value={props.pessoa.pessoaInfo.xNome} />
          <ItemInfo label='CPF/CNPJ' value={formatarCPFCNPJ(props.pessoa.documento)}/>
          <ItemInfo label="E-mail" value={props.pessoa.pessoaInfo.email} />
          <ItemInfo
            label="Nome Fantasia"
            value={props.pessoa.pessoaInfo.fantasia}
          />
          <ItemInfo label="Telefone" value={props.pessoa.pessoaInfo.telefone} />
          <ItemInfo
            label="Endereço"
            value={
              props.pessoa.pessoaInfo.logradouro +
              ', ' +
              props.pessoa.pessoaInfo.numero
            }
          />
          <ItemInfo
            label="Município - UF"
            value={
              props.pessoa.pessoaInfo.municipio +
              ' - ' +
              props.pessoa.pessoaInfo.uf
            }
          />
          {(!props.isRevendedor && (tipoUsuario() !== EnumTipoPessoas.Revendedor)) && (
            <>
              <ItemInfo
              label='Revendedor'
              value={props.pessoa.revendaNome}
            />
            <ItemInfo
              label='CPF/CNPJ Revendedor'
              value={formatarCPFCNPJ(props.pessoa.revendaDocumento)}
            />
            </>
          )}
          <Button
            variant="outlined"
            color="primary"
            style={{ alignSelf: 'center', marginTop: '5px' }}
            onClick={() =>
              history.push({
                pathname: (props.isRevendedor ? '/revendedor/' : '/clientes/') + props.pessoa.pessoaInfo.pessoaId,
                state: {
                  from: {
                    pathname: history.location.pathname
                  }
                }
              })
            }
          >
            <NovoIcon tipo="BUTTON" />
            Ver Mais
          </Button>
        </div>
      </InfoWindow>}
    </>
  );
}

type ItemInfoProps = {
  label: string;
  value: string;
};

const ItemInfo = ({ label, value }: ItemInfoProps) => {
  return (
    <div>
      <Typography variant="body1" display="inline" style={{ fontWeight: 600 }}>
        {label}:{' '}
      </Typography>
      <Typography variant="body1" display="inline">
        {value}
      </Typography>
    </div>
  );
};
