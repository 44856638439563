import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    acoes: {
        flex: "0 0 auto",
        padding: theme.spacing(2),
    },
    containerFinal: {
        display: "flex",
        justifyContent: "space-between",

    },
    btnPesquisa: {
        marginLeft: theme.spacing(1),
        width: "40px",
        minWidth: "40px",
        height: "40px",
        padding: 0,
        "& svg": {
            margin: 0,
        }
    }
}));