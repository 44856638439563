import { PesquisaHeaderProps } from './pesquisa-sistemas-props';
import { useStyles } from './pesquisa-sistemas-styles';
import { Button, Fade, Grid, useTheme } from '@material-ui/core';

import { useState, useCallback, ChangeEvent, useRef, useEffect } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { ProcurarIcon } from 'views/components/icons';
import { useThemeQueries } from 'views';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { SituacaoSistemaMockTodos } from 'data/mocks/situacao-sistema-mock';
import { EnumSituacaoSistema } from 'model/enums/enum-situacao-sistema';

export const PesquisaSistemas = (props: PesquisaHeaderProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const [termoPesquisar, setTermoPesquisar] = useState('');
  const [situacaoPesquisar, setSituacaoPesquisar] = useState(EnumSituacaoSistema.Ativo);
  const refInput = useRef<HTMLDivElement | null>(null);
  const { isMobile } = useThemeQueries();

  //FOCO AO FICAR VISIVEL
  useEffect(() => {
    if (props.isOpened && !isMobile) refInput.current?.focus();
    else {
      setTermoPesquisar('');
    }
  }, [isMobile, props.isOpened]);

  //CHAMADA DO PESQUISAR
  const pesquisar = useCallback(
    (event: any) => {
      event.preventDefault();
      props.onPesquisar({ termo: termoPesquisar, situacao: situacaoPesquisar });
    },
    [props, situacaoPesquisar, termoPesquisar],
  );

  //ALTERAR A PESQUISA
  const onChangePesquisa = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTermoPesquisar(event.target.value);
    },
    [],
  );

  return (
    <Fade in={props.isOpened} mountOnEnter unmountOnExit>
      <form onSubmit={pesquisar}>
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={12} sm={4}>
            <SelectSaurus
              conteudo={SituacaoSistemaMockTodos}
              value={situacaoPesquisar}
              size="small"
              fullWidth
              onChange={ev => {
                const item =
                  SituacaoSistemaMockTodos
                    .filter(item => item.Key === ev.target.value)

                setSituacaoPesquisar(item[0].Key)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <div className={classes.containerFinal}>
              <TextFieldSaurus
                inputRef={refInput}
                fullWidth
                size="small"
                allowSubmit={true}
                value={termoPesquisar}
                placeholder="Digite o nome do sistema que deseja buscar..."
                onChange={onChangePesquisa}
                adornmentColor={theme.palette.text.disabled}
              />
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.btnPesquisa}
                fullWidth={true}
              >
                <ProcurarIcon tipo="BUTTON_PRIMARY" />
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </Fade>
  );
};
