import { PesquisaHeaderProps } from './pesquisa-planos-props';
import { useStyles } from './pesquisa-planos-styles';
import { Button, Grid, Fade, useTheme } from '@material-ui/core';
import { useState, useCallback, ChangeEvent, useRef, useEffect } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { ProcurarIcon } from 'views/components/icons';
import { useThemeQueries } from 'views';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { SituacaoPlanoMockTodos } from 'data/mocks/situacao-plano-mock';

export const PesquisaPlanos = (props: PesquisaHeaderProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { isMobile } = useThemeQueries();
  const [termoPesquisar, setTermoPesquisar] = useState('');
  const refInput = useRef<HTMLDivElement | null>(null);

  const [situacao, setSituacao] = useState<number>(-1)

  //FOCO AO FICAR VISIVEL
  useEffect(() => {
    if (props.isOpened && !isMobile) refInput.current?.focus();
    else {
      setTermoPesquisar('');
    }
  }, [isMobile, props.isOpened]);

  //CHAMADA DO PESQUISAR
  const pesquisar = useCallback(
    (event: any) => {
      event.preventDefault();
      props.onPesquisar({ termo: termoPesquisar, situacao });
    },
    [props, situacao, termoPesquisar],
  );

  //ALTERAR A PESQUISA
  const onChangePesquisa = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTermoPesquisar(event.target.value);
    },
    [],
  );

  return (
    <Fade in={props.isOpened} mountOnEnter unmountOnExit>
      <form onSubmit={pesquisar}>
        <Grid container className={classes.root} spacing={1}>
        <Grid item xs={4}>
            <div className={classes.containerFinal}>
              <SelectSaurus
              size='small'
                conteudo={SituacaoPlanoMockTodos}
                value={situacao}
                onChange={(e) => {
                  const item = SituacaoPlanoMockTodos.find(x => x.Key === e.target.value)
                  if(item)
                    setSituacao(item.Key)
                }}
              />
            </div>
          </Grid>
          <Grid item xs={8}>
            <div className={classes.containerFinal}>
              <TextFieldSaurus
                inputRef={refInput}
                fullWidth
                size="small"
                allowSubmit={true}
                value={termoPesquisar}
                placeholder="Digite o nome do plano que deseja buscar..."
                onChange={onChangePesquisa}
                adornmentColor={theme.palette.text.disabled}
              />
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.btnPesquisa}
                fullWidth={true}
              >
                <ProcurarIcon tipo="BUTTON_PRIMARY" />
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </Fade>
  );
};
