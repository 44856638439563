import { VariaveisAmbiente } from 'config';
import { ApiListModel } from 'model/api/gestao/api-list-model/api-list-model';
import { ContratoModel } from 'model/api/gestao/contrato/contrato-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetContratos() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ApiListModel<ContratoModel>>();

    const getContratos = useCallback(
        async (pessoaId: string, page: number, queryBusca: string) => {
            let queryPage = "";
            if (page) {
                queryPage = "page=" + page.toString();
            }
            const queryFinal = `${queryPage?.length > 0 || queryBusca?.length > 0 ? "?" : ""
                }${queryPage}${queryPage.length > 0 ? "&" : ""}${queryBusca}`;

            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/pessoas/${pessoaId}/Contrato${queryFinal}`,
                method: 'GET',
                enviarTokenUsuario: true
            })
        }
        , [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getContratos,
    };
}
