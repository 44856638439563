import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { useFormPessoaSenhaValidation } from './form-pessoa-senha-validation';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  TextFieldSaurus,

} from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { picker } from 'utils/picker';
import { PessoaSenhaModel } from 'model/api/gestao/pessoa/pessoa-senha-model';

interface PessoaFormModelProps extends DefaultFormProps<PessoaSenhaModel> {

}


export const FormPessoaSenha = forwardRef<
  DefaultFormRefs<PessoaSenhaModel>,
  PessoaFormModelProps
>(
  (
    { loading, onSubmit, ...props }: PessoaFormModelProps,
    ref,
  ) => {
    const { isMobile } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);
    const { FormPessoaSenhaValidationYup } = useFormPessoaSenhaValidation()
    const [modelForm, setModelForm] = useState<PessoaSenhaModel>(new PessoaSenhaModel())


    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
    } = useForm<PessoaSenhaModel>({
      defaultValues: { ...modelForm },
      resolver: yupResolver(FormPessoaSenhaValidationYup),
      criteriaMode: 'all',
      mode: 'onBlur' && 'onChange',
    });

    const carregando = loading

    const submitEnviar = (values: PessoaSenhaModel) => {
      const senha = picker<PessoaSenhaModel>(values, modelForm)
      onSubmit(senha, modelForm);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitEnviar)();
      },
      resetForm: () => {
        reset();
        if (!isMobile) refInputNome.current?.focus();
      },
      fillForm: (model: PessoaSenhaModel) => {
        setModelForm({ ...model })
        reset({ ...model });
        if (!isMobile) refInputNome.current?.focus();
      },
    }));

    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {carregando && props.showLoading && (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="FULLSIZED" />
              </div>
            )}

            <form
              onSubmit={handleSubmit(submitEnviar)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <Controller
                    name="novaSenha"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={loading}
                        allowSubmit={false}
                        tipo="PASSWORD"
                        label="Nova Senha"
                        showPasswordVisibilityButton
                        fullWidth
                        autoComplete={'off'}
                        helperText={
                          touchedFields.novaSenha && errors.novaSenha
                            ? errors.novaSenha.message
                            : undefined
                        }
                        error={Boolean(errors.novaSenha && errors.novaSenha.message)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="repetirSenha"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={loading}
                        allowSubmit={false}
                        tipo="PASSWORD"
                        label="Confirmar Senha"
                        showPasswordVisibilityButton
                        fullWidth
                        autoComplete={'off'}
                        helperText={
                          touchedFields.repetirSenha && errors.repetirSenha
                            ? errors.repetirSenha.message
                            : undefined
                        }
                        error={Boolean(errors.repetirSenha && errors.repetirSenha.message)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
