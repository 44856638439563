import { useTheme } from '@material-ui/core';
import { CircularLoading } from 'views';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useToastSaurus } from 'services/app';
import { DefaultFormRefs } from 'views/components/form/utils';
import { TerminalModel } from 'model/api/gestao/terminal/terminal-model';
import { FormTerminal } from 'views/components/form/master/terminal/form-terminal';
import { usePutDesativarTerminal } from 'data/api/gestao/terminal/put-desativar-terminal';
import { usePutBloquearTerminal } from 'data/api/gestao/terminal/put-bloquear-terminal';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { usePutAtivarTerminal } from 'data/api/gestao/terminal/put-ativar-terminal';
import { EnumTerminalStatus } from 'model/enums/enum-status-terminal';

enum EnumAcoes {
    Ativar = 1,
    Bloquear = 2,
    Desativar = 3,
}
interface AccordionTerminalFormProps {
    setRefresh?: React.Dispatch<React.SetStateAction<boolean>>;
    model: TerminalModel;
    carregando: boolean;
    openned: boolean;
    formRef: React.RefObject<DefaultFormRefs<TerminalModel>>
}

export const AccordionTerminalForm = ({
    model,
    setRefresh,
    carregando,
    openned,
    formRef
}: AccordionTerminalFormProps) => {
    const theme = useTheme();
    const { showToast } = useToastSaurus()

    const { putAtivarTerminal } = usePutAtivarTerminal()
    const { putDesativarTerminal } = usePutDesativarTerminal()
    const { putBloquearTerminal } = usePutBloquearTerminal()

    const recarregarForm = useCallback(() => {
        formRef.current?.fillForm(model)
    }, [formRef, model])

    const [acao, setAcao] = useState<string>('')
    const [openAccordion, setOpenAccordion] = useState<boolean>(true)

    const prevStatus = useRef<string>(EnumAcoes[model.status])

    useEffect(() => {
        if (openned) {
            prevStatus.current = EnumAcoes[model.status]
            if (model.status === EnumTerminalStatus.Disponivel) setAcao(EnumAcoes[1])
            else setAcao(EnumAcoes[model.status])
            recarregarForm();
        }
    }, [model, recarregarForm, openned])


    const handleSubmit = useCallback(async (actualModel: TerminalModel, beforeModel: TerminalModel) => {

        try {
            let res, mensagem
            if (prevStatus.current === acao) return
            switch (acao) {
                case 'Ativar':
                    res = await putAtivarTerminal(actualModel)
                    mensagem = 'Ativado'
                    break
                case 'Desativar':
                    res = await putDesativarTerminal(actualModel.id)
                    mensagem = 'Desativado'
                    break
                case 'Bloquear':
                    res = await putBloquearTerminal(actualModel.id)
                    mensagem = 'Bloqueado'
                    break
                default:
                    throw new Error('Opção inválida')
            }

            if (res.statusCode !== 204) throw new Error(res.erro)

            showToast('success', `Terminal ${mensagem} com sucesso`)
            prevStatus.current = acao
            if (setRefresh) setRefresh(current => !current)
        } catch (e: any) {
            showToast('error', e.message)
        }

    }, [acao, putAtivarTerminal, putBloquearTerminal, putDesativarTerminal, setRefresh, showToast])

    const changeRadio = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setAcao((event.target as HTMLInputElement).value);
    }, [])

    return (
        <AccordionSaurus
            labelPrimary="Informações"
            tipoExpand="bold"
            noPaperRoot={false}
            heightDivider={2}
            showDivider={openAccordion}
            colorDivider={theme.palette.primary.main}
            colorExpand={theme.palette.primary.main}
            expanded={openAccordion}
            onChange={() => setOpenAccordion(prev => !prev)}
        >
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <FormTerminal ref={formRef} model={model} onSubmit={handleSubmit} loading={carregando} showLoading={carregando}
                acao={acao} changeRadio={changeRadio} />
        </AccordionSaurus>
    );
};
