import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useDeleteAmbienteSistema() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const deleteAmbienteSistema = useCallback(
        (sistemaId: string, id: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/sistema/${sistemaId}/ambiente/${id}`,
                method: 'DELETE',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        deleteAmbienteSistema,
    };
}
