import { PagamentoModel } from "model/api/gestao/pagamento/pagamento-model";
import { guidEmpty } from "utils/guid-empty";

export class FaturaFormModel {
  constructor(
    public id: string = guidEmpty(),
    public nossoNumero: number = 0,
    public dataVencimento: string = '',
    public formaPagamentoId: string = guidEmpty(),
    public valorCusto: number = 0,
    public encargo: number = 0,
    public multaJuros: number = 0,
    public valor: number = 0,
    public valorOriginal: number = 0,
    public formasPagamentosDisponiveis: PagamentoModel[] = []
  ) { }
}


