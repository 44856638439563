import { EnumTipoControleModulo } from "model/enums/enum-tipo-controle-modulo";

export class NovoModuloCadastroFormModel {
    constructor(
        public nome: string = '',
        public descricao: string = '',
        public tipoCalculo: number = 0,
        public codigo: number = 0,
        public codModuloBaseCalculo: string = '',
        public quantidadeMinima: number = 0,
        public quantidadeMaxima: number = 0,
        public quantidadePadrao: number = 0,
        public tipoControle: EnumTipoControleModulo = EnumTipoControleModulo.NaoControla,
        public tipoTerminal: string = ''
    ) { }
}