import { isEmpty } from 'lodash';
import { RelatorioChartModel } from 'model/api/gestao/relatorios/relatorio-model';
import { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { toDecimalString } from 'utils/to-decimal';

type Props = {
    list: RelatorioChartModel[]
    stacked?: boolean;
    width?: number;
}

export default function ChartContratos({ list, stacked = false, width }: Props) {

    const chartOptions = useMemo(() => {
        const series = list.sort((a, b) => {
            if(isEmpty(a.data) || isEmpty(b.data)) return 1
            return a.data[0].x - b.data[0].x
        })
        const options: ApexCharts.ApexOptions = {
            chart: {
                zoom: {
                    enabled: true,
                    type: 'x'
                },
                toolbar: {
                    show: false
                },
                type: stacked ? 'area' : 'line',
                height: 350,
                stacked: stacked,
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'straight',
                width: 2
            },
            fill: {
                type: stacked ? 'gradient' : 'solid',
                gradient: {
                    opacityFrom: stacked ? 0.6 : 1,
                    opacityTo: stacked ? 0.8 : 1,
                }
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left'
            },
            yaxis: {
                labels: {
                    formatter(val, opts) {
                        return toDecimalString(val, 0)
                    },
                }
            },
            xaxis: {
                labels: {
                    formatter: (value: string) => {
                        if(!value || typeof value !== 'string' || !value.substring) return ''
                        return `${value.substring(4)}/${value.substring(0, 4)}`
                    }
                }

            },
        };

        return {
            options,
            series
        }
    }, [list, stacked])

    return (
        <Chart options={chartOptions.options} series={chartOptions.series} type={stacked ? 'area' : 'line'} width={width || '100%'} height={600} />

    )
}