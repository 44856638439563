import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetFaturasFilhasExportarExcel() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getFaturasFilhasExportarExcel = useCallback(
        async (id: string) => {

            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/fatura/${id}/filhas/excel`,
                method: 'GET',
                enviarTokenUsuario: true,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/vnd.ms-excel",
                    "Content-Disposition": "attachment; filename=faturas.xls; filename*=UTF-8''faturas.xls "
                },
            })
        }
        , [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getFaturasFilhasExportarExcel,
    };
}
