import { Grid } from "@material-ui/core";
import { PessoaSistemaModel } from "model/api/gestao/pessoa/pessoa-sistema-model";
import { PessoaWhitelabelModel } from "model/api/gestao/pessoa/pessoa-whitelabel-model";
import { CardNaoEncontrado } from "views/components/cards";
import { CardWhitelabel } from "views/components/cards/card-whitelabel/card-whitelabel";
import { InformacaoIcon } from "views/components/icons";

export interface ClienteRevendedorGridProps {
    list: Array<PessoaSistemaModel>;
    whitelabels: Array<PessoaWhitelabelModel>;
    carregando: boolean;
    onCardClick: (model: PessoaSistemaModel, existe: boolean) => void;
}

export const AccordionWhitelabelListData = (props: ClienteRevendedorGridProps) => {

    return (
        <>
            {props.list.length === 0 && !props.carregando && (
                <Grid item xs={12}>
                    <CardNaoEncontrado
                        mensagem="Nenhum Sistema vinculado."
                        icon={<InformacaoIcon tipo="GERAL" />}
                    />
                </Grid>
            )}
            {props?.list.length > 0 &&
                props.list.map((sistema, index) => {
                    return (
                        <Grid item xs={6} md={3}>
                            <CardWhitelabel
                                existe={Boolean(props.whitelabels.find(x => x.sistemaId === sistema.sistema.id))}
                                model={sistema}
                                onClick={props.onCardClick}
                            />
                        </Grid>
                    );
                })}
        </>
    );
};