import { Button, Grid, Slide } from "@material-ui/core";
import classNames from "classnames";
import { PessoaPostModel } from "model/api/gestao/pessoa/pessoa-post-model";
import { useEffect, useRef } from "react";
import { useSessaoAtual } from "services/app";
import { FormPessoaCadastro } from "views/components/form/master/pessoa/pessoa-cadastro/form-pessoa-cadastro"
import { DefaultFormRefs } from "views/components/form/utils";
import { AvancarIcon } from "views/components/icons";
import { useModalStyles } from "views/components/modals/utils/modal-styles";

interface Props {
    carregando: boolean
    handleSubmit: (model: PessoaPostModel) => void
    revenda: boolean
    pessoaForm: PessoaPostModel
    fase: 1 | 2 | 3
}

export const PessoaCadastroPrimeiraFase = ({ carregando, handleSubmit, revenda, pessoaForm, fase }: Props) => {
    const classes = useModalStyles();
    const { usuario } = useSessaoAtual()
    const cadFormRef =
        useRef<DefaultFormRefs<PessoaPostModel>>(null);

    useEffect(() => {
        if(fase === 1)
            cadFormRef.current?.fillForm(pessoaForm);
    }, [fase, pessoaForm]);

    return (
        <Slide in={fase === 1} direction="right">
            <div className={classes.content}>
                <div
                    className={classNames(
                        classes.contentForms,
                        carregando ? classes.contentFormsLoading : undefined,
                    )}
                >
                    <FormPessoaCadastro
                        ref={cadFormRef}
                        showLoading={false}
                        loading={carregando}
                        revenda={revenda}
                        isRevendedor={usuario?.Tipo.toLocaleLowerCase() === 'revenda'}
                        onSubmit={handleSubmit}
                    />
                </div>
                <div className={classes.acoes}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button
                                disabled={carregando}
                                onClick={() => cadFormRef.current!.submitForm()}
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                            >
                                <AvancarIcon tipo="BUTTON_PRIMARY" />
                                Continuar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Slide>
    )
}