import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { RevendaDesempenhoModel } from 'model/api/gestao/revendedor/revenda-desempenho-model';

export function useGetRevendaDesempenho() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<RevendaDesempenhoModel[]>();

    const getRevendaDesempenho = useCallback(
        (anoMesApuracao: number, query?: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/resultados/revendas-desempenho/apuracao/${anoMesApuracao}?${query || ''}`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getRevendaDesempenho,
    };
}
