import { useState } from "react";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus";
import { useThemeQueries } from "views/theme";
import {useStyles} from './accordion-cliente-ideal-styles'
import { Box } from "@material-ui/core";
import { ClienteIdeal } from "views/components/cliente-ideal/cliente-ideal";

type Props = {
    id: string;
    defaultState?: boolean;
}

export const AccordionClienteIdeal = ({ id, defaultState = false }: Props) => {

    const [openAccordion, setOpenAccordion] = useState<boolean>(defaultState)
    const { theme } = useThemeQueries();
    const classes = useStyles()

    return (
        <>
            <AccordionSaurus
                labelPrimary="Cliente Ideal"
                tipoExpand="bold"
                noPaperRoot={false}
                heightDivider={2}
                showDivider={openAccordion}
                colorDivider={theme.palette.primary.main}
                colorExpand={theme.palette.primary.main}
                expanded={openAccordion}
                onChange={() => setOpenAccordion(prev => !prev)}
            >
                <Box className={classes.container}>
                    <ClienteIdeal pessoaId={id}/>
                </Box>
            </AccordionSaurus>
        </>
    );
};