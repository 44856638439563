import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";

export class MenuModel {
  constructor(
    public nome: string = "",
    public codigo: number = 0,
    public permissoes: Array<EnumTipoPessoas | undefined> = [],
    public rota: string = "",
    public posicao: number,
    public codigoPai: number = 0
  ) { }
}
