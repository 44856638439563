import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    cardContent: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
    },
    label: {
        fontSize: '0.9rem',
        color: '#9D9D9D',
        lineHeight: '14,1px',
        textAlign: 'justify',
        marginRight: '3px'
    },
    qrcode: {
        display: 'flex',
        flexDirection: "column",
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(1)
    },
    containerPag: {
        backgroundColor: 'rgb(245, 245, 245)',
        padding: '0px 28px 0',
        borderTopRightRadius: '20px',
        borderTopLeftRadius: '20px'
    },
    containerList: {
        backgroundColor: '#f5f5f5',
        padding: '0 17px 25px'
    },
    qrText: {
        textAlign: 'center',
    },
    buttonRemover: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
        '& svg': {
            fill: theme.palette.error.main
        }
    },
}));