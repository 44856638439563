import { Button, Grid } from '@material-ui/core';
import { useStyles } from './ambientes-list-styles';
import { AmbientesListData } from './ambientes-list-data';
import { NovoIcon } from 'views/components/icons';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { useCallback, useEffect, useState } from 'react';
import { Paginacao } from 'views/components/paginacao';
import { useCadastros, useToastSaurus } from 'services/app';
import { useGetAmbienteSistemas } from 'data/api/gestao/ambiente-sistema';
import { AmbienteSistemaModel } from 'model/api/gestao/ambiente-sistema/ambiente-sistema-model';
import { DialogAmbiente } from 'views/components/dialog/dialog-ambiente/dialog-ambiente';

interface AmbientesListProps {
  sistemaId: string;
}

export const AmbientesList = ({ sistemaId }: AmbientesListProps) => {

  const { showToast } = useToastSaurus()
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [selectedModel, setSelectedModel] = useState<AmbienteSistemaModel>(new AmbienteSistemaModel())

  const { getAmbienteSistemas, carregando } = useGetAmbienteSistemas()

  const { abrirCadastroAmbiente, cadastroAmbienteState } = useCadastros()

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<AmbienteSistemaModel>(),
  });

  const fillResult = useCallback(
    (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<AmbienteSistemaModel>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    []
  );

  const search = useCallback(
    async (newPage: number) => {
      try {
        const res = await getAmbienteSistemas(sistemaId, newPage, ``);
        if (res.erro) throw res.erro;

        if (!res.resultado) {
          return;
        }

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          await search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list
        );

        return res;
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [getAmbienteSistemas, sistemaId, fillResult, showToast]
  );

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        await search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  useEffect(() => {
    if(!cadastroAmbienteState.aberto && !openDialog)
      search(queryStatus.page)
  }, [cadastroAmbienteState.aberto, openDialog, queryStatus.page, search])

  const onClick = useCallback((model: AmbienteSistemaModel) => {
    setSelectedModel(model)
    setOpenDialog(true)
  }, [])

  return (
    <>
      <AccordionSaurus
        labelPrimary='Ambientes'
      >
        <div className={classes.defaultContainer}>
          <Grid container>
            <Grid item xs={12} className={classes.listContainer}>
              <Grid style={{
                padding: '0 16px'
              }}>
                <Paginacao
                  pageChanged={pageChanged}
                  totalPages={queryStatus.totalPages}
                  totalRegisters={queryStatus.totalResults}
                  currentPage={queryStatus.page}
                />
              </Grid>
              <AmbientesListData
                atualizarList={() => search(queryStatus.page)}
                carregando={carregando}
                list={queryStatus.list}
                selectedList={[]}
                onCardSelected={onClick}
                onCardChecked={() => { }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent='flex-end'>
                <Grid item md={6} lg={5} xs={12} style={{ padding: '0 8px 16px' }}>
                  <Button
                    variant="contained"
                    color='primary'
                    fullWidth
                    onClick={() => abrirCadastroAmbiente(sistemaId, '', false)}
                    size="large"
                  >
                    <NovoIcon tipo="BUTTON_PRIMARY" />
                    NOVO AMBIENTE
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </AccordionSaurus>
      <DialogAmbiente
        closeDialog={() => setOpenDialog(false)}
        openned={openDialog}
        model={selectedModel}
        sistemaId={sistemaId}
      />
    </>
  );
};
