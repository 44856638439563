
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormFaturaLiquidarValidation = () => {


  const FormFaturaLiquidarValidationYup = useMemo(() => {
    return Yup.object().shape({
        valor: Yup.string().required('Valor é obrigatório').typeError('Valor é obrigatório'),
      dataHora: Yup.string().required('Campo obrigatório'),
      formaPagamentoId: Yup.string().required('Forma de Pagamento é obrigatória.').typeError('Forma de Pagamento é obrigatório.'),

    });
  }, [])

  return {
    FormFaturaLiquidarValidationYup,
  }
}