import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeUtilClasses, useThemeQueries } from 'views';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { ImagemInput, TextFieldSaurus } from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useForm, Controller } from 'react-hook-form';
import { picker } from 'utils/picker';
import { EditarSistemaFormModel } from 'model/app/forms/sistemas/editar-sistema-form-model';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormEditarSistemaValidation } from './form-editar-sistema-validation';
import { useStyles } from './form-editar-sistema-styles'

export const FormEditarSistema = forwardRef<
  DefaultFormRefs<EditarSistemaFormModel>,
  DefaultFormProps<EditarSistemaFormModel>
>(
  (
    { loading, onSubmit, ...props }: DefaultFormProps<EditarSistemaFormModel>,
    ref,
  ) => {
    const [modelForm, setModel] = useState<EditarSistemaFormModel>(
      new EditarSistemaFormModel())
    const classes = useStyles()

    const { FormEditarSistemaValidationYup } = useFormEditarSistemaValidation()
    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
      getValues,
      setValue
    } = useForm<EditarSistemaFormModel>({
      resolver: yupResolver(FormEditarSistemaValidationYup),
      defaultValues: { ...modelForm },
      criteriaMode: 'all',
      mode: 'onChange',
    });
    const utilClasses = makeUtilClasses();
    const { isMobile } = useThemeQueries();

    const submit = useCallback(
      (data: EditarSistemaFormModel) => {
        const sistema = picker<EditarSistemaFormModel>(
          data, new EditarSistemaFormModel()
        )
        onSubmit(sistema, modelForm);
      },
      [modelForm, onSubmit],
    );

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submit)();
      },
      resetForm: () => {
        if (!isMobile) {
        }
      },
      fillForm: (model: EditarSistemaFormModel) => {
        setModel(model)
        reset({ ...model, })
      },
    }));

    const imagemBase64 = useCallback(() => {
      try {
        return atob(getValues('urlImagem'))
      } catch (e: any) {
        return getValues('urlImagem')
      }
    }, [getValues])

    return (
      <>
        <div className={utilClasses.formContainer} style={{
          position: "relative"
        }}>
          {loading && props.showLoading && (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="FULLSIZED" />
            </div>
          )}

          <form
            onSubmit={handleSubmit(submit)}
            className={loading ? utilClasses.controlLoading : ''}
          >
            <div className={classes.content}>
              <Grid item className={classes.cardImg}>
                <Controller
                  name="urlImagem"
                  control={control}
                  render={({ field }) => (
                    <ImagemInput
                      loadErrorImage={SemImagem}
                      accept='image/*'
                      width={'180px'}
                      height={'180px'}
                      error={Boolean(
                        errors.urlImagem && errors.urlImagem.message,
                      )}
                      helperText={
                        touchedFields.urlImagem || errors.urlImagem
                          ? errors.urlImagem?.message
                          : undefined
                      }
                      {...field}
                      value={imagemBase64()}
                      onChange={({ base64 }: any) => {
                        setValue('urlImagem', base64);
                      }}
                      onBlur={() => {
                        setValue('urlImagem', '');
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <Controller
                    name="nome"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        id="nome"
                        label="Nome"
                        disabled={loading}
                        tipo="TEXTO"
                        fullWidth={true}
                        error={Boolean(
                          errors.nome && errors.nome.message,
                        )}
                        helperText={
                          touchedFields.nome || errors.nome
                            ? errors.nome?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="descricao"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={loading}
                        multiline
                        rows={4}
                        tipo="TEXTO"
                        id="descricao"
                        label="Descrição"
                        fullWidth={true}
                        error={Boolean(
                          errors.descricao && errors.descricao.message,
                        )}
                        helperText={
                          touchedFields.descricao || errors.descricao
                            ? errors.descricao?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                    <Controller 
                      name="percDescontoAntecipacao"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus 
                          tipo='DECIMAL'
                          disabled={loading}
                          casasDecimais={2}
                          id="percDescontoAntecipacao"
                          label="Percentual de Desconto Antecipado %"
                          fullWidth
                          manterMascara
                          error={Boolean(
                            errors.percDescontoAntecipacao && errors.percDescontoAntecipacao.message,
                          )}
                          helperText={
                            touchedFields.percDescontoAntecipacao || errors.percDescontoAntecipacao
                              ? errors.percDescontoAntecipacao?.message
                              : undefined
                          }
                          {...field}
                        />
                      )}
                    />
                </Grid>
              </Grid>
            </div>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </>
    );
  },
);
