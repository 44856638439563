import { Button, Grid } from '@material-ui/core';
import { picker } from 'utils/picker';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useRef, useCallback, useEffect, useState } from 'react';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { useToastSaurus, useCadastros } from 'services/app';
import {
  SalvarEditarIcon,
  VoltarIcon,
} from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import classNames from 'classnames';
import { PagamentoModel } from 'model/api/gestao/pagamento/pagamento-model';
import { useGetPagamentoById, usePutAtualizarPagamento } from 'data/api/gestao/pagamento';
import { PagamentoFormModel } from 'model/app/forms/pagamento/pagamento-form-model';
import { FormPagamentoEdicao } from 'views/components/form/master/pagamento/pagamento-edicao/form-pagamento-edicao';
import { isEqual } from 'utils/is-equal';

type Props = {
  id: string
}

export const PagamentoEdicao = ({ id }: Props) => {

  const { putAtualizarPagamento, carregando: carregandoPut } = usePutAtualizarPagamento();
  const { getPagamentoById, carregando: carregandoGet } = useGetPagamentoById()

  const [preenchendoTela, setPreechendoTela] = useState<boolean>(false)

  const carregando = carregandoGet || carregandoPut || preenchendoTela

  const { fecharCadastroPagamento } =
    useCadastros();
  const { showToast } = useToastSaurus();

  const refEditForm =
    useRef<DefaultFormRefs<PagamentoFormModel>>(null);
  const [pagamentoForm, setPagamentoForm] = useState<PagamentoFormModel>(new PagamentoFormModel())
  const refPagamentoModel = useRef<PagamentoModel>(new PagamentoModel())

  const classes = useModalStyles();
  const recarregarForm = useCallback((model: PagamentoFormModel) => {
    refEditForm.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(pagamentoForm)
  }, [pagamentoForm, recarregarForm])

  const getPagamentoByIdWrapper = useCallback(async () => {
    const res = await getPagamentoById(id)

    if (res.erro) throw res.erro

    const ret = res.resultado?.data as PagamentoModel

    refPagamentoModel.current = ret
    
    const credenciais = ret.credenciais

    const instituicao = picker<PagamentoFormModel>(ret, new PagamentoFormModel())

    if(instituicao.instituicao && credenciais){
      try{
        instituicao.credenciais = {...JSON.parse(credenciais)}
      }catch(e){

      }
    }

    return instituicao
  }, [getPagamentoById, id])

  useEffect(() => {
    (async () => {
      try {
        setPreechendoTela(true)
        const instituicao = await getPagamentoByIdWrapper()

        setPagamentoForm(instituicao)
      } catch (e: any) {
        showToast('error', e.message)
      } finally {
        setPreechendoTela(false)
      }
    })()
  }, [getPagamentoByIdWrapper, showToast])

  const savePagamento = useCallback(
    async (model: PagamentoModel) => {

      const pagamento = picker<PagamentoModel>(model, refPagamentoModel.current)

      const ret = await putAtualizarPagamento(pagamento);
      if (ret.erro) {
        throw ret.erro;
      }

      if(!ret.resultado?.data.credenciamento.credenciado){
        showToast('error', 'Não foi possível credenciar. Verifique suas credenciais.')
        return
      }

      showToast('success', "Forma de Pagamento Editada!")
    },
    [putAtualizarPagamento, showToast],
  );
  const handleSubmit = useCallback(
    async (model: PagamentoFormModel, beforeModel: PagamentoFormModel) => {
      try {

        const formaPagamento = { ...model, credenciais: model.credenciais ? JSON.stringify(model.credenciais) : '' }

        const pagamentoEqual = isEqual(formaPagamento, beforeModel)

        if (pagamentoEqual) {
          showToast('info', "Nenhuma informação foi alterada")
          return
        }

        await savePagamento(formaPagamento);

        refEditForm.current?.fillForm(model)
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [savePagamento, showToast],
  );

  const onCloseClick = useCallback(() => {
    fecharCadastroPagamento()
  }, [fecharCadastroPagamento]);

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={'Editar Forma de Pagamento'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}
        >
          <FormPagamentoEdicao
            ref={refEditForm}
            showLoading={false}
            loading={carregando}
            onSubmit={handleSubmit}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                disabled={carregando}
                onClick={() => {
                  refEditForm.current!.submitForm()
                }
                }
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                Salvar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
