import { Checkbox, withStyles } from "@material-ui/core";

export const CheckboxDefault = withStyles(theme => ({
  root: {
    "&": {
      color: theme.palette.text.disabled,
    },
    "&$checked": {
      color: theme.palette.primary.main,
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
      },
      "& .MuiIconButton-label:after": {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: "absolute",
        backgroundColor: theme.palette.primary.contrastText,
        zIndex: -1
      }
    }
  },
  checked: {}
}))((props) => {

  return (
    <>
      <Checkbox color="default" {...props} />
    </>
  )
})





