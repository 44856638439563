
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormComissaoEditarValidation = () => {


  const FormComissaoEditarValidationYup = useMemo(() => {
    return Yup.object().shape({
      
    });
  }, [])

  return {
    FormComissaoEditarValidationYup,
  }
}

