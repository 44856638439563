import { guidEmpty } from "utils/guid-empty";

export class AmbienteSistemaModel {
    constructor(
        public id: string = guidEmpty(),
        public sistemaId: string = guidEmpty(),
        public nome: string = '',
        public valor: string = '',
        public revendaId: string = '',
    ) { }
}