import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, Tooltip, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { QRCodeSVG } from 'qrcode.react';
import { useStyles } from './dialog-pix-styles'
import { CloseIcon } from 'views/components/icons/close-icon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CopiarIcon, DinheiroIcon } from 'views/components/icons';
import { toCurrency } from 'utils/toCurrency';
import { FaturaModel } from 'model/api/gestao/fatura/fatura-model';
import { usePostFaturaPagar } from 'data/api/gestao/faturas';
import { EnumTipoComunicacao, KeyValueModel } from 'model';
import { isEmpty } from 'lodash';
import { useToastSaurus } from 'services/app';
import { CircularLoading } from 'views/components/utils';
import { PagamentoModel } from 'model/api/gestao/pagamento/pagamento-model';
import { toDateString } from 'utils/to-date';
import { useThemeQueries } from 'views/theme';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { guidEmpty } from 'utils/guid-empty';
import { TipoComunicacaoMock } from 'data/mocks/tipo-comunicacao-mock';
import { DialogPixError } from './components/dialog-erro-pix';

interface DialogPixProps {
    openned: boolean;
    closeDialog: () => void;
    pagarPix: (formaPagamento: PagamentoModel) => Promise<void>;
    value: string;
    model: FaturaModel;
}

export const DialogPix = ({
    openned,
    closeDialog,
    pagarPix,
    value,
    model
}: DialogPixProps) => {

    const classes = useStyles();

    //REFS
    const qrRef = useRef(null);
    const refreshsCount = useRef<number>(0)

    const { showToast } = useToastSaurus();
    const { theme, isMobile } = useThemeQueries();

    const { postFaturaPagar, carregando } = usePostFaturaPagar()

    const [formaPagamento, setFormaPagamento] = useState<PagamentoModel>(new PagamentoModel())
    const [concorda, setConcorda] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)

    const formasPagamentoBoleto = useMemo(() => {
        const formasPagamento = model.formasPagamentosDisponiveis.filter(fp => {
            return (
                fp.tipoComunicacao === EnumTipoComunicacao.Boleto &&
                fp.id !== model.formaPagamento.id
            )
        })
        return !isEmpty(formasPagamento) ? formasPagamento : null
    }, [model.formaPagamento.id, model.formasPagamentosDisponiveis])

    const formaPagamentoPix = useMemo(() => {
        if (model.formaPagamento.tipoComunicacao === EnumTipoComunicacao.Pix) {
            return model.formaPagamento
        }
        const pix = model.formasPagamentosDisponiveis.find(fp => fp.tipoComunicacao === EnumTipoComunicacao.Pix)
        return !isEmpty(pix) ? pix : null
    }, [model.formaPagamento, model.formasPagamentosDisponiveis])

    useEffect(() => {
        if(error){
            return
        }
        if (!model.dhExpiracaoPix || !openned) {
            return
        }
        if ((new Date(model.dhExpiracaoPix) < new Date()) && formaPagamentoPix) {
            if(refreshsCount.current > 2){
                setError(true)
                return
            }
            closeDialog()
            refreshsCount.current += 1
            pagarPix(formaPagamentoPix);

        }
    }, [closeDialog, error, formaPagamentoPix, model.dhExpiracaoPix, openned, pagarPix])

    const pagarOutraFormaPagamento = useCallback(async () => {
        try {
            switch (formaPagamento.tipoComunicacao) {
                case EnumTipoComunicacao.Boleto:
                    const res = await postFaturaPagar(model.id, formaPagamento!.id)

                    if (res.erro) throw res.erro
                    if (!res.resultado) throw new Error('Erro ao gerar boleto. Tente Novamente')

                    window.open(res.resultado.data.url, '_blank');
                    break;
                case EnumTipoComunicacao.Pix:
                    closeDialog();
                    pagarPix(formaPagamento)
            }
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [closeDialog, formaPagamento, model.id, pagarPix, postFaturaPagar, showToast])

    const selectFormaPagamento = useMemo(() => formasPagamentoBoleto ? (
        <SelectSaurus
            conteudo={formasPagamentoBoleto.map(fp => {
                const label = `${fp.nome} (${TipoComunicacaoMock.find(tc => tc.Key === fp.tipoComunicacao)?.Value})`
                return new KeyValueModel(fp.id, label)
            })}
            label='Outras Formas de Pagamento'
            value={formaPagamento.id}
            onChange={(e: any) => {
                const fp = formasPagamentoBoleto.find(fp => fp.id === e.target.value)
                if (fp) {
                    setFormaPagamento(fp)
                }
            }}
        />
    ) : null, [formaPagamento.id, formasPagamentoBoleto])

    return (
        <DialogSaurus
            aberto={openned}
            titulo='Pagamento de Fatura'
            tamanho="sm"
            fullScreen={isMobile}
            endAdornment={
                <Button onClick={closeDialog} style={{ margin: '5px 0' }}>
                    <CloseIcon tipo='BUTTON' style={{ margin: 0 }} />
                </Button>
            }
        >
            {carregando && <CircularLoading tipo='FULLSIZED' />}
            <Grid container className={classes.qrCodecontainer} spacing={2}>
                <Grid item xs={12}>
                    <Typography align='center'>
                        Leia o QR Code no aplicativo do banco para realizar o pagamento da fatura. O pagamento via PIX permite a constatação de pagamento imediata além de oferecer maior segurança no pagamento por ser uma transação registrada no BACEN em tempo real.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={5} className={classes.qrCodeInfo}>
                    <div ref={qrRef} className={classes.qrCodeBox}>
                        <QRCodeSVG value={value} size={200} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={7} className={classes.qrCodeInfo}>
                    <Box display='block'>
                        <Typography variant='caption' color='textSecondary'>Este PIX expira em: {toDateString(new Date(model.dhExpiracaoPix), 'DD/MM/yyyy HH:mm')}.</Typography>
                        <Typography variant='h5'>Valor: {toCurrency(model.valor)}</Typography>
                    </Box>
                    <Box display='block' width={218}>
                        <Typography variant='body2'>Pix Copia e Cola</Typography>
                        <Box display='flex' gridGap={1} width='100%' mt={0.5}>
                            <Typography variant='body2' style={{ wordBreak: 'break-all' }}>
                                {value.substring(0, 37)}...
                            </Typography>
                            <Tooltip title='Copiar Código PIX'>
                                <Button onClick={() => {
                                    navigator.clipboard.writeText(value)
                                    showToast('info', 'PIX copiado.', 1500)

                                }}>
                                    <CopiarIcon tipo='BUTTON' />
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>
                </Grid>

                {/* <Button variant='contained' color='primary' onClick={() => {
                    exportComponentAsPNG(qrRef)
                }}>
                    <DownloadIcon />
                    Baixar QRCode
                </Button> */}
                {(formasPagamentoBoleto) && <>
                    <Grid item xs={12}>
                        <Divider style={{ background: theme.palette.primary.main }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box display='flex' flexDirection='column' gridGap={10} mx={1} mb={1}>
                            {selectFormaPagamento}
                            <FormControlLabel
                                control={<Checkbox color='primary' checked={concorda} onChange={(e: any) => setConcorda(e.target.checked)} />}
                                label={
                                    <Typography variant='body2' color='textPrimary'>
                                        Ao trocar a forma de pagamento, aceito que somente o PIX oferece quitação instantânea do pagamento.
                                    </Typography>
                                }
                            />
                            <Box display='flex' justifyContent='flex-end' width='100%'>
                                <Button variant='contained' color='primary' onClick={pagarOutraFormaPagamento} disabled={!concorda || formaPagamento.id === guidEmpty()}>
                                    <DinheiroIcon tipo='BUTTON_PRIMARY' />
                                    Confirmar Troca
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </>}
            </Grid>
            {error && (
                <DialogPixError
                    closeDialog={() => {
                        setError(false)
                        closeDialog()
                    }}
                    openned={error}
                    selectFormaDePagamento={selectFormaPagamento}
                    formaPagamentoId={formaPagamento.id}
                    pagarOutraFormaPagamento={pagarOutraFormaPagamento}
                />
            )}
        </DialogSaurus >
    );
};
