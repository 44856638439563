import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    menu: {
        '& before': {
            content: "",
            width: '0px',
            height: '0px',
            position: 'absolute',
            borderLeft: '15px solid transparent',
            borderRight: '15px solid #00bfb6',
            borderTop: '15px solid #00bfb6',
            borderBottom: '15px solid transparent',
            left: '-16px',
            top: '0px',
        },
    },
    icon: {
        '& svg': {
            margin: 0,
            marginLeft: theme.spacing(3)
        },
        display: 'flex'
    },
}))