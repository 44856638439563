import { useCallback, useMemo } from "react";
import { PrivatePageHeader } from "views/components/headers";
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { MenuIcon, VoltarIcon } from "views/components/icons";
import { GestaoStorageKeys, useGestaoStorage, useMenuPrincipal, useToastSaurus } from "services/app";
import { useThemeQueries } from "views";
import { useHistory } from "react-router-dom";
import { PessoasMapaModel } from "model/api/gestao/pessoa/pessoa-mapa-model";
import { RetornarIcon } from "views/components/icons/retornar-icon";
import { useHeaderStyles } from "views/components/headers/header-private-page/header-styles";
import { useGetPessoaMapa } from "data/api/gestao/pessoa-mapa/get-pessoa-mapa";
import { EnumTipoBuscaMapa } from "model/enums/enum-tipo-busca-mapa";
import { LicenciamentoDBPrimary } from "database/licenciamento-database";
import { IndicacoesModel } from "model/api/gestao/indicacoes/indicacoes-model";
import { RelatorioSearchPropsModel } from "model/api/gestao/relatorios/relatorio-search-props-model";

export interface DashboardHeaderProps {
  titulo: string;
  getPessoasMapaWrapper: (ret: PessoasMapaModel[]) => Promise<any>;
  setLoadingRevenda: React.Dispatch<React.SetStateAction<boolean>>;
  getIndicacoesWrapper: () => Promise<IndicacoesModel[]>;
  getResultadoRevendaWrapper: (model: RelatorioSearchPropsModel) => Promise<boolean>;
  loading: boolean;
  isAllowed: boolean;
}

export const DashboardHeader = ({
  titulo,
  getPessoasMapaWrapper,
  setLoadingRevenda,
  getIndicacoesWrapper,
  getResultadoRevendaWrapper,
  loading,
  isAllowed
}: DashboardHeaderProps) => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile, theme } = useThemeQueries();
  const { showToast } = useToastSaurus();
  const {getRegistro} = useGestaoStorage();

  const history = useHistory()
  const classes = useHeaderStyles();

  const { getPessoaMapa } = useGetPessoaMapa();

  const getDadosRevendedoresWrapper = useCallback(async () => {
    try {
      setLoadingRevenda(true)
      const res = await getPessoaMapa(`tipoBuscaMapa=${EnumTipoBuscaMapa.Revendedores}`)

      if (res.erro) throw res.erro;
      if (!res.resultado) return [];

      const ret = res.resultado.data

      await LicenciamentoDBPrimary.limparCampo('revendedores')
      await LicenciamentoDBPrimary.revendedores.bulkAdd(ret)
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [getPessoaMapa, setLoadingRevenda, showToast])


  const sincronizarDados = useCallback(async () => {
    const periodoRelatorio = getRegistro(GestaoStorageKeys.PeriodoRelatorio)

    if(isAllowed) {
      await getDadosRevendedoresWrapper()
    }
    await getResultadoRevendaWrapper(new RelatorioSearchPropsModel(periodoRelatorio.anoMesInicial, periodoRelatorio.anoMesFinal))

    await getPessoasMapaWrapper([])
    await getIndicacoesWrapper()
    setLoadingRevenda(false)
  }, [getDadosRevendedoresWrapper, getIndicacoesWrapper, getPessoasMapaWrapper, getRegistro, getResultadoRevendaWrapper, isAllowed, setLoadingRevenda])

  const leftArea = useCallback(
    () =>
      isMobile ? (
        <ButtonPrivateHeader
          icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
          tooltip="Menu"
          onClick={abrirMenu}
        ></ButtonPrivateHeader>
      ) : (titulo !== "Dashboard") ? (
        <ButtonPrivateHeader
          icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
          tooltip="Voltar"
          onClick={() => history.push('/planos')}
        />
      ) : null,
    [isMobile, abrirMenu, titulo, history]
  );

  const rightArea = useMemo(() => (
    <ButtonPrivateHeader
      className={classes.pesquisaBox}
      text="Sincronizar Dados"
      icon={<RetornarIcon tipo="PRIVATE_HEADER" fill={isMobile ? theme.palette.background.paper : loading ? theme.palette.divider : theme.palette.primary.main} />}
      tooltip='Sincronizar Dados em tempo real'
      onClick={sincronizarDados}
      disabled={loading}
    ></ButtonPrivateHeader>
  ), [classes.pesquisaBox, isMobile, loading, sincronizarDados, theme.palette.background.paper, theme.palette.divider, theme.palette.primary.main])

  return (
    <>
      <PrivatePageHeader
        title={titulo}
        leftArea={leftArea()}
        rightArea={rightArea}
      />
    </>
  );
};
