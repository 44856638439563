import { Tooltip, Typography } from '@material-ui/core';
import { SituacaoMock } from 'data/mocks/situacao-mock';
import { EnumSituacao } from 'model/enums/enum-situacao';
import { useCallback } from 'react';
import { toDateString } from 'utils/to-date';
import { EditarIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { CardConsultaFaturaProps } from './card-fatura-props';
import {useStyles} from './card-consulta-fatura-styles'

export const CardConsultaFatura = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardConsultaFaturaProps) => {
  const classes = useDefaultCardStyles();
  const faturaClasses = useStyles();
  const { theme } = useThemeQueries()

  const retornarCorStatus = useCallback((): string => {
    switch (model.situacao) {
      case EnumSituacao.Aberta:
        return theme.palette.info.main
      case EnumSituacao.Paga:
        return theme.palette.success.main
      case EnumSituacao.Atraso:
        return theme.palette.warning.main
      case EnumSituacao.Bonificada:
        return '#D7096C'
      case EnumSituacao.Cancelada:
        return theme.palette.error.main
      case EnumSituacao.Fechada:
        return '#8346a6'
    }
    return theme.palette.info.main
  }, [model.situacao, theme.palette.error.main, theme.palette.info.main, theme.palette.success.main, theme.palette.warning.main]);

  const descricao = SituacaoMock.filter(
    (x) => x.Key === model.situacao,
  )[0].Value;

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model);
        }}
      >
        <Tooltip arrow title={descricao} placement="right">
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        <div className={`${classes.cardContent} ${faturaClasses.container}`}>
          <div className={`${classes.celulaGrid} ${faturaClasses.cardGrid}`}>
            <Typography color="primary" variant="caption">
              Nº
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              <b>{model.nossoNumero}</b>
            </Typography>
          </div>
          <div className={`${classes.celulaGrid}`}>
            <Typography color="primary" variant="caption">
              Vencimento
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              <b>{toDateString(model.dataVencimento)}</b>
            </Typography>
          </div>
          <div className={`${classes.celulaGridFull} ${faturaClasses.cardGrid}`}>
            <Typography color="primary" variant="caption">
              Descrição
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {model.descricao}
            </Typography>
          </div>
          <div className={`${classes.celulaGrid} ${faturaClasses.cardGrid}`}>
            <Typography color="primary" variant="caption">
              Emissão
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {toDateString(new Date(model.dataEmissao))}
            </Typography>
          </div>
          <div className={`${classes.celulaGrid} ${faturaClasses.cardGrid}`}>
            <Typography color="primary" variant="caption">
              Sistema
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {model.sistemaDescricao}
            </Typography>
          </div>
          <div className={`${classes.celulaGrid} ${faturaClasses.valores}`}>
            <Typography color="primary" variant="caption">
              Contratado
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              <b>{model.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b>
            </Typography>
          </div>
          <div className={`${classes.celulaGrid} ${faturaClasses.valores}`}>
            <Typography color="primary" variant="caption">
              Valor Pago
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              <b>{model.valorPago.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b>
            </Typography>
          </div>
        </div>
        <Tooltip arrow title="Editar Fatura">
          <div className={classes.cardRightButton}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
