import { guidEmpty } from "utils/guid-empty";
import { SistemasModel } from 'model/api/gestao/sistemas/sistemas-model';
import { TabelaPrecosModel } from "../tabela-preco/tabela-precos-model";

export class PessoaSistemaModel {
    constructor(
        public id: string = guidEmpty(),
        public pessoaId: string = guidEmpty(),
        public percentualOverTabela: number = 0,
        public tabelaPreco: TabelaPrecosModel = new TabelaPrecosModel(),
        public sistema: SistemasModel = new SistemasModel(),
        public dataDeAlteracao: string = ''
    ) { }
}