import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    button: {
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            border: 'none',
            color: theme.palette.background.paper
        }
    }
}))