import { Grid } from "@material-ui/core";
import { AgenteExternoEdit } from "./components/agente-externo-edit/agente-externo-edit";

type Props = {
  id: string
}

export const AgenteExternoEditar = ({ id }: Props) => {

  return (
    <div style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    }}>
      <Grid container spacing={1}
        style={{
          maxWidth: 1400,
          padding: 16,
        }}>
        <AgenteExternoEdit id={id} />
      </Grid>
    </div>
  );
};
