import { Box, Card, Divider, Grid, Typography } from '@material-ui/core';
import { RelatorioSistemaModel } from 'model/api/gestao/relatorios/sistema-relatorio-model';
import { useStyles } from '../dashboard-mapa/dashboard-mapa-styles'
import ChartSistemas from './components/chart-sistemas';


interface DashboardSistemasRelatorioProps {
    list: RelatorioSistemaModel[];
}


export default function DashboarSistemasRelatorio({ list }: DashboardSistemasRelatorioProps) {
    const classes = useStyles()
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Card className={classes.mapContainer}>
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Typography variant='h6' color='textPrimary'>Sistemas - Ativos</Typography>
                        <Typography variant='caption' color='textSecondary'>Registros: <b>{list.length}</b></Typography>
                    </Box>
                    <Divider />
                    <Grid container spacing={1} style={{ overflowY: 'auto', height: 225 }}>
                        <Box display='flex' alignItems='center' justifyContent='center' px={2} width='100%'>
                            <Box>
                                <ChartSistemas list={list} ativos />
                            </Box>
                        </Box>
                    </Grid>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card className={classes.mapContainer}>
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Typography variant='h6' color='textPrimary'>Sistemas - Inativos</Typography>
                        {list.length > 0 && <Typography variant='caption' color='textSecondary'>Registros: <b>{list.length}</b></Typography>}
                    </Box>
                    <Divider />
                    <Grid container spacing={1} style={{ overflowY: 'auto', height: 225 }}>
                        <Box display='flex' alignItems='center' justifyContent='center' px={2} width='100%'>
                            <Box>
                                <ChartSistemas list={list} ativos={false} />
                            </Box>
                        </Box>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
}
