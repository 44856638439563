import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { IndicacoesInteracoesPostModel } from 'model/api/gestao/indicacoes/indicacoes-interacoes-post-model';

export function usePostIndicacoesInteracoes() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postIndicacoesInteracoes = useCallback(
        (id: string, interacoes: IndicacoesInteracoesPostModel) =>
            invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/Indicacao/${id}/interacoes`,
                data: interacoes,
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postIndicacoesInteracoes,
    };
}
