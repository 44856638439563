import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export class IdentidadeIntegracaoProps {
    public constructor(
        public id: string = '',
        public documento: string = ''
    ) { }
}

export function usePostIdentidadeIntegracao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postIdentidadeIntegracao = useCallback(
        (autenticacaoProps: IdentidadeIntegracaoProps) =>
            invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/identidade/integracao`,
                data: autenticacaoProps,
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                enviarTokenUsuario: false
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postIdentidadeIntegracao,
    };
}