import { Button, Grid } from '@material-ui/core';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useRef, useCallback, useEffect } from 'react';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { useToastSaurus, useCadastros } from 'services/app';
import {
  SalvarNovoIcon,
  VoltarIcon,
} from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import classNames from 'classnames';
import { PagamentoModel } from 'model/api/gestao/pagamento/pagamento-model';
import { PagamentoFormModel } from 'model/app/forms/pagamento/pagamento-form-model';
import { usePostAdicionarPagamento } from 'data/api/gestao/pagamento';
import { FormPagamentoCadastro } from 'views/components/form/master/pagamento/pagamento-cadastro/form-pagamento-cadastro';

export const PagamentoCadastro = () => {

  const { postAdicionarPagamento, carregando } = usePostAdicionarPagamento();

  const { fecharCadastroPagamento } =
    useCadastros();
  const cadNovaPagamentoFormRef =
    useRef<DefaultFormRefs<PagamentoFormModel>>(null);
  const classes = useModalStyles();

  useEffect(() => {
    cadNovaPagamentoFormRef.current?.fillForm(
      new PagamentoFormModel(),
    );
  }, []);

  const { showToast } = useToastSaurus();

  const saveNewPagamento = useCallback(
    async (model: PagamentoModel) => {

      const ret = await postAdicionarPagamento(model);
      if (ret.erro) {
        throw ret.erro;
      }
    },
    [postAdicionarPagamento],
  );
  const handleSubmit = useCallback(
    async (model: PagamentoFormModel) => {
      try {

        const formaPagamento = { ...model, credenciais: model.credenciais ? JSON.stringify(model.credenciais) : '' }

        await saveNewPagamento(formaPagamento);
        fecharCadastroPagamento()
        showToast('success', "Nova Forma de Pagamento Adicionada!")
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [fecharCadastroPagamento, saveNewPagamento, showToast],
  );

  const onCloseClick = useCallback(() => {
    fecharCadastroPagamento()
  }, [fecharCadastroPagamento]);

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={'Nova Forma de Pagamento'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}
        >
          <FormPagamentoCadastro
            ref={cadNovaPagamentoFormRef}
            showLoading={false}
            loading={carregando}
            onSubmit={handleSubmit}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            
            <Grid item xs={12}>
              <Button
                disabled={carregando}
                onClick={() => {
                  cadNovaPagamentoFormRef.current!.submitForm()
                }
                }
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarNovoIcon tipo="BUTTON_PRIMARY" />
                Salvar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
