import { useStyles } from './button-add-e-sub-styles';
import { SwitchSaurus } from 'views/components/controles/switches/switch-saurus';
import { ModuloPlanoModel } from 'model/api/gestao/plano/modulo-plano-model';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { Typography } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
import { useThemeQueries } from 'views/theme';

interface ButtonAddESubProps {
  model: ModuloPlanoModel;
  alterarQtd: (model: ModuloPlanoModel, qtde: number) => void;
}

export const BotoesAddESub = ({
  model,
  alterarQtd,
}: ButtonAddESubProps) => {
  const classes = useStyles();
  const [erro, setErro] = useState<string>('')
  const { isMobile } = useThemeQueries()

  const alterarQuantidade = useCallback((qtd: string) => {
    if (model.quantidadeContratada < 0) {
      alterarQtd(model, 0)
      return
    }
    if (qtd.includes('-')) {
      if (model.quantidadeMinima === -1) {
        alterarQtd(model, -1)
        return
      } else {
        alterarQtd(model, model.quantidadeMinima)
        return
      }
    }

    const qtdN = Number(qtd)

    if (qtdN < model.quantidadeMinima) {
      setErro('A quantidade informada é menor que o permitido')
    }
    else if (qtdN > model.quantidadeMaxima && model.quantidadeMaxima > -1) {
      setErro('A quantidade informada é maior que o permitido.')
    } else if (qtdN > 999999999) {
      setErro('Quantidade não suportada.')
    }
    else {
      setErro('')
    }

    alterarQtd(model, qtdN)

  }, [alterarQtd, model])

  return (
    <div className={classes.containerQtde}>
      {model.quantidadeMaxima > 1 || model.quantidadeMaxima === -1 ? (
        <div>
          <div style={{
            display: 'flex',
            justifyItems: 'flex-end',
            maxWidth: 150,
            alignItems: 'center'
          }}>

            <TextFieldSaurus
              tipo={model.quantidadeMinima === -1 ? "TEXTO" : "NUMERO"}
              label="Quantidade"
              onChange={ev => alterarQuantidade(ev.target.value)}
              value={model.quantidadeContratada}
              size="small"
              error={!isEmpty(erro)}
            />
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 5
          }}>
            <div>
              <Typography variant="caption" color='primary'>
                Min:
              </Typography>
              <Typography variant="caption">
                {model.quantidadeMinima === -1 ? 0 : model.quantidadeMinima}
              </Typography>
            </div>
            <div>
              <Typography variant="caption" color='primary'>
                Max:
              </Typography>
              <Typography variant="caption">
                {model.quantidadeMaxima === -1 ? 9999999 : model.quantidadeMaxima}
              </Typography>
            </div>
          </div>
        </div>
      ) : (
        <div style={{
          display: 'flex',
          justifyContent: isMobile ? 'flex-start' : 'flex-end',
          width: '100%'
        }}>
          <SwitchSaurus
            variant='LIGHT'
            checked={model.quantidadeContratada === 1}
            onClick={() => {
              if (model.quantidadeContratada === 1) {
                return alterarQtd(model, 0)
              }

              alterarQtd(model, 1)
            }}
          />
        </div>
      )}
    </div>
  );
};

/* <Grid container style={{
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%'
}}>
  <Grid item xs={4}>
    <TextFieldSaurus
      tipo="NUMERO"
      label="Quantidade"
      disabled={model.quantidadeContratada === -1}
      onChange={ev => alterarQuantidade(Number(ev.target.value))}
      value={model.quantidadeContratada === -1 ? '' : model.quantidadeContratada}
      size="small"
      error={!isEmpty(erro)}
      style={{ marginBottom: 0 }}
    />
  </Grid>
  <Grid item xs={4}>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <SwitchSaurus
        variant='LIGHT'
        checked={model.quantidadeContratada === -1}
        onClick={() => {
          if (model.quantidadeContratada > -1) {
            return alterarQtd(model, -1)
          }

          alterarQtd(model, 0)
        }}
      />
      <Typography variant="caption">
        Ilimitado
      </Typography>
    </div>
  </Grid>
</Grid> */
