import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const MenuVerticalIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M25 16.6667C27.2917 16.6667 29.1667 14.7917 29.1667 12.5C29.1667 10.2083 27.2917 8.33334 25 8.33334C22.7084 8.33334 20.8334 10.2083 20.8334 12.5C20.8334 14.7917 22.7084 16.6667 25 16.6667ZM25 20.8333C22.7084 20.8333 20.8334 22.7083 20.8334 25C20.8334 27.2917 22.7084 29.1667 25 29.1667C27.2917 29.1667 29.1667 27.2917 29.1667 25C29.1667 22.7083 27.2917 20.8333 25 20.8333ZM20.8334 37.5C20.8334 35.2083 22.7084 33.3333 25 33.3333C27.2917 33.3333 29.1667 35.2083 29.1667 37.5C29.1667 39.7917 27.2917 41.6667 25 41.6667C22.7084 41.6667 20.8334 39.7917 20.8334 37.5Z" />
      </DefaultIcon>
    </>
  );
};
