import { PropTypes, Tooltip } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import { useButtonFabStyles } from "./button-fab-styles";
interface ButtonFabProps {
  tooltip: string;
  icon: JSX.Element;
  size?: 'small' | 'medium' | 'large';
  color?: PropTypes.Color;
  style?: React.CSSProperties;
  onClick: () => any;
}

export const ButtonFab = (props: ButtonFabProps) => {
  const classes = useButtonFabStyles();
  return (
    <>
      <Tooltip arrow title={props.tooltip} placement="right">
        <Fab size={props.size} color={props.color === undefined ? "primary" : props.color} className={classes.root} onClick={props.onClick} style={props.style}>
          {props.icon}
        </Fab>
      </Tooltip>
    </>
  );
};
