import { Box, Button, ClickAwayListener, Divider, Grid, Grow, MenuItem, MenuList, Paper, Popper, Tooltip, Typography } from "@material-ui/core"
import { SituacaoMock } from "data/mocks/situacao-mock"
import { FaturaModel } from "model/api/gestao/fatura/fatura-model"
import { FaturaFormModel } from "model/app/forms/fatura/fatura-form-model"
import { EnumSituacao } from "model/enums/enum-situacao"
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas"
import { forwardRef, useCallback, useMemo, useRef, useState } from "react"
import { useSessaoAtual } from "services/app"
import { DefaultFormRefs } from "views/components/form/utils"
import { BoletoIcon, CancelarIcon, ConfiguracaoIcon, DinheiroIcon, EditarIcon, FaturamentoIcon, QrCodeIcon, SalvarEditarIcon } from "views/components/icons"
import { useThemeQueries } from "views/theme"
import { useStyles } from './nota-fiscal-revenda-styles'
import { DialogFaturasFilhas } from "views/components/dialog/dialog-faturas-filhas/dialog-faturas-filhas"
import { isEmpty } from "lodash"
import { FormFaturaEdicao } from "views/components/form/master/fatura/fatura-edicao/form-fatura-edicao"
import { AccordionNotaFiscal } from "../../../accordions/accordion-nota-fiscal/accordion-nota-fiscal"
import { toDateString } from "utils/to-date"
import { formatarCPFCNPJ } from "utils/cpfcnpj-format"
import { DialogAnteciparFatura } from "views/components/dialog/dialog-antecipar-fatura/dialog-antecipar-fatura"
import { EnumTipoComunicacao } from "model"
import { GerenteIcon } from "views/components/icons/gerente-icon"
import { MaoMoedaIcon } from "views/components/icons/mao-moeda-icon"
import FaturaValores from "views/pages/private/faturas/components/faturas-edit/components/cliente-fatura/cliente-fatura-valores"
import { FaturaPagamentoModel } from "model/api/gestao/fatura/fatura-pagamento-model"
import { DialogStatus } from "../../notas-fiscais-editar"
import { PagamentoModel } from "model/api/gestao/pagamento/pagamento-model"
import { ConfirmarIcon } from "views/components/icons/confirmar-icon"
import { DialogConfirmarPagamento } from "views/components/dialog/dialog-confirmar-pagamento/dialog-confirmar-pagamento"
import { AccordionHistoricoFatura } from "../../../accordions/accordion-historico-fatura/accordion-historico-fatura"
import { CalendarioIcon } from "views/components/icons/calendario-icon"
import { RetornarIcon } from "views/components/icons/retornar-icon"
import { CalculadoraIcon } from "views/components/icons/calculadora-icon"

interface FaturaRefs extends DefaultFormRefs<FaturaFormModel> {
    validate(): Promise<boolean>;
    isFormEqual(form: FaturaFormModel): boolean;
}


interface Props {
    model: FaturaModel
    modelForm: FaturaFormModel
    funcoes: {
        bonificarFatura: () => boolean,
        cancelarFatura: () => boolean,
        reabrirFatura: () => boolean,
        preencherTela: () => Promise<void>,
        sincronizarERP: () => Promise<void>,
        recalcularFatura: () => Promise<void>,
    },
    carregando: boolean
    handleSubmit: (model: FaturaFormModel, baforeModel?: FaturaFormModel) => Promise<void>;
    pagarFatura: () => Promise<void>
    pagarPix: (formaPagamento: PagamentoModel) => Promise<void>;
    cadForm: React.RefObject<FaturaRefs>;
    setLiquidarDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenCompetencia: React.Dispatch<React.SetStateAction<boolean>>;
    setPixDialog: React.Dispatch<React.SetStateAction<FaturaPagamentoModel & DialogStatus>>;
}

export const NotasFiscaisRevendaInfo = forwardRef<
    DefaultFormRefs<FaturaFormModel>,
    Props
>(({ model, modelForm, funcoes, carregando, pagarFatura, handleSubmit, cadForm, pagarPix, setLiquidarDialog, setOpenCompetencia, setPixDialog }: Props, ref) => {

    const classes = useStyles()
    const { theme, isMobile } = useThemeQueries()
    const { tipoUsuario } = useSessaoAtual()

    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [openAnteciparFatura, setAnteciparFatura] = useState<boolean>(false);
    const [openDialogPagamento, setOpenDialogPagamento] = useState<boolean>(false)

    const [openSWOptions, setOpenSWOptions] = useState<boolean>(false)

    const formaPagamentoPix = model.formasPagamentosDisponiveis.find(fp => fp.tipoComunicacao === EnumTipoComunicacao.Pix)

    const swOptionsRef = useRef<HTMLButtonElement>(null)

    const isSoftwarehouse = ([EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto].includes(tipoUsuario()))

    const handleToggleOptions = () => {
        setOpenSWOptions((prevOpen) => !prevOpen);
    };


    const handleCloseOptions = (event: React.MouseEvent<EventTarget>) => {
        if (swOptionsRef.current && swOptionsRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpenSWOptions(false);
    };

    function handleListKeyDownOptions(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenSWOptions(false);
        }
    }

    const retornarCorStatus = useCallback((): string => {
        switch (model.situacao) {
            case EnumSituacao.Aberta:
                return theme.palette.info.main
            case EnumSituacao.Paga:
                return theme.palette.success.main
            case EnumSituacao.Atraso:
                return theme.palette.warning.main
            case EnumSituacao.Bonificada:
                return '#D7096C'
            case EnumSituacao.BonificadoRevenda:
                return '#D7096C'
            case EnumSituacao.Cancelada:
                return theme.palette.error.main
            case EnumSituacao.Fechada:
                return '#8346a6'
        }
        return theme.palette.info.main
    }, [model.situacao, theme.palette.error.main, theme.palette.info.main, theme.palette.success.main, theme.palette.warning.main]);

    const arrNumButtons = useMemo(() => {
        let arrayNumber: Array<number> = []

        if (([EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto].includes(tipoUsuario()))) {
            arrayNumber.push(-1)
            return arrayNumber
        }

        if (
            model.situacao !== EnumSituacao.Cancelada &&
            model.situacao !== EnumSituacao.Paga &&
            model.situacao !== EnumSituacao.Bonificada &&
            model.situacao !== EnumSituacao.BonificadoRevenda
        ) {
            arrayNumber.push(0)
        }

        if ((
            model.situacao === EnumSituacao.Aberta ||
            model.situacao === EnumSituacao.EmPagamento ||
            model.situacao === EnumSituacao.Atraso
        ) && tipoUsuario() === EnumTipoPessoas.SoftwareHouse) {
            arrayNumber.push(1)
        }

        if (model.situacao === EnumSituacao.Fechada || model.situacao === EnumSituacao.Cancelada || ((model.situacao === EnumSituacao.Paga || model.situacao === EnumSituacao.Bonificada || model.situacao === EnumSituacao.BonificadoRevenda) && tipoUsuario() === EnumTipoPessoas.SoftwareHouse &&
            (model.formaPagamento.tipoComunicacao === EnumTipoComunicacao.Dinheiro || model.formaPagamento.tipoComunicacao === EnumTipoComunicacao.Transferencia))) {
            arrayNumber.push(2)
        }

        if (model.situacao !== EnumSituacao.Cancelada && model.situacao !== EnumSituacao.Paga && model.situacao !== EnumSituacao.BonificadoRevenda && model.situacao !== EnumSituacao.Bonificada && ([EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto].includes(tipoUsuario()))) {
            arrayNumber.push(3)
        }

        return arrayNumber
    }, [model.formaPagamento.tipoComunicacao, model.situacao, tipoUsuario])

    // const formaPagamentoPix = useMemo(() => {
    //     if(model.formaPagamento.tipoComunicacao === EnumTipoComunicacao.Pix){
    //         return model.formaPagamento
    //     }
    //     const pix = model.formasPagamentosDisponiveis.find(fp => fp.tipoComunicacao === EnumTipoComunicacao.Pix)
    //     return !isEmpty(pix) ? pix : null
    // }, [model.formaPagamento, model.formasPagamentosDisponiveis])

    const desabilitarEdicao = model.situacao === EnumSituacao.Cancelada ||
        !isSoftwarehouse ||
        model.situacao === EnumSituacao.Paga ||
        model.situacao === EnumSituacao.Bonificada ||
        model.situacao === EnumSituacao.BonificadoRevenda ||
        model.situacao === EnumSituacao.EmPagamento


    return (
        <>
            <Grid item xs={12}>
                <Grid container className={classes.acoesCard} spacing={2}>
                    <Grid item xs={12} lg={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box display='flex' justifyContent='space-between' flexWrap='wrap' gridGap={theme.spacing(1)}>
                                    <div style={{
                                        padding: '8px 20px',
                                        background: retornarCorStatus(),
                                        textAlign: 'center',
                                        color: '#fff',
                                        fontWeight: 600,
                                        borderRadius: 4,
                                    }}>
                                        {SituacaoMock.find(item => item.Key === model.situacao)?.Value}
                                    </div>
                                    {!arrNumButtons.includes(-1) && <Button variant='outlined' color='primary' onClick={() => { setOpenCompetencia(true) }}>
                                        <EditarIcon tipo='BUTTON' />
                                        Editar Competência
                                    </Button>}
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Typography variant='caption' color='textSecondary'>Número da Fatura</Typography>
                                <Typography className={classes.boldText}>{model.nossoNumero}</Typography>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Typography variant='caption' color='textSecondary'>Ano/Mês</Typography>
                                <Typography className={classes.boldText}>{model.competenciaAnoMes}</Typography>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Typography variant='caption' color='textSecondary'>Data de Emissão</Typography>
                                <Typography className={classes.boldText}>{toDateString(model.dataEmissao, 'DD/MM/yyyy HH:mm')}</Typography>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Typography variant='caption' color='textSecondary'>Data do Registro</Typography>
                                <Typography className={classes.boldText}>{toDateString(model.dataRegistro, 'DD/MM/yyyy HH:mm')}</Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant='caption' color='textSecondary'>Descrição</Typography>
                                <Typography className={classes.boldText}>{model.descricao}</Typography>
                            </Grid>
                            {!isEmpty(model.dataSincronizacaoERP) && (
                                <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <Tooltip title={`Data de Sincronização: ${toDateString(model.dataSincronizacaoERP + 'Z', 'DD/MM/yyyy HH:mm')}`}>
                                        <Box>
                                            <CalendarioIcon tipo='BUTTON' />
                                        </Box>
                                    </Tooltip>
                                </Grid>
                            )}
                            {(model.situacao === EnumSituacao.Paga || model.situacao === EnumSituacao.Cancelada) && (
                                <>
                                    {/*Campos Especiais para faturas canceladas e pagas*/}
                                    <Grid item xs={12}>
                                        <Divider style={{ background: theme.palette.divider }} />
                                    </Grid>
                                    {(model.situacao === EnumSituacao.Paga) && (
                                        <>
                                            <Grid item xs={12} md={5}>
                                                <Typography variant='caption' color='textSecondary'>Data do Pagamento</Typography>
                                                <Typography>{toDateString(model.dataPagamento, 'DD/MM/yyyy HH:mm')}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <Typography variant='caption' color='textSecondary'>Valor Pago</Typography>
                                                <Typography>{model.valorPago.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Typography>
                                            </Grid>
                                        </>
                                    )}
                                    {model.situacao === EnumSituacao.Cancelada && (
                                        <>
                                            <Grid item xs={12} md={5}>
                                                <Typography variant='caption' color='textSecondary'>Data do Cancelamento</Typography>
                                                <Typography className={classes.boldText}>{toDateString(model.dataCancelamento + 'Z', 'DD/MM/yyyy HH:mm')}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <Typography variant='caption' color='textSecondary'>Motivo do Cancelamento</Typography>
                                                <Typography className={classes.boldText}></Typography>
                                            </Grid>
                                        </>
                                    )}
                                    {/*------------*/}
                                </>
                            )}
                            <Grid item xs={12}>
                                <Divider style={{ background: theme.palette.divider }} />
                            </Grid>
                            <Grid item md={8} xs={12} style={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
                                {!isMobile && <MaoMoedaIcon />}
                                <Box>
                                    <Typography variant='caption' color='textSecondary'>Pagador</Typography>
                                    <Typography>{model.pagador.nomeComercial}</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Typography variant='caption' color='textSecondary'>Pagador Documento</Typography>
                                <Typography>{formatarCPFCNPJ(model.pagador.cpfCnpj)}</Typography>
                            </Grid>
                            <Grid item md={8} xs={12} style={{ display: 'flex', gap: theme.spacing(2) }}>
                                {!isMobile && <GerenteIcon tipo='GERAL' style={{ width: 30, fill: theme.palette.primary.main }} />}
                                <Box>
                                    <Typography variant='caption' color='textSecondary'>Beneficiário</Typography>
                                    <Typography>{model.beneficiario.nomeComercial}</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Typography variant='caption' color='textSecondary'>Pagador Documento</Typography>
                                <Typography>{formatarCPFCNPJ(model.beneficiario.cpfCnpj)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Grid container className={classes.contentContainer} spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant='h6'>Valores</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider style={{ background: theme.palette.primary.main, marginBottom: theme.spacing(2) }} />
                            </Grid>
                            <Grid item xs={12}>
                                {desabilitarEdicao ? (
                                    <FaturaValores formaPagamentoNome={model.formaPagamento.nome} model={modelForm} tipoFatura={model.tipoFatura} />
                                ) : (
                                    <>
                                        <FormFaturaEdicao
                                            ref={cadForm}
                                            loading={carregando}
                                            showLoading={carregando}
                                            model={modelForm}
                                            onSubmit={handleSubmit}
                                            valorOriginal={model.valor - (model.encargo + model.multaJuros)}
                                            formaPagamento={model.formaPagamento}
                                            tipoFatura={model.tipoFatura}
                                        />
                                    </>
                                )}
                            </Grid>
                            {!isEmpty(model.faturasFilhas) && <Grid item xs={12}>
                                <Button variant='outlined' color='primary' onClick={() => setOpenDialog(true)} fullWidth>
                                    <FaturamentoIcon tipo='BUTTON' />
                                    Faturas
                                </Button>
                            </Grid>}
                            {!desabilitarEdicao &&
                                (
                                    <Grid item xs={12} md={12}>
                                        <Button
                                            disabled={carregando}
                                            onClick={() => cadForm.current?.submitForm()}
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                        >
                                            <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                                            Salvar
                                        </Button>
                                    </Grid>
                                )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider style={{ background: theme.palette.divider }} />
                    </Grid>
                    <Grid item xs={12} className={classes.acoesCardButtonsContainer}>
                        <div className={classes.acoesCardButtons}>
                            {!arrNumButtons.includes(-1) && <Button variant='outlined' color='primary' onClick={handleToggleOptions} ref={swOptionsRef}>
                                <ConfiguracaoIcon tipo='BUTTON' />
                                Opções
                            </Button>}
                            <Popper open={openSWOptions} anchorEl={swOptionsRef.current} placement="top" role={undefined} transition disablePortal style={{ zIndex: 999 }}>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleCloseOptions}>
                                                <MenuList autoFocusItem={openSWOptions} id="menu-list-grow" onKeyDown={handleListKeyDownOptions}>
                                                    <MenuItem onClick={funcoes.reabrirFatura}>
                                                        <Box display='flex' alignItems='center'>
                                                            <ConfirmarIcon tipo='BUTTON' />
                                                            <Typography color='primary'>Reabrir</Typography>
                                                        </Box>
                                                    </MenuItem>
                                                    <MenuItem onClick={funcoes.bonificarFatura}>
                                                        <Box display='flex' alignItems='center'>
                                                            <ConfirmarIcon tipo='BUTTON' />
                                                            <Typography color='primary'>Bonificar</Typography>
                                                        </Box>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => setLiquidarDialog(true)}>
                                                        <Box display='flex' alignItems='center'>
                                                            <DinheiroIcon tipo='BUTTON' />
                                                            <Typography color='primary'>Liquidar Manual</Typography>
                                                        </Box>
                                                    </MenuItem>
                                                    <MenuItem onClick={funcoes.sincronizarERP}>
                                                        <Box display='flex' alignItems='center'>
                                                            <RetornarIcon tipo='BUTTON' />
                                                            <Typography color='primary'>Sincronizar ERP</Typography>
                                                        </Box>
                                                    </MenuItem>
                                                    <MenuItem onClick={funcoes.recalcularFatura}>
                                                        <Box display='flex' alignItems='center'>
                                                            <CalculadoraIcon tipo='BUTTON' />
                                                            <Typography color='primary'>Recalcular Fatura</Typography>
                                                        </Box>
                                                    </MenuItem>
                                                    <MenuItem onClick={funcoes.cancelarFatura}>
                                                        <CancelarIcon tipo='BUTTON' />
                                                        <Typography color='primary'>Cancelar</Typography>
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                        <div className={classes.acoesCardButtons}>
                            {!isEmpty(model.boletoUrl) && <Button
                                disabled={carregando}
                                onClick={() => window.open(model.boletoUrl, '_blank')}
                                color='primary'
                                variant='contained'
                                fullWidth
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                <BoletoIcon tipo='BUTTON_PRIMARY' />
                                Visualizar Boleto
                            </Button>}
                            {(!isEmpty(model.pixCodigo)) && <Button
                                disabled={carregando}
                                onClick={() => setPixDialog({
                                    aberto: true,
                                    codigoDeBarras: '',
                                    linhaDigitavel: '',
                                    nossoNumero: model.nossoNumero,
                                    pixChave: '',
                                    pixCopiaCola: model.pixCodigo,
                                    url: model.boletoUrl,
                                    dhExpiracaoPix: model.dhExpiracaoPix
                                })}
                                color='primary'
                                variant='contained'
                                fullWidth
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                <QrCodeIcon tipo='BUTTON_PRIMARY' />
                                Visualizar PIX
                            </Button>}
                            {(isEmpty(model.pixCodigo) &&
                                !isEmpty(model.boletoUrl) &&
                                model.situacao !== EnumSituacao.Paga &&
                                model.situacao !== EnumSituacao.Bonificada &&
                                model.situacao !== EnumSituacao.BonificadoRevenda &&
                                model.situacao !== EnumSituacao.Cancelada &&
                                formaPagamentoPix) && (
                                    <Button variant='outlined' color='primary' onClick={() => setOpenDialogPagamento(true)} fullWidth style={{ whiteSpace: 'nowrap' }}>
                                        <QrCodeIcon tipo='BUTTON' />
                                        Pagar com PIX
                                    </Button>
                                )}
                            {(model.situacao !== EnumSituacao.Paga && model.situacao !== EnumSituacao.Bonificada && model.situacao !== EnumSituacao.BonificadoRevenda &&
                                model.situacao !== EnumSituacao.Cancelada && model.situacao !== EnumSituacao.EmPagamento) &&
                                <Button
                                    disabled={carregando}
                                    onClick={pagarFatura}
                                    variant='contained'
                                    color='primary'
                                    size='large'
                                    fullWidth
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    <DinheiroIcon tipo='BUTTON_PRIMARY' />
                                    Pagar Fatura
                                </Button>}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <AccordionNotaFiscal model={model} />
            </Grid>
            {model.historico.length > 0 && <Grid item xs={12}>
                <AccordionHistoricoFatura model={model} />
            </Grid>}
            <DialogFaturasFilhas
                faturas={model.faturasFilhas}
                closeDialog={() => setOpenDialog(false)}
                openned={openDialog}
                onClickCard={setAnteciparFatura}
                faturaId={model.id}
            />
            <DialogAnteciparFatura
                openned={openAnteciparFatura}
                faturaPaiId={model.id}
                preencherTela={funcoes.preencherTela}
                onClose={() => {
                    setAnteciparFatura(false)
                }}
            />
            <DialogConfirmarPagamento
                openned={openDialogPagamento}
                closeDialog={() => setOpenDialogPagamento(false)}
                formasPagamento={model.formasPagamentosDisponiveis}
                pagarPix={pagarPix}
            />
        </>
    )
})