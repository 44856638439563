import { guidEmpty } from "utils/guid-empty";

export class IndicacoesPutModel {
    constructor(
        public id: string = guidEmpty(),
        public responsavelId: string | null = null ,
        public indicadorId: string = guidEmpty(),
        public sistemaId: string | null= null,
        public data: string = '',
        public nivelDeInteresse: number = 0,
        public instrucao: string = '',
        public observacaoFinalizacao: string = '',
        public status: number = 0,
        public avaliacaoIndicador: number = 0,
        public motivoAvaliacaoIndicador: string = '',
        public avaliacaoResponsavel: number = 0,
        public motivoAvaliacaoResponsavel: string = '',
        public faturamentoEstimado: number = 0
    ){}
}