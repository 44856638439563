import { Card, Grid, Typography } from '@material-ui/core';
import { CardInstituicaoProps } from './card-tabela-preco-props';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useThemeQueries } from '../../..';
import { EditarIcon } from 'views/components/icons';
import { useStyles } from './card-tabela-preco-styles'

export const CardTabelaPreco = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardInstituicaoProps) => {
  const classes = useDefaultCardStyles();
  const tabelaClasses = useStyles();
  const { isMobile } = useThemeQueries();

  return (
    <>
      <Card
        onClick={() => onClick(model.id)}
        style={{
          cursor: 'pointer',
        }}>
        <Grid container className={`${classes.cardContent} ${tabelaClasses.container}`}>
          <Grid item xs={10} lg={11}>
            <div>
              <Typography
                color="primary"
                variant="caption"
                >
                Nome:
              </Typography>
            </div>
            <div >
              <Typography
                color="textPrimary"
                variant="body1"
                style={{
                  width: isMobile ? '280px' : '100%',
                  fontWeight: 600,
                }}
              >
                {model.nome}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={2} lg={1}>
            <div className={tabelaClasses.editIcon}>
              <EditarIcon tipo='BUTTON' />
            </div>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
