import { forwardRef, useImperativeHandle } from 'react';
import { Box, Grid, Button, Divider } from '@material-ui/core';
import { useFormPessoaSoftwareHouseValidation } from './form-pessoa-softwarehouse-validation';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
    TextFieldSaurus,

} from 'views/components/controles/inputs';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { picker } from 'utils/picker';
import { PessoaPostModel } from 'model/api/gestao/pessoa/pessoa-post-model';



interface FormPessoaEdicaoProps extends DefaultFormProps<PessoaPostModel> {

}

export const FormPessoaSoftwareHouse = forwardRef<
    DefaultFormRefs<PessoaPostModel>,
    FormPessoaEdicaoProps
>(
    (
        { loading, onSubmit, ...props }: FormPessoaEdicaoProps,
        ref,
    ) => {
        const { isMobile } = useThemeQueries();
        const utilClasses = makeUtilClasses();
        const { FormPessoaSoftwareHouseValidationYup } = useFormPessoaSoftwareHouseValidation()
        const { theme } = useThemeQueries()

        const {
            handleSubmit,
            control,
            formState: { errors, touchedFields },
            reset,
        } = useForm<PessoaPostModel>({
            resolver: yupResolver(FormPessoaSoftwareHouseValidationYup),
            criteriaMode: 'all',
            mode: 'onBlur' && 'onChange',
        });

        const submit = async (values: PessoaPostModel) => {
            const pessoa = picker<PessoaPostModel>(values, new PessoaPostModel())
            if(pessoa.melhorDia){
                pessoa.melhorDia = Number(pessoa.melhorDia)
            }
            onSubmit(pessoa);
        };

        const carregando = loading

        useImperativeHandle(ref, () => ({
            submitForm: () => {
                handleSubmit(submit)();
            },
            resetForm: () => {
                reset();
            },
            fillForm: (values: PessoaPostModel) => {
                reset({ ...values })
            },
        }));

        // const quantidadeDias = () => {
        //   let dias: Array<number> = [5, 20, 25, 30];
        //   return dias.map((item) => new KeyValueModel(item, item === 30 ? `${item} (Desabilitado)` : item.toString()));
        // };


        return (
            <>
                <Box my={2}>
                    <div className={utilClasses.formContainer} style={{
                        position: 'relative'
                    }}>
                        {carregando && props.showLoading ? (
                            <div className={utilClasses.controlLoading}>
                                <CircularLoading tipo="FULLSIZED" />
                            </div>
                        ) : null}

                        <form
                            onSubmit={handleSubmit(submit)}
                            className={carregando ? utilClasses.controlLoading : ''}
                            style={{ padding: !isMobile ? '0 10px' : '0' }}
                            autoComplete='off'
                        >
                            <Grid container spacing={2}>
                                <>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="horaInicioCobranca"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={loading}
                                                    allowSubmit={false}
                                                    label="Horário de Início de Cobrança"
                                                    tipo="NUMERO_RANGE"
                                                    fullWidth
                                                    autoComplete={'new-password'}
                                                    helperText={
                                                        touchedFields.horaInicioCobranca && errors.horaInicioCobranca
                                                            ? errors.horaInicioCobranca.message
                                                            : undefined
                                                    }
                                                    error={Boolean(errors.horaInicioCobranca && errors.horaInicioCobranca.message)}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="horaFimCobranca"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={loading}
                                                    allowSubmit={false}
                                                    label="Horário de Fim de Cobrança"
                                                    tipo="NUMERO_RANGE"
                                                    fullWidth
                                                    autoComplete={'new-password'}
                                                    helperText={
                                                        touchedFields.horaFimCobranca && errors.horaFimCobranca
                                                            ? errors.horaFimCobranca.message
                                                            : undefined
                                                    }
                                                    error={Boolean(errors.horaFimCobranca && errors.horaFimCobranca.message)}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider style={{ background: theme.palette.divider }} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="diasCancelamentoContrato"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={loading}
                                                    allowSubmit={false}
                                                    label="Dias para Cancelamento de Inadimplente"
                                                    tipo="NUMERO"
                                                    positivo
                                                    fullWidth
                                                    autoComplete={'new-password'}
                                                    helperText={
                                                        touchedFields.diasCancelamentoContrato && errors.diasCancelamentoContrato
                                                            ? errors.diasCancelamentoContrato.message
                                                            : undefined
                                                    }
                                                    error={Boolean(errors.diasCancelamentoContrato && errors.diasCancelamentoContrato.message)}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="melhorDia"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={loading}
                                                    allowSubmit={false}
                                                    label="Melhor Dia"
                                                    tipo="NUMERO"
                                                    positivo
                                                    fullWidth
                                                    autoComplete={'new-password'}
                                                    helperText={
                                                        touchedFields.melhorDia && errors.melhorDia
                                                            ? errors.melhorDia.message
                                                            : undefined
                                                    }
                                                    error={Boolean(errors.melhorDia && errors.melhorDia.message)}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </>
                            </Grid>
                            <Button style={{ display: 'none' }} type="submit"></Button>
                        </form>
                    </div>
                </Box>
            </>
        );
    },
);
