
import { isEmpty } from "lodash";

import { DefaultModal } from 'views/components/modals/components/default-modal';
import { PagamentoCadastro } from "./components/pagamento-cadastro/pagamento-cadastro";
import { PagamentoEdicao } from "./components/pagamento-edicao/pagamento-edicao";


type Props = {
  openned: boolean;
  id: string;
}

export const PagamentoModal = ({ id, openned }: Props) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {openned && isEmpty(id) && <PagamentoCadastro />}
      {openned && !isEmpty(id) && <PagamentoEdicao id={id} />}
    </DefaultModal>
  );
};
