import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        '& p': {
            fontSize: '12px !important'
        }
    },
    valores: {
        [theme.breakpoints.up('lg')]: {
            textAlign: 'right'
        }
    },
    data: {
        [theme.breakpoints.up('lg')]: {
            textAlign: 'center'
        }
    }
}))