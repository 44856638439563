import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    cardModulosContainer: {
        width: '100%',
        margin: '0 10px',
        display: 'flex',
        flexWrap: 'nowrap'
    },
    tituloModulo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            '& span': {
                display: 'none'
            },
            '& p': {
                color: theme.palette.primary.main,
                fontSize: '1rem'
            }
        }
    },
    botaoQtd: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: theme.spacing(1),
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start'
        },
        '& svg': {
            display: 'none'
        }
    },
    infoQtd: {
        position: 'absolute',
        left: -20,
        top: '50%',
        transform: 'translateY(-50%)',
        width: 18,
        zIndex: 999999,
        '& svg': {
            width: 18
        }
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: 0
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: 0
        }
    },
    custoTotal: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        },
    },
    percAdicional: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start'
        }
    },
    valorTotal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
        }
    },
    valorContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    campoDesconto: {
        textDecoration: 'line-through',
        fontSize: 14,
        color: theme.palette.warning.dark,

    }
}))