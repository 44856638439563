import { useEffect, useState, useCallback } from "react";

import { Grid } from "@material-ui/core";
import { useStyles } from "./pagamento-list-styles";
import { PagamentoListData } from "./pagamento-list-data";
import { Paginacao } from "views/components/paginacao";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import { useCadastros, useToastSaurus } from "services/app";
import { PagamentoListSearchProps } from "./pagamento-list-search-props";
import { useHistory } from "react-router-dom";
import { PagamentoModel } from "model/api/gestao/pagamento/pagamento-model";
import { useGetPagamento } from "data/api/gestao/pagamento/get-pagamento";
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles";

export interface PagamentoListProps {
  searchProps: PagamentoListSearchProps;
}

export const PagamentoList = (props: PagamentoListProps) => {
  const classes = useStyles();
  const listClasses = useDefaultCadastroStyles();

  const { getPagamento, carregando } = useGetPagamento();
  const { abrirCadastroPagamento, cadastroPagamentoState } = useCadastros()

  const { showToast } = useToastSaurus();
  const history = useHistory();

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<PagamentoModel>(),
  });

  const [modalEditAberto, setModalEditAberto] = useState(false);

  const [selectedList, setSelectedList] = useState<Array<string>>([]);

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<PagamentoModel>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    []
  );

  const search = useCallback(
    async (newPage: number) => {
      try {
        const res = await getPagamento(newPage, `pageSize=18&query=${props.searchProps.termo}`);
        if (res.erro) throw res.erro;

        if (!res.resultado) return
        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list
        );

        return res;
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [getPagamento, props.searchProps.termo, fillResult, showToast]
  );

  useEffect(() => {
    if (cadastroPagamentoState.aberto === false && modalEditAberto) {
      search(queryStatus.page);
    }
    setModalEditAberto(cadastroPagamentoState.aberto);
  }, [cadastroPagamentoState.aberto, modalEditAberto, queryStatus.page, search]);

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  const onCardSelected = (id: string) => {
    abrirCadastroPagamento(id, history.location.pathname, true);
  };

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  useEffect(() => {
    search(queryStatus.page);
  }, [queryStatus.page, search]);

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid container className={listClasses.listContainer}>
          <Paginacao
            pageChanged={pageChanged}
            totalPages={queryStatus.totalPages}
            totalRegisters={queryStatus.totalResults}
            currentPage={queryStatus.page}
          />
          <Grid item xs={12} className={classes.listContainer}>
            <Grid container spacing={1}>
              <PagamentoListData
                carregando={carregando}
                list={queryStatus.list}
                selectedList={selectedList}
                onCardSelected={onCardSelected}
                onCardChecked={onCardChecked}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
