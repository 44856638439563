import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  TextFieldSaurus,

} from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { TpComunicacaoMock } from 'data/mocks';
import { InstituicaoPagamentoMock } from 'data/mocks/instituicao-pagamento-mock';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { isEmpty } from 'lodash';
import { CredenciamentoItauBoleto, CredenciamentoSafraPix, PagamentoFormModel } from 'model/app/forms/pagamento/pagamento-form-model';
import { useCredenciaisPagamento } from 'services/app/use-cases/credenciais-pagamento';
import { compararChavesObjetos } from 'utils/comparar-objetos';
import { CredencialIcon } from 'views/components/icons/credencial-icon';

export const FormPagamentoEdicao = forwardRef<
  DefaultFormRefs<PagamentoFormModel>,
  DefaultFormProps<PagamentoFormModel>
>(
  (
    { loading, onSubmit, ...props }: DefaultFormProps<PagamentoFormModel>,
    ref,
  ) => {
    const { isMobile } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);
    const [model, setModel] = useState<PagamentoFormModel>(new PagamentoFormModel())
    const [instituicao, setInstituicao] = useState<number>(-1)
    const [tipoComunicacao, setTipoComunicacao] = useState<number>(-1)

    const [accordion, setAccordion] = useState<boolean>(false)


    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
      getValues,
      setValue,
      getFieldState,
      clearErrors
    } = useForm<PagamentoFormModel>({
      defaultValues: { ...model },
      criteriaMode: 'all',
      mode: 'onBlur' && 'onChange',
    });

    const submit = (values: PagamentoFormModel) => {
      if(isCustomizadas){
        values.credenciais = credenciaisCustomizadas
      }
      onSubmit(values, model);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submit)();
      },
      resetForm: () => {
        reset();
        if (!isMobile) refInputNome.current?.focus();
      },
      fillForm: (values: PagamentoFormModel) => {
        setModel(values)
        reset({ ...values })
        setTipoComunicacao(values.tipoComunicacao)

        if (values.instituicao) {
          setInstituicao(values.instituicao)
          if(values.credenciais)
            setValue('credenciais.instituicao', values.instituicao)
        }

        //Verificando credenciais customizadas
        if(!isEmpty(values.credenciais)){
          const credenciaisExistentes = [
            new CredenciamentoItauBoleto(),
            new CredenciamentoSafraPix()
          ]
          let isCustom = credenciaisExistentes.reduce((prev, curr, index) => {
            return compararChavesObjetos(values.credenciais, curr) || (index > 0 && !prev )? false : true
          }, false)
          if(isCustom && !isCustomizadas){
            habilitarDesabilitarCustomizadas()
            setarCredenciaisCustomizadas(values.credenciais as Record<string, string>)
          }
        }
        if (!isMobile) refInputNome.current?.focus();
      },
    }));

    useEffect(() => {
      setValue('credenciais', model.credenciais)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instituicao, tipoComunicacao])

    //Caso tenham novas formas de pagamento credenciadas, adicionar os campos dentro do hook
    const { getCampos, setCredenciais, habilitarDesabilitarCustomizadas, isCustomizadas, setarCredenciaisCustomizadas, credenciaisCustomizadas } = useCredenciaisPagamento({
      control,
      getFieldState,
      setValue,
      clearErrors,
      getValues,
      loading,
    })

    const accordionCredenciais = () => {
      const campos = getCampos(instituicao, tipoComunicacao)

      if(isEmpty(campos)){
        return null
      }

      return (<AccordionSaurus
        labelPrimary='Credenciais'
        expanded={accordion}
        onChange={() => setAccordion(prev => !prev)}
      >
        <Grid container spacing={2}>
          {campos}
        </Grid>
      </AccordionSaurus>
      )
    }

    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {loading && props.showLoading ? (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="NORMAL" />
              </div>
            ) : null}

            <form
              onSubmit={handleSubmit(submit)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Box my={3}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Controller
                      name="nome"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          inputRef={refInputNome}
                          disabled={loading}
                          allowSubmit={false}
                          label="Nome da Forma de Pagamento"
                          fullWidth
                          autoComplete={'off'}
                          helperText={
                            touchedFields.nome && errors.nome
                              ? errors.nome.message
                              : undefined
                          }
                          error={Boolean(errors.nome && errors.nome.message)}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="tipoComunicacao"
                      control={control}
                      render={({ field }) => (
                        <SelectSaurus
                          variant='outlined'
                          disabled={loading}
                          label="Tipo de Comunicação"
                          fullWidth
                          conteudo={TpComunicacaoMock}
                          autoComplete={'off'}
                          helperText={
                            touchedFields.tipoComunicacao && errors.tipoComunicacao
                              ? errors.tipoComunicacao.message
                              : undefined
                          }
                          error={Boolean(
                            errors.tipoComunicacao && errors.tipoComunicacao.message,
                          )}
                          {...field}
                          value={getValues('tipoComunicacao')}
                          onChange={ev => {
                            const item =
                              TpComunicacaoMock
                                .filter(item => item.Key === ev.target.value)

                            setValue('tipoComunicacao', item[0].Key)
                            setTipoComunicacao(item[0].Key)
                            setCredenciais(instituicao, item[0].Key)
                          }}

                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="instituicao"
                      control={control}
                      render={({ field }) => (
                        <SelectSaurus
                          variant='outlined'
                          conteudo={InstituicaoPagamentoMock}
                          disabled={loading}
                          label="Instituição"
                          fullWidth
                          autoComplete={'off'}
                          helperText={
                            touchedFields.instituicao && errors.instituicao
                              ? errors.instituicao.message
                              : undefined
                          }
                          error={Boolean(
                            errors.instituicao && errors.instituicao.message,
                          )}
                          {...field}
                          value={instituicao}
                          onChange={ev => {
                            const item = InstituicaoPagamentoMock.filter(item => item.Key === ev.target.value)
                            if (item) {
                              setValue('instituicao', item[0].Key)
                              setInstituicao(item[0].Key)
                              setCredenciais(item[0].Key, tipoComunicacao)
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {accordionCredenciais()}
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant='outlined' color='primary' onClick={() => {
                      habilitarDesabilitarCustomizadas()
                      if(!accordion) setAccordion(true)
                    }} fullWidth>
                      <CredencialIcon tipo='BUTTON'/>
                      {isCustomizadas ? 'Desabilitar Customizadas' : 'Credenciais Customizadas'}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
