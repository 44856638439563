import { guidEmpty } from "utils/guid-empty"

export class TerminalHistoricoModel{
    constructor(
        public id: string = guidEmpty(),
        public terminalId: string = guidEmpty(),
        public tipo: string = '',
        public chave: string = '',
        public ipLocal: string = '',
        public ipPublico: string = '',
        public maquina: string = '',
        public versao: string = '',
        public sistemaOperacional: string = '',
        public serialHd: string = '',
        public dataAcesso: string = '',
        public status: number = 0,
    ){

    }
}