import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { ApiListModel } from 'model/api/gestao/api-list-model/api-list-model';
import { PessoaPagamentoModel } from 'model/api/gestao/pessoa/pessoa-pagamento-model';

export function useGetPessoaFormaPagamentoById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ApiListModel<PessoaPagamentoModel>>();

    const getPessoaById = useCallback(
        (pessoaId: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/pessoa/${pessoaId}/forma-pagamento`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getPessoaById,
    };
}
