import { Button, Grid } from '@material-ui/core';
import { useRef } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import React from 'react';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { EnviarEmail } from 'model/api/gestao/nota-fiscal/nota-fiscal-enviar-email-danfe';

export const FormEnviarEmail = React.forwardRef<
  DefaultFormRefs<EnviarEmail>,
  DefaultFormProps<EnviarEmail>
>((props: DefaultFormProps<EnviarEmail>, refs) => {
  const txtEmail = useRef<HTMLInputElement>();
  const utilClasses = makeUtilClasses();
  const { isMobile } = useThemeQueries();

   const {
    control,
    formState: { errors, touchedFields },
    getValues,
    reset,
    handleSubmit
  } = useForm<EnviarEmail>({
    criteriaMode: 'all',
    mode: 'onBlur' && 'onChange',
  });

  const onSubmit = (form: EnviarEmail) => {
    props.onSubmit(form);
  };

  React.useImperativeHandle(refs, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      if (!isMobile) txtEmail?.current?.focus();
    },
    fillForm: (model: EnviarEmail) => {
      reset({ ...model });
      if (!isMobile) txtEmail?.current?.focus();
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12}>
               <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  tipo="EMAIL"
                  fullWidth={true}
                  allowSubmit={false}
                  autoComplete={'off'}
                  label="E-mail"
                  placeholder="Digite seu email"
                  error={Boolean(
                    errors.email && errors.email.message,
                  )}
                  helperText={
                    touchedFields.email || errors.email
                      ? errors.email?.message
                      : undefined
                  }
                  {...field}
                  value={getValues('email')}
                />
              )}
            />
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
