import { useMemo } from 'react';
import * as Yup from 'yup';

interface Props{
    isRetaguarda: boolean;
    isTipoRevenda: boolean;
    ambiente: boolean;
}


export const useFormContratoEdicaoValidation = ({isTipoRevenda,ambiente}: Props) => {

    const FormContratoEdicaoValidationYup = useMemo(() => {
        return (
            Yup.object().shape({
                sistemaId: Yup.string().required('Precisa ser selecionado.'),
                formaPagamentoId: isTipoRevenda ? Yup.string().notRequired().nullable(true) : Yup.string().required('Forma de Pagamento é obrigatória.').typeError('Forma de Pagamento é obrigatória.'),
                ambienteId: ambiente ? Yup.string().required('Ambiente é obrigatório').typeError('Ambiente é obrigatório') : Yup.string().notRequired().nullable(true)

            })
        )
    }, [ambiente, isTipoRevenda])

    return {
        FormContratoEdicaoValidationYup
    }
}

