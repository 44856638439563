import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePostSincronizarERP() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postSincronizarERP = useCallback(
        (faturaId: string) =>
            invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/Fatura/${faturaId}/sincronizar-erp`,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postSincronizarERP,
    };
}
