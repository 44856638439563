import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
      '& p': {
        fontSize: '12px !important'
      }
    },
    item: {
      marginLeft: 7,
      minWidth: 100,
      maxWidth: 200,
      [theme.breakpoints.down('md')]: {
        margin: '4px 0',
        marginRight: theme.spacing(2)
      }
    },
  }))