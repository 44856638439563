import { DefaultModal } from 'views/components/modals/components/default-modal';
import { useThemeQueries } from 'views/theme';
import { ClienteCadastro } from "./components/cliente-cadastro/cliente-cadastro";

type Props = {
  openned: boolean;
  id: string;
  revenda: boolean;
}

export const ClienteModal = ({ id, openned, revenda }: Props) => {
  const { isMobile } = useThemeQueries()
  return (
    <DefaultModal
      minWidth={isMobile ? '0' : "600px"}
      open={openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {openned && <ClienteCadastro revenda={revenda} />}
    </DefaultModal>
  );
};
