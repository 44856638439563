import { useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { useParams } from 'react-router-dom';
import { FuncionariosListSearchProps } from './components/funcionarios-list/funcionarios-list-search-props';
import { FuncionariosHeader } from './components/funcionarios-header/funcionario-header';
import { FuncionariosList } from './components/funcionarios-list/funcionarios-list';
import { FuncionariosEditar } from './components/funcionarios-editar/funcionarios-editar';

interface paramProps {
  parametro: string;
  tipo: string;
}

export const ClientesPage = () => {
  const classes = useDefaultCadastroStyles();

  const [openPesquisa, setOpenPesquisa] = useState(false);
  const [searchProps, setSearchProps] = useState<FuncionariosListSearchProps>({
    termo: '',
  });
  const { parametro } = useParams<paramProps>()

  const pageSwitch = useMemo(() => {
    setOpenPesquisa(false)
    switch (parametro) {
      case undefined:
        return <FuncionariosList searchProps={searchProps}/>
      default:
        return (
          <div className={classes.defaultContainer}>
            <FuncionariosEditar id={parametro}/>
          </div>
        )
    }
  }, [classes.defaultContainer, parametro, searchProps])

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <FuncionariosHeader
          titulo={parametro === undefined
            ? 'Funcionários'
            : parametro === 'adicionar'
              ? 'Adicionar Funcionário'
              : 'Editar Funcionário'}
          openPesquisa={openPesquisa}
          setSearchProps={setSearchProps}
          setOpenPesquisa={setOpenPesquisa}
        />
      </Grid>
      {pageSwitch}
    </Grid>
  );
};

export default ClientesPage;
