import { RevendaDesempenhoByIdModel } from "model/api/gestao/revendedor/revenda-desempenho-by-id-model";
import { CardNaoEncontrado } from "views/components/cards";
import { CardDesempenhoRevendaById } from "views/components/cards/card-desempenho-revenda/card-desempenho-revenda-by-id";
import { InformacaoIcon } from "views/components/icons";

export interface DesempenhoRevendaListDataByIdProps {
  list: Array<RevendaDesempenhoByIdModel>;
  carregando: boolean;
  onClick: (anoMes: number) => any;
}

export const DesempenhoRevendaByIdListData = (props: DesempenhoRevendaListDataByIdProps) => {

  const onCardClick = (anoMes: number) => {
    props.onClick(anoMes);
  };
  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem='Nenhum dado de desempenho encontrado.'
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props?.list.length > 0 &&
        props.list.map((model, index) => {
          return (
            <CardDesempenhoRevendaById
                model={model}
                onClick={onCardClick}
            />
          );
        })}
    </>
  );
};