import { ClienteIdealPerguntaModel } from "model/api/gestao/cliente-ideal/cliente-ideal-pergunta-model";
import { useModalStyles } from "../../utils/modal-styles";
import { Button, Grid } from "@material-ui/core";
import { SalvarNovoIcon, VoltarIcon } from "views/components/icons";
import { useEffect, useRef, useState } from "react";
import { DefaultFormRefs } from "views/components/form/utils";
import { ModalHeader } from "../../components";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header";
import { useCadastros, useToastSaurus } from "services/app";
import { PerguntaApuracaoFormModel } from "model/app/forms/pergunta-apuracao/pergunta-apuracao-form-model";
import { picker } from "utils/picker";
import { guidEmpty } from "utils/guid-empty";
import { FormPerguntaApuracao } from "views/components/form/master/pergunta-apuracao/form-pergunta-apuracao";
import { CircularLoading } from "views/components/utils";
import { CardApuracaoResposta } from "views/components/cards/card-apuracao-resposta/card-apuracao-resposta";
import { DialogApuracaoResposta } from "views/components/dialog/dialog-apuracao-resposta/dialog-apuracao-resposta";
import { ClienteIdealRespostaModel } from "model/api/gestao/cliente-ideal/cliente-ideal-resposta-model";
import { AddIcon } from "views/components/icons/add-icon";

interface DialogRespostaStateProps {
    aberto: boolean;
    model: ClienteIdealRespostaModel;
    index: number;
}

type Props = {
    model: ClienteIdealPerguntaModel;
    finalizar: (model: ClienteIdealPerguntaModel) => Promise<boolean>;
}

export const PerguntasApuracao = ({
    finalizar,
    model
}: Props) => {

    const classes = useModalStyles();
    const { fecharCadastroPergunta } = useCadastros();
    const { showToast } = useToastSaurus();

    const refForm = useRef<DefaultFormRefs<PerguntaApuracaoFormModel>>(null)
    const refNovo = useRef<boolean>(false)
    const maxPontos = useRef<number>(model.maxPontos);

    const [perguntaModel, setPerguntaModel] = useState<ClienteIdealPerguntaModel>(model)
    const [dialogResposta, setDialogResposta] = useState<DialogRespostaStateProps>({
        aberto: false,
        model: new ClienteIdealRespostaModel(),
        index: 0
    })
    const [loading, setLoading] = useState<boolean>(false);

    const novaPergunta = perguntaModel.id === guidEmpty();

    useEffect(() => {
        const formModel = picker<PerguntaApuracaoFormModel>(model, new PerguntaApuracaoFormModel());
        refForm.current?.fillForm(formModel);
    }, [model])

    const handleSubmit = async (model: PerguntaApuracaoFormModel) => {
        try {
            setLoading(true)
            const completeModel: ClienteIdealPerguntaModel = {
                ...perguntaModel,
                ativo: model.ativo,
                detalhe: model.detalhe,
                maxPontos: model.maxPontos,
                pergunta: model.pergunta,
                interna: model.interna
            }

            const ret = await finalizar(completeModel)

            if (ret) {
                showToast('success', novaPergunta ? 'Pergunta Cadastrada com sucesso.' : 'Pergunta Alterada com sucesso');
                if (refNovo.current) {
                    setPerguntaModel(new ClienteIdealPerguntaModel());
                    return
                }
                fecharCadastroPergunta();
            }
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }

    const fecharDialogResposta = () => {
        setDialogResposta(prev => ({
            ...prev,
            aberto: false
        }))
    }

    const abrirDialogResposta = (index: number) => {
        const model = index > -1 ? { ...perguntaModel.respostas[index] } : new ClienteIdealRespostaModel();

        setDialogResposta({
            aberto: true,
            model,
            index
        })
    }

    const submitResposta = (model: ClienteIdealRespostaModel, index: number) => {
        const cloneArr = [...perguntaModel.respostas]
        //-1 é caso seja uma nova pergunta
        if (index > -1) {
            cloneArr[index] = model;
        } else {
            cloneArr.push(model)
        }

        setPerguntaModel(prev => ({
            ...prev,
            respostas: cloneArr
        }))
        showToast('success', 'Respostas Atualizadas');
        fecharDialogResposta()
    }
    return (
        <div className={classes.root}>
            <ModalHeader
                title={novaPergunta ? 'Cadastrar Pergunta' : 'Editar Pergunta'}
                leftArea={
                    <ButtonModalHeader
                        tooltip="Voltar"
                        icon={<VoltarIcon tipo="MODAL_HEADER" />}
                        onClick={() => fecharCadastroPergunta()}
                    />
                }
            />
            <div className={classes.content}>
                {loading && <CircularLoading tipo='FULLSIZED' />}
                <div
                    className={classes.contentForms}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormPerguntaApuracao
                                loading={false}
                                showLoading={false}
                                onSubmit={handleSubmit}
                                ref={refForm}
                                changePontosMaximos={(pontos) => {
                                    maxPontos.current = pontos;
                                }}
                            />
                        </Grid>
                        {perguntaModel.respostas.map((resposta, index) => (
                            <Grid item xs={12}>
                                <CardApuracaoResposta
                                    index={index}
                                    model={resposta}
                                    onClick={abrirDialogResposta}
                                />
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <Button
                                color='primary'
                                variant='outlined'
                                fullWidth
                                onClick={() => {
                                    abrirDialogResposta(-1)
                                }}
                            >
                                <AddIcon tipo='BUTTON' />
                                Nova Resposta
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.acoes}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={7}>
                            <Button
                                onClick={() => {
                                    refNovo.current = true
                                    refForm.current?.submitForm()
                                }}
                                variant="outlined"
                                color="primary"
                                size="large"
                                fullWidth
                            >
                                <SalvarNovoIcon tipo="BUTTON" />
                                Salvar e Adicionar Novo
                            </Button>
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <Button
                                onClick={() => {
                                    refNovo.current = false
                                    refForm.current?.submitForm()
                                }}
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                            >
                                <SalvarNovoIcon tipo="BUTTON_PRIMARY" />
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
            {dialogResposta.aberto && (
                <DialogApuracaoResposta
                    close={() => fecharDialogResposta()}
                    maxPontos={maxPontos.current}
                    model={dialogResposta.model}
                    onSubmit={submitResposta}
                    openned
                    index={dialogResposta.index}
                />
            )}
        </div>
    )
}