import { Button, Grid, Slide } from "@material-ui/core";
import classNames from "classnames";
import { ContratoFormModel } from "model/app/forms/contrato/contrato-form-model";
import { useEffect, useRef } from "react";
import { useCadastros } from "services/app";
import { FormContratoCadastroPrimeiraFase } from "views/components/form/master/contrato/contrato-cadastro/form-contrato-cadastro-primeira-fase";
import { DefaultFormRefs } from "views/components/form/utils";
import { AvancarIcon, CancelarIcon } from "views/components/icons";
import { useModalStyles } from "views/components/modals/utils/modal-styles";

interface ContratoPrimeiraFaseProps {
    carregando: boolean
    contratoForm: ContratoFormModel
    handleSubmit: (model: ContratoFormModel) => void;
    fase: 1 | 2,
    setBottomArea: (node: React.ReactNode) => void;
    revendaId: string;
    pessoaId: string;
    setSistemaNome: (nome: string) => void;
}

export const ContratoPrimeiraFase = ({ carregando, contratoForm, handleSubmit, fase, setBottomArea, revendaId, pessoaId, setSistemaNome }: ContratoPrimeiraFaseProps) => {

    const classes = useModalStyles()

    const { fecharCadastroContrato } = useCadastros()

    const cadFormRef =
        useRef<DefaultFormRefs<ContratoFormModel>>(null);

    useEffect(() => {
        cadFormRef.current?.fillForm(contratoForm)
    }, [contratoForm])

    useEffect(() => {
        setBottomArea(
            <div className={classes.acoes}>
                <Grid container spacing={2} justifyContent='flex-end'>
                    <Grid item xs={6} md={2}>
                        <Button
                            disabled={carregando}
                            onClick={() => fecharCadastroContrato(`/clientes/${pessoaId}`)}
                            variant='outlined'
                            color='primary'
                            fullWidth
                        >
                            <CancelarIcon tipo='BUTTON' />
                            Fechar
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Button
                            disabled={carregando}
                            onClick={() => {
                                cadFormRef.current!.submitForm()
                            }
                            }
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            <AvancarIcon tipo="BUTTON_PRIMARY" />
                            Continuar
                        </Button>
                    </Grid>
                </Grid>
            </div>
        )
    }, [carregando, classes.acoes, fecharCadastroContrato, pessoaId, setBottomArea])

    return (
        <Slide in={fase === 1} direction="right">
            <div style={{
                flex: 1,
                height: "100%",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}>
                <div className={classes.content} style={{ background: 'transparent' }}>
                    <div
                        className={classNames(
                            classes.contentForms,
                            carregando ? classes.contentFormsLoading : undefined,
                        )}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: 'auto'
                        }}
                    >
                        <FormContratoCadastroPrimeiraFase
                            ref={cadFormRef}
                            showLoading={true}
                            loading={carregando}
                            onSubmit={handleSubmit}
                            revendaId={revendaId}
                            setSistemaNome={setSistemaNome}
                        />
                    </div>
                </div>
            </div>
        </Slide>
    )
}