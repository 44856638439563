
import { useMemo } from 'react';
import { useValidationYupDocumento, useValidationYupSenha } from 'views/components/form-validations';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

export const useFormAgenteExterno = () => {

    const { documentoYup } = useValidationYupDocumento(false)
    const { senhaYup } = useValidationYupSenha()

    const formAgenteExternoValidationYup = useMemo(() => {
        return Yup.object().shape<AnyObject>({
            nomeComercial: Yup.string().required('O nome é obrigatório.'),
            cpfCnpj: documentoYup(),
            senha: senhaYup().transform(val => val === '' ? null : val).notRequired().nullable(true)
        });
    }, [documentoYup, senhaYup])

    return {
        formAgenteExternoValidationYup,
    }
}