import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export class IdentidadeAutenticarProps {
    public constructor(
        public login: string = '',
        public senha: string = ''
    ) { }
}

export function usePostIdentidadeAutenticar() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postIdentidadeAutenticar = useCallback(
        (autenticacaoProps: IdentidadeAutenticarProps) =>
            invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/identidade/autenticar`,
                data: autenticacaoProps,
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                enviarTokenUsuario: false
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postIdentidadeAutenticar,
    };
}
