import { Grid, Typography } from '@material-ui/core';
import { CardModulosContratadosProps } from './card-modulos-planos-props';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { BotoesAddESub } from './components/button-add-e-sub/button-add-e-sub';

export const CardModuloPlano = ({
  model,
  alterarQtd
}: CardModulosContratadosProps) => {
  const classes = useDefaultCardStyles();

  return (
    <>
      <DefaultCard>
        <Grid container spacing={2} className={classes.cardContent}>
          <Grid item xs={12} sm={8}>
            <Typography variant="caption" color='primary'>
              Nome
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
              style={{ fontWeight: 600 }}
            >
              {model.nomeModulo}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <BotoesAddESub
              model={model}
              alterarQtd={alterarQtd}
            />
          </Grid>
        </Grid>
      </DefaultCard>
    </>
  );
};
