import * as Yup from 'yup';

export const useAccordionindicacaoInteresseValidation = () => {
    const FormIndicacaointeresseYupValidation = Yup.object().shape({
        nivelDeInteresse: Yup.number().required('O nivel de interesse é obrigatório.').typeError('É necessario ser um número.'),
        instrucao: Yup.string().min(50, 'Deve ter pelo menos 50 caracteres.').required('A instrução para atuação é obrigatório')
    });

    return {
        FormIndicacaointeresseYupValidation
    }
}

