import { guidEmpty } from "utils/guid-empty";
import { ModuloModel } from "./modulo-model";

export class SistemaTabelaPrecosModel {
  constructor(
    public id: string = guidEmpty(),
    public nome: string = "",
    public tabelas: TabelasSistema[] = []
  ) { }
}

export class TabelasSistema {
  constructor(
    public tabelaPrecoId: string = guidEmpty(),
    public nome: string = '',
    public precoModulos: PrecoModulos[] = []
  ) { }
}

export class PrecoModulos {
  constructor(
    public id: string = guidEmpty(),
    public valor: number = 0,
    public valorCustoOriginal: number = 0,
    public percAdicional: number = 0,
    public valorCusto: number = 0,
    public percAdicionalCusto: number = 0,
    public modulo: ModuloModel = new ModuloModel()
  ) { }
}
