
import { useMemo } from 'react';
import { useValidationYupEmail, useValidationYupFone } from 'views/components/form-validations';
import * as Yup from 'yup';

interface Props{
  esconderAbertura?: boolean;
  pessoaFisica?: boolean;
}

export const useFormPessoaInfoValidation = ({esconderAbertura, pessoaFisica}: Props) => {
  const {emailYup} = useValidationYupEmail();
  const telefoneYup = useValidationYupFone();
  const FormPessoaInfoValidationYup = useMemo(() => {
    return Yup.object().shape({
      xNome: Yup.string().required('Nome Completo é obrigatório.'),
      email: emailYup().required('E-mail é obrigatório'),
      telefone: telefoneYup().required('Telefone é obrigatório'),
      logradouro: Yup.string().required('Endereço é obrigatório'),
      cep: Yup.string().required('CEP é obrigatório'),
      numero: Yup.string().required('Numero é obrigatório'),
      codMunicipio: Yup.string().required('Código do município é obrigatório').transform(value => value === '0' ? null : value).typeError('Código do município é obrigatório'),
      uf: Yup.string().required('UF é obrigatório'),
      bairro: Yup.string().required('Bairro é obrigatório'),
      municipio: Yup.string().required('Município é obrigatório'),
      segmentoId: pessoaFisica ? Yup.string().notRequired().nullable(true) : Yup.string().required('Segmento é obrigatório').typeError('Segmento é obrigatório'),
      fantasia: pessoaFisica ? Yup.string().notRequired().nullable(true) : Yup.string().required('Nome Fantasia é obrigatório').typeError('Nome Fantasia é obrigatório')
    });
  }, [emailYup, pessoaFisica, telefoneYup])

  return {
    FormPessoaInfoValidationYup,
  }
}

