import { FaturasModel } from "model/api/gestao/fatura/faturas-model";
import { EnumTipoCobrancaRevenda } from "model/enums/enum-tipo-cobranca-revenda";
import { CardNaoEncontrado } from "views/components/cards";
import { CardFaturaPessoa } from "views/components/cards/card-fatura-pessoa";
import { InformacaoIcon } from "views/components/icons";

export interface ClienteFaturasGridProps {
  list: Array<FaturasModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (id: string) => void;
  onCardChecked: (id: string) => any;
  tipoCobrancaRevenda: EnumTipoCobrancaRevenda;
}

export const AccordionClienteFaturasListData = (props: ClienteFaturasGridProps) => {

  const onCardSelected = (id: string) => {
    props.onCardSelected(id);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhuma Fatura Encontrada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props?.list.length > 0 &&
        props.list.map((items, index) => {
          return (
            <CardFaturaPessoa
              selected={
                props.selectedList.filter((item) => item === items.id).length >
                  0
                  ? true
                  : false
              }
              onCheck={onCardChecked}
              onClick={onCardSelected}
              model={items}
              key={index}
              tipoCobrancaRevenda={props.tipoCobrancaRevenda}
            />
          );
        })}
    </>
  );
};
