import { Typography } from '@material-ui/core';
import { CardPessoaSocioProps } from './card-pessoa-socio-props';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { EditarIcon, OlhoAbertoIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import {useStyles} from './card-pessoa-socio-styles';
import { useSessaoAtual } from 'services/app';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';

export const CardPessoaSocio = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardPessoaSocioProps) => {
  const classes = useDefaultCardStyles();
  const socioClasses = useStyles();

  const {md} = useThemeQueries()
  const {tipoUsuario} = useSessaoAtual();

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model);
        }}
        className={socioClasses.card}
      >
        <div className={`${classes.cardContent} ${socioClasses.container}`}>
          <div>
            <Typography color="textPrimary" variant="caption">
              Nome
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              <b>{model.nome}</b>
            </Typography>
          </div>
        </div>
        {!md ? <div className={socioClasses.editIcon}>
          {tipoUsuario() !== EnumTipoPessoas.Funcionario ? <EditarIcon tipo='BUTTON' /> : <OlhoAbertoIcon tipo='BUTTON'/>}
        </div> : null}
      </DefaultCard>
    </>
  );
};
