import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    cardContent: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
    },
    label: {
        fontSize: '0.9rem',
        color: '#9D9D9D',
        lineHeight: '14,1px',
        textAlign: 'justify',
        marginRight: '3px'
    },
    qrcode: {
        display: 'flex',
        flexDirection: "column",
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(1)
    },
    qrText: {
        textAlign: 'center',
    },
    buttonReutilizar: {
        backgroundColor: "#1F9CE4",
        "&:hover": {
            backgroundColor: '#0f6a9f'
        }
    },
    btnAtivar: {
        backgroundColor: theme.palette.success.main,
        "&:hover": {
            backgroundColor: '#098515'
        }
    },
    btnDesativar: {
        backgroundColor: theme.palette.error.main,
        "&:hover": {
            backgroundColor: '#b12e24'
        }
    },
    btnBloquear: {
        backgroundColor: theme.palette.warning.main,
        "&:hover": {
            backgroundColor: '#ab7f06'
        }
    },
}));
