import { useEffect, useState, useCallback } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Paginacao } from 'views/components/paginacao';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useToastSaurus } from 'services/app';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { useStyles } from './notas-fiscais-rejeitadas-list-styles'
import { NotasFiscaisRejeitadasListSearchProps } from './notas-fiscais-rejeitadas-list-search-props';
import { NotaFiscalModel } from 'model/api/gestao/nota-fiscal/nota-fiscal-model';
import { useGetNotasFiscaisRejeitadas } from 'data/api/gestao/nota-fiscal/get-notas-fiscais-rejeitadas';
import { NotasFiscaisRejeitadasListData } from './notas-fiscais-rejeitadas-list-data';

export interface NotasFiscaisRevendaListProps {
  searchProps: NotasFiscaisRejeitadasListSearchProps;
  setSelectedNF: React.Dispatch<React.SetStateAction<NotaFiscalModel>>;
}

export const NotasFiscaisRejeitadasList = (
  props: NotasFiscaisRevendaListProps,
) => {
  const classes = useStyles();
  const listClasses = useDefaultCadastroStyles();
  const { getNotasFiscaisRejeitadas, carregando } = useGetNotasFiscaisRejeitadas();
  const { showToast } = useToastSaurus();
  const { location, push } = useHistory();

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<NotaFiscalModel>(),
  });

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<NotaFiscalModel>,
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    [],
  );

  const abrirNF = useCallback((model: NotaFiscalModel) => {
    props.setSelectedNF(model)
    push({
      pathname: '/notas-fiscais-rejeitadas/' + model.id,
      state: {
        from: {
          pathname: location.pathname,
          page: queryStatus.page,
        },
      },
    });
  }, [location.pathname, props, push, queryStatus.page])

  const search = useCallback(
    async (newPage: number, selectedId: string | null) => {
      try {
        const query = `&pageSize=30${!isEmpty(props.searchProps.dataInicial)
          ? '&DataInicial=' + props.searchProps.dataInicial
          : ''
          }${!isEmpty(props.searchProps.dataFinal)
            ? '&DataFinal=' + props.searchProps.dataFinal
            : ''
          }`;

        const res = await getNotasFiscaisRejeitadas(newPage, query);
        if (res.erro) throw res.erro;

        if (!res.resultado) return;

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages, selectedId);
          return;
        }

        const selectedModel = res.resultado.data.list.find(nf => nf.id === selectedId)

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list,
        );

        if (!isEmpty(selectedId) && !isEmpty(selectedModel)) {
          abrirNF(selectedModel)
          return
        }

        return res;
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [props.searchProps.dataInicial, props.searchProps.dataFinal, getNotasFiscaisRejeitadas, fillResult, abrirNF, showToast],
  );

  useEffect(() => {
    let page = Number(new URLSearchParams(location.search).get('page'));
    let selectedId = new URLSearchParams(location.search).get('notaId');
    
    search(page || queryStatus.page, selectedId);
    location.search = ''
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchProps]);

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage, null);
      }
    },
    [search, queryStatus.totalPages],
  );

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="space-between"
          flex={1}
          overflow="auto"
        >
          <Grid container className={listClasses.listContainer}>
            <Grid item xs={12}>
              <Paginacao
                pageChanged={pageChanged}
                totalPages={queryStatus.totalPages}
                totalRegisters={queryStatus.totalResults}
                currentPage={queryStatus.page}
              />
            </Grid>
            <Grid item xs={12} className={classes.listContainer}>
              <NotasFiscaisRejeitadasListData
                carregando={carregando}
                list={queryStatus.list}
                onCardClick={abrirNF}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};