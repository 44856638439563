import { VariaveisAmbiente } from 'config';
import { ApiListModel } from 'model/api/gestao/api-list-model/api-list-model';
import { PessoaComissaoModel } from 'model/api/gestao/pessoa/pessoa-comissao-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetPessoaComissao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ApiListModel<PessoaComissaoModel>>();

    const getPessoaComissao = useCallback(
        async (pessoaId: string, page: number, query?: string) => {

            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/pessoa/${pessoaId}/comissao?page=${page}${query}`,
                method: 'GET',
                enviarTokenUsuario: true
            })
        }
        , [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getPessoaComissao,
    };
}
