import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { makeUtilClasses } from 'views';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { KeyValueModel } from 'model';
import { ClienteIdealRespostaModel } from 'model/api/gestao/cliente-ideal/cliente-ideal-resposta-model';
import { useFormRespostaApuracaoValidation } from './form-resposta-apuracao-validation';
import { useEventTools } from 'services/app/use-cases/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { ClienteIdealPerguntaModel } from 'model/api/gestao/cliente-ideal/cliente-ideal-pergunta-model';
import { useCadastros } from 'services/app';

interface FormRespostaApuracaoProps extends DefaultFormProps<ClienteIdealRespostaModel> {
    maxPontos: number;
    index: number;
}

export const FormRespostaApuracao = forwardRef<
    DefaultFormRefs<ClienteIdealRespostaModel>,
    FormRespostaApuracaoProps
>(
    (
        { loading, onSubmit, maxPontos, index, ...props }: FormRespostaApuracaoProps,
        ref,
    ) => {
        const { FormRespostaApuracaoValidation } = useFormRespostaApuracaoValidation(maxPontos);
        const { callEvent, addHandler, removeHandler } = useEventTools();
        const [perguntas, setPerguntas] = useState<KeyValueModel[]>([])
        const perguntasRef = useRef<ClienteIdealPerguntaModel[]>([])
        const [, setRender] = useState<boolean>(false)
        const { cadastroPerguntaState } = useCadastros();

        const {
            handleSubmit,
            control,
            formState: { errors },
            reset,
            setValue,
            getValues
        } = useForm<ClienteIdealRespostaModel>({
            defaultValues: { ...new ClienteIdealRespostaModel() },
            resolver: yupResolver(FormRespostaApuracaoValidation),
            criteriaMode: 'all',
            mode: 'onChange',
        });
        const refInputSenhaAtual = useRef<HTMLInputElement>(null);
        const utilClasses = makeUtilClasses();

        const submit = (data: ClienteIdealRespostaModel) => {
            onSubmit(data);
        }

        useImperativeHandle(ref, () => ({
            submitForm: () => {
                handleSubmit(submit)();
            },
            resetForm: () => {
                refInputSenhaAtual.current?.focus();
                reset();
            },
            fillForm: (values: ClienteIdealRespostaModel) => {
                reset({ ...values });
                callEvent(AppEventEnum.SolicitarPerguntas, {})
            },
        }));

        const setarPerguntas = useCallback((array: ClienteIdealPerguntaModel[]) => {
            array = [...array].filter(x => x.id !== cadastroPerguntaState.model.id && x.ativo)
            perguntasRef.current = array;
            const values = array
                .filter(pergunta => !pergunta.respostas.some(resp => resp.perguntaIdDependente === cadastroPerguntaState.model.id))
                .map(pergunta => new KeyValueModel(pergunta.id, pergunta.pergunta))
            setPerguntas(values)
        }, [cadastroPerguntaState.model.id])

        useEffect(() => {
            addHandler(AppEventEnum.ReceberPerguntas, setarPerguntas)
            return () => {
                removeHandler(AppEventEnum.ReceberPerguntas, setarPerguntas)
            }
        }, [addHandler, removeHandler, setarPerguntas])

        const retRespostasDependente = (id: string) => {
            return perguntasRef.current.find(x => x.id === id)?.respostas.map(x => new KeyValueModel(x.id, x.resposta)) || [];
        }


        return (
            <>
                <Box my={2}>
                    <div className={utilClasses.formContainer}>
                        {loading && props.showLoading ? (
                            <div className={utilClasses.controlLoading}>
                                <CircularLoading tipo="NORMAL" />
                            </div>
                        ) : null}

                        <form
                            onSubmit={handleSubmit(submit)}
                            className={loading ? utilClasses.controlLoading : ''}
                        >
                            <Grid container spacing={2} justifyContent="space-between">
                                <Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name='resposta'
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                label='Descrição da Resposta'
                                                tipo='TEXTO'
                                                fullWidth
                                                {...field}
                                                error={Boolean(
                                                    errors.resposta && errors.resposta.message,
                                                )}
                                                helperText={
                                                    errors.resposta
                                                        ? errors.resposta?.message
                                                        : undefined
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name='pontos'
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                label='Pontos'
                                                tipo='NUMERO_RANGE'
                                                fullWidth
                                                {...field}
                                                error={Boolean(
                                                    errors.pontos && errors.pontos.message,
                                                )}
                                                helperText={
                                                    errors.pontos
                                                        ? errors.pontos?.message
                                                        : undefined
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name='ativo'
                                        render={({ field }) => (
                                            <SelectSaurus
                                                label='Status da Resposta'
                                                conteudo={[
                                                    new KeyValueModel(true, 'Ativo'),
                                                    new KeyValueModel(false, 'Desativado')
                                                ]}
                                                fullWidth
                                                {...field}
                                                error={Boolean(
                                                    errors.ativo && errors.ativo.message,
                                                )}
                                                helperText={
                                                    errors.ativo
                                                        ? errors.ativo?.message
                                                        : undefined
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                                {perguntas.length > 0 && <Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name='perguntaIdDependente'
                                        render={({ field }) => (
                                            <SelectSaurus
                                                label='Pergunta Dependente'
                                                conteudo={[
                                                    ...perguntas,
                                                    new KeyValueModel(null, 'Remover Pergunta')
                                                ]}
                                                fullWidth

                                                {...field}
                                                error={Boolean(
                                                    errors.ativo && errors.ativo.message,
                                                )}
                                                helperText={
                                                    errors.ativo
                                                        ? errors.ativo?.message
                                                        : undefined
                                                }
                                                onChange={(e) => {
                                                    setValue('perguntaIdDependente', e.target.value)
                                                    setValue('respostaIdDependente', null)
                                                    setRender(prev => !prev)
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>}
                                {getValues('perguntaIdDependente') && <Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name='respostaIdDependente'
                                        render={({ field }) => (
                                            <SelectSaurus
                                                label='Resposta Dependente'
                                                conteudo={[
                                                    ...retRespostasDependente(getValues('perguntaIdDependente')!),
                                                    new KeyValueModel(null, 'Remover Resposta')
                                                ]}
                                                fullWidth
                                                {...field}
                                                error={Boolean(
                                                    errors.ativo && errors.ativo.message,
                                                )}
                                                helperText={
                                                    errors.ativo
                                                        ? errors.ativo?.message
                                                        : undefined
                                                }
                                                onChange={(e) => {
                                                    setValue('respostaIdDependente', e.target.value)
                                                    setRender(prev => !prev)
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>}
                            </Grid>
                            <Button style={{ display: 'none' }} type="submit"></Button>
                        </form>
                    </div>
                </Box>
            </>
        );
    },
);