import { guidEmpty } from "utils/guid-empty";

export class PagamentoModel {
    constructor(
        public id: string = guidEmpty(),
        public nome: string = '',
        public credenciais: string = '',
        public tipoComunicacao: number = 0,
        public instituicao: number = 0
    ) { }
}