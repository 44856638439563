import { Button, Grid } from '@material-ui/core';
import { useRef, useCallback, useMemo, useState } from 'react';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { useToastSaurus } from 'services/app';
import {
  BoletoIcon,
  DinheiroIcon,
  QrCodeIcon,
  VoltarIcon,
} from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import classNames from 'classnames';
import { usePostFaturaRegistrar } from 'data/api/gestao/faturas';
import { ConsultaFaturaInfo } from './components/consulta-fatura-info';
import { FaturaFormModel } from 'model/app/forms/fatura/fatura-form-model';
import { EnumSituacao } from 'model/enums/enum-situacao';
import { DefaultFormRefs } from 'views/components/form/utils';
import { FaturasModel } from 'model/api/gestao/fatura/faturas-model';
import { stringNumeros } from 'utils/string-numeros';
import { FaturaPagamentoModel } from 'model/api/gestao/fatura/fatura-pagamento-model';
import { isEmpty } from 'lodash';
import { DialogPixConsulta } from 'views/components/dialog/dialog-pix-consulta/dialog-pix-consulta';
import { EnumTipoComunicacao } from 'model';

interface Props {
  id: string
  model: FaturasModel
  closeModal: () => void;
}

export interface PixDialogProps extends FaturaPagamentoModel{
  aberto: boolean,
  codigoDeBarras: string,
  linhaDigitavel:string,
  nossoNumero: number,
  pixChave: string,
  pixCopiaCola: string,
  url: string,
  dhExpiracaoPix: string,
  formaPagamentoAtual: string;
}

export const ConsultaFatura = ({ id, model, closeModal, ...props }: Props) => {

  const classes = useModalStyles();
  const { showToast } = useToastSaurus();

  const { postFaturaRegistrar, carregando: carregandoPostRegistrar } = usePostFaturaRegistrar();

  const carregando =
    carregandoPostRegistrar

  const cadForm = useRef<DefaultFormRefs<FaturaFormModel>>(null)

  const [pixDialog, setPixDialog] = useState<PixDialogProps>({
    aberto: false,
    codigoDeBarras: '',
    linhaDigitavel: '',
    nossoNumero: 0,
    pixChave: '',
    pixCopiaCola: '',
    url: '',
    dhExpiracaoPix: '',
    formaPagamentoAtual: ''
  })

  const pagarFatura = useCallback(async () => {
    try {
      const value = model
      if (isEmpty(value.formasPagamentosDisponiveis)) {
        throw new Error('Não há uma forma de pagamento vinculada. Contate seu representante.')
      }

      //DANDO PREFERÊNCIA PARA A FORMA DE PAGAMENTO PIX EM VEZ DA FORMA QUE VEM POR PADRÃO DA API
      const fpPix = value.formasPagamentosDisponiveis.find(fp => fp.tipoComunicacao === EnumTipoComunicacao.Pix)
      const formaPagamentoId = fpPix?.id || value.formaPagamentoId
      
      const res = await postFaturaRegistrar(value.id, formaPagamentoId, stringNumeros(value.pagadorCpfCnpj), value.contratoId)
      if (res.erro) {
        throw res.erro
      }

      if (!res.resultado) return

      const ret = res.resultado.data

      if (res.statusCode === 200) {
        if (!isEmpty(res.resultado.data.url)) {
          window.open(ret.url, '_blank');
          closeModal();
          showToast('success', 'Fatura paga com sucesso!')
          return
        }

        if (!isEmpty(res.resultado.data.pixCopiaCola)) {
          setPixDialog({
            aberto: true,
            codigoDeBarras: ret.codigoDeBarras,
            linhaDigitavel: ret.linhaDigitavel,
            nossoNumero: ret.nossoNumero,
            pixChave: ret.pixChave,
            pixCopiaCola: ret.pixCopiaCola,
            url: ret.url,
            dhExpiracaoPix: ret.dhExpiracaoPix,
            formaPagamentoAtual: formaPagamentoId
          })
          return
        }
      }
    }
    catch (err: any) {
      showToast('error', err.message)
    }
  }, [model, postFaturaRegistrar, closeModal, showToast])

  const onCloseClick = useCallback(() => {
    closeModal()
  }, [closeModal]);

  const faturaInfo = useMemo(() => {
    return (
      <ConsultaFaturaInfo
        model={model}
        carregando={carregando}
        ref={cadForm}
      />
    )
  }, [carregando, model])

  //MENSAGEM DE ERRO PQ N TEM TOKEN DO ITAU ATUALMENTE
  // const avisoSistema = useCallback(async () => {
  //   const fpAtual = model.formasPagamentosDisponiveis.find(fp => fp.id === model.formaPagamentoId)
  //   switch (fpAtual?.tipoComunicacao) {
  //     case EnumTipoComunicacao.Boleto:
  //       const fpPix = model.formasPagamentosDisponiveis.find(pagamento => pagamento.tipoComunicacao === EnumTipoComunicacao.Pix)
  //       confirm({
  //         title: 'IMPORTANTE',
  //         description: `Estamos com problemas na emissão dos boletos, devido uma pendência junto ao BANCO ITAÚ, os pagamentos via PIX QRCODE, podem ser efetivados normalmente.`,
  //         confirmationText: fpPix ? 'Prosseguir com PIX' : 'Entendi',
  //         cancellationText: 'Fechar'
  //       }).then(() => {
  //         if (fpPix) {
  //           pagarFatura()
  //         }
  //       })
  //       break;
  //     default:
  //       pagarFatura();
  //   }
  // }, [confirm, model, pagarFatura])

  console.log(model)

  return (
    <div className={classes.root}>
      <ModalHeader
        title="Detalhes Fatura"
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
        <div
          className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}
        >
          {faturaInfo}
        </div>
        {(model.situacao !== EnumSituacao.Paga && (model.situacao !== EnumSituacao.Bonificada)) && (
          <div className={classes.acoes}>
            <Grid container spacing={2}>
              {(isEmpty(model.boletoUrl) && isEmpty(model.pixCodigo)) && <Grid item xs={12}>
                <Button variant='contained' color='primary' fullWidth disabled={carregando} onClick={() => {
                  pagarFatura()
                }}>
                  <DinheiroIcon tipo="BUTTON_PRIMARY" />
                  Pagar Fatura
                </Button>
              </Grid>}
              {!isEmpty(model.boletoUrl) && <Grid item xs={12}>
                <Button variant='contained' fullWidth color='primary' disabled={carregando} onClick={() => {
                  window.open(model.boletoUrl, '_blank')

                }}>
                  <BoletoIcon tipo='BUTTON_PRIMARY' />
                  Visualizar Boleto
                </Button>
              </Grid>}
              {!isEmpty(model.pixCodigo) && <Grid item xs={12}>
                <Button variant='contained' fullWidth color='primary' onClick={() => {
                  setPixDialog({
                    aberto: true,
                    codigoDeBarras: '',
                    linhaDigitavel: '',
                    nossoNumero: model.nossoNumero,
                    pixChave: '',
                    pixCopiaCola: model.pixCodigo,
                    url: model.boletoUrl,
                    dhExpiracaoPix: model.dhExpiracaoPix,
                    formaPagamentoAtual: model.formaPagamentoId
                  })
                }}>
                  <QrCodeIcon tipo='BUTTON_PRIMARY' />
                  Visualizar PIX
                </Button>
              </Grid>}
              {/* {(!isEmpty(model.boletoUrl) && formaPagamentoPix && isEmpty(model.pixCodigo)) && <Grid item xs={12}>
                <Button variant='outlined' color='primary' onClick={() => pagarPix(formaPagamentoPix)}>
                  <QrCodeIcon tipo='BUTTON'/>
                  Gerar PIX
                </Button>
              </Grid>} */}
            </Grid>
          </div>
        )}
      </div>
      <DialogPixConsulta
        closeDialog={() => {
          setPixDialog(prev => ({
            ...prev,
            aberto: false
          }))
          closeModal();
        }}
        setDialogPix={setPixDialog}
        openned={pixDialog.aberto}
        model={model}
        formaPagamentoAtual={pixDialog.formaPagamentoAtual}
        value={pixDialog.pixCopiaCola}
        dhExpiracaoPix={!isEmpty(pixDialog.dhExpiracaoPix) ? pixDialog.dhExpiracaoPix.split('+')[0] : model.dhExpiracaoPix}
      />
    </div>
  );
};
