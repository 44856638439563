import { Button, Divider, FormControlLabel, Grid } from "@material-ui/core";
import { PessoaPostModel } from "model/api/gestao/pessoa/pessoa-post-model";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { useCallback, useEffect, useState } from "react";
import { useSessaoAtual } from "services/app";
import { CircularLoading } from "views/components";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { SwitchSaurus } from "views/components/controles/switches/switch-saurus";
import { SalvarIcon } from "views/components/icons";
import { useThemeQueries } from "views/theme";
import { useStyles } from './accordion-cliente-acesso-avisos-styles'

type Props = {
  cliente: PessoaPostModel;
  onSubmit: (model: PessoaPostModel) => Promise<boolean>
  carregando: boolean
}

type CamposClienteProps = {
  bloquear: boolean;
  mensagem: string;
}

export const AccordionClienteAcessoAvisos = ({ cliente, onSubmit, ...props }: Props) => {
  const { theme } = useThemeQueries()
  const [openAccordion, setOpenAccordion] = useState<boolean>(false)
  const classes = useStyles()
  const { tipoUsuario } = useSessaoAtual()
  const [carregandoInterno, setCarregandoInterno] = useState<boolean>(false)

  const [campos, setCampos] = useState<CamposClienteProps>({
    bloquear: cliente.bloquearContratos,
    mensagem: cliente.mensagemAosContratos
  })

  useEffect(() => {
    setCampos({
      bloquear: cliente.bloquearContratos,
      mensagem: cliente.mensagemAosContratos
    })
  }, [cliente.bloquearContratos, cliente.mensagemAosContratos])


  const handleSubmit = useCallback(async (e: React.SyntheticEvent) => {
    try {

      setCarregandoInterno(true)
      e.preventDefault()

      const res = await onSubmit({
        ...cliente,
        mensagemAosContratos: campos.mensagem,
        bloquearContratos: campos.bloquear
      })

      if (!res) {
        setCampos({
          bloquear: cliente.bloquearContratos,
          mensagem: cliente.mensagemAosContratos
        })
      }
    }
    finally {
      setCarregandoInterno(false)
    }
  }, [campos.bloquear, campos.mensagem, cliente, onSubmit])

  const carregando = carregandoInterno && props.carregando

  return (
    <>
      <AccordionSaurus
        labelPrimary="Acesso e Avisos"
        tipoExpand="bold"
        noPaperRoot={false}
        heightDivider={2}
        showDivider={openAccordion}
        colorDivider={theme.palette.primary.main}
        colorExpand={theme.palette.primary.main}
        expanded={openAccordion}
        onChange={() => setOpenAccordion(prev => !prev)}
      >
        <Grid container spacing={1} style={{
          position: 'relative'
        }}>
          {carregando && <CircularLoading tipo="FULLSIZED" />}
          {tipoUsuario() !== EnumTipoPessoas.Funcionario && <>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <div
                      className={classes.switchInputContainer}
                    >
                      <FormControlLabel
                        className={classes.switchInput}
                        control={
                          <SwitchSaurus
                            variant="LIGHT"
                            name="bloquear"
                            checked={campos.bloquear}
                            onChange={ev => setCampos(prev => {
                              return {
                                ...prev,
                                bloquear: ev.target.checked
                              }
                            })}
                          />
                        }
                        label="Bloquear Acesso ao Sistema"
                        labelPlacement="start"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldSaurus
                      label="Mensagem ao Usuário"
                      name="mensagem"
                      value={campos.mensagem}
                      allowSubmit
                      minRows={3}
                      multiline
                      placeholder="Digite aqui a mensagem que deseja que o usuário visualize ao acessar o sistema."
                      onChange={ev => setCampos(prev => {
                        return {
                          ...prev,
                          mensagem: ev.target.value
                        }
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} container justifyContent="flex-end">
                    <Grid item container justifyContent="flex-end">
                      <Grid item xs={12} md={4}>
                        <Button variant="contained" color='primary' fullWidth type="submit">
                          <SalvarIcon tipo='BUTTON_PRIMARY' />
                          Salvar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ background: theme.palette.divider, margin: '8px 0' }} />
            </Grid>
          </>}
        </Grid>
      </AccordionSaurus>
    </>
  );
};
