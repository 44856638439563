import { Tooltip, Typography } from '@material-ui/core';
import { CardAmbienteSistemaProps } from './card-ambiente-sistema-props';
import { EditarIcon } from '../../icons/editar-icon';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';

export const CardAmbienteSistema = ({
  model,
  onClick,
  onCheck,
  selected,
  atualizarList
}: CardAmbienteSistemaProps) => {
  const classes = useDefaultCardStyles();


  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => onClick(model)}
      >
        <>
          <div className={classes.cardContent}>
            <div className={classes.celulaGrid}>
              <Typography
                className={'celula-grid-value'}
                color="primary"
                variant="caption"
              >
                Nome
              </Typography>
              <Typography
                color="textPrimary"
                variant="body1"
              >
                <strong>{model.nome}</strong>
              </Typography>

            </div>
            <div className={classes.celulaGrid}>
              <Typography
                className={'celula-grid-value'}
                color="primary"
                variant="caption"
              >
                Valor
              </Typography>
              <Typography
                color="textPrimary"
                variant="body1"
              >
                <strong>{model.valor}</strong>
              </Typography>

            </div>
          </div>
          <Tooltip arrow title="Editar Sistema">
            <div className={classes.cardRightButton}>
              <EditarIcon tipo="BUTTON" />
            </div>
          </Tooltip>
        </>
      </DefaultCard>
    </>
  );
};
