import { Box, Button, Grid, Typography } from "@material-ui/core";
import { AmbientePagamentoMock } from "data/mocks/ambiente-pagamento-mock";
import { isEmpty } from "lodash";
import { EnumTipoComunicacao } from "model";
import { CampoCredencialModel } from "model/app/forms/pagamento/campo-credencial-model";
import { CredenciamentoItauBoleto, CredenciamentoSafraPix, PagamentoFormModel } from "model/app/forms/pagamento/pagamento-form-model";
import { EnumInstituicao } from "model/enums/enum-instituicao";
import { useCallback, useMemo, useState } from "react";
import { Control, Controller, UseFormClearErrors, UseFormGetFieldState, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { TextFieldSaurus } from "views/components/controles/inputs";
import ArquivoInput from "views/components/controles/inputs/arquivo-input/arquivo-input";
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus";
import { LixoIcon } from "views/components/icons";
import { AddIcon } from "views/components/icons/add-icon";

interface CrendenciaisPagamentoProps {
  control: Control<PagamentoFormModel, any>;
  getFieldState: UseFormGetFieldState<PagamentoFormModel>;
  setValue: UseFormSetValue<PagamentoFormModel>;
  getValues: UseFormGetValues<PagamentoFormModel>;
  clearErrors: UseFormClearErrors<PagamentoFormModel>;
  loading: boolean;
}

export function useCredenciaisPagamento({ control, getFieldState, setValue, clearErrors, getValues, loading }: CrendenciaisPagamentoProps) {

  const credenciais = useMemo<CampoCredencialModel[]>(() => (
    [
      new CampoCredencialModel(EnumInstituicao.Itau, EnumTipoComunicacao.Boleto,
        <>
          <Grid item xs={9}>
            <Controller
              name="credenciais.agencia"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  disabled={loading}
                  allowSubmit={false}
                  id="credenciais.agencia"
                  label="Agência"
                  tipo='NUMERO'
                  fullWidth
                  autoComplete={'off'}
                  helperText={
                    getFieldState('credenciais.agencia').isTouched
                      && getFieldState('credenciais.agencia').error
                      ? getFieldState('credenciais.agencia').error?.message
                      : undefined
                  }
                  error={Boolean(getFieldState('credenciais.agencia').error)}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="credenciais.digitoAgencia"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  disabled={loading}
                  allowSubmit={false}
                  id="credenciais.digitoAgencia"
                  label="Digito"
                  tipo='NUMERO'
                  fullWidth
                  autoComplete={'off'}
                  helperText={
                    getFieldState('credenciais.digitoAgencia').isTouched
                      && getFieldState('credenciais.digitoAgencia').error
                      ? getFieldState('credenciais.digitoAgencia').error?.message
                      : undefined
                  }
                  error={Boolean(getFieldState('credenciais.digitoAgencia').error)}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={9}>
            <Controller
              name="credenciais.conta"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  disabled={loading}
                  allowSubmit={false}
                  id="credenciais.conta"
                  label="Conta"
                  tipo='NUMERO'
                  fullWidth
                  autoComplete={'off'}
                  helperText={
                    getFieldState('credenciais.conta').isTouched
                      && getFieldState('credenciais.conta').error
                      ? getFieldState('credenciais.conta').error?.message
                      : undefined
                  }
                  error={Boolean(getFieldState('credenciais.conta').error)}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="credenciais.digitoConta"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  disabled={loading}
                  allowSubmit={false}
                  id="credenciais.digitoConta"
                  label="Digito"
                  tipo='NUMERO'
                  fullWidth
                  autoComplete={'off'}
                  helperText={
                    getFieldState('credenciais.digitoConta').isTouched
                      && getFieldState('credenciais.digitoConta').error
                      ? getFieldState('credenciais.digitoConta').error?.message
                      : undefined
                  }
                  error={Boolean(getFieldState('credenciais.digitoConta').error)}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="credenciais.razaoSocial"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  disabled={loading}
                  allowSubmit={false}
                  id="credenciais.razaoSocial"
                  label="Razão Social"
                  fullWidth
                  autoComplete={'off'}
                  helperText={
                    getFieldState('credenciais.razaoSocial').isTouched
                      && getFieldState('credenciais.razaoSocial').error
                      ? getFieldState('credenciais.razaoSocial').error?.message
                      : undefined
                  }
                  error={Boolean(getFieldState('credenciais.razaoSocial').error)}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="credenciais.clientId"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  disabled={loading}
                  allowSubmit={false}
                  id="credenciais.clientId"
                  label="ID Cliente"
                  fullWidth
                  autoComplete={'off'}
                  helperText={
                    getFieldState('credenciais.clientId').isTouched
                      && getFieldState('credenciais.clientId').error
                      ? getFieldState('credenciais.clientId').error?.message
                      : undefined
                  }
                  error={Boolean(getFieldState('credenciais.clientId').error)}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="credenciais.clientSecret"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  disabled={loading}
                  allowSubmit={false}
                  id="credenciais.clientSecret"
                  label="Segredo Cliente"
                  fullWidth
                  autoComplete={'off'}
                  helperText={
                    getFieldState('credenciais.clientSecret').isTouched
                      && getFieldState('credenciais.clientSecret').error
                      ? getFieldState('credenciais.clientSecret').error?.message
                      : undefined
                  }
                  error={Boolean(getFieldState('credenciais.clientSecret').error)}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="credenciais.cpfCnpj"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  disabled={loading}
                  allowSubmit={false}
                  id="credenciais.cpfCnpj"
                  label="CPF/CNPJ"
                  tipo='CNPJ_CPF'
                  fullWidth
                  autoComplete={'off'}
                  helperText={
                    getFieldState('credenciais.cpfCnpj').isTouched
                      && getFieldState('credenciais.cpfCnpj').error
                      ? getFieldState('credenciais.cpfCnpj').error?.message
                      : undefined
                  }
                  error={Boolean(getFieldState('credenciais.cpfCnpj').error)}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="credenciais.certificadoPfx"
              control={control}
              render={({ field }) => (
                <ArquivoInput
                  label='Certificado Digital(.pfx)'
                  accept='.pfx'
                  nameArquivo={!isEmpty(getValues('credenciais.certificadoPfx')) ? 'Árquivo Anexado' : undefined}
                  helperText={
                    getFieldState('credenciais.certificadoPfx').error
                      ? getFieldState('credenciais.certificadoPfx').error?.message
                      : undefined
                  }
                  error={Boolean(getFieldState('credenciais.certificadoPfx').error)}
                  {...field}
                  onChange={(dados) => {
                    if (dados?.base64) {
                      setValue('credenciais.certificadoPfx', dados?.base64)
                      clearErrors('credenciais.certificadoPfx')
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="credenciais.certificadoSecret"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  disabled={loading}
                  allowSubmit={false}
                  id="credenciais.certificadoSecret"
                  label="Segredo Certificado"
                  fullWidth
                  autoComplete={'off'}
                  helperText={
                    getFieldState('credenciais.certificadoSecret').isTouched
                      && getFieldState('credenciais.certificadoSecret').error
                      ? getFieldState('credenciais.certificadoSecret').error?.message
                      : undefined
                  }
                  error={Boolean(getFieldState('credenciais.certificadoSecret').error)}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="credenciais.ambiente"
              control={control}
              render={({ field }) => (
                <SelectSaurus
                  disabled={loading}
                  allowSubmit={false}
                  conteudo={AmbientePagamentoMock}
                  id="credenciais.ambiente"
                  label="Ambiente"
                  fullWidth
                  autoComplete={'off'}
                  helperText={
                    getFieldState('credenciais.ambiente').isTouched
                      && getFieldState('credenciais.ambiente').error
                      ? getFieldState('credenciais.ambiente').error?.message
                      : undefined
                  }
                  error={Boolean(getFieldState('credenciais.ambiente').error)}
                  {...field}
                  onChange={ev => {
                    const item =
                      AmbientePagamentoMock
                        .filter(item => item.Key === ev.target.value)

                    setValue('credenciais.ambiente', item[0].Key)
                  }}
                />
              )}
            />
          </Grid>
        </>),

      new CampoCredencialModel(EnumInstituicao.Safra, EnumTipoComunicacao.Pix,
        <>
          <Grid item xs={12}>
            <Controller
              name="credenciais.razaoSocial"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  disabled={loading}
                  allowSubmit={false}
                  id="credenciais.razaoSocial"
                  label="Razão Social"
                  fullWidth
                  autoComplete={'off'}
                  helperText={
                    getFieldState('credenciais.razaoSocial').isTouched
                      && getFieldState('credenciais.razaoSocial').error
                      ? getFieldState('credenciais.razaoSocial').error?.message
                      : undefined
                  }
                  error={Boolean(getFieldState('credenciais.razaoSocial').error)}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="credenciais.cnpj"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  disabled={loading}
                  allowSubmit={false}
                  id="credenciais.cnpj"
                  tipo='CNPJ'
                  label="CNPJ"
                  fullWidth
                  autoComplete={'off'}
                  helperText={
                    getFieldState('credenciais.cnpj').isTouched
                      && getFieldState('credenciais.cnpj').error
                      ? getFieldState('credenciais.cnpj').error?.message
                      : undefined
                  }
                  error={Boolean(getFieldState('credenciais.cnpj').error)}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="credenciais.merchantId"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  disabled={loading}
                  allowSubmit={false}
                  id="credenciais.merchantId"
                  label="ID Merchant"
                  fullWidth
                  autoComplete={'off'}
                  helperText={
                    getFieldState('credenciais.merchantId').isTouched
                      && getFieldState('credenciais.merchantId').error
                      ? getFieldState('credenciais.merchantId').error?.message
                      : undefined
                  }
                  error={Boolean(getFieldState('credenciais.merchantId').error)}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="credenciais.merchantToken"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  disabled={loading}
                  allowSubmit={false}
                  id="credenciais.merchantToken"
                  label="Token Merchant"
                  fullWidth
                  autoComplete={'off'}
                  helperText={
                    getFieldState('credenciais.merchantToken').isTouched
                      && getFieldState('credenciais.merchantToken').error
                      ? getFieldState('credenciais.merchantToken').error?.message
                      : undefined
                  }
                  error={Boolean(getFieldState('credenciais.merchantToken').error)}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="credenciais.ambiente"
              control={control}
              render={({ field }) => (
                <SelectSaurus
                  disabled={loading}
                  allowSubmit={false}
                  conteudo={AmbientePagamentoMock}
                  id="credenciais.ambiente"
                  label="Ambiente"
                  fullWidth
                  autoComplete={'off'}
                  helperText={
                    getFieldState('credenciais.ambiente').isTouched
                      && getFieldState('credenciais.ambiente').error
                      ? getFieldState('credenciais.ambiente').error?.message
                      : undefined
                  }
                  error={Boolean(getFieldState('credenciais.ambiente').error)}
                  {...field}
                  onChange={ev => {
                    const item =
                      AmbientePagamentoMock
                        .filter(item => item.Key === ev.target.value)

                    setValue('credenciais.ambiente', item[0].Key)
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="credenciais.chave"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  disabled={loading}
                  allowSubmit={false}
                  id="credenciais.chave"
                  label="Chave"
                  fullWidth
                  autoComplete={'off'}
                  helperText={
                    getFieldState('credenciais.chave').isTouched
                      && getFieldState('credenciais.chave').error
                      ? getFieldState('credenciais.chave').error?.message
                      : undefined
                  }
                  error={Boolean(getFieldState('credenciais.chave').error)}
                  {...field}
                />
              )}
            />
          </Grid>
        </>)

    ]
  ), [clearErrors, control, getFieldState, getValues, loading, setValue])


  const [propriedades, setPropriedades] = useState<Record<string, string>>({})

  const [keyName, setKeyName] = useState<string>('')
  const [valueName, setValueName] = useState<string>('')

  const [isCustom, setIsCustom] = useState<boolean>(false)

  const excluirPropriedade = useCallback((key: string) => {
    const props = {...propriedades}
    delete props[key]

    setPropriedades(props)
  }, [propriedades])

  const adicionarPropriedade = useCallback(() => {
    if(isEmpty(keyName) || isEmpty(valueName)){
      return
    }

    const newCredenciais = {
      [keyName]: valueName,
      ...propriedades
    }

    setPropriedades(newCredenciais)

    setValue('credenciais', newCredenciais)

    setKeyName('')
    setValueName('')
  }, [keyName, propriedades, setValue, valueName])

  const camposCustomizados = useMemo(() => (
    <>
      {Object.entries(propriedades).map(([key, value]) => <Grid item xs={12}>
        <Box display='flex'>
          <Box flexGrow={1}>
            <Typography variant='caption' color='textSecondary'>{key}</Typography>
            <Typography variant='body1' color='textPrimary'>{value}</Typography>
          </Box>
          <Button variant='outlined' color='primary' onClick={() => excluirPropriedade(key)}>
            <LixoIcon tipo='BUTTON' style={{margin: 0}}/>
          </Button>
        </Box>
      </Grid>)}
      <Grid item xs={6}>
        <TextFieldSaurus
          value={keyName}
          onChange={(e: any) => setKeyName(e.target.value)}
          label='Nome'
        />
      </Grid>
      <Grid item xs={6}>
      <TextFieldSaurus
          value={valueName}
          onChange={(e: any) => setValueName(e.target.value)}
          label='Valor'
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant='contained' color='primary' fullWidth onClick={adicionarPropriedade}>
          <AddIcon tipo='BUTTON_PRIMARY'/>
          Adicionar Campo
        </Button>
      </Grid>
    </>
  ), [adicionarPropriedade, excluirPropriedade, keyName, propriedades, valueName])



  const getCampos = useCallback((instituicao: EnumInstituicao, tipoComunicacao: EnumTipoComunicacao) => {
    if(isCustom){
      return camposCustomizados
    }

    const credencial = credenciais.find(campo => campo.instituicao === instituicao && campo.tipoComunicacao === tipoComunicacao)

    if (isEmpty(credencial)) {
      return null
    }

    return credencial.campos
  }, [camposCustomizados, credenciais, isCustom])

  const setCredenciais = useCallback((instituicao: EnumInstituicao, tipoComunicacao: EnumTipoComunicacao) => {
    if (instituicao && tipoComunicacao !== null) {
      setValue('credenciais', null)
      switch (tipoComunicacao) {
        case EnumTipoComunicacao.Pix:
          const credencialPix = new CredenciamentoSafraPix()
          credencialPix.instituicao = instituicao
          credencialPix.tipo = tipoComunicacao
          setValue('credenciais', credencialPix)
          break
        case EnumTipoComunicacao.Boleto:
          const credencialBoleto = new CredenciamentoItauBoleto()
          credencialBoleto.instituicao = instituicao
          setValue('credenciais', credencialBoleto)
          break
        default:
          return
      }
    }
  }, [setValue])

  const habilitarDesabilitarCustomizadas = useCallback(() => {
    setIsCustom(prev => !prev)
    setCredenciais(getValues('instituicao'), getValues('tipoComunicacao'));
  }, [getValues, setCredenciais])


  return {
    getCampos,
    setCredenciais,
    credenciaisCustomizadas: propriedades,
    setarCredenciaisCustomizadas: setPropriedades,
    habilitarDesabilitarCustomizadas,
    isCustomizadas: isCustom
  };
}
