import { useStyles } from './menu-principal-perfil-styles';
import { Typography } from '@material-ui/core';
import { useSessaoAtual } from 'services/app/providers';
import { MenuPrincipalPerfilProps } from './menu-principal-perfil-props';
import { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import React from 'react';
import { TpPessoasMock } from 'data/mocks';
import UsuarioSemImagem from 'assets/img/usuario-sem-imagem.png';
import { useHistory } from 'react-router-dom';
import { EditarIcon } from 'views/components/icons';

export const MenuPrincipalPerfil = (props: MenuPrincipalPerfilProps) => {
  const classes = useStyles();
  const {
    usuario,
    tipoUsuario
  } = useSessaoAtual();
  const [nomeUsuario, setNomeUsuario] = useState(
    usuario?.NomeComercial || 'Visitante',
  );
  const [imagemAtual, setImagemAtual] = useState(UsuarioSemImagem);
  const history = useHistory()

  useEffect(() => {
    let imagem = '';
    try {
      if ((usuario?.UrlImagem || '').indexOf('http') > -1) {
        imagem = usuario?.UrlImagem!;
      } else {
        imagem = (usuario?.UrlImagem || '').length > 0 ? atob(usuario?.UrlImagem || '') : '';
      }
    } catch { }
    setImagemAtual(imagem);
    setNomeUsuario(usuario?.NomeComercial || 'Visitante');
  }, [usuario]);

  const addDefaultSrc = useCallback((e: any) => {
    setImagemAtual(UsuarioSemImagem);
  }, []);

  const tpPerfil = TpPessoasMock.find(item => item.Key === tipoUsuario())?.Value

  const component = React.useMemo(() => {
    return (
      <>
        <div
          className={classNames(
            classes.drawerResumoPerfilContainer,
            props.modeloAtual === 'Completo' && !props.isMobile
              ? classes.drawerPerfilCompletoNotMobile
              : undefined,
            props.modeloAtual === 'Mini' ? classes.drawerPerfilMini : undefined,
          )}
        >
          <div className="fotoPerfilContainer">
            {imagemAtual !== UsuarioSemImagem && (
              <img src={imagemAtual} onError={addDefaultSrc} alt="" />
            )}
            {imagemAtual === UsuarioSemImagem && (
              <img src={imagemAtual} alt="" />
            )}
            <div className="editarPerfil" onClick={() => history.push('/configuracoes')}>
              <EditarIcon tipo="GERAL" />
              Editar Usuário
            </div>
          </div>
          {props.modeloAtual === 'Completo' && (
            <div className="nomeContainer">
              <div className={classes.containerNomeEPlano}>
                <Typography className={classes.usuarioNome}>
                  {nomeUsuario}
                </Typography>
              </div>
              <Typography className={classes.usuarioPerfil}>
                {tpPerfil?.includes('(') ? <>
                {tpPerfil.split('(')[0]}<br/>
                {'(' + tpPerfil.split('(')[1]}
                </> : tpPerfil}
              </Typography>
            </div>
          )}
        </div>
      </>
    );
  }, [addDefaultSrc, classes.containerNomeEPlano, classes.drawerPerfilCompletoNotMobile, classes.drawerPerfilMini, classes.drawerResumoPerfilContainer, classes.usuarioNome, classes.usuarioPerfil, history, imagemAtual, nomeUsuario, props.isMobile, props.modeloAtual, tpPerfil]);

  return component;
};
