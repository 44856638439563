import { FaturasModel } from "model/api/gestao/fatura/faturas-model";
import { CardNaoEncontrado } from "views/components/cards";
import { CardConsultaFatura } from "views/components/cards/card-consulta-fatura";
import { InformacaoIcon } from "views/components/icons";

export interface ConsultaFaturasGridProps {
  list: Array<FaturasModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (fatura: FaturasModel) => any;
  onCardChecked: (id: string) => any;
}

export const ConsultaFaturaListData = (props: ConsultaFaturasGridProps) => {

  const onCardSelected = (fatura: FaturasModel) => {
    props.onCardSelected(fatura);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhuma Fatura Encontrada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((fatura, index) => {
          return (
            <CardConsultaFatura
              selected={
                props.selectedList.filter((item) => item === fatura.id).length >
                  0
                  ? true
                  : false
              }
              onCheck={onCardChecked}
              onClick={onCardSelected}
              model={fatura}
              key={index}
            />
          );
        })}
    </>
  );
};
