import { VariaveisAmbiente } from 'config';
import { NotaFiscalDanfeModel } from 'model/api/gestao/nota-fiscal/nota-fiscal-danfe';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetNotaFiscalDanfe() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<NotaFiscalDanfeModel>();

    const getNotaFiscalDanfe = useCallback(
        (id: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/fatura/${id}/nota-fiscal/DANFE`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getNotaFiscalDanfe,
    };
}
