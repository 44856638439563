
import { useMemo } from 'react';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

export const useFormPessoaSoftwareHouseValidation = () => {


    const FormPessoaSoftwareHouseValidationYup = useMemo(() => {
        return Yup.object().shape<AnyObject>({
            horaInicioCobranca: Yup.number()
                .min(0, 'Horário mínimo é 00:00')
                .max(23, 'Horáro máximo é 11:00 PM')
                .test({
                    name: 'maiorQueFim',
                    exclusive: false,
                    params: {},
                    message: 'Horário de Início não pode ser maior que o horário de fim',
                    test: (value, context) => (value || 0) < parseInt(context.parent.horaFimCobranca)
                })
                .typeError('Horário de Início da Cobrança é obrigatório'),

            horaFimCobranca: Yup.number()
                .min(0, 'Horário mínimo é 00:00 AM (0)')
                .max(23, 'Horáro máximo é 11:00 PM (23)')
                .test({
                    name: 'maiorQueInicio',
                    exclusive: false,
                    params: {},
                    message: 'Horário de Fim não pode ser menor que o horário de início',
                    test: (value, context) => (value || 0) > parseInt(context.parent.horaInicioCobranca)
                })
                .typeError('Horário de Fim da Cobrança é obrigatório'),


        });
    }, [])

    return {
        FormPessoaSoftwareHouseValidationYup,
    }
}