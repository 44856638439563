import { FaturasModel } from "model/api/gestao/fatura/faturas-model";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { useSessaoAtual } from "services/app";
import { dateParaCompetencia } from "utils/converter-competencia";
import { CardNaoEncontrado } from "views/components/cards";
import { CardAGerar } from "views/components/cards/card-a-gerar";
import { CardNotaFiscalRevenda } from "views/components/cards/card-nota-fiscal-revenda/card-nota-fiscal-revenda";
import { InformacaoIcon } from "views/components/icons";

export interface NotasFiscaisRevendaGridProps {
  list: Array<FaturasModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (id: string) => any;
  onCardChecked: (id: string) => any;
  onClickNextInvoices: React.Dispatch<React.SetStateAction<boolean>>
}

export const NotasFiscaisRevendaListData = (props: NotasFiscaisRevendaGridProps) => {
  const { tipoUsuario } = useSessaoAtual();

  const onCardSelected = (id: string) => {
    props.onCardSelected(id);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  const isRevendedor = tipoUsuario() === EnumTipoPessoas.Revendedor

  const temFaturasProximoMes = props.list.find(fatura => {
    return fatura.competenciaAnoMes === dateParaCompetencia(new Date(new Date().setMonth(new Date().getMonth() + 1)))
  })

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhuma Fatura Encontrada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}

      {(isRevendedor && !temFaturasProximoMes) && <CardAGerar onClick={props.onClickNextInvoices}/>}
   
      {props.list.length > 0 &&
        props.list.map((fatura, index) => {
          return (
            <CardNotaFiscalRevenda
              selected={
                props.selectedList.filter((item) => item === fatura.id).length >
                  0
                  ? true
                  : false
              }
              onCheck={onCardChecked}
              onClick={onCardSelected}
              model={fatura}
              key={index}
            />
          );
        })}
    </>
  );
};
