import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        borderRadius: theme.spacing(2)
    },
    cardImg: {
        display: "flex",
        objectFit: "cover",
        zIndex: 1,
        position: 'relative',
        background: 'transparent',
        width: "50px",
        height: "50px",
        transition: "0.2s ease-in-out transform",
        "&:hover": {
          transform: "scale(1.5)",
        },
      },
}))