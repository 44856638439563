import { isEmpty } from 'lodash';
import { RelatorioChartModel } from 'model/api/gestao/relatorios/relatorio-model';
import { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useThemeQueries } from 'views/theme';

type Props = {
    list: RelatorioChartModel[]
    stacked?: boolean;
    width?: number;
}

export default function ChartCancelamentos({ list, width }: Props) {

    const {theme} = useThemeQueries();

    const chartOptions = useMemo(() => {
        const series = list.sort((a, b) => {
            if(isEmpty(a.data) || isEmpty(b.data)) return 1
            return a.data[0].x - b.data[0].x
        })
        const options: ApexCharts.ApexOptions = {
            chart: {
                zoom: {
                    enabled: true,
                    type: 'x'
                },
                toolbar: {
                    show: false
                },
                type: 'line',
                height: 350,
            },
            dataLabels: {
                enabled: false,
            },
            colors: [theme.palette.error.main, theme.palette.error.dark, theme.palette.warning.dark],
            stroke: {
                curve: 'straight',
                width: 2,
                colors: [theme.palette.error.main, theme.palette.error.dark, theme.palette.warning.dark],
            },
            fill: {
                type: 'solid',
                gradient: {
                    opacityFrom: 1,
                    opacityTo: 1,
                }
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left'
            },
            yaxis: {
                min: 0,
            },
            xaxis: {
                labels: {
                    formatter: (value: string) => {
                        if(!value || typeof value !== 'string' || !value.substring) return ''
                        return `${value.substring(4)}/${value.substring(0, 4)}`
                    }
                }

            },
        };

        return {
            options,
            series
        }
    }, [list, theme.palette.error.dark, theme.palette.error.main, theme.palette.warning.dark])

    return (
        <Chart options={chartOptions.options} series={chartOptions.series} type={'line'} width={width || '100%'} height={600} />

    )
}