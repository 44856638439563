import { Button, Grid } from '@material-ui/core';
import { useStyles } from './modulos-list-styles';
import { useCadastros } from 'services/app';
import { ModulosListData } from './modulos-list-data';
import { ModuloModel } from 'model/api/gestao/sistemas/modulo-model';
import { NovoIcon } from 'views/components/icons';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { useState } from 'react';
import { DialogModulo } from 'views/components/dialog/dialog-modulo/dialog-modulo';

interface ModulosListProps {
  modulos: ModuloModel[];
  carregando: boolean,
  sistemaId: string;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  openDialog: boolean;
}

export const ModulosList = ({ carregando, modulos, sistemaId, openDialog, setOpenDialog }: ModulosListProps) => {
  const classes = useStyles();
  const { abrirCadastroNovoModulo } = useCadastros()

  const [selectedModel, setSelectedModel] = useState<ModuloModel>(new ModuloModel())

  const onCardSelected = (modulo: ModuloModel) => {
    setSelectedModel(modulo)
    setOpenDialog(true)
  };

  return (
    <AccordionSaurus
      labelPrimary='Módulos'
    >
      <div className={classes.defaultContainer}>
        <Grid container>
          <Grid item xs={12} className={classes.listContainer}>
            <ModulosListData
              carregando={carregando}
              list={modulos}
              selectedList={[]}
              onCardSelected={onCardSelected}
              onCardChecked={() => { }}
            />
            <Grid item xs={12}>
              <Grid container justifyContent='flex-end'>
                <Grid item md={5} lg={4} xs={12} style={{ padding: '0 8px 16px' }}>
                  <Button
                    variant="contained"
                    color='primary'
                    fullWidth
                    onClick={() => abrirCadastroNovoModulo(new ModuloModel(), sistemaId, '', false)}
                    size="large"
                    >
                    <NovoIcon tipo="BUTTON_PRIMARY"/>
                    NOVO MÓDULO
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <DialogModulo
        openned={openDialog}
        closeDialog={() => setOpenDialog(false)}
        modulo={selectedModel}
        sistemaId={sistemaId}
      />
    </AccordionSaurus>
  );
};
