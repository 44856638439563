import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { makeUtilClasses } from 'views';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PerguntaApuracaoFormModel } from 'model/app/forms/pergunta-apuracao/pergunta-apuracao-form-model';
import { useFormPerguntaApuracaoValidation } from './form-pergunta-apuracao-validation';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { KeyValueModel } from 'model';

interface FormPerguntaApuracaoProps extends DefaultFormProps<PerguntaApuracaoFormModel>{
    changePontosMaximos: (pontos: number) => void;
}

export const FormPerguntaApuracao = forwardRef<
    DefaultFormRefs<PerguntaApuracaoFormModel>,
    FormPerguntaApuracaoProps
>(
    (
        { loading, onSubmit, changePontosMaximos, ...props }: FormPerguntaApuracaoProps,
        ref,
    ) => {
        const { FormPerguntaApuracaoValidation } = useFormPerguntaApuracaoValidation();

        const {
            handleSubmit,
            control,
            formState: { errors },
            reset,
        } = useForm<PerguntaApuracaoFormModel>({
            defaultValues: { ...new PerguntaApuracaoFormModel() },
            resolver: yupResolver(FormPerguntaApuracaoValidation),
            criteriaMode: 'all',
            mode: 'onChange',
        });
        const refInputSenhaAtual = useRef<HTMLInputElement>(null);
        const utilClasses = makeUtilClasses();

        const submit = (data: PerguntaApuracaoFormModel) => {
            onSubmit(data);
        }

        useImperativeHandle(ref, () => ({
            submitForm: () => {
                handleSubmit(submit)();
            },
            resetForm: () => {
                refInputSenhaAtual.current?.focus();
                reset();
            },
            fillForm: (values: PerguntaApuracaoFormModel) => {
                reset({ ...values });
            },
        }));


        return (
            <>
                <Box my={2}>
                    <div className={utilClasses.formContainer}>
                        {loading && props.showLoading ? (
                            <div className={utilClasses.controlLoading}>
                                <CircularLoading tipo="NORMAL" />
                            </div>
                        ) : null}

                        <form
                            onSubmit={handleSubmit(submit)}
                            className={loading ? utilClasses.controlLoading : ''}
                        >
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name='pergunta'
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                label='Título da Pergunta'
                                                tipo='TEXTO'
                                                fullWidth
                                                {...field}
                                                error={Boolean(
                                                    errors.pergunta && errors.pergunta.message,
                                                )}
                                                helperText={
                                                    errors.pergunta
                                                        ? errors.pergunta?.message
                                                        : undefined
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name='detalhe'
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                label='Pergunta'
                                                tipo='TEXTO'
                                                multiline
                                                minRows={2}
                                                fullWidth
                                                {...field}
                                                error={Boolean(
                                                    errors.detalhe && errors.detalhe.message,
                                                )}
                                                helperText={
                                                    errors.detalhe
                                                        ? errors.detalhe?.message
                                                        : undefined
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name='maxPontos'
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                label='Máximo de Pontos'
                                                tipo='NUMERO_RANGE'
                                                fullWidth
                                                {...field}
                                                error={Boolean(
                                                    errors.maxPontos && errors.maxPontos.message,
                                                )}
                                                helperText={
                                                    errors.maxPontos
                                                        ? errors.maxPontos?.message
                                                        : undefined
                                                }
                                                onChange={(e) => {
                                                    changePontosMaximos(Number(e.target.value))
                                                    setValue('maxPontos', Number(e.target.value))
                                                }}
                                            />
                                        )}
                                    />
                                </Grid> */}
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name='ativo'
                                        render={({ field }) => (
                                            <SelectSaurus
                                                label='Status da Pergunta'
                                                conteudo={[
                                                    new KeyValueModel(true, 'Ativo'),
                                                    new KeyValueModel(false, 'Desativado')
                                                ]}
                                                fullWidth
                                                {...field}
                                                error={Boolean(
                                                    errors.ativo && errors.ativo.message,
                                                )}
                                                helperText={
                                                    errors.ativo
                                                        ? errors.ativo?.message
                                                        : undefined
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                <Controller
                                        control={control}
                                        name='interna'
                                        render={({ field }) => (
                                            <SelectSaurus
                                                label='Visibilidade da pergunta'
                                                conteudo={[
                                                    new KeyValueModel(true, 'Interna'),
                                                    new KeyValueModel(false, 'Indicação')
                                                ]}
                                                fullWidth
                                                {...field}
                                                error={Boolean(
                                                    errors.interna && errors.interna.message,
                                                )}
                                                helperText={
                                                    errors.interna
                                                        ? errors.interna?.message
                                                        : undefined
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Button style={{ display: 'none' }} type="submit"></Button>
                        </form>
                    </div>
                </Box>
            </>
        );
    },
);