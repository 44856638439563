import { Tooltip, Typography } from '@material-ui/core';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useThemeQueries } from 'views/theme';
import { EditarIcon } from 'views/components/icons';
import {useStyles} from './card-classificacoes-styles'
import { CardClassificacaoProps } from './card-classificacao-props';

export const CardClassificacao = ({
  model,
  onClick,
}: CardClassificacaoProps) => {
  const classes = useDefaultCardStyles();
  const cardStyles = useStyles();
  const { theme } = useThemeQueries()

  return (
    <>
      <DefaultCard
        onClick={() => {
          onClick(model);
        }}
      >
        <div className={`${classes.cardContent} ${cardStyles.container}`}>
          <div className={`${classes.celulaGrid} ${cardStyles.item}`} style={{minWidth: 120, maxWidth: 200}}>
            <Typography color="primary" variant="caption">
              Descrição
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
              style={{fontWeight: 600}}
            >
              {model.descricao}
            </Typography>
          </div>
          <div className={`${classes.celulaGrid} ${cardStyles.item}`} style={{minWidth: '150px'}}>
            <Typography color="primary" variant="caption">
              Categoria
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
              align='left'
            >
              {model.categoria}
            </Typography>
          </div>
          <div className={`${classes.celulaGrid} ${cardStyles.item}`}>
            <Typography color="primary" variant="caption">
              Abreviação
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {model.abreviacao}
            </Typography>
          </div>
        </div>
        <Tooltip arrow title="Editar Classificação">
          <div className={classes.cardRightButton} style={{marginLeft: '10px'}}>
            <EditarIcon tipo="BUTTON" fill={theme.palette.primary.main}/>
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};