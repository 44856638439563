import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { CircularLoading } from 'views';
import { ComissaoModel } from 'model/api/gestao/comissao/comissao-model';
import { CardComissao } from 'views/components/cards/card-comissao/card-comissao';

export interface ComandasListDataProps {
  list: Array<ComissaoModel>;
  carregando: boolean;
  onCardClicked: (id: string) => any;
}

export const ComissoesListData = (props: ComandasListDataProps) => {
  const onCardSelected = (id: string) => {
    props.onCardClicked(id);
  };


  return (
    <>
      {props.carregando && <CircularLoading tipo="FULLSIZED" />}
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhuma comissão encontrada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((model, index) => {
          return (
            <CardComissao
                model={model}
                onClick={onCardSelected}
            />
          );
        })}
    </>
  );
};
