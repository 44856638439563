import { Box, Button, Divider, Tooltip, Typography } from '@material-ui/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { RelatorioSaldoModel, RelatorioSaldoUnitarioModel } from 'model/api/gestao/relatorios/relatorio-saldo-model';
import { useThemeQueries } from 'views/theme';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { SwitchSaurus } from 'views/components/controles/switches/switch-saurus';
import ChartSaldo from '../chart-saldo/chart-saldo';
import { ResultadoRevendaModel } from 'model/api/gestao/relatorios/resultado-revenda-model';
import { useStyles } from './saldo-styles';
import { PesquisaIcon } from 'views/components/icons/pesquisa-icon';

interface DashboardSaldoProps {
    resultadoRevenda: ResultadoRevendaModel[];
    somenteAtivos: boolean;
    meses: number;
}

export default function Saldo({ resultadoRevenda, somenteAtivos, meses }: DashboardSaldoProps) {
    const { theme } = useThemeQueries()

    const [quantidade, setQuantidade] = useState<number>(30)
    const [disable, setDisable] = useState({
        anterior: true,
        proximo: false
    })
    const [stacked, setStacked] = useState<boolean>(false)
    const chartContainerRef = useRef<HTMLDivElement>(null)

    const classes = useStyles({ showOverflow: meses >= 24, chartContainerRef });

    const [dadosSaldo, setDadosSaldo] = useState<RelatorioSaldoModel[]>([])

    const dados = useRef<RelatorioSaldoModel[]>([])

    const paginacao = useRef<number>(0)
    const outrosStorage = useRef<RelatorioSaldoUnitarioModel[]>([])

    const tratarDados = useCallback(() => {
        try {
            let res = somenteAtivos ? resultadoRevenda.map(element => {
                if (element.contratosSaldo < 0) {
                    return { ...element, contratosSaldo: 0 }
                }

                return element
            }) : resultadoRevenda

            // res = res.filter(element => {
            //     let revendaIsEqual = true;
            //     let gerenteIsEqual = true;

            //     if (!isEmpty(model.revendaId)) {
            //         revendaIsEqual = model.revendaId === element.revendaId
            //     }
            //     if (!isEmpty(model.gerenteId)) {
            //         gerenteIsEqual = model.gerenteId === element.gerenteId
            //     }

            //     const anoMesValido = model.anoMesInicial <= element.anoMes && model.anoMesFinal >= element.anoMes;

            //     return (anoMesValido && revendaIsEqual && gerenteIsEqual)
            // })


            const ret = res.sort((a, b) => b.contratosSaldo - a.contratosSaldo)

            let arr: RelatorioSaldoModel[] = []

            for (let i = 0; i < ret.length; i++) {
                const foundItem = arr.find(x => x.name === ret[i].revenda)

                if (foundItem) {
                    const index = arr.indexOf(foundItem)
                    arr[index].data.push(new RelatorioSaldoUnitarioModel(ret[i].anoMes, ret[i].contratosSaldo, ret[i].revenda))
                    continue
                }

                const newData = new RelatorioSaldoModel(ret[i].revenda)
                newData.data.push(new RelatorioSaldoUnitarioModel(ret[i].anoMes, ret[i].contratosSaldo, ret[i].revenda))
                arr.push(newData)
            }

            dados.current = arr
            arr = []

            for (let i = 0; i < ret.length; i++) {
                const foundItem = arr.find(x => x.name === ret[i].revenda)

                if (foundItem) {
                    const index = arr.indexOf(foundItem)
                    arr[index].data.push(new RelatorioSaldoUnitarioModel(ret[i].anoMes, ret[i].contratosSaldo, ret[i].revenda))
                    continue
                }

                if (arr.length >= quantidade) {
                    if (arr.length === quantidade) {
                        const outrosData = new RelatorioSaldoModel('Outros', [
                            new RelatorioSaldoUnitarioModel(ret[i].anoMes, ret[i].contratosSaldo, ret[i].revenda)
                        ])
                        arr.push(outrosData)
                        continue
                    }
                    const unitItem = arr[arr.length - 1].data.find(unit => {
                        return unit.x === ret[i].anoMes
                    })!
                    if (unitItem) {
                        unitItem.y += ret[i].contratosSaldo
                        const index = arr[arr.length - 1].data.indexOf(unitItem)
                        arr[arr.length - 1].data[index] = unitItem
                        continue
                    }
                    arr[arr.length - 1].data.push(new RelatorioSaldoUnitarioModel(ret[i].anoMes, ret[i].contratosSaldo, ret[i].revenda))
                    continue
                }

                const newData = new RelatorioSaldoModel(ret[i].revenda)
                newData.data.push(new RelatorioSaldoUnitarioModel(ret[i].anoMes, ret[i].contratosSaldo, ret[i].revenda))



                arr.push(newData)
            }
            arr.map(item => {
                item.data = item.data.sort((a, b) => a.x - b.x)
                return item
            })

            if(arr.length <= quantidade){
                setQuantidade(arr.length)
                setDisable({
                    anterior: true,
                    proximo: true
                })
            }
            setDadosSaldo(arr)

        } catch (e: any) {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultadoRevenda, somenteAtivos])



    const carregarProximos = useCallback((naoMudarPagina?: boolean) => {
        if(quantidade > dados.current.length) return
        if (!naoMudarPagina) paginacao.current += 1
        const index = (paginacao.current * quantidade)
        let arr: RelatorioSaldoModel[] = dados.current.slice(index, index + quantidade)

        setDisable(prev => ({
            anterior: !naoMudarPagina ? false : prev.anterior,
            proximo: index + quantidade >= dados.current.length
        }))

        setDadosSaldo(prev => {

            const outros = prev.find(element => element.name === 'Outros')!

            if (!naoMudarPagina) {
                outrosStorage.current = [...outrosStorage.current,
                ...outros.data.filter(element => arr.find(x => x.name === element.revenda))]

                outros.data = outros.data.map(element => {
                    const valorSubtraido = arr.reduce((prev, curr) => {
                        const itemValor = curr.data.reduce((innerPrev, innerCurr) => innerCurr.x === element.x ? innerPrev + innerCurr.y : innerPrev, 0)
                        return itemValor + prev
                    }, 0)

                    return { ...element, y: arr.length === quantidade ? element.y - valorSubtraido : 0 }
                })
            }

            arr.push(outros)

            return arr.map(item => {
                item.data = item.data.sort((a, b) => a.x - b.x)
                return item
            })
        })

    }, [quantidade])

    const carregarAnteriores = useCallback(() => {
        paginacao.current -= 1
        const index = (paginacao.current * quantidade)
        let arr: RelatorioSaldoModel[] = dados.current.slice(index, index + quantidade)

        setDisable(() => ({
            anterior: paginacao.current === 0,
            proximo: false
        }))

        setDadosSaldo(prev => {
            const outros = prev.find(element => element.name === 'Outros')!

            const index = (outrosStorage.current.length - quantidade) - 1
            outros.data = outros.data.map(element => {
                const valorSubtraido = prev.filter(x => x.name !== 'Outros').reduce((prev, curr) => {
                    const itemValor = curr.data.reduce((innerPrev, innerCurr) => innerCurr.x === element.x ? innerPrev + innerCurr.y : innerPrev, 0)
                    return itemValor + prev
                }, 0)

                return { ...element, y: element.y + valorSubtraido }
            })
            outrosStorage.current = outrosStorage.current.filter((_, i) => i >= index)
            arr.push(outros)

            return arr.map(item => {
                item.data = item.data.sort((a, b) => a.x - b.x)
                return item
            })
        })

    }, [dados, quantidade, setDadosSaldo])

    useEffect(() => {
        tratarDados()
    }, [tratarDados])

    return (
        <>
            <Box display='flex' flexDirection='column' width='100%' gridGap={theme.spacing(1)}>
                <Box display='flex' justifyContent='space-between' alignItems='center' flexWrap='wrap' gridGap={5}>
                    <Typography color='primary' variant='h6'>Saldo das Revendas</Typography>
                    <Box display='flex' gridGap={8} alignItems='center'>
                        <Box display='flex' alignItems='center'>
                            <Typography
                                variant='caption'
                                color='textSecondary'
                            >
                                Empilhado:
                            </Typography>
                            <SwitchSaurus
                                value={stacked}
                                onChange={() => setStacked(prev => !prev)}
                                variant='LIGHT'
                            />
                        </Box>
                        <TextFieldSaurus
                            label='Registros'
                            value={quantidade}
                            onChange={(e: any) => setQuantidade(Number(e.target.value))}
                            tipo='NUMERO'
                            size='small'
                            positivo
                            min={1}
                            style={{ width: 130 }}
                            searchable
                            onSearch={() => {
                                carregarProximos(true)
                            }}
                        />
                        <Tooltip title='Mostrar Anteriores'>
                            <Button variant='outlined' color='primary'
                                onClick={carregarAnteriores}
                                disabled={disable.anterior}
                            >
                                <PesquisaIcon tipo='BUTTON' fill={disable.anterior ? theme.palette.divider : undefined}/> -
                            </Button>
                        </Tooltip>
                        <Tooltip title='Mostrar Próximos'>
                            <Button variant='outlined' color='primary'
                                onClick={() => carregarProximos()}
                                disabled={disable.proximo}
                            >
                                <PesquisaIcon tipo='BUTTON' fill={disable.proximo ? theme.palette.divider : undefined}/> +
                            </Button>
                        </Tooltip>
                    </Box>
                </Box>
                <Divider />
                <div className={classes.chartContainer} ref={chartContainerRef}>
                    <ChartSaldo list={dadosSaldo} carregarProximos={carregarProximos} stacked={stacked} width={
                        meses >= 24 ? meses * 100 : undefined
                    } />
                </div>
            </Box>
        </>
    );
}