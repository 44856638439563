import { guidEmpty } from "utils/guid-empty";

export class TerminalModel {
  constructor(
    public id: string = guidEmpty(),
    public contratoId: string = '',
    public tipo: string = '',
    public chave: string = '',
    public descricao: string = '',
    public ipLocal: string = '',
    public ipPublico: string = '',
    public maquina: string = '',
    public versao: string = '',
    public sistemaOperacional: string = '',
    public primeiroAcesso: string = '',
    public ultimoAcesso: string = '',
    public qAcessos: number = 0,
    public serialHd: string = '',
    public status: number = 0,
    public clienteId: string = guidEmpty(),
    public clienteNome: string = '',
    public clienteDoc: string = '',
    public dominio: string = '',
    public senhaOffline: string = '',
  ) { }
}
