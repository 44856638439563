import { Button, Grid, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useCallback, useRef } from 'react';
import { VoltarIcon } from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { useStyles } from './dialog-enviar-email-styles';
import { EnvioDeEmailIcon } from 'views/components/icons/envio-email-icon';
import { usePostEnviarNotaFiscalDANFE } from 'data/api/gestao/nota-fiscal/post-enviar-nota-fiscal-DNFE';
import { FaturaModel } from 'model/api/gestao/fatura/fatura-model';
import { useToastSaurus } from 'services/app';
import { EnviarEmail } from 'model/api/gestao/nota-fiscal/nota-fiscal-enviar-email-danfe';
import { DefaultFormRefs } from 'views/components/form/utils';
import { FormEnviarEmail } from 'views/components/form/master/enviar-email/form-enviar-email';

interface DialogEnviarEmailProps {
  openned: boolean;
  closeModal: () => void;
  model: FaturaModel;
  email: EnviarEmail;
}

export const DialogEnviarEmail = ({
  openned,
  closeModal,
  model,
  email
}: DialogEnviarEmailProps) => {
  const classes = useStyles();
  const { acoes } = useModalStyles()
  const { showToast } = useToastSaurus()

  const { postEnviarNotaFiscalDANFE, carregando } = usePostEnviarNotaFiscalDANFE();

  const cadEnviarEmail =
    useRef<DefaultFormRefs<EnviarEmail>>(null);

  const handleSubmit = useCallback(
    async () => {
      try {
        const ret = await postEnviarNotaFiscalDANFE(model.id, email);

        if (ret.erro) throw ret.erro
        showToast('success', 'Email enviado!')
      } catch (e: any) {
        showToast('error', e.message)
      }
    },
    [email, model.id, postEnviarNotaFiscalDANFE, showToast],
  );



  return (
    <DialogSaurus
      aberto={openned}
      titulo='Enviar para o Email'
      tamanho="sm">
      <Typography className={classes.title} >Informe o seu Email para enviarmos a nota fiscal: </Typography>
      <div className={acoes}>
        <Grid container spacing={2} className={classes.content}>
          <Grid item xs={12} md={12}>
            <FormEnviarEmail
              ref={cadEnviarEmail}
              showLoading={false}
              loading={carregando}
              onSubmit={handleSubmit}
            />
          </Grid>
        </Grid>
        <div className={classes.divider} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Button
              variant='outlined'
              color='primary'
              onClick={closeModal}
              fullWidth
            >
              <VoltarIcon tipo='BUTTON' />
              Voltar
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              disabled={carregando}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => { cadEnviarEmail?.current?.submitForm() }}
            >
              <EnvioDeEmailIcon tipo='BUTTON_PRIMARY' />
              Enviar
            </Button>
          </Grid>
        </Grid>
      </div>
    </DialogSaurus>
  );
};
