import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => (
    {
      cardRightButton: {
        width: "50px",
        borderLeftWidth: 1,
        borderLeftStyle: "solid",
        borderLeftColor: theme.palette.divider,
        margin: '5px 0px 5px 10px',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
        "& svg": {
          margin: 0,
          width: "22px",
          height: "22px",
          fill: theme.palette.primary.main,
        },
      },
      container: {
        padding: '8px',
        "& .MuiTypography-body1": {
          fontSize: 12
        }
      }
    }
  ))