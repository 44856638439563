import { makeStyles, Theme } from "@material-ui/core";

interface StylesProps{
    existe: boolean;
}

export const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
    container: {
        position: 'relative',
        cursor: 'pointer',
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        opacity: ({existe}) => existe? 1 : 0.5,
        '& img': {
            width: 100,
            height: 100,
            alignSelf: 'center'
        }
    },
    icon: {
        position: 'absolute',
        bottom: theme.spacing(3),
        right: theme.spacing(5),
        zIndex: 50,
        width: 30,
        height: 30,
        fill: ({existe}) => existe ? theme.palette.warning.main : theme.palette.success.main,
        [theme.breakpoints.down('md')]: {
            top: theme.spacing(1),
            bottom: 'unset',
            right: theme.spacing(1)
        }
    },
}))