import { VariaveisAmbiente } from 'config';
import { PlanoModel } from 'model/api/gestao/plano/plano-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetPlanoById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<PlanoModel>();

    const getPlanoById = useCallback(
        (id: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Planos/${id}`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getPlanoById,
    };
}
