import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { PessoaWhitelabelModel } from 'model/api/gestao/pessoa/pessoa-whitelabel-model';

export function usePutAtualizarPessoaWhitelabel() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putAtualizarPessoaWhitelabel = useCallback(
        (pessoaId: string, pessoa: PessoaWhitelabelModel) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/pessoa/${pessoaId}/Whitelabel`,
                method: 'PUT',
                data: pessoa,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putAtualizarPessoaWhitelabel,
    };
}