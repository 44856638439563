import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePatchReativarContrato() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const patchReativarContrato = useCallback(
        (id: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/contrato/${id}/Reativar`,
                method: 'PATCH',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        patchReativarContrato,
    };
}
