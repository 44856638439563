import { Box, Grid } from "@material-ui/core";
import { useGetPessoa } from "data/api/gestao/pessoa";
import { isEmpty } from "lodash";
import { ApiListModel } from "model/api/gestao/api-list-model/api-list-model";
import { PessoasModel } from "model/api/gestao/pessoa/pessoas-model";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useCadastros, useToastSaurus } from "services/app";
import { validarCPFCNPJ } from "utils/cpfcnpj-validate";
import { CircularLoading } from "views/components";
import { Paginacao } from "views/components/paginacao";
import { AgenteExternoListData } from "./agente-exteno-list-data";
import { AgenteExternoListSearchProps } from "./agente-externo-list-search-props";
import { useStyles } from "./agente-externo-list-styles";
import { useGetPessoaExportarExcel } from "data/api/gestao/pessoa/get-pessoa-exportar-excel";
import { ButtonFabMenu } from "views/components/controles/buttons/button-fab-menu";
import { MenuVerticalIcon } from "views/components/icons";
import { useThemeQueries } from "views/theme";
import { ButtonFabMenuModel } from "model/app/components/button-fab-menu/button-fab-menu";
import { ExportarExcelIcon } from "views/components/icons/exportar-excel-icon";
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles";

interface AgenteExternoListProps {
    searchProps: AgenteExternoListSearchProps;
}

export const AgenteExternoList = (props: AgenteExternoListProps) => {

    const classes = useStyles();
    const listClasses = useDefaultCadastroStyles();
    const { showToast } = useToastSaurus();
    const history = useHistory();
    const location = useLocation();
    const { cadastroPessoaState } = useCadastros();
    const { isMobile } = useThemeQueries();


    const { getPessoa, carregando: carregandoGet } = useGetPessoa();
    const { getPessoaExportarExcel, carregando: carregandoExcel } = useGetPessoaExportarExcel();


    const [selectedList, setSelectedList] = useState<Array<string>>([]);
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const [queryStatus, setQueryStatus] = useState<ApiListModel<PessoasModel>>({
        pageIndex: 1,
        totalPages: 0,
        totalResults: 0,
        pageSize: 0,
        list: Array<PessoasModel>(),
    });

    const carregando = carregandoExcel || carregandoGet

    const handleOpen = useCallback(() => {
        setOpenMenu(true);
    }, [])

    const handleClose = useCallback(() => {
        setOpenMenu(false);
    }, [])

    const fillResult = useCallback(
        async (
            page: number,
            totalPages: number,
            totalResults: number,
            list: Array<PessoasModel>
        ) => {
            setQueryStatus({
                pageIndex: page,
                list: list,
                totalResults: totalResults,
                totalPages: totalPages,
                pageSize: 0
            });
        },
        []
    );

    const search = useCallback(
        async (newPage: number) => {
            let termo = props.searchProps.termo
            if (validarCPFCNPJ(termo)) {
                termo = termo.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '')
            }

            const queryPesquisa = `${!isEmpty(props.searchProps.termo) ? `&query=${props.searchProps.termo}` : ''}`
            try {
                const query = `pageSize=30&TipoPessoa=${EnumTipoPessoas.AgenteExterno}${queryPesquisa}`

                const res = await getPessoa(newPage, query);
                if (res.erro) throw res.erro;

                if (res.resultado?.data === undefined) {
                    return
                }

                //se o index for maior que as paginas ele busca a ultima
                if (
                    res.resultado?.data.pageIndex > res.resultado?.data.totalPages &&
                    res.resultado?.data.totalResults > 0
                ) {
                    search(res.resultado.data.totalPages);
                    return;
                }

                fillResult(
                    res.resultado?.data.pageIndex,
                    res.resultado?.data.totalPages,
                    res.resultado?.data.totalResults,
                    res.resultado?.data.list
                );

                return res;
            } catch (e: any) {
                showToast("error", e.message);
            }
        },
        [props.searchProps, getPessoa, fillResult, showToast]
    );

    const pageChanged = useCallback(
        async (newPage: number) => {
            if (newPage <= queryStatus.totalPages || newPage > 0) {
                search(newPage);
            }
        },
        [search, queryStatus.totalPages]
    );

    const onCardSelected = (id: string) => {
        history.push({
            pathname: `/agente-externo/${id}`,
            state: {
                from: {
                    pathname: history.location.pathname,
                    page: queryStatus.pageIndex
                }
            }
        });
    };

    const onCardChecked = (id: string) => {
        const aux = [...selectedList];
        aux.push(id);
        setSelectedList(aux);
    };

    useEffect(() => {
        let page = Number(new URLSearchParams(location.search).get('page'));
        if (!cadastroPessoaState.aberto) {
            search(page || queryStatus.pageIndex);
        }
        location.search = ''
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryStatus.pageIndex, search, cadastroPessoaState]);

    const exportExcel = useCallback(async (newPage: number) => {
        const query =
            (!isEmpty(props.searchProps.termo)
                ? '&query=' + props.searchProps.termo
                : '') + `&tipoPessoa=${EnumTipoPessoas.AgenteExterno}`

        try {
            const res = await getPessoaExportarExcel(newPage, query)
            if (res.erro) throw (res.erro)
            if (!res.resultado) return;

            const blob = new Blob([res.resultado?.data], { type: 'application/vnd.ms-excel' });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a')

            anchor.href = url
            anchor.download = `AgentesExternos-${new Date().toLocaleDateString().replaceAll('/', '-')}.xls`
            anchor.click()

        } catch (e: any) {
            showToast('error', e.message);
        }
    }, [getPessoaExportarExcel, props.searchProps.termo, showToast])

    const onClickMenuItem = useMemo(() => {
        const itens = new Array<ButtonFabMenuModel>();

        itens.push(new ButtonFabMenuModel(<ExportarExcelIcon tipo='BUTTON_FAB' />, 'Exportar Excel', () => exportExcel(1)))

        return itens;
    }, [exportExcel])

    return (
        <>
            <div className={classes.defaultContainer}>
                {carregando && <CircularLoading tipo="FULLSIZED" />}
                <Box display='flex' flexDirection='column' height='100%' justifyContent='space-between' flex={1} overflow='auto'>
                    <Grid container className={listClasses.listContainer}>
                        <Grid item xs={12}>
                            <Paginacao
                                pageChanged={pageChanged}
                                totalPages={queryStatus.totalPages}
                                totalRegisters={queryStatus.totalResults}
                                currentPage={queryStatus.pageIndex}
                                exportarExcel={() => exportExcel(1)}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.listContainer}>
                            <AgenteExternoListData
                                carregando={carregando}
                                list={queryStatus.list}
                                selectedList={selectedList}
                                onCardSelected={onCardSelected}
                                onCardChecked={onCardChecked}
                                label='Agente Externo'
                            />
                        </Grid>
                    </Grid>
                </Box>
            </div>

            {isMobile ? (
                <>
                    {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
                    <ButtonFabMenu
                        open={openMenu}
                        onOpen={handleOpen}
                        onClose={handleClose}
                        values={onClickMenuItem}
                        icon={<MenuVerticalIcon tipo='BUTTON_FAB' />}
                        style={{
                            zIndex: 0,
                        }}
                    />
                </>
            ) : null}

        </>
    );
}