import { Button, Grid, Typography } from '@material-ui/core';
import { useCallback, useState, useEffect, useMemo } from 'react';
import { useStyles } from './simulacao-contrato-styles';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { EnumTipoCalculo, KeyValueModel } from 'model';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import {
    ContratoFormAlternativoModel,
    ContratoFormModuloCardModel,
} from 'model/app/forms/contrato/contrato-form-model';
import { isEmpty } from 'lodash';
import { TabelasSistema } from 'model/api/gestao/sistemas/sistema-tabela-precos-model';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { calcPercent } from 'utils/calc-percent';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { guidEmpty } from 'utils/guid-empty';
import { CancelarIcon } from 'views/components/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { EnumTipoCobrancaRevenda } from 'model/enums/enum-tipo-cobranca-revenda';
import { useConfirm } from 'material-ui-confirm';
import { EnumTipoControleModulo } from 'model/enums/enum-tipo-controle-modulo';
import { DefaultFormProps, DefaultFormRefs } from '../../utils';
import { useFormSimulacaoContratoValidation } from './simulacao-contrato-validation';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { toCurrency } from 'utils/toCurrency';
import { ValoresState } from '../contrato/contrato-cadastro/form-contrato-cadastro'
import { PlanoModel } from 'model/api/gestao/plano/plano-model';
import { useGetNovoContrato } from 'data/api/gestao/contratos/get-novo-contrato';
import CardModulosContrato from 'views/components/cards/card-modulos-contrato/card-modulos-contrato';

interface Props extends DefaultFormProps<ContratoFormAlternativoModel> {
    erro: string | null;
    setErro: (value: string | null) => void;
    sistemaId: string;
    pessoaId: string;
    sistemaNome: string;
}

export const FormSimulacaoContrato = React.forwardRef<
    DefaultFormRefs<ContratoFormAlternativoModel>,
    Props
>((props: Props, refs) => {
    const { getNovoContrato, carregando } = useGetNovoContrato();
    const { showToast } = useToastSaurus();

    const utilClasses = makeUtilClasses();
    const classes = useStyles();
    const confirm = useConfirm();
    const { usuario, tipoUsuario } = useSessaoAtual();

    const podeVerCusto = useMemo(() => (
        ![
            EnumTipoPessoas.Representante,
            EnumTipoPessoas.FuncionarioFinanceiroSemCusto,
            EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto,
            EnumTipoPessoas.Funcionario,
            EnumTipoPessoas.FuncionarioSoftwareHouse,
        ].includes(tipoUsuario())
    ), [tipoUsuario]);
    
    const isTipoRevenda = usuario?.TipoCobrancaRevenda === EnumTipoCobrancaRevenda.Revenda || usuario?.TipoCobrancaRevenda === EnumTipoCobrancaRevenda.RevendaPagas

    const { FormSimulacaoContratoValidation } = useFormSimulacaoContratoValidation();

    const [model, setModel] = useState<ContratoFormAlternativoModel>(
        new ContratoFormAlternativoModel(),
    );
    const [tabelasPreco, setTabelasPreco] = useState<TabelasSistema[]>([]);
    const [tabelaPreco, setTabelaPreco] = useState<TabelasSistema>(
        new TabelasSistema(),
    );
    const [precosModulosCard, setPrecosModulosCard] = useState<
        ContratoFormModuloCardModel[]
    >([]);
    const [planosMock, setPlanosMock] = useState<KeyValueModel[]>([]);
    const [planos, setPlanos] = useState<PlanoModel[]>([])
    const [planoId, setPlanoId] = useState<string>('');

    const [valoresState, setValoresState] = useState<ValoresState>(new ValoresState())

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        reset,
        setValue,
        getValues,
        setError,
        clearErrors,
    } = useForm<ContratoFormAlternativoModel>({
        resolver: yupResolver(FormSimulacaoContratoValidation),
        defaultValues: { ...model },
        criteriaMode: 'all',
        mode: 'onChange',
    });

    const { isMobile } = useThemeQueries();

    const onSubmit = useCallback((form: ContratoFormAlternativoModel) => {

        props.onSubmit(form, model);
    }, [model, props]);

    const confirmSubmit = useCallback((form: ContratoFormAlternativoModel) => {
        confirm({
            title: 'Criar Contrato',
            description: (
                <Typography>Deseja criar este contrato? Valor do contrato: <b>R{form.valorContrato.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b></Typography>
            ),
            cancellationText: 'Cancelar',
            confirmationText: 'Salvar'
        }).then(() => onSubmit(form))
    }, [confirm, onSubmit])

    React.useImperativeHandle(refs, () => ({
        submitForm: () => {
            handleSubmit(confirmSubmit)();
        },
        resetForm: () => {
            setModel(new ContratoFormAlternativoModel());
            reset();
        },
        fillForm: (model: ContratoFormAlternativoModel) => {
            if (isTipoRevenda) {
                model.melhorDia = null
            } else {
                model.melhorDia = 25
            }
            setModel(model);
            reset({ ...model });
        },
    }));

    const alterarQtdModulo = useCallback(
        (model: ContratoFormModuloCardModel) => {
            const modulo = [...precosModulosCard];
            const ret = modulo.find((item) => item.modulo.id === model.modulo.id);

            const index = modulo.indexOf(ret || new ContratoFormModuloCardModel());
            modulo[index] = model;
            setPrecosModulosCard(modulo);
        },
        [precosModulosCard],
    );

    const alterarQtd = useCallback(
        (model: ContratoFormModuloCardModel, qtde: number) => {
            const newState = Object.assign({}, model);

            newState.quantidadeContratada = qtde;
            alterarQtdModulo(newState);
        },
        [alterarQtdModulo],
    );

    const contratoDadosWrapper = useCallback(async () => {
        try {
            const res = await getNovoContrato(props.pessoaId, props.sistemaId)

            if (res.erro) throw res.erro
            if (!res.resultado) throw new Error('Erro ao carregar dados. Tente novamente.')

            //Caso o revendedor não tenha configurado a tabela de preços
            if (isEmpty(res.resultado.data.precos.tabelas)) {
                throw new Error('É necessário configurar uma tabela de preço para este sistema.')
            }

            setPlanosMock(res.resultado.data.planos.map(plano => new KeyValueModel(plano.id, plano.nome)))
            setPlanos(res.resultado.data.planos)

            setTabelaPreco(res.resultado.data.precos.tabelas[0])
            setTabelasPreco(res.resultado.data.precos.tabelas)
            setValue('tabelaPrecoId', res.resultado.data.precos.tabelas[0].tabelaPrecoId)

        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getNovoContrato, props.pessoaId, props.sistemaId, setValue, showToast])

    useEffect(() => {
        if (!props.erro) {
            contratoDadosWrapper();;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (!isEmpty(tabelaPreco) && !isEmpty(tabelaPreco.precoModulos)) {
            const contemModulo = tabelaPreco.precoModulos.filter((pm) => {
                const foundValue = precosModulosCard.find(
                    (md) => md.modulo.id === pm.modulo.id,
                );

                return !isEmpty(foundValue);
            });

            if (contemModulo?.length !== precosModulosCard.length) {
                setPlanoId('');
                setValue('planoId', null);
            }

            setPrecosModulosCard(
                tabelaPreco.precoModulos.map((item) => {
                    return {
                        modulo: item.modulo,
                        percAdicional: item.percAdicional,
                        percAdicionalCusto: item.percAdicionalCusto,
                        quantidadeContratada: item.modulo.quantidadePadrao === -1 ? 0 : item.modulo.quantidadePadrao,
                        valor: item.valor,
                        valorCustoOriginal: item.valorCustoOriginal,
                        valorCusto: item.valorCusto,
                        editavel: false,
                    };
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValue, tabelaPreco, tabelaPreco.precoModulos]);

    const valoresFinais = precosModulosCard.map((item) => {
        let quantMult = item.quantidadeContratada;
        let quantMult2 = item.quantidadeContratada;
        let quantMult3 = item.quantidadeContratada;
        let valorFinal = item.valor;
        let valorCusto = item.valorCusto;
        let valorCustoOriginal = item.valorCustoOriginal;

        if (item.quantidadeContratada === -1) {
            return {
                ...item,
                valorFinal: 0,
                valorCusto: 0,
                valorCustoOriginal: 0
            };
        }

        if (item.quantidadeContratada > 1) {
            quantMult =
                toDecimal((item.quantidadeContratada - 1) *
                    calcPercent(item.valor, item.percAdicional), 6);
            quantMult2 =
                toDecimal((item.quantidadeContratada - 1) *
                    calcPercent(item.valorCusto, item.percAdicionalCusto), 6);
            quantMult3 = toDecimal((item.quantidadeContratada - 1) *
                calcPercent(item.valorCustoOriginal, item.percAdicionalCusto), 6);
        }

        if (item.quantidadeContratada === 0) {
            valorFinal = 0;
            valorCusto = 0;
            valorCustoOriginal = 0;
        }
        else if (item.quantidadeContratada < 2) {
            valorFinal = item.valor;
            valorCusto = item.valorCusto;
            valorCustoOriginal = item.valorCustoOriginal;
        } else {
            valorFinal = valorFinal + quantMult;
            valorCusto = valorCusto + quantMult2;
            valorCustoOriginal = valorCustoOriginal + quantMult3;
        }

        if (item.modulo.tipoCalculo === EnumTipoCalculo.Modulo && item.modulo.tipoControle === EnumTipoControleModulo.Filial) {
            item.modulo.codModuloBaseCalculo = item.modulo.codModuloBaseCalculo.toString()
            const codsBase = item.modulo.codModuloBaseCalculo.includes(',') ? item.modulo.codModuloBaseCalculo.split(',').map(cod => Number(cod)) : item.modulo.codModuloBaseCalculo.split('-').map(cod => Number(cod))
            const moduloBase = precosModulosCard.filter(mod => codsBase.includes(mod.modulo.codigo))

            const valorBase = toDecimal(moduloBase.reduce((prev, curr) => {
                if (curr.quantidadeContratada === 0) return prev
                if (curr.quantidadeContratada === 1) {
                    return curr.valor + prev
                }
                return toDecimal((curr.quantidadeContratada - 1) * calcPercent(curr.valor, curr.percAdicional) + curr.valor + prev, 6)
            }, 0))

            const valorBaseOriginal = toDecimal(moduloBase.reduce((prev, curr) => {
                if (curr.quantidadeContratada === 0) return prev
                if (curr.quantidadeContratada === 1) {
                    return curr.valorCustoOriginal + prev
                }
                return toDecimal((curr.quantidadeContratada - 1) * calcPercent(curr.valorCustoOriginal, curr.percAdicionalCusto) + curr.valorCustoOriginal + prev, 6)
            }, 0))

            const valorBaseCusto = moduloBase.reduce((prev, curr) => {
                if (curr.quantidadeContratada === 0) return prev
                if (curr.quantidadeContratada === 1) {
                    return curr.valorCusto + prev
                }
                return toDecimal((curr.quantidadeContratada - 1) * calcPercent(curr.valorCusto, curr.percAdicionalCusto) + curr.valorCusto + prev, 6)
            }, 0)


            valorFinal = moduloBase.length > 0 && item.quantidadeContratada > 0 ? toDecimal((item.quantidadeContratada) * calcPercent(valorBase, item.percAdicional), 6) : valorFinal
            valorCusto = moduloBase.length > 0 && item.quantidadeContratada > 0 ? toDecimal((item.quantidadeContratada) * calcPercent(valorBaseCusto, item.percAdicionalCusto), 6) : valorCusto
            valorCustoOriginal = moduloBase.length > 0 && item.quantidadeContratada > 0 ? toDecimal((item.quantidadeContratada) * calcPercent(valorBaseOriginal, item.percAdicionalCusto), 6) : valorCustoOriginal

        } else if (item.modulo.tipoCalculo === EnumTipoCalculo.Modulo) {
            const codBase = Number(item.modulo.codModuloBaseCalculo)
            const moduloBase = precosModulosCard.find(mod => mod.modulo.codigo === codBase)

            valorFinal = moduloBase ? valorFinal * (moduloBase?.quantidadeContratada || 1) : valorFinal
            valorCusto = moduloBase ? valorCusto * (moduloBase?.quantidadeContratada || 1) : valorCusto
            valorCustoOriginal = moduloBase ? valorCustoOriginal * (moduloBase.quantidadeContratada || 1) : valorCustoOriginal

        }


        return {
            ...item,
            valorFinal: valorFinal,
            valorCusto: valorCusto,
            valorCustoOriginal: valorCustoOriginal
        };
    });

    useEffect(() => {
        if (!props.erro) {
            // try catch pra retirar o erro que não estava entendendo o que estava acontecendo
            try {
                const vCalculado = valoresFinais.reduce((a: any, b: any) => a + b.valorFinal, 0);
                const vContrato = valoresFinais.reduce((a: any, b: any) => a + b.valorFinal, 0)
                const vCusto = valoresFinais.reduce((a: any, b: any) => a + b.valorCusto, 0)
                const vOriginal = valoresFinais.reduce((a: any, b: any) => a + b.valorCustoOriginal, 0)

                setValue('valorCalculado', vCalculado)
                setValue('valorContrato', vContrato)
                setValue('valorCusto', vCusto)

                setValoresState(prev => ({
                    valorCalculado: vCalculado,
                    valorContrato: vContrato,
                    valorCusto: vCusto,
                    valorDesconto: prev.valorDesconto,
                    valorCustoOriginal: vOriginal
                }))

                validateFields()
            } catch {

            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [precosModulosCard])

    const setValores = useCallback((event: any) => {
        if (toDecimal(event.target.value) < 0 || event.target.value.includes('-')) return

        setValue(event.target.name, event.target.value);

        // const valorDesconto = toDecimal(
        //     event.target.name === 'valorDesconto'
        //         ? event.target.value
        //         : getValues('valorDesconto'), 4
        // );

        // const valorContrato = toDecimal(
        //     event.target.name === 'valorContrato' ? event.target.value : getValues('valorContrato'), 4
        // )

        // if (event.target.name === 'valorDesconto') {
        //     setValue(
        //         'valorContrato',
        //         toDecimal(valoresFinais.reduce((a: any, b: any) => a + b.valorFinal, 0) -
        //             valorDesconto, 4)
        //     );
        //     return;
        // }

        if (event.target.name === 'valorContrato') {
            // setValue(
            //     'valorDesconto',
            //     toDecimal(toDecimal(getValues('valorCalculado'), 4) - valorContrato, 4) < 0 ? (
            //         0
            //     ) : (
            //         toDecimal(toDecimal(getValues('valorCalculado'), 4) - valorContrato, 4)
            //     )
            // );
            setValoresState(prev => ({
                ...prev,
                valorContrato: toDecimal(event.target.value)
            }))
        }
    }, [setValue])

    const validateFields = useCallback(() => {
        const valorContrato = toDecimal(getValues('valorContrato'), 4);
        const valorTabelado = toDecimal(getValues('valorCalculado'), 4)
        if (
            valorContrato < valorTabelado &&
            !podeVerCusto
        ) {
            setError('valorContrato', {
                type: 'error',

            });
        } else if (
            valorContrato >= valorTabelado
        ) {
            clearErrors('valorContrato');
        }
    }, [clearErrors, getValues, podeVerCusto, setError]);


    const selecionarPlano = useCallback(async (item: KeyValueModel) => {
        try {
            const plano = planos.find(plano => plano.id === item.Key) || new PlanoModel()

            setPrecosModulosCard((prev) => {
                const ret = plano.modulos;
                const modulosNaoPlanos = prev
                    .filter(
                        (item) =>
                            !ret.map((fill) => fill.moduloId).includes(item.modulo.id),
                    )
                    .map((item) => ({
                        ...item,
                        editavel: true,
                    }));
                const temPlanos = plano.modulos.map((item) => {
                    const valoresPrev = prev.filter(
                        (pr) => pr.modulo.id === item.moduloId,
                    )[0];

                    return {
                        ...valoresPrev,
                        quantidadeContratada: item!.quantidadeContratada,
                        editavel: false,
                    };
                });
                return [...modulosNaoPlanos, ...temPlanos];
            });
            setValue('planoId', item.Key);
            setPlanoId(item.Key);
        } catch (err: any) {
            showToast('error', err.message);
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            model.tabelaPrecoId,
            setValue,
            showToast,
            tabelaPreco,
            tabelasPreco,
        ],
    );

    const removePlano = useCallback(() => {
        setPlanoId('');
        setValue('planoId', null);
    }, [setValue]);

    const valorLucro = useCallback(() => {
        const vLucro = toDecimal(valoresState.valorContrato - valoresState.valorCusto);
        const percLucro = toDecimal(((vLucro / valoresState.valorCusto) * 100))

        return `${toCurrency(vLucro)} (%${isNaN(percLucro) ? 0 : toDecimalString(percLucro)})`
    }, [valoresState])

    if (!carregando && props.erro) {
        return (
            <>
                <div
                    className={utilClasses.formContainer}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <div>
                            <CancelarIcon
                                tipo="GERAL"
                                fill="#D22"
                                style={{
                                    width: 150,
                                }}
                            />
                        </div>
                        <Typography variant="h4" color="primary">
                            Ocorreu um Erro
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                            Detalhe: {props.erro}
                        </Typography>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div
                className={utilClasses.formContainer}
            >
                {carregando || props.showLoading ? (
                    <CircularLoading tipo="FULLSIZED" />
                ) : null}
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        background: '#fff',
                        padding: !isMobile ? '1rem' : '10px',
                    }}
                    className={`${classes.formContainer} ${props.loading ? utilClasses.controlLoading : ''
                        }`}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems='center'>
                                <Grid item xs={12}>
                                    <Typography variant="caption">Sistema</Typography>
                                    <Typography
                                        color="textPrimary"
                                        variant="body1"
                                        style={{ fontWeight: 600 }}
                                    >
                                        {props.sistemaNome}
                                    </Typography>
                                </Grid>
                                {planosMock.length > 0 && <Grid item xs={12} md={6}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row-reverse',
                                        }}
                                    >
                                        <Controller
                                            name="planoId"
                                            control={control}
                                            render={({ field }) => (
                                                <SelectSaurus
                                                    conteudo={
                                                        [new KeyValueModel(-1, 'Não utilizar plano'), new KeyValueModel(-5, ''), ...planosMock]
                                                    }
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Selecionar Plano (opcional)"
                                                    size='small'
                                                    {...field}
                                                    value={planoId}
                                                    onChange={(event) => {
                                                        if (event) {
                                                            const item = planosMock.filter(
                                                                (item) => item.Key === event.target.value,
                                                            )[0];
                                                            if (item) {
                                                                selecionarPlano(item);
                                                            } else {
                                                                removePlano()
                                                            }
                                                        }
                                                    }}
                                                    helperText={
                                                        touchedFields.planoId && errors.planoId
                                                            ? errors.planoId.message
                                                            : undefined
                                                    }
                                                    error={Boolean(
                                                        errors.planoId && errors.planoId.message,
                                                    )}
                                                />
                                            )}
                                        />
                                    </div>
                                </Grid>}
                                <Grid item xs={12} md={planosMock.length > 0 ? 6 : 12}>
                                    {(Array.isArray(tabelasPreco)) && (
                                        <Controller
                                            name="tabelaPrecoId"
                                            control={control}
                                            render={({ field }) => (
                                                <SelectSaurus
                                                    conteudo={tabelasPreco.map(
                                                        (item) =>
                                                            new KeyValueModel(item.tabelaPrecoId, item.nome),
                                                    )}
                                                    fullWidth
                                                    size='small'
                                                    variant="outlined"
                                                    label="Tabela de Preços"
                                                    {...field}
                                                    value={getValues('tabelaPrecoId')}
                                                    onChange={(event) => {
                                                        if (event) {
                                                            const item = tabelasPreco.filter(
                                                                (item) =>
                                                                    item.tabelaPrecoId === event.target.value,
                                                            )[0];
                                                            if (item) {
                                                                setTabelaPreco(item);
                                                                setValue('tabelaPrecoId', item.tabelaPrecoId);
                                                            }
                                                        }
                                                    }}
                                                    helperText={
                                                        touchedFields.tabelaPrecoId && errors.tabelaPrecoId
                                                            ? errors.tabelaPrecoId.message
                                                            : undefined
                                                    }
                                                    error={Boolean(
                                                        errors.tabelaPrecoId && errors.tabelaPrecoId.message,
                                                    )}
                                                />
                                            )}
                                        />)}
                                </Grid>
                                {!isTipoRevenda && <Grid item xs={12} md={2}>
                                    <div>
                                        <Typography variant='caption' color='textSecondary'>Valor Tabelado</Typography>
                                        <Typography style={{ fontSize: '1.1rem', fontWeight: 500 }}>{toCurrency(valoresState.valorCalculado)}</Typography>

                                    </div>
                                </Grid>}
                                {!isTipoRevenda && < Grid item xs={12} md={4}>
                                    <Controller
                                        name="valorContrato"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                label="Valor Contrato"
                                                size="small"
                                                style={{ marginTop: 16 }}
                                                fullWidth={false}
                                                showStartAdornment
                                                {...field}
                                                value={getValues('valorContrato')}
                                                tipo="DECIMAL"
                                                helperText={
                                                    errors.valorContrato
                                                        ? errors.valorContrato.message
                                                        : undefined
                                                }
                                                error={Boolean(
                                                    errors.valorContrato && errors.valorContrato.message,
                                                )}
                                                manterMascara
                                                onChange={(ev) => {
                                                    setValores(ev)
                                                }}
                                                onBlur={validateFields}
                                            />
                                        )}
                                    />
                                </Grid>}
                                {podeVerCusto && (
                                    <>
                                        <Grid item xs={12} md={3}>
                                            <div>
                                                <Typography variant='caption' color='textSecondary'>Valor Custo</Typography>
                                                <div className={classes.valorDescontoBox}>
                                                    <Typography style={{ fontSize: '1rem', fontWeight: 500 }}>{toCurrency(valoresState.valorCusto)}</Typography>
                                                    {valoresState.valorCusto < valoresState.valorCustoOriginal && <Typography variant='caption'>{toCurrency(valoresState.valorCustoOriginal)}</Typography>}
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} md={3}>
                                            <Typography variant='caption' color='textSecondary'>Lucro</Typography>
                                            <Typography style={{ fontSize: '1rem', fontWeight: 500, color: '#148EE5' }}>{valorLucro()}</Typography>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h6' color='textPrimary' style={{ padding: '1rem 0 .5rem' }}>Módulos</Typography>
                            <Grid
                                container
                                spacing={1}
                                style={{
                                    flex: '1 1 auto',
                                    overflowY: 'auto',
                                    position: 'relative',
                                }}
                                className={classes.modulosContainer}
                            >
                                {precosModulosCard.sort((a, b) => a.modulo.codigo - b.modulo.codigo).map((item) => {
                                    return (
                                        <CardModulosContrato
                                            hasPlanoId={
                                                !isEmpty(planoId || planoId !== guidEmpty())
                                            }
                                            alterarQtd={alterarQtd}
                                            model={item}
                                            modulos={precosModulosCard}
                                            isTipoRevenda={isTipoRevenda}

                                        />
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div >
        </>
    );
});
