import { Box, Button, Card, Divider, Grid, Slide, Tooltip, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { picker } from "utils/picker";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { CircularLoading, LoadingButton } from "views/components/utils";
import { useStyles } from "./indicacao-editar-chat-styles";
import { usePostIndicacoesInteracoes } from "data/api/gestao/indicacoes/post-indicacoes-interacoes";
import { useGetIndicacoesInteracoes } from "data/api/gestao/indicacoes/get-indicacoes-interacoes";
import { IndicacoesInteracoesModel } from "model/api/gestao/indicacoes/indicacoes-interacoes-model";
import { IndicacoesInteracoesPostModel } from "model/api/gestao/indicacoes/indicacoes-interacoes-post-model";
import { EnviarMensagemIcon } from "views/components/icons/enviar-mensagem-icon";
import { isEmpty } from "lodash";
import { InteracaoChat } from "./interacao-chat/interacao-chat";
import { EnumStatusIndicacao } from "model/enums/enum-status-indicacao";
import { TagIcon } from "views/components/icons/tag-icon";
import { useThemeQueries } from "views/theme";
import { EditarIcon, OkIcon } from "views/components/icons";
import { MenuOptions } from "views/components/menu-options/menu-options";
import { MenuSaurusModel } from "model/app/components/menu-saurus/menu-saurus-model";
import { guidEmpty } from "utils/guid-empty";
import { toDateString, toDateStringApi } from "utils/to-date";
import { DialogConclusaoIndicacao } from "views/components/dialog/dialog-conclusao-indicacao/dialog-conclusao-indicacao";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { DialogFinalizarIndicacao } from "views/components/dialog/dialog-finalizar-indicacao/dialog-finalizar-indicacao";
import { useRetornarCorStatus } from "services/app/use-cases/retornar-cor-status";

type Props = {
    id: string;
    status: string;
    indStatus?: EnumStatusIndicacao;
}

export const IndicacaoEditarChat = ({ id, status, indStatus }: Props) => {

    //AUX
    const modalClasses = useModalStyles();
    const classes = useStyles()
    const { theme, } = useThemeQueries();
    const { showToast } = useToastSaurus();
    const { retornarCorStatus } = useRetornarCorStatus();

    //PROVIDERS
    const { usuario, tipoUsuario } = useSessaoAtual()

    //CHAMADAS DA API
    const { postIndicacoesInteracoes, carregando: carregandoPost } = usePostIndicacoesInteracoes()
    const { getIndicacoesInteracoes, carregando: carregandoGet } = useGetIndicacoesInteracoes()

    //STATES E REFS
    const contentFormsRef = useRef<any>()
    const scrollRef = useRef<HTMLElement | null>(null)
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [openDialogFinalizar, setOpenDialogFinalizar] = useState<boolean>(false)
    const [interacoes, setInteracoes] = useState<IndicacoesInteracoesModel[]>([])

    const validarStatus = useCallback(() => {
        const ultimaInteracao = interacoes.length > 0 && interacoes[interacoes.length - 1]

        if (ultimaInteracao && ultimaInteracao.tipoInteracao < EnumStatusIndicacao.PropostaRecusada) return true

        if (tipoUsuario() === EnumTipoPessoas.SoftwareHouse) return true

        return false
    }, [interacoes, tipoUsuario])

    const getInteracoesWrapper = useCallback(async () => {
        try {

            const res = await getIndicacoesInteracoes(id, 1, `&pageSize=0`)

            if (res.erro) {
                throw res.erro
            }

            if (!res.resultado) {
                return
            }

            setInteracoes(res.resultado.data.list)

            scrollRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            })

        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [getIndicacoesInteracoes, id, showToast])

    const {
        handleSubmit,
        reset,
        control,
    } = useForm<IndicacoesInteracoesPostModel>({

    })

    const enviarMensagem = useCallback(async (value: IndicacoesInteracoesPostModel) => {
        try {
            if (isEmpty(value.descricao)) {
                return showToast('error', 'Você não pode enviar uma mensagem vazia.')
            }

            if (carregandoPost) return

            const model = interacoes.filter(x => x.indicacaoId === id)[0];

            if (isEmpty(model)) {
                return showToast('error', 'Aconteceu um erro inesperado. Tente novamente em alguns minutos.')
            }

            const interacaoPicker = picker<IndicacoesInteracoesPostModel>(value, new IndicacoesInteracoesPostModel())

            interacaoPicker.indicacaoId = id;
            interacaoPicker.responsavelId = usuario?.Id ?? '';
            interacaoPicker.tipoInteracao = model.tipoInteracao;
            interacaoPicker.dataHora = toDateStringApi(new Date())

            reset(new IndicacoesInteracoesPostModel())

            const res = await postIndicacoesInteracoes(id, interacaoPicker)

            if (res.erro) throw res.erro

            await getInteracoesWrapper()

        } catch (err: any) {
            showToast('error', `Não foi possível enviar a mensagem pelo seguinte motivo: ${err.message}`)
        }
    }, [carregandoPost, getInteracoesWrapper, id, interacoes, postIndicacoesInteracoes, reset, showToast, usuario?.Id])

    const saveChange = useCallback(async (model: IndicacoesInteracoesModel, status: EnumStatusIndicacao) => {
        try {
            const postModel = picker<IndicacoesInteracoesPostModel>(model, new IndicacoesInteracoesPostModel());

            postModel.tipoInteracao = status;
            postModel.descricao = '';
            postModel.responsavelId = usuario?.Id ?? '';
            postModel.id = guidEmpty();
            postModel.dataHora = toDateStringApi(new Date());

            const res = await postIndicacoesInteracoes(id, postModel)

            if (res.erro) throw res.erro

            await getInteracoesWrapper()

            showToast('success', 'Status Atualizado!')
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [usuario?.Id, postIndicacoesInteracoes, id, getInteracoesWrapper, showToast])

    const boxColorido = useCallback((cor: string) => (
        <Box borderRadius={theme.shape.borderRadius} width="20px" height="20px" marginRight={1} style={{ background: cor }} />
    ), [theme.shape.borderRadius])

    const menuOptions = useMemo(() => {
        const options: Array<MenuSaurusModel> = []
        const model = interacoes.filter(x => x.indicacaoId === id)[0];

        if (isEmpty(model)) {
            return options;
        }
        if (model.tipoInteracao < EnumStatusIndicacao.PropostaRecusada) {

            options.push(new MenuSaurusModel("Contato Realizado", boxColorido(retornarCorStatus(EnumStatusIndicacao.ContatoRealizado)), () => saveChange(model, EnumStatusIndicacao.ContatoRealizado)))

            options.push(new MenuSaurusModel("Visita Marcada", boxColorido(retornarCorStatus(EnumStatusIndicacao.VisitaMarcada)), () => saveChange(model, EnumStatusIndicacao.VisitaMarcada)))

            options.push(new MenuSaurusModel("Visita Realizada", boxColorido(retornarCorStatus(EnumStatusIndicacao.VisitaRealizada)), () => saveChange(model, EnumStatusIndicacao.VisitaRealizada)))

            options.push(new MenuSaurusModel("Proposta Enviada", boxColorido(retornarCorStatus(EnumStatusIndicacao.PropostaEnviada)), () => saveChange(model, EnumStatusIndicacao.PropostaEnviada)))
        }

        return options
    }, [boxColorido, id, interacoes, retornarCorStatus, saveChange])

    const onClose = () => {
        setOpenDialog(false);
    }

    const buttonTag = useMemo(() => {
        const interacaoAtual = interacoes.filter(x => x.indicacaoId === id)[0];

        return (
            <>
                {!isEmpty(interacaoAtual) && interacaoAtual.tipoInteracao <= EnumStatusIndicacao.PropostaRecusada && (
                    <MenuOptions
                        titulo="Tags"
                        descricao="Escolha uma das opções abaixo para realizar a modificação"
                        icon={<TagIcon tipo="BUTTON_EXPORT" fill={theme.palette.grey[500]} />}
                        options={menuOptions}
                        dark
                        iconButton
                        setaAvancar
                    />
                )}
            </>
        );
    }, [id, interacoes, menuOptions, theme.palette.grey]);

    useEffect(() => {
        getInteracoesWrapper()
    }, [getInteracoesWrapper])

    const switchDiaSemana = useCallback((dia: number) => {
        switch (dia) {
            case 0:
                return 'Domingo'
            case 1:
                return 'Segunda-Feira'
            case 2:
                return 'Terça-Feira'
            case 3:
                return 'Quarta-Feira'
            case 4:
                return 'Quinta-Feira'
            case 5:
                return 'Sexta-Feira'
            case 6:
                return 'Sabádo'
        }
    }, [])

    const validarDiasInteracao = useCallback((dataInteracao: string) => {

        var dataString = dataInteracao;
        var partesDaData = dataString.split('/');
        var dataFormatoAmericano = partesDaData[1] + '/' + partesDaData[0] + '/' + partesDaData[2];

        const dataAtual = new Date();
        const dataOntem = new Date();
        const dataLimite = new Date();
        const dataValidar = new Date(dataFormatoAmericano);

        const diaSemana = dataValidar.getDay();

        dataOntem.setDate(dataOntem.getDate() - 1);
        dataLimite.setDate(dataLimite.getDate() - 7);

        if (dataValidar.getFullYear() === dataAtual.getFullYear() && dataValidar.getMonth() === dataAtual.getMonth() &&
            dataValidar.getDate() === dataAtual.getDate()) {
            return 'Hoje';
        }
        else if (dataValidar.getFullYear() === dataOntem.getFullYear() && dataValidar.getMonth() === dataOntem.getMonth() &&
            dataValidar.getDate() === dataOntem.getDate()) {
            return 'Ontem';
        }
        else if (dataValidar >= dataLimite && dataValidar <= dataAtual) {
            return switchDiaSemana(diaSemana);
        }
        else {
            return `${switchDiaSemana(diaSemana)}, ${dataInteracao}`;
        }
    }, [switchDiaSemana])

    const formataArray = interacoes.map(x => {
        return {
            ...x,
            data: toDateString(x.dataHora) ?? ''
        }
    })

    const interacoesPorData = Array.from(new Set<string>(formataArray.map(x => x.data)))

    const mostrarFinalizar = (
        ([EnumTipoPessoas.SoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto].includes(tipoUsuario())) &&
        (status === 'em-validacao' || status === 'finalizadas')
    )

    const finalizada = status === 'finalizadas'
    const emValidacao = indStatus && indStatus === EnumStatusIndicacao.AguardandoValidacao;

    return (
        <>
            <Box height={"100%"} width="100%" display={"flex"}>
                <div className={modalClasses.content}>
                    <Box position={"relative"} width="100%">
                        <div className={classes.loading}>
                            <Slide unmountOnExit mountOnEnter in={carregandoGet} direction="down">
                                <div>
                                    <CircularLoading tipo="NORMAL" />
                                </div>
                            </Slide>
                        </div>
                    </Box>
                    <div className={classNames(modalClasses.contentForms)}>
                        <Box paddingTop={5}>
                            <Grid container>
                                {interacoesPorData.sort((a, b) => new Date(a).getTime() - new Date(b).getTime()).map((data) => {
                                    return (
                                        <>
                                            <Box width='100%' display='flex' justifyContent='center' style={{
                                                paddingBottom: '8px'
                                            }}>
                                                <div className={classes.containerBalao}>
                                                    <Card style={{
                                                        background: theme.palette.grey[200],
                                                    }}>
                                                        <Typography
                                                            variant="body2"
                                                            align="center"
                                                        >
                                                            <Box p={1} fontWeight={500}>
                                                                {validarDiasInteracao(data)}
                                                            </Box>
                                                        </Typography>
                                                    </Card>
                                                </div>
                                            </Box>
                                            {interacoes.filter(interacao => toDateString(interacao.dataHora) === data).sort((a, b) => new Date(a.dataHora).getTime() - new Date(b.dataHora).getTime()).map((interacao, index, array) => {
                                                return (
                                                    <InteracaoChat key={interacao.id} model={interacao} array={array} index={index} scrollRef={scrollRef} />
                                                )
                                            })}
                                        </>
                                    )
                                })}
                                <div ref={contentFormsRef}></div>
                            </Grid>
                        </Box>
                    </div>
                    {validarStatus() && (
                        <div style={{
                            flex: '0 0 auto'
                        }}>
                            <form onSubmit={handleSubmit(async (ev) => await enviarMensagem(ev))}>
                                <Grid container style={{
                                    padding: '8px',
                                    paddingBottom: '0px'
                                }}>
                                    <Grid item xs={10} lg={11}>
                                        <Controller
                                            control={control}
                                            name="descricao"
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    fullWidth
                                                    className={classes.chat}
                                                    placeholder="Envie uma mensagem"
                                                    {...field}
                                                    allowSubmit
                                                    tipo="TEXTO"
                                                    onClick={() => {
                                                        getInteracoesWrapper();
                                                    }}
                                                    autoComplete="off"
                                                    size="small"
                                                    endAdornmentButton={buttonTag}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={2} lg={1} style={{
                                        display: 'flex'
                                    }}>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            disableElevation
                                            className={classes.btnEnviar}
                                        >
                                            {
                                                carregandoPost
                                                    ? (
                                                        <Tooltip arrow title="Enviando mensagem">
                                                            <div>
                                                                <LoadingButton tipo="BRANCO" />
                                                            </div>
                                                        </Tooltip>
                                                    )
                                                    : <EnviarMensagemIcon tipo="BUTTON_PRIMARY" />
                                            }
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} style={{ paddingTop: '8px' }}>
                                        <Divider style={{ background: theme.palette.primary.main }} />
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    )}

                    <Box px={1} py={1}>
                        {mostrarFinalizar ? (
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="large"
                                onClick={() => setOpenDialogFinalizar(true)}
                            >
                                {finalizada ? <EditarIcon tipo='BUTTON_PRIMARY' /> : <OkIcon tipo="BUTTON_PRIMARY" />}
                                {finalizada ? 'Alterar' : emValidacao ? 'Avaliar' :'Finalizar Validação'}
                            </Button>
                        ) : (validarStatus() && (
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="large"
                                onClick={() => setOpenDialog(true)}
                            >
                                <OkIcon tipo="BUTTON_PRIMARY" />
                                Encerrar
                            </Button>
                        ))}
                    </Box>

                </div >
            </Box >

            {openDialog && (
                <DialogConclusaoIndicacao
                    open={openDialog}
                    setDialogConclusao={setOpenDialog}
                    id={id}
                    interacoes={interacoes}
                    onClose={onClose}
                    getInteracoesWrapper={getInteracoesWrapper}
                />
            )}

            {openDialogFinalizar && (
                <DialogFinalizarIndicacao
                    open={openDialogFinalizar}
                    id={id}
                    interacoes={interacoes}
                    onClose={() => setOpenDialogFinalizar(false)}
                    getInteracoesWrapper={getInteracoesWrapper}
                    validando={Boolean(indStatus && indStatus === EnumStatusIndicacao.AguardandoValidacao)}
                />
            )}
        </>
    )
}