import { useMemo, useState } from 'react';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { useParams } from 'react-router-dom';
import { GerentesHeader } from './components/gerentes-header/gerentes-header';
import { GerentesList } from './components/gerentes-list/gerentes-list';
import { GerenteEditar } from './components/gerente-editar';
import { RevendedorListSearchProps } from '../revendedor/components/revendedor-list/revendedor-list-search-props';

interface paramProps {
  parametro: string
}

export const GerentesPage = () => {
  const classes = useDefaultCadastroStyles();

  const [openPesquisa, setOpenPesquisa] = useState(false);
  const [searchProps, setSearchProps] = useState<RevendedorListSearchProps>({
    termo: '',
  });

  const { parametro } = useParams<paramProps>()

  const pageSwitch = useMemo(() => {
    setOpenPesquisa(false)
    switch (parametro) {
      case undefined:
        return <GerentesList searchProps={searchProps}/>
      default:
        return (
          <div className={classes.defaultContainer}>
            <GerenteEditar id={parametro}/>
          </div>
        )
    }
  }, [classes.defaultContainer, parametro, searchProps])

  return (
    <div className={classes.root}>
    <div className={classes.header}>
        <GerentesHeader
          titulo={parametro === undefined
            ? 'Gerentes Comerciais'
            : 'Editar Gerente Comercial'}
          openPesquisa={openPesquisa}
          setSearchProps={setSearchProps}
          setOpenPesquisa={setOpenPesquisa}
        />
      </div>
      {pageSwitch}
    </div>
  );
};

export default GerentesPage;
