import { Box, Button, Grid } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-filial-styles';
import { CircularLoading, useThemeQueries } from 'views';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { Paginacao } from 'views/components/paginacao';
import { FilialModel } from 'model/api/gestao/contrato/contrato-filial-model';
import { useGetFiliais } from 'data/api/gestao/filiais/get-filiais';
import { ContratoFormModel } from 'model/app/forms/contrato/contrato-form-model';
import { FilialList } from './components/filial-list/filial.list';
import { NovoIcon, ProcurarIcon, VoltarIcon } from 'views/components/icons';
import { DialogFormFilial } from './dialog-form-filial/dialog-form-filial';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { isEmpty } from 'lodash';
interface DialogFilialProps {
  openned: boolean;
  closeDialog: () => void;
  contratoModel: ContratoFormModel;
}

export const DialogFilial = ({
  openned,
  closeDialog,
  contratoModel
}: DialogFilialProps) => {
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const { showToast } = useToastSaurus()

  const { getFiliais, carregando } = useGetFiliais()

  const { tipoUsuario } = useSessaoAtual();

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<FilialModel>(),
  });

  const [query, setQuery] = useState<string>('')

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [edit, setEdit] = useState<boolean>(false)
  const [selectedModel, setSelectedModel] = useState<FilialModel>(new FilialModel())

  const isFuncionario = tipoUsuario() === EnumTipoPessoas.Funcionario || tipoUsuario() === EnumTipoPessoas.FuncionarioSoftwareHouse

  const fillResult = useCallback(
    (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<FilialModel>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    []
  );

  const search = useCallback(
    async (newPage: number) => {
      try {
        const res = await getFiliais(contratoModel.id, newPage, `pageSize=5${!isEmpty(query) ? `&cpfCnpj=${query}` : ''}`);
        if (res.erro) throw res.erro;

        if (!res.resultado) {
          return;
        }

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          await search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list
        );

        return res;
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [getFiliais, contratoModel.id, query, fillResult, showToast]
  );

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        await search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  const updateList = useCallback(() => {
    search(queryStatus.page);
  }, [queryStatus.page, search])

  const onClick = (model: FilialModel) => {
    if (isFuncionario) return

    setEdit(true)
    setSelectedModel(model)
    setOpenDialog(true)
  }

  useEffect(() => {
    if(openned)
      search(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openned])

  const searchTextfield = useMemo(() => {
    return (
      <Box mb={!isMobile ? 1 : undefined} component='form' onSubmit={(e: any) => {
        e.preventDefault();
        search(1)
      }}>
        <TextFieldSaurus
          size='small'
          tipo='CNPJ_CPF'
          placeholder='Pesquisar por CNPJ'
          value={query}
          onChange={(e: any) => setQuery(e.target.value)}
          endAdornmentButton={
            <Button
              color="primary"
              type="submit"
              className={classes.btnPesquisa}
              fullWidth={true}
            >
              <ProcurarIcon tipo="BUTTON" />
            </Button>
          }
        />
      </Box>
    )
  }, [classes.btnPesquisa, isMobile, query, search])

  return (
    <DialogSaurus
      aberto={openned}
      titulo="Empresas Vinculadas"
      tamanho="md"
      endAdornment={!isMobile ? searchTextfield : undefined}
    >
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <DialogFormFilial
            closeDialog={() => setOpenDialog(false)}
            openned={openDialog}
            model={selectedModel}
            edit={edit}
            updateList={updateList}
            contratoModel={contratoModel}
          />
          <Grid container>
            {isMobile && <Grid item xs={12}>
              {searchTextfield}
            </Grid>}
            <Grid item xs={12}>
              <Paginacao
                pageChanged={pageChanged}
                totalPages={queryStatus.totalPages}
                totalRegisters={queryStatus.totalResults}
                currentPage={queryStatus.page}
              />
            </Grid>
            <Grid item xs={12}>
              <FilialList
                list={queryStatus.list}
                carregando={carregando}
                updateList={updateList}
                onClick={onClick}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} style={{ margin: '12px 0 0', display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} md={3}>
                  <Button
                    fullWidth
                    onClick={closeDialog}
                    variant='outlined'
                    color='primary'

                  >
                    <VoltarIcon tipo='BUTTON' />
                    Voltar
                  </Button>
                </Grid>
                {tipoUsuario() !== EnumTipoPessoas.Funcionario && <Grid item xs={12} md={4} >
                  <Grid item xs={12}>
                    <Button variant="contained" color='primary' fullWidth
                      onClick={() => {
                        setEdit(false)
                        setSelectedModel(new FilialModel())
                        setOpenDialog(true)
                      }}>
                      <NovoIcon tipo='BUTTON_PRIMARY' /> Nova Empresa
                    </Button>
                  </Grid>
                </Grid>}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
