import { useState, useCallback, useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import { useStyles } from "../../../clientes/components/clientes-list/clientes-list-styles";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import { useCadastros, useToastSaurus } from "services/app";
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles";
import { useGetClienteIdeal } from "data/api/gestao/cliente-ideal/get-cliente-ideal";
import { ClienteIdealPerguntaModel } from "model/api/gestao/cliente-ideal/cliente-ideal-pergunta-model";
import { PerguntasApuracaoListData } from "./perguntas-apuracao-list-data";
import { usePutClienteIdeal } from "data/api/gestao/cliente-ideal/put-cliente-ideal";
import { ButtonFab } from "views/components/controles";
import { NovoIcon } from "views/components/icons";
import { useEventTools } from "services/app/use-cases/events/event-tools";
import { AppEventEnum } from "model/enums/enum-app-event";

export const PerguntasApuracaoList = () => {
    const classes = useStyles();
    const listClasses = useDefaultCadastroStyles();
    const { abrirCadastroPergunta, cadastroPerguntaState } = useCadastros();
    const { addHandler, callEvent, removeHandler } = useEventTools();

    const { getClienteIdeal, carregando } = useGetClienteIdeal();
    const { putClienteIdeal } = usePutClienteIdeal();

    const { showToast } = useToastSaurus();

    const [perguntas, setPerguntas] = useState<ClienteIdealPerguntaModel[]>([])


    const search = useCallback(
        async () => {
            try {
                const res = await getClienteIdeal();
                if (res.erro) throw res.erro;

                if (res.resultado?.data === undefined) {
                    return
                }

                setPerguntas(res.resultado.data.perguntas)
            } catch (e: any) {
                showToast("error", e.message);
            }
        },
        [getClienteIdeal, showToast]
    );

    const salvarPerguntas = async (model: ClienteIdealPerguntaModel) => {
        try {
            const copyArr = [...perguntas]
            const exists = copyArr.find(pergunta => pergunta.id === model.id);
            if (exists) {
                const index = copyArr.indexOf(exists);
                copyArr[index] = { ...model };
            } else {
                copyArr.push({ ...model })
            }

            const res = await putClienteIdeal(copyArr);

            if (res.erro) throw res.erro

            return true
        } catch (e: any) {
            showToast('error', e.message)
            return false
        }
    }

    useEffect(() => {
        if (!cadastroPerguntaState.aberto) {
            search();
        }
    }, [cadastroPerguntaState.aberto, search])

    const onClickCard = (model: ClienteIdealPerguntaModel) => {
        abrirCadastroPergunta(model, salvarPerguntas)
    }

    const novaPergunta = () => {
        abrirCadastroPergunta(
            new ClienteIdealPerguntaModel(),
            salvarPerguntas
        )
    }

    const enviarPerguntas = useCallback(() => {
        callEvent(AppEventEnum.ReceberPerguntas, perguntas)
    }, [callEvent, perguntas])

    useEffect(() => {
        addHandler(AppEventEnum.SolicitarPerguntas, enviarPerguntas)
        return () => {
            removeHandler(AppEventEnum.SolicitarPerguntas, enviarPerguntas)
        }
    }, [addHandler, enviarPerguntas, removeHandler])

    return (
        <>
            <div className={classes.defaultContainer}>
                {carregando && <CircularLoading tipo="FULLSIZED" />}
                <Box display='flex' flexDirection='column' height='100%' justifyContent='space-between' flex={1} overflow='auto' >
                    <Grid container className={listClasses.listContainer}>
                        <Grid item xs={12} className={classes.listContainer}>
                            <PerguntasApuracaoListData
                                carregando={carregando}
                                list={perguntas}
                                onClick={onClickCard}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <ButtonFab
                icon={<NovoIcon tipo='BUTTON_FAB' />}
                onClick={novaPergunta}
                tooltip="Nova Pergunta"
            />
        </>
    )
}