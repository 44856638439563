
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFiltroRelatorioValidation = () => {


  const FiltroRelatorioValidation = useMemo(() => {
    return Yup.object().shape({
        anoMesInicial: Yup.string().required('Ano/Mês Inicial é obrigatório.'),
        anoMesFinal: Yup.string().required('Ano/Mês Final é obrigatório.'),
    });
  }, [])

  return {
    FiltroRelatorioValidation,
  }
}

