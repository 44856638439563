import { yupResolver } from '@hookform/resolvers/yup';
import { SituacaoFilialMock } from 'data/mocks/situacao-filial-mock';
import { FilialFormModel } from 'model/app/forms/filial/filial-form-model';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSessaoAtual } from 'services/app';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { DefaultFormProps, DefaultFormRefs } from 'views/components/form/utils';
import { useFormFilialValidation } from './form-filial-validation';

export const FormContratoFilialEditar = forwardRef<
    DefaultFormRefs<FilialFormModel>,
    DefaultFormProps<FilialFormModel>
>((props, ref) => {


    const [model, setModel] = useState<FilialFormModel>(new FilialFormModel())
    const { formFilialEditValidationYup } = useFormFilialValidation()

    const { tipoUsuario } = useSessaoAtual()

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        reset,
        setValue,
        getValues
    } = useForm<FilialFormModel>({
        defaultValues: { ...model },
        resolver: yupResolver(formFilialEditValidationYup),
        criteriaMode: 'all',
        mode: 'onBlur' && 'onChange',
    });

    const submitEnviar = useCallback((filial: FilialFormModel) => {
        props.onSubmit(filial)
    }, [props])

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(submitEnviar)();
        },
        resetForm: () => {
            reset();
        },
        fillForm: (model: FilialFormModel) => {
            setModel(model)
            reset(model)
            setValue('contratoId', model.contratoId)
            setValue('status', model.status)
        },
    }));

    return (
        <form style={{ width: '100%' }} onSubmit={(e) => {
            e.preventDefault()
            handleSubmit(submitEnviar)();
        }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '7px' }}>
                <Controller
                    name="documento"
                    control={control}
                    render={({ field }) => (
                        <TextFieldSaurus
                            allowSubmit={false}
                            id="documento"
                            label="CPF/CNPJ da Empresa"
                            tipo='CNPJ_CPF'
                            disabled={tipoUsuario() !== EnumTipoPessoas.SoftwareHouse}
                            autoComplete={'off'}
                            helperText={
                                touchedFields.documento && errors.documento
                                    ? errors.documento.message
                                    : undefined
                            }
                            error={Boolean(errors.documento && errors.documento.message)}
                            {...field}
                        />
                    )}
                />

                <Controller
                    name='status'
                    control={control}
                    render={({ field }) => (
                        <SelectSaurus
                            id='status'
                            label='Situação'
                            conteudo={SituacaoFilialMock}
                            {...field}
                            value={getValues('status')}
                            onChange={(e) => {
                                const item = SituacaoFilialMock.find(x => x.Key === e.target.value)
                                if (item) {
                                    setValue('status', item.Key)
                                }
                            }}
                        />
                    )}
                />
            </div>
        </form>
    )
});
