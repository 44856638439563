import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        margin: 0,
        position: "relative",
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(2),
        background: theme.palette.common.white,
        [theme.breakpoints.up("md")]: {
            width: '350px',
            background: 'transparent',
        },
        '& .MuiInputBase-root': {
            background: '#fff'
        }
    },

    containerFinal: {
        display: "flex",
        justifyContent: "space-between",

    },
    btnPesquisa: {
        marginLeft: theme.spacing(1),
        width: "40px",
        minWidth: "40px",
        height: "40px",
        padding: 0,
        "& svg": {
            margin: 0,
        }
    }

}));
