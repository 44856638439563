import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { Box, Grid, Button, Typography, Divider, Tooltip, Card } from '@material-ui/core';
import { useFormTabelaPrecoValidation } from './form-tabela-preco-validation';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  TextFieldSaurus,

} from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { picker } from 'utils/picker';
import { TabelaPrecoModel } from 'model/api/gestao/tabela-preco/tabela-preco-model';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { useSessaoAtual } from 'services/app';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { CardNaoEncontrado } from 'views/components/cards';
import { ModuloIcon, SalvarEditarIcon, SATIcon } from 'views/components/icons';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { TabelaPrecoSistemaModel } from 'model/api/gestao/tabela-preco/tabela-preco-sistema-model';
import { EnumTipoControleModulo } from 'model/enums/enum-tipo-controle-modulo';
import { isEmpty } from 'lodash';

export const FormTabelaPrecoEdicao = forwardRef<
  DefaultFormRefs<TabelaPrecoModel>,
  DefaultFormProps<TabelaPrecoModel>
>(
  (
    { loading, onSubmit, ...props }: DefaultFormProps<TabelaPrecoModel>,
    ref,
  ) => {
    const { theme } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const { FormTabelaPrecoValidationYup } = useFormTabelaPrecoValidation()
    const [model, setModel] = useState<TabelaPrecoModel>(new TabelaPrecoModel())
    const { tipoUsuario } = useSessaoAtual()

    const isRevendedor = tipoUsuario() === EnumTipoPessoas.Revendedor
    const isFuncionarioFinanceiro = ([EnumTipoPessoas.FinanceiroSoftwareHouse, EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto].includes(tipoUsuario()))

    const calcularValorReal = useCallback((vPreco: number, vCompra: number) => {
      const pLucro = toDecimal(((vPreco - vCompra) / vCompra) * 100, 4);

      return toDecimal(isNaN(pLucro) ? 0 : pLucro, 4)
    }, []);

    const calcularValorFinal = useCallback((vCompra: number, pLucro: number) => {
      return toDecimal((vCompra * pLucro) / 100 + vCompra, 4);
    },
      [],
    );

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
      setValue,
      getValues,
      getFieldState
    } = useForm<TabelaPrecoModel>({
      defaultValues: { ...model },
      resolver: yupResolver(FormTabelaPrecoValidationYup),
      criteriaMode: 'all',
      mode: 'onBlur',
    });

    const {
      fields,
    } = useFieldArray({
      control: control,
      name: "sistemas",
    })

    const fieldsSerializado = fields.map((item, index) => {
      return {
        ...item,
        modulos: item.modulos.map((modulo, i) => {
          return {
            ...modulo,
            indexOriginal: i
          }
        }),
        indexOriginal: index
      }
    })

    const calcularValores = useCallback(
      (event: any, sistemaIndex: number, moduloIndex: number) => {
        setValue(event.target.name, event.target.value);

        if (isRevendedor) {
          const valor = toDecimal(
            event.target.name === `sistemas.${sistemaIndex}.modulos.${moduloIndex}.valor`
              ? event.target.value
              : toDecimalString(getValues(`sistemas.${sistemaIndex}.modulos.${moduloIndex}.valor`), 4), 4
          )

          const valorCusto = toDecimal(
            event.target.name === `sistemas.${sistemaIndex}.modulos.${moduloIndex}.valorCusto`
              ? event.target.value
              : toDecimalString(getValues(`sistemas.${sistemaIndex}.modulos.${moduloIndex}.valorCusto`), 4), 4
          )

          const margem = toDecimal(
            event.target.name === `sistemas.${sistemaIndex}.modulos.${moduloIndex}.margem`
              ? event.target.value
              : toDecimalString(getValues(`sistemas.${sistemaIndex}.modulos.${moduloIndex}.margem`), 4), 4
          )

          if (event.target.name === `sistemas.${sistemaIndex}.modulos.${moduloIndex}.margem`) {
            setValue(`sistemas.${sistemaIndex}.modulos.${moduloIndex}.valor`, calcularValorFinal(valorCusto, margem));

          } else {
            setValue(`sistemas.${sistemaIndex}.modulos.${moduloIndex}.margem`, calcularValorReal(valor, valorCusto));
          }
        }
      },
      [setValue, isRevendedor, getValues, calcularValorFinal, calcularValorReal],
    );


    const submit = (values: TabelaPrecoModel) => {
      const precificacao = picker<TabelaPrecoModel>(values, new TabelaPrecoModel())
      precificacao.sistemas = [...values.sistemas]
      onSubmit(precificacao, model);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submit)();
      },
      resetForm: () => {
        reset();
      },
      fillForm: (values: TabelaPrecoModel) => {
        values.sistemas = tratarValores(values.sistemas)
        setModel(values)
        reset({ ...values })
      },
    }));

    const tratarValores = useCallback((sistemas: TabelaPrecoSistemaModel[]) => {
      return sistemas.map(sistema => {
        sistema.modulos = sistema.modulos.map(modulo => {
          modulo.valor = !modulo.valor ? 0 : modulo.valor
          modulo.percAdicional = !modulo.percAdicional ? 0 : modulo.percAdicional
          return modulo;
        })
        return sistema;
      })
    }, [])

    const copiarValores = useCallback(() => {
      fieldsSerializado.forEach(field => {
        field.modulos.forEach(mod => {
          const percCusto = getValues(`sistemas.${field.indexOriginal}.modulos.${mod.indexOriginal}.percAdicionalCusto`)
          const valorCusto = getValues(`sistemas.${field.indexOriginal}.modulos.${mod.indexOriginal}.valorCusto`)

          setValue(`sistemas.${field.indexOriginal}.modulos.${mod.indexOriginal}.percAdicional`, toDecimal(percCusto, 4))
          setValue(`sistemas.${field.indexOriginal}.modulos.${mod.indexOriginal}.valor`, toDecimal(valorCusto, 4))
          setValue(`sistemas.${field.indexOriginal}.modulos.${mod.indexOriginal}.margem`, toDecimal(0, 4))
        })
      })
    }, [fieldsSerializado, getValues, setValue])

    return (
      <>
        {loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="FULLSIZED" />
          </div>
        ) : null}
        <div className={utilClasses.formContainer}>
          <form
            onSubmit={handleSubmit(submit)}
            className={loading ? utilClasses.controlLoading : ''}>
            <Box my={3}>
              <Grid container spacing={2} justifyContent="center" style={{ height: '100%' }}>
                <Card style={{ width: '100%' }}>
                  <div style={{
                    padding: '40px 30px 48px',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    maxHeight: '600px',
                  }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={!isEmpty(getValues('vendedorId')) ? 8 : 12}>
                        <Controller
                          name="nome"
                          control={control}
                          render={({ field }) => (
                            <TextFieldSaurus
                              disabled={loading}
                              allowSubmit={false}
                              label="Nome da Tabela"
                              fullWidth
                              autoComplete={'off'}
                              helperText={
                                touchedFields.nome && errors.nome
                                  ? errors.nome.message
                                  : undefined
                              }
                              error={Boolean(errors.nome && errors.nome.message)}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      {!isEmpty(getValues('vendedorId')) && (
                        <Grid item xs={12} md={4}>
                          <Controller
                            name="vendedorNome"
                            control={control}
                            render={({ field }) => (
                              <TextFieldSaurus
                                readOnly
                                allowSubmit={false}
                                label="Vendedor"
                                fullWidth
                                autoComplete={'off'}
                                helperText={
                                  touchedFields.vendedorNome && errors.vendedorNome
                                    ? errors.vendedorNome.message
                                    : undefined
                                }
                                error={Boolean(errors.vendedorNome && errors.vendedorNome.message)}
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      )}
                      {fieldsSerializado.map(sistema => {
                        return (
                          <>
                            <Grid item xs={12}>
                              <AccordionSaurus
                                labelPrimary={sistema.nome}
                              >
                                <Grid container spacing={2}>
                                  {sistema.modulos.length === 0 && (
                                    <Grid item xs={12}>
                                      <CardNaoEncontrado icon={<ModuloIcon tipo="GERAL" />} mensagem='Não há módulos' />
                                    </Grid>
                                  )}
                                  {sistema.modulos.length > 0 && sistema.modulos.map(modulo => {
                                    return (
                                      <Grid item xs={12}>
                                        <Box pl={1} pr={2}>
                                          <Grid container spacing={2}>
                                            <Grid item xs={12} style={{ display: 'flex' }} justifyContent='space-between' alignItems='center'>
                                              <Typography variant="h6" color="primary">
                                                {modulo.modulo.nome}
                                              </Typography>
                                              <div>
                                                <Typography variant='caption' color='secondary'>
                                                  <b>Cód: </b>
                                                </Typography>
                                                <Typography variant='caption' color='textSecondary'>
                                                  {modulo.modulo.codigo}
                                                </Typography>
                                              </div>
                                            </Grid>
                                            {(isRevendedor && modulo.modulo.tipoControle !== EnumTipoControleModulo.Filial) && (
                                              <>
                                                <Grid item xs={6} md={4}>
                                                  <Controller
                                                    name={`sistemas.${sistema.indexOriginal}.modulos.${modulo.indexOriginal}.valorCusto`}
                                                    control={control}
                                                    render={({ field }) => (
                                                      <TextFieldSaurus
                                                        label="Valor Custo"
                                                        tipo="DECIMAL"
                                                        casasDecimais={4}
                                                        limite={14}
                                                        readOnly
                                                        manterMascara
                                                        helperText={
                                                          getFieldState(`sistemas.${sistema.indexOriginal}.modulos.${modulo.indexOriginal}.valorCusto`).isTouched
                                                            && getFieldState(`sistemas.${sistema.indexOriginal}.modulos.${modulo.indexOriginal}.valorCusto`).error
                                                            ? getFieldState(`sistemas.${sistema.indexOriginal}.modulos.${modulo.indexOriginal}.valorCusto`).error?.message
                                                            : undefined
                                                        }
                                                        error={Boolean(
                                                          getFieldState(`sistemas.${sistema.indexOriginal}.modulos.${modulo.indexOriginal}.valorCusto`).error)}
                                                        {...field}
                                                      />
                                                    )}
                                                  />
                                                </Grid>
                                                <Grid item xs={6} md={4}>
                                                  <Controller
                                                    name={`sistemas.${sistema.indexOriginal}.modulos.${modulo.indexOriginal}.margem`}
                                                    control={control}
                                                    render={({ field }) => (
                                                      <TextFieldSaurus
                                                        label="Margem (%)"
                                                        tipo="DECIMAL"
                                                        casasDecimais={4}
                                                        max={14}
                                                        {...field}
                                                        manterMascara
                                                        limite={14}
                                                        onChange={ev => calcularValores(ev, sistema.indexOriginal, modulo.indexOriginal)}
                                                      />
                                                    )}
                                                  />
                                                </Grid>
                                              </>
                                            )}
                                            {modulo.modulo.tipoControle !== EnumTipoControleModulo.Filial && (
                                              <Grid item xs={6} md={isRevendedor ? 4 : 6}>
                                                <Controller
                                                  name={`sistemas.${sistema.indexOriginal}.modulos.${modulo.indexOriginal}.valor`}
                                                  control={control}
                                                  render={({ field }) => (
                                                    <TextFieldSaurus
                                                      label="Valor Venda"
                                                      readOnly={isFuncionarioFinanceiro}
                                                      positivo
                                                      tipo="DECIMAL"
                                                      showStartAdornment
                                                      casasDecimais={4}
                                                      manterMascara
                                                      limite={14}
                                                      {...field}
                                                      helperText={
                                                        getFieldState(`sistemas.${sistema.indexOriginal}.modulos.${modulo.indexOriginal}.valor`).isTouched
                                                          && getFieldState(`sistemas.${sistema.indexOriginal}.modulos.${modulo.indexOriginal}.valor`).error
                                                          ? getFieldState(`sistemas.${sistema.indexOriginal}.modulos.${modulo.indexOriginal}.valor`).error?.message
                                                          : undefined
                                                      }
                                                      error={Boolean(
                                                        getFieldState(`sistemas.${sistema.indexOriginal}.modulos.${modulo.indexOriginal}.valor`).error)}
                                                      onChange={ev => calcularValores(ev, sistema.indexOriginal, modulo.indexOriginal)}
                                                    />
                                                  )}
                                                />
                                              </Grid>
                                            )}
                                            {isRevendedor && (
                                              <Grid item xs={6}>
                                                <Controller
                                                  name={`sistemas.${sistema.indexOriginal}.modulos.${modulo.indexOriginal}.percAdicionalCusto`}
                                                  control={control}
                                                  render={({ field }) => (
                                                    <TextFieldSaurus
                                                      label="Adicional Custo (%)"
                                                      tipo="NUMERO"
                                                      casasDecimais={4}
                                                      limite={14}
                                                      helperText={
                                                        getFieldState(`sistemas.${sistema.indexOriginal}.modulos.${modulo.indexOriginal}.percAdicionalCusto`).isTouched
                                                          && getFieldState(`sistemas.${sistema.indexOriginal}.modulos.${modulo.indexOriginal}.percAdicionalCusto`).error
                                                          ? getFieldState(`sistemas.${sistema.indexOriginal}.modulos.${modulo.indexOriginal}.percAdicionalCusto`).error?.message
                                                          : undefined
                                                      }
                                                      error={Boolean(
                                                        getFieldState(`sistemas.${sistema.indexOriginal}.modulos.${modulo.indexOriginal}.percAdicionalCusto`).error)}
                                                      manterMascara
                                                      {...field}
                                                      readOnly
                                                    />
                                                  )}
                                                />
                                              </Grid>
                                            )}
                                            <Grid item xs={modulo.modulo.tipoControle === EnumTipoControleModulo.Filial && !isRevendedor ? 12 : 6}>
                                              <Controller
                                                name={`sistemas.${sistema.indexOriginal}.modulos.${modulo.indexOriginal}.percAdicional`}
                                                control={control}
                                                render={({ field }) => (
                                                  <TextFieldSaurus
                                                    label="Adicional (%)"
                                                    tipo="DECIMAL"
                                                    readOnly={isFuncionarioFinanceiro}
                                                    positivo
                                                    casasDecimais={4}
                                                    limite={14}
                                                    helperText={
                                                      getFieldState(`sistemas.${sistema.indexOriginal}.modulos.${modulo.indexOriginal}.percAdicional`).isTouched
                                                        && getFieldState(`sistemas.${sistema.indexOriginal}.modulos.${modulo.indexOriginal}.percAdicional`).error
                                                        ? getFieldState(`sistemas.${sistema.indexOriginal}.modulos.${modulo.indexOriginal}.percAdicional`).error?.message
                                                        : undefined
                                                    }
                                                    error={Boolean(
                                                      getFieldState(`sistemas.${sistema.indexOriginal}.modulos.${modulo.indexOriginal}.percAdicional`).error)}
                                                    manterMascara
                                                    {...field}
                                                  />
                                                )}
                                              />
                                            </Grid>

                                          </Grid>
                                          <Divider style={{
                                            backgroundColor: 'rgba(241, 241, 241, 1)',
                                            marginTop: 16,
                                            height: 2,
                                          }} />
                                        </Box>
                                      </Grid>
                                    )
                                  })}
                                </Grid>
                              </AccordionSaurus>
                            </Grid>
                          </>
                        )
                      })}
                    </Grid>
                  </div>
                  <div style={{
                    flex: "0 0 auto",
                    padding: theme.spacing(2)
                  }}>
                    <Grid container justifyContent='flex-end' spacing={2}>
                      {tipoUsuario() === EnumTipoPessoas.Revendedor && (
                        <Grid item xs={12} md={4} lg={3} sm={5}>
                          <Tooltip title='Transferir valor e percentual adicional de custo de todas os módulos para os valores de venda.'>
                            <Button variant='outlined' color='primary' fullWidth type='button' onClick={copiarValores}>
                              <SATIcon tipo='BUTTON' />
                              Replicar Custo
                            </Button>
                          </Tooltip>
                        </Grid>
                      )}
                      {!isFuncionarioFinanceiro && <Grid item xs={12} md={3} lg={2} sm={4}>
                        <Button variant='contained' color='primary' fullWidth type='submit'>
                          <SalvarEditarIcon tipo='BUTTON_PRIMARY' />
                          Salvar
                        </Button>
                      </Grid>}
                    </Grid>
                  </div>
                </Card>
              </Grid>
            </Box>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </>
    );
  },
);
