import * as Yup from 'yup';

export const useAvaliacaoRevendaValidation = () => {

    const FormAvalicacaoRevendaValidationYup = Yup.object().shape({
        avaliacaoResponsavel: Yup.number().required('A avaliação do responsavel é obrigatória.').typeError('A avaliação do responsavel é obrigatória.'),
        motivoAvaliacaoResponsavel: Yup.string().required('O motivo da avaliação do responsavel é obrigatória.').typeError('O motivo da avaliação do responsavel é obrigatória.')
    });

    return {
        FormAvalicacaoRevendaValidationYup,
    }
}
