import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardBackground: {
        background: '#F8F8F8',
        borderRadius: theme.shape.borderRadius,
        padding: 5,
    },
    editIcon: {
        borderLeft: '2px solid #E6E6E6',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 10px',
        margin: '10px 0',
    },
    container: {
        '& p': {
            fontSize: 13
        }
    }
}))