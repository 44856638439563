import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Grid, Button, Tooltip, Typography, Divider } from '@material-ui/core';
import { useFormGerenteCadastroValidation } from './form-gerente-validation';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
    TextFieldSaurus,

} from 'views/components/controles/inputs';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { picker } from 'utils/picker';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { PessoaPostModel } from 'model/api/gestao/pessoa/pessoa-post-model';
import { validarCPFCNPJ } from 'utils/cpfcnpj-validate';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { ProcurarIcon } from 'views/components/icons';
import { stringNumeros } from 'utils/string-numeros';
import { useStyles } from './form-gerente-edicao-styles'
import { SituacaoPessoaMock } from 'data/mocks/pessoa-situacao-mock';
import { EnumSituacaoPessoa } from 'model/enums/enum-situacao-pessoa';
import { AutocompleteClassificacoes } from 'views/components/controles/autocompletes/autocomplete-classificacao/autocomplete-classificacao';

interface FormPessoaEdicaoProps extends DefaultFormProps<PessoaPostModel> {
    consultarCnpj?: () => void;
    configuracoes?: boolean;
}

export const FormGerenteEdicao = forwardRef<
    DefaultFormRefs<PessoaPostModel>,
    FormPessoaEdicaoProps
>(
    (
        { loading, onSubmit, configuracoes, ...props }: FormPessoaEdicaoProps,
        ref,
    ) => {
        const { isMobile } = useThemeQueries();
        const utilClasses = makeUtilClasses();
        const classes = useStyles()
        const refInputNome = useRef<HTMLInputElement>(null);
        const { FormGerenteEdicaoValidationYup } = useFormGerenteCadastroValidation()
        const { showToast } = useToastSaurus()
        const { tipoUsuario } = useSessaoAtual()
        const { theme } = useThemeQueries()

        // const { getPessoa, carregando: carregandoGet } = useGetPessoa()

        const [model, setModel] = useState<PessoaPostModel>(new PessoaPostModel())
        // const [arrayRevenda, setArrayRevenda] = useState<KeyValueModel[]>([])

        // const getPessoaWrapper = useCallback(async () => {
        //   const query = `tipoPessoa=${EnumTipoPessoas.Revendedor}&pageSize=0`
        //   const res = await getPessoa(1, query)

        //   if (res.erro) throw res.erro

        //   const mock = res.resultado?.data.list.map(item => {
        //     return new KeyValueModel(item.id, item.nomeComercial)
        //   })

        //   setArrayRevenda(mock ?? [])
        // }, [getPessoa])

        // useEffect(() => {
        //   if (tipoFormPessoa === EnumTipoPessoas.Cliente && !tipoRevenda) {
        //     getPessoaWrapper()
        //   }
        // }, [getPessoaWrapper, tipoFormPessoa, tipoRevenda])

        const {
            handleSubmit,
            control,
            formState: { errors },
            reset,
            setValue,
            getValues,
        } = useForm<PessoaPostModel>({
            defaultValues: { ...model },
            resolver: yupResolver(FormGerenteEdicaoValidationYup),
            criteriaMode: 'all',
            mode: 'onBlur' && 'onChange',
        });
        const submit = (values: PessoaPostModel) => {
            const pessoa = picker<PessoaPostModel>(values, new PessoaPostModel())
            if (!validarCPFCNPJ(pessoa.cpfCnpj)) {
                return showToast('error', 'CPF/CNPJ Inválido')
            }
            pessoa.melhorDia = null
            onSubmit(pessoa, model);
        };

        const carregando = loading

        useImperativeHandle(ref, () => ({
            submitForm: () => {
                handleSubmit(submit)();
            },
            resetForm: () => {
                reset();
                if (!isMobile) refInputNome.current?.focus();
            },
            fillForm: (values: PessoaPostModel) => {
                setModel(values)
                reset({ ...values })
                if (!isMobile) refInputNome.current?.focus();
            },
        }));

        return (
            <>
                <Box my={2}>
                    <div className={utilClasses.formContainer} style={{
                        position: 'relative'
                    }}>
                        {carregando && props.showLoading ? (
                            <div className={utilClasses.controlLoading}>
                                <CircularLoading tipo="FULLSIZED" />
                            </div>
                        ) : null}

                        <form
                            onSubmit={handleSubmit(submit)}
                            className={carregando ? utilClasses.controlLoading : ''}
                            style={{ padding: !isMobile ? '10px' : '0' }}
                        >
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={12}>
                                    <div
                                        style={{
                                            display: 'flex',
                                        }}
                                    >
                                        <Controller
                                            name="cpfCnpj"
                                            control={control}
                                            render={({ field }) => (
                                                <Box pr={stringNumeros(getValues('cpfCnpj')).length === 14 ? 1 : 0} width="100%">
                                                    <TextFieldSaurus
                                                        disabled={(tipoUsuario() === EnumTipoPessoas.Revendedor) || loading}
                                                        allowSubmit={false}
                                                        tipo="CNPJ_CPF"
                                                        label="CPF/CNPJ"
                                                        fullWidth
                                                        autoComplete={'off'}
                                                        helperText={
                                                            errors.cpfCnpj
                                                                ? errors.cpfCnpj.message
                                                                : undefined
                                                        }
                                                        error={Boolean(errors.cpfCnpj && errors.cpfCnpj.message)}
                                                        {...field}
                                                    />
                                                </Box>
                                            )}
                                        />
                                        {stringNumeros(getValues('cpfCnpj')).length === 14 ? (
                                            <Tooltip title="Consultar Cnpj">
                                                <Button
                                                    variant="contained"
                                                    color='primary'
                                                    className={classes.buttonConsulta}
                                                    onClick={() => props.consultarCnpj && props.consultarCnpj()}
                                                >
                                                    <ProcurarIcon tipo="BUTTON_PRIMARY" />
                                                </Button>
                                            </Tooltip>
                                        ) : null}
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="nomeComercial"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                disabled={loading}
                                                allowSubmit={false}
                                                inputRef={refInputNome}
                                                label="Nome"
                                                fullWidth
                                                autoComplete={'off'}
                                                helperText={
                                                    errors.nomeComercial
                                                        ? errors.nomeComercial.message
                                                        : undefined
                                                }
                                                error={Boolean(errors.nomeComercial && errors.nomeComercial.message)}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                {!configuracoes && <Grid item xs={12}>
                                    <Controller
                                        name="status"
                                        control={control}
                                        render={({ field }) => (
                                            <SelectSaurus
                                                disabled={loading || getValues('status') === EnumSituacaoPessoa.Inadimplente}
                                                allowSubmit={false}
                                                label="Situação"
                                                fullWidth
                                                conteudo={SituacaoPessoaMock}
                                                autoComplete={'off'}
                                                helperText={
                                                    errors.status
                                                        ? errors.status.message
                                                        : undefined
                                                }
                                                error={Boolean(errors.status && errors.status.message)}
                                                {...field}
                                                onChange={ev => {
                                                    const item = SituacaoPessoaMock
                                                        .filter(item => ev.target.value === item.Key)
                                                    setValue('status', item[0].Key)
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>}
                                {!configuracoes && (
                                    <Grid item xs={12}>
                                        <Controller
                                            name="classificacaoId"
                                            control={control}
                                            render={({ field }) => (
                                                <AutocompleteClassificacoes
                                                    label='Classificação'
                                                    value={getValues('classificacaoId') || undefined}
                                                    defaultValue={model.classificacaoId || undefined}
                                                    defaultName={model.gerenteNome}
                                                    fetchOnLoad
                                                    onChange={(e: any) => {
                                                        setValue('classificacaoId', e.value.id)
                                                    }}
                                                    helperText={
                                                        errors.classificacaoId
                                                            ? errors.classificacaoId.message
                                                            : undefined
                                                    }
                                                    error={Boolean(errors.classificacaoId && errors.classificacaoId.message)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                )}
                                {!configuracoes ? <Grid item xs={12}>
                                    <Grid container spacing={2} className={classes.loginContainer}>
                                        <Grid item xs={12}>
                                            <Typography variant='h6' color='primary'>Login</Typography>
                                            <Divider style={{ background: theme.palette.divider, marginTop: 5 }} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="login"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextFieldSaurus
                                                        disabled={loading}
                                                        allowSubmit={false}
                                                        label="Usuário"
                                                        tipo="TEXTO"
                                                        fullWidth
                                                        autoComplete={'off'}
                                                        helperText={
                                                            errors.login
                                                                ? errors.login.message
                                                                : undefined
                                                        }
                                                        error={Boolean(errors.login && errors.login.message)}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="senha"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextFieldSaurus
                                                        disabled={loading}
                                                        allowSubmit={false}
                                                        label="Senha"
                                                        tipo='PASSWORD'
                                                        fullWidth
                                                        autoComplete={'off'}
                                                        helperText={
                                                            errors.senha
                                                                ? errors.senha.message
                                                                : undefined
                                                        }
                                                        error={Boolean(errors.senha && errors.senha.message)}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid> :
                                    <Grid item xs={12}>
                                        <Controller
                                            name="login"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={loading}
                                                    allowSubmit={false}
                                                    label="Usuário"
                                                    tipo="TEXTO"
                                                    fullWidth
                                                    autoComplete={'off'}
                                                    helperText={
                                                        errors.login
                                                            ? errors.login.message
                                                            : undefined
                                                    }
                                                    error={Boolean(errors.login && errors.login.message)}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>}
                            </Grid>
                            <Button style={{ display: 'none' }} type="submit"></Button>
                        </form>
                    </div>
                </Box>
            </>
        );
    },
);