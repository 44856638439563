import { guidEmpty } from "utils/guid-empty";
import { PagamentoModel } from "../pagamento/pagamento-model";
import { PessoaModel } from "../pessoa/pessoa-model";
import { FaturaFilhaModel } from "./faturas-filhas-model";
import { FaturaHistoricoModel } from "./fatura-historico-model";
import { SistemaModel } from "../sistemas/sistema-model";

export class FaturaModel {
    constructor(
        public id: string = guidEmpty(),
        public contratoId: string = guidEmpty(),
        public faturaPaiId: string = guidEmpty(),
        public nossoNumero: number = 0,
        public situacao: number = 0,
        public tipoFatura: number = 0,
        public dataEmissao: string = '',
        public dataVencimento: string = '',
        public dataRegistro: string = '',
        public dataPagamento: string = '',
        public dataSincronizacaoERP: string = '',
        public dhExpiracaoPix: string = '',
        public competenciaAnoMes: number = 0,
        public dataCancelamento: string = '',
        public descricao: string = '',
        public encargo: number = 0,
        public multaJuros: number = 0,
        public valor: number = 0,
        public valorPago: number = 0,
        public boletoNumero: number = 0,
        public boletoUrl: string = '',
        public boletoCodigo: string = '',
        public pagamentoUrl: string = '',
        public pixCodigo: string = '',
        public formaPagamento: PagamentoModel = new PagamentoModel(),
        public pagador: PessoaModel = new PessoaModel(),
        public beneficiario: PessoaModel = new PessoaModel(),
        public faturasFilhas: FaturaFilhaModel[] = [],
        public formasPagamentosDisponiveis: PagamentoModel[] = [],
        public historico: FaturaHistoricoModel[] = [],
        public sistema: SistemaModel = new SistemaModel()
    ) { }
}