import { guidEmpty } from "utils/guid-empty";

export class ModuloPlanoModel {
  constructor(
    public id: string = guidEmpty(),
    public moduloId: string = guidEmpty(),
    public codModulo: number = 0,
    public nomeModulo: string = '',
    public quantidadeContratada: number = 0,
    public quantidadeMinima: number = 0,
    public quantidadeMaxima: number = 0,
    public quantidadePadrao: number = 0,
  ) { }
}
