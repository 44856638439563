import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { FaturaModel } from 'model/api/gestao/fatura/fatura-model';

export function usePostFaturaEmAbertoGerar() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<FaturaModel>();

    const postFaturaEmAbertoGerar = useCallback(
        (revendaId: string, anoMesFinal: number) =>
            invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/fatura/revenda/${revendaId}/em-aberto/${anoMesFinal}/faturar`,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postFaturaEmAbertoGerar,
    };
}