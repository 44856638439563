import { VariaveisAmbiente } from 'config';
import { PessoasModel } from 'model/api/gestao/pessoa/pessoas-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetPessoaRevendasIndicas() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<PessoasModel[]>();

    const getPessoaRevendasIndicas = useCallback(
        async () => {
            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Pessoa/revendas-indicadas`,
                method: 'GET',
                enviarTokenUsuario: true
            })
        }
        , [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getPessoaRevendasIndicas,
    };
}
