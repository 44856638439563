
import { EnumTipoComunicacao } from 'model';
import { EnumInstituicao } from 'model/enums/enum-instituicao';
import { useCallback, useMemo } from 'react';
import * as Yup from 'yup';

interface FormPagamentoValidationProps {
  instituicao: number;
  tipo: number;
}

export const useFormPagamentoValidation = ({ instituicao, tipo }: FormPagamentoValidationProps) => {

  const credencialItauBoleto = Yup.object().shape({
    conta: Yup.object().shape({
      agencia: Yup.string().required('Agência é obrigatório.').typeError('Agência é obrigatório.'),
      conta: Yup.string().required('Conta é obrigatório').typeError('Campo Obrigatório.'),
      digitoConta: Yup.string().required('Digito da conta é obrigatório').typeError('Campo Obrigatório.'),
      digitoAgencia: Yup.string().required('Digito da agência é obrigatório.').typeError('Campo Obrigatório.'),
      cpfCnpj: Yup.string().required('CPF/CNPJ é obrigatório.').typeError('Campo Obrigatório.'),
      nome: Yup.string().required('Razão Social é obrigatório.').typeError('Campo Obrigatório.'),
    }),
    certificado: Yup.object().shape({
      conteudo: Yup.string().required('Certificado é obrigatório.').typeError('Campo Obrigatório.'),
      senha: Yup.string().required('Segredo do certificado é obrigatório.').typeError('Campo Obrigatório.'),
    }),
    credenciais: Yup.object().shape({
      id: Yup.string().required('ID do cliente é obrigatório.').typeError('Campo Obrigatório.'),
      clientSecret: Yup.string().required('Segredo do cliente é obrigatório.').typeError('Campo Obrigatório.'),
    }),
    ambiente: Yup.number().required('Ambiente é obrigatório').typeError('Ambiente é obrigatório').typeError('Campo Obrigatório.'),
  })

  const credencialItauPix = Yup.object().shape({
    chave: Yup.string().required('Chave PIX é obrigatório.').typeError('Campo Obrigatório.'),
  })

  const credencialBancoDoBrasilBoleto = Yup.object().shape({
    ambiente: Yup.number().required('Ambiente é obrigatório').typeError('Ambiente é obrigatório').typeError('Campo Obrigatório.'),
    conta: Yup.object().shape({
      agencia: Yup.string().required('Agência é obrigatório.').typeError('Agência é obrigatório.'),
      conta: Yup.string().required('Conta é obrigatório').typeError('Campo Obrigatório.'),
      digitoConta: Yup.string().required('Digito da conta é obrigatório').typeError('Campo Obrigatório.'),
      digitoAgencia: Yup.string().required('Digito da agência é obrigatório.').typeError('Campo Obrigatório.'),
      cpfCnpj: Yup.string().required('CPF/CNPJ é obrigatório.').typeError('Campo Obrigatório.'),
      nome: Yup.string().required('Razão Social é obrigatório.').typeError('Campo Obrigatório.'),
    }),
    convenio: Yup.string().required('Convênio é obrigatório').typeError('Convênio é obrigatório'),
    carteira: Yup.string().required('Carteira é obrigatória').typeError('Carteira é obrigatória'),
    basic: Yup.string().required('Campo é obrigatório').typeError('Campo é obrigatório'),
    variacao: Yup.string().required('Campo é obrigatório').typeError('Campo é obrigatório'),
    appKey: Yup.string().required('Campo é obrigatório').typeError('Campo é obrigatório')
  })

  const validarCredencial = useCallback<any>(() => {
    if (instituicao === EnumInstituicao.Itau) {
      if (tipo === EnumTipoComunicacao.Boleto) {
        return credencialItauBoleto
      }
      if (tipo === EnumTipoComunicacao.Pix) {
        return credencialItauPix
      }
      return Yup.object().shape({}).nullable(true)
    } else if (instituicao === EnumInstituicao.BANCO_DO_BRASIL) {
      if (tipo === EnumTipoComunicacao.Boleto) {
        return credencialBancoDoBrasilBoleto
      }
    }
    return Yup.object().shape({}).nullable(true)
  }, [credencialBancoDoBrasilBoleto, credencialItauBoleto, credencialItauPix, instituicao, tipo])


  const FormPagamentoValidationYup = useMemo(() => {
    return Yup.object().shape({
      nome: Yup.string().required('O nome é obrigatório'),
      tipoComunicacao: Yup.number().required('Selecione um deles.'),
      instituicao: Yup.number(),
      credenciais: validarCredencial()
    });
  }, [validarCredencial])

  return {
    FormPagamentoValidationYup,
  }
}

