
import { useMemo } from 'react';
import * as Yup from 'yup';


export const useFormNovoSistemaValidation = () => {


  const FormNovoSistemaValidationYup = useMemo(() => {
    return Yup.object().shape({
      nome: Yup.string().required('Nome é obrigatório'),
      descricao: Yup.string().required('Descrição é obrigatório')
    });
  }, [])

  return {
    FormNovoSistemaValidationYup,
  }
}
