import { Box, Button, Grid } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-comissao-styles';
import { useThemeQueries } from 'views';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DefaultFormRefs } from 'views/components/form/utils';
import { EditarIcon, LixoIcon, VoltarIcon } from 'views/components/icons';
import { useCadastros, useSessaoAtual, useToastSaurus } from 'services/app';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { useGetComissaoById } from 'data/api/gestao/comissao/get-comissao-by-id';
import { ComissaoPutModel } from 'model/api/gestao/comissao/comissao-put-model';
import { ComissaoPaiModel } from 'model/api/gestao/comissao/comissao-pai-model';
import { picker } from 'utils/picker';
import { usePutComissao } from 'data/api/gestao/comissao/put-comissao';
import { isEmpty } from 'lodash';
import { FormComissaoEditar } from 'views/components/form/master/comissao/comissao-editar/form-comissao-editar';
import { useHistory } from 'react-router-dom';
import { useDeleteComissao } from 'data/api/gestao/comissao/delete-excluir-comissao';
import { useConfirm } from 'material-ui-confirm';
import { EnumTipoFatura } from 'model/enums/enum-tipo-fatura';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { ComissaoPendenteModel } from "model/api/gestao/comissao/comissao-pendente-model";
import { Paginacao } from 'views/components/paginacao';
import { useGetComissaoByIdExportarExcel } from 'data/api/gestao/comissao/get-comissao-by-id-exportar-excel';
import { FaturaComissoesPagasListData } from './components/fatura-comissoes-pagas-list-data';


interface DialogComissaoProps {
    openned: boolean;
    id: string;
}

interface FaturasList {
    list: ComissaoPendenteModel[];
    totalResults: number;
    page: number;

}

export const DialogComissao = ({
    openned,
    id,
}: DialogComissaoProps) => {
    const classes = useStyles()
    const modalClasses = useModalStyles();
    const { tipoUsuario } = useSessaoAtual()
    const { isMobile } = useThemeQueries();
    const { fecharEditarComissao } = useCadastros();
    const { showToast } = useToastSaurus();
    const { putComissao, carregando } = usePutComissao();
    const { getComissaoById, carregando: carregandoGet } = useGetComissaoById()
    const { deleteComissao, carregando: carregandoDelete } = useDeleteComissao();
    const { getComissaoByIdExportarExcel } = useGetComissaoByIdExportarExcel()
    const history = useHistory()
    const confirm = useConfirm()
    const loading = carregando || carregandoGet || carregandoDelete
    const [comissao, setComissao] = useState<ComissaoPaiModel>(new ComissaoPaiModel())

    const [results, setResults] = useState<FaturasList>({
        list: [],
        totalResults: 0,
        page: 1
    })

    const closeDialog = useCallback(() => {
        fecharEditarComissao()
    }, [fecharEditarComissao])

    const formRef = useRef<DefaultFormRefs<ComissaoPutModel>>(null)

    const recarregarForm = useCallback((modelForm: ComissaoPutModel) => {
        formRef.current?.fillForm(modelForm)
    }, [])


    const comissaoWrapper = useCallback(async (page: number) => {
        try {
            const res = await getComissaoById(id);

            if (res.erro) throw res.erro
            if (!res.resultado) return
            setComissao(res.resultado.data)

            setResults({
                list: res.resultado.data.faturasComissoes.slice((page - 1) * 10, ((page + 1) * 10 + 50)),
                page: page,
                totalResults: res.resultado.data.faturasComissoes.length
            })
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getComissaoById, id, showToast])

    useEffect(() => {
        const comissaoPut = picker<ComissaoPutModel>(comissao, new ComissaoPutModel())
        recarregarForm(comissaoPut);
    }, [comissao, recarregarForm])

    const handleSubmit = useCallback(async (model: ComissaoPutModel) => {
        try {
            const res = await putComissao(model)

            if (res.erro) throw res.erro

            showToast('success', 'Operação realizada com sucesso.')

            closeDialog()
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [closeDialog, putComissao, showToast])

    useEffect(() => {
        if (openned)
            comissaoWrapper(1);
    }, [comissaoWrapper, openned])

    const handleDelete = useCallback(async () => {
        try {
            const res = await deleteComissao(id)

            if (res.erro) throw res.erro

            showToast('success', 'Comissão excluída.')
            closeDialog()
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [closeDialog, deleteComissao, id, showToast])

    const confirmDelete = useCallback(() => {
        confirm({
            title: 'Confirmação',
            description: 'Tem certeza que deseja excluir esta comissão?',
            confirmationText: 'Confirmar',
            cancellationText: 'Cancelar'
        }).then(() => handleDelete())
    }, [confirm, handleDelete])

    const redirecionar = useCallback((id: string, tipo?: EnumTipoFatura) => {
        fecharEditarComissao()
        if (tipo === EnumTipoFatura.Cliente) {
            history.push({
                pathname: `/faturas/${id}`,
                state: {
                    from: {
                        pathname: history.location.pathname + '/' + comissao.id,
                    }
                }
            })
        } else {
            history.push({
                pathname: `/revenda/notas-fiscais/${id}`,
                state: {
                    from: {
                        pathname: '/comissoes/realizadas/' + comissao.id,
                    }
                }
            })
        }
    }, [comissao.id, fecharEditarComissao, history])

    const pageChanged = useCallback((page: number) => {
        setResults({
            list: comissao.faturasComissoes.slice((page - 1) * 10, ((page + 1) * 10 + 50)),
            page: page,
            totalResults: comissao.faturasComissoes.length
        })
    }, [comissao.faturasComissoes])


    const exportExcel = useCallback(async () => {
        try {

            const res = await getComissaoByIdExportarExcel(id)
            if (res.erro) throw (res.erro)
            if (!res.resultado) return;

            const blob = new Blob([res.resultado?.data], { type: 'application/vnd.ms-excel' },);

            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a')

            anchor.href = url
            anchor.download = `Faturas-${new Date().toLocaleDateString().replaceAll('/', '-')}.xls`
            anchor.click()

        } catch (e: any) {
            showToast('error', e.message);
        }
    }, [getComissaoByIdExportarExcel, id, showToast])

    const isFuncionarioFinanceiro = tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouse || tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto;
    const isSoftwareHouse = tipoUsuario() === EnumTipoPessoas.SoftwareHouse;

    const podeExcluir = isFuncionarioFinanceiro || isSoftwareHouse;
    const podeEditar = isSoftwareHouse;

    return (
        <DialogSaurus
            aberto={openned}
            titulo={tipoUsuario() === EnumTipoPessoas.SoftwareHouse ? 'Editar Comissão' : 'Comissão'}
            tamanho="lg"
            fullScreen={isMobile}
            zIndex='1298'
            bottomArea={
                <Box className={modalClasses.acoes}>
                    <Grid container spacing={2} style={{ paddingBottom: '7px', borderTop: '2px solid #E6E6E6' }} justifyContent='flex-end'>
                        {podeExcluir && <Grid item xs={12} md={isFuncionarioFinanceiro ? 10 : 8}>
                            <Button onClick={confirmDelete} fullWidth={isMobile} variant='outlined' className={classes.buttonRemover}>
                                <LixoIcon tipo='BUTTON' />
                                Excluir
                            </Button>
                        </Grid>}
                        <Grid item xs={12} md={2}>
                            <Button
                                disabled={loading}
                                variant="outlined"
                                fullWidth
                                color="primary"
                                onClick={() => {
                                    closeDialog()
                                }}
                            >
                                <VoltarIcon tipo='BUTTON' />
                                Voltar
                            </Button>
                        </Grid>
                        {podeEditar && <Grid item xs={12} md={2}>
                            <Button
                                disabled={loading}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={formRef.current?.submitForm}
                            >
                                <EditarIcon tipo='BUTTON_PRIMARY' />
                                Editar Comissão
                            </Button>
                        </Grid>}
                    </Grid>
                </Box>
            }
        >
            <Box className={classes.cardContainer}>
                <Box className={classes.cardContent}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid item xs={12}>
                                <FormComissaoEditar
                                    aberto={openned}
                                    loading={loading}
                                    onSubmit={handleSubmit}
                                    showLoading={loading}
                                    ref={formRef}
                                    block={tipoUsuario() !== EnumTipoPessoas.SoftwareHouse}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.containerPag}>
                                <Paginacao
                                    currentPage={results.page}
                                    totalPages={Math.ceil(results.totalResults / 50)}
                                    totalRegisters={results.totalResults}
                                    pageChanged={pageChanged}
                                    exportarExcel={() => exportExcel()}
                                />

                            </Grid>
                            <Grid item xs={12} className={classes.containerList} >
                                <FaturaComissoesPagasListData
                                    carregando={loading}
                                    list={isEmpty(comissao.faturasComissoes) ? [] : results.list.map(faturaComissao => faturaComissao.fatura)}
                                    onCardChecked={() => { }}
                                    onCardSelected={redirecionar}
                                    selectedList={[]}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </DialogSaurus>
    );
};
