import { useGetTabelaPrecoById, usePutAtualizarTabelaPreco } from 'data/api/gestao/tabela-preco';
import { isEmpty, isEqual } from 'lodash';
import { TabelaPrecoModel } from 'model/api/gestao/tabela-preco/tabela-preco-model';
import { TabelaPrecoFormModel } from 'model/app/forms/tabela-preco/tabela-preco-form-model';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useToastSaurus } from 'services/app';
import { guidEmpty } from 'utils/guid-empty';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { FormTabelaPrecoEdicao } from 'views/components/form/master/tabela-preco/tabela-preco-edicao/form-tabela-preco-edicao';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useStyles } from './precificacao-edit-styles'

type Props = {
    id: string;
}

function PrecificacaoEdit({ id }: Props) {

    const { putAtualizarTabelaPreco, carregando: carregandoPut } = usePutAtualizarTabelaPreco();
    const { getTabelaPrecoById, carregando: carregandoGet } = useGetTabelaPrecoById()

    const [preenchendoTela, setPreechendoTela] = useState<boolean>(false)

    const carregando = carregandoGet || carregandoPut || preenchendoTela

    const { showToast } = useToastSaurus();

    const classes = useStyles()

    const refEditForm =
        useRef<DefaultFormRefs<TabelaPrecoModel>>(null);
    const [precificacaoForm, setPreceficacaoForm] = useState<TabelaPrecoModel>(new TabelaPrecoModel())
    const refTabelaPrecoModel = useRef<TabelaPrecoModel>(new TabelaPrecoModel())

    const recarregarForm = useCallback((model: TabelaPrecoModel) => {
        refEditForm.current?.fillForm(model);
    }, []);

    useEffect(() => {
        recarregarForm(precificacaoForm)
    }, [precificacaoForm, recarregarForm])

    const getPrecificacaoByIdWrapper = useCallback(async () => {
        const res = await getTabelaPrecoById(id)

        if (res.erro) throw res.erro

        const ret = res.resultado?.data ?? new TabelaPrecoModel()
        ret.sistemas.map(item => item.modulos.map(modulo => {
            return modulo.margem = toDecimal(toDecimalString((modulo.valor / modulo.valorCusto) * 100 - 100, 4), 4)
        }))
        refTabelaPrecoModel.current = ret

        return ret
    }, [getTabelaPrecoById, id])

    useEffect(() => {
        (async () => {
            try {
                setPreechendoTela(true)
                const tabelaPreco = await getPrecificacaoByIdWrapper()

                setPreceficacaoForm(tabelaPreco)
            } catch (err: any) {
                showToast('error', err.message)
            } finally {
                setPreechendoTela(false)
            }
        })()
    }, [getPrecificacaoByIdWrapper, showToast])

    const savePrecificacao = useCallback(
        async (model: TabelaPrecoModel) => {
            const tabela: TabelaPrecoFormModel = {
                ...model,
                sistemas: model.sistemas.map(item => {
                    return {
                        sistemaId: item.sistemaId,
                        modulos: item.modulos.map(modulo => {
                            return {
                                id: isEmpty(modulo.id) ? guidEmpty() : modulo.id,
                                valor: modulo.valor ? toDecimal(modulo.valor, 4) : 0,
                                percAdicional: modulo.percAdicional ? toDecimal(modulo.percAdicional, 4) : 0,
                                tabelaPrecoId: id,
                                moduloId: modulo.modulo.id
                            }
                        })
                    }
                })
            }

            const ret = await putAtualizarTabelaPreco(tabela);
            if (ret.erro) {
                throw ret.erro;
            }
        }, [id, putAtualizarTabelaPreco]
    );
    const handleSubmit = useCallback(
        async (model: TabelaPrecoModel, beforeModel: TabelaPrecoModel) => {
            try {

                const precificacaoEqual = isEqual(model, beforeModel)

                if (precificacaoEqual) {
                    showToast('info', "Nenhuma informação foi alterada.")
                    return
                }

                await savePrecificacao(model);

                showToast('success', "Tabela de Preço Editada!")
            } catch (e: any) {
                showToast('error', e.message);
            }
        },
        [savePrecificacao, showToast],
    );

    return (
        <>
            <div className={classes.container}>
                <FormTabelaPrecoEdicao
                    loading={carregando}
                    onSubmit={handleSubmit}
                    showLoading={carregando}
                    ref={refEditForm}
                />
            </div>
        </>
    )
}

export default PrecificacaoEdit