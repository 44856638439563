import { Box, Button, Grid } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useGetPessoaMapa } from 'data/api/gestao/pessoa-mapa/get-pessoa-mapa';
import { PessoasMapaModel } from 'model/api/gestao/pessoa/pessoa-mapa-model';
import { GestaoStorageKeys, useGestaoStorage, useSessaoAtual, useToastSaurus } from 'services/app';
import { useStyles } from './mapa-cliente.styles';
import { useGetSistemas } from 'data/api/gestao/sistemas';
import { guidEmpty } from 'utils/guid-empty';
import { FiltroMapaModel } from 'model/app/forms/filtro-mapa/filtro-mapa-model';
import { KeyValueModel } from 'model';
import { VoltarIcon } from 'views/components/icons';
import { MapaClienteHeader } from './components/mapa-cliente-header/mapa-cliente-header';
import { CircularLoading } from 'views/components';
import { VariaveisAmbiente } from 'config';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import MapMarker from './components/mapa-info/mapa-info';
import { useGetPessoaInfo } from 'data/api/gestao/pessoa-info';
import { isEmpty } from 'lodash';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { LicenciamentoDBPrimary } from 'database/licenciamento-database';
import { EnumTipoBuscaMapa } from 'model/enums/enum-tipo-busca-mapa';


export default function MapaClientePage() {
  const { showToast } = useToastSaurus();
  const { tipoUsuario, usuario } = useSessaoAtual();
  const {setRegistro, getRegistro} = useGestaoStorage();
  const { getPessoaMapa, carregando: carregandoMapa } = useGetPessoaMapa();
  const { getSistemas, carregando: carregandoSistema } = useGetSistemas();
  const { getPessoaInfo, carregando: carregandoInfo } = useGetPessoaInfo();
  const classes = useStyles();

  const [pessoas, setPessoas] = useState<PessoasMapaModel[]>([]);
  const [sistemas, setSistemas] = useState<KeyValueModel[]>([]);
  const [showFilter, setShowFilter] = useState<boolean>(true);
  const [isRevendedor, setIsRevendedor] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [center, setCenter] = useState({
    lat: -23.540222250473388,
    lng: -46.57867425660036,
  })

  //Pegar a localização da revenda para posicionar o mapa em cima do local dela
  const getRevendaInfo = useCallback(async (revendaId: string) => {
    try {
      const res = await getPessoaInfo(revendaId, 0, '')

      if (res.erro) throw res.erro;
      if (!res.resultado) throw new Error();

      const info = res.resultado.data.list.find(info => info.isComercial)

      if (isEmpty(info)) {
        return
      }

      if (info.latitude === 0 || info.longitude === 0) {
        return
      }

      setCenter({
        lat: info.latitude,
        lng: info.longitude
      })

    } catch (e: any) {
      setCenter({
        lat: -23.540222250473388,
        lng: -46.57867425660036,
      })
    }
  }, [getPessoaInfo])

  const carregando = carregandoInfo || carregandoMapa || carregandoSistema || loading

  const getPessoasMapaWrapper = useCallback(
    async (filtro: FiltroMapaModel) => {

      try {
        const queryTipoBusca = `tipoBuscaMapa=${filtro.tipoBuscaMapa}`;

        setIsRevendedor(filtro.tipoBuscaMapa === 1 ? true : false)

        const querySistema =
          filtro.sistemaId && filtro.sistemaId !== guidEmpty()
            ? `&sistemaId=${filtro.sistemaId}`
            : '';
        const queryDataInicial =
          filtro.dataAdesaoInicial && filtro.dataAdesaoInicial !== ''
            ? `${(querySistema.length > 0 || queryTipoBusca.length > 0) ? '&' : ''}dataAdesaoInicial=${filtro.dataAdesaoInicial
            }`
            : '';
        const queryDataFinal =
          filtro.dataAdesaoFinal && filtro.dataAdesaoFinal !== ''
            ? `${(querySistema.length > 0 || queryDataInicial.length > 0 || queryTipoBusca.length > 0) ? '&' : ''
            }dataAdesaoFinal=${filtro.dataAdesaoFinal}`
            : '';

        const res = await getPessoaMapa(
          `${queryTipoBusca}${querySistema}${queryDataInicial}${queryDataFinal}`,
        );

        if (res.erro) throw res.erro;
        if (!res.resultado) return [];

        return res.resultado?.data
      } catch (e: any) {
        showToast('error', e.message)
        return []
      }
    },
    [getPessoaMapa, showToast],
  );




  const getSistemasWrapper = useCallback(async () => {
    const res = await getSistemas(1, '&pageSize=0&situacao=0');

    if (res.erro) throw res.erro;

    return (
      res.resultado?.data.list.map(
        (item) => new KeyValueModel(item.id, item.nome),
      ) || []
    );
  }, [getSistemas]);

  const search = useCallback(
    async (filtro: FiltroMapaModel) => {
      setLoading(true)

      const filtrosVazios = isEmpty(filtro.dataAdesaoFinal) && isEmpty(filtro.dataAdesaoInicial) && filtro.sistemaId === guidEmpty();
      const apenasFiltroDeSistema = isEmpty(filtro.dataAdesaoFinal) && isEmpty(filtro.dataAdesaoInicial) && filtro.sistemaId !== guidEmpty();

      let res: PessoasMapaModel[] = []

      const isAllowed = tipoUsuario() === EnumTipoPessoas.SoftwareHouse || tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouse || tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto || tipoUsuario() === EnumTipoPessoas.GerenteComercial
      const revendaId = tipoUsuario() === EnumTipoPessoas.Revendedor ? usuario?.Id : usuario?.RevendaId

      //ESSA VALIDAÇÃO SERVE PARA TER CERTEZA QUE UM USUÁRIO QUE TROCA DE CONTA NÃO TENHA ACESSO AOS RELATÓRIOS DO OUTRO ACESSO
      const isRegistroAtualizado = getRegistro(GestaoStorageKeys.RelatorioUsuario, false).usuarioId === usuario?.Id

      if (filtro.tipoBuscaMapa === EnumTipoBuscaMapa.Revendedores && filtrosVazios) {
        res = await LicenciamentoDBPrimary.revendedores.toArray() as PessoasMapaModel[]
      } else if (filtro.tipoBuscaMapa === EnumTipoBuscaMapa.Clientes && filtrosVazios && isRegistroAtualizado) {
        res = await LicenciamentoDBPrimary.clientes.toArray() as PessoasMapaModel[]

        if(!isAllowed){
          res = res.filter(pessoa => pessoa.revendaId === revendaId)
        }

      } else if (filtro.tipoBuscaMapa === EnumTipoBuscaMapa.Clientes && apenasFiltroDeSistema && isRegistroAtualizado) {
        res = await LicenciamentoDBPrimary.clientes.filter(pessoa => {
          if(!isAllowed && pessoa.revendaId !== revendaId){
            return false
          }
          return !isEmpty(pessoa.contratos.find(contrato => contrato.sistemaId === filtro.sistemaId))
        }).toArray() as PessoasMapaModel[]
      } else {
        res = await getPessoasMapaWrapper(filtro)

        if (filtro.tipoBuscaMapa === EnumTipoBuscaMapa.Clientes && filtrosVazios) {
          await LicenciamentoDBPrimary.limparCampo('clientes')
          await LicenciamentoDBPrimary.clientes.bulkAdd(res)
          setRegistro(GestaoStorageKeys.RelatorioUsuario, {usuarioId: usuario?.Id}, false)
        }
        if (filtro.tipoBuscaMapa === EnumTipoBuscaMapa.Revendedores && filtrosVazios) {
          await LicenciamentoDBPrimary.limparCampo('revendedores')
          await LicenciamentoDBPrimary.revendedores.bulkAdd(res)
        }
      }

      if (isEmpty(res)) {
        res = await getPessoasMapaWrapper(filtro)

        if (filtro.tipoBuscaMapa === EnumTipoBuscaMapa.Clientes && filtrosVazios) {
          await LicenciamentoDBPrimary.limparCampo('clientes')
          await LicenciamentoDBPrimary.clientes.bulkAdd(res)
        }
        if (filtro.tipoBuscaMapa === EnumTipoBuscaMapa.Revendedores && filtrosVazios) {
          await LicenciamentoDBPrimary.limparCampo('revendedores')
          await LicenciamentoDBPrimary.revendedores.bulkAdd(res)
        }
      }

      setPessoas(res)
      setLoading(false)
    },
    [getPessoasMapaWrapper, getRegistro, setRegistro, tipoUsuario, usuario?.Id, usuario?.RevendaId],
  );

  useEffect(() => {
    search(new FiltroMapaModel());
    getSistemasWrapper()
      .then((data) => setSistemas(data))
      .catch((err) => showToast('error', err.message));

    if (tipoUsuario() === EnumTipoPessoas.Revendedor ||
      (tipoUsuario() === EnumTipoPessoas.Funcionario && !isEmpty(usuario?.RevendaId)) ||
      [EnumTipoPessoas.FuncionarioFinanceiro, EnumTipoPessoas.FuncionarioFinanceiroSemCusto].includes(tipoUsuario())) {

      const revendaId = tipoUsuario() === EnumTipoPessoas.Revendedor ? usuario!.Id : usuario!.RevendaId

      getRevendaInfo(revendaId)
    }
  }, [getPessoasMapaWrapper, getRevendaInfo, getSistemasWrapper, search, showToast, tipoUsuario, usuario]);

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <MapaClienteHeader titulo="Mapa de Clientes" sistemas={sistemas} onSubmit={search} />
      </Grid>
      <Box component="div" className={classes.mapContainer}>
        <Button
          className={classes.hideButton}
          color='primary'
          variant='outlined'
          onClick={() => setShowFilter(true)}
          style={{ bottom: '20px', display: showFilter ? 'none' : 'block' }}
        >
          <VoltarIcon
            tipo="BUTTON_FAB"
            style={{
              transform: 'rotate(90deg)',
            }}
          />
        </Button>
        <LoadScript
          googleMapsApiKey={VariaveisAmbiente.googleMapsKey}
        >
          <GoogleMap
            mapContainerStyle={{
              height: '100%',
              width: '100%'
            }}
            clickableIcons={false}

            center={center}
            zoom={10}
            options={{
              clickableIcons: false,
              disableDefaultUI: true,

            }}
          >
            {!carregando && pessoas.map(marker => (
              <MapMarker isRevendedor={isRevendedor} lat={marker.latitude} lng={marker.longitude} pessoa={marker} />
            ))}
          </GoogleMap>
        </LoadScript>
        :{carregando && <CircularLoading tipo="FULLSIZED" />}
      </Box>
    </Grid>
  );
}
