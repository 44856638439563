import { Box, Grid } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TabSaurusContent, TabSaurusLabel, TabsSaurus } from "views/components/tabs/tabs-saurus"
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles";
import { PesquisaIndicacoes } from "../indicacoes-header/components/pesquisa-indicacoes/pesquisa-indicacoes";
import { IndicacoesList } from "./components/indicacoes-list";
import { IndicacoesListSearchProps } from "./components/indicacoes-list-seach-props";
import { useStyles } from "./indicacoes-tabs-styles";
import { useThemeQueries } from "views/theme";

interface IndicacoesTabProps {
    openSearch: boolean;
    searchProps: IndicacoesListSearchProps;
    openPesquisa: boolean;
    setOpenPesquisa: (open: boolean) => any;
    setSearchProps: React.Dispatch<React.SetStateAction<IndicacoesListSearchProps>>
}

export const IndicacoesTabs = ({
    openSearch,
    searchProps,
    openPesquisa,
    setOpenPesquisa,
    setSearchProps,
}: IndicacoesTabProps) => {
    // rotas
    const rotaDisponiveis = '/indicacoes/disponiveis';
    const rotaNegociacao = '/indicacoes/aceitas';
    const rotaValidacao = '/indicacoes/em-validacao'
    const rotaFinalizadas = '/indicacoes/finalizadas';

    // AUX
    const { location, replace } = useHistory();
    const classes = useStyles();
    const classesCadastros = useDefaultCadastroStyles();
    const { isMobile } = useThemeQueries();

    const retornaAba = (path: string) => {
        return path.indexOf(rotaNegociacao) > -1 ? 1 : path.indexOf(rotaValidacao) > -1 ? 2 : path.indexOf(rotaFinalizadas) > -1 ? 3 : 0;
    };

    const [abaSelecionada, setAbaSelecionada] = useState(
        retornaAba(location.pathname),
    );

    const tabChange = (index: number) => {
        if (index === 0) {
            replace(rotaDisponiveis);
            return
        }
        if (index === 1) {
            replace(rotaNegociacao);
            return
        }
        if (index === 2) {
            replace(rotaValidacao);
            return
        }

        replace(rotaFinalizadas)
    }


    useEffect(() => {
        const aba = retornaAba(location.pathname);
        setAbaSelecionada(aba);
    }, [location.pathname]);

    const closePesquisaWrapper = useCallback(() => {
        setOpenPesquisa(false);
    }, [setOpenPesquisa]);

    const onPesquisar = useCallback(
        (props: IndicacoesListSearchProps) => {
            setSearchProps(props);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <>
            <TabsSaurus
                selectedTabIndex={abaSelecionada}
                onChange={tabChange}
                tabsLabel={[
                    new TabSaurusLabel('Disponíveis', 0),
                    new TabSaurusLabel(isMobile ? 'Negociação' : 'Em Negociação', 1),
                    new TabSaurusLabel('Validação', 2),
                    new TabSaurusLabel('Finalizadas', 3),
                ]}
                tabsContent={[
                    new TabSaurusContent(0,
                        (
                            <>
                                {abaSelecionada === retornaAba(location.pathname) && (
                                    <Box display='flex' flexDirection='column' justifyContent='space-between'>
                                        <div className={classes.defaultContainer}>
                                            <Grid container>
                                                <Grid item xs={12} className={classes.listContainer}>
                                                    <IndicacoesList
                                                        searchProps={searchProps}
                                                        abaSelecionada={abaSelecionada}
                                                        aba={0}
                                                        status={abaSelecionada}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Box>
                                )}
                            </>
                        ),
                    ),
                    new TabSaurusContent(1,
                        (
                            <>
                                {abaSelecionada === retornaAba(location.pathname) && (
                                    <Box display='flex' flexDirection='column' justifyContent='space-between'>
                                        {openSearch && <Grid className={classesCadastros.header}>
                                            <PesquisaIndicacoes
                                                isOpened={openPesquisa!}
                                                onCloseSearch={closePesquisaWrapper}
                                                onPesquisar={onPesquisar}
                                                aba={1}
                                            />
                                        </Grid>}
                                        <div className={classes.defaultContainer}>
                                            <Grid container>
                                                <Grid item xs={12} className={classes.listContainer}>
                                                    <IndicacoesList
                                                        searchProps={searchProps}
                                                        abaSelecionada={abaSelecionada}
                                                        aba={1}
                                                        status={abaSelecionada}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Box>
                                )}
                            </>
                        ),
                    ),
                    new TabSaurusContent(2,
                        (
                            <>
                                {abaSelecionada === retornaAba(location.pathname) && (
                                    <Box display='flex' flexDirection='column' justifyContent='space-between'>
                                        {openSearch && <Grid className={classesCadastros.header}>
                                            <PesquisaIndicacoes
                                                isOpened={openPesquisa!}
                                                onCloseSearch={closePesquisaWrapper}
                                                onPesquisar={onPesquisar}
                                                aba={2}
                                            />
                                        </Grid>}
                                        <div className={classes.defaultContainer}>
                                            <Grid container>
                                                <Grid item xs={12} className={classes.listContainer}>
                                                    <IndicacoesList
                                                        searchProps={searchProps}
                                                        abaSelecionada={abaSelecionada}
                                                        aba={2}
                                                        status={abaSelecionada}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Box>
                                )}
                            </>
                        ),
                    ),
                    new TabSaurusContent(3,
                        (
                            <>
                                {abaSelecionada === retornaAba(location.pathname) && (
                                    <Box display='flex' flexDirection='column' justifyContent='space-between'>
                                        {openSearch && <Grid className={classesCadastros.header}>
                                            <PesquisaIndicacoes
                                                isOpened={openPesquisa!}
                                                onCloseSearch={closePesquisaWrapper}
                                                onPesquisar={onPesquisar}
                                                aba={3}
                                            />
                                        </Grid>}
                                        <div className={classes.defaultContainer}>
                                            <Grid container>
                                                <Grid item xs={12} className={classes.listContainer}>
                                                    <IndicacoesList
                                                        searchProps={searchProps}
                                                        abaSelecionada={abaSelecionada}
                                                        aba={3}
                                                        status={abaSelecionada}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Box>
                                )}
                            </>
                        ),
                    ),
                ]}
            />
        </>
    );
}