import { makeStyles } from "@material-ui/core";

export const usePessoaFormaPagamentoStyles = makeStyles((theme) => ({
    buttonRemover: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
        '& svg': {
            fill: theme.palette.error.main
        }
    },
}));
