import { Card, Divider, Typography } from '@material-ui/core';
import { useStyles } from './dashboard-mapa-styles';
import { useHistory } from 'react-router-dom';

// interface DashboardMapaProps{
// }

export default function DashboardMapa() {
    const classes = useStyles()
    const history = useHistory()

    return (
            <Card
                onClick={() => history.push('/dashboard/mapa-clientes')}
                className={classes.mapContainer} style={{height: '100%', minHeight: 400}}>
                <Typography variant='h6' color='textPrimary'>Mapa de Clientes</Typography>
                <Divider />
                <div className={classes.mapImage}></div>
            </Card>
    );
}
