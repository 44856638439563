import { useCallback } from "react";
import { PrivatePageHeader } from "views/components/headers";
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { FiltroIcon, MenuIcon, VoltarIcon } from "views/components/icons";
import { PesquisaFaturas } from "./components/pesquisa-faturas/pesquisa-faturas";
import { useMenuPrincipal } from "services/app";
import { useThemeQueries } from "views";
import { FaturasListSearchProps } from "../faturas-list/faturas-list-search-props";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import { FaturaLocationProps } from "views/pages/private/notas-fiscais-revenda/components/notas-fiscais-revenda-header/notas-fiscais-revenda-header";
import { useHeaderStyles } from "views/components/headers/header-private-page/header-styles";
import { SetaCimaIcon } from "views/components/icons/seta-cima-icon";

export interface FaturasHeaderProps {
  openPesquisa: boolean;
  setOpenPesquisa: (state: boolean) => any;
  setSearchProps: (props: FaturasListSearchProps) => any;
  titulo: string;
}

export const FaturasHeader = ({
  openPesquisa,
  setOpenPesquisa,
  setSearchProps,
  titulo
}: FaturasHeaderProps) => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();
  const { theme } = useThemeQueries();
  const classes = useHeaderStyles();

  const history = useHistory()
  const { state } = useLocation<FaturaLocationProps>()

  const leftArea = useCallback(
    () =>
      isMobile ? (titulo === 'Faturas de Clientes') ? (
        <ButtonPrivateHeader
          icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
          tooltip="Menu"
          onClick={abrirMenu}
        ></ButtonPrivateHeader>
      ) : <ButtonPrivateHeader
        icon={<VoltarIcon tipo="PRIVATE_HEADER"></VoltarIcon>}
        tooltip="Voltar"
        onClick={() => {
          if (!state?.from?.pathname || isEmpty(state.from.pathname)) {
            history.push('/faturas')
            return
          }
          if (state.from.page) {
            history.push('/faturas?page=' + state.from.page)
            return
          }
          if (state.from.comissionadoId) {
            history.push({
              pathname: `/comissoes/disponiveis/`,
              state: {
                from: {
                  comissionadoId: state.from.comissionadoId,
                  faturasIds: state.from.faturasIds,
                },
              }

            })
            return
          }
          if (state.from.pathname.includes('/comissoes/realizadas/')) {
            history.push(state.from.pathname)
            return
          }
          if (state.from.pathname.includes('/clientes/')) {
            history.push({
              pathname: state.from.pathname,
              state: {
                from: {
                  searchProps: state.from.searchProps,
                  pageFatura: state.from.pageFatura
                }
              }
            })
            return
          }
          if(state.from.pathname.includes('/notas-fiscais-rejeitadas')){
            history.push(state.from.pathname)
            return
          }
          history.goBack()
        }}
      ></ButtonPrivateHeader> : (titulo !== "Faturas de Clientes") ? (
        <ButtonPrivateHeader
          icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
          tooltip="Voltar"
          onClick={() => {
            if (!state?.from?.pathname || isEmpty(state.from.pathname)) {
              history.push('/faturas')
              return
            }
            if (state.from.page) {
              history.push('/faturas?page=' + state.from.page)
              return
            }
            if (state.from.comissionadoId) {
              history.push({
                pathname: `/comissoes/disponiveis/`,
                state: {
                  from: {
                    comissionadoId: state.from.comissionadoId,
                    faturasIds: state.from.faturasIds,
                  },
                }

              })
              return
            }
            if (state.from.pathname.includes('/comissoes/realizadas/')) {
              history.push(state.from.pathname)
              return
            }
            if (state.from.pathname.includes('/clientes/')) {
              history.push({
                pathname: state.from.pathname,
                state: {
                  from: {
                    searchProps: state.from.searchProps,
                    pageFatura: state.from.pageFatura
                  }
                }
              })
              return
            }
            if(state.from.pathname.includes('/notas-fiscais-rejeitadas')){
              history.push(state.from.pathname)
              return
            }
            history.goBack()
          }}
        />
      ) : null,
    [isMobile, titulo, abrirMenu, state, history]
  );

  const rightArea = useCallback(
    () => (titulo === 'Faturas de Clientes') ? (
      isMobile ? (
        <ButtonPrivateHeader
          icon={
            openPesquisa ? (
              <SetaCimaIcon tipo="PRIVATE_HEADER"></SetaCimaIcon>
            ) : (
              <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>
            )
          }
          tooltip={!openPesquisa ? "Pesquisar" : "Fechar Pesquisa"}
          onClick={() => setOpenPesquisa(!openPesquisa)}
        ></ButtonPrivateHeader>
      ) :
        <ButtonPrivateHeader
          className={classes.pesquisaBox}
          text={!openPesquisa ? 'Filtros' : 'Fechar Filtros'}
          icon={
            openPesquisa ? (
              <SetaCimaIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main}></SetaCimaIcon>
            ) : (
              <FiltroIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main}></FiltroIcon>
            )
          }
          tooltip={!openPesquisa ? "Pesquisar" : "Fechar Pesquisa"}
          onClick={() => setOpenPesquisa(!openPesquisa)}
        ></ButtonPrivateHeader>) : null,
    [classes.pesquisaBox, isMobile, openPesquisa, setOpenPesquisa, theme.palette.primary.main, titulo]
  );

  const closePesquisaWrapper = useCallback(() => {
    setOpenPesquisa(false);
  }, [setOpenPesquisa]);

  const onPesquisar = useCallback(
    (props: FaturasListSearchProps) => {
      setSearchProps({ ...props, atualizarPesquisa: !props.atualizarPesquisa });
    },
    [setSearchProps]
  );

  const pesquisa = (
    <PesquisaFaturas
      isOpened={openPesquisa!}
      onCloseSearch={closePesquisaWrapper}
      onPesquisar={onPesquisar}
    />
  );

  return (
    <>
      <PrivatePageHeader
        title={titulo}
        leftArea={leftArea()}
        bottomArea={pesquisa}
        rightArea={rightArea()}
      />
    </>
  );
};
