import { Box, Button, Grid } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from '../dialog-pessoa-sistema/dialog-pessoa-sistema-styles';
import { usePessoaFormaPagamentoStyles } from './dialog-pessoa-forma-pagamento-styles';
import { CircularLoading, useThemeQueries } from 'views';
import { useCallback, useEffect, useRef } from 'react';
import { useToastSaurus } from 'services/app';
import { DefaultFormRefs } from 'views/components/form/utils';
import { guidEmpty } from 'utils/guid-empty';
import { LixoIcon, SalvarIcon, VoltarIcon } from 'views/components/icons';
import { PessoaPagamentoModel } from 'model/api/gestao/pessoa/pessoa-pagamento-model';
import { PessoaFormaPagamentoFormModel } from 'model/app/forms/pessoa-forma-pagamento/pessoa-forma-pagamento-form-model';
import { usePostAdicionarPessoaPagamento, usePutAtualizarPessoaPagamento } from 'data/api/gestao/pessoa-forma-pagamento';
import { FormPessoaPagamento } from 'views/components/form/master/pessoa/pessoa-pagamento/form-pessoa-pagamento';
import { useDeletePessoaFormaPagamento } from 'data/api/gestao/pessoa-forma-pagamento/delete-deletar-pessoa-forma-pagamento';
import { useConfirm } from 'material-ui-confirm';

interface DialogPessoaFormaPagamentoProps {
  openned: boolean;
  closeModal: () => void;
  model: PessoaPagamentoModel
  loading: boolean;
  id: string;
}

export const DialogPessoaFormaPagamento = ({
  openned,
  closeModal,
  model,
  loading,
  id
}: DialogPessoaFormaPagamentoProps) => {
  const classes = useStyles();
  const { buttonRemover } = usePessoaFormaPagamentoStyles()
  const { showToast } = useToastSaurus()
  const { isMobile } = useThemeQueries()
  const confirm = useConfirm()

  const { postAdicionarPessoaPagamento, carregando: carregandoPost } = usePostAdicionarPessoaPagamento()
  const { putAtualizarPessoaPagamento, carregando: carregandoPut } = usePutAtualizarPessoaPagamento()
  const { deletePessoaFormaPagamento, carregando: carregandoDelete } = useDeletePessoaFormaPagamento()

  const carregando = carregandoPost || carregandoPut || carregandoDelete || loading

  const formRef = useRef<DefaultFormRefs<PessoaFormaPagamentoFormModel>>(null)

  const recarregarForm = useCallback((modelForm: PessoaFormaPagamentoFormModel) => {
    formRef.current?.fillForm(modelForm)
  }, [])

  useEffect(() => {
    const formModel = new PessoaFormaPagamentoFormModel(model.id, model.pessoaId, model.formaPagamento.id, model.padrao)
    recarregarForm(formModel);
  }, [model, recarregarForm, openned])

  const saveNewPagamento = useCallback(
    async (model: PessoaFormaPagamentoFormModel) => {
      const res = await postAdicionarPessoaPagamento(id, model);

      if (res.erro) throw res.erro;

      showToast('success', 'Nova Forma de Pagamento Adicionada!');
      closeModal();
    },
    [closeModal, id, postAdicionarPessoaPagamento, showToast],
  );

  const saveChangePagamento = useCallback(
    async (model: PessoaFormaPagamentoFormModel) => {
      const res = await putAtualizarPessoaPagamento(id, model);

      if (res.erro) throw res.erro;

      showToast('success', 'Forma de Pagamento Atualizada!');
    },
    [putAtualizarPessoaPagamento, id, showToast],
  );  

  const deleteFormaPagamento = useCallback(async () => {
    try {
      await deletePessoaFormaPagamento(model.pessoaId, model.id)

      showToast('success', 'Forma de Pagamento excluída.')
      closeModal()
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [closeModal, deletePessoaFormaPagamento, model.id, model.pessoaId, showToast])

  const confirmDelete = useCallback(() => {
    confirm({
      confirmationText: 'Excluir',
      cancellationText: 'Cancelar',
      description: 'Tem certeza que deseja desvincular esta forma de pagamento?'
    }).then(() => {
      deleteFormaPagamento()
    })
  }, [confirm, deleteFormaPagamento])

  const handleSubmit = useCallback(
    async (model: PessoaFormaPagamentoFormModel) => {
      try {
        if (model.id === guidEmpty()) {
          await saveNewPagamento(model);
          showToast('success', 'Forma de Pagamento adicionada')
          return true;
        }

        await saveChangePagamento(model);
        showToast('success', 'Alterado com sucesso')
        return true;
      } catch (err: any) {
        showToast('error', err.message);

        return false;
      }
    },
    [saveChangePagamento, saveNewPagamento, showToast],
  );
  return (
    <DialogSaurus
      aberto={openned}
      titulo={model.id === guidEmpty() ? "Nova Forma de Pagamento" : "Editar Forma de Pagamento"}
      tamanho="sm"
    >
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, paddingTop: 5 }}>
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={12}>
                <FormPessoaPagamento
                  loading={carregando}
                  onSubmit={handleSubmit}
                  showLoading={carregando}
                  ref={formRef}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} style={{ paddingBottom: '7px' }}>
                  <Grid item xs={12} md={6}>
                    {model.id !== guidEmpty() && <Button variant='outlined' color='primary' fullWidth={isMobile} className={buttonRemover}
                    onClick={confirmDelete}>
                      <LixoIcon tipo='BUTTON' />
                      Excluir
                    </Button>}
                  </Grid>
                  <Grid item xs={12} md={3}>
                    {closeModal && (
                      <Button
                        disabled={carregando}
                        variant="outlined"
                        fullWidth
                        color="primary"
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        <VoltarIcon tipo='BUTTON' />
                        Voltar
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Button
                      disabled={carregando}
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => formRef.current?.submitForm()}
                    >
                      <SalvarIcon tipo='BUTTON_PRIMARY' />
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
