import { Grid, Tooltip, Typography } from '@material-ui/core';
import { OlhoAbertoIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { CardNFRejeitadasProps } from './card-nf-rejeitadas-props';
import { useStyles } from './card-nf-rejeitadas-styles'
import { toCurrency } from 'utils/toCurrency';
import { toDateString } from 'utils/to-date';

export const CardNFRejeitadas = ({
    model,
    onClick
}: CardNFRejeitadasProps) => {
    const classes = useDefaultCardStyles();
    const listClasses = useStyles();
    const { theme, isMobile } = useThemeQueries();

    return (
        <>
            <DefaultCard
                onClick={() => {
                    onClick(model);
                }}
            >
                <div className={`${classes.cardContent} ${listClasses.container}`}>
                    <Grid container spacing={isMobile ? 1 : 2}>
                        <Grid item md={4} xs={12}>
                            <Typography color="textPrimary" variant="caption">
                                Pagador
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {model.fatura.pagadorNome}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography color="textPrimary" variant="caption">
                                Descrição
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {model.descricao.substring(0, 40) + '...'}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Typography color="textPrimary" variant="caption">
                                Data de Emissão da Fatura
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                style={{fontWeight: 600}}
                            >
                                {toDateString(model.fatura.dataEmissao)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2} style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end'
                        }}>
                            <Typography color="textPrimary" variant="caption">
                                Valor do Serviço
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                style={{
                                    fontWeight: 600
                                }}
                            >
                                {toCurrency(model.valorServico)}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <Tooltip arrow title="Visualizar Nota Fiscal">
                    <div className={classes.cardRightButton} style={{ margin: '0px 0px 0px 10px' }}>
                        <OlhoAbertoIcon tipo="BUTTON" fill={theme.palette.primary.main} />
                    </div>
                </Tooltip>
            </DefaultCard>
        </>
    );
};