import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { IndicacoesModel } from 'model/api/gestao/indicacoes/indicacoes-model';
import { Controller, useForm } from 'react-hook-form';
import { IndicacoesPutModel } from 'model/api/gestao/indicacoes/indicacoes-put-model';
import { picker } from 'utils/picker';
import { AutocompleteRevendas } from 'views/components/controles/autocompletes/autocomplete-revendas/autocomplete-revendas';
import { toDateString } from 'utils/to-date';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormIndicacaoEditValidation } from './form-indicacao-validation';
import { AutocompleteAgenteExterno } from 'views/components/controles/autocompletes/autocomplete-agente-externo/autocomplete-agente-externo';


interface FormIndicacaoEditProps extends DefaultFormProps<IndicacoesPutModel> {
    tipoRevenda: boolean;
    tipoFormPessoa: EnumTipoPessoas;
    configuracoes?: boolean;
    indicacao: IndicacoesModel;
}

export const FormIndicacaoEdicao = forwardRef<DefaultFormRefs<IndicacoesPutModel>, FormIndicacaoEditProps>(({
    loading,
    onSubmit,
    tipoRevenda,
    tipoFormPessoa,
    configuracoes = false,
    indicacao,
    ...props
}: FormIndicacaoEditProps, ref) => {
    const { isMobile } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const { FormIndicacaoValidationYup } = useFormIndicacaoEditValidation()

    // STATES E REFS
    const [model, setModel] = useState<IndicacoesPutModel>(new IndicacoesPutModel())
    const refInputNome = useRef<HTMLInputElement>(null);

    const valores: IndicacoesPutModel = useMemo(() => ({
        ...model,
        responsavelId: indicacao.responsavel?.id ?? null,
        indicadorId: indicacao.indicador.id ?? null,
    }), [indicacao.indicador.id, indicacao.responsavel?.id, model])

    const {
        handleSubmit,
        formState: { errors },
        control,
        reset,
        setValue,
    } = useForm<IndicacoesPutModel>({
        resolver: yupResolver(FormIndicacaoValidationYup),
        defaultValues: { ...valores },
        criteriaMode: 'all',
        mode: 'onBlur' && 'onChange',
    });

    useEffect(() => {
        reset({ ...valores })
    }, [reset, valores])

    const submit = (values: IndicacoesPutModel) => {
        const indicacao = picker<IndicacoesPutModel>(values, new IndicacoesPutModel())
        onSubmit(indicacao, model);
    };

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(submit)();
        },
        resetForm: () => {
            reset();
            if (!isMobile) refInputNome.current?.focus();
        },
        fillForm: (values: IndicacoesPutModel) => {
            setModel(values)
            reset({ ...values })
            if (!isMobile) refInputNome.current?.focus();
        },
    }));

    return (
        <>
            <Box my={2}>
                <div className={utilClasses.formContainer} style={{
                    position: 'relative'
                }}>
                    <form
                        className={utilClasses.controlLoading}
                        style={{ padding: !isMobile ? '10px' : '0' }}
                    >
                        <Grid container spacing={2} justifyContent="center">
                            {!tipoRevenda ? (
                                <>
                                    <Grid item xs={12} md={4}>
                                        <Controller
                                            name="indicadorId"
                                            control={control}
                                            render={({ field }) => (
                                                <AutocompleteAgenteExterno
                                                    size="medium"
                                                    label='Indicador'
                                                    fullWidth={true}
                                                    {...field}
                                                    onChange={(e: any) => {
                                                        setValue('indicadorId', e.value.id)
                                                    }}
                                                    helperText={
                                                        errors.indicadorId
                                                            ? errors.indicadorId.message
                                                            : undefined
                                                    }
                                                    error={Boolean(errors.indicadorId && errors.indicadorId)}
                                                    value={field.value ?? ''}
                                                    defaultValue={indicacao.indicador.id}
                                                    fetchOnLoad
                                                />
                                            )} />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Controller
                                            name="responsavelId"
                                            control={control}
                                            render={({ field }) => (
                                                <AutocompleteRevendas
                                                    size="medium"
                                                    label='Revenda'
                                                    fullWidth={true}
                                                    {...field}
                                                    onChange={(e: any) => {
                                                        setValue('responsavelId', e.value.id)
                                                    }}
                                                    helperText={
                                                        errors.responsavelId
                                                            ? errors.responsavelId.message
                                                            : undefined
                                                    }
                                                    error={Boolean(errors.responsavelId && errors.responsavelId)}
                                                    value={field.value ?? ''}
                                                    revendasIndicadas
                                                />
                                            )} />
                                    </Grid>
                                </>
                            ) : null}
                            <Grid item xs={12} md={tipoRevenda ? 3 : 4}>
                                <Controller
                                    name="faturamentoEstimado"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            allowSubmit={false}
                                            readOnly
                                            formatarMilhares
                                            tipo='DECIMAL'
                                            showStartAdornment
                                            label="VOP Mensal Estimado"
                                            fullWidth
                                            autoComplete={'off'}
                                            {...field}
                                        />
                                    )} />
                            </Grid>
                            <Grid item md={tipoRevenda ? 3 : 4} xs={12}>
                                <TextFieldSaurus
                                    allowSubmit={false}
                                    readOnly
                                    tipo='TEXTO'
                                    label="Data Último Retorno"
                                    fullWidth
                                    autoComplete={'off'}
                                    size='medium'
                                    value={toDateString(indicacao.dataUltimoRetorno) ?? "-"}
                                />
                            </Grid>
                            <Grid item md={tipoRevenda ? 3 : 4} xs={12}>
                                <TextFieldSaurus
                                    readOnly
                                    allowSubmit={false}
                                    tipo='TEXTO'
                                    label="Data Finalização"
                                    fullWidth
                                    size='medium'
                                    autoComplete={'off'}
                                    value={toDateString(indicacao.dataFinalizacao) ?? "-"}
                                />
                            </Grid>
                            <Grid item md={tipoRevenda ? 3 : 4} xs={12}>
                                <TextFieldSaurus
                                    readOnly
                                    allowSubmit={false}
                                    tipo='TEXTO'
                                    label="Data Última Visita"
                                    fullWidth
                                    value={toDateString(indicacao.dataUltimaVisita) ?? "-"}
                                    autoComplete={'off'}
                                />
                            </Grid>
                        </Grid>
                        <Button style={{ display: 'none' }} type="submit"></Button>
                    </form>
                </div>
            </Box>
        </>
    );
},
);
