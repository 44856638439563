import { ClienteIdealRespostaModel } from "model/api/gestao/cliente-ideal/cliente-ideal-resposta-model";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus";
import { guidEmpty } from "utils/guid-empty";
import { Button, Grid } from "@material-ui/core";
import { useEffect, useRef } from "react";
import { DefaultFormRefs } from "views/components/form/utils";
import { FormRespostaApuracao } from "views/components/form/master/resposta-apuracao/form-resposta-apuracao";
import { SalvarIcon, VoltarIcon } from "views/components/icons";

interface DialogApuracaoRespostaProps {
    openned: boolean;
    model: ClienteIdealRespostaModel;
    onSubmit: (model: ClienteIdealRespostaModel, index: number) => void;
    close: () => void;
    maxPontos: number;
    index: number;
}

export const DialogApuracaoResposta = ({
    close,
    onSubmit,
    openned,
    model,
    maxPontos,
    index
}: DialogApuracaoRespostaProps) => {

    const isNovaResposta = model.id === guidEmpty();
    const formRef = useRef<DefaultFormRefs<ClienteIdealRespostaModel>>(null)

    useEffect(() => {
        if (openned) {
            setTimeout(() => {
                formRef.current?.fillForm(model)
            }, 50)
        }
    }, [model, openned])

    const handleSubmit = (model: ClienteIdealRespostaModel) => {
        onSubmit(model, index)
    }

    return (
        <DialogSaurus
            aberto={openned}
            titulo={isNovaResposta ? 'Nova Resposta' : 'Editar Resposta'}
            tamanho='sm'
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormRespostaApuracao
                        loading={false}
                        showLoading={false}
                        maxPontos={maxPontos}
                        onSubmit={handleSubmit}
                        ref={formRef}
                        index={index}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        variant='outlined'
                        color='primary'
                        onClick={() => close()}
                    >
                        <VoltarIcon tipo='BUTTON' />
                        Voltar
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        onClick={() => formRef.current?.submitForm()}
                    >
                        <SalvarIcon tipo='BUTTON_PRIMARY' />
                        Salvar
                    </Button>
                </Grid>
            </Grid>
        </DialogSaurus>
    )
}