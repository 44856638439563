import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    container: {
        '& p': {
            fontSize: 13
        }
    },
    item: {
        marginLeft: 0
    }
}))