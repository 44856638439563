import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Grid, Button, Fade } from '@material-ui/core';
import { makeUtilClasses } from 'views';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useForm, Controller } from 'react-hook-form';
import { NovoModuloCadastroFormModel } from 'model/app/forms/sistemas/novo-modulo-cadastro-form-model';
import { picker } from 'utils/picker';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { TpCalculosMock } from 'data/mocks';
import { EnumTipoControleModulo } from 'model/enums/enum-tipo-controle-modulo';
import { TipoControleModuloMock } from 'data/mocks/tipo-controle-modulo-mock';
import { EnumTipoCalculo } from 'model';
import { useFormEditarSistemaValidation } from './form-novo-modulo-validation';
import { yupResolver } from '@hookform/resolvers/yup';

export const FormNovoModuloCadastro = forwardRef<
  DefaultFormRefs<NovoModuloCadastroFormModel>,
  DefaultFormProps<NovoModuloCadastroFormModel>
>(
  (
    { loading, onSubmit, ...props }: DefaultFormProps<NovoModuloCadastroFormModel>,
    ref,
  ) => {
    const [model, setModel] = useState<NovoModuloCadastroFormModel>(new NovoModuloCadastroFormModel())
    const [, setControlaTerminal] = useState<EnumTipoControleModulo>(model.tipoControle)
    const [tipoCalculo, setTipoCalculo] = useState<number>(model.tipoCalculo)
    const {FormEditarSistemaValidationYup} = useFormEditarSistemaValidation()

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
      getValues,
      setValue
    } = useForm<NovoModuloCadastroFormModel>({
      defaultValues: { ...model },
      resolver: yupResolver(FormEditarSistemaValidationYup),
      criteriaMode: 'all',
      mode: 'onChange',
    });
    const refInputSenhaAtual = useRef<HTMLInputElement>(null);
    const utilClasses = makeUtilClasses();

    const submitNovoModulo = useCallback(
      (data: NovoModuloCadastroFormModel) => {
        const model = picker<NovoModuloCadastroFormModel>(data,
          new NovoModuloCadastroFormModel())
        if (model.tipoControle === EnumTipoControleModulo.NaoControla || model.tipoControle === EnumTipoControleModulo.Filial) {
          model.tipoTerminal = ''
        }
        onSubmit(model);
      },
      [onSubmit],
    );

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitNovoModulo)();
      },
      resetForm: () => {
        refInputSenhaAtual.current?.focus();

        reset();
      },
      fillForm: (values: NovoModuloCadastroFormModel) => {
        setModel(values)
        reset({ ...values });
      },
    }));

    useEffect(() => {
      setTipoCalculo(model.tipoCalculo)
    }, [model.tipoCalculo])


    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {loading && props.showLoading ? (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="NORMAL" />
              </div>
            ) : null}

            <form
              onSubmit={handleSubmit(submitNovoModulo)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={6}>
                  <Controller
                    name="nome"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        inputRef={refInputSenhaAtual}
                        id="nome"
                        label="Nome"
                        tipo="TEXTO"
                        allowSubmit
                        fullWidth={true}
                        error={Boolean(
                          errors.nome && errors.nome.message,
                        )}
                        helperText={
                          touchedFields.nome || errors.nome
                            ? errors.nome?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="codigo"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={loading}
                        tipo="NUMERO"
                        label="Código"
                        allowSubmit
                        error={Boolean(
                          errors.codigo && errors.codigo.message,
                        )}
                        helperText={
                          touchedFields.codigo || errors.codigo
                            ? errors.codigo?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="descricao"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        multiline
                        rows={4}
                        id="descricao"
                        label="Descrição"
                        tipo="TEXTO"
                        allowSubmit
                        fullWidth={true}
                        error={Boolean(
                          errors.descricao && errors.descricao.message,
                        )}
                        helperText={
                          touchedFields.descricao || errors.descricao
                            ? errors.descricao?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="tipoCalculo"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        disabled={loading}
                        label="Tipo de Cálculo"
                        allowSubmit
                        conteudo={TpCalculosMock}
                        error={Boolean(
                          errors.tipoCalculo && errors.tipoCalculo.message,
                        )}
                        helperText={
                          touchedFields.tipoCalculo || errors.tipoCalculo
                            ? errors.tipoCalculo?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('tipoCalculo')}
                        onChange={ev => {
                          const item =
                            TpCalculosMock
                              .filter(item => item.Key === ev.target.value)

                          setValue('tipoCalculo', item[0].Key)
                          setTipoCalculo(item[0].Key)
                        }}
                      />
                    )}
                  />
                </Grid>
                <Fade in={tipoCalculo === EnumTipoCalculo.Modulo}>
                  {tipoCalculo === EnumTipoCalculo.Modulo ? <Grid item xs={12}>
                    <Controller
                      name="codModuloBaseCalculo"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          disabled={loading}
                          tipo="TEXTO"
                          label="Código de Referência"
                          allowSubmit
                          error={Boolean(
                            errors.codModuloBaseCalculo && errors.codModuloBaseCalculo.message,
                          )}
                          helperText={
                            touchedFields.codModuloBaseCalculo || errors.codModuloBaseCalculo
                              ? errors.codModuloBaseCalculo?.message
                              : undefined
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid> : <div></div>}
                </Fade>
                <Grid item xs={4}>
                  <Controller
                    name="quantidadeMinima"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={loading}
                        tipo="TEXTO"
                        positivo
                        label="Quantidade Mínima"
                        allowSubmit
                        fullWidth={true}
                        error={Boolean(
                          errors.quantidadeMinima && errors.quantidadeMinima.message,
                        )}
                        helperText={
                          touchedFields.quantidadeMinima || errors.quantidadeMinima
                            ? errors.quantidadeMinima?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="quantidadeMaxima"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={loading}
                        tipo="TEXTO"
                        positivo
                        allowSubmit
                        label="Quantidade Máxima"
                        fullWidth={true}
                        error={Boolean(
                          errors.quantidadeMaxima && errors.quantidadeMaxima.message,
                        )}
                        helperText={
                          touchedFields.quantidadeMaxima || errors.quantidadeMaxima
                            ? errors.quantidadeMaxima?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="quantidadePadrao"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={loading}
                        tipo="TEXTO"
                        positivo
                        allowSubmit
                        label="Quantidade Padrão"
                        fullWidth={true}
                        error={Boolean(
                          errors.quantidadePadrao && errors.quantidadePadrao.message,
                        )}
                        helperText={
                          touchedFields.quantidadePadrao || errors.quantidadePadrao
                            ? errors.quantidadePadrao?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="tipoControle"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        disabled={loading}
                        conteudo={TipoControleModuloMock}
                        allowSubmit
                        label="Tipo de Controle"
                        fullWidth={true}
                        error={Boolean(
                          errors.tipoControle && errors.tipoControle.message,
                        )}
                        helperText={
                          touchedFields.tipoControle || errors.tipoControle
                            ? errors.tipoControle?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('tipoControle')}
                        onChange={ev => {
                          const item = TipoControleModuloMock.filter(e => e.Key === ev.target.value)

                          setValue('tipoControle', item[0].Key)
                          setControlaTerminal(item[0].Key)

                        }}

                      />
                    )}
                  />
                </Grid>
                {getValues('tipoControle') === EnumTipoControleModulo.Terminal && (
                  <Grid item xs={12}>
                    <Controller
                      name="tipoTerminal"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          disabled={loading}
                          tipo="TEXTO"
                          allowSubmit
                          label="Tipo de Terminal"
                          fullWidth={true}
                          error={Boolean(
                            errors.tipoTerminal && errors.tipoTerminal.message,
                          )}
                          helperText={
                            touchedFields.tipoTerminal || errors.tipoTerminal
                              ? errors.tipoTerminal?.message
                              : undefined
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
