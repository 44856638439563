import { VariaveisAmbiente } from 'config';
import { FaturaLiquidarModel } from 'model/api/gestao/fatura/fatura-liquidar-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePostFaturaLiquidar() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postFaturaLiquidar = useCallback(
        (faturaId: string, model: FaturaLiquidarModel) =>
            invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/Fatura/${faturaId}/liquidar-manual`,
                data: model,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postFaturaLiquidar,
    };
}
