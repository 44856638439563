import { Box, Button, Typography, } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { QRCodeSVG } from 'qrcode.react';
import { useStyles } from './dialog-qrcode-styles'
import { CloseIcon } from 'views/components/icons/close-icon';
import { useRef } from 'react';
import { exportComponentAsPNG } from 'react-component-export-image';
import { DownloadIcon } from 'views/components/icons/download-icon';

interface DialogQRCodeProps {
    openned: boolean;
    closeDialog: () => void;
    value: string;
    titulo?: string;
    descricao?: string;
}

export const DialogQRCode = ({
    openned,
    closeDialog,
    value,
    titulo,
    descricao
}: DialogQRCodeProps) => {

    const classes = useStyles()
    const qrRef = useRef(null)

    return (
        <DialogSaurus
            aberto={openned}
            titulo={titulo || 'QRCode'}
            tamanho="sm"
            endAdornment={
                <Button onClick={closeDialog} style={{ margin: '5px 0' }}>
                    <CloseIcon tipo='BUTTON' style={{ margin: 0 }} />
                </Button>
            }
        >
            <Box className={classes.qrCodecontainer}>
                <div ref={qrRef} className={classes.qrCodeBox}>
                    <QRCodeSVG value={value} size={200} />
                </div>
                <Button variant='contained' color='primary' onClick={() => {
                    exportComponentAsPNG(qrRef)
                }}>
                    <DownloadIcon/>
                    Download
                </Button>
                {descricao && <Typography variant='caption' color='textSecondary'>{descricao}</Typography>}
            </Box>
        </DialogSaurus>
    );
};
