import { VariaveisAmbiente } from 'config';
import { PessoaFormaPagamentoFormModel } from 'model/app/forms/pessoa-forma-pagamento/pessoa-forma-pagamento-form-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePutAtualizarPessoaPagamento() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putAtualizarPessoaPagamento = useCallback(
    (pessoaId: string, pessoa: PessoaFormaPagamentoFormModel) =>
      invocarApi({
        url: `${VariaveisAmbiente.apiVersion}/pessoa/${pessoaId}/forma-pagamento`,
        method: 'PUT',
        data: pessoa,
        enviarTokenUsuario: true,
      }),
    [invocarApi],
  );
  return {
    ...outrasPropriedades,
    putAtualizarPessoaPagamento,
  };
}
