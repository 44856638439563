import { useCallback } from 'react';
import * as Yup from 'yup';

export const useValidationYupSenhaConfirmacao = () => {
    const senhaConfirmacaoYup = useCallback((fieldName: string) => {
        return (
            Yup.string()
                .required('A confirmação da senha é obrigatória.')
                .oneOf([Yup.ref(fieldName)], 'A confirmação diverge da senha informada.')
        )
    }, [])

    return {
        senhaConfirmacaoYup
    }
}