import { guidEmpty } from "utils/guid-empty";
import { SistemaModel } from "../sistemas/sistema-model";
import { PessoasModel } from "../pessoa/pessoas-model";

export class IndicacoesModel {
    constructor(
        public id: string = guidEmpty(),
        public responsavel: PessoasModel = new PessoasModel(),
        public cliente: PessoasModel = new PessoasModel(),
        public indicador: PessoasModel = new PessoasModel(),
        public sistema: SistemaModel = new SistemaModel(),
        public data: string = '',
        public nivelDeInteresse: number = 0,
        public instrucao: string = '', 
        public contato: string = '',
        public observacaoFinalizacao: string = '',
        public status: number = 0,
        public statusGeral: number = 0,
        public avaliacaoIndicador: number = 0,
        public motivoAvaliacaoIndicador: string = '',
        public avaliacaoResponsavel: number = 0,
        public motivoAvaliacaoResponsavel: string = '',
        public dataUltimoRetorno: string = '',
        public dataUltimaVisita: string = '',
        public dataFinalizacao: string = '',
        public dataLimite: string | null = null,
        public faturamentoEstimado: number = 0,
    ) { }
}