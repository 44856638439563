import { Box, Divider, Grid, Tooltip, Typography } from "@material-ui/core";
import { isEmpty } from "lodash";
import { useCallback, useMemo } from "react";
import { formatarCPFCNPJ } from "utils/cpfcnpj-format";
import { toDateString } from "utils/to-date";
import { CancelarIcon, EditarIcon } from "views/components/icons";
import { useThemeQueries } from "views/theme";
import { DefaultCard, useDefaultCardStyles } from "../components";
import { CardIndicacoesProps } from "./card-indicacoes-props";
import { useStyles } from "./card-indicacoes-styles";
import { EnumStatusIndicacao } from "model/enums/enum-status-indicacao";
import { useRetornarCorStatus } from "services/app/use-cases/retornar-cor-status";
import { AvisoIcon } from "views/components/icons/aviso-icon";
import { calcularDiferencaEmDias } from "utils/calc-date";

interface TooltipWarningWrapperProps {
    children: React.ReactNode;
    mostrarTooltip: boolean;
    dataLimite: string;
}

const TooltipWarningWrapper = ({ children, mostrarTooltip, dataLimite }: TooltipWarningWrapperProps) => {
    const diferencaDias = calcularDiferencaEmDias(new Date(), new Date(dataLimite))
    const classes = useStyles({
        expirado: diferencaDias < 0
    })
    if (mostrarTooltip) {
        return (
            <Tooltip title={diferencaDias <= 0 ? 'Esta indicação está expirada.' : <Box display='flex' flexDirection='column'>
                <Typography variant='caption'><span style={{fontWeight: 600}}>AVISO:</span> Faltam apenas {diferencaDias} dias para esta indicação ser disponibilizada novamente.</Typography>
                <Typography variant='caption'>Adicione um retorno para certificar que ficará em sua carteira.</Typography>
            </Box>}>
                <Box display='flex' alignItems='center' gridGap={6} mr={1}>
                    {diferencaDias <= 0 ? <CancelarIcon tipo='GERAL' class={classes.warningIcon} /> : <AvisoIcon tipo='GERAL' class={classes.warningIcon} />}
                    {children}
                </Box>
            </Tooltip>
        )
    }

    return <>
        {children}
    </>
}

export const CardIndicacoes = ({
    model,
    onCheck,
    onClick,
    selected,
}: CardIndicacoesProps) => {
    const classes = useDefaultCardStyles();
    const indicacoesStyles = useStyles({});
    const { theme, isMobile } = useThemeQueries()
    const { retornarCorStatus } = useRetornarCorStatus();


    const retornaStatus = useCallback((): string => {
        switch (model.status) {
            case EnumStatusIndicacao.InteracaoIniciada:
                return 'Interação Iniciada'
            case EnumStatusIndicacao.Disponivel:
                return 'Disponivel'
            case EnumStatusIndicacao.RetornoInterno:
                return 'Retorno Interno'
            case EnumStatusIndicacao.ContatoRealizado:
                return 'Contrato Realizado'
            case EnumStatusIndicacao.VisitaMarcada:
                return 'Visita Marcada'
            case EnumStatusIndicacao.VisitaRealizada:
                return 'Visita Realizada'
            case EnumStatusIndicacao.PropostaEnviada:
                return 'Proposta Enviada'
            case EnumStatusIndicacao.PropostaAceita:
                return 'Proposta Aceita'
            case EnumStatusIndicacao.PropostaRecusada:
                return 'Proposta Recusada'
            case EnumStatusIndicacao.InvalidezSolicitada:
                return 'Invalidez Solicitada'
            case EnumStatusIndicacao.CancelamentoSolicitado:
                return 'Cancelamento Solicitado'
            case EnumStatusIndicacao.Cancelado:
                return 'Cancelado'
            case EnumStatusIndicacao.Finalizado:
                return 'Finalizado'
            case EnumStatusIndicacao.Invalidado:
                return 'Inválida'
            case EnumStatusIndicacao.AguardandoValidacao:
                return 'Aguardando Validação'
        }
        return ''
    }, [model.status])

    const mostrarWarning = useMemo(() => {
        if (!model.dataLimite) return false
        const dataTresDias = new Date(model.dataLimite)
        dataTresDias.setDate(dataTresDias.getDate() - 5)
        return new Date() >= dataTresDias
    }, [model.dataLimite])


    // const retornaEndereco = useMemo(() => {
    //     const { bairro, municipio, numero, complemento, logradouro, uf } = model.cliente?.informacoes ?? new PessoasModel()

    //     if (isEmpty(model.cliente?.informacoes)) {
    //         return ''
    //     }

    //     return `${logradouro ?? ''}, ${numero ?? 0} - ${bairro ?? ''}${municipio ? `, ${municipio}` : ''}${uf ? ` - ${uf}` : ''}${complemento ? `, ${complemento}` : ''}`
    // }, [model.cliente?.informacoes])

    return (
        <>
            <DefaultCard
                isSelected={selected}
                onClick={() => {
                    onClick(model.id, model.status);
                }}

            >
                {isMobile ? (
                    <Box padding={1}>
                        <Grid container spacing={2} style={{ marginBottom: theme.spacing(1) }}>
                            <Grid item xs={6} style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                            }}>
                                <Box display='flex' gridGap={4}>
                                    <Typography
                                        variant="caption"
                                    >
                                        Criação:
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        style={{ fontWeight: 600, color: theme.palette.grey[600], fontSize: 13 }}
                                    >
                                        {toDateString(model.data)}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} style={{
                                display: 'flex',
                                justifyContent: 'end',
                                alignItems: 'center'
                            }}>
                                <Typography className={indicacoesStyles.statusText} style={{
                                    backgroundColor: retornarCorStatus(model.status),
                                    paddingLeft: '8px',
                                    paddingRight: '8px',
                                }}>
                                    {retornaStatus()}
                                </Typography>
                            </Grid>
                            {!isEmpty(model.dataUltimoRetorno) && <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                                <Box display='flex' gridGap={4}>
                                    <Typography
                                        variant="caption"
                                    >
                                        Último Retorno:
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        style={{ fontWeight: 600, color: theme.palette.grey[600], fontSize: 13 }}
                                    >
                                        {toDateString(model.dataUltimoRetorno)}
                                    </Typography>
                                </Box>
                            </Grid>}
                            {model.dataLimite && <Grid item xs={6} style={{
                                display: 'flex',
                                justifyContent: 'end'
                            }}>
                                <TooltipWarningWrapper dataLimite={model.dataLimite} mostrarTooltip={mostrarWarning}>
                                    <Box display='flex' gridGap={4}>
                                        <Typography
                                            variant="caption"
                                            style={{ wordBreak: 'keep-all' }}
                                        >
                                            Data Limite:
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            style={{ fontWeight: 600, color: theme.palette.grey[600], fontSize: 13 }}
                                        >
                                            {toDateString(model.dataLimite)}
                                        </Typography>
                                    </Box>
                                </TooltipWarningWrapper>
                            </Grid>}
                            <Grid item xs={12}>
                                <Divider variant="fullWidth" className={indicacoesStyles.dividerMobile} />
                            </Grid>
                            <Grid item xs={12} style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',

                            }}>
                                <Box>
                                    <Typography
                                        color="textPrimary"
                                        variant="body1"
                                    >
                                        <b>{model?.cliente?.nomeComercial}</b>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box>
                                    <Typography
                                        color="textPrimary"
                                        variant="caption"
                                    >
                                        CPF/CNPJ
                                    </Typography>
                                    <Typography
                                        color="textPrimary"
                                        variant="body2"
                                    >
                                        {formatarCPFCNPJ(model?.cliente?.cpfCnpj)}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box>
                                    <Typography
                                        color="textPrimary"
                                        variant="caption"
                                    >
                                        Indicador
                                    </Typography>
                                    <Typography
                                        color="textPrimary"
                                        variant="body2"
                                    >
                                        {model?.indicador?.nomeComercial}
                                    </Typography>
                                    <Typography
                                        color='textPrimary'
                                        variant='body2'>
                                        {(model.indicador?.revendaNome && model.indicador?.informacoes?.telefone) ? `${model.indicador.informacoes.telefone}` : ''}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box>
                                    <Typography
                                        color="textPrimary"
                                        variant="caption"
                                    >
                                        Responsavel
                                    </Typography>
                                    <Typography
                                        color="textPrimary"
                                        variant="body2"
                                    >
                                        {model?.responsavel?.revendaNome ?? 'Sem Responsavel'}
                                    </Typography>
                                    <Typography
                                        color='textPrimary'
                                        variant='body2'>
                                        {(model.responsavel?.revendaNome && model.responsavel?.informacoes?.telefone) ? `${model.responsavel.informacoes.telefone}` : ''}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box>
                                    <Typography
                                        color="textPrimary"
                                        variant="caption"
                                    >
                                        Município
                                    </Typography>
                                    <Typography
                                        color="textPrimary"
                                        variant="body2"
                                    >
                                        {model?.cliente?.informacoes?.municipio}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box>
                                    <Typography
                                        color="textPrimary"
                                        variant="caption"
                                    >
                                        Estado
                                    </Typography>
                                    <Typography
                                        color="textPrimary"
                                        variant="body2"
                                    >
                                        {model?.cliente?.informacoes?.uf}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box>
                                    <Typography
                                        color="textPrimary"
                                        variant="caption"
                                    >
                                        Interesse
                                    </Typography>
                                    <Typography
                                        color="textPrimary"
                                        variant="body2"
                                        style={{ wordBreak: 'break-all' }}
                                    >
                                        {model?.instrucao}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                ) : (
                    <>
                        <Box p={1} width="100%" >
                            <Grid container spacing={1}>
                                <Box width="100%">
                                    <Box display="flex" justifyContent={"space-between"} alignItems="center" paddingX={1}>
                                        <Box display='flex' gridGap={theme.spacing(3)}>
                                            <Box display='flex' flexDirection='column'>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    Criação
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    style={{ fontWeight: 600, color: theme.palette.grey[600], fontSize: 13 }}
                                                >
                                                    {toDateString(model.data)}
                                                </Typography>
                                            </Box>
                                            {!isEmpty(model.dataUltimoRetorno) && <Box display='flex' flexDirection='column'>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    Último Retorno
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    style={{ fontWeight: 600, color: theme.palette.grey[600], fontSize: 13 }}
                                                >
                                                    {toDateString(model.dataUltimoRetorno)}
                                                </Typography>
                                            </Box>}
                                        </Box>
                                        <Box display='flex' gridGap={theme.spacing(3)} alignItems="center">
                                            {model.dataLimite && <TooltipWarningWrapper dataLimite={model.dataLimite} mostrarTooltip={mostrarWarning}>
                                                <Box display='flex' flexDirection='column'>
                                                    <Typography
                                                        variant="caption"
                                                    >
                                                        Data Limite
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        style={{ fontWeight: 600, color: theme.palette.grey[600], fontSize: 13 }}
                                                    >
                                                        {toDateString(model.dataLimite)}
                                                    </Typography>
                                                </Box>
                                            </TooltipWarningWrapper>}
                                            <Typography className={indicacoesStyles.statusText} style={{
                                                backgroundColor: retornarCorStatus(model.status),
                                                paddingLeft: '8px',
                                                paddingRight: '8px',
                                            }}>
                                                {retornaStatus()}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <div className={`${classes.cardContent} ${indicacoesStyles.container}`} style={{ padding: '8px 0px', height: '100%' }}>
                                    <div className={`${classes.celulaGridFull} ${indicacoesStyles.item}`} style={{
                                        minWidth: 250,
                                        maxWidth: 250,
                                    }}>
                                        <Box display="flex" >
                                            <Typography
                                                variant="body2"
                                                color="textPrimary"
                                                style={{
                                                    minWidth: 130,
                                                    maxWidth: 130
                                                }}
                                            >
                                                {formatarCPFCNPJ(model?.cliente?.cpfCnpj)}
                                            </Typography>
                                            <Tooltip arrow title={model.cliente.nomeComercial}>
                                                <Typography
                                                    variant="body2"
                                                    color="textPrimary"
                                                >
                                                    <b>{model?.cliente?.nomeComercial}</b>
                                                </Typography>
                                            </Tooltip>
                                        </Box>
                                    </div>
                                    <div className={`${classes.celulaGridFull} ${indicacoesStyles.item}`} style={{
                                        minWidth: 170,
                                        maxWidth: 170,
                                    }}>
                                        <Box display='flex' flexDirection='column'>
                                            {model.indicador?.informacoes && <Typography
                                                variant='body2'
                                                color='textPrimary'>
                                                {model.indicador.informacoes.telefone}
                                            </Typography>}
                                            <Typography
                                                variant="body2"
                                                color="textPrimary"
                                            >
                                                Indicador: {model.indicador.nomeComercial}
                                            </Typography>
                                        </Box>
                                    </div>
                                    <div className={`${classes.celulaGridFull} ${indicacoesStyles.item}`} style={{
                                        minWidth: 170,
                                        maxWidth: 170,
                                    }}>
                                        <Box display='flex' flexDirection='column'>
                                            {model.responsavel?.informacoes && <Typography
                                                variant='body2'
                                                color='textPrimary'>
                                                {model.responsavel.informacoes.telefone}
                                            </Typography>}
                                            <Typography
                                                variant="body2"
                                                color="textPrimary"
                                            >
                                                Responsável: {isEmpty(model?.responsavel?.nomeComercial) ? 'Sem Responsável' : model?.responsavel?.nomeComercial}
                                            </Typography>
                                        </Box>
                                    </div>
                                    <div className={`${classes.celulaGrid} ${indicacoesStyles.item}`} style={{
                                        minWidth: 80,
                                        maxWidth: 80,
                                    }}>
                                        <Box>
                                            <Typography
                                                color="textPrimary"
                                                variant="caption"
                                            >
                                                Município
                                            </Typography>
                                            <Typography
                                                color="textPrimary"
                                                variant="body2"
                                            >
                                                {model?.cliente.informacoes?.municipio}
                                            </Typography>
                                        </Box>
                                    </div>
                                    <div className={`${classes.celulaGrid} ${indicacoesStyles.item}`} style={{
                                        minWidth: 50,
                                        maxWidth: 50,
                                    }}>
                                        <Box>
                                            <Typography
                                                color="textPrimary"
                                                variant="caption"
                                            >
                                                Estado
                                            </Typography>
                                            <Typography
                                                color="textPrimary"
                                                variant="body2"
                                            >
                                                {model?.cliente.informacoes?.uf}
                                            </Typography>
                                        </Box>
                                    </div>
                                    <Tooltip arrow title={model?.instrucao}>
                                        <div className={`${classes.celulaGridFull} ${indicacoesStyles.item}`} style={{
                                            minWidth: 220,
                                            maxWidth: 220,
                                        }}>
                                            <Box>
                                                <Typography
                                                    color="textPrimary"
                                                    variant="caption"
                                                >
                                                    Interesse
                                                </Typography>
                                                <Typography
                                                    color="textPrimary"
                                                    variant="body2"
                                                >
                                                    {model?.instrucao}
                                                </Typography>
                                            </Box>
                                        </div>
                                    </Tooltip>
                                    <Tooltip arrow title="Editar Indicação">
                                        <div className={indicacoesStyles.cardRightButton} style={{ marginLeft: '10px', height: '100%' }}>
                                            <EditarIcon tipo="BUTTON" fill={theme.palette.primary.main} />
                                        </div>
                                    </Tooltip>
                                </div>
                            </Grid>
                        </Box>
                    </>
                )}
            </DefaultCard >
        </>
    );
}