import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Grid, Button, Typography } from '@material-ui/core';
import { useFormPessoaSocioValidation } from './form-pessoa-socio-validation';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  TextFieldSaurus,

} from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PessoaSocioModel } from 'model/api/gestao/pessoa/pessoa-socio-model';
import { picker } from 'utils/picker';
import { useStyles } from './form-pessoa-socio-styles'
import { UFMock } from 'data/mocks';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { useConsultaCEP } from 'data/api/wsmaster';
import { useToastSaurus } from 'services/app';
import { isEmpty } from 'lodash';

interface PessoaFormModelProps extends DefaultFormProps<PessoaSocioModel> {
  revenda?: boolean;
  modal?: boolean;
  block?: boolean;
}


export const FormPessoaSocio = forwardRef<
  DefaultFormRefs<PessoaSocioModel>,
  PessoaFormModelProps
>(
  (
    { loading, onSubmit, revenda = false, modal = false, block = false, ...props }: PessoaFormModelProps,
    ref,
  ) => {
    const { isMobile } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const classes = useStyles()
    const {consultaCEP, carregando: carregandoCEP} = useConsultaCEP()
    const {showToast} = useToastSaurus();

    const refInputNome = useRef<HTMLInputElement>(null);
    const { FormPessoaSocioValidationYup } = useFormPessoaSocioValidation(revenda)
    const [modelForm, setModelForm] = useState<PessoaSocioModel>(new PessoaSocioModel())


    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
      setValue,
      getValues
    } = useForm<PessoaSocioModel>({
      defaultValues: { ...modelForm },
      resolver: yupResolver(FormPessoaSocioValidationYup),
      criteriaMode: 'all',
      mode: 'onBlur' && 'onChange',
    });

    const carregando = loading || carregandoCEP

    const submitEnviar = (values: PessoaSocioModel) => {
      const socio = picker<PessoaSocioModel>(values, modelForm)
      onSubmit(socio, modelForm);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitEnviar)();
      },
      resetForm: () => {
        reset();
        if (!isMobile) refInputNome.current?.focus();
      },
      fillForm: (model: PessoaSocioModel) => {
        setModelForm({ ...model })
        reset({ ...model });
        if (!isMobile) refInputNome.current?.focus();
      },
    }));

    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {carregando && props.showLoading && (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="FULLSIZED" />
              </div>
            )}

            <form
              onSubmit={handleSubmit(submitEnviar)}
              className={loading ? utilClasses.controlLoading : ''}
              style={{padding: !isMobile ? '1rem' : '10px'}}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={revenda && !modal ? 6 : 12}>
                  <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12}>
                      <Controller
                        name="nome"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            inputRef={refInputNome}
                            disabled={loading}
                            readOnly={block}
                            allowSubmit={false}
                            id="nome"
                            label="Nome Completo"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.nome
                                ? errors.nome.message
                                : undefined
                            }
                            error={Boolean(errors.nome && errors.nome.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="cpf"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            disabled={loading}
                            readOnly={block}
                            allowSubmit={false}
                            tipo="CPF"
                            label="CPF"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              touchedFields.cpf && errors.cpf
                                ? errors.cpf.message
                                : undefined
                            }
                            error={Boolean(errors.cpf && errors.cpf.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            disabled={loading}
                            readOnly={block}
                            allowSubmit={false}
                            tipo="EMAIL"
                            label="E-mail"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.email
                                ? errors.email.message
                                : undefined
                            }
                            error={Boolean(touchedFields.email && errors.email && errors.email.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={revenda ? 6 : 12}>
                      <Controller
                        name="fone"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            disabled={loading}
                            readOnly={block}
                            allowSubmit={false}
                            tipo="TELEFONE"
                            label="Telefone/Celular"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.fone
                                ? errors.fone.message
                                : undefined
                            }
                            error={Boolean(touchedFields.fone && errors.fone && errors.fone.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    {revenda && <>
                      <Grid item xs={6}>
                        <Controller
                          name="dataNascimento"
                          control={control}
                          render={({ field }) => (
                            <TextFieldSaurus
                              disabled={loading}
                              allowSubmit={false}
                              tipo="DATA"
                              label="Data de Nascimento"
                              fullWidth
                              autoComplete={'off'}
                              helperText={
                                errors.dataNascimento
                                  ? errors.dataNascimento.message
                                  : undefined
                              }
                              error={Boolean(errors.dataNascimento && errors.dataNascimento.message)}
                              {...field}
                              value={!isEmpty(getValues('dataNascimento')) ? getValues('dataNascimento').split('T')[0] : ''}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="nomeMae"
                          control={control}
                          render={({ field }) => (
                            <TextFieldSaurus
                              disabled={loading}
                              allowSubmit={false}
                              tipo="TEXTO"
                              label="Nome da Mãe (Completo)"
                              fullWidth
                              autoComplete={'off'}
                              helperText={
                                errors.nomeMae
                                  ? errors.nomeMae.message
                                  : undefined
                              }
                              error={Boolean(errors.nomeMae && errors.nomeMae.message)}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </>}

                  </Grid>
                </Grid>
                {revenda && <Grid item xs={12} md={!modal ? 6 : 12}>
                  <Grid container spacing={2} className={classes.enderecoContainer}>
                    <Grid item xs={12} className={classes.enderecoTitle}>
                      <Typography variant='h5' color='textSecondary'>Endereço</Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Controller
                        name="logradouro"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            disabled={loading}
                            placeholder='Ex: Rua Ipiranga'
                            allowSubmit={false}
                            label="Endereço"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.logradouro
                                ? errors.logradouro.message
                                : undefined
                            }
                            error={Boolean(errors.logradouro && errors.logradouro.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Controller
                        name="cep"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            disabled={loading}
                            allowSubmit={false}
                            tipo="CEP"
                            label="CEP"
                            placeholder='Ex: 99999-999'
                            searchable
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              touchedFields.cep && errors.cep
                                ? errors.cep.message
                                : undefined
                            }
                            error={Boolean(errors.cep && errors.cep.message)}
                            {...field}
                            onSearch={async (value: string) => {
                              try {
                                let res = await consultaCEP(value);

                                setValue('cep', res.CEP)
                                setValue('municipio', res.Municipio)
                                setValue('logradouro', res.Logradouro)
                                setValue('bairro', res.Bairro)
                                setValue('uf', res.UF)
                              }
                              catch (err: any) {
                                showToast('error', err.message)
                              }
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="numero"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            disabled={loading}
                            allowSubmit={false}
                            tipo="TEXTO"
                            label="Número"
                            placeholder='Ex: 10'
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.numero
                                ? errors.numero.message
                                : undefined
                            }
                            error={Boolean(errors.numero && errors.numero.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="bairro"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            disabled={loading}
                            allowSubmit={false}
                            label="Bairro"
                            placeholder='Ex: Tatuapé'
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.bairro
                                ? errors.bairro.message
                                : undefined
                            }
                            error={Boolean(errors.bairro && errors.bairro.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="uf"
                        control={control}
                        render={({ field }) => (
                          <SelectSaurus
                            conteudo={UFMock}
                            disabled={loading}
                            allowSubmit={false}
                            label="UF"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.uf
                                ? errors.uf.message
                                : undefined
                            }
                            error={Boolean(errors.uf && errors.uf.message)}
                            {...field}
                            value={UFMock.find(uf => uf.Value === getValues('uf'))?.Key}
                            onChange={(event: any) => {
                              if (event) {
                                const item = UFMock.filter(
                                  (item) => item.Key === event.target.value,
                                )[0];
                                if (item) {
                                  setValue('uf', item.Value);
                                }
                              }
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="municipio"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            disabled={loading}
                            allowSubmit={false}
                            tipo="TEXTO"
                            label="Município"
                            placeholder='Ex: São Paulo'
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.municipio
                                ? errors.municipio.message
                                : undefined
                            }
                            error={Boolean(errors.municipio && errors.municipio.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="complemento"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            disabled={loading}
                            allowSubmit={false}
                            label="Complemento"
                            placeholder='Ex: Bloco 3, apartamento 05'
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              touchedFields.complemento && errors.complemento
                                ? errors.complemento.message
                                : undefined
                            }
                            error={Boolean(errors.complemento && errors.complemento.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>}
              </Grid>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
