
import { useMemo } from 'react';
import { useValidationYupDocumento } from 'views/components/form-validations';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

export const useFormFilialValidation = () => {

  const { documentoYup } = useValidationYupDocumento(false)

  const formFilialEditValidationYup = useMemo(() => {
    return Yup.object().shape<AnyObject>({
      id: Yup.string().required(),
      contratoId: Yup.string().required(),
      status: Yup.boolean().required(),
      documento: documentoYup()
    });
  }, [documentoYup])

  return {
    formFilialEditValidationYup,
  }
}

