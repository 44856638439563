import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useMemo } from 'react';
import { guidEmpty } from 'utils/guid-empty';
import { ClassificacaoModel } from 'model/api/gestao/classificacao/classificacao-model';
import { ClassificacoesEditar } from './components/classificacoes-editar/classificacoes-editar';
import { ClassificacoesCriar } from './components/classificacoes-criar/classificacoes-criar';

interface DialogClassificacaoProps {
    model: ClassificacaoModel;
    closeDialog: () => void;
    openned: boolean;
}

export const DialogClassificacao = ({
    model,
    openned,
    closeDialog
}: DialogClassificacaoProps) => {


    const edicao = useMemo<boolean>(() => model.id !== guidEmpty(), [model.id])

    return (
        <DialogSaurus
            aberto={openned}
            titulo={edicao ? 'Editar Classificação' : 'Criar Classificação'}
            tamanho="sm">
                {edicao ? (
                    <ClassificacoesEditar model={model} closeDialog={closeDialog}/>
                ) : (
                    <ClassificacoesCriar closeDialog={closeDialog}/>
                )}
        </DialogSaurus>
    );
};