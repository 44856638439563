import { ClassificacaoModel } from "model/api/gestao/classificacao/classificacao-model";
import { useCallback } from "react";
import { CardNaoEncontrado } from "views/components/cards";
import { CardClassificacao } from "views/components/cards/card-classificacao/card-classificacao";
import { InformacaoIcon } from "views/components/icons";

export interface ClientesGridProps {
  list: Array<ClassificacaoModel>
  carregando: boolean;
  onClick: (model: ClassificacaoModel) => any;
}

export const ClassificacoesListData = (props: ClientesGridProps) => {

  const onClick = useCallback((model: ClassificacaoModel) => {
    props.onClick(model)
  }, [props])

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem={'Nenhuma Classificação cadastrada.'}
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props?.list.length > 0 &&
        props.list.map((classificacao, index) => {
          return (
            <CardClassificacao
              model={classificacao}
              onClick={onClick}
            />
          );
        })}
    </>
  );
};
