import { FaturasModel } from "model/api/gestao/fatura/faturas-model";
import { CardNaoEncontrado } from "views/components/cards";
import { CardNotaFiscalRevenda } from "views/components/cards/card-nota-fiscal-revenda/card-nota-fiscal-revenda";
import { InformacaoIcon } from "views/components/icons";

export interface NotasFiscaisGridProps {
  list: Array<FaturasModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (id: string) => void;
  onCardChecked: (id: string) => any;
}

export const AccordionNotasFiscaisRevendaListData = (props: NotasFiscaisGridProps) => {

  const onCardSelected = (id: string) => {
    props.onCardSelected(id);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhuma Fatura de Revenda Encontrada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props?.list.length > 0 &&
        props.list.map((items, index) => {
          return (
            <CardNotaFiscalRevenda
              selected={
                props.selectedList.filter((item) => item === items.id).length >
                  0
                  ? true
                  : false
              }
              onCheck={onCardChecked}
              onClick={onCardSelected}
              model={items}
              key={index}
            />
          );
        })}
    </>
  );
};
