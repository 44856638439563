import { ClienteIdealPerguntaModel } from 'model/api/gestao/cliente-ideal/cliente-ideal-pergunta-model';
import { DefaultModal } from 'views/components/modals/components/default-modal';
import { PerguntasApuracao } from './components/perguntas-apuracao';

type Props = {
    model: ClienteIdealPerguntaModel;
    finalizar: (model: ClienteIdealPerguntaModel) => Promise<boolean>;
    aberto: boolean;
}

export const PerguntasApuracaoModal = ({
    aberto,
    finalizar,
    model
}: Props) => {
    if (!aberto) return <></>
    return (
        <DefaultModal
            minWidth="400px"
            open={aberto || false}
            variant={"temporary"}
            anchor="right"
        >
            <PerguntasApuracao
                finalizar={finalizar}
                model={model}
            />
        </DefaultModal>
    );
};