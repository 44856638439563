import { Button, Grid } from '@material-ui/core';
import { picker } from 'utils/picker';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useRef, useCallback, useEffect, useState } from 'react';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { useToastSaurus, useCadastros } from 'services/app';
import {
  SalvarNovoIcon,
  VoltarIcon,
} from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import classNames from 'classnames';
import { FormNovoModuloCadastro } from 'views/components/form/master/sistemas/novo-modulo/form-novo-modulo';
import { NovoModuloCadastroFormModel } from 'model/app/forms/sistemas/novo-modulo-cadastro-form-model';
import { ModuloModel } from 'model/api/gestao/sistemas/modulo-model';
import { useGetSistemasById, usePutAtualizarSistema } from 'data/api/gestao/sistemas';
import { SistemaModel } from 'model/api/gestao/sistemas/sistema-model';
import { isEmpty } from 'lodash';
import { guidEmpty } from 'utils/guid-empty';

type Props = {
  sistemaId: string
  modulo: ModuloModel
}

const NovoModuloCadastro = ({ sistemaId, modulo }: Props) => {

  const { getSistemasById, carregando: carregandoGet } = useGetSistemasById()
  const { putAtualizarSistema, carregando: carregandoPut } = usePutAtualizarSistema()
  const { showToast } = useToastSaurus();
  const { fecharCadastroNovoModulo } = useCadastros();
  const cadNovoModuloFormRef =
    useRef<DefaultFormRefs<NovoModuloCadastroFormModel>>(null);
  const [moduloForm, setModuloForm] = useState<NovoModuloCadastroFormModel>(new NovoModuloCadastroFormModel())
  const refModuloModel = useRef<ModuloModel>(modulo)
  const classes = useModalStyles();

  useEffect(() => {
    setModuloForm(modulo)
  }, [modulo])

  useEffect(() => {
    cadNovoModuloFormRef.current?.fillForm(
      moduloForm
    );
  }, [moduloForm]);

  const carregando = carregandoGet || carregandoPut

  const getSistemaWrapper = useCallback(async () => {
    const res = await getSistemasById(sistemaId)

    if (res.erro) throw res.erro

    return res.resultado?.data as SistemaModel
  }, [getSistemasById, sistemaId])

  const saveNewOrChangeModulo = useCallback(
    async (model: SistemaModel) => {

      const validationError = model.modulos.find(item => item.quantidadePadrao > item.quantidadeMaxima || item.quantidadePadrao < item.quantidadeMinima)

      if (validationError) {
        throw new Error('Quantidade padrão está incorreta.')
      }

      const ret = await putAtualizarSistema(model);
      if (ret.erro) {
        throw ret.erro;
      }

      showToast(
        'success',
        'Novo módulo adicionado!',
        2000,
        'bottom-center',
        60,
      );

    },
    [putAtualizarSistema, showToast],
  );

  const onCloseClick = useCallback(() => {
    fecharCadastroNovoModulo()
  }, [fecharCadastroNovoModulo]);

  const handleSubmit = useCallback(
    async (model: NovoModuloCadastroFormModel) => {
      try {

        const moduloPicker = picker<ModuloModel>(
          model,
          refModuloModel.current
        );

        const sistema = await getSistemaWrapper()

        const temModuloSistema = sistema.modulos.filter(modulo => modulo.id === moduloPicker.id)
        const diferentesModuloSistema = sistema.modulos.filter(modulo => modulo.id !== moduloPicker.id)

        if (!isEmpty(temModuloSistema)) {
          sistema.modulos = [...diferentesModuloSistema, moduloPicker]
        }
        else {
          sistema.modulos = [...sistema.modulos, moduloPicker]
        }

        await saveNewOrChangeModulo(sistema);

        onCloseClick()
        if (moduloPicker.id === guidEmpty()) {
          showToast('success', "Novo Módulo Adicionado!")
        } else {
          showToast('success', "Módulo Atualizado!")
        }
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [getSistemaWrapper, onCloseClick, saveNewOrChangeModulo, showToast],
  )

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title='Novo Módulo'
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}
        >
          <FormNovoModuloCadastro
            ref={cadNovoModuloFormRef}
            showLoading={false}
            loading={carregando}
            onSubmit={handleSubmit}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                disabled={carregando}
                onClick={() => {
                  cadNovoModuloFormRef.current!.submitForm()
                }
                }
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarNovoIcon tipo="BUTTON_PRIMARY" />
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default NovoModuloCadastro;
