import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { ClassificacaoModel } from 'model/api/gestao/classificacao/classificacao-model';

export function useGetPessoaClassificacaoById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ClassificacaoModel>();

    const getPessoaClassificacaoId = useCallback(
        (id: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/pessoa-classificacao/${id}`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getPessoaClassificacaoId,
    };
}