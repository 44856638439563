import { Grid } from '@material-ui/core';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { PerguntasApuracaoHeader } from './components/perguntas-apuracao-header/perguntas-apuracao-header';
import { PerguntasApuracaoList } from './components/perguntas-apuracao-list/perguntas-apuracao-list';

export const PerguntasApuracaoPage = () => {
    const classes = useDefaultCadastroStyles();

    return (
        <Grid className={classes.root}>
            <Grid className={classes.header}>
                <PerguntasApuracaoHeader />
            </Grid>
            <Grid className={classes.list}>
                <PerguntasApuracaoList />
            </Grid>
        </Grid>
    );
};

export default PerguntasApuracaoPage;