import { PessoaComissaoModel } from "model/api/gestao/pessoa/pessoa-comissao-model";
import { CardNaoEncontrado } from "views/components/cards";
import { CardPessoaComissao } from "views/components/cards/card-pessoa-comissao";
import { InformacaoIcon } from "views/components/icons";

export interface PessoaComissaoListDataProps {
  list: Array<PessoaComissaoModel>;
  carregando: boolean;
  onCardSelected: (model: PessoaComissaoModel) => void;
  onCardChecked: (id: string) => any;
}

export const AccordionRevendedorComissaoListData = (props: PessoaComissaoListDataProps) => {

  const onCardClick = (model: PessoaComissaoModel) => {
    props.onCardSelected(model);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhuma Comissão vinculada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props?.list.length > 0 &&
        props.list.map(items => {
          return (
            <CardPessoaComissao
                model={items}
                onCheck={onCardChecked}
                onClick={onCardClick}
            />
          );
        })}
    </>
  );
};
