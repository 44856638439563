import { guidEmpty } from 'utils/guid-empty';

export class PerguntaApuracaoFormModel {
    constructor(
        public id: string = guidEmpty(),
        public pergunta: string = '',
        public detalhe: string = '',
        public interna: boolean = false,
        public ativo: boolean = true,
        public maxPontos: number = 10,
    ) { }
}