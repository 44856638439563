import { useCallback } from 'react';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles/buttons';
import { DesfazerIcon, FiltroIcon, MenuIcon } from 'views/components/icons';
import { useMenuPrincipal } from 'services/app';
import { useThemeQueries } from 'views';
import { useHeaderStyles } from 'views/components/headers/header-private-page/header-styles';
import { SetaCimaIcon } from 'views/components/icons/seta-cima-icon';

export interface ComissoesHeaderProps {
  openPesquisa: boolean;
  setOpenPesquisa: (state: boolean) => any;
}

export const ComissoesHeader = ({
  openPesquisa,
  setOpenPesquisa,
}: ComissoesHeaderProps) => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile, theme } = useThemeQueries();
  const classes = useHeaderStyles();

  const leftArea = useCallback(
    () =>
      isMobile ? (
        <ButtonPrivateHeader
          icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
          tooltip="Menu"
          onClick={abrirMenu}
        ></ButtonPrivateHeader>
      ) : null,
    [isMobile, abrirMenu],
  );

  const rightArea = useCallback(
    () => isMobile ? (
      <ButtonPrivateHeader
        icon={
          openPesquisa ? (
            <DesfazerIcon tipo="PRIVATE_HEADER"></DesfazerIcon>
          ) : (
            <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>
          )
        }
        tooltip={!openPesquisa ? 'Pesquisar' : 'Voltar'}
        onClick={() => setOpenPesquisa(!openPesquisa)}
      ></ButtonPrivateHeader>
    ) : (
      <ButtonPrivateHeader
        className={classes.pesquisaBox}
        text={!openPesquisa ? 'Filtros' : 'Fechar Filtros'}
        icon={
          openPesquisa ? (
            <SetaCimaIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main}></SetaCimaIcon>
          ) : (
            <FiltroIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main}></FiltroIcon>
          )
        }
        tooltip={!openPesquisa ? "Pesquisar" : "Voltar"}
        onClick={() => setOpenPesquisa(!openPesquisa)}
      ></ButtonPrivateHeader>
    ),
    [isMobile, openPesquisa, classes.pesquisaBox, theme.palette.primary.main, setOpenPesquisa],
  );

  return (
    <>
      <PrivatePageHeader
        title="Comissões"
        leftArea={leftArea()}
        rightArea={rightArea()}
      />
    </>
  );
};
