import { makeStyles } from "@material-ui/core";
export const useRevendedorStyles = makeStyles((theme) => ({
  defaultContainer: {
    position: "relative",
    width: '100%',
    margin: '0 11rem',
    borderRadius: 8,
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.down('lg')]: {
      margin: '0 10rem',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0 1rem',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 .5rem'
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0'
    }
  },
}));