import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  defaultContainer: {
    position: "relative",
    maxWidth: 900,
    background: '#FFF',
    borderRadius: 8,
    overflowY: "auto",
    overflowX: "hidden",
  },
  listContainer: {
    "& .card": {
      margin: theme.spacing(1),
    }
  },
  list: {
    padding: 16,
    overflowX: "hidden",
    overflow: "auto",
    position: "relative",
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  contentForms: {
    flex: "1 1 auto",
    padding: theme.spacing(2),
    overflowX: "hidden",
    overflowY: "auto",
    "& * > form": {
      visibility: "visible",
    },
    "&  .tabs-class": {
      visibility: "visible",
    },
  },
  contentFormsLoading: {
    "& * > form": {
      visibility: "hidden",
    },

    "&  .tabs-class": {
      visibility: "hidden",
    },
  },
  acoes: {
    flex: "0 0 auto",
    padding: theme.spacing(2),
  },
  btnPesquisa: {
    marginLeft: theme.spacing(1),
    width: "40px",
    minWidth: "40px",
    height: "40px",
    padding: 0,
    "& svg": {
      margin: 0,
    }
  }
}));
