import { RelatorioSaldoModel } from 'model/api/gestao/relatorios/relatorio-saldo-model';
import { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useThemeQueries } from 'views/theme';

type Props = {
    list: RelatorioSaldoModel[]
    carregarProximos: () => void
    stacked: boolean;
    width?: number;
}

export default function ChartSaldo({ list, carregarProximos, stacked = false, width }: Props) {

    const {isMobile} = useThemeQueries();

    const chartOptions = useMemo(() => {
        const series = list.sort((a, b) => a.data[0].x - b.data[0].x)
        const options: ApexCharts.ApexOptions = {
            chart: {
                zoom: {
                    enabled: false,
                    type: 'x'
                },
                toolbar: {
                    show: false
                },
                type: stacked ? 'area' : 'line',
                height: 350,
                stacked: stacked,
                events: {
                    click: (event, chartContext, config) => {
                        if (config.config.series[config.seriesIndex] && config.config.series[config.seriesIndex].name === 'Outros') {
                            carregarProximos()
                        }
                    },
                    dataPointMouseEnter: (e, chart, opt) => {
                        console.log(chart, opt)
                    }
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'straight',
                width: 2
            },
            fill: {
                type: stacked ? 'gradient' : 'solid',
                gradient: {
                    opacityFrom: stacked ? 0.6 : 1,
                    opacityTo: stacked ? 0.8 : 1,
                }
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
            },
            yaxis: {

            },
            xaxis: {
                labels: {
                    formatter: (value: string) => {
                        if(!value || typeof value !== 'string' || !value.substring) return ''
                        return `${value.substring(4)}/${value.substring(0, 4)}`
                    }
                }

            },
            noData: {
                text: 'Carregando...'
            },
            tooltip: {
                custom(options) {
                    const series = options.w.config.series as RelatorioSaldoModel[]

                    const mesDados = series.map(element => {
                        const thisMonthData = element.data[options.dataPointIndex]

                        return {
                            revenda: element.name,
                            saldo: thisMonthData.y
                        }
                    }).sort((a, b) => b.saldo - a.saldo)

                    return `
                    <ul style='display: flex; flex-direction: column; flex-wrap: wrap; z-index: 9999999; padding: 10px; font-size: 11px'>
                        ${mesDados.reduce((prev, element) => `${prev}<li><span style='font-weight: 500'>${element.revenda}</span>: ${element.saldo}</li>`, '')}
                    </ul>
                    `
                },
            }
        };

        return {
            options,
            series
        }
    }, [carregarProximos, list, stacked])

    return (
        <Chart options={chartOptions.options} series={chartOptions.series} type={stacked ? 'area' : 'line'} width={width || '100%'} height={isMobile || width ? 600 : undefined} />

    )
}