import { Button, Grid } from '@material-ui/core';
import {
  useGetPessoaPagamento,
} from 'data/api/gestao/pessoa-forma-pagamento';
import { ApiListModel } from 'model/api/gestao/api-list-model/api-list-model';
import { PessoaPagamentoModel } from 'model/api/gestao/pessoa/pessoa-pagamento-model';
import { useCallback, useEffect, useState } from 'react';
import { useToastSaurus } from 'services/app';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { DialogPessoaFormaPagamento } from 'views/components/dialog/dialog-pessoa-forma-pagamento/dialog-pessoa-forma-pagamento';
import { NovoIcon } from 'views/components/icons';
import { Paginacao } from 'views/components/paginacao';
import { useThemeQueries } from 'views/theme';
import { RevendedorPagamentoListData } from './accordion-revendedor-pagament-list-data';

type Props = {
  id: string;
};

export const RevendedorPagamentoList = ({ id }: Props) => {

  const [queryStatus, setQueryStatus] = useState<ApiListModel<PessoaPagamentoModel>>({
    pageIndex: 1,
    totalPages: 0,
    totalResults: 0,
    pageSize: 0,
    list: Array<PessoaPagamentoModel>(),
  });

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<PessoaPagamentoModel>
    ) => {
      setQueryStatus({
        pageIndex: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
        pageSize: 0
      });
    },
    []
  );

  const { theme } = useThemeQueries();
  const [openAccordion, setOpenAccordion] = useState<boolean>(false);
  const [selectedModel, setSelectedModel] =
    useState<PessoaPagamentoModel>(
      new PessoaPagamentoModel(),
    );
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const { showToast } = useToastSaurus();
  const { getPessoaPagamento, carregando } =
    useGetPessoaPagamento();

  const getPessoaPagamentoWrapper = useCallback(async (newPage: number) => {
    const res = await getPessoaPagamento(id, newPage, '');

    if (res.erro) throw res.erro;

    if (res.resultado?.data === undefined) {
      return
    }

    //se o index for maior que as paginas ele busca a ultima
    if (
      res.resultado?.data.pageIndex > res.resultado?.data.totalPages &&
      res.resultado?.data.totalResults > 0
    ) {
      getPessoaPagamentoWrapper(res.resultado.data.totalPages);
      return;
    }

    fillResult(
      res.resultado?.data.pageIndex,
      res.resultado?.data.totalPages,
      res.resultado?.data.totalResults,
      res.resultado?.data.list
    );

  }, [fillResult, getPessoaPagamento, id]);

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        getPessoaPagamentoWrapper(newPage);
      }
    },
    [queryStatus.totalPages, getPessoaPagamentoWrapper]
  );

  useEffect(() => {
    if (!openDialog)
      getPessoaPagamentoWrapper(queryStatus.pageIndex).catch((err) => {
        showToast('error', err.message);
      });
  }, [getPessoaPagamentoWrapper, openDialog, queryStatus.pageIndex, showToast]);


  const onClickAdicionar = useCallback(() => {
    setSelectedModel(new PessoaPagamentoModel())
    setOpenDialog(true)
  }, []);

  const onClickAlterar = useCallback((model: PessoaPagamentoModel) => {
    setSelectedModel(model);
    setOpenDialog(true);
  }, [])

  return (
    <>
      <AccordionSaurus
        labelPrimary="Forma de Pagamento"
        tipoExpand="bold"
        noPaperRoot={false}
        heightDivider={2}
        showDivider={openAccordion}
        colorDivider={theme.palette.primary.main}
        colorExpand={theme.palette.primary.main}
        expanded={openAccordion}
        onChange={() => setOpenAccordion((prev) => !prev)}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paginacao
              pageChanged={pageChanged}
              totalPages={queryStatus.totalPages}
              totalRegisters={queryStatus.totalResults}
              currentPage={queryStatus.pageIndex}
            />
          </Grid>
          <Grid item xs={12}>
            <RevendedorPagamentoListData
              list={queryStatus.list}
              carregando={carregando}
              onCardSelected={onClickAlterar}
              onCardChecked={() => { }}
              selectedList={[]}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={7} lg={6}>
                <Button variant="contained" color='primary' fullWidth
                onClick={onClickAdicionar}>
                  <NovoIcon tipo='BUTTON_PRIMARY'/>
                  NOVA FORMA DE PAGAMENTO
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSaurus>
      <DialogPessoaFormaPagamento
        closeModal={() => setOpenDialog(false)}
        id={id}
        loading={carregando}
        model={selectedModel}
        openned={openDialog}
      />
    </>
  );
};
