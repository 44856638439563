import { VariaveisAmbiente } from 'config';
import { PessoaTributoModel } from 'model/api/gestao/pessoa/pessoa-tributo-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePutAtualizarPessoaTributo() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<PessoaTributoModel>();

    const putAtualizarPessoaTributo = useCallback(
        (pessoaId: string, model: PessoaTributoModel) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/pessoa/${pessoaId}/tributo`,
                method: 'PUT',
                data: model,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putAtualizarPessoaTributo,
    };
}
