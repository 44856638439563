import { VariaveisAmbiente } from 'config';
import { TerminalModel } from 'model/api/gestao/terminal/terminal-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePutAtivarTerminal() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putAtivarTerminal = useCallback(
        (model: TerminalModel) =>
            invocarApi({
                method: 'PUT',
                url: `/${VariaveisAmbiente.apiVersion}/terminal/ativar`,
                enviarTokenUsuario: true,
                data: model
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putAtivarTerminal,
    };
}
