import { PessoaSistemaModel } from "model/api/gestao/pessoa/pessoa-sistema-model";
import { CardNaoEncontrado } from "views/components/cards";
import { CardPessoaSistema } from "views/components/cards/card-pessoa-sistema";
import { InformacaoIcon } from "views/components/icons";

export interface ClienteRevendedorGridProps {
  list: Array<PessoaSistemaModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (model: PessoaSistemaModel) => void;
  onCardChecked: (id: string) => any;
}

export const AccordionRevendedorPrecosListData = (props: ClienteRevendedorGridProps) => {

  const onCardSelected = (model: PessoaSistemaModel) => {
    props.onCardSelected(model);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhum Sistema vinculado."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props?.list.length > 0 &&
        props.list.map((items, index) => {
          return (
            <CardPessoaSistema
              selected={
                props.selectedList.filter((item) => item === items.id).length >
                  0
                  ? true
                  : false
              }
              onCheck={onCardChecked}
              onClick={onCardSelected}
              model={items}
              key={index}
            />
          );
        })}
    </>
  );
};
