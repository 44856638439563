import { Button, Grid } from "@material-ui/core"
import { PessoaPostModel } from "model/api/gestao/pessoa/pessoa-post-model"
import { useCallback, useRef, useState } from "react"
import { useToastSaurus } from "services/app"
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { FormPessoaSenha } from "views/components/form/master/pessoa/pessoa-senha/form-pessoa-senha"
import { useThemeQueries } from "views/theme"
import { DefaultFormRefs } from "views/components/form/utils"
import { PessoaSenhaModel } from "model/api/gestao/pessoa/pessoa-senha-model"
import { usePutAtualizarPessoa } from "data/api/gestao/pessoa"
import { useStyles } from '../../../clientes/components/clientes-editar/components/cliente-edit/cliente-edit-styles';
import classNames from "classnames"
import { SalvarIcon } from "views/components/icons"

interface Props {
    model: PessoaPostModel
    preencherTela: () => Promise<void>
}

export const AccordionConfiguracoesSenha = ({ model, preencherTela }: Props) => {

    const { theme } = useThemeQueries()
    const classes = useStyles()

    const [openAccordion, setOpenAccordion] = useState<boolean>(false)

    const { showToast } = useToastSaurus()
    const { putAtualizarPessoa, carregando } = usePutAtualizarPessoa()

    const formRef = useRef<DefaultFormRefs<PessoaSenhaModel>>(null)

    const saveChanges = useCallback(async (values: PessoaPostModel) => {

        const res = await putAtualizarPessoa(values)

        if (res.erro) throw res.erro

    }, [putAtualizarPessoa])

    const handleSubmit = useCallback(async (actualModel: PessoaSenhaModel) => {
        try {
            const pessoa = model
            pessoa.senha = actualModel.novaSenha

            await saveChanges(pessoa)

            await preencherTela()

            formRef.current?.resetForm()

            showToast('success', 'Senha Alterada!')
        }
        catch (err: any) {
            showToast('error', err.message)
        }

    }, [model, preencherTela, saveChanges, showToast])

    return (
        <AccordionSaurus
            labelPrimary="Alterar Senha"
            tipoExpand="bold"
            noPaperRoot={false}
            heightDivider={2}
            showDivider={openAccordion}
            colorDivider={theme.palette.primary.main}
            colorExpand={theme.palette.primary.main}
            expanded={openAccordion}
            onChange={() => setOpenAccordion(prev => !prev)}
        >
            <div className={classes.content}>
                <div
                    className={classNames(
                        classes.contentForms,
                        carregando ? classes.contentFormsLoading : undefined,
                    )}
                >
                    <FormPessoaSenha
                        onSubmit={handleSubmit}
                        loading={carregando}
                        showLoading={true}
                        ref={formRef}
                    />
                </div>
                <div className={classes.acoes}>
                    <Grid container spacing={2} justifyContent='flex-end'>
                        <Grid item xs={12} md={3}>
                            <Button
                                disabled={carregando}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => formRef.current?.submitForm()}
                            >
                                <SalvarIcon tipo="BUTTON_PRIMARY"/>
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </AccordionSaurus >
    )
}