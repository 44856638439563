import { RelatorioChartMixedModel } from 'model/api/gestao/relatorios/relatorio-model';
import { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { toCurrency } from 'utils/toCurrency';
import { useThemeQueries } from 'views/theme';

type Props = {
    list: RelatorioChartMixedModel[]
    width?: number;
    meses: number;
}

export default function ChartReceita({ list, width, meses }: Props) {

    const { theme } = useThemeQueries();

    const chartOptions = useMemo(() => {
        const series = list
        console.log(series)
        const options: ApexCharts.ApexOptions = {
            chart: {
                zoom: {
                    enabled: false,
                    type: 'x'
                },
                toolbar: {
                    show: false
                },
                type: 'line',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            colors: [theme.palette.success.light, theme.palette.info.dark],
            dataLabels: {
                enabled: true,
                enabledOnSeries: [1],
                formatter(val, opts) {
                    return `${val}%`
                },
            },
            stroke: {
                show: true,
                width: 2,
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    datetimeFormatter: {
                        day: '',
                        month: 'MM/yyyy',
                        hour: '',
                        minute: '',
                        second: '',
                        year: 'MM/yyyy'
                    }
                }
            },
            yaxis: [
                {

                    labels: {
                        formatter(val, opts) {
                            return toCurrency(val)
                        },
                    }
                },
                {
                    opposite: true,
                    labels: {
                        formatter(val, opts) {
                            return `${val}%`
                        },
                    }
                }
            ],
            fill: {
                opacity: 1
            },
            tooltip: {
                y: [
                    {
                        formatter(val, opts) {
                            return toCurrency(val)
                        },
                    },
                    {
                        formatter(val, opts) {
                            return `${val}%`
                        },
                    }
                ]
            }
        };

        return {
            options,
            series
        }
    }, [list, theme.palette.info.dark, theme.palette.success.light])

    return (
        <Chart options={chartOptions.options} series={chartOptions.series} type='line' width={width || '100%'} height={600} />

    )
}