import { Button, Grid } from '@material-ui/core';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import {
    SalvarNovoIcon,
    VoltarIcon,
} from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import classNames from 'classnames';
import { useCallback, useEffect, useRef } from 'react';
import { usePostAdicionarParametroSistema } from 'data/api/gestao/parametro-sistema';
import { useCadastros, useToastSaurus } from 'services/app';
import { ParametroSistemaModel } from 'model/api/gestao/parametro-sistema/parametro-sistema-model';
import { DefaultFormRefs } from 'views/components/form/utils';
import { FormParametro } from 'views/components/form/master/sistemas/parametro/form-parametro';

type Props = {
    sistemaId: string,
}

export const ParametroCadastro = ({ sistemaId, ...props }: Props) => {


    const classes = useModalStyles();

    const { fecharCadastroParametro } = useCadastros()

    const refForm = useRef<DefaultFormRefs<ParametroSistemaModel>>(null)
    const refNovo = useRef<boolean>(false)

    const { postAdicionarParametroSistema, carregando: carregandoPost } = usePostAdicionarParametroSistema()

    const { showToast } = useToastSaurus()

    useEffect(() => {
        refForm.current?.fillForm(new ParametroSistemaModel())
    }, [])

    const carregando = carregandoPost

    const saveNew = useCallback(async (model: ParametroSistemaModel) => {
        const res = await postAdicionarParametroSistema(sistemaId, model)

        if (res.erro) throw res.erro

        showToast('success', 'Parâmetro adicionado!')
    }, [postAdicionarParametroSistema, showToast, sistemaId])

    const handleSubmit = useCallback(async (model: ParametroSistemaModel) => {
        try {
            await saveNew(model)
            if (refNovo.current) {
                refForm.current?.resetForm()
                return
            }
            fecharCadastroParametro()
        }
        catch (err: any) {
            showToast('error', err.message)
        }
    }, [fecharCadastroParametro, saveNew, showToast])


    return (
        <div className={classes.root}>
            {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
            <ModalHeader
                title='Cadastrar Parâmetro'
                leftArea={
                    <ButtonModalHeader
                        tooltip="Voltar"
                        icon={<VoltarIcon tipo="MODAL_HEADER" />}
                        onClick={() => fecharCadastroParametro()}
                    />
                }
            />
            <div className={classes.content}>
                <div
                    className={classNames(
                        classes.contentForms,
                        carregando ? classes.contentFormsLoading : undefined,
                    )}
                >
                    <FormParametro
                        ref={refForm}
                        loading={carregando}
                        showLoading={carregando}
                        onSubmit={handleSubmit}
                    />
                </div>
                <div className={classes.acoes}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={7}>
                            <Button
                                disabled={carregando}
                                onClick={() => {
                                    refNovo.current = true
                                    refForm.current?.submitForm()
                                }}
                                variant="outlined"
                                color="primary"
                                size="large"
                                fullWidth
                            >
                                <SalvarNovoIcon tipo="BUTTON" />
                                Salvar e Adicionar Novo
                            </Button>
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <Button
                                disabled={carregando}
                                onClick={() => {
                                    refNovo.current = false
                                    refForm.current?.submitForm()
                                }}
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                            >
                                <SalvarNovoIcon tipo="BUTTON_PRIMARY" />
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
};
