
import { EnumSistemas } from 'model/enums/enum-sistemas';
import { useMemo } from 'react';
import * as Yup from 'yup';


export const useFormWhitelabelValidation = (sistema: EnumSistemas) => {

    const FormWhitelabelValidationYup = useMemo(() => {

        const yupObj: any = {
            nome: Yup.string().required('Nome é obrigatório.').typeError('Nome é obrigatório.'),
            urlIcone: Yup.string().required('Icone é Obrigatório.').typeError('Icone é Obrigatório.'),
            urlImagemPrimaria: Yup.string().required('Imagem é Obrigatória.').typeError('Imagem é Obrigatória.'),
            urlImagemSecundaria: Yup.string().required('Imagem é Obrigatória').typeError('Imagem é Obrigatória'),
            corPrincipal: Yup.string().required('Informe a Cor Primária').typeError('Informe a Cor Primária'),
            corSecundaria: Yup.string().required('Informe a Cor Secundária').typeError('Informe a Cor Secundária'),
            corTerciaria: Yup.string().required('Informe a Cor Terciaria').typeError('Informe a Cor Terciaria'),
            urlCssProprio: Yup.string().required('URL de CSS é obrigatória').typeError('URL de CSS é obrigatória'),
            urlJsProprio: Yup.string().required('URL de JavaScript é obrigatória').typeError('URL de JavaScript é obrigatória'),
            urlHtmlProprio: Yup.string().required('URL de HTML é obrigatória').typeError('URL de HTML é obrigatória'),
        }

        const retornarValidacao = () => {
            const model = { ...yupObj }
            switch (sistema) {
                case EnumSistemas.PDV_APP:
                    delete model['urlHtmlProprio'];
                    delete model['urlJsProprio'];
                    delete model['urlCssProprio'];
                    return Yup.object().shape(model);
                case EnumSistemas.RETAGUARDA_APP:
                    delete model['corPrincipal'];
                    delete model['corSecundaria'];
                    delete model['corTerciaria']
                    delete model['urlImagemPrimaria'];
                    delete model['urlImagemSecundaria'];
                    delete model['urlIcone'];
                    return Yup.object().shape(model)
                case EnumSistemas.TOUCHONE:
                    delete model['corTerciaria'];
                    delete model['urlImagemSecundaria'];
                    delete model['urlCssProprio'];
                    delete model['urlJsProprio'];
                    delete model['urlHtmlProprio'];
                    return Yup.object().shape(model)
                case EnumSistemas.MOBILE:
                    delete model['urlHtmlProprio'];
                    delete model['urlJsProprio'];
                    delete model['urlCssProprio'];
                    delete model['corTerciaria'];
                    return Yup.object().shape(model)
            }
        }

        return retornarValidacao();
    }, [sistema])

    return {
        FormWhitelabelValidationYup,
    }
}