import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    sistemaContainer: {
        background: '#F8F8F8',
        padding: '1rem',
        borderRadius: '15px',
        width: '100%',
        '& .MuiFormControl-root': {
            background: '#fff'
        },
        [theme.breakpoints.down('md')]: {
            padding: '10px',
            marginRight: 0
        }
    },
    textfieldSenhaSync: {
        '& .MuiFormLabel-root': {
            [theme.breakpoints.down('xs')]: {
                fontSize: 14
            }
        }
    }
}))