import { PessoaInfoModel } from "model/api/gestao/pessoa/pessoa-info-model";
import { CardNaoEncontrado } from "views/components/cards";
import { CardPessoaInfo } from "views/components/cards/card-pessoa-info";
import { InformacaoIcon } from "views/components/icons";

export interface ClienteInfoGridProps {
  list: Array<PessoaInfoModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (model: PessoaInfoModel) => void;
  onCardChecked: (id: string) => any;
}

export const AccordionClienteInformacoesListData = (props: ClienteInfoGridProps) => {

  const onCardSelected = (model: PessoaInfoModel) => {
    props.onCardSelected(model);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhuma Informação Cadastrada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props?.list.length > 0 &&
        props.list.map((items, index) => {
          return (
            <CardPessoaInfo
              selected={
                props.selectedList.filter((item) => item === items.id).length >
                  0
                  ? true
                  : false
              }
              onCheck={onCardChecked}
              onClick={onCardSelected}
              model={items}
              key={index}
            />
          );
        })}
    </>
  );
};
