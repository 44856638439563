import { VariaveisAmbiente } from 'config';
import { ContratoDadosModel } from 'model/api/gestao/contrato/contrato-dados-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetNovoContrato() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ContratoDadosModel>();

    const getNovoContrato = useCallback(
        (pessoaId: string, sistemaId: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/pessoas/${pessoaId}/Contrato/sistema/${sistemaId}/novo`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getNovoContrato,
    };
}
