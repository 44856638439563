
import { useMemo } from 'react';
import { useValidationYupDocumento, useValidationYupEmail, useValidationYupFone } from 'views/components/form-validations';
import * as Yup from 'yup';

export const useFormPessoaSocioValidation = (revenda: boolean = false) => {

  const {documentoYup} = useValidationYupDocumento(true)
  const {emailYup} = useValidationYupEmail();
  const foneYup = useValidationYupFone()


  const FormPessoaSocioValidationYup = useMemo(() => {
    return Yup.object().shape({
      nome: Yup.string().required('Nome Completo é obrigatório.'),
      cpf: documentoYup('CPF').notRequired().nullable(true),
      email: emailYup().notRequired().nullable(true),
      fone: foneYup().notRequired().nullable(true).transform(str => str === '' ? null : str),
      dataNascimento: revenda ? Yup.string().required('O campo é obrigatório.').typeError('O campo é obrigatório.') : Yup.string().notRequired().nullable(true),
      nomeMae: revenda ? Yup.string().required('O campo é obrigatório.').typeError('O campo é obrigatório.') : Yup.string().notRequired().nullable(true),
      logradouro: revenda ? Yup.string().required('O campo é obrigatório').typeError('O campo é obrigatório.') : Yup.string().notRequired().nullable(true),
      numero: revenda ? Yup.string().required('O campo é obrigatório').typeError('O campo é obrigatório.') : Yup.string().notRequired().nullable(true),
      complemento: Yup.string().notRequired().nullable(true),
      cep: revenda ? Yup.string().required('O campo é obrigatório').typeError('O campo é obrigatório.') : Yup.string().notRequired().nullable(true),
      bairro: revenda ? Yup.string().required('O campo é obrigatório').typeError('O campo é obrigatório.') : Yup.string().notRequired().nullable(true),
      municipio: revenda ? Yup.string().required('O campo é obrigatório').typeError('O campo é obrigatório.') : Yup.string().notRequired().nullable(true),
      uf: revenda ? Yup.string().required('O campo é obrigatório').typeError('O campo é obrigatório.') : Yup.string().notRequired().nullable(true)
    });
  }, [documentoYup, emailYup, foneYup, revenda])

  return {
    FormPessoaSocioValidationYup,
  }
}

