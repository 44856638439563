import { useEffect, useState, useCallback } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useStyles } from './sistemas-list-styles';
import { SistemasListData } from './sistemas-list-data';
import { Paginacao } from 'views/components/paginacao';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useCadastros, useToastSaurus } from 'services/app';
import { SistemasListSearchProps } from './sistemas-list-search-props';
import { useHistory } from 'react-router';
import { useGetSistemas } from 'data/api/gestao/sistemas';
import { SistemasModel } from 'model/api/gestao/sistemas/sistemas-model';
import { isEmpty } from 'lodash';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';

export interface SistemasListProps {
  searchProps: SistemasListSearchProps;
}

export const SistemasList = (props: SistemasListProps) => {
  const classes = useStyles();
  const listClasses = useDefaultCadastroStyles();
  const { getSistemas, carregando } = useGetSistemas();
  const { cadastroNovoSistemaState } = useCadastros()
  const { showToast } = useToastSaurus();
  const history = useHistory();
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<SistemasModel>(),
  });

  const [selectedList] = useState<Array<string>>([]);

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<SistemasModel>,
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    [],
  );

  const search = useCallback(
    async (newPage: number) => {
      try {
        const res = await getSistemas(
          newPage,
          `${!isEmpty(props.searchProps.termo)
            ? '&query=' + props.searchProps.termo
            : ''}${props.searchProps.situacao > -1
              ? '&situacao=' + props.searchProps.situacao
              : ''}`);
        if (res.erro) throw res.erro;

        if (!res.resultado) return

        //se o index for maior que as paginas ele busca a ultima

        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list,
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [getSistemas, props.searchProps.termo, props.searchProps.situacao, fillResult, showToast],
  );

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [search, queryStatus.totalPages],
  );

  const onCardSelected = (id: string) => {
    history.push('/sistemas/detalhes/' + id)
  };

  useEffect(() => {
    if (!cadastroNovoSistemaState.aberto) {
      search(queryStatus.page);
    }
  }, [search, queryStatus.page, cadastroNovoSistemaState.aberto]);

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Box display='flex' flexDirection='column' height='100%' justifyContent='space-between' flex={1} overflow='hidden'>
          <Grid container className={listClasses.listContainer}>
            <Grid item xs={12}>
              <Paginacao
                pageChanged={pageChanged}
                totalPages={queryStatus.totalPages}
                totalRegisters={queryStatus.totalResults}
                currentPage={queryStatus.page}
              />
            </Grid>
            <Grid container spacing={1}>
              <SistemasListData
                carregando={carregando}
                list={queryStatus.list}
                selectedList={selectedList}
                onCardSelected={onCardSelected}
                onCardChecked={() => { }}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};
