import { useEffect, useState, useCallback } from "react";
import { Box, Grid } from "@material-ui/core";
import { useStyles } from "../../../clientes/components/clientes-list/clientes-list-styles";
import { Paginacao } from "views/components/paginacao";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import { useToastSaurus } from "services/app";
import { ButtonFab } from "views/components/controles";
import { NovoIcon } from "views/components/icons";
import { ApiListModel } from "model/api/gestao/api-list-model/api-list-model";
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles";
import { ClassificacoesListData } from "./classificacoes-list-data";
import { ClassificacaoModel } from "model/api/gestao/classificacao/classificacao-model";
import { useGetPessoaClassificacao } from "data/api/gestao/pessoa-classificacao/get-pessoa-classificacao";
import { DialogClassificacao } from "views/components/dialog/dialog-classificacoes/dialog-classificacoes";
import { isEmpty } from "lodash";

interface ClassificacoesListProps {
    query: string;
}

export const ClassificacoesList = (props: ClassificacoesListProps) => {
    const classes = useStyles();
    const listClasses = useDefaultCadastroStyles();

    const { getPessoaClassificacao, carregando: carregandoGet } = useGetPessoaClassificacao();

    const { showToast } = useToastSaurus();

    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [selectedModel, setSelectedModel] = useState<ClassificacaoModel>(new ClassificacaoModel())


    const [queryStatus, setQueryStatus] = useState<ApiListModel<ClassificacaoModel>>({
        pageIndex: 1,
        totalPages: 0,
        totalResults: 0,
        pageSize: 0,
        list: Array<ClassificacaoModel>(),
    });

    const carregando = carregandoGet

    const fillResult = useCallback(
        async (
            page: number,
            totalPages: number,
            totalResults: number,
            list: Array<ClassificacaoModel>
        ) => {
            setQueryStatus({
                pageIndex: page,
                list: list,
                totalResults: totalResults,
                totalPages: totalPages,
                pageSize: 0
            });
        },
        []
    );

    const search = useCallback(
        async (newPage: number) => {
            try {
                const query = `&pageSize=30${!isEmpty(props.query) ? `&query=${props.query}` : ''}`
                const res = await getPessoaClassificacao(newPage, query);
                if (res.erro) throw res.erro;

                if (res.resultado?.data === undefined) {
                    return
                }

                //se o index for maior que as paginas ele busca a ultima
                if (
                    res.resultado?.data.pageIndex > res.resultado?.data.totalPages &&
                    res.resultado?.data.totalResults > 0
                ) {
                    search(res.resultado.data.totalPages);
                    return;
                }

                fillResult(
                    res.resultado?.data.pageIndex,
                    res.resultado?.data.totalPages,
                    res.resultado?.data.totalResults,
                    res.resultado?.data.list
                );

                return res;
            } catch (e: any) {
                showToast("error", e.message);
            }
        },
        [props.query, getPessoaClassificacao, fillResult, showToast]
    );

    const pageChanged = useCallback(
        async (newPage: number) => {
            if (newPage <= queryStatus.totalPages || newPage > 0) {
                search(newPage);
            }
        },
        [search, queryStatus.totalPages]
    );

    useEffect(() => {
        if(!openDialog)
            search(queryStatus.pageIndex);
    }, [queryStatus.pageIndex, search, openDialog, props.query]);


    const onCardClick = useCallback((model: ClassificacaoModel) => {
        setSelectedModel(model)
        setOpenDialog(true)
    }, [])

    return (
        <>
            <div className={classes.defaultContainer}>
                {carregando && <CircularLoading tipo="FULLSIZED" />}
                <Box display='flex' flexDirection='column' height='100%' justifyContent='space-between' flex={1} overflow='auto' >
                    <Grid container className={listClasses.listContainer}>
                        <Grid item xs={12}>
                            <Paginacao
                                pageChanged={pageChanged}
                                totalPages={queryStatus.totalPages}
                                totalRegisters={queryStatus.totalResults}
                                currentPage={queryStatus.pageIndex}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.listContainer}>
                            <ClassificacoesListData
                                carregando={carregando}
                                list={queryStatus.list}
                                onClick={onCardClick}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <ButtonFab
                tooltip="Nova Classificação"
                icon={<NovoIcon tipo="BUTTON_FAB" />}
                onClick={() => {
                    setSelectedModel(new ClassificacaoModel())
                    setOpenDialog(true)
                }}
            />
            <DialogClassificacao
                closeDialog={() => setOpenDialog(false)}
                model={selectedModel}
                openned={openDialog}
            />
        </>
    )
}