import { Grid, Tooltip, Typography } from '@material-ui/core';
import { SituacaoMock } from 'data/mocks/situacao-mock';
import { isEmpty } from 'lodash';
import { EnumSituacao } from 'model/enums/enum-situacao';
import { useCallback, useMemo } from 'react';
import { toDateString } from 'utils/to-date';
import { EditarIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { CardNotaFiscalRevendaProps } from './card-nota-fiscal-revenda-props';
import { useStyles } from './card-nota-fiscal-revenda-styles'

export const CardNotaFiscalRevenda = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardNotaFiscalRevendaProps) => {
  const classes = useDefaultCardStyles();
  const listClasses = useStyles();
  const { theme, isMobile } = useThemeQueries();


  const retornarCorStatus = useCallback((): string => {
    switch (model.situacao) {
      case EnumSituacao.Aberta:
        return theme.palette.info.main
      case EnumSituacao.Paga:
        return theme.palette.success.main
      case EnumSituacao.Atraso:
        return theme.palette.warning.main
      case EnumSituacao.Bonificada:
        return '#D7096C'
      case EnumSituacao.Cancelada:
        return theme.palette.error.main
      case EnumSituacao.Fechada:
        return '#8346a6'
    }
    return theme.palette.info.main
  }, [model.situacao, theme.palette.error.main, theme.palette.info.main, theme.palette.success.main, theme.palette.warning.main]);

  const descricao = SituacaoMock.filter(
    (x) => x.Key === model.situacao,
  )[0].Value;

  const emissao = useMemo(() => !isEmpty(model.dataEmissao), [model])

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model.id, model.tipoFatura);
        }}
      >
        <Tooltip arrow title={descricao} placement="right">
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        <div className={`${classes.cardContent} ${listClasses.container}`}>
          <Grid container spacing={isMobile ? 1 : 2}>
            <Grid item xs={12} md={3} lg={3}>
              <Typography color="textPrimary" variant="caption">
                Nº {model.nossoNumero}
              </Typography>
              <Typography
                color="textPrimary"
                variant="body1"
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                <b>{model.pagadorNome}</b>
              </Typography>
            </Grid>
            <Grid item xs={4} md={1} lg={2}>
              <Typography color="textPrimary" variant="caption">
                Ano/Mês
              </Typography>
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {model.competenciaAnoMes}
              </Typography>
            </Grid>
            {emissao && <Grid item xs={4} md={2} lg={2}>
              <Typography color="textPrimary" variant="caption">
                Emissão
              </Typography>
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {toDateString(new Date(model.dataEmissao))}
              </Typography>
            </Grid>}
            <Grid item xs={4} md={2} lg={2}>
              <Typography color="textPrimary" variant="caption">
                Vencimento
              </Typography>
              <Typography
                color="textPrimary"
                variant="body1"
              >
                <b>{toDateString(model.dataVencimento)}</b>
              </Typography>
            </Grid>
            <Grid item xs={4} md={2} lg={1} className={listClasses.valores}>
              <Typography color="textPrimary" variant="caption">
                Valor
              </Typography>
              <Typography
                color="textPrimary"
                variant="body1"
              >
                <b>{model.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b>
              </Typography>
            </Grid>
            <Grid item xs={4} md={2} lg={2} className={listClasses.valores}>
              <Typography color="textPrimary" variant="caption">
                Valor Pago
              </Typography>
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {model.valorPago.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Tooltip arrow title="Editar Fatura">
          <div className={classes.cardRightButton} style={{ margin: '0px 0px 0px 10px' }}>
            <EditarIcon tipo="BUTTON" fill={theme.palette.primary.main} />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
