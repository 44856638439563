import { PesquisaHeaderProps } from './pesquisa-terminal-props';
import { useStyles } from './pesquisa-terminal-styles';
import {
  Button,
  Grid,
  Fade,
  useTheme,
  Box,
  Tooltip,
} from '@material-ui/core';
import { useCallback } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { ProcurarIcon } from 'views/components/icons';
import { Controller, useForm } from 'react-hook-form';
import { TerminalListSearchProps } from '../../terminal-list/terminal-list-search-props';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { useThemeQueries } from 'views/theme';
import { SituacaoTerminalMockTodos } from 'data/mocks/situacao-terminal-mock';
import { EnumSituacaoTerminal } from 'model/enums/enum-situacao-terminal';
import { isNumber } from 'lodash';

export const TerminalFiltro = (props: PesquisaHeaderProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { isMobile, md, lg, xl } = useThemeQueries()

  const {
    handleSubmit,
    control,
    setValue,
    getValues
  } = useForm<TerminalListSearchProps>({
    defaultValues: {
      situacaoTerminal: [EnumSituacaoTerminal.Ativo, EnumSituacaoTerminal.Bloqueado, EnumSituacaoTerminal.Disponivel],
      chave: '',
      query: ''
    },
    criteriaMode: 'all',
    mode: 'onBlur' && 'onChange',
  });

  //FOCO AO FICAR VISIVEL

  //CHAMADA DO PESQUISAR
  const pesquisar = useCallback(
    (event: any) => {
      event.preventDefault();
      handleSubmit(props.onPesquisar)();
    },
    [handleSubmit, props.onPesquisar],
  );

  const changeValue = useCallback((ev: any) => {
    if (ev.target.name === 'situacaoTerminal') {
      if (isNumber(ev.target.value) && ev.target.value === -2) {
        setValue('situacaoTerminal', [EnumSituacaoTerminal.Ativo, EnumSituacaoTerminal.Disponivel, EnumSituacaoTerminal.Bloqueado])
        return
      }

      setValue('situacaoTerminal', [ev.target.value])
    }
  }, [setValue])

  //ALTERAR A PESQUISA

  return (
    <Fade in={props.isOpened} mountOnEnter unmountOnExit>
      <form onSubmit={pesquisar}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12} md={4}>
            <Controller
              name="query"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  label='Domínio ou Razão Social'
                  size='small'
                  fullWidth
                  adornmentColor={theme.palette.text.disabled}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Controller
              name="chave"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  fullWidth
                  size='small'
                  label='Chave'
                  adornmentColor={theme.palette.text.disabled}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Controller
              name="situacaoTerminal"
              control={control}
              render={({ field }) => (
                <SelectSaurus
                  label="Situação do Terminal"
                  conteudo={SituacaoTerminalMockTodos}
                  size='small'
                  {...field}
                  onChange={changeValue}
                  value={getValues('situacaoTerminal').length > 1 ? -2 : getValues('situacaoTerminal')[0]}
                />
              )}
            />

          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <Controller
              name="tipo"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  fullWidth
                  size='small'
                  label='Tipo'
                  adornmentColor={theme.palette.text.disabled}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <Controller
              name="versao"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  fullWidth
                  size='small'
                  label='Versão'
                  adornmentColor={theme.palette.text.disabled}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <Box className={classes.containerFinal}>
              <Controller
                name="sistemaOperacional"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    fullWidth
                    size='small'
                    label='Sistema Operacional'
                    adornmentColor={theme.palette.text.disabled}
                    {...field}
                  />
                )}
              />

              {(!isMobile && md) && (
                <Tooltip title='Pesquisar'>
                  <Button color="primary" variant="contained" type="submit">
                    <ProcurarIcon tipo="BUTTON_PRIMARY" style={{ margin: 0 }} />
                  </Button>
                </Tooltip>
              )}
            </Box>
          </Grid>
          {(((lg || xl) && !md) || isMobile) && (
            <Grid item xs={12} lg={4}>
              <Box display='flex' justifyContent='flex-end' width='100%'>
                <Button variant='contained' color='primary' fullWidth={isMobile} type='submit'>
                  <ProcurarIcon tipo='BUTTON_PRIMARY' />
                  Pesquisar
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      </form>
    </Fade>
  );
};
