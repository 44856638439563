import { Grid } from "@material-ui/core";
import { useGetIndicacoes } from "data/api/gestao/indicacoes/get-indicacoes";
import { useGetIndicacoesExportarExcel } from "data/api/gestao/indicacoes/get-indicacoes-exportar-excel";
import { isEmpty } from "lodash";
import { useConfirm } from "material-ui-confirm";
import { IndicacoesModel } from "model/api/gestao/indicacoes/indicacoes-model";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useCadastros, useSessaoAtual, useToastSaurus } from "services/app";
import { CircularLoading } from "views/components";
import { Paginacao } from "views/components/paginacao";
import { IndicacoesListData } from "./indicacoes-list-data";
import { IndicacoesListSearchProps } from "./indicacoes-list-seach-props";
import { useStyles } from "./indicacoes-list-styles";
import { useThemeQueries } from "views/theme";
import { ButtonFabMenu } from "views/components/controles/buttons/button-fab-menu";
import { MenuVerticalIcon } from "views/components/icons";
import { ButtonFabMenuModel } from "model/app/components/button-fab-menu/button-fab-menu";
import { ExportarExcelIcon } from "views/components/icons/exportar-excel-icon";
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles";
import { usePostIndicacoesInteracoes } from "data/api/gestao/indicacoes/post-indicacoes-interacoes";
import { IndicacoesInteracoesPostModel } from "model/api/gestao/indicacoes/indicacoes-interacoes-post-model";
import { guidEmpty } from "utils/guid-empty";
import { validarCPFCNPJ } from "utils/cpfcnpj-validate";
import { EnumStatusIndicacao } from "model/enums/enum-status-indicacao";
interface IndicacoesListProps {
  searchProps: IndicacoesListSearchProps;
  abaSelecionada: number;
  aba: number;
  status: number;
}

export const IndicacoesList = (props: IndicacoesListProps) => {
  const classes = useStyles();
  const listClasses = useDefaultCadastroStyles();
  const confirm = useConfirm();
  const { showToast } = useToastSaurus();
  const history = useHistory()
  const { isMobile } = useThemeQueries();
  const location = useLocation();

  const { postIndicacoesInteracoes, carregando: carregandoInteracao } = usePostIndicacoesInteracoes();
  const { getIndicacoes, carregando: carregandoGet } = useGetIndicacoes();
  const { usuario, tipoUsuario } = useSessaoAtual();
  const { getIndicacoesExportarExcel, carregando: carregandoExcel } = useGetIndicacoesExportarExcel();

  const carregando = carregandoGet || carregandoExcel || carregandoInteracao;

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<IndicacoesModel>(),
  });

  const [selectedList, setSelectedList] = useState<Array<string>>([]);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const handleOpen = useCallback(() => {
    setOpenMenu(true);
  }, [])

  const handleClose = useCallback(() => {
    setOpenMenu(false);
  }, [])

  const { edicaoIndicacaoState } = useCadastros()

  const fillResult = useCallback(
    (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<IndicacoesModel>,
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    [],
  );

  const search = useCallback(
    async (newPage: number) => {
      try {
        let query;

        let termo = props.searchProps.termo;

        if (validarCPFCNPJ(termo)) {
          termo = termo.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '')
        }


        if (props.abaSelecionada === 0) {
          query = `${!isEmpty(termo) ? `&query=${termo}` : ''}&status=0`;
        }
        else {
          query = `${!isEmpty(termo) ? `&query=${termo}` : ''}${!isEmpty(props.searchProps.revendaId) ? `&responsavelId=${props.searchProps.revendaId}` : ''}${props.searchProps.statusProcesso !== -1 ? `&statusProcesso=${props.searchProps.statusProcesso}` : ''}${!isEmpty(props.searchProps.indicadorId) ? `&indicadorId=${props.searchProps.indicadorId}` : ''}${!isEmpty(props.searchProps.dataInicial) ? `&dataInicial=${props.searchProps.dataInicial}` : ''}${!isEmpty(props.searchProps.dataFinal) ? `&dataFinal=${props.searchProps.dataFinal}` : ''}${props.status !== null ? `&status=${props.status}` : ''}`
        }

        const res = await getIndicacoes(newPage, query);
        if (res.erro) throw res.erro;

        if (!res.resultado) return;
        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data.pageIndex > res.resultado?.data.totalPages &&
          res.resultado?.data.totalResults > 0
        ) {
          search(res.resultado.data.totalPages);
          return;
        }
        fillResult(
          res.resultado?.data.pageIndex,
          res.resultado?.data.totalPages,
          res.resultado?.data.totalResults,
          res.resultado?.data.list,
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ getIndicacoes, fillResult, showToast, props.searchProps]
  );

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        await search(newPage);
      }
    },
    [search, queryStatus.totalPages],
  );

  const alterarIndicacao = useCallback(async (id: string, model: IndicacoesInteracoesPostModel) => {
    const res = await postIndicacoesInteracoes(id, model)

    if (res.erro) throw res.erro

  }, [postIndicacoesInteracoes])

  const redirectEditar = useCallback((id: string, status: EnumStatusIndicacao) => {
    history.push({
      pathname: props.abaSelecionada === 0 ? `/indicacoes/disponiveis/${id}/chat` : props.abaSelecionada === 1 ? `/indicacoes/aceitas/${id}/chat` : props.abaSelecionada === 2 ? `/indicacoes/em-validacao/${id}/chat` : `/indicacoes/finalizadas/${id}/chat`,
      state: {
        from: {
          pathname: history.location.pathname,
          page: queryStatus.page,
          status: status
        }
      },
      search: `?indStatus=${status}`
    });
  }, [history, props.abaSelecionada, queryStatus.page])

  const saveChangeIndicacoes = useCallback(async (id: string) => {
    try {
      const interacaoPostModel = new IndicacoesInteracoesPostModel(
        guidEmpty(),
        id,
        usuario?.Id,
        EnumStatusIndicacao.InteracaoIniciada,
        `Indicação Aceita por ${usuario?.NomeComercial}`
      )
      await alterarIndicacao(id, interacaoPostModel);

      showToast('success', 'Indicação vinculada com sucesso.')
      redirectEditar(id, EnumStatusIndicacao.InteracaoIniciada);
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [alterarIndicacao, redirectEditar, showToast, usuario?.Id, usuario?.NomeComercial])

  const confirmIndicacao = useCallback((id: string) => {
    confirm({
      title: 'Confirmar Indicação',
      description: 'Você deseja vincular com essa indicação? ',
      confirmationText: 'Vincular',
      cancellationText: 'Voltar',
    }).then(() => {
      saveChangeIndicacoes(id);
    });
  }, [confirm, saveChangeIndicacoes])

  const onCardSelected = useCallback(
    (id: string, status: EnumStatusIndicacao) => {
      if (status === EnumStatusIndicacao.Disponivel && tipoUsuario() === EnumTipoPessoas.Revendedor) {
        confirmIndicacao(id);
      }
      else {
        redirectEditar(id, status)
      }
    },
    [confirmIndicacao, redirectEditar, tipoUsuario],
  );

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  useEffect(() => {
    if (!edicaoIndicacaoState.aberto && (props.abaSelecionada === props.aba) && props.status !== null) {
      let page = Number(new URLSearchParams(location.search).get('page'));
      search(page || queryStatus.page);
      location.search = ''
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const exportExcel = useCallback(async () => {
    let query = '';

    if (props.abaSelecionada === 0) {
      query = `${!isEmpty(props.searchProps.termo) ? `&query=${props.searchProps.termo}` : ''}&status=0`;
    }
    else {
      query = `${!isEmpty(props.searchProps.termo) ? `&query=${props.searchProps.termo}` : ''}${!isEmpty(props.searchProps.revendaId) ? `&responsavelId=${props.searchProps.revendaId}` : ''}${!isEmpty(props.searchProps.statusProcesso) ? `&statusProcesso=${props.searchProps.statusProcesso}` : ''}${!isEmpty(props.searchProps.indicadorId) ? `&indicadorId=${props.searchProps.indicadorId}` : ''}${!isEmpty(props.searchProps.dataInicial) ? `&dataInicial=${props.searchProps.dataInicial}` : ''}${!isEmpty(props.searchProps.dataFinal) ? `&dataFinal=${props.searchProps.dataFinal}` : ''}${isEmpty(props.status) ? `&status=${props.status}` : ''}`
    }

    try {
      const res = await getIndicacoesExportarExcel(query)
      if (res.erro) throw (res.erro)
      if (!res.resultado) return;
      const blob = new Blob([res.resultado?.data], { type: 'application/vnd.ms-excel' });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a')

      anchor.href = url
      anchor.download = `Indicações -${new Date().toLocaleDateString().replaceAll('/', '-')}.xls`
      anchor.click()

    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getIndicacoesExportarExcel, props.abaSelecionada, props.searchProps.dataFinal, props.searchProps.dataInicial, props.searchProps.indicadorId, props.searchProps.revendaId, props.searchProps.statusProcesso, props.searchProps.termo, props.status, showToast])

  const onClickMenuItem = useMemo(() => {
    const itens = new Array<ButtonFabMenuModel>();

    itens.push(new ButtonFabMenuModel(<ExportarExcelIcon tipo='BUTTON_FAB' />, 'Exportar Excel', () => exportExcel()))

    return itens;
  }, [exportExcel])

  return (
    <>
      <Grid className={classes.list}>
        <div className={classes.defaultContainer}>
          {carregando && <CircularLoading tipo="FULLSIZED" />}
          <Grid container className={listClasses.listContainer}>
            <Grid item xs={12}>
              <Paginacao
                pageChanged={pageChanged}
                totalPages={queryStatus.totalPages}
                totalRegisters={queryStatus.totalResults}
                currentPage={queryStatus.page}
                exportarExcel={() => exportExcel()}
              />
            </Grid>
            <Grid item xs={12} className={classes.listContainer}>
              <IndicacoesListData
                carregando={carregando}
                list={queryStatus.list}
                selectedList={selectedList}
                onCardSelected={onCardSelected}
                onCardChecked={onCardChecked}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>

      {isMobile ? (
        <>
          {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
          <ButtonFabMenu
            open={openMenu}
            onOpen={handleOpen}
            onClose={handleClose}
            values={onClickMenuItem}
            icon={<MenuVerticalIcon tipo='BUTTON_FAB' />}
            style={{
              zIndex: 0,
              position: 'fixed',
            }}
          />
        </>
      ) : null}

    </>
  );
}