import { useState } from 'react';
import { PagamentoHeader } from './components/pagamento-header/pagamento-header';
import { PagamentoList } from './components/pagamento-list/pagamento-list';
import { Grid } from '@material-ui/core';
import { PagamentoListSearchProps } from './components/pagamento-list/pagamento-list-search-props';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { ButtonFab } from 'views/components/controles';
import { useCadastros } from 'services/app';
import { NovoIcon } from 'views/components/icons';

export const PagamentoPage = () => {
  const classes = useDefaultCadastroStyles();

  const [openPesquisa, setOpenPesquisa] = useState(false);
  const [searchProps, setSearchProps] = useState<PagamentoListSearchProps>({
    termo: '',
  });
  const { abrirCadastroPagamento } = useCadastros()

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <PagamentoHeader
          openPesquisa={openPesquisa}
          setSearchProps={setSearchProps}
          setOpenPesquisa={setOpenPesquisa}
        />
      </Grid>
      <Grid className={classes.list}>
        <PagamentoList searchProps={searchProps} />
      </Grid>
      <ButtonFab
        tooltip="Nova Forma de Pagamento"
        icon={<NovoIcon tipo="BUTTON_FAB" />}
        onClick={() => abrirCadastroPagamento('', '', true)}
      />
    </Grid>
  );
};

export default PagamentoPage;
