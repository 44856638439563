import { Box, Divider, Typography } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useThemeQueries } from 'views/theme';
import { ResultadoRevendaModel } from 'model/api/gestao/relatorios/resultado-revenda-model';
import { useStyles } from '../../../accordion-revenda/components/saldo/saldo-styles';
import { RelatorioChartMixedModel, RelatorioUnitarioChartModel } from 'model/api/gestao/relatorios/relatorio-model';
import { competenciaMesAnterior, competenciaParaDate, subtrairCompetencia } from 'utils/converter-competencia';
import ChartReceita from '../chart-receita/chart-receita';
import { toDecimal } from 'utils/to-decimal';
import { LicenciamentoDBPrimary } from 'database/licenciamento-database';
import { useGetResultadosRevenda } from 'data/api/gestao/relatorio/get-resultado-revendas';
import { CircularLoading } from 'views/components';
import { useSessaoAtual } from 'services/app';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { isEmpty } from 'lodash';

interface DashboardSaldoProps {
    resultadoRevenda: ResultadoRevendaModel[];
    meses: number;
    gerenteId: string;
}

export default function ReceitaGerente({ resultadoRevenda, meses, gerenteId }: DashboardSaldoProps) {
    const { theme } = useThemeQueries()

    const chartContainerRef = useRef<HTMLDivElement>(null)

    const classes = useStyles({ showOverflow: meses >= 24, chartContainerRef });
    const { getResultadosRevenda, carregando } = useGetResultadosRevenda();
    const { tipoUsuario } = useSessaoAtual();

    const arrayDoGerente = useMemo(() => resultadoRevenda.filter(element => element.gerenteId === gerenteId),
        [gerenteId, resultadoRevenda])

    const [dadosReceita, setDadosReceita] = useState<RelatorioChartMixedModel[]>([])

    const getResultadosMesAnterior = useCallback(async (competencia: number) => {
        try {
            let ret = await LicenciamentoDBPrimary.resultadoRevenda.filter(obj => obj.anoMes === competencia && obj.gerenteId === gerenteId).toArray() as ResultadoRevendaModel[]

            if (!ret || ret.length === 0) {
                const res = await getResultadosRevenda(`anoMesInicial=${competencia}&anoMesFinal=${competencia}&gerenteId=${gerenteId}`)

                if (res.erro) throw res.erro
                if (!res.resultado) throw new Error()

                ret = res.resultado.data
            }

            return ret.reduce<number>((prev, curr) => prev + curr.licencasValorFaturado, 0)
        } catch (e: any) {
            return 0
        }
    }, [gerenteId, getResultadosRevenda])

    const retornarTituloRelatorio = useCallback(() => {
        if (tipoUsuario() === EnumTipoPessoas.GerenteComercial) {
            return 'Dados de Receita'
        }

        const nome = arrayDoGerente[0] ? arrayDoGerente[0].gerente : ''

        return `Receita de ${nome}`
    }, [arrayDoGerente, tipoUsuario])

    const tratarDados = useCallback(async () => {
        try {
            let arr: RelatorioChartMixedModel[] = [
                new RelatorioChartMixedModel('Valor Faturado', 'bar', []),
                new RelatorioChartMixedModel('Percentual de Crescimento %', 'line', []),
            ]
            const competenciaAtual = competenciaMesAnterior();

            for (let i = 0; i <= meses; i++) {
                const competenciaSubtraida = subtrairCompetencia(competenciaAtual, i)

                const competenciaMesAntes = subtrairCompetencia(competenciaAtual, i + 1)

                let faturaMesAnterior = 0

                if (i === meses) {
                    faturaMesAnterior = await getResultadosMesAnterior(competenciaMesAntes)
                } else {
                    faturaMesAnterior = arrayDoGerente.reduce((prev, curr) => {
                        if (curr.anoMes !== competenciaMesAntes) return prev

                        return prev + curr.licencasValorFaturado
                    }, 0)
                }

                const resultadosMes = arrayDoGerente.filter(element => element.anoMes === competenciaSubtraida)
                const faturaMes = toDecimal(resultadosMes.reduce<number>((prev, curr) => prev + curr.licencasValorFaturado, 0))

                const percentualFaturado = toDecimal(((faturaMes - faturaMesAnterior) / faturaMesAnterior) * 100)

                arr[0].data.push(new RelatorioUnitarioChartModel(competenciaParaDate(competenciaSubtraida), faturaMes, 'Valor Faturado'))
                arr[1].data.push(new RelatorioUnitarioChartModel(competenciaParaDate(competenciaSubtraida), percentualFaturado, 'Percentual de Crescimento %'))

            }
            setDadosReceita(arr.map(element => {
                element.data = element.data.sort((a, b) => a.x + b.x)
                return element
            }))
        } catch (e) {

        }
    }, [arrayDoGerente, getResultadosMesAnterior, meses])

    useEffect(() => {
        tratarDados()
    }, [tratarDados])

    if (isEmpty(arrayDoGerente)) {
        return (<Box display='flex' flexDirection='column' width='100%'>
            <Box display='flex' justifyContent='center' alignItems='center'>
                <Typography color='textSecondary' variant='h6'>Não foram encontrados dados deste Gerente.</Typography>
            </Box>
        </Box>)
    }

    return (
        <>
            {carregando && <CircularLoading tipo='FULLSIZED' />}
            <Box display='flex' flexDirection='column' width='100%' gridGap={theme.spacing(1)}>
                <Box display='flex' justifyContent='space-between' alignItems='center' flexWrap='wrap' gridGap={5}>
                    <Typography color='primary' variant='h6'>{retornarTituloRelatorio()}</Typography>
                </Box>
                <Divider />
                <div className={classes.chartContainer} ref={chartContainerRef}>
                    <ChartReceita list={dadosReceita} width={
                        meses >= 24 ? meses * 100 : undefined
                    } meses={meses} />
                </div>
            </Box>
        </>
    );
}