import { useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { useParams } from 'react-router-dom';
import { RevendedorListSearchProps } from '../revendedor/components/revendedor-list/revendedor-list-search-props';
import { RepresentantesHeader } from './components/representantes-header/representantes-header';
import { RepresentantesList } from './components/representantes-list/representantes-list';
import { RepresentantesEditar } from './components/representantes-editar';

interface paramProps {
  parametro: string
}

export const RepresentantesPage = () => {
  const classes = useDefaultCadastroStyles();

  const [openPesquisa, setOpenPesquisa] = useState(false);
  const [searchProps, setSearchProps] = useState<RevendedorListSearchProps>({
    termo: '',
  });

  const { parametro } = useParams<paramProps>()

  const pageSwitch = useMemo(() => {
    setOpenPesquisa(false)
    switch (parametro) {
      case undefined:
        return <RepresentantesList searchProps={searchProps}/>
      default:
        return (
          <div className={classes.defaultContainer}>
            <RepresentantesEditar id={parametro}/>
          </div>
        )
    }
  }, [classes.defaultContainer, parametro, searchProps])

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <RepresentantesHeader
          titulo={parametro === undefined
            ? 'Representantes'
            : 'Editar Representante'}
          openPesquisa={openPesquisa}
          setSearchProps={setSearchProps}
          setOpenPesquisa={setOpenPesquisa}
        />
      </Grid>
      {pageSwitch}
    </Grid>
  );
};

export default RepresentantesPage;
