import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    formContainer: {
    },
    btnPesquisa: {
        marginLeft: theme.spacing(1),
        width: "60px",
        minWidth: "60px",
        padding: 0,
        "& svg": {
            margin: 0,
        }
    },
    modulosContainer: {
        background: '#F8F8F8',
        padding: '1rem',
        borderRadius: '15px',
        boxShadow: '0px 4px 8px rgba(96, 97, 112, 0.16)',
        '& .MuiFormControl-root': {
            background: '#fff'
        },
        maxHeight: 500,
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'unset'
        },
        [theme.breakpoints.up('lg')]: {
            maxHeight: 600
        },
        [theme.breakpoints.up('xl')]: {
            maxHeight: 700
        }
    },
    modulosScroll: {
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'unset'
        },
        [theme.breakpoints.up('lg')]: {
            maxHeight: 400
        },
        [theme.breakpoints.up('xl')]: {
            maxHeight: 450
        }
    },
    camposContainer: {
        background: '#F8F8F8',
        padding: '1rem',
        borderRadius: '15px',
        boxShadow: '0px 4px 8px rgba(96, 97, 112, 0.16)',
        '& .MuiFormControl-root': {
            background: '#fff'
        }
    }
}));
