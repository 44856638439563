import { Button, Grid } from "@material-ui/core"
import { PessoaPostModel } from "model/api/gestao/pessoa/pessoa-post-model"
import { useCallback, useEffect, useRef, useState } from "react"
import { useToastSaurus } from "services/app"
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { useThemeQueries } from "views/theme"
import { DefaultFormRefs } from "views/components/form/utils"
import { usePutAtualizarPessoa } from "data/api/gestao/pessoa"
import { useStyles } from '../../../clientes/components/clientes-editar/components/cliente-edit/cliente-edit-styles';
import classNames from "classnames"
import { PessoaMensagemModel } from "model/api/gestao/pessoa/pessoa-mensagem-model"
import { FormPessoaMensagem } from "views/components/form/master/pessoa/pessoa-mensagem/form-pessoa-mensagem"
import { SalvarIcon } from "views/components/icons"

interface Props {
    model: PessoaPostModel
    preencherTela: () => Promise<void>
}

export const AccordionConfiguracoesMensagem = ({ model, preencherTela }: Props) => {

    const { theme } = useThemeQueries()
    const classes = useStyles()

    const [openAccordion, setOpenAccordion] = useState<boolean>(false)

    const { showToast } = useToastSaurus()
    const { putAtualizarPessoa, carregando } = usePutAtualizarPessoa()

    const formRef = useRef<DefaultFormRefs<PessoaMensagemModel>>(null)
    const beforeMessage = useRef<string>('')

    const saveChanges = useCallback(async (values: PessoaPostModel) => {

        const res = await putAtualizarPessoa(values)

        if (res.erro) throw res.erro

    }, [putAtualizarPessoa])

    const handleSubmit = useCallback(async (actualModel: PessoaMensagemModel) => {
        try {
            if(beforeMessage.current === actualModel.mensagemAosContratos){
                showToast('info', 'Mensagem não foi alterada.')
                return
            }
            
            const pessoa = model
            pessoa.mensagemAosContratos = actualModel.mensagemAosContratos

            await saveChanges(pessoa)

            await preencherTela()

            formRef.current?.resetForm()

            showToast('success', 'Mensagem aos clientes atualizada.')
        }
        catch (err: any) {
            showToast('error', err.message)
        }

    }, [model, preencherTela, saveChanges, showToast])

    useEffect(() => {
        beforeMessage.current = model.mensagemAosContratos
        formRef.current?.fillForm(new PessoaMensagemModel(model.mensagemAosContratos))
    }, [model.mensagemAosContratos])

    return (
        <AccordionSaurus
            labelPrimary="Mensagem aos Clientes"
            tipoExpand="bold"
            noPaperRoot={false}
            heightDivider={2}
            showDivider={openAccordion}
            colorDivider={theme.palette.primary.main}
            colorExpand={theme.palette.primary.main}
            expanded={openAccordion}
            onChange={() => setOpenAccordion(prev => !prev)}
        >
            <div className={classes.content}>
                <div
                    className={classNames(
                        classes.contentForms,
                        carregando ? classes.contentFormsLoading : undefined,
                    )}
                >
                    <FormPessoaMensagem
                        onSubmit={handleSubmit}
                        loading={carregando}
                        showLoading={true}
                        ref={formRef}
                    />
                </div>
                <div className={classes.acoes}>
                    <Grid container spacing={2} justifyContent='flex-end'>
                        <Grid item xs={12} md={3}>
                            <Button
                                disabled={carregando}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => formRef.current?.submitForm()}
                            >
                                <SalvarIcon tipo='BUTTON_PRIMARY'/>
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </AccordionSaurus >
    )
}