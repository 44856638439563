import { KeyValueModel } from "model";
import { EnumSituacaoPessoa } from "model/enums/enum-situacao-pessoa";

export const SituacaoPessoaMock: KeyValueModel[] = [
    new KeyValueModel(EnumSituacaoPessoa.Ativo, "Ativo"),
    new KeyValueModel(EnumSituacaoPessoa.Inativo, "Inativo")
];

export const SituacaoPessoaTodosMock: KeyValueModel[] = [
    new KeyValueModel(EnumSituacaoPessoa.Ativo, 'Ativo'),
    new KeyValueModel(EnumSituacaoPessoa.Inativo, 'Inativo'),
    new KeyValueModel(-1, 'Todos')
]