import { Button } from "@material-ui/core";
import { EnumEstadoConsultaFatura } from "model/enums/enum-estado-consulta-fatura";
import { useMemo } from "react";
import { PrivatePageHeader } from "views/components/headers";
import { FiltroIcon } from "views/components/icons";
import { useThemeQueries } from "views/theme";
import {useStyles} from './consulta-faturas-header-styles'

export interface FaturasHeaderProps {
  setEstado: React.Dispatch<React.SetStateAction<EnumEstadoConsultaFatura>>;
  estado: EnumEstadoConsultaFatura;
}

export const ConsultaFaturasHeader = ({ setEstado, estado }: FaturasHeaderProps) => {

  const {isMobile} = useThemeQueries();

  const classes = useStyles();

  const switchEstado = useMemo(() => (
      <Button
      variant='outlined'
      color='primary'
      className={classes.button}
      onClick={() => setEstado(prev => prev === EnumEstadoConsultaFatura.ABERTAS ? EnumEstadoConsultaFatura.FINALIZADAS : EnumEstadoConsultaFatura.ABERTAS)}
    >
      <FiltroIcon tipo={isMobile ? 'BUTTON_PRIMARY' : 'BUTTON'} />
      {estado === EnumEstadoConsultaFatura.ABERTAS ? 'Mostrar Finalizadas' : 'Mostrar Abertas'}
    </Button>
  ), [classes.button, estado, isMobile, setEstado])

  return (
    <>
      <PrivatePageHeader
        title="Cobrança"
        rightArea={switchEstado}
      />
    </>
  );
};
