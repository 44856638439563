
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: 0,
    position: "relative",
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    background: theme.palette.common.white,
    [theme.breakpoints.up("md")]: {
      borderTopWidth: 1,
      borderTopStyle: "solid",
      borderTopColor: theme.palette.grey[300],
    },
  },

  containerFinal: {
    display: "flex",
    justifyContent: "space-between",

  },
  btnPesquisa: {
    marginLeft: theme.spacing(1),
    width: "40px",
    minWidth: "40px",
    height: "40px",
    padding: 0,
    "& svg": {
      margin: 0,
    }
  }

}));
