import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: theme.spacing(2),
        padding: theme.spacing(1)
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(3)
    }
}));