import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePutReutilizarTerminal() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putReutilizarTerminal = useCallback(
        (id: string) =>
            invocarApi({
                method: 'PUT',
                url: `/${VariaveisAmbiente.apiVersion}/terminal/${id}/reutilizar`,
                enviarTokenUsuario: true,
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putReutilizarTerminal,
    };
}
