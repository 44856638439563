import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    buttonConsulta: {
        '& svg': {
            padding: 0,
            margin: 0
        }
    },
    loginContainer: {
        background: '#F8F8F8',
        padding: '.5rem 1rem',
        marginTop: '1rem',
        borderRadius: '15px',
        '& .MuiFormControl-root': {
            background: '#fff'
        }
    },
    pin: {
        width: '50px',
        height: '50px',
        borderRadius: '50% 50% 50% 0',
        background: theme.palette.primary.main,
        transform: 'rotate(-45deg)',
        cursor: 'pointer',
        padding: '5px',
        '& img': {
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          transform: 'rotate(45deg)',
        }
      },
      pinContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        width: 50,
      },
      loadingPinContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.6)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      loadingPin: {
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: theme.spacing(1),
        boxShadow: theme.shadows[15],
        border: `1px solid ${theme.palette.divider}`
      }
}))