import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useStyles } from './notas-fiscais-rejeitar-editar-styles'
import { NotaFiscalModel } from "model/api/gestao/nota-fiscal/nota-fiscal-model";
import NotaFiscalTributos from "views/pages/private/notas-fiscais-revenda/components/accordions/accordion-nota-fiscal/components/nota-fiscal-tributos";
import { DesfazerIcon, NotaFiscalIcon } from "views/components/icons";
import { useCallback } from "react";
import { usePostEmitirNotaFiscal } from "data/api/gestao/nota-fiscal/post-emitir-nota-fiscal";
import { picker } from "utils/picker";
import { NotaFiscalFormModel } from "model/api/gestao/nota-fiscal/emitir-nota-fiscal-model";
import { EnumSituacaoNotaFiscal } from "model/enums/enum-situacao-nota-fiscal";
import { useToastSaurus } from "services/app";
import { useHistory, useLocation } from "react-router-dom";
import { CircularLoading } from "views/components";
import { AvisoIcon } from "views/components/icons/aviso-icon";
import { useThemeQueries } from "views/theme";
import { EnumTipoFatura } from "model/enums/enum-tipo-fatura";
import { LocationProps } from "views/pages/private/representantes/components/representantes-header/representantes-header";
import FaturaNFInfo from "./components/fatura-nf-info";
import { PessoaIcon } from "views/components/icons/pessoa-icon";

export interface NotasFiscaisRejeitadasEditarProps {
    model: NotaFiscalModel;
}

export const NotasFiscaisRejeitadasEditar = ({ model }: NotasFiscaisRejeitadasEditarProps) => {

    const classes = useStyles();
    const { theme, isMobile } = useThemeQueries();
    const { push } = useHistory();
    const { state } = useLocation<LocationProps>()

    const { showToast } = useToastSaurus();
    const { postEmitirNotaFiscal, carregando } = usePostEmitirNotaFiscal();

    const handleEmitir = useCallback(async () => {
        try {
            const notaFiscalForm = picker<NotaFiscalFormModel>(model, new NotaFiscalFormModel());
            const res = await postEmitirNotaFiscal(model.fatura.id, notaFiscalForm)

            if (res.erro) throw res.erro
            if (!res.resultado) throw new Error('Erro ao carregar Nota Fiscal')
            if (res.statusCode === 204 || !res.resultado?.data) {
                throw new Error('Erro ao Emitir Nota Fiscal')
            }

            if (res.resultado.data.situacao !== EnumSituacaoNotaFiscal.Autorizada) {
                showToast('error', res.resultado.data.retornoAutorizacao);
                return
            } else {
                showToast('success', res.resultado.data.retornoAutorizacao)
                const pushState = {
                    pathname: model.fatura.tipoFatura === EnumTipoFatura.Revenda ? `/revenda/notas-fiscais/${model.fatura.id}` : `/faturas/${model.fatura.id}`,
                    state: {
                        from: {
                            pathname: `/notas-fiscais-rejeitadas/?page=${state.from.page}&notaId=${model.id}`
                        }
                    }
                }
                push(pushState)
            }

        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [model, postEmitirNotaFiscal, push, showToast, state.from.page])

    return (
        <div className={classes.container}>
            {carregando && <CircularLoading tipo='FULLSIZED' />}
            <div className={classes.cardContainer}>
                <Grid container spacing={2} className={classes.card}>
                    <FaturaNFInfo model={model.fatura} />
                    <NotaFiscalTributos model={model} upperBorder />
                    <Grid item xs={12}>
                        <Box className={classes.errorMessageContainer}>
                            {!isMobile && <AvisoIcon fill={theme.palette.error.main} tipo='GERAL'
                                style={{ width: 40, height: 40 }} viewBox="0 0 40 40" />}
                            <Box>
                                <Typography variant='caption'>Mensagem de Erro:</Typography>
                                <Typography style={{ wordBreak: 'break-all' }}>{model.retornoAutorizacao}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} className={classes.acoes}>
                        <Box className={classes.childButtons}>
                            <Button variant='outlined' color='primary' fullWidth={isMobile} onClick={() => {
                                const pushState = {
                                    pathname: model.fatura.tipoFatura === EnumTipoFatura.Revenda ? `/revenda/notas-fiscais/${model.fatura.id}` : `/faturas/${model.fatura.id}`,
                                    state: {
                                        from: {
                                            pathname: `/notas-fiscais-rejeitadas/?page=${state.from.page}&notaId=${model.id}`
                                        }
                                    }
                                }
                                push(pushState)
                            }}>
                                <DesfazerIcon tipo='BUTTON' />
                                Ver Fatura
                            </Button>

                            <Button variant='outlined' color='primary' fullWidth={isMobile} onClick={() => {
                                const pushState = {
                                    pathname: model.fatura.tipoFatura === EnumTipoFatura.Revenda ? `/revendedor/${model.fatura.pagadorId}` : `/clientes/${model.fatura.pagadorId}`,
                                    state: {
                                        from: {
                                            pathname: `/notas-fiscais-rejeitadas/?page=${state.from.page}&notaId=${model.id}`
                                        }
                                    }
                                }
                                push(pushState)
                            }}>
                                <PessoaIcon tipo='BUTTON' />
                                Ver Pagador
                            </Button>
                        </Box>
                        <Button variant='contained' color='primary' onClick={handleEmitir} fullWidth={isMobile}>
                            <NotaFiscalIcon tipo='BUTTON_PRIMARY' />
                            Emitir Nota Fiscal
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};