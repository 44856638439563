import { guidEmpty } from "utils/guid-empty";
import { TabelaPrecoSistemaModel } from "./tabela-preco-sistema-model";

export class TabelaPrecoModel {
  constructor(
    public id: string = guidEmpty(),
    public nome: string = '',
    public sistemas: TabelaPrecoSistemaModel[] = [],
    public vendedorId?: string,
    public vendedorNome?: string,
  ) { }
}
