import { VariaveisAmbiente } from 'config';
import { PessoaTributoModel } from 'model/api/gestao/pessoa/pessoa-tributo-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetPessoaTributo() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<PessoaTributoModel>();

    const getPessoaTributo = useCallback(
        async (pessoaId: string) => {
            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/pessoa/${pessoaId}/tributo`,
                method: 'GET',
                enviarTokenUsuario: true
            })
        }
        , [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getPessoaTributo,
    };
}
