import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSessaoAtual } from '../../../services/app/providers';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import {
  useGestaoToken,
  useToastSaurus,
} from 'services/app';

export const ExpiredTokenRoute: React.FC<RouteProps> = ({
  children,
  ...otherProps
}) => {
  const { usuario, termosDeUsoAtivo, carregando: carregandoSessaoAtual } = useSessaoAtual();
  const { isTokenExpired } = useGestaoToken();
  const { showToast } = useToastSaurus();
  const { deslogar } = useSessaoAtual()
  const carregando = carregandoSessaoAtual

  const retRoute = useCallback(() => {
    if (isTokenExpired(usuario)) {
      showToast(
        'error',
        `Sua sessão expirou. Realize o login novamente para continuar. (401) `,
      );
      deslogar()
      return <Redirect to="/login/expirou" />;
    }

    return carregando ? (
      children
    ) : isEmpty(usuario) ? (
      <Redirect to="/login/expirou" />
    ) : !termosDeUsoAtivo() ? (
      <Redirect to='termos'/>
    ) : (
      children
    );
  }, [isTokenExpired, usuario, carregando, children, termosDeUsoAtivo, showToast, deslogar]);
  return <Route {...otherProps}>{retRoute()}</Route>;
};
