import { Grid, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { FaturasModel } from 'model/api/gestao/fatura/faturas-model';
import { } from 'model/api/gestao/nota-fiscal/emitir-nota-fiscal-model';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { toDateString } from 'utils/to-date';
import { toCurrency } from 'utils/toCurrency';

interface FaturaNFInfoProps {
    model: FaturasModel;
}

export default function FaturaNFInfo({
    model,
}: FaturaNFInfoProps) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
                <Typography variant='caption'>
                    Pagador
                </Typography>
                <Typography variant='body1' style={{ fontWeight: 600 }}>
                    {model.pagadorNome}
                </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
                <Typography variant='caption'>
                    CPF/CNPJ
                </Typography>
                <Typography variant='body1' style={{ fontWeight: 600 }}>
                    {formatarCPFCNPJ(model.pagadorCpfCnpj)}
                </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
                <Typography variant='caption'>
                    Data de Emissão da Fatura
                </Typography>
                <Typography variant='body1' style={{ fontWeight: 600 }}>
                    {toDateString(model.dataEmissao)}
                </Typography>
            </Grid>
            {!isEmpty(model.dataPagamento) && <Grid item xs={6} md={3}>
                <Typography variant='caption'>
                    Data de Pagamento da Fatura
                </Typography>
                <Typography variant='body1' style={{ fontWeight: 600 }}>
                    {toDateString(model.dataPagamento)}
                </Typography>
            </Grid>}
            {!isEmpty(model.valorPago) && <Grid item xs={6} md={3}>
                <Typography variant='caption'>
                    Valor Pago
                </Typography>
                <Typography variant='body1' style={{ fontWeight: 600 }}>
                    {toCurrency(model.valorPago)}
                </Typography>
            </Grid>}
        </Grid>
    );
}