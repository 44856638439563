
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormPessoaMensagemValidation = () => {

  const formPessoaMensagemValidationYup = useMemo(() => {
    return Yup.object().shape({
      mensagemAosContratos: Yup.string().notRequired()
    });
  }, [])

  return {
    formPessoaMensagemValidationYup,
  }
}

