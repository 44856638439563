import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  defaultContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden"
  },
  listContainer: {
    "& .card": {
      margin: theme.spacing(1),
    },
  },
  footer: {
    width: '100%',
    display: 'flex',
    background: '#FFF',
    borderTop: '1px solid #e0e0e0',
    maxHeight: '100px',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 15px 7px 15px',
    boxShadow: '5px 10px 18px #888888',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      display: 'flex',
      background: '#FFF',
      borderTop: '1px solid #e0e0e0',
      maxHeight: '100px',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 0,
      boxShadow: '5px 10px 18px #888888'
    },
    '& p': {
      fontSize: 13
    },
    '& span': {
      fontSize: 11
    }
  }
}));