import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const AvisoIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M48.6744 44.1345C41.1539 31.0218 33.5914 17.9302 26.0289 4.83864C25.3873 3.72222 24.6089 3.72222 23.9673 4.8281C16.4153 17.9197 8.8528 31.0113 1.32183 44.1239C0.64867 45.293 1.04836 46.0408 2.36312 46.0303C9.90461 45.9882 17.4461 46.0092 24.9876 46.0092C32.5291 46.0092 40.0706 45.9882 47.612 46.0303C48.9373 46.0408 49.337 45.293 48.6744 44.1345ZM3.60426 44.0186C10.7355 31.6432 17.8353 19.331 24.9876 6.91349C32.1504 19.3416 39.2501 31.6432 46.3814 44.0186C32.1083 44.0186 17.9089 44.0186 3.60426 44.0186Z" />
                <path d="M23.5571 26.2296C23.5571 24.0494 23.5676 21.8692 23.5466 19.6891C23.5361 19.1203 23.6833 18.9097 24.3039 18.9097C26.4391 18.9307 26.4391 18.8991 26.4391 21.0056C26.4391 24.892 26.4286 28.7784 26.4496 32.6648C26.4496 33.3283 26.2918 33.6443 25.5766 33.5495C25.1348 33.4968 24.672 33.5074 24.2303 33.5495C23.6833 33.6022 23.5466 33.3494 23.5571 32.8544C23.5571 30.6426 23.5571 28.4414 23.5571 26.2296Z" />
                <path d="M26.5022 37.9521C26.5022 39.532 26.5022 39.532 24.9245 39.5214C23.2626 39.5109 23.515 39.711 23.5045 38.0891C23.494 36.3302 23.5045 36.3302 25.261 36.3302C25.4083 36.3302 25.5661 36.3512 25.7133 36.3302C26.3129 36.2564 26.6074 36.4671 26.5232 37.1201C26.4706 37.3939 26.5022 37.6783 26.5022 37.9521Z" />
            </DefaultIcon>
        </>
    );
}
