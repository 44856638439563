import { Button, Grid } from "@material-ui/core";
import { useGetFaturas } from "data/api/gestao/faturas";
import { SituacaoMockTodos } from "data/mocks/situacao-mock";
import { isEmpty } from "lodash";
import { FaturasModel } from "model/api/gestao/fatura/faturas-model";
import { EnumSituacao } from "model/enums/enum-situacao";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useToastSaurus } from "services/app";
import { toDateString } from "utils/to-date";
import { CircularLoading } from "views/components";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus";
import { ProcurarIcon } from "views/components/icons";
import { Paginacao } from "views/components/paginacao";
import { FaturaLocationProps } from "views/pages/private/notas-fiscais-revenda/components/notas-fiscais-revenda-header/notas-fiscais-revenda-header";
import { useThemeQueries } from "views/theme";
import { AccordionNotasFiscaisRevendaListData } from "./accordion-notas-fiscais-revenda-list-data";
import { useStyles } from './accordion-notas-fiscais-revenda-list-styles'

type Props = {
  id: string;
}

export type FiltroProps = {
  dataInicial: string
  dataFinal: string,
  situacao: number,
  atualizarPesquisa: boolean
}

export const AccordionNotasFiscaisList = ({ id }: Props) => {
  const { theme } = useThemeQueries()
  const [openAccordion, setOpenAccordion] = useState<boolean>(false)
  const { showToast } = useToastSaurus()
  const classes = useStyles()
  const { state } = useLocation<FaturaLocationProps>()


  const history = useHistory();

  const getCurrentMonth = useCallback(() => {
    const month = new Date().getMonth() + 1
    const year = new Date().getFullYear()
    return {
      inicial: new Date(`${month}/01/${year - 1}`).toString(),
      final: new Date(`${month}/31/${year}`).toString()
    }
  }, [])

  const [filtro, setFiltro] = useState<FiltroProps>({
    dataInicial: '',
    dataFinal: '',
    situacao: EnumSituacao.Aberta,
    atualizarPesquisa: false
  })

  const [valoresFiltro, setValoresFiltro] = useState<FiltroProps>({
    dataInicial: '',
    dataFinal: '',
    situacao: EnumSituacao.Aberta,
    atualizarPesquisa: false
  })

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<FaturasModel>(),
    valorTotal: 0
  });

  const scrollRef = useRef<HTMLElement | null>(null)

  const { getFaturas, carregando: carregandoGet } = useGetFaturas()

  const carregando = carregandoGet

  const fillResult = useCallback(
    (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<FaturasModel>,
      valorTotal: number
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
        valorTotal: valorTotal
      });
    },
    []
  );

  const [valores, setValores] = useState({
    Valor: 0
  });
  const fillResultValores = useCallback(
    async (
      Valor: number
    ) => {
      setValores({
        Valor: Valor
      });
    },
    [],
  );


  const search = useCallback(
    async (newPage: number) => {
      if (new Date(filtro.dataInicial) > new Date(filtro.dataFinal)) {
        showToast('error', 'Data de Vencimento Inicial é maior do que a Data de Vencimento Final')
        return
      }
      try {
        const query =
          `&PagadorId=${id}${!isEmpty(filtro.dataInicial)
            ? "&DataInicial=" + filtro.dataInicial ?? ''
            : ''
          }${!isEmpty(filtro.dataFinal)
            ? "&DataFinal=" + filtro.dataFinal
            : ""
          }${filtro.situacao > -1
            ? '&Situacao=' + filtro.situacao
            : ''}`

        const res = await getFaturas(newPage, query);
        if (res.erro) throw res.erro;

        if (!res.resultado) {
          return [];
        }

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          await search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list,
          res.resultado.data.somatorios ? res.resultado.data.somatorios.Valor : 0
        );

        if (res.resultado.data.somatorios) {
          fillResultValores(
            res.resultado.data.somatorios.Valor
          );
        } else {
          fillResultValores(
            0
          )
        }


        return res;
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [filtro.dataInicial, filtro.dataFinal, filtro.situacao, showToast, id, getFaturas, fillResult, fillResultValores]
  );

  useEffect(() => {
    if (openAccordion) {
      search((state && state.from.pageFatura) || queryStatus.page)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAccordion, queryStatus.page, filtro])

  useEffect(() => {
    if (state && state.from.pageFatura && state.from.searchProps) {
      setOpenAccordion(true)
      setFiltro(state.from.searchProps)
      setValoresFiltro(state.from.searchProps)
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        })
      }, 1000)
    }
  }, [state])

  const onCardSelected = useCallback((id: string) => {
    history.push({
      pathname: `/revenda/notas-fiscais/${id}`,
      state: {
        from: {
          pathname: history.location.pathname,
          searchProps: filtro,
          pageFatura: queryStatus.page,
        }
      }
    })
  }, [filtro, history, queryStatus.page])

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        await search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  const handleFiltro = useCallback((event: any) => {
    event.preventDefault()
    setFiltro({ ...valoresFiltro, atualizarPesquisa: !filtro.atualizarPesquisa })
  }, [filtro.atualizarPesquisa, valoresFiltro])

  const changeValue = useCallback((ev: any) => {
    if (ev.target.name === 'situacao' && Number(ev.target.value) !== EnumSituacao.Aberta) {
      setValoresFiltro(prev => {
        return {
          ...prev,
          dataInicial: !isEmpty(prev.dataInicial) ? prev.dataInicial : (toDateString(getCurrentMonth().inicial, 'yyyy-MM-DD') || ''),
          [ev.target.name]: ev.target.value
        }
      })

      return
    }
    setValoresFiltro(prev => ({
      ...prev,
      [ev.target.name]: ev.target.value
    }))
  }, [getCurrentMonth])

  return (
    <>
      <AccordionSaurus
        labelPrimary="Notas Fiscais"
        tipoExpand="bold"
        noPaperRoot={false}
        heightDivider={2}
        showDivider={openAccordion}
        colorDivider={theme.palette.primary.main}
        colorExpand={theme.palette.primary.main}
        expanded={openAccordion}
        onChange={() => setOpenAccordion(prev => !prev)}
      >
        <Grid container spacing={1} style={{
          position: 'relative'
        }}>
          {carregando && <CircularLoading tipo="FULLSIZED" />}
          <form onSubmit={handleFiltro} style={{
            width: '100%'
          }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextFieldSaurus
                  label="Data de Vencimento Inicial"
                  tipo="DATA"
                  name="dataInicial"
                  size="small"
                  fullWidth
                  onChange={changeValue}
                  value={valoresFiltro.dataInicial}
                />
              </Grid>
              <Grid item xs={6}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <TextFieldSaurus
                    label="Data de Vencimento Final"
                    tipo="DATA"
                    name="dataFinal"
                    size="small"
                    fullWidth
                    onChange={changeValue}
                    value={valoresFiltro.dataFinal}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <SelectSaurus
                    conteudo={SituacaoMockTodos}
                    label="Situação"
                    name="situacao"
                    size="small"
                    fullWidth
                    onChange={changeValue}
                    value={valoresFiltro.situacao}
                  />
                  <Button
                    variant="contained"
                    type='submit'
                    color="primary"
                    className={classes.btnPesquisa}
                  >
                    <ProcurarIcon tipo="BUTTON_PRIMARY" />
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
          <Grid item xs={12}>
            <Paginacao
              pageChanged={pageChanged}
              totalPages={queryStatus.totalPages}
              totalRegisters={queryStatus.totalResults}
              currentPage={queryStatus.page}
              valorTotal={valores.Valor}
            />
          </Grid>
          <Grid item xs={12} innerRef={scrollRef}>
            <AccordionNotasFiscaisRevendaListData
              list={queryStatus.list}
              carregando={carregando}
              onCardSelected={onCardSelected}
              onCardChecked={() => { }}
              selectedList={[]}
            />
          </Grid>
        </Grid>
      </AccordionSaurus>
    </>
  );
};
