import { useStyles } from './button-add-e-sub-styles';
import { SwitchSaurus } from 'views/components/controles/switches/switch-saurus';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { Grid } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
import { ContratoModuloModel } from 'model/api/gestao/contrato/contrato-modulo-model';

interface ButtonAddESubProps {
  model: ContratoModuloModel;
  alterarQtd: (model: ContratoModuloModel, qtde: number) => void;
  hasPlanoId: boolean;
  disable: boolean;
}

export const BotoesAddESub = ({
  model,
  alterarQtd,
  hasPlanoId,
  disable
}: ButtonAddESubProps) => {
  const classes = useStyles();
  const [erro, setErro] = useState<string>('')


  const alterarQuantidade = useCallback((qtd: number) => {
    if (model.quantidadeMaxima > -1 && model.quantidadeMinima > -1) {
      if (qtd < model.quantidadeMinima) {
        setErro('A quantidade informada é menor que o permitido')
      }
      else if (qtd > model.quantidadeMaxima) {
        setErro('A quantidade informada é maior que o permitido')
      }
      else {
        setErro('')
      }
      if (qtd > Number(model.quantidadeMaxima.toString() + '0')) {
        return
      }
    }
    if(qtd > 99999999) return
    alterarQtd(model, qtd)

  }, [alterarQtd, model])

  return (
    <div className={classes.containerQtde}>
      {model.quantidadeMaxima > 1 || model.quantidadeMaxima === -1 ? (
        <div>
          <div style={{
            justifyItems: 'flex-end',
            maxWidth: 150,
          }}>
            <Grid container>
              <Grid item xs={12}>
                <TextFieldSaurus
                  tipo="TEXTO"
                  label="Quant."
                  onChange={ev => {
                    if (model.quantidadeContratada === -1) {
                      alterarQuantidade(0)
                      return
                    }
                    if (ev.target.value.includes('-')) {
                      alterarQuantidade(-1)
                      return
                    }

                    if(isNaN(Number(ev.target.value))){
                      alterarQuantidade(0)
                      return
                    }

                    alterarQuantidade(Number(ev.target.value))
                  }}
                  value={model.quantidadeContratada}
                  size="small"
                  disabled={(hasPlanoId && !model.editavel) || disable}
                  error={!isEmpty(erro)}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        <SwitchSaurus
          variant='LIGHT'
          checked={model.quantidadeContratada === 1}
          disabled={hasPlanoId || disable}
          onClick={() => {
            if (model.quantidadeContratada === 1) {
              return alterarQtd(model, 0)
            }

            alterarQtd(model, 1)
          }}
        />
      )}
    </div>
  );
};
