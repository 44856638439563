

import { useMemo } from 'react';
import * as Yup from 'yup';


export const useFormSimulacaoContratoValidation = () => {

    const FormSimulacaoContratoValidation = useMemo(() => {
        return (
            Yup.object().shape({
                
            })
        )
    }, [])

    return {
        FormSimulacaoContratoValidation
    }
}