import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { FaturaPagamentoModel } from 'model/api/gestao/fatura/fatura-pagamento-model';

export function usePostFaturaPagar() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<FaturaPagamentoModel>();

    const postFaturaPagar = useCallback(
        (faturaId: string, formaPagamentoId: string) =>
            invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/Fatura/${faturaId}/pagar?formaPagamentoId=${formaPagamentoId}`,
                enviarTokenUsuario: true,
                timeout: 30000
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postFaturaPagar,
    };
}
