import { Grid, Tooltip, Typography } from '@material-ui/core';
import { CardModulosProps } from './card-modulos-props';
import { EditarIcon } from '../../icons/editar-icon';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';

export const CardModulos = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardModulosProps) => {
  const classes = useDefaultCardStyles();

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model);
        }}
      >
        <div className={classes.cardContent}>
          <div style={{
            width: '100%'
          }}>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="caption"
              style={{
                fontSize: 13
              }}
            >
              <b>Cód:</b> {model.codigo}
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
              style={{
                fontSize: 18
              }}
            >
              <strong>{model.nome}</strong>
            </Typography>
            <div style={{
              width: '100%'
            }}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography
                    color="textPrimary"
                    variant="caption"
                  >
                    Mínimo
                  </Typography>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    style={{
                      fontWeight: 600
                    }}
                  >
                    {model.quantidadeMinima < 0 ? 'Ilimitado' : model.quantidadeMinima}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color="textPrimary"
                    variant="caption"
                  >
                    Máximo
                  </Typography>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    style={{
                      fontWeight: 600
                    }}
                  >
                    {model.quantidadeMaxima < 0 ? 'Ilimitado' : model.quantidadeMaxima}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color="textPrimary"
                    variant="caption"
                  >
                    Padrão
                  </Typography>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    style={{
                      fontWeight: 600
                    }}
                  >
                    {model.quantidadePadrao < 0 ? 'Ilimitado' : model.quantidadePadrao}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <Tooltip arrow title="Editar Sistema">
          <div className={classes.cardRightButton}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
