import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const SetaCimaIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
      <path d="M8.33331 25L11.2708 27.9375L22.9166 16.3125V41.6667H27.0833V16.3125L38.7083 27.9583L41.6666 25L25 8.33333L8.33331 25Z"/>
      </DefaultIcon>
    </>
  );
};