import { useState, useCallback, useEffect, useMemo } from "react";
import { Box, Button, Card, Grid, Tooltip, Typography } from "@material-ui/core";
import { useStyles } from "../../../clientes/components/clientes-list/clientes-list-styles";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles";
import { DesempenhoRevendaListData } from "./desempenho-revenda-list-data";
import { RevendaDesempenhoModel } from "model/api/gestao/revendedor/revenda-desempenho-model";
import { useGetRevendaDesempenho } from "data/api/gestao/resultados/get-revenda-desempenho";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { useHistory } from "react-router-dom";
import { DesempenhoRevendaSearchProps } from "./desempenho-revenda-search-props";
import { useStyles as useLocalStyles } from './desempenho-revenda-list-styles'
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { toDecimalString } from "utils/to-decimal";
import { useThemeQueries } from "views/theme";
import { ExportarExcelIcon } from "views/components/icons/exportar-excel-icon";
import { useGetRevendaDesempenhoExcel } from "data/api/gestao/resultados/excel/get-revenda-desempenho-excel";
import { toCurrency } from "utils/toCurrency";
import classNames from "classnames";
import { InformacaoIcon } from "views/components/icons";

interface RevendedorListProps {
  searchProps: DesempenhoRevendaSearchProps;
}

export const DesempenhoRevendaList = ({ searchProps }: RevendedorListProps) => {
  const classes = useStyles();
  const listClasses = useDefaultCadastroStyles();
  const { footer, saldoNegativo, saldoPositivo, tooltipDetalhes, bold, semiBold, ...localClasses } = useLocalStyles();
  const { tipoUsuario } = useSessaoAtual();
  const { isMobile, theme } = useThemeQueries();

  const isAllowed = 
    tipoUsuario() === EnumTipoPessoas.SoftwareHouse || 
    tipoUsuario() === EnumTipoPessoas.GerenteComercial ||
    tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouse ||
    tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouseSemCusto

  const { showToast } = useToastSaurus();
  const { push } = useHistory();

  const { getRevendaDesempenho, carregando: carregandoGet } = useGetRevendaDesempenho();
  const { getRevendaDesempenhoExcel, carregando: carregandoExcel } = useGetRevendaDesempenhoExcel();

  const carregando = carregandoGet || carregandoExcel

  const [list, setList] = useState<RevendaDesempenhoModel[]>([]);

  const valores = useMemo(() => {
    // const mediaTotal = list.reduce<number>((prev, curr) => prev + curr.media, 0) / list.length
    const mediaSaldoTerminais = list.reduce<number>((prev, curr) => prev + curr.terminaisSaldo, 0) / list.length
    const mediaSaldoClientes = list.reduce<number>((prev, curr) => prev + curr.cnpjSaldo, 0) / list.length
    const mediaPontos = list.reduce<number>((prev, curr) => prev + curr.pontosMedia, 0) / list.length
    const somaUltimaFatura = list.reduce<number>((prev, curr) => prev + curr.faturaUltima, 0)
    const somaFaturaInicial = list.reduce<number>((prev, curr) => prev + curr.faturaInicial, 0)

    const somaClientesAdicionados = list.reduce<number>((prev, curr) => prev + curr.cnpjAdicionados, 0)
    const somaTerminaisAdicionados = list.reduce<number>((prev, curr) => prev + curr.terminaisAdicionados, 0)

    const somaClientesDesativados = list.reduce<number>((prev, curr) => prev + curr.cnpjDesativados, 0)
    const somaTerminaisDesativados = list.reduce<number>((prev, curr) => prev + curr.terminaisDesativados, 0)

    const somaRevendas = list.length
    const somaGerentes = list.reduce<{ soma: number, gerentes: string[] }>((prev, curr) => {
      if (!prev.gerentes.includes(curr.gerenteId)) {
        return {
          soma: prev.soma + 1,
          gerentes: [...prev.gerentes, curr.gerenteId]
        }
      }
      return prev
    }, {
      soma: 0,
      gerentes: []
    }).soma

    const diferencaValor = somaUltimaFatura - somaFaturaInicial
    const percentualTotal = (diferencaValor / somaFaturaInicial) * 100

    return {
      // mediaTotal,
      mediaSaldoTerminais,
      mediaSaldoClientes,
      percentualTotal,
      somaGerentes,
      somaRevendas,
      mediaPontos,
      somaUltimaFatura,
      somaFaturaInicial,

      somaClientesAdicionados,
      somaClientesDesativados,
      somaTerminaisAdicionados,
      somaTerminaisDesativados
    }
  }, [list])

  const search = useCallback(async (newPage: number) => {
    try {
      const searchParams = new URLSearchParams();

      if (searchProps.gerenteNome) {
        searchParams.append('gerenteNome', searchProps.gerenteNome)
      }

      if (searchProps.revendaNome) {
        searchParams.append('revendaNome', searchProps.revendaNome)
      }

      const query = searchParams.toString();

      const res = await getRevendaDesempenho(searchProps.anoMesApuracao, query);
      if (res.erro) throw res.erro;

      if (res.resultado?.data === undefined) {
        return
      }

      setList(res.resultado.data)
    } catch (e: any) {
      showToast("error", e.message);
    }
  }, [getRevendaDesempenho, searchProps.anoMesApuracao, searchProps.gerenteNome, searchProps.revendaNome, showToast])

  const getExcel = useCallback(async () => {
    try {
      const searchParams = new URLSearchParams();

      if (searchProps.gerenteNome) {
        searchParams.append('gerenteNome', searchProps.gerenteNome)
      }

      if (searchProps.revendaNome) {
        searchParams.append('revendaNome', searchProps.revendaNome)
      }

      const query = searchParams.toString();

      const res = await getRevendaDesempenhoExcel(searchProps.anoMesApuracao, query)
      if (res.erro) throw (res.erro)
      if (!res.resultado) return;

      const blob = new Blob([res.resultado?.data], { type: 'application/vnd.ms-excel' });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a')

      anchor.href = url
      anchor.download = `Desempenho-Revendas-${new Date().toLocaleDateString().replaceAll('/', '-')}.xls`
      anchor.click()

    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getRevendaDesempenhoExcel, searchProps.anoMesApuracao, searchProps.gerenteNome, searchProps.revendaNome, showToast])

  useEffect(() => {
    search(1)
  }, [search])

  const onClickCard = useCallback((id: string) => {
    push('/desempenho-revendedores/' + id + '/mes')
  }, [push])

  const retornarClasseSaldo = useCallback((saldo: number) => {
    if (saldo === 0) return ''
    if (saldo > 0) return saldoPositivo
    return saldoNegativo
  }, [saldoNegativo, saldoPositivo])

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Box display='flex' flexDirection='column' height='100%' justifyContent='space-between' flex={1} overflow='auto'>
          <Grid container className={listClasses.listContainer}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: theme.spacing(1) }}>
              <Card style={{ marginRight: 8, marginLeft: 24, display: 'flex', alignItems: 'center', maxHeight: '40px' }}>
                <Tooltip arrow title="Exportar Excel">
                  <Button color="primary" onClick={() => getExcel()}>
                    <ExportarExcelIcon tipo="BUTTON_EXPORT_MOBILE" />
                  </Button>
                </Tooltip>
              </Card>
            </Grid>
            <Grid item xs={12} className={classes.listContainer}>
              <DesempenhoRevendaListData
                list={list}
                carregando={carregando}
                onClick={onClickCard}
              />
            </Grid>
          </Grid>
          {isAllowed && list.length > 0 ? <div className={footer}>
            <Box p={1} display={'flex'} flexWrap='wrap' alignItems={'center'} justifyContent={"space-between"} width={"100%"}>
              {/* <Box ml={1}>
                <Typography variant='caption'>
                  Média Total
                </Typography>
                <Typography variant="body1">
                  <Box fontWeight={600}>
                    {toDecimalString(valores.mediaTotal)}
                  </Box>
                </Typography>
              </Box> */}
              <Box ml={1}>
                <Typography variant='caption'>
                  Revendas
                </Typography>
                <Typography variant="body1" align='center'>
                  <Box fontWeight={600}>
                    {valores.somaRevendas}
                  </Box>
                </Typography>
              </Box>
              <Box ml={1}>
                <Typography variant='caption'>
                  Gerentes
                </Typography>
                <Typography variant="body1" align='center'>
                  <Box fontWeight={600}>
                    {valores.somaGerentes}
                  </Box>
                </Typography>
              </Box>
              <Box ml={1}>
                <Typography variant='caption'>
                  Media de Pontos
                </Typography>
                <Typography variant="body1" align='center'>
                  <Box fontWeight={600}>
                    {toDecimalString(valores.mediaPontos)}
                  </Box>
                </Typography>
              </Box>
              <Tooltip
                title={(
                  <Box className={tooltipDetalhes}>
                    <div>
                      <Typography variant='caption' color='textPrimary'>Terminais Adicionados: </Typography>
                      <Typography variant='caption' color='textPrimary' className={
                        classNames(semiBold, retornarClasseSaldo(1))
                      }>{valores.somaTerminaisAdicionados}</Typography>
                    </div>
                    <div>
                      <Typography variant='caption' color='textPrimary'>Terminais Desativados: </Typography>
                      <Typography variant='caption' color='textPrimary' className={
                        classNames(semiBold, retornarClasseSaldo(-1))
                      }>{valores.somaTerminaisDesativados}</Typography>
                    </div>
                  </Box>
                )}
                classes={localClasses}>
                <Box ml={1}>
                  <Typography variant='caption'>
                    {isMobile ? 'Média Terminais' : 'Média Saldo de Terminais'}
                  </Typography>
                  <Typography variant="body1" className={classNames(retornarClasseSaldo(valores.mediaSaldoTerminais), localClasses.textIcon)} align='center'>
                    <Box fontWeight={600}>
                      {toDecimalString(valores.mediaSaldoTerminais)}
                    </Box>
                    <InformacaoIcon class={localClasses.miniIcon} tipo='GERAL' />
                  </Typography>
                </Box>
              </Tooltip>

              <Tooltip
                title={(
                  <Box className={tooltipDetalhes}>
                    <div>
                      <Typography variant='caption' color='textPrimary'>Clientes Adicionados: </Typography>
                      <Typography variant='caption' color='textPrimary' className={
                        classNames(semiBold, retornarClasseSaldo(1))
                      }>{valores.somaClientesAdicionados}</Typography>
                    </div>
                    <div>
                      <Typography variant='caption' color='textPrimary'>Clientes Desativados: </Typography>
                      <Typography variant='caption' color='textPrimary' className={
                        classNames(semiBold, retornarClasseSaldo(-1))
                      }>{valores.somaClientesDesativados}</Typography>
                    </div>
                  </Box>
                )}
                classes={localClasses}>
                <Box ml={1}>
                  <Typography variant='caption'>
                    {isMobile ? 'Média Clientes' : 'Média Saldo de Clientes'}
                  </Typography>
                  <Typography variant="body1" className={classNames(retornarClasseSaldo(valores.mediaSaldoClientes), localClasses.textIcon)} align='center'>
                    <Box fontWeight={600}>
                      {toDecimalString(valores.mediaSaldoClientes)}
                    </Box>
                    <InformacaoIcon class={localClasses.miniIcon} tipo='GERAL' />
                  </Typography>
                </Box>
              </Tooltip>
              <Tooltip title={
                <Box className={tooltipDetalhes}>
                  <div>
                    <Typography variant='caption' color='textPrimary'>Soma das Faturas Iniciais: </Typography>
                    <Typography variant='caption' color='textPrimary' className={bold}>{toCurrency(valores.somaFaturaInicial)}</Typography>
                  </div>
                  <div>
                    <Typography variant='caption' color='textPrimary'>Soma das Últimas Faturas: </Typography>
                    <Typography variant='caption' color='textPrimary' className={bold}>{toCurrency(valores.somaUltimaFatura)}</Typography>
                  </div>
                </Box>
              } classes={localClasses}>
                <Box ml={1}>
                  <Typography variant='caption'>
                    Média de Crescimento
                  </Typography>
                  <Typography variant="body1" className={classNames(retornarClasseSaldo(valores.percentualTotal), localClasses.textIcon)} align='center'>
                    <Box fontWeight={600}>
                      {toDecimalString(valores.percentualTotal)}%
                    </Box>
                    <InformacaoIcon class={localClasses.miniIcon} tipo='GERAL' />
                  </Typography>
                </Box>
              </Tooltip>
            </Box>
          </div> : null}
        </Box>
      </div>
    </>
  );
};
