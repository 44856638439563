import { Box, Grid, useTheme } from '@material-ui/core';
import { useGetHistoricoTerminal } from 'data/api/gestao/terminal/get-historico-terminal';
import { TerminalHistoricoModel } from 'model/api/gestao/terminal/terminal-historico-model';
import { useCallback, useEffect, useState } from 'react'
import { useToastSaurus } from 'services/app';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { Paginacao } from 'views/components/paginacao';
import { CircularLoading } from 'views/components/utils';
import DialogHistoricoTerminal from '../../dialog-historico-terminal/dialog-historico-terminal';
import { useStyles } from '../../dialog-terminal-styles'
import { TerminalHistoricoList } from './components/terminal-historico-list/terminal-historico-list';

interface AccordionTerminalHistoricoProps {
    terminalId: string;
    openned: boolean;
}

export default function AccordionTerminalHistorico({
    terminalId,
    openned
}: AccordionTerminalHistoricoProps) {
    const theme = useTheme();
    const { showToast } = useToastSaurus()
    const classes = useStyles()

    const { getHistoricoTerminal, carregando } = useGetHistoricoTerminal()

    const [queryStatus, setQueryStatus] = useState({
        page: 1,
        totalPages: 0,
        totalResults: 0,
        list: Array<TerminalHistoricoModel>(),
    });

    const [selectedModel, setSelectedModel] = useState<TerminalHistoricoModel>(new TerminalHistoricoModel());

    const [openAccordion, setOpenAccordion] = useState<boolean>(false)
    const [openDialog, setOpenDialog] = useState<boolean>(false)

    const fillResult = useCallback(
        (
            page: number,
            totalPages: number,
            totalResults: number,
            list: Array<TerminalHistoricoModel>
        ) => {
            setQueryStatus({
                page: page,
                list: list,
                totalResults: totalResults,
                totalPages: totalPages,
            });
        },
        []
    );

    const search = useCallback(
        async (newPage: number) => {
            try {
                const res = await getHistoricoTerminal(terminalId, newPage, `pageSize=10`);
                if (res.erro) throw res.erro;

                if (!res.resultado) {
                    return;
                }

                //se o index for maior que as paginas ele busca a ultima
                if (
                    res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
                    res.resultado?.data?.totalResults > 0
                ) {
                    await search(res.resultado?.data?.totalPages);
                    return;
                }

                fillResult(
                    res.resultado?.data?.pageIndex,
                    res.resultado?.data?.totalPages,
                    res.resultado?.data?.totalResults,
                    res.resultado?.data?.list
                );

                return res;
            } catch (e: any) {
                showToast("error", e.message);
            }
        },
        [getHistoricoTerminal, terminalId, fillResult, showToast]
    );

    const pageChanged = useCallback(
        async (newPage: number) => {
            if (newPage <= queryStatus.totalPages || newPage > 0) {
                await search(newPage);
            }
        },
        [search, queryStatus.totalPages]
    );

    const changeSelectedModel = useCallback((model: TerminalHistoricoModel) => {
        setSelectedModel(model)
        setOpenDialog(true)
    }, [])

    useEffect(() => {
        if (openned)
            search(1)
    }, [search, openned])

    return (
        <AccordionSaurus
            labelPrimary="Histórico"
            tipoExpand="bold"
            noPaperRoot={false}
            heightDivider={2}
            showDivider={openAccordion}
            colorDivider={theme.palette.primary.main}
            colorExpand={theme.palette.primary.main}
            expanded={openAccordion}
            onChange={() => setOpenAccordion(prev => !prev)}
        >
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <Box className={classes.cardContainer} style={{ width: '100%' }}>
                <Box className={classes.cardContent}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Paginacao
                                pageChanged={pageChanged}
                                totalPages={queryStatus.totalPages}
                                totalRegisters={queryStatus.totalResults}
                                currentPage={queryStatus.page}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TerminalHistoricoList
                                carregando={carregando}
                                list={queryStatus.list}
                                onClick={changeSelectedModel}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <DialogHistoricoTerminal
                    model={selectedModel}
                    openned={openDialog}
                    closeDialog={() => setOpenDialog(false)}
                />
            </Box>
        </AccordionSaurus>
    );
}