import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { PessoaWhitelabelModel } from 'model/api/gestao/pessoa/pessoa-whitelabel-model';

export function usePostAdicionarPessoaWhitelabel() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<PessoaWhitelabelModel>();

    const postPessoaWhitelabel = useCallback(
        (pessoaId: string, model: PessoaWhitelabelModel) =>
            invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/pessoa/${pessoaId}/Whitelabel`,
                data: model,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postPessoaWhitelabel,
    };
}
