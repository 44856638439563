import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  TextFieldSaurus,

} from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { picker } from 'utils/picker';
import { PessoaMensagemModel } from 'model/api/gestao/pessoa/pessoa-mensagem-model';
import { useFormPessoaMensagemValidation } from './form-pessoa-mensagem-validation';



export const FormPessoaMensagem = forwardRef<
  DefaultFormRefs<PessoaMensagemModel>,
  DefaultFormProps<PessoaMensagemModel>
>(
  (
    { loading, onSubmit, ...props }: DefaultFormProps<PessoaMensagemModel>,
    ref,
  ) => {
    const { isMobile } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);
    const {formPessoaMensagemValidationYup} = useFormPessoaMensagemValidation()
    const [modelForm, setModelForm] = useState<PessoaMensagemModel>(new PessoaMensagemModel())


    const {
      handleSubmit,
      control,
      formState: { errors, },
      reset,
    } = useForm<PessoaMensagemModel>({
      defaultValues: { ...modelForm },
      resolver: yupResolver(formPessoaMensagemValidationYup),
      criteriaMode: 'all',
      mode: 'onBlur' && 'onChange',
    });

    const carregando = loading

    const submitEnviar = (values: PessoaMensagemModel) => {
      const mensagem = picker<PessoaMensagemModel>(values, modelForm)
      onSubmit(mensagem, modelForm);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitEnviar)();
      },
      resetForm: () => {
        reset();
        if (!isMobile) refInputNome.current?.focus();
      },
      fillForm: (model: PessoaMensagemModel) => {
        setModelForm({ ...model })
        reset({ ...model });
        if (!isMobile) refInputNome.current?.focus();
      },
    }));

    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {carregando && props.showLoading && (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="FULLSIZED" />
              </div>
            )}

            <form
              onSubmit={handleSubmit(submitEnviar)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <Controller
                    name="mensagemAosContratos"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        inputRef={refInputNome}
                        disabled={loading}
                        allowSubmit={false}
                        label="Mensagem aos Clientes"
                        fullWidth
                        tipo="TEXTO"
                        multiline
                        rows={4}
                        autoComplete={'off'}
                        helperText={
                          errors.mensagemAosContratos
                            ? errors.mensagemAosContratos.message
                            : undefined
                        }
                        error={Boolean(errors.mensagemAosContratos && errors.mensagemAosContratos.message)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);