import * as Yup from 'yup';

export const useAccordionAvaliacaoAgenteValidation = () => {

  const FormAvalicacaoAgenteValidationYup = Yup.object().shape({
    avaliacaoIndicador: Yup.number().required('A avaliação do agente é obrigatório.').typeError('A avaliação do agente é obrigatório.'),
    motivoAvaliacaoIndicador: Yup.string().required('O motivo da avaliação do agente é obrigatório.').typeError('O motivo da avaliação do agente é obrigatório.')
});

  return {
    FormAvalicacaoAgenteValidationYup,
  }
}

