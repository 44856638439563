import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePutAnteciparFatura() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putAnteciparFatura = useCallback(
        async (contratoId: string, competenciaFinal: number, faturaPaiId?: string) => {

            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Contrato/${contratoId}/antecipar-faturas?${faturaPaiId ? `faturaPaiId=${faturaPaiId}&` : ''}competenciaFinal=${competenciaFinal}`,
                method: 'PUT',
                enviarTokenUsuario: true
            })
        }
        , [invocarApi]
    );
    return {
        ...outrasPropriedades,
        putAnteciparFatura,
    };
}
