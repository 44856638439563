
import { useMemo } from 'react';
import * as Yup from 'yup';


export const useFormRespostaApuracaoValidation = (maxPontos: number) => {


  const FormRespostaApuracaoValidation = useMemo(() => {
    return Yup.object().shape({
      // pontos: Yup.number().max(maxPontos, 'Limite de Pontos excedido.').typeError('Informe os Pontos')
    });
  }, [])

  return {
    FormRespostaApuracaoValidation,
  }
}