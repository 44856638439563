import { Box, Button, Grid } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-pessoa-info-styles';
import { CircularLoading, useThemeQueries } from 'views';
import { useCallback, useEffect, useRef } from 'react';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { PessoaInfoModel } from 'model/api/gestao/pessoa/pessoa-info-model';
import { FormPessoaInfo } from 'views/components/form/master/pessoa/pessoa-info/form-pessoa-info';
import { DefaultFormRefs } from 'views/components/form/utils';
import { isEqual } from 'lodash';
import { guidEmpty } from 'utils/guid-empty';
import { CancelarIcon, SalvarIcon, VoltarIcon } from 'views/components/icons';
import { useDeletePessoaInfo } from 'data/api/gestao/pessoa-info/delete-pessoa-info';
import { useConfirm } from 'material-ui-confirm';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';

interface DialogPessoaInfoProps {
  openned: boolean;
  closeModal: () => void;
  model: PessoaInfoModel;
  carregando: boolean;
  esconderAbertura?: boolean;
  pessoaFisica?: boolean;
  enviarModel: (model: PessoaInfoModel) => Promise<boolean>;
}

export const DialogPessoaInfo = ({
  openned,
  closeModal,
  model,
  carregando,
  enviarModel,
  esconderAbertura,
  pessoaFisica
}: DialogPessoaInfoProps) => {
  const classes = useStyles();
  const { acoes } = useModalStyles()
  const { showToast } = useToastSaurus()

  const { isMobile } = useThemeQueries()

  const formRef = useRef<DefaultFormRefs<PessoaInfoModel>>(null)

  const { deletePessoaInfo, carregando: carregandoDelete } = useDeletePessoaInfo()

  const {tipoUsuario} = useSessaoAtual();

  const confirm = useConfirm()

  const loading = carregando || carregandoDelete

  const recarregarForm = useCallback((modelForm: PessoaInfoModel) => {
    formRef.current?.fillForm(modelForm)
  }, [])

  useEffect(() => {
    recarregarForm(model);
  }, [model, recarregarForm])

  const handleSubmit = useCallback(async (actualModel: PessoaInfoModel, beforeModel: PessoaInfoModel) => {
    const equal = isEqual(actualModel, beforeModel)

    if (equal) {
      showToast('info', 'Nenhuma informação foi alterada.')
      return
    }

    const res = await enviarModel(actualModel)

    if (!res) {
      return
    }

    closeModal()

  }, [closeModal, enviarModel, showToast])

  const handleDelete = useCallback(async () => {
    confirm({
      title: 'Excluir informação',
      cancellationText: 'Cancelar',
      confirmationText: 'Excluir',
      description: 'Tem certeza que deseja excluir esta informação?',

    }).then(async () => {
      try {
        const res = await deletePessoaInfo(model.pessoaId, model.id)

        if (res.erro) throw res.erro

        showToast('success', 'Informação excluída.')

        closeModal()
      } catch (e: any) {
        showToast('error', e.message)
      }
    }).catch(() => { })
  }, [closeModal, confirm, deletePessoaInfo, model.id, model.pessoaId, showToast])

  const isFuncionario = tipoUsuario() === EnumTipoPessoas.Funcionario || tipoUsuario() === EnumTipoPessoas.FuncionarioSoftwareHouse

  return (
    <DialogSaurus
      aberto={openned}
      titulo={model.id === guidEmpty() ? "Nova Informação" : "Editar Informação"}
      tamanho="lg"
      fullScreen={isMobile}
      bottomArea={
        <div className={acoes}>
          <Grid container spacing={2} >
            <Grid item xs={12} md={8}>
              {(model.id !== guidEmpty() && !isFuncionario) && <Button variant='outlined' className={classes.buttonRemover} style={{ height: 36 }}
                onClick={handleDelete} fullWidth={isMobile}>
                <CancelarIcon tipo='BUTTON_PRIMARY' />
                Excluir
              </Button>}
            </Grid>
            {!isMobile && <Grid item md={2}>
              <Button
                variant='outlined'
                color='primary'
                onClick={closeModal}
                fullWidth
              >
                <VoltarIcon tipo='BUTTON' />
                Voltar
              </Button>
            </Grid>}
            <Grid item xs={12} md={2}>
              <Button
                disabled={carregando}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => formRef.current?.submitForm()}
              >
                <SalvarIcon tipo='BUTTON_PRIMARY' />
                Salvar
              </Button>
            </Grid>
            {isMobile && <Grid item xs={12}>
              <Button
                variant='outlined'
                color='primary'
                onClick={closeModal}
                fullWidth
              >
                <VoltarIcon tipo='BUTTON' />
                Voltar
              </Button>
            </Grid>}
          </Grid>
        </div>
      }
    >
      {loading && <CircularLoading tipo="FULLSIZED" />}
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={12}>
                <FormPessoaInfo
                  aberto={openned}
                  onSubmit={handleSubmit}
                  loading={carregando}
                  showLoading={false}
                  ref={formRef}
                  esconderAbertura={esconderAbertura}
                  pessoaFisica={pessoaFisica}
                  block={isFuncionario}
                />
              </Grid>
              {/* <Grid item xs={12}>
                {closeModal && (
                  <Button
                    disabled={carregando}
                    variant="text"
                    fullWidth
                    color="primary"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    Voltar
                  </Button>
                )}
              </Grid> */}
            </Grid>
          </Box>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
