import { PesquisaHeaderProps } from './pesquisa-notas-fiscais-revenda-props';
import { useStyles } from './pesquisa-notas-fiscais-revenda-styles';
import { Button, Grid, Fade, useTheme, Tooltip } from '@material-ui/core';
import { useState, useCallback, ChangeEvent, useRef, useEffect, useMemo } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { ProcurarIcon } from 'views/components/icons';
import { CircularLoading, useThemeQueries } from 'views';
import { NotasFiscaisRevendaListSearchProps } from '../../../notas-fiscais-revenda-list/notas-revenda-fiscais-revenda-list-search-props';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { SituacaoMockTodos } from 'data/mocks/situacao-mock';
import { toDateString } from 'utils/to-date';
import { stringNumeros } from 'utils/string-numeros';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { validarCPFCNPJ } from 'utils/cpfcnpj-validate';
import { EnumSituacao } from 'model/enums/enum-situacao';
import { isEmpty } from 'lodash';
import { EnumTipoFiltroData } from 'model/enums/enum-tipo-filtro-data';
import { TipoFiltroDataMock } from 'data/mocks/tipo-filtro-data-mock';
import { useGetPagamento } from 'data/api/gestao/pagamento';
import { KeyValueModel } from 'model';
import { TipoComunicacaoMock } from 'data/mocks/tipo-comunicacao-mock';

export const PesquisaFaturas = (props: PesquisaHeaderProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { showToast } = useToastSaurus();
  const { isMobile, md } = useThemeQueries();
  const { tipoUsuario } = useSessaoAtual();

  const isRevendedor = tipoUsuario() === EnumTipoPessoas.Revendedor || tipoUsuario() === EnumTipoPessoas.Funcionario || tipoUsuario() === EnumTipoPessoas.FuncionarioFinanceiro || tipoUsuario() === EnumTipoPessoas.FuncionarioFinanceiroSemCusto

  // const { getPessoaById, carregando: carregandoPagamentoPessoa } = useGetPessoaFormaPagamentoById()
  const { getPagamento, carregando: carregandoPagamento } = useGetPagamento();

  const [formasPagamento, setFormasPagamento] = useState<KeyValueModel[]>([])


  const getCurrentMonth = useCallback(() => {
    const month = new Date().getMonth() + 1
    const year = new Date().getFullYear()
    return {
      inicial: new Date(`${month}/01/${year - 1}`).toString(),
      final: new Date(`${month}/31/${year}`).toString()
    }
  }, [])

  const pesquisaInicial = useMemo<NotasFiscaisRevendaListSearchProps>(() => {
    return {
      cpfCnpjPagador: '',
      dataFinal: '',
      CompetenciaInicial: '',
      CompetenciaFinal: '',
      dataInicial: isRevendedor ? (toDateString(getCurrentMonth().inicial, 'yyyy-MM-DD') || '') : '',
      NomePagador: '',
      nossoNumero: null,
      Situacao: isRevendedor ? EnumSituacao.Todos : EnumSituacao.Aberta,
      tipoData: EnumTipoFiltroData.Vencimento,
      atualizarPesquisa: false,
      formaPagamentoId: -1
    }
  }, [getCurrentMonth, isRevendedor])

  const [pesquisa, setPesquisa] = useState<NotasFiscaisRevendaListSearchProps>(pesquisaInicial);
  const refInput = useRef<HTMLDivElement | null>(null);
  const [error, setError] = useState<boolean>(false)

  //FOCO AO FICAR VISIVEL
  useEffect(() => {
    if (props.isOpened && !isMobile) refInput.current?.focus();
    else {
      setPesquisa(pesquisaInicial);
    }
  }, [isMobile, pesquisaInicial, props.isOpened]);

  //CHAMADA DO PESQUISAR
  const pesquisar = useCallback(
    (event: any) => {
      event.preventDefault();
      if (pesquisa.Situacao !== EnumSituacao.Aberta && isEmpty(pesquisa.dataInicial)) {
        showToast('error', 'Informe uma Data de Vencimento Inicial')
        return
      }
      if (!isEmpty(pesquisa.dataInicial) && new Date(pesquisa.dataInicial) > new Date(pesquisa.dataFinal)) {
        showToast('error', 'Data de Vencimento Inicial é maior do que a Data de Vencimento Final')
        return
      }
      setPesquisa(prev => {
        prev.cpfCnpjPagador = stringNumeros(prev.cpfCnpjPagador)
        try {
          prev.nossoNumero = isNaN(Number(prev.nossoNumero)) ? null : prev.nossoNumero
        } catch (e) {
          prev.nossoNumero = null
        }
        return prev
      })
      props.onPesquisar(pesquisa);
    },
    [pesquisa, props, showToast],
  );


  //ALTERAR A PESQUISA
  const onChangePesquisa = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event.target.name === 'Situacao' && Number(event.target.value) !== EnumSituacao.Aberta) {
        setPesquisa(prev => {
          return {
            ...prev,
            dataInicial: prev.dataInicial ? prev.dataInicial : (toDateString(getCurrentMonth().inicial, 'yyyy-MM-DD') || ''),
            [event.target.name]: event.target.value
          }
        })

        return
      }
      setPesquisa(prev => {
        return {
          ...prev,
          [event.target.name]: event.target.value
        }
      });
    },
    [getCurrentMonth],
  );

  const validacaoCampoCPFCNPJ = useCallback(() => {
    if (!validarCPFCNPJ(pesquisa.cpfCnpjPagador) && pesquisa.cpfCnpjPagador.length > 0) {
      setError(true)
      return
    }
    setError(false)
  }, [pesquisa.cpfCnpjPagador])

  const tipoFiltroMock = useMemo<KeyValueModel[]>(() => {
    let mock = TipoFiltroDataMock;

    if (pesquisa.Situacao === EnumSituacao.Aberta || pesquisa.Situacao === EnumSituacao.EmPagamento || pesquisa.Situacao === EnumSituacao.Fechada || pesquisa.Situacao === EnumSituacao.Atraso) {
      mock = mock.filter(item => item.Key !== EnumTipoFiltroData.Bonificacao &&
        item.Key !== EnumTipoFiltroData.Cancelamento &&
        item.Key !== EnumTipoFiltroData.Pagamento)

      setPesquisa(prev => ({
        ...prev,
        tipoData: mock[0].Key
      }))

      return mock
    }

    if (pesquisa.Situacao === EnumSituacao.Bonificada || pesquisa.Situacao === EnumSituacao.BonificadoRevenda) {
      mock = mock.filter(item => item.Key === EnumTipoFiltroData.Bonificacao)

      setPesquisa(prev => ({
        ...prev,
        tipoData: mock[0].Key
      }))

      return mock
    }

    if (pesquisa.Situacao === EnumSituacao.Paga) {
      mock = mock.filter(item => item.Key === EnumTipoFiltroData.Pagamento)

      setPesquisa(prev => ({
        ...prev,
        tipoData: mock[0].Key
      }))

      return mock

    }

    if (pesquisa.Situacao === EnumSituacao.Cancelada) {
      mock = mock.filter(item => item.Key === EnumTipoFiltroData.Cancelamento)

      setPesquisa(prev => ({
        ...prev,
        tipoData: mock[0].Key
      }))

      return mock
    }

    return mock
  }, [pesquisa.Situacao])

  const getFormaDePagamentoWrapper = useCallback(async () => {
    try {

      const res = await getPagamento(1, 'pageSize=9999');

      if (res.erro) throw res.erro

      const mock = res.resultado!.data.list.map((fp) => {
        const label = `${fp.nome} - ${TipoComunicacaoMock.find(x => x.Key === fp.tipoComunicacao)?.Value}`
        return new KeyValueModel(fp.id, label)
      })


      setFormasPagamento([new KeyValueModel(-1, 'Todas'), new KeyValueModel(-5, ''), ...mock])
      return

      // const res = await getPessoaById((tipoUsuario() === EnumTipoPessoas.Revendedor ? usuario?.Id : usuario?.RevendaId) || '')

      // if (res.erro) throw res.erro

      // const mock = res.resultado!.data.list.map((fp) => new KeyValueModel(fp.formaPagamento.id, fp.formaPagamento.nome))

      // setFormasPagamento([new KeyValueModel(-1, 'Todas'), new KeyValueModel(-5, ''), ...mock])
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [getPagamento, showToast])

  useEffect(() => {
    if (props.isOpened) {
      getFormaDePagamentoWrapper();
    }
  }, [getFormaDePagamentoWrapper, props.isOpened])

  const carregando = carregandoPagamento

  return (
    <Fade in={props.isOpened} mountOnEnter unmountOnExit>
      <form onSubmit={pesquisar}>
        {carregando && <CircularLoading tipo='FULLSIZED' />}
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12} md={4} lg={2}>
            <SelectSaurus
              name="tipoData"
              label="Filtrar Data por"
              conteudo={tipoFiltroMock}
              fullWidth
              size="small"
              allowSubmit={true}
              value={pesquisa.tipoData}
              onChange={onChangePesquisa}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextFieldSaurus
              name="dataInicial"
              fullWidth
              size="small"
              label="Data Inicial"
              tipo='DATA'
              allowSubmit={true}
              value={pesquisa.dataInicial}
              placeholder="Data Inicial"
              onChange={onChangePesquisa}
              adornmentColor={theme.palette.text.disabled}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextFieldSaurus
              name="dataFinal"
              fullWidth
              label="Data Final"
              size="small"
              tipo='DATA'
              allowSubmit={true}
              value={pesquisa.dataFinal}
              placeholder="Data Final"
              onChange={onChangePesquisa}
              adornmentColor={theme.palette.text.disabled}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <TextFieldSaurus
              name="CompetenciaInicial"
              fullWidth
              size="small"
              label="Competência Inicial"
              tipo='NUMERO'
              allowSubmit={true}
              value={pesquisa.CompetenciaInicial}
              placeholder="Ano/Mês"
              onChange={onChangePesquisa}
              adornmentColor={theme.palette.text.disabled}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <TextFieldSaurus
              name="CompetenciaFinal"
              fullWidth
              label="Competência Final"
              size="small"
              tipo='NUMERO'
              allowSubmit={true}
              value={pesquisa.CompetenciaFinal}
              placeholder="Mês/Ano"
              onChange={onChangePesquisa}
              adornmentColor={theme.palette.text.disabled}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <SelectSaurus
              name="Situacao"
              label="Situação"
              conteudo={SituacaoMockTodos}
              fullWidth
              size="small"
              allowSubmit={true}
              value={pesquisa.Situacao}
              placeholder="Situação"
              onChange={onChangePesquisa}
            />
          </Grid>
          {!props.isLicenca && <Grid item xs={6} md={4} lg={isRevendedor ? 3 : 2}>
            <SelectSaurus
              name="formaPagamentoId"
              label="Forma de Pagamento"
              conteudo={formasPagamento}
              fullWidth
              size="small"
              allowSubmit={true}
              value={pesquisa.formaPagamentoId}
              placeholder="Situação"
              onChange={onChangePesquisa}
            />
          </Grid>}
          <Grid item xs={12} md={4} lg={isRevendedor ? 3 : (props.isLicenca ? 3 : 2)}>
            <TextFieldSaurus
              name="nossoNumero"
              fullWidth
              label="Nº da Fatura"
              size="small"
              tipo='NUMERO'
              allowSubmit={true}
              value={pesquisa.nossoNumero}
              onChange={onChangePesquisa}
              adornmentColor={theme.palette.text.disabled}
            />
          </Grid>
          {!isRevendedor && (
            <>
              <Grid item xs={12} md={4} lg={3}>
                <TextFieldSaurus
                  name="NomePagador"
                  fullWidth
                  label="Nome do Pagador"
                  size="small"
                  allowSubmit={true}
                  tipo="TEXTO"
                  value={pesquisa.NomePagador}
                  placeholder="Ex: Miriam"
                  onChange={onChangePesquisa}
                  adornmentColor={theme.palette.text.disabled}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <div className={classes.containerFinal}>
                  <TextFieldSaurus
                    name="cpfCnpjPagador"
                    fullWidth
                    label="CPF/CNPJ do Pagador"
                    size="small"
                    tipo='CNPJ_CPF'
                    manterMascara={false}
                    allowSubmit={true}
                    value={pesquisa.cpfCnpjPagador}
                    onChange={onChangePesquisa}
                    adornmentColor={theme.palette.text.disabled}
                    helperText={error ? 'CPF/CNPJ Inválido.' : undefined}
                    error={error}
                    onBlur={validacaoCampoCPFCNPJ}
                  />

                  {!isMobile && <Tooltip title='Pesquisar'>
                    <Button color="primary" variant="contained" type="submit">
                      <ProcurarIcon tipo="BUTTON_PRIMARY" style={{ margin: 0 }} />
                    </Button>
                  </Tooltip>}
                </div>
              </Grid>
            </>
          )}
          {(isMobile || isRevendedor) && <Grid item xs={12} md={4} lg={4} style={{ display: 'flex', justifyContent: !md ? 'flex-end' : 'flex-start' }}>
            <Tooltip title='Pesquisar'>
              <Button color="primary" variant="contained" type="submit" fullWidth={isMobile}>
                <ProcurarIcon tipo="BUTTON_PRIMARY" />
                Pesquisar
              </Button>
            </Tooltip>
          </Grid>}
        </Grid>
      </form>
    </Fade>
  );
};
