import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    moduloContainer: {
        padding: '1rem',
        background: '#F8F8F8',
        overflowY: 'auto',
        [theme.breakpoints.up('md')]: {
            maxHeight: '500px'
        }
    }
}));
