import { guidEmpty } from "utils/guid-empty";
import { FaturasModel } from "../fatura/faturas-model";

export class ComissaoPendenteModel {
    constructor(
        public id: string = guidEmpty(),
        public comissaoPagamentoId: string = '',
        public comissionadoId: string = '',
        public comissionadoNome: string = '',
        public comissionadoCpfCnpj: string = '',
        public baseCalculo: number = 0,
        public valorComissao: number = 0,
        public fatura: FaturasModel = new FaturasModel()
    ) { }
}
