import { guidEmpty } from "utils/guid-empty";

export class FiltroMapaModel {
  constructor(
    public sistemaId: string = guidEmpty(),
    public tipoBuscaMapa: number = 0,
    public dataAdesaoInicial: string = '',
    public dataAdesaoFinal: string = '',
  ) { }
}


