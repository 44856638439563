import { useCallback } from "react";

export enum GestaoStorageKeys {
  Token,
  TermosDeUso,
  EmpresaAtual,
  PerfisDoUsuario,
  EmailRecuperacaoSenha,
  UsuariosConectados,
  ConfiguracoesMenu,
  RelatorioUsuario,
  PeriodoRelatorio
}

export function useGestaoStorage() {
  const getRegistro = useCallback(
    (key: GestaoStorageKeys, session: boolean = true) =>
      JSON.parse(
        (session
          ? sessionStorage.getItem(GestaoStorageKeys[key].toString())
          : localStorage.getItem(GestaoStorageKeys[key].toString())) || "{}"
      ),
    []
  );
  const exists = useCallback((key: GestaoStorageKeys, session: boolean = true) => {
    if (session) return Boolean(sessionStorage.getItem(GestaoStorageKeys[key].toString()));
    else return Boolean(localStorage.getItem(GestaoStorageKeys[key].toString()));
  }, []);
  const setRegistro = useCallback((key: GestaoStorageKeys, obj: any, session: boolean = true) => {
    if (session) {
      sessionStorage.setItem(GestaoStorageKeys[key].toString(), JSON.stringify(obj));
    } else {
      localStorage.setItem(GestaoStorageKeys[key].toString(), JSON.stringify(obj));
    }
  }, []);
  const delRegistro = useCallback((key: GestaoStorageKeys, session: boolean = true) => {
    if (session) {
      sessionStorage.removeItem(GestaoStorageKeys[key].toString());
    } else {
      localStorage.removeItem(GestaoStorageKeys[key].toString());
    }
  }, []);
  return {
    getRegistro,
    setRegistro,
    delRegistro,
    exists,
  };
}
