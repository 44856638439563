import { useStyles } from './pesquisa-notas-fiscais-rejeitadas-styles';
import { Grid, Fade, useTheme, Button } from '@material-ui/core';
import { useState, useCallback, ChangeEvent, useRef, useEffect, useMemo } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { useThemeQueries } from 'views';
import { isEmpty } from 'lodash';
import { NotasFiscaisRejeitadasListSearchProps } from '../../../notas-fiscais-rejeitadas-list/notas-fiscais-rejeitadas-list-search-props';
import { PesquisaHeaderProps } from './pesquisa-notas-fiscais-rejeitadas-props';
import { useToastSaurus } from 'services/app';
import { PesquisaIcon } from 'views/components/icons/pesquisa-icon';

export const PesquisaNotasFiscaisRejeitadas = (props: PesquisaHeaderProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { showToast } = useToastSaurus();
  const { isMobile } = useThemeQueries();

  const pesquisaInicial = useMemo(() => {
    return {
      dataInicial: '',
      dataFinal: ''
    }
  }, [])

  const [pesquisa, setPesquisa] = useState<NotasFiscaisRejeitadasListSearchProps>(pesquisaInicial);
  const refInput = useRef<HTMLDivElement | null>(null);

  //FOCO AO FICAR VISIVEL
  useEffect(() => {
    if (props.isOpened && !isMobile) refInput.current?.focus();
    else {
      setPesquisa(pesquisaInicial);
    }
  }, [isMobile, pesquisaInicial, props.isOpened]);

  //CHAMADA DO PESQUISAR
  const pesquisar = useCallback(
    (event: any) => {
      event.preventDefault();
      if (!isEmpty(pesquisa.dataInicial) && new Date(pesquisa.dataInicial) > new Date(pesquisa.dataFinal)) {
        showToast('error', 'Data Inicial é maior do que a Data Final')
        return
      }
      props.onPesquisar(pesquisa);
    },
    [pesquisa, props, showToast],
  );


  //ALTERAR A PESQUISA
  const onChangePesquisa = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPesquisa(prev => {
        return {
          ...prev,
          [event.target.name]: event.target.value
        }
      });
    },
    [],
  );

  return (
    <Fade in={props.isOpened} mountOnEnter unmountOnExit>
      <form onSubmit={pesquisar}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12} md={4} lg={3}>
            <TextFieldSaurus
              name="dataInicial"
              fullWidth
              size="small"
              label="Data Inicial"
              tipo='DATA'
              allowSubmit={true}
              value={pesquisa.dataInicial}
              placeholder="Data Inicial"
              onChange={onChangePesquisa}
              adornmentColor={theme.palette.text.disabled}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextFieldSaurus
              name="dataFinal"
              fullWidth
              label="Data Final"
              size="small"
              tipo='DATA'
              allowSubmit={true}
              value={pesquisa.dataFinal}
              placeholder="Data Final"
              onChange={onChangePesquisa}
              adornmentColor={theme.palette.text.disabled}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={6} className={classes.containerBotao}>
            <Button variant='contained' color='primary' type='submit' fullWidth={isMobile}>
                <PesquisaIcon tipo='BUTTON_PRIMARY'/>
                Pesquisar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Fade>
  );
};