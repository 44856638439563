
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormCadastrarPlanoValidation = () => {


  const FormCadastrarPlanoValidationYup = useMemo(() => {
    return Yup.object().shape({
      nome: Yup.string().required('É necessário informar um nome').transform(nome => nome.length > 100 ? null : nome).typeError('Limite de caracteres excedido.'),
      descricao: Yup.string().notRequired(),
      sistemaId: Yup.string().required('É necessário selecionar um sistema')
    });
  }, [])

  return {
    FormCadastrarPlanoValidationYup,
  }
}

