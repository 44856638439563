import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    qrCodecontainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px'
    },
    qrCodeBox: {
        padding: 10
    }
}));
