import { Box, Grid } from '@material-ui/core';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { DashboardHeader } from './components/dashboard-header/dashboard-header';
import DashboardRelatorio from './components/dashboard-relatorio/dashboard-relatorio';
import { useCallback, useState } from 'react';
import { RelatorioSistemaModel } from 'model/api/gestao/relatorios/sistema-relatorio-model';
import { useGetPessoaMapa } from 'data/api/gestao/pessoa-mapa/get-pessoa-mapa';
import { isEmpty } from 'lodash';
import { LicenciamentoDBPrimary } from 'database/licenciamento-database';
import { PessoasMapaModel } from 'model/api/gestao/pessoa/pessoa-mapa-model';
import { GestaoStorageKeys, useGestaoStorage, useSessaoAtual, useToastSaurus } from 'services/app';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { EnumTipoBuscaMapa } from 'model/enums/enum-tipo-busca-mapa';
import { useGetIndicacoes } from 'data/api/gestao/indicacoes/get-indicacoes';
import { TabelaIndicacoes } from 'database/interfaces/interface-tabela-indicacoes';
import { RelatorioIndicacaoModel } from 'model/api/gestao/relatorios/relatorio-indicacoes-model';
import { IndicacoesModel } from 'model/api/gestao/indicacoes/indicacoes-model';
import { RelatorioSearchPropsModel } from 'model/api/gestao/relatorios/relatorio-search-props-model';
import { useGetResultadosRevenda } from 'data/api/gestao/relatorio/get-resultado-revendas';
import { CircularLoading } from 'views/components';
import { SituacaoStatusIndicacaoMock } from 'data/mocks/situacao-status-indicacao-mock';


export const DashboardPage = () => {
  const classes = useDefaultCadastroStyles();
  const { showToast } = useToastSaurus();
  const { tipoUsuario, usuario } = useSessaoAtual()
  const { setRegistro } = useGestaoStorage();


  const [relatorioSistemas, setRelatorioSistemas] = useState<RelatorioSistemaModel[]>([])
  const [relatorioIndicacoes, setRelatorioIndicacoes] = useState<RelatorioIndicacaoModel[]>([])
  const [indicacoes, setIndicacoes] = useState<IndicacoesModel[]>([])

  const { getPessoaMapa, carregando: carregandoGet } = useGetPessoaMapa()
  const { getIndicacoes, carregando: carregandoIndicacoes } = useGetIndicacoes();
  const { getResultadosRevenda, carregando: carregandoResultado } = useGetResultadosRevenda();

  const [loadingRevenda, setLoadingRevenda] = useState<boolean>(false)

  const carregando = carregandoGet || loadingRevenda || carregandoIndicacoes || carregandoResultado

  const isAllowed = tipoUsuario() === EnumTipoPessoas.SoftwareHouse || tipoUsuario() === EnumTipoPessoas.GerenteComercial

  const getResultadoRevendaWrapper = useCallback(async (model: RelatorioSearchPropsModel) => {
    try {
      setLoadingRevenda(prev => !prev ? true : prev)

      const searchParams = new URLSearchParams();

      searchParams.append('anoMesInicial', model.anoMesInicial.toString())
      searchParams.append('anoMesFinal', model.anoMesFinal.toString())

      const res = await getResultadosRevenda(searchParams.toString())
      
      await LicenciamentoDBPrimary.limparCampo('resultadoRevenda')
      if (res.erro) throw res.erro
      if (!res.resultado) throw new Error('Nenhum dado encontrado.')
      if (res.resultado.data.length === 0) return false
      
      const ret = res.resultado.data

      await LicenciamentoDBPrimary.resultadoRevenda.bulkAdd(ret)

      setRegistro(GestaoStorageKeys.PeriodoRelatorio, {
        anoMesInicial: model.anoMesInicial,
        anoMesFinal: model.anoMesFinal
      }, false)

      setRegistro(GestaoStorageKeys.RelatorioUsuario, {
        usuarioId: usuario?.Id
      }, false)

      return true
    } catch (e: any) {
      showToast('error', e.message)
      return false
    }
  }, [getResultadosRevenda, setRegistro, showToast, usuario?.Id])

  const getIndicacoesWrapper = useCallback(async () => {
    try {
      const responsavelId = isAllowed ? '' : (tipoUsuario() === EnumTipoPessoas.Revendedor ? usuario?.Id : usuario?.RevendaId)

      const query = `${!isEmpty(responsavelId) ? `&responsavelId=${responsavelId}` : ''}`

      const res = await getIndicacoes(1, `&pageSize=999999${query}`)

      if (res.erro) throw res.erro
      if (!res.resultado) throw new Error('Erro ao carregar dados.')

      await LicenciamentoDBPrimary.limparCampo('indicacoes')
      await LicenciamentoDBPrimary.indicacoes.bulkAdd(res.resultado.data.list as TabelaIndicacoes[])

      const relatorio = res.resultado.data.list.map(item => new RelatorioIndicacaoModel(
        SituacaoStatusIndicacaoMock.find(x => x.Key === item.status)?.Value,
        1,
        item.status
      ))

      const dadosFinal = relatorio.reduce<RelatorioIndicacaoModel[]>((prev, curr) => {
        const equal = prev.find(x => x.status === curr.status)
        if (equal) {
          const index = prev.indexOf(equal)

          prev[index].quantidade += 1;
          return prev
        }

        prev.push(curr)
        return prev
      }, [])

      setRelatorioIndicacoes(dadosFinal)
      setIndicacoes(res.resultado.data.list)

      return res.resultado.data.list

    } catch (e: any) {
      showToast('error', e.message)
      return []
    }
  }, [getIndicacoes, isAllowed, showToast, tipoUsuario, usuario?.Id, usuario?.RevendaId])

  const getPessoasMapaWrapper = useCallback(
    async (ret: PessoasMapaModel[]) => {
      if (isEmpty(ret)) {
        if (!loadingRevenda) setLoadingRevenda(true)
        try {
          const res = await getPessoaMapa(`tipoBuscaMapa=${EnumTipoBuscaMapa.Clientes}`);
          if (res.erro) throw res.erro;
          if (!res.resultado) return [];

          ret = res.resultado.data

          await LicenciamentoDBPrimary.limparCampo('clientes')
          await LicenciamentoDBPrimary.clientes.bulkAdd(ret)
          
        } catch (e: any) {
          showToast('error', e.message)
          setLoadingRevenda(false)
          return
        }
      }
      const arrSistemas = ret.map(obj => {
        return obj.contratos.reduce<RelatorioSistemaModel[]>((prev, curr) => {
          const equal = prev.find(x => x.sistema === curr.sistema)
          if (prev.length > 0 && equal) {
            const index = prev.indexOf(equal)
            equal.ativos = curr.situacao.toLowerCase() === 'ativo' ? (equal.ativos + 1) : equal.ativos
            equal.inativos = curr.situacao.toLowerCase() === 'inativo' ? equal.inativos : (equal.inativos + 1)
            prev[index] = equal
            return prev
          }

          const item = new RelatorioSistemaModel(curr.sistema, curr.situacao.toLowerCase() === 'ativo' ? 1 : 0, curr.situacao.toLowerCase() === 'inativo' ? 1 : 0)

          return [...prev, item]
        }, [])
      })

      const retSistemas = arrSistemas.flat()

      const finalArray = retSistemas.reduce<RelatorioSistemaModel[]>((prev, curr) => {
        const equal = prev.find(x => x.sistema === curr.sistema)
        if (prev.length > 0 && equal) {
          const index = prev.indexOf(equal)
          equal.ativos += curr.ativos
          equal.inativos += curr.inativos
          prev[index] = equal
          return prev
        }
        return [...prev, curr]
      }, [])


      finalArray.sort((a, b) => a.ativos - b.ativos).reverse()

      setRelatorioSistemas(finalArray)

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getPessoaMapa, isAllowed, setRegistro, showToast, usuario?.Id],
  );

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <DashboardHeader
          titulo='Dashboard'
          getPessoasMapaWrapper={getPessoasMapaWrapper}
          getIndicacoesWrapper={getIndicacoesWrapper}
          setLoadingRevenda={setLoadingRevenda}
          getResultadoRevendaWrapper={getResultadoRevendaWrapper}
          loading={carregando}
          isAllowed={isAllowed}
        />
      </div>
      <Grid container spacing={3} justifyContent='space-between' alignItems='stretch'
        style={{
          padding: '10px 20px', position: 'relative', marginTop: 0, height: carregando ? '100%' : undefined,
          overflowY: carregando ? 'hidden' : undefined
        }}>
        {carregando && <Box position='absolute' height='100%' width='100%'>
          <CircularLoading tipo='FULLSIZED' />
        </Box>}
        <DashboardRelatorio
          carregando={carregando}
          setLoading={setLoadingRevenda}
          getPessoasMapaWrapper={getPessoasMapaWrapper}
          getIndicacoesWrapper={getIndicacoesWrapper}
          getResultadoRevendaApi={getResultadoRevendaWrapper}
          relatorioSistemas={relatorioSistemas}
          relatorioIndicacoes={relatorioIndicacoes}
          setRelatorioIndicacoes={setRelatorioIndicacoes}
          indicacoes={indicacoes}
          setIndicacoes={setIndicacoes}
        />
      </Grid>
    </div>
  );
};

export default DashboardPage;
