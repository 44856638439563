import { Button, Grid } from "@material-ui/core";
import { useGetPessoaComissao } from "data/api/gestao/pessoa-comissao/get-pessoa-comissao";
import { PessoaComissaoModel } from "model/api/gestao/pessoa/pessoa-comissao-model";
import { useCallback, useEffect, useState } from "react";
import { useToastSaurus } from "services/app";
import { CircularLoading } from "views/components";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus";
import { DialogPessoaComissao } from "views/components/dialog/dialog-pessoa-comissao/dialog-pessoa-comissao";
import { NovoIcon } from "views/components/icons";
import { Paginacao } from "views/components/paginacao";
import { useThemeQueries } from "views/theme";
import { AccordionRevendedorComissaoListData } from "./accordion-revendedor-comissao-list-data";

type Props = {
  id: string;
}

export type FiltroProps = {
  dataInicial: string
  dataFinal: string,
  situacao: number
}

export const AccordionRevendedorPessoaComissaoList = ({ id }: Props) => {
  const { theme } = useThemeQueries()
  const [openAccordion, setOpenAccordion] = useState<boolean>(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const { showToast } = useToastSaurus()

  const [selectedId, setSelectedId] = useState<string | undefined>()


  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<PessoaComissaoModel>(),
  });

  const { getPessoaComissao, carregando } = useGetPessoaComissao();

  const fillResult = useCallback(
    (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<PessoaComissaoModel>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    []
  );

  const search = useCallback(
    async (newPage: number) => {
      try {
        const res = await getPessoaComissao(id, newPage, `&pageSize=5`);
        if (res.erro) throw res.erro;

        if (!res.resultado) {
          return [];
        }

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          await search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list
        );

        return res;
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [showToast, getPessoaComissao, id, fillResult]
  );

  useEffect(() => {
    if (openAccordion && !openDialog) {
      search(queryStatus.page)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAccordion, openDialog, queryStatus.page])

  const onCardSelected = useCallback((id: string) => {
    setSelectedId(id)
    setOpenDialog(true)
  }, [])

  const onClickAdicionar = useCallback(() => {
    setOpenDialog(true)
    setSelectedId(undefined)
  }, [])

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        await search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  return (
    <>
      <AccordionSaurus
        labelPrimary="Modelo de Comissão"
        tipoExpand="bold"
        noPaperRoot={false}
        heightDivider={2}
        showDivider={openAccordion}
        colorDivider={theme.palette.primary.main}
        colorExpand={theme.palette.primary.main}
        expanded={openAccordion}
        onChange={() => setOpenAccordion(prev => !prev)}
      >
        <Grid container spacing={1} style={{
          position: 'relative'
        }}>
          {carregando && <CircularLoading tipo="FULLSIZED" />}
          <Grid item xs={12}>
            <Paginacao
              pageChanged={pageChanged}
              totalPages={queryStatus.totalPages}
              totalRegisters={queryStatus.totalResults}
              currentPage={queryStatus.page}
            />
          </Grid>
          <Grid item xs={12}>
            <AccordionRevendedorComissaoListData
              carregando={carregando}
              list={queryStatus.list}
              onCardChecked={onCardSelected}
              onCardSelected={(model: PessoaComissaoModel) => { }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={5} lg={4}>
                <Button variant="contained" color='primary' fullWidth
                  onClick={onClickAdicionar}>
                  <NovoIcon tipo='BUTTON_PRIMARY' />
                  NOVA COMISSÃO
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSaurus>
      <DialogPessoaComissao
        closeModal={() => setOpenDialog(false)}
        carregando={carregando}
        openned={openDialog}
        revendaId={id}
        id={selectedId}
      />
    </>
  );
};