import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const LinkIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M23.8122 21.6805C22.0546 18.4546 22.6519 14.0483 25.2472 11.2823C27.1834 9.22323 29.1746 7.21223 31.2412 5.28359C34.9695 1.81753 40.7918 2.09893 44.4033 5.8258C47.8707 9.40168 47.8707 15.2425 44.3759 18.839C42.5495 20.7196 40.7232 22.6208 38.7938 24.3984C35.8415 27.1232 32.4222 27.7272 28.6596 26.2859C28.5292 26.2378 28.3987 26.1623 28.1859 26.0525C28.7077 25.5515 29.1952 25.0916 29.6689 24.618C29.8269 24.4602 29.9573 24.5357 30.1152 24.5837C33.0058 25.4005 35.5119 24.721 37.6403 22.6139C39.3637 20.9049 41.0802 19.1959 42.783 17.4663C45.6598 14.5493 45.598 9.95763 42.6731 7.05437C39.8512 4.25407 35.2373 4.26779 32.3741 7.10241C30.5958 8.85947 28.8176 10.6097 27.0805 12.4079C25.0893 14.4669 24.4988 16.9103 25.261 19.6626C25.3365 19.944 25.3296 20.1156 25.1099 20.3284C24.6636 20.747 24.2723 21.2 23.8122 21.6805Z" />
                <path d="M21.6563 23.7944C21.1414 24.2955 20.6539 24.7553 20.187 25.2358C20.036 25.3868 19.8986 25.325 19.7407 25.277C16.8501 24.4602 14.3372 25.126 12.2156 27.2399C10.4854 28.9627 8.74827 30.6854 7.03177 32.4287C4.25791 35.2427 4.27164 39.8687 7.03177 42.6485C9.91548 45.5517 14.4951 45.6615 17.3719 42.8612C19.212 41.0699 21.0521 39.2648 22.8235 37.4047C24.7666 35.3732 25.364 32.9572 24.6018 30.2324C24.5126 29.903 24.5538 29.7039 24.8009 29.4774C25.2129 29.1136 25.5837 28.7156 25.975 28.3312C26.0025 28.3106 26.0368 28.2969 26.1055 28.2694C27.0187 30.1569 27.2521 32.1267 26.8401 34.172C26.4694 36.0252 25.5974 37.6244 24.2585 38.9628C22.5077 40.7198 20.7637 42.4837 18.9923 44.2202C15.1954 47.9539 9.31128 47.9196 5.57618 44.1584C1.90974 40.4727 1.94407 34.5701 5.6517 30.8227C7.43686 29.0244 9.18082 27.1781 11.0484 25.4623C14.0007 22.7375 17.42 22.1266 21.1894 23.5611C21.3199 23.616 21.4435 23.6915 21.6563 23.7944Z" />
                <path d="M32.2849 18.4683C32.2917 19.0106 32.0789 19.2714 31.8249 19.5185C28.4605 22.8816 25.0962 26.2447 21.7318 29.6078C20.9835 30.3559 20.2351 31.104 19.4867 31.8453C18.9305 32.3944 18.3057 32.4355 17.8526 31.9757C17.42 31.5296 17.4612 30.905 17.983 30.3834C22.1095 26.2584 26.2359 22.1403 30.3555 18.0085C30.7263 17.6379 31.1245 17.4457 31.6326 17.6447C32.0652 17.8163 32.2711 18.1664 32.2849 18.4683Z" />
            </DefaultIcon>
        </>
    );
};
