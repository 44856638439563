import { useStyles } from './paginacao-styles';
import { useRef, useState, useCallback } from 'react';
import { usePagination } from '@material-ui/lab/Pagination';
import { Button, Card, Fade, Grid, Tooltip } from '@material-ui/core';
import { TextFieldSaurus } from 'views/components/controles/inputs/text-field-saurus/text-field-saurus';
import { OkIcon, CancelarIcon, UltimoIcon } from '../icons';
import { PrimeiroIcon } from '../icons/primeiro-icon';
import { AvancarIcon } from '../icons/avancar-icon';
import { VoltarIcon } from '../icons/voltar-icon';
import classNames from 'classnames';
import { useThemeQueries } from 'views';
import { ExportarExcelIcon } from '../icons/exportar-excel-icon';
import { toCurrency } from 'utils/toCurrency';

interface PaginacaoProps {
  pageChanged: (currentPage: number) => any;
  totalRegisters: number;
  totalPages: number;
  currentPage: number;
  isRegistro?: boolean;
  exportarExcel?: () => any
  valorTotal?: number,
  tittleButton?: string;
  onClickButton?: () => void;
  carregando?: boolean;
}

export const Paginacao = ({
  pageChanged,
  totalPages,
  totalRegisters,
  currentPage,
  isRegistro = true,
  exportarExcel,
  valorTotal,
  onClickButton,
  tittleButton,
  carregando,
}: PaginacaoProps) => {
  const classes = useStyles();
  const [showInput, setShowInput] = useState(false);
  const [inputSearchValue, setinputSearchValue] = useState('');
  const refInput = useRef<HTMLInputElement>(null);
  const { isMobile, xs } = useThemeQueries();

  const formatValue = useCallback((value: number) => {
    if (value > 0) {
      return value;
    } else {
      return 1;
    }
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      let val = formatValue(value);
      pageChanged(val);
    },
    [formatValue, pageChanged],
  );

  const setShowInputWrapper = useCallback(() => {
    setShowInput(true);
    setTimeout(() => {
      if (!isMobile) refInput.current?.focus();
    }, 20);
  }, [isMobile]);

  const { items } = usePagination({
    count: totalPages,
    page: currentPage,
    siblingCount: 1,
    boundaryCount: 1,

    onChange: handleChange,
    showFirstButton: true,
    showLastButton: true,
  });

  const submitForm = useCallback(
    (valores?: any): any => {
      if (valores?.currentTarget?.form) {
        pageChanged(parseInt(valores.currentTarget.form['pagina'].value));
      } else if (valores?.currentTarget?.['pagina']?.value) {
        pageChanged(parseInt(valores?.currentTarget?.['pagina']?.value));
      }
      setinputSearchValue('');
      setShowInput(false);
      return false;
    },
    [pageChanged],
  );

  const cancelForm = useCallback(() => {
    setinputSearchValue('');
    setShowInput(false);
  }, []);

  const btnOk = (
    <Grid className={classes.gridBtnTxtPagina}>
      <Button className={classes.btnTxtPagina} onClick={cancelForm}>
        <CancelarIcon tipo="GERAL" />
      </Button>
      <Button className={classes.btnTxtPagina} onClick={submitForm}>
        <OkIcon tipo="GERAL" />
      </Button>
    </Grid>
  );

  return (
    <>
      {isMobile ?
        <div className={classes.root}>
          <Grid container justifyContent='space-between'>
            {totalPages <= 1 && <div></div>}
            <Grid container style={{
              display: 'flex',
              alignItems: 'center',
            }}>
              <Grid item xs={6}>
                {totalPages > 1 && (
                  <div className={classes.navContainer}>
                    <div className={classes.navContainerInterno}>
                      <nav>
                        <ul className={classes.ul}>
                          {items.map(({ page, type, selected, ...item }, index) => {
                            let children = null;
                            if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                              children = (
                                <div
                                  className={classes.btnNavPagina}
                                  onClick={setShowInputWrapper}
                                >
                                  ...
                                </div>
                              );
                            } else if (type === 'page') {
                              children = (
                                <div
                                  className={classNames(
                                    classes.btnNavPagina,
                                    selected ? classes.btnNavPaginaSelected : undefined,
                                  )}
                                  {...item}
                                >
                                  {page}
                                </div>
                              );
                            } else if (type === 'first') {
                              children = (
                                <div
                                  className={classNames(
                                    classes.btnNavAction,
                                    currentPage <= 1
                                      ? classes.svgIconActionDisabled
                                      : undefined,
                                  )}
                                  {...item}
                                >
                                  <PrimeiroIcon tipo="GERAL" />
                                </div>
                              );
                            } else if (type === 'last') {
                              children = (
                                <div
                                  className={classNames(
                                    classes.btnNavAction,
                                    currentPage >= totalPages
                                      ? classes.svgIconActionDisabled
                                      : undefined,
                                  )}
                                  {...item}
                                >
                                  <UltimoIcon tipo="GERAL" />
                                </div>
                              );
                            } else if (type === 'next') {
                              children = (
                                <div
                                  className={classNames(
                                    classes.btnNavAction,
                                    currentPage >= totalPages
                                      ? classes.svgIconActionDisabled
                                      : undefined,
                                  )}
                                  {...item}
                                >
                                  <AvancarIcon tipo="GERAL" />
                                </div>
                              );
                            } else if (type === 'previous') {
                              children = (
                                <div
                                  className={classNames(
                                    classes.btnNavAction,
                                    currentPage <= 1
                                      ? classes.svgIconActionDisabled
                                      : undefined,
                                  )}
                                  {...item}
                                >
                                  <VoltarIcon tipo="GERAL" />
                                </div>
                              );
                            }

                            return <li key={index}>{children}</li>;
                          })}
                        </ul>
                      </nav>
                    </div>
                    {showInput && (
                      <Fade in={showInput}>
                        <div className={classes.paginaContainer}>
                          <form onSubmit={submitForm}>
                            <TextFieldSaurus
                              inputRef={refInput}
                              className={classes.txtFieldPagina}
                              variant="standard"
                              tipo="NUMERO"
                              name="pagina"
                              placeholder="Página"
                              value={inputSearchValue}
                              allowSubmit={true}
                              InputProps={{
                                endAdornment: btnOk,
                              }}
                            />
                          </form>
                        </div>
                      </Fade>
                    )}
                  </div>
                )}
              </Grid>
              {isRegistro ?
                <>
                  <Grid item xs={6}>
                    <Grid container justifyContent='flex-end'>
                      <Grid item style={{ gap: 4 }}>
                        <Card className={classes.sumaryContainer}>
                          Qtd: <strong>{totalRegisters}</strong>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                  {valorTotal ?
                    <Grid item>
                      <Grid item>
                        <Card className={classes.valorTotal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          Valor Total:<strong>{toCurrency(valorTotal)}</strong>
                        </Card>
                      </Grid>
                    </Grid>
                    :
                    ''
                  }
                </>
                : ''}
            </Grid>
          </Grid>
        </div>
        :
        <div className={classes.root} style={totalPages <= 1 ? {justifyContent: 'flex-end'} : undefined}>
          {totalPages <= 1 && <div></div>}
          {totalPages > 1 && (
            <div className={classes.navContainer}>
              <div className={classes.navContainerInterno}>
                <nav>
                  <ul className={classes.ul}>
                    {items.map(({ page, type, selected, ...item }, index) => {
                      let children = null;
                      if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                        children = (
                          <div
                            className={classes.btnNavPagina}
                            onClick={setShowInputWrapper}
                          >
                            ...
                          </div>
                        );
                      } else if (type === 'page') {
                        children = (
                          <div
                            className={classNames(
                              classes.btnNavPagina,
                              selected ? classes.btnNavPaginaSelected : undefined,
                            )}
                            {...item}
                          >
                            {page}
                          </div>
                        );
                      } else if (type === 'first') {
                        children = (
                          <div
                            className={classNames(
                              classes.btnNavAction,
                              currentPage <= 1
                                ? classes.svgIconActionDisabled
                                : undefined,
                            )}
                            {...item}
                          >
                            <PrimeiroIcon tipo="GERAL" />
                          </div>
                        );
                      } else if (type === 'last') {
                        children = (
                          <div
                            className={classNames(
                              classes.btnNavAction,
                              currentPage >= totalPages
                                ? classes.svgIconActionDisabled
                                : undefined,
                            )}
                            {...item}
                          >
                            <UltimoIcon tipo="GERAL" />
                          </div>
                        );
                      } else if (type === 'next') {
                        children = (
                          <div
                            className={classNames(
                              classes.btnNavAction,
                              currentPage >= totalPages
                                ? classes.svgIconActionDisabled
                                : undefined,
                            )}
                            {...item}
                          >
                            <AvancarIcon tipo="GERAL" />
                          </div>
                        );
                      } else if (type === 'previous') {
                        children = (
                          <div
                            className={classNames(
                              classes.btnNavAction,
                              currentPage <= 1
                                ? classes.svgIconActionDisabled
                                : undefined,
                            )}
                            {...item}
                          >
                            <VoltarIcon tipo="GERAL" />
                          </div>
                        );
                      }

                      return <li key={index}>{children}</li>;
                    })}
                  </ul>
                </nav>
              </div>
              {showInput && (
                <Fade in={showInput}>
                  <div className={classes.paginaContainer}>
                    <form onSubmit={submitForm}>
                      <TextFieldSaurus
                        inputRef={refInput}
                        className={classes.txtFieldPagina}
                        variant="standard"
                        tipo="NUMERO"
                        name="pagina"
                        placeholder="Página"
                        value={inputSearchValue}
                        allowSubmit={true}
                        InputProps={{
                          endAdornment: btnOk,
                        }}
                      />
                    </form>
                  </div>
                </Fade>
              )}
            </div>
          )}
          {xs ?
            <>
              <Grid container spacing={2}>
                {isRegistro ?
                  <>
                    {exportarExcel ?
                      <Card style={{ marginRight: 8, marginLeft: 24, display: 'flex', alignItems: 'center', maxHeight: '40px' }}>
                        <Grid item xs={12}>
                          <Tooltip arrow title="Exportar Excel">
                            <Button color="primary" onClick={() => exportarExcel()}>
                              <ExportarExcelIcon tipo="BUTTON_EXPORT_MOBILE" />
                            </Button>
                          </Tooltip>
                        </Grid>
                      </Card>
                      :
                      null
                    }
                    <Card className={classes.sumaryContainer}>
                      <Grid item xs={12} style={{ gap: 4 }}>
                        Registros: <strong>{totalRegisters}</strong>
                      </Grid>
                    </Card>
                    {valorTotal ?
                      <Grid item>
                        <Card className={classes.valorTotal} style={{ marginLeft: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          Valor Total:<strong>{toCurrency(valorTotal === 0 ?? 0)}</strong>
                        </Card>
                      </Grid>
                      :
                      ''
                    }
                  </>
                  : ''}
              </Grid>
            </>
            :
            <>
              {isRegistro ?
                <>
                  <Grid container justifyContent='flex-end'>
                    {exportarExcel ?
                      <Grid item>
                        <Card style={{ marginRight: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <Tooltip arrow title="Exportar Excel">
                            <Button color="primary" onClick={() => exportarExcel()}>
                              <ExportarExcelIcon tipo="BUTTON_EXPORT" />
                            </Button>
                          </Tooltip>
                        </Card>
                      </Grid>
                      :
                      ''
                    }
                    <Grid item>
                      <Card className={classes.sumaryContainer} style={{ gap: 4 }}>
                        Registros: <strong>{totalRegisters}</strong>
                      </Card>
                    </Grid>
                    {(valorTotal === 0) ?
                      <Grid item>
                        <Card className={classes.valorTotal} style={{ marginLeft: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          Valor Total: <strong>{toCurrency(valorTotal)}</strong>
                        </Card>
                      </Grid> : null}
                    {valorTotal ?
                      <Grid item>
                        <Card className={classes.valorTotal} style={{ marginLeft: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          Valor Total: <strong>{toCurrency(valorTotal)}</strong>
                        </Card>
                      </Grid>
                      :
                      ''
                    }
                  </Grid>
                </>
                : ''}
            </>
          }
        </div>
      }
    </>
  );
};
