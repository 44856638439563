import { KeyValueModel } from "model";

export const TpTributacaoMock: KeyValueModel[] = [
    new KeyValueModel('T', "T - Tributado em São Paulo"),
    new KeyValueModel('F', 'F - Tributado Fora de São Paulo'),
    new KeyValueModel('A', 'A - Tributado em São Paulo, porém isento'),
    new KeyValueModel('B', 'B - Tributado fora de São Paulo, porém isento'),
    new KeyValueModel('D', 'D - Tributado em São Paulo com isenção parcial'),
    new KeyValueModel('M', 'M - Tributado em São Paulo, porém com indicação de imunidade subjetiva'),
    new KeyValueModel('N', 'N - Tributado Fora de São Paulo, porém com indicação de imunidade subjetiva'),
    new KeyValueModel('R', 'R - Tributado em São Paulo, porém com indicação de imunidade objetiva'),
    new KeyValueModel('S', 'S - Tributado fora de São Paulo, porém com indicação de imunidade objetiva'),
    new KeyValueModel('X', 'X - Tributado em São Paulo, porém Exigibilidade Suspensa'),
    new KeyValueModel('V', 'V - Tributado Fora de São Paulo, porém Exigibilidade Suspensa'),
    new KeyValueModel('P', 'P - Exportação de Serviços')
];
