import { Box, Grid, Typography } from "@material-ui/core";
import { useMemo } from "react";
import { CaracteresString } from "utils/caracteres-string";
import { DefaultCard } from "../components";
import { CardIndicacoesSistemasProps } from "./card-indicacoes-sistemas-props";
import SemImagem from 'assets/img/sem-imagem.jpg';
import { useStyles } from "./card-indicacoes-sistemas-styles";
import { isEmpty } from "lodash";

export const CardIndicacoesSistemas = ({
    model,
    sistemaId,
    index,
    selected,
    onClick
}: CardIndicacoesSistemasProps) => {
    const classes = useStyles();

    const img = useMemo(() => {
        const url = atob(model?.urlImagem);
        return <img src={isEmpty(url) ? SemImagem : url} className={classes.cardImg} alt={model?.nome} />;
    }, [classes.cardImg, model?.nome, model?.urlImagem]); 

    return (
        <DefaultCard
            isSelected={selected}
            onClick={
                () => {
                    onClick(model)
                }
            }
        >
            <div className={classes.card}>
                <Box p={2} display={"flex"} width="100%">
                    <Grid>
                        <Box mr={2}>
                            <Typography>
                                {img}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid>
                        <Typography>
                            <Box fontWeight={600}>
                                {model?.nome}
                            </Box>
                        </Typography>
                        <Typography variant="body2">
                            {CaracteresString(model?.descricao || '', 300)}
                        </Typography>
                    </Grid>
                </Box>
            </div>
        </DefaultCard>
    );
}