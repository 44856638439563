
import { ModuloModel } from "model/api/gestao/sistemas/modulo-model";

import { DefaultModal } from 'views/components/modals/components/default-modal';
import NovoModuloCadastro from "./components/modulo-cadastro/novo-modulo-cadastro";

type Props = {
  openned: boolean;
  modulo: ModuloModel;
  sistemaId: string
}

export const NovoModuloModal = ({ modulo, openned, sistemaId }: Props) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {openned && <NovoModuloCadastro sistemaId={sistemaId} modulo={modulo} />}
    </DefaultModal>
  );
};
