import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    fontSize: '17px',
    fontWeight: 600,
    padding: '0 15px 10px'
  },
  content: {
    paddingBottom: '15px'
  },
  divider: {
    background: theme.palette.primary.light,
    height: '1px',
    margin: '17px 0'
  },
}));
