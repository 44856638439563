import { useCallback, useMemo, useState } from 'react';
import { FaturasHeader } from './components/faturas-header/faturas-header';
import { FaturasList } from './components/faturas-list/faturas-list';
import { FaturasListSearchProps } from './components/faturas-list/faturas-list-search-props';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { EnumSituacao } from 'model/enums/enum-situacao';
import { useParams } from 'react-router-dom';
import { NotasFiscaisEditar } from '../notas-fiscais-revenda/components/notas-fiscais-editar/notas-fiscais-editar';
import { useSessaoAtual } from 'services/app';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { toDateString } from 'utils/to-date';
import { EnumTipoFiltroData } from 'model/enums/enum-tipo-filtro-data';

interface paramProps {
  parametro: string
}

export const FaturasPage = () => {
  const classes = useDefaultCadastroStyles();

  const { tipoUsuario } = useSessaoAtual();

  const isRevendedor = useMemo(() => tipoUsuario() === EnumTipoPessoas.Revendedor, [tipoUsuario])

  const getCurrentMonth = useCallback(() => {
    const month = new Date().getMonth() + 1
    const year = new Date().getFullYear()
    return {
      inicial: new Date(`${month}/01/${year - 1}`).toString(),
      final: new Date(`${month}/31/${year}`).toString()
    }
  }, [])
  

  const [openPesquisa, setOpenPesquisa] = useState(false);
  const [searchProps, setSearchProps] = useState<FaturasListSearchProps>({
    cpfCnpjPagador: '',
      dataFinal: '',
      CompetenciaInicial: '',
      CompetenciaFinal: '',
      dataInicial: isRevendedor ? (toDateString(getCurrentMonth().inicial, 'yyyy-MM-DD') || '') : '',
      NomePagador: '',
      nossoNumero: null,
      Situacao: EnumSituacao.Aberta,
      tipoData: EnumTipoFiltroData.Vencimento,
      atualizarPesquisa: false,
      formaPagamentoId: -1
  });

  const { parametro } = useParams<paramProps>()

  const pageSwitch = useMemo(() => {
    switch (parametro) {
      case undefined:
        return (
          <FaturasList searchProps={searchProps} />
        )
      default:
        setOpenPesquisa(false)
        return (
          <NotasFiscaisEditar id={parametro} />
        )
    }
  }, [parametro, searchProps])

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <FaturasHeader
          openPesquisa={openPesquisa}
          setSearchProps={setSearchProps}
          setOpenPesquisa={setOpenPesquisa}
          titulo={parametro ? 'Editar Fatura' : 'Faturas de Clientes'}
        />
      </div>
      {pageSwitch}
    </div>
  );
};

export default FaturasPage;
