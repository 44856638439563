import { makeStyles } from '@material-ui/core';

export const useDefaultCadastroStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.grey[100],
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflowX: 'hidden',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 1 auto',
  },
  list: {
    flex: '1 1 100%',
    position: 'relative',
  },
  listContainer: {
    flex: '0 1 auto',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '0 16px 16px 16px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 5px'
    }
  },
  content: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  defaultContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  excelMobile: {
    marginRight: '8px',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  },
  acoes: {
    flex: '0 0 auto',
    padding: theme.spacing(2),
  },
}));
