import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    formContainer: {
    }, btnPesquisa: {
        marginLeft: theme.spacing(1),
        width: "60px",
        minWidth: "60px",
        padding: 0,
        "& svg": {
            margin: 0,
        }
    },
    modulosContainer: {
        background: '#F8F8F8',
        padding: '1rem',
        borderRadius: '15px',
        '& .MuiFormControl-root': {
            background: '#fff'
        },
        maxHeight: 400,
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'unset'
        },
        [theme.breakpoints.up('lg')]: {
            maxHeight: 500
        },
        [theme.breakpoints.up('xl')]: {
            maxHeight: 700
        }
    },
    modulosScroll: {
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'unset'
        },
        [theme.breakpoints.up('lg')]: {
            maxHeight: 350
        },
        [theme.breakpoints.up('xl')]: {
            maxHeight: 550
        }
    },
    camposContainer: {
        background: '#F8F8F8',
        padding: '1rem',
        borderRadius: '15px',
        '& .MuiFormControl-root': {
            background: '#fff'
        },
    },
    greyContainer: {
        background: '#F8F8F8',
        padding: '8px 14px',
        borderRadius: theme.shape.borderRadius,
        fontWeight: 500,
    },
    valorDescontoBox: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        '& span': {
            textDecoration: 'line-through',
            fontSize: 13,
            color: theme.palette.warning.dark,
            alignSelf: 'flex-start'
        }
    }
}));
