import { Box, Button, Grid } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-pessoa-socio-styles';
import { CircularLoading, useThemeQueries } from 'views';
import { useCallback, useEffect, useRef } from 'react';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { DefaultFormRefs } from 'views/components/form/utils';
import { isEqual } from 'lodash';
import { PessoaSocioModel } from 'model/api/gestao/pessoa/pessoa-socio-model';
import { FormPessoaSocio } from 'views/components/form/master/pessoa/pessoa-socio/form-pessoa-socio';
import { guidEmpty } from 'utils/guid-empty';
import { LixoIcon, SalvarIcon, VoltarIcon } from 'views/components/icons';
import { useConfirm } from 'material-ui-confirm';
import { useDeleteSocio } from 'data/api/gestao/pessoa-socio/delete-deletar-socio';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';

interface DialogPessoaSocioProps {
  openned: boolean;
  closeModal: () => void;
  model: PessoaSocioModel
  carregando: boolean
  enviarModel: (model: PessoaSocioModel) => Promise<boolean>;
  revenda?: boolean;
}

export const DialogPessoaSocio = ({
  openned,
  closeModal,
  model,
  carregando,
  enviarModel,
  revenda
}: DialogPessoaSocioProps) => {
  const classes = useStyles();
  const {isMobile} = useThemeQueries();
  const { showToast } = useToastSaurus();
  const confirm = useConfirm();
  const {tipoUsuario} = useSessaoAtual()

  const isFuncionario = tipoUsuario() === EnumTipoPessoas.Funcionario || tipoUsuario() === EnumTipoPessoas.FuncionarioSoftwareHouse;

  const formRef = useRef<DefaultFormRefs<PessoaSocioModel>>(null)

  const recarregarForm = useCallback((modelForm: PessoaSocioModel) => {
    formRef.current?.fillForm(modelForm)
  }, [])

  const {deleteSocio, carregando: carregandoDelete} = useDeleteSocio()

  const loading = carregando || carregandoDelete;

  useEffect(() => {
    recarregarForm(model);
  }, [model, recarregarForm])

  const handleSubmit = useCallback(async (actualModel: PessoaSocioModel, beforeModel: PessoaSocioModel) => {
    const equal = isEqual(actualModel, beforeModel)

    if (equal) {
      showToast('info', 'Nenhuma informação foi alterada.')
      return
    }

    const res = await enviarModel(actualModel)

    if (!res) {
      return
    }

    closeModal()

  }, [closeModal, enviarModel, showToast])

  const deletePessoaSocio = useCallback(async () => {
    try {
      await deleteSocio(model.pessoaId, model.id)

      showToast('success', 'Sócio excluído.')
      closeModal()
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [closeModal, deleteSocio, model.id, model.pessoaId, showToast])

  const confirmDelete = useCallback(() => {
    confirm({
      confirmationText: 'Excluir',
      cancellationText: 'Cancelar',
      description: 'Tem certeza que deseja excluir este sócio?'
    }).then(() => {
      deletePessoaSocio()
    })
  }, [confirm, deletePessoaSocio])

  return (
    <DialogSaurus
      aberto={openned}
      titulo={model.id === guidEmpty() ? "Cadastrar Sócio" : "Editar Sócio"}
      tamanho={revenda ? 'lg' : 'sm'}
    >
      {loading && <CircularLoading tipo="FULLSIZED" />}
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={12}>
                <FormPessoaSocio
                  onSubmit={handleSubmit}
                  loading={carregando}
                  showLoading={true}
                  ref={formRef}
                  revenda={revenda}
                />
              </Grid>
              <Grid item xs={12}>
                 <Grid container spacing={2} style={{ paddingBottom: '7px' }}>
                 <Grid item xs={12} md={revenda ? 8 : 6}>
                    {(model.id !== guidEmpty() && !isFuncionario) && <Button variant='outlined' color='primary' fullWidth={isMobile} className={classes.buttonRemover}
                    onClick={confirmDelete}>
                      <LixoIcon tipo='BUTTON' />
                      Excluir
                    </Button>}
                  </Grid>
                  <Grid item xs={12} md={revenda ? 2 : 3}>
                    {closeModal && (
                      <Button
                        disabled={carregando}
                        variant="outlined"
                        fullWidth
                        color="primary"
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        <VoltarIcon tipo='BUTTON' />
                        Voltar
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12} md={revenda ? 2 : 3}>
                    <Button
                      disabled={carregando}
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => formRef.current?.submitForm()}
                    >
                      <SalvarIcon tipo='BUTTON_PRIMARY' />
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
