import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { Grid } from '@material-ui/core';
import { CardAmbienteSistema } from 'views/components/cards/card-ambiente-sistema';
import { AmbienteSistemaModel } from 'model/api/gestao/ambiente-sistema/ambiente-sistema-model';

export interface AmbientesGridProps {
  list: AmbienteSistemaModel[];
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (modulo: AmbienteSistemaModel) => any;
  onCardChecked: (id: string) => any;
  atualizarList: () => void
}

export const AmbientesListData = (props: AmbientesGridProps) => {
  const onCardSelected = (modulo: AmbienteSistemaModel) => {
    props.onCardSelected(modulo);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhum Ambiente Encontrado."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((modulos, index) => {
          return (
            <Grid xs={12}>
              <CardAmbienteSistema
                selected={
                  false
                }
                atualizarList={props.atualizarList}
                onCheck={onCardChecked}
                onClick={onCardSelected}
                model={modulos}
                key={index}
              />
            </Grid>
          );
        })}
    </>
  );
};
