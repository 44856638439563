import { RelatorioChartModel } from 'model/api/gestao/relatorios/relatorio-model';
import { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { toCurrency } from 'utils/toCurrency';
import { useThemeQueries } from 'views/theme';

type Props = {
    list: RelatorioChartModel[]
    stacked: boolean;
    width?: number;
}

export default function ChartValores({ list, stacked = false, width }: Props) {

    const { theme } = useThemeQueries();

    const chartOptions = useMemo(() => {
        const series = list.sort((a, b) => a.data[0].x - b.data[0].x)
        const options: ApexCharts.ApexOptions = {
            chart: {
                zoom: {
                    enabled: true,
                    type: 'x'
                },
                toolbar: {
                    show: false
                },
                type: stacked ? 'area' : 'line',
                height: 350,
                stacked: stacked,
            },
            dataLabels: {
                enabled: false,
            },
            colors: [
                theme.palette.info.main,
                theme.palette.error.main,
                theme.palette.success.main,
                '#8346a6',
                theme.palette.warning.dark,
                '#D7096C',
                '#4C4C4C'
            ],
            stroke: {
                curve: 'straight',
                width: 2,
                colors: [
                    theme.palette.info.main,
                    theme.palette.error.main,
                    theme.palette.success.main,
                    '#8346a6',
                    theme.palette.warning.dark,
                    '#D7096C',
                    '#4C4C4C'
                ]
            },
            fill: {
                type: stacked ? 'gradient' : 'solid',
                gradient: {
                    opacityFrom: stacked ? 0.6 : 1,
                    opacityTo: stacked ? 0.8 : 1,
                }
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
            },
            yaxis: {
                labels: {
                    formatter: function (val: number) {
                        return toCurrency(val);
                    },
                }
            },
            xaxis: {
                labels: {
                    formatter: (value: string) => {
                        if (!value || typeof value !== 'string' || !value.substring) return ''
                        return `${value.substring(4)}/${value.substring(0, 4)}`
                    }
                }

            },
        };

        return {
            options,
            series
        }
    }, [list, stacked, theme.palette.error.main, theme.palette.info.main, theme.palette.success.main, theme.palette.warning.dark])

    return (
        <Chart options={chartOptions.options} series={chartOptions.series} type={stacked ? 'area' : 'line'} width={width || '100%'} height={600} />

    )
}