import { useMemo } from 'react';


import { Grid } from '@material-ui/core';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { SimulacaoContratoHeader } from './components/simulacao-contrato-header/simulacao-contrato-header';
import { SimulacaoContrato } from './components/simulacao-contrato/simulacao-contrato';

export const SimulacaoContratoPage = () => {
  const classes = useDefaultCadastroStyles();


  const simulacaoPage = useMemo(() => <SimulacaoContrato/>, [])

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <SimulacaoContratoHeader/>
      </Grid>
      {simulacaoPage}
    </Grid>
  );
};

export default SimulacaoContratoPage;