import { Button, Divider, Grid, Slide, Typography } from "@material-ui/core";
import classNames from "classnames";
import { PessoaSocioModel } from "model/api/gestao/pessoa/pessoa-socio-model";
import { useEffect, useRef } from "react";
import { FormPessoaSocio } from "views/components/form/master/pessoa/pessoa-socio/form-pessoa-socio";
import { DefaultFormRefs } from "views/components/form/utils";
import { SalvarNovoIcon, VoltarIcon } from "views/components/icons";
import { useModalStyles } from "views/components/modals/utils/modal-styles";

interface Props {
    carregando: boolean
    handleSubmit: (model: PessoaSocioModel) => void
    pessoaSocioForm: PessoaSocioModel
    fase: 1 | 2 | 3
    setFase: (value: 1 | 2 | 3) => void
}

export const PessoaCadastroSocios = ({ carregando, handleSubmit, fase, pessoaSocioForm, setFase }: Props) => {
    const classes = useModalStyles();
    const cadFormRef =
        useRef<DefaultFormRefs<PessoaSocioModel>>(null);

    useEffect(() => {
        cadFormRef.current?.fillForm(
            pessoaSocioForm
        );
    }, [pessoaSocioForm]);

    return (
        <>
            <Slide in={fase === 3} direction="right">
                <div style={{
                    height: '100%',
                    display: 'flex',
                    flex: 1
                }}>
                    <div className={classes.content}>
                        <div
                            className={classNames(
                                classes.contentForms,
                                carregando ? classes.contentFormsLoading : undefined,
                            )}
                        >
                            <Typography variant="h6" color="primary">
                                Sócio
                            </Typography>
                            <Divider style={{ marginBottom: 40 }} />
                            <FormPessoaSocio
                                ref={cadFormRef}
                                showLoading={false}
                                loading={carregando}
                                revenda
                                onSubmit={handleSubmit}
                                modal={true}
                            />
                        </div>
                        <div className={classes.acoes}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Button
                                        disabled={carregando}
                                        onClick={() => {
                                            setFase(2)
                                        }
                                        }
                                        variant="outlined"
                                        color="primary"
                                        size="large"
                                        fullWidth
                                    >
                                        <VoltarIcon tipo="BUTTON" />
                                        Voltar
                                    </Button>
                                </Grid>
                                <Grid item xs={8}>
                                    <Button
                                        disabled={carregando}
                                        onClick={() => {
                                            cadFormRef.current!.submitForm()
                                        }
                                        }
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth
                                    >
                                        <SalvarNovoIcon tipo="BUTTON_PRIMARY" />
                                        Salvar
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </Slide>
        </>
    )
}