
import { useCallback, useMemo } from 'react';
import { toDecimal } from 'utils/to-decimal';
import * as Yup from 'yup';

export const useFormPessoaTributoValidation = (revenda: boolean = false) => {

    const validarLimiteAliquota = useCallback((val: any) => {
        if(isNaN(toDecimal(val))) return false

        if(toDecimal(val, 3) >= 100) return false

        return true
    }, [])

    const FormPessoaTributoValidationYup = useMemo(() => {
        return Yup.object().shape({
            aliquotaDeducao: Yup.string().required('Aliquota Dedução é obrigatória.').test('limite', 'Valor máximo ultrapassado', validarLimiteAliquota).typeError('Aliquota Dedução é obrigatória.'),
            aliquotaISS: Yup.string().required('Aliquota ISS é obrigatória.').test('limite', 'Valor máximo ultrapassado', validarLimiteAliquota).typeError('Aliquota ISS é obrigatória.'),
            aliquotaPIS: Yup.string().required('Aliquota PIS é obrigatória.').test('limite', 'Valor máximo ultrapassado', validarLimiteAliquota).typeError('Aliquota PIS é obrigatória.'),
            aliquotaCOFINS: Yup.string().required('Aliquota COFINS é obrigatória.').test('limite', 'Valor máximo ultrapassado', validarLimiteAliquota).typeError('Aliquota COFINS é obrigatória.'),
            aliquotaINSS: Yup.string().required('Aliquota INSS é obrigatória.').test('limite', 'Valor máximo ultrapassado', validarLimiteAliquota).typeError('Aliquota INSS é obrigatória.'),
            aliquotaIR: Yup.string().required('Aliquota IR é obrigatória.').test('limite', 'Valor máximo ultrapassado', validarLimiteAliquota).typeError('Aliquota IR é obrigatória.'),
            aliquotaCSLL: Yup.string().required('Aliquota CSLL é obrigatória.').test('limite', 'Valor máximo ultrapassado', validarLimiteAliquota).typeError('Aliquota CSLL é obrigatória.'),
            pTotTrib: Yup.string().required('PTot Trib é obrigatório.').typeError('PTot Trib é obrigatório.'),
            tipoTributacao: Yup.string().required('Tipo de Tributação é obrigatório.').typeError('Tipo de Tributação é obrigatório.'),
            codigoServico: Yup.string().required('Código do Serviço é obrigatório.').max(10, 'Máximo de 10 caracteres').transform(val => isNaN(Number(val)) ? null : val).typeError('Caracteres Inválidos.'),
            textoServico: Yup.string().required('Texto do Serviço é obrigatório.').typeError('Texto do Serviço é obrigatório.'),
            issRetido: Yup.boolean().required('ISS Retido é obrigatório.').typeError('ISS Retido é obrigatório.'),
            serieRPS: Yup.string().required('Série RPS é obrigatória.').max(9, 'Máximo de 10 caracteres').transform(val => isNaN(Number(val)) ? null : val).typeError('Caracteres Inválidos.'),
        });
    }, [validarLimiteAliquota])

    return {
        FormPessoaTributoValidationYup,
    }
}

