import { Grid } from "@material-ui/core";
import { TerminalModel } from "model/api/gestao/terminal/terminal-model";
import { CardNaoEncontrado } from "views/components/cards";
import { CardTerminal } from "views/components/cards/card-terminal/card-terminal";
import { InformacaoIcon } from "views/components/icons";

export interface TerminalGridProps {
  list: Array<TerminalModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onClick: (model: TerminalModel) => any;
}

export const TerminalListData = (props: TerminalGridProps) => {

  const onClickButton = (model: TerminalModel) => {
    props.onClick(model);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <Grid item xs={12}>
          <CardNaoEncontrado
            mensagem="Nenhum Terminal encontrado."
            icon={<InformacaoIcon tipo="GERAL" />}
          />
        </Grid>
      )}
      {props.list.length > 0 &&
        props.list.map((terminal, index) => {
          return (
            <Grid item xs={12} style={{padding: '5px 0'}}>
              <CardTerminal
                onClick={onClickButton}
                model={terminal}
                key={index}
              />
            </Grid>
          );
        })}
    </>
  );
};
