import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Grid, Button, FormControlLabel, Typography } from '@material-ui/core';
import { useFormPessoaInfoValidation } from './form-pessoa-info-validation';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  TextFieldSaurus,

} from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useStyles } from './form-pessoa-info-styles'
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PessoaInfoModel } from 'model/api/gestao/pessoa/pessoa-info-model';
import { picker } from 'utils/picker';
import { useConsultaCEP } from 'data/api/wsmaster';
import { useToastSaurus } from 'services/app';
import { CheckboxDefault } from 'views/components/controles/checkboxes';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { UFMock } from 'data/mocks';
import { AutocompleteSegmento } from 'views/components/controles/autocompletes/autocomplete-segmento/autocomplete-segmento';
import { CRTMock } from 'data/mocks/crt-mock';
import { MapaIcon } from 'views/components/icons/mapa-icon';

interface PessoaFormModelProps extends DefaultFormProps<PessoaInfoModel> {
  revenda?: boolean;
  aberto?: boolean;
  modal?: boolean;
  esconderAbertura?: boolean;
  block?: boolean;
  pessoaFisica?: boolean;
}

type OpcoesInfoProps = {
  isJuridico: boolean
  isFinanceiro: boolean
  isComercial: boolean
}

export const FormPessoaInfo = forwardRef<
  DefaultFormRefs<PessoaInfoModel>,
  PessoaFormModelProps
>(
  (
    { loading, onSubmit, revenda = false, aberto = false, modal = false, esconderAbertura, block, pessoaFisica, ...props }: PessoaFormModelProps,
    ref,
  ) => {
    const { isMobile } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const classes = useStyles()
    const refInputNome = useRef<HTMLInputElement>(null);
    const refInputNumero = useRef<HTMLInputElement>(null)
    const { FormPessoaInfoValidationYup } = useFormPessoaInfoValidation({ esconderAbertura, pessoaFisica })
    const [modelForm, setModelForm] = useState<PessoaInfoModel>(new PessoaInfoModel())
    const { showToast } = useToastSaurus()

    const { consultaCEP, carregando: carregandoCep } = useConsultaCEP();

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
      setValue,
      getValues
    } = useForm<PessoaInfoModel>({
      defaultValues: { ...modelForm },
      resolver: yupResolver(FormPessoaInfoValidationYup),
      criteriaMode: 'all',
      mode: 'onBlur' && 'onChange',
    });

    const carregando = loading || carregandoCep
    const [tipoInfo, setTipoInfo] = useState<OpcoesInfoProps>({
      isComercial: false,
      isFinanceiro: false,
      isJuridico: false
    })

    const submitAlterarDadosConta = (values: PessoaInfoModel) => {

      const info = picker<PessoaInfoModel>(values, modelForm)
      info.isComercial = tipoInfo.isComercial
      info.isFinanceiro = tipoInfo.isFinanceiro
      info.isJuridico = tipoInfo.isJuridico

      info.dataAberturaEmpresa = null

      onSubmit(info, modelForm);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitAlterarDadosConta)();
      },
      resetForm: () => {
        reset();
        if (!isMobile) refInputNome.current?.focus();
      },
      fillForm: (model: PessoaInfoModel) => {
        setTipoInfo({
          isComercial: model.isComercial,
          isJuridico: model.isJuridico,
          isFinanceiro: model.isFinanceiro
        })
        if (!model.isComercial && !model.isJuridico && !model.isFinanceiro) {
          setTipoInfo(prev => ({
            ...prev,
            isComercial: true
          }))
        }
        setModelForm({ ...model })
        reset({ ...model });
        if (!isMobile) refInputNome.current?.focus();
      },
    }));

    const mostrarLatLong = (
      getValues('latitude') !== null && getValues('latitude') !== 0 &&
      getValues('longitude') !== null && getValues('longitude') !== 0
    )

    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {carregando && props.showLoading && (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="FULLSIZED" />
              </div>
            )}

            <form
              onSubmit={handleSubmit(submitAlterarDadosConta)}
              className={loading ? utilClasses.controlLoading : ''}
              style={{ padding: !isMobile ? '1rem' : '10px' }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={modal ? 12 : 6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        name="xNome"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            inputRef={refInputNome}
                            readOnly={loading || block}
                            allowSubmit={false}
                            id="nome"
                            label="Nome Completo"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.xNome
                                ? errors.xNome.message
                                : undefined
                            }
                            error={Boolean(errors.xNome && errors.xNome.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="fantasia"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            readOnly={loading || block}
                            allowSubmit={false}
                            label="Nome Fantasia"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              touchedFields.fantasia && errors.fantasia
                                ? errors.fantasia.message
                                : undefined
                            }
                            error={Boolean(errors.fantasia && errors.fantasia.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            readOnly={loading || block}
                            tipo="EMAIL"
                            allowSubmit={false}
                            label="E-mail"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.email
                                ? errors.email.message
                                : undefined
                            }
                            error={Boolean(errors.email && errors.email.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="telefone"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            readOnly={loading || block}
                            tipo="TELEFONE"
                            allowSubmit={false}
                            label="Telefone/Celular"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.telefone
                                ? errors.telefone.message
                                : undefined
                            }
                            error={Boolean(errors.telefone && errors.telefone.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="cnae"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            readOnly={loading || block}
                            allowSubmit={false}
                            label="CNAE"
                            fullWidth
                            autoComplete={'off'}
                            InputLabelProps={{ shrink: true }}
                            helperText={
                              touchedFields.cnae && errors.cnae
                                ? errors.cnae.message
                                : undefined
                            }
                            error={Boolean(errors.cnae && errors.cnae.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="crt"
                        control={control}
                        render={({ field }) => (
                          <SelectSaurus
                            conteudo={CRTMock}
                            disabled={loading || block}
                            allowSubmit={false}
                            label="CRT"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              touchedFields.crt && errors.crt
                                ? errors.crt.message
                                : undefined
                            }
                            error={Boolean(errors.crt && errors.crt.message)}
                            {...field}
                            onChange={(event) => {
                              if (event) {
                                const item = CRTMock.filter(
                                  (item) => item.Key === event.target.value,
                                )[0];
                                if (item) {
                                  setValue('crt', item.Key);
                                }
                              }
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="segmentoId"
                        control={control}
                        render={({ field }) => (
                          <AutocompleteSegmento
                            disabled={loading || block}
                            allowSubmit={false}
                            fullWidth
                            aberto={aberto}
                            label="Segmento"
                            helperText={
                              errors.segmentoId
                                ? errors.segmentoId.message
                                : undefined
                            }
                            error={Boolean(errors.segmentoId && errors.segmentoId.message)}
                            {...field}
                            onChange={(v) => {
                              if (v.value === '') {
                                setValue('segmentoId', '')
                                return
                              }
                              setValue('segmentoId', v.value.id)
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="informacao"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            readOnly={loading || block}
                            allowSubmit={false}
                            label="Informação"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              touchedFields.informacao && errors.informacao
                                ? errors.informacao.message
                                : undefined
                            }
                            error={Boolean(errors.informacao && errors.informacao.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    {/* {!esconderAbertura && <Grid item xs={12}>
                      <Controller
                        name="dataAberturaEmpresa"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            readOnly={loading || block}
                            allowSubmit={false}
                            tipo='DATA'
                            label="Data de Abertura da Empresa"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.dataAberturaEmpresa
                                ? errors.dataAberturaEmpresa.message
                                : undefined
                            }
                            error={Boolean(errors.dataAberturaEmpresa && errors.dataAberturaEmpresa.message)}
                            {...field}
                            value={!isEmpty(getValues('dataAberturaEmpresa')) ? getValues('dataAberturaEmpresa').split('T')[0] : ''}
                          />
                        )}
                      />
                    </Grid>} */}
                    {mostrarLatLong && <Grid container className={classes.tipoContainer}>
                      <Grid item xs={12} md={4}>
                        <FormControlLabel
                          label="Comercial"
                          checked={tipoInfo.isComercial}
                          onClick={() => {
                            if (block) return
                            setTipoInfo(prev => {
                              return {
                                ...prev,
                                isComercial: !prev.isComercial,
                              }
                            })
                          }}
                          control={<CheckboxDefault />}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControlLabel
                          label="Jurídico"
                          checked={tipoInfo.isJuridico}
                          onClick={() => {
                            if (block) return

                            setTipoInfo(prev => {
                              return {
                                ...prev,
                                isJuridico: !prev.isJuridico,
                              }
                            })
                          }}
                          control={<CheckboxDefault />}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControlLabel
                          label="Financeiro"
                          checked={tipoInfo.isFinanceiro}
                          onClick={() => {
                            if (block) return
                            setTipoInfo(prev => {
                              return {
                                ...prev,
                                isFinanceiro: !prev.isFinanceiro,
                              }
                            })
                          }}
                          control={<CheckboxDefault />}
                        />
                      </Grid>
                    </Grid>}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={modal ? 12 : 6}>
                  <Grid container spacing={2} className={classes.enderecoContainer}>
                    <Grid item xs={12} className={classes.enderecoTitle}>
                      <Typography variant='h5' color='textSecondary'>Endereço</Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Controller
                        name="cep"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            readOnly={loading || block}
                            allowSubmit={false}
                            tipo="CEP"
                            label="CEP"
                            placeholder='Ex: 99999-999'
                            searchable
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.cep
                                ? errors.cep.message
                                : undefined
                            }
                            error={Boolean(errors.cep && errors.cep.message)}
                            {...field}
                            onSearch={async (value: string) => {
                              try {
                                let res = await consultaCEP(value);

                                setValue('cep', res.CEP)
                                setValue('codMunicipio', Number(res.CMun))
                                setValue('municipio', res.Municipio)
                                setValue('logradouro', res.Logradouro)
                                setValue('bairro', res.Bairro)
                                setValue('uf', res.UF)

                                refInputNumero.current?.focus();
                              }
                              catch (err: any) {
                                showToast('error', err.message)
                              }
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Controller
                        name="logradouro"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            readOnly={loading || block}
                            placeholder='Ex: Rua Ipiranga'
                            allowSubmit={false}
                            label="Endereço"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.logradouro
                                ? errors.logradouro.message
                                : undefined
                            }
                            error={Boolean(errors.logradouro && errors.logradouro.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="numero"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            readOnly={loading || block}
                            allowSubmit={false}
                            inputRef={refInputNumero}
                            tipo="TEXTO"
                            label="Número"
                            placeholder='Ex: 10'
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.numero
                                ? errors.numero.message
                                : undefined
                            }
                            error={Boolean(errors.numero && errors.numero.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Controller
                        name="complemento"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            readOnly={loading || block}
                            allowSubmit={false}
                            label="Complemento"
                            placeholder='Bloco 3, apto 05'
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              touchedFields.complemento && errors.complemento
                                ? errors.complemento.message
                                : undefined
                            }
                            error={Boolean(errors.complemento && errors.complemento.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Controller
                        name="bairro"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            readOnly={loading || block}
                            allowSubmit={false}
                            label="Bairro"
                            placeholder='Ex: Tatuapé'
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.bairro
                                ? errors.bairro.message
                                : undefined
                            }
                            error={Boolean(errors.bairro && errors.bairro.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Controller
                        name="municipio"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            readOnly={loading || block}
                            allowSubmit={false}
                            tipo="TEXTO"
                            label="Município"
                            placeholder='Ex: São Paulo'
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.municipio
                                ? errors.municipio.message
                                : undefined
                            }
                            error={Boolean(errors.municipio && errors.municipio.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Controller
                        name="codMunicipio"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            readOnly={loading || block}
                            allowSubmit={false}
                            tipo="NUMERO"
                            label="Cód. Município"
                            placeholder='Ex: 15654'
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.codMunicipio
                                ? errors.codMunicipio.message
                                : undefined
                            }
                            error={Boolean(errors.codMunicipio && errors.codMunicipio.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Controller
                        name="uf"
                        control={control}
                        render={({ field }) => (
                          <SelectSaurus
                            conteudo={UFMock}
                            disabled={loading || block}
                            allowSubmit={false}
                            label="UF"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.uf
                                ? errors.uf.message
                                : undefined
                            }
                            error={Boolean(errors.uf && errors.uf.message)}
                            {...field}
                            value={UFMock.find(uf => uf.Value === getValues('uf'))?.Key}
                            onChange={(event) => {
                              if (event) {
                                const item = UFMock.filter(
                                  (item) => item.Key === event.target.value,
                                )[0];
                                if (item) {
                                  setValue('uf', item.Value);
                                }
                              }
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Controller
                        name="ie"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            readOnly={loading || block}
                            allowSubmit={false}
                            tipo="NUMERO"
                            label="Inscrição Estadual"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              touchedFields.ie && errors.ie
                                ? errors.ie.message
                                : undefined
                            }
                            error={Boolean(errors.ie && errors.ie.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Controller
                        name="im"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            readOnly={loading || block}
                            allowSubmit={false}
                            tipo="NUMERO"
                            label="Inscrição Municipal"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              touchedFields.im && errors.im
                                ? errors.im.message
                                : undefined
                            }
                            error={Boolean(errors.im && errors.im.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Controller
                        name="obs"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            readOnly={loading || block}
                            allowSubmit={false}
                            label="Observação"
                            fullWidth
                            autoComplete={'off'}
                            InputLabelProps={{ shrink: true }}
                            helperText={
                              touchedFields.obs && errors.obs
                                ? errors.obs.message
                                : undefined
                            }
                            error={Boolean(errors.obs && errors.obs.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    {mostrarLatLong && <>
                      <Grid item xs={6} md={4}>
                        <Controller
                          name="latitude"
                          control={control}
                          render={({ field }) => (
                            <TextFieldSaurus
                              readOnly
                              allowSubmit={false}
                              label="Latitude"
                              tipo='DECIMAL'
                              casasDecimais={8}
                              fullWidth
                              autoComplete={'off'}
                              InputLabelProps={{ shrink: true }}
                              helperText={
                                touchedFields.latitude && errors.latitude
                                  ? errors.latitude.message
                                  : undefined
                              }
                              error={Boolean(errors.latitude && errors.latitude.message)}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Controller
                          name="longitude"
                          control={control}
                          render={({ field }) => (
                            <TextFieldSaurus
                              readOnly
                              allowSubmit={false}
                              label="Longitude"
                              tipo='DECIMAL'
                              casasDecimais={8}
                              fullWidth
                              autoComplete={'off'}
                              InputLabelProps={{ shrink: true }}
                              helperText={
                                touchedFields.longitude && errors.longitude
                                  ? errors.longitude.message
                                  : undefined
                              }
                              error={Boolean(errors.longitude && errors.longitude.message)}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Button
                          variant='outlined'
                          color='primary'
                          fullWidth
                          href={`https://maps.google.com/?q=${getValues('latitude')},${getValues('longitude')}`}
                          target='_blank'
                          style={{ height: '100%' }}
                        >
                          <MapaIcon tipo='BUTTON' />
                          Abrir Mapa
                        </Button>
                      </Grid>
                    </>}
                    {!mostrarLatLong && <Grid container className={classes.tipoContainer}>
                      <Grid item xs={12} md={4}>
                        <FormControlLabel
                          label="Comercial"
                          checked={tipoInfo.isComercial}
                          onClick={() => {
                            if (block) return
                            setTipoInfo(prev => {
                              return {
                                ...prev,
                                isComercial: !prev.isComercial,
                              }
                            })
                          }}
                          control={<CheckboxDefault />}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControlLabel
                          label="Jurídico"
                          checked={tipoInfo.isJuridico}
                          onClick={() => {
                            if (block) return

                            setTipoInfo(prev => {
                              return {
                                ...prev,
                                isJuridico: !prev.isJuridico,
                              }
                            })
                          }}
                          control={<CheckboxDefault />}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControlLabel
                          label="Financeiro"
                          checked={tipoInfo.isFinanceiro}
                          onClick={() => {
                            if (block) return
                            setTipoInfo(prev => {
                              return {
                                ...prev,
                                isFinanceiro: !prev.isFinanceiro,
                              }
                            })
                          }}
                          control={<CheckboxDefault />}
                        />
                      </Grid>
                    </Grid>}
                  </Grid>
                </Grid>
              </Grid>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
