import { useMemo, useState } from 'react';
import { ClientesHeader } from './components/clientes-header/clientes-header';
import { ClientesList } from './components/clientes-list/clientes-list';
import { ClientesListSearchProps } from './components/clientes-list/clientes-list-search-props';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { useParams } from 'react-router-dom';
import { ClientesEditar } from './components/clientes-editar/clientes-editar';
import { EnumSituacaoPessoa } from 'model/enums/enum-situacao-pessoa';

interface paramProps {
  parametro: string
}

export const ClientesPage = () => {
  const classes = useDefaultCadastroStyles();

  const [openPesquisa, setOpenPesquisa] = useState(false);
  const [searchProps, setSearchProps] = useState<ClientesListSearchProps>({
    termo: '',
    documento: '',
    dominio: '',
    revenda: '',
    status: EnumSituacaoPessoa.Ativo,
    atualizarPesquisa: false
  });
  const { parametro } = useParams<paramProps>()

  const pageSwitch = useMemo(() => {
    switch (parametro) {
      case undefined:
        return <ClientesList searchProps={searchProps} />
      default:
        setOpenPesquisa(false)
        return (
          <div className={classes.defaultContainer}>
            <ClientesEditar id={parametro} />
          </div>
        )
    }
  }, [classes.defaultContainer, parametro, searchProps])

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <ClientesHeader
          titulo={parametro === undefined
            ? 'Clientes'
            : parametro === 'adicionar'
              ? 'Adicionar Cliente'
              : 'Editar Cliente'}
          openPesquisa={openPesquisa}
          setSearchProps={setSearchProps}
          setOpenPesquisa={setOpenPesquisa}
        />
      </div>
      {pageSwitch}
    </div>
  );
};

export default ClientesPage;
