import { Button } from "@material-ui/core"

interface ButtonIconProps {
    icon: JSX.Element;
    onClick: () => any;
    className?: any;
}

export const ButtonIcon= ({ icon, onClick, ...props }: ButtonIconProps) => {

    return (
        <>
            <Button onClick={onClick} {...props}>
                {icon}
            </Button>
        </>
    )

}

