import { guidEmpty } from "utils/guid-empty";
import { FaturasModel } from "../fatura/faturas-model";

export class NotaFiscalModel {
    constructor(
        public id: string = guidEmpty(),
        public valorServico: number = 0,
        public aliquotaDeducao: number = 0,
        public valorDeducao: number = 0,
        public aliquotaISS: number = 0,
        public valorISS: number = 0,
        public aliquotaPIS: number = 0,
        public valorPIS: number = 0,
        public aliquotaCOFINS: number = 0,
        public valorCOFINS: number = 0,
        public aliquotaINSS: number = 0,
        public valorINSS: number = 0,
        public aliquotaIR: number = 0,
        public valorIR: number = 0,
        public aliquotaCSLL: number = 0,
        public valorCSLL: number = 0,
        public pTotTrib: number = 0,
        public valorTrib: number = 0,
        public tipoTributacao: string = '',
        public codigoServico: string = '',
        public textoServico: string = '',
        public issRetido: boolean = false,
        public serieRPS: number = 0,
        public nroRPS: number = 0,
        public nroNF: number = 0,
        public descricao: string = '',
        public situacao: number = 0,
        public dataAutorizacao: string = '',
        public urlArqEnvioAutorizacao: string = '',
        public urlArqRetornoAutorizacao: string = '',
        public retornoAutorizacao: string = '',
        public dataCancelamento: string = '',
        public urlArqEnvioCancelamento: string = '',
        public urlArqRetornoCancelamento: string = '',
        public retornoCancelamento: string = '',
        public fatura: FaturasModel = new FaturasModel()
    ) { }
}