import { Button, Grid } from "@material-ui/core"
import { useCallback, useEffect, useRef, useState } from "react"
import { useToastSaurus } from "services/app"
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { useThemeQueries } from "views/theme"
import { DefaultFormRefs } from "views/components/form/utils"
import { useStyles } from '../../../clientes/components/clientes-editar/components/cliente-edit/cliente-edit-styles';
import classNames from "classnames"
import { SalvarIcon } from "views/components/icons"
import { FormPessoaTributo } from "views/components/form/master/pessoa/pessoa-tributo/form-pessoa-tributo"
import { PessoaTributoModel } from "model/api/gestao/pessoa/pessoa-tributo-model"
import { isEqual } from "lodash"
import { usePutAtualizarPessoaTributo } from "data/api/gestao/pessoa-tributo/put-pessoa-tributo"
import { useGetPessoaTributo } from "data/api/gestao/pessoa-tributo/get-pessoa-tributo"

interface Props {
    pessoaId: string;
}

export const AccordionConfiguracoesTributo = ({ pessoaId }: Props) => {

    const { theme } = useThemeQueries()
    const classes = useStyles()

    const {putAtualizarPessoaTributo, carregando: carregandoPut} = usePutAtualizarPessoaTributo()
    const {getPessoaTributo, carregando: carregandoGet} = useGetPessoaTributo()

    const carregando = carregandoGet || carregandoPut

    const [openAccordion, setOpenAccordion] = useState<boolean>(false)

    const { showToast } = useToastSaurus()

    const [model, setModel] = useState<PessoaTributoModel>(new PessoaTributoModel())

    const formRef = useRef<DefaultFormRefs<PessoaTributoModel>>(null)

    const preencherForm = useCallback((values: PessoaTributoModel) => {
        formRef.current?.fillForm(values)
    }, [])
    
    useEffect(() => {
        preencherForm(model)
    }, [model, preencherForm])

    const saveChanges = useCallback(async (model: PessoaTributoModel, beforeModel?: PessoaTributoModel) => {
        try {
            if (isEqual(model, beforeModel)) {
                showToast('none', 'Nenhuma informação alterada.')
                return
            }

            const res = await putAtualizarPessoaTributo(pessoaId, model)

            if(res.erro) throw res.erro

            showToast('success', 'Tributo Atualizado com succeso.')

            if(res.resultado)
                setModel(res.resultado?.data)

        } catch (e: any) {
            showToast('error', e.message)
        }

    }, [pessoaId, putAtualizarPessoaTributo, showToast])

    const pessoaTributoWrapper = useCallback(async () => {
        try{
            const res = await getPessoaTributo(pessoaId)

            if(res.erro) throw res.erro
            if(!res.resultado!) throw new Error('Erro. Tente Novamente.')

            setModel(res.resultado.data)
        }catch(e: any){
            showToast('error', e.message)
        }
    }, [getPessoaTributo, pessoaId, showToast])

    useEffect(() => {
        if(openAccordion)
            pessoaTributoWrapper()
    }, [openAccordion, pessoaTributoWrapper])

    return (
        <AccordionSaurus
            labelPrimary="Tributação"
            tipoExpand="bold"
            noPaperRoot={false}
            heightDivider={2}
            showDivider={openAccordion}
            colorDivider={theme.palette.primary.main}
            colorExpand={theme.palette.primary.main}
            expanded={openAccordion}
            onChange={() => setOpenAccordion(prev => !prev)}
        >
            <div className={classes.content}>
                <div
                    className={classNames(
                        carregando ? classes.contentFormsLoading : undefined,
                    )}
                >
                    <FormPessoaTributo
                        loading={carregando}
                        showLoading={carregando}
                        onSubmit={saveChanges}
                        ref={formRef}
                    />
                </div>
                <div className={classes.acoes} style={{paddingTop: 10}}>
                    <Grid container spacing={2} justifyContent='flex-end'>
                        <Grid item xs={12} md={3}>
                            <Button
                                disabled={carregando}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => formRef.current?.submitForm()}
                            >
                                <SalvarIcon tipo='BUTTON_PRIMARY' />
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </AccordionSaurus >
    )
}