import { VariaveisAmbiente } from 'config';
import { PessoaFormaPagamentoFormModel } from 'model/app/forms/pessoa-forma-pagamento/pessoa-forma-pagamento-form-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePostAdicionarPessoaPagamento() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postAdicionarPessoaPagamento = useCallback(
        (pessoaId: string, formaPagamento: PessoaFormaPagamentoFormModel) =>
            invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/pessoa/${pessoaId}/forma-pagamento`,
                data: formaPagamento,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postAdicionarPessoaPagamento,
    };
}
