import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TabSaurusContent, TabSaurusLabel, TabsSaurus } from "views/components/tabs/tabs-saurus";
import { IndicacaoEditarChat } from "./components/indicacoes-editar-chat/indicacao-editar-chat";
import { IndicacoesEditarDados } from "./components/indicacoes-editar-dados";
import { LocationProps } from "../../indicacoes-header/indicacoes-header";
import { EnumStatusIndicacao } from "model/enums/enum-status-indicacao";

interface IndicacoesEditarTabsProps {
    id: string;
    status: string;
}

interface IndLocationProps extends LocationProps {
    from: {
        status: EnumStatusIndicacao;
        pathname: string,
        page?: number;
    }
}

export const IndicacoesEditarTabs = ({
    id,
    status,
}: IndicacoesEditarTabsProps) => {

    //ROTAS
    const chatRoute = `/indicacoes/${status}/${id}/chat`;
    const dadosRoute = `/indicacoes/${status}/${id}/dados`;

    //AUX
    const { replace } = useHistory();
    const { pathname } = useLocation()
    const { state, search } = useLocation<IndLocationProps>()

    const retornaAba = useCallback((path: string) => {
        return path.indexOf(dadosRoute) > -1 ? 1 : 0;
    }, [dadosRoute]);

    const [abaSelecionada, setAbaSelecionada] = useState(
        retornaAba(pathname),
    );

    const tabChange = (index: number) => {
        if (index === 0) {
            replace(chatRoute, {
                from: {
                    pathname: state.from.pathname,
                    page: state.from.page
                }
            });
            return
        }
        else {
            replace(dadosRoute, {
                from: {
                    pathname: state.from.pathname,
                    page: state.from.page
                }
            })
            return
        }
    }

    useEffect(() => {
        const aba = retornaAba(pathname);
        setAbaSelecionada(aba);
    }, [pathname, retornaAba])

    const urlParams = new URLSearchParams(search);

    const indStatus = state?.from.status || Number(urlParams.get('indStatus')) || undefined as EnumStatusIndicacao | undefined;

        return(
            <>
                <TabsSaurus
                    selectedTabIndex={abaSelecionada}
                    onChange={tabChange}
                    tabsLabel={[
                        new TabSaurusLabel("Chat", 0),
                        new TabSaurusLabel("Dados", 1)
                    ]}
                    tabsContent={[
                        new TabSaurusContent(0, (
                            <IndicacaoEditarChat id={id} indStatus={indStatus} status={status}/>
                        )),
                        new TabSaurusContent(1, (
                            <IndicacoesEditarDados id={id} abaSelecionada={abaSelecionada} />
                        )),
                    ]}
                />
            </>
        );
}