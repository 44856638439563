import { guidEmpty } from "utils/guid-empty";

export class FilialModel {
    constructor(
        public id: string = guidEmpty(),
        public contratoId: string = guidEmpty(),
        public documento: string = '',
        public status: boolean = true,
        public dataAtivacao: string = '',
        public dataDesativacao: string = '' 
    ) { }
}