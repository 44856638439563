import { VariaveisAmbiente } from 'config';
import { NotaFiscalFormModel } from 'model/api/gestao/nota-fiscal/emitir-nota-fiscal-model';
import { NotaFiscalModel } from 'model/api/gestao/nota-fiscal/nota-fiscal-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePutNotaFiscal() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<NotaFiscalModel>();

    const putNotaFiscal = useCallback(
        (id: string, model: NotaFiscalFormModel) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/fatura/${id}/nota-fiscal`,
                method: 'PUT',
                data: model,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putNotaFiscal,
    };
}
