import { Button, Grid } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useToastSaurus } from 'services/app';
import { DefaultFormRefs } from 'views/components/form/utils';
import { SalvarEditarIcon, VoltarIcon } from 'views/components/icons';
import { ModuloModel } from 'model/api/gestao/sistemas/modulo-model';
import { useGetSistemasById, usePutAtualizarSistema } from 'data/api/gestao/sistemas';
import { NovoModuloCadastroFormModel } from 'model/app/forms/sistemas/novo-modulo-cadastro-form-model';
import { SistemaModel } from 'model/api/gestao/sistemas/sistema-model';
import { guidEmpty } from 'utils/guid-empty';
import { picker } from 'utils/picker';
import { FormNovoModuloCadastro } from 'views/components/form/master/sistemas/novo-modulo/form-novo-modulo';

interface DialogModuloProps {
    openned: boolean;
    closeDialog: () => void;
    sistemaId: string;
    modulo: ModuloModel;
}

export const DialogModulo = ({
    openned,
    closeDialog,
    sistemaId,
    modulo
}: DialogModuloProps) => {
    const { showToast } = useToastSaurus()

    const { getSistemasById, carregando: carregandoGet } = useGetSistemasById();
    const { putAtualizarSistema, carregando: carregandoPut } = usePutAtualizarSistema();

    const cadEditarModuloFormRef =
        useRef<DefaultFormRefs<NovoModuloCadastroFormModel>>(null);
    const refModuloModel = useRef<ModuloModel>(modulo)

    const [moduloForm, setModuloForm] = useState<NovoModuloCadastroFormModel>(new NovoModuloCadastroFormModel())

    useEffect(() => {
        refModuloModel.current = modulo
        setModuloForm(modulo)
    }, [modulo])

    useEffect(() => {
        cadEditarModuloFormRef.current?.fillForm(
            moduloForm
        );
    }, [moduloForm]);

    const carregando = carregandoGet || carregandoPut

    const getSistemaWrapper = useCallback(async () => {
        const res = await getSistemasById(sistemaId)

        if (res.erro) throw res.erro

        return res.resultado?.data as SistemaModel
    }, [getSistemasById, sistemaId])

    const saveNewOrChangeModulo = useCallback(
        async (model: SistemaModel) => {

            const validationError = model.modulos.find(item => (item.quantidadePadrao > item.quantidadeMaxima || item.quantidadePadrao < item.quantidadeMinima) && item.quantidadeMinima > -1)

            if (validationError) {
                throw new Error('Quantidade padrão está incorreta.')
            }

            const ret = await putAtualizarSistema(model);
            if (ret.erro) {
                throw ret.erro;
            }

            showToast(
                'success',
                'Novo módulo adicionado!',
                2000,
                'bottom-center',
                60,
            );

        },
        [putAtualizarSistema, showToast],
    );

    const handleSubmit = useCallback(
        async (model: NovoModuloCadastroFormModel) => {
            try {
                const moduloPicker = picker<ModuloModel>(
                    model,
                    refModuloModel.current
                );

                const sistema = await getSistemaWrapper()

                const diferentesModuloSistema = sistema.modulos.filter(modulo => modulo.id !== moduloPicker.id)

                sistema.modulos = [...diferentesModuloSistema, moduloPicker]

                await saveNewOrChangeModulo(sistema);

                if (moduloPicker.id === guidEmpty()) {
                    showToast('success', "Novo Módulo Adicionado!")
                } else {
                    showToast('success', "Módulo Atualizado!")
                }
                closeDialog()
            } catch (e: any) {
                showToast('error', e.message);
            }
        },
        [closeDialog, getSistemaWrapper, saveNewOrChangeModulo, showToast],
    )

    return (
        <DialogSaurus
            aberto={openned}
            titulo="Editar Módulo"
            tamanho='md'
        >
            <Grid container spacing={2}>
                <FormNovoModuloCadastro
                    ref={cadEditarModuloFormRef}
                    showLoading={false}
                    loading={carregando}
                    onSubmit={handleSubmit}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2} justifyContent='flex-end'>
                    <Grid item md={2} xs={12}>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={closeDialog}
                            fullWidth
                        >
                            <VoltarIcon tipo='BUTTON' />
                            Voltar
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Button
                            disabled={carregando}
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => cadEditarModuloFormRef.current?.submitForm()}
                        >
                            <SalvarEditarIcon tipo='BUTTON_PRIMARY' />
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </DialogSaurus>
    );
};
