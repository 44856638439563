import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const PlanosIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M4.16667 12.4999H12.5V35.4166H4.16667V12.4999ZM14.5833 39.5833H35.4167V8.33325H14.5833V39.5833ZM18.75 12.4999H31.25V35.4166H18.75V12.4999ZM37.5 12.4999H45.8333V35.4166H37.5V12.4999Z" />
            </DefaultIcon>
        </>
    );
};