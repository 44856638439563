import { Button, Grid } from "@material-ui/core";
import { useStyles } from "./cliente-edit-styles";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { SalvarEditarIcon } from "views/components/icons";
import { usePutAtualizarPessoa } from "data/api/gestao/pessoa";
import { DefaultFormRefs } from "views/components/form/utils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PessoaModel } from "model/api/gestao/pessoa/pessoa-model";
import { picker } from "utils/picker";
import classNames from "classnames";
import { useGetPessoaById } from './../../../../../../../../data/api/gestao/pessoa/get-pessoa-by-id';
import { FormPessoaEdicao } from "views/components/form/master/pessoa/pessoa-edicao/form-pessoa-edicao";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { AccordionClienteContratosList } from "../accordion-cliente-contratos-list/accordion-cliente-contratos-list";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus";
import { useThemeQueries } from "views/theme";
import { AccordionClienteInformacoesList } from "../accordion-cliente-informacoes-list/accordion-cliente-informacoes-list";
import { AccordionClienteSociosList } from "../accordion-cliente-socio-list/accordion-cliente-socio-list";
import { PessoaPostModel } from "model/api/gestao/pessoa/pessoa-post-model";
import { AccordionClienteFaturasList } from "../accordion-cliente-faturas-list/accordion-cliente-faturas-list";
import { useConsultaCnpj } from "data/api/wsmaster";
import { ConsultaCNPJModel } from "model";
import { DialogOpcoesConsultaCnpj } from "views/components/dialog/dialog-opcoes-consulta-cnpj/dialog-opcoes-consulta-cnpj";
import { CircularLoading } from "views/components";
import { LocalizacaoModel } from "model/api/gestao/localizacao/localizacao-model";
import { AccordionClienteTerminaisList } from "../accordion-cliente-terminais/accordion-cliente-terminais-list";
import { isEqual } from "lodash";
import { AccordionClienteAcessoAvisos } from "../accordion-cliente-acesso-avisos/accordion-cliente-acesso-avisos";
import { usePostPessoaLiberacaoProvisoria } from "data/api/gestao/pessoa/post-pessoa-liberacao-provisoria";
import { EnumTipoCobrancaRevenda } from "model/enums/enum-tipo-cobranca-revenda";
type Props = {
  id: string
}

export const ClienteEdit = ({ id }: Props) => {
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const { putAtualizarPessoa, carregando: carregandoPut } = usePutAtualizarPessoa();
  const { postLiberacaoProvisoria, carregando: carregandoLiberacao } = usePostPessoaLiberacaoProvisoria();
  const { getPessoaById, carregando: carregandoGet } = useGetPessoaById()
  const { consultarCNPJ, carregando: carregandoConsulta } = useConsultaCnpj()

  const [preenchendoTela, setPreechendoTela] = useState<boolean>(false)
  const [cnpjEmpresa, setCnpjEmpresa] = useState<ConsultaCNPJModel>(new ConsultaCNPJModel())
  const { tipoUsuario } = useSessaoAtual()
  const { theme } = useThemeQueries()
  const [openAccordion, setOpenAccordion] = useState<boolean>(true)
  const [isAbertodialog, setIsAbertoDialog] = useState<boolean>(false)
  const [localizacao, setLocalizacao] = useState<LocalizacaoModel | undefined>()

  const carregando = carregandoGet || carregandoPut || preenchendoTela || carregandoConsulta || carregandoLiberacao

  const cadClienteFormRef =
    useRef<DefaultFormRefs<PessoaPostModel>>(null);
  const refClienteModel = useRef<PessoaModel>(new PessoaModel())
  const [clienteForm, setClienteForm] = useState<PessoaPostModel>(new PessoaPostModel())
  const [atualizar, setAtualizar] = useState<boolean>(false)

  const recarregarForm = useCallback((model: PessoaPostModel) => {
    cadClienteFormRef.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(clienteForm)
  }, [clienteForm, recarregarForm]);

  const isFuncionario = tipoUsuario() === EnumTipoPessoas.Funcionario || tipoUsuario() === EnumTipoPessoas.FuncionarioSoftwareHouse

  const getContratoByIdWrapper = useCallback(async () => {
    const res = await getPessoaById(id)

    if (res.erro) throw res.erro

    const ret = res.resultado?.data as PessoaModel

    refClienteModel.current = ret

    const cliente = picker<PessoaPostModel>(ret, new PessoaPostModel())

    return cliente
  }, [getPessoaById, id])

  useEffect(() => {
    (async () => {
      try {
        setPreechendoTela(true)
        const cliente = await getContratoByIdWrapper()
        setClienteForm(cliente)
      } catch (e: any) {
        showToast('error', e.message)
      } finally {
        setPreechendoTela(false)
      }
    })()
  }, [getContratoByIdWrapper, showToast])

  const saveChangeCliente = useCallback(
    async (model: PessoaPostModel) => {
      if (model.tipo !== EnumTipoPessoas.Revendedor && model.melhorDia) {
        model.melhorDia = null
      }
      const ret = await putAtualizarPessoa(model);

      if (ret.erro) {
        throw ret.erro;
      }

      if (!ret.resultado) {
        throw new Error()
      }

      return ret.resultado?.data
    },
    [putAtualizarPessoa],
  );

  const consultarCNPJWrapper = useCallback(async () => {
    try {
      const empresa = await consultarCNPJ(clienteForm.cpfCnpj, localizacao)
      setCnpjEmpresa(empresa)

      setIsAbertoDialog(true)
    } catch (err: any) {
      showToast('error', err.message)
    }
  }, [clienteForm.cpfCnpj, consultarCNPJ, localizacao, showToast])

  const handleSubmit = useCallback(
    async (model: PessoaPostModel) => {
      try {
        if (isEqual(clienteForm, { ...model, status: clienteForm.status })) {
          throw new Error('Nenhuma informação alterada.')
        }

        const retPessoa = await saveChangeCliente(model);
        showToast('success', "Cliente Atualizado!")
        setClienteForm(retPessoa)
        return true
      } catch (e: any) {
        showToast('error', e.message);
        cadClienteFormRef.current?.resetForm()
        return false
      }
    },
    [clienteForm, saveChangeCliente, showToast],
  );

  useEffect(() => {
    if ('geolocation' in navigator) {
      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setLocalizacao(
            new LocalizacaoModel(position.coords.latitude, position.coords.longitude)
          );
        },
        function (error) {

        },
        options,
      );
    }
  }, []);

  const handleLiberacaoProvisoria = useCallback(async () => {
    try {
      const res = await postLiberacaoProvisoria(id);

      if (res.erro) throw res.erro

      if (res.statusCode === 200) {
        showToast('success', 'Liberação Provisória efetuada.')
        try {
          setPreechendoTela(true)
          const cliente = await getContratoByIdWrapper()
          setClienteForm(cliente)
        } catch (e: any) {
          showToast('error', e.message)
        } finally {
          setPreechendoTela(false)
        }
      }
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [getContratoByIdWrapper, id, postLiberacaoProvisoria, showToast])

  const formPessoaEdicao = useMemo(() =>
    <div>
      <AccordionSaurus
        labelPrimary={'Cliente'}
        tipoExpand="bold"
        showDivider={openAccordion}
        noPaperRoot={false}
        colorDivider={theme.palette.primary.main}
        colorExpand={theme.palette.primary.main}
        expanded={openAccordion}
        onChange={() => setOpenAccordion(prev => !prev)}
      >
        <div className={classes.content}>
          {carregandoConsulta && <CircularLoading tipo="FULLSIZED" />}
          <div
            className={classNames(
              classes.contentForms,
              carregando ? classes.contentFormsLoading : undefined,
            )}
          >
            <FormPessoaEdicao
              consultarCnpj={consultarCNPJWrapper}
              ref={cadClienteFormRef}
              showLoading={true}
              loading={carregando}
              onSubmit={handleSubmit}
              tipoRevenda={tipoUsuario() === EnumTipoPessoas.Revendedor}
              tipoFormPessoa={EnumTipoPessoas.Cliente}
              handleLiberacaoProvisoria={handleLiberacaoProvisoria}
              showClienteIdeal={tipoUsuario() !== EnumTipoPessoas.Funcionario}
            />
          </div>
          {tipoUsuario() !== EnumTipoPessoas.Funcionario && <div className={classes.acoes}>
            <Grid container spacing={2} justifyContent={'flex-end'}>
              <Grid item xs={12} md={4}>
                <Button
                  disabled={carregando}
                  onClick={() => {
                    cadClienteFormRef.current!.submitForm()
                  }
                  }
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </div>}
        </div>
      </AccordionSaurus>
      <DialogOpcoesConsultaCnpj
        setAtualizar={setAtualizar}
        openned={isAbertodialog}
        closeDialog={() => setIsAbertoDialog(false)}
        consultaCnpjModel={cnpjEmpresa}
        pessoaId={id}
      />
    </div>
    , [openAccordion, theme.palette.primary.main, classes.content, classes.contentForms, classes.contentFormsLoading, classes.acoes, carregandoConsulta, carregando, consultarCNPJWrapper, handleSubmit, tipoUsuario, isAbertodialog, cnpjEmpresa, id, handleLiberacaoProvisoria])

  const accordionClienteInformacoesList = useMemo(() => (
    <AccordionClienteInformacoesList atualizar={atualizar} setAtualizar={setAtualizar} id={id} tipoPessoa={clienteForm.tipo} />
  ), [atualizar, clienteForm.tipo, id])

  const accordionClienteFaturasList = useMemo(() => (
    <AccordionClienteFaturasList id={id} tipoCobrancaRevenda={clienteForm.revendaTipoCobranca || EnumTipoCobrancaRevenda.ClienteFinal} />
  ), [clienteForm.revendaTipoCobranca, id])

  const accordionClienteContratosList = useMemo(() => (
    <AccordionClienteContratosList cliente={clienteForm} pessoaId={id} revendaId={clienteForm.revendaId} />
  ), [clienteForm, id])

  const accordionClienteAcessoAvisos = useMemo(() => (
    <AccordionClienteAcessoAvisos carregando={carregandoPut} cliente={clienteForm} onSubmit={async (value) => await handleSubmit(value)} />
  ), [carregandoPut, clienteForm, handleSubmit])

  const accordionClienteSociosList = useMemo(() => (
    <AccordionClienteSociosList id={id} atualizar={atualizar} setAtualizar={setAtualizar} />
  ), [atualizar, id])

  const accordionClienteTerminal = useMemo(() => (
    <AccordionClienteTerminaisList id={id} />
  ), [id])

  // const accordionClienteIdeal = useMemo(() => (
  //   <AccordionClienteIdeal id={id} />
  // ), [id])

  // const accordionTaxa = useMemo(() => (
  //   <AccordionClienteTaxa id={id} tipoPessoa={clienteForm.tipo} />
  // ), [clienteForm.tipo, id])

  // const mostrarTaxas = [
  //   EnumTipoPessoas.SoftwareHouse,
  //   EnumTipoPessoas.FinanceiroSoftwareHouse,
  //   EnumTipoPessoas.Revendedor,
  // ]

  return (
    <Grid container spacing={2} className={classes.defaultContainer}>
      <Grid item xs={12}>
        {formPessoaEdicao}
      </Grid>
      <Grid item xs={12}>
        {accordionClienteInformacoesList}
      </Grid>
      <Grid item xs={12}>
        {accordionClienteContratosList}
      </Grid>
      {(!isFuncionario && tipoUsuario() !== EnumTipoPessoas.Representante) && (
        <Grid item xs={12}>
          {accordionClienteAcessoAvisos}
        </Grid>
      )}
      {!isFuncionario && <Grid item xs={12}>
        {accordionClienteSociosList}
      </Grid>}
      <Grid item xs={12}>
        {accordionClienteTerminal}
      </Grid>
      {(tipoUsuario() !== EnumTipoPessoas.GerenteComercial &&
        !isFuncionario
      ) && <Grid item xs={12}>
          {accordionClienteFaturasList}
        </Grid>}
      {/* {!isFuncionario &&
        <Grid item xs={12}>
          {accordionClienteIdeal}
        </Grid>
      } */}
      {/* {mostrarTaxas.includes(tipoUsuario()) && <Grid item xs={12}>
        {accordionTaxa}
      </Grid>} */}
    </Grid>
  );
};
