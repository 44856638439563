import { Button, Grid } from "@material-ui/core";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { useStyles } from '../../dialog-classificacoes-styles'
import { LixoIcon, SalvarEditarIcon, VoltarIcon } from "views/components/icons";
import { DefaultFormRefs } from "views/components/form/utils";
import { ClassificacaoModel } from "model/api/gestao/classificacao/classificacao-model";
import { useCallback, useEffect, useRef } from "react";
import { useToastSaurus } from "services/app";
import { usePutPessoaClassificacao } from "data/api/gestao/pessoa-classificacao/put-pessoa-classificacao";
import { FormClassificacao } from "views/components/form/master/classificacao/form-classificacao";
import { useStyles as useDeleteStyles } from './classificacoes-editar-styles'
import { useDeletePessoaClassificacao } from "data/api/gestao/pessoa-classificacao/delete-pessoa-classificacao";
import { useConfirm } from "material-ui-confirm";

interface Props {
    closeDialog: () => void;
    model: ClassificacaoModel;
}

export const ClassificacoesEditar = ({ closeDialog, model }: Props) => {

    const { acoes } = useModalStyles();
    const classes = useStyles();
    const removerClasses = useDeleteStyles();
    const { showToast } = useToastSaurus();
    const confirm = useConfirm();

    const { putPessoaClassificacao, carregando: carregandoPut } = usePutPessoaClassificacao();
    const { deletePessoaClassificacao, carregando: carregandoDelete } = useDeletePessoaClassificacao();

    const carregando = carregandoDelete || carregandoPut

    const editarClassificacoesRef = useRef<DefaultFormRefs<ClassificacaoModel>>(null);

    const preencherFormulario = useCallback((model: ClassificacaoModel) => {
        editarClassificacoesRef.current?.fillForm(model)
    }, [])

    const handleSubmit = useCallback(async (model: ClassificacaoModel) => {
        try {
            const res = await putPessoaClassificacao(model)

            if (res.erro) throw res.erro
            if (res.statusCode === 204) {
                showToast('success', 'Classificação cadastrada.')
                closeDialog();
            }
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [closeDialog, putPessoaClassificacao, showToast])

    const handleDelete = useCallback(async (id: string) => {
        try{
            const res = await deletePessoaClassificacao(id)
            
            if(res.erro){
                throw res.erro
            }

            if(res.statusCode === 204){
                showToast('success', 'Classificação Excluída')
                closeDialog();
            }
        }catch(e: any){
            showToast('error', e.message)
        }
    }, [closeDialog, deletePessoaClassificacao, showToast])

    const confirmDelete = useCallback(() => {
        confirm({
            title: 'Excluir Classificação',
            description: 'Tem certeza que deseja excluir esta classificação?',
            cancellationText: 'Cancelar',
            confirmationText: 'Excluir'
        }).then(() => handleDelete(model.id))
    }, [confirm, handleDelete, model.id])

    useEffect(() => {
        preencherFormulario(model)
    }, [model, preencherFormulario])

    return (
        <div className={acoes}>
            <Grid container spacing={2} className={classes.content}>
                <Grid item xs={12} md={12}>
                    <FormClassificacao
                        loading={carregando}
                        onSubmit={handleSubmit}
                        showLoading={carregando}
                        ref={editarClassificacoesRef}
                    />
                </Grid>
            </Grid>
            <div className={classes.divider} />
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={closeDialog}
                        fullWidth
                    >
                        <VoltarIcon tipo='BUTTON' />
                        Voltar
                    </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={confirmDelete}
                        fullWidth
                        className={removerClasses.buttonRemover}
                    >
                        <LixoIcon tipo='BUTTON' />
                        Excluir
                    </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button
                        disabled={carregando}
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={editarClassificacoesRef.current?.submitForm}
                    >
                        <SalvarEditarIcon tipo='BUTTON_PRIMARY' />
                        Salvar
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};