import { VariaveisAmbiente } from 'config';
import { PessoaPostModel } from 'model/api/gestao/pessoa/pessoa-post-model';
import { RevendedorPostModel } from 'model/api/gestao/revendedor/revendedor-post-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePostAdicionarPessoa() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postAdicionarPessoa = useCallback(
        (pessoa: PessoaPostModel | RevendedorPostModel) =>
            invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/Pessoa`,
                data: pessoa,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postAdicionarPessoa,
    };
}
