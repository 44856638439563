import { Grid, Tooltip, Typography } from '@material-ui/core';
import { SituacaoMock } from 'data/mocks/situacao-mock';
import { EnumSituacao } from 'model/enums/enum-situacao';
import { useCallback, useMemo } from 'react';
import { toDateString } from 'utils/to-date';import { useThemeQueries } from 'views/theme';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { CardFaturaPessoaProps } from './card-fatura-props';
import {useStyles} from './card-fatura-styles'
import { EnumTipoCobrancaRevenda } from 'model/enums/enum-tipo-cobranca-revenda';

export const CardFaturaPessoa = ({
  model,
  onClick,
  onCheck,
  selected,
  tipoCobrancaRevenda
}: CardFaturaPessoaProps) => {
  const classes = useDefaultCardStyles();
  const faturaClasses = useStyles();
  const { theme } = useThemeQueries()

  const isTipoRevenda = tipoCobrancaRevenda !== EnumTipoCobrancaRevenda.ClienteFinal

  const retornarCorStatus = useCallback((): string => {
    switch (model.situacao) {
      case EnumSituacao.Aberta:
        return theme.palette.info.main
      case EnumSituacao.Paga:
        return theme.palette.success.main
      case EnumSituacao.Atraso:
        return theme.palette.warning.main
      case EnumSituacao.Bonificada:
        return '#D7096C'
      case EnumSituacao.Cancelada:
        return theme.palette.error.main
    }
    return theme.palette.info.main
  }, [model.situacao, theme.palette.error.main, theme.palette.info.main, theme.palette.success.main, theme.palette.warning.main]);

  const descricao = SituacaoMock.filter(
    (x) => x.Key === model.situacao,
  )[0].Value;

  const valorELabel = useMemo(() => {
    if(isTipoRevenda){
      const valor = model.valorCusto || 0
      return {
        valor,
        label: 'Custo'
      }
    }
    const valor = model.valor || 0
    return {
      valor,
      label: 'Contratado'
    }
  }, [isTipoRevenda, model.valor, model.valorCusto])

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model.id);
        }}
        className={faturaClasses.card}
      >
        <Tooltip arrow title={descricao} placement="right">
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        <Grid className={`${classes.cardContent} ${faturaClasses.container}`} container>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="caption">
              <b>Nº: </b>
            </Typography>
            <Typography
              color="textPrimary"
              variant="caption"
            >
              {model.nossoNumero}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography color="textPrimary" variant="caption" style={{ marginRight: 5 }}>
              Vencimento
            </Typography>
            <Typography
              color="textPrimary"
              variant="caption"
            >
              <b>{toDateString(model.dataVencimento)}</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              <b>{model.descricao.length > 44 ? model.descricao.substring(0, 44) + '...' : model.descricao}</b>
            </Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography color="primary" variant="caption">
              Sistema
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {model.sistemaDescricao}
            </Typography>
          </Grid>
          {(!isTipoRevenda && model.situacao === EnumSituacao.Paga) && <Grid item xs={6} md={3}>
            <Typography color="primary" variant="caption">
              Valor Pago
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {model.valorPago.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
            </Typography>
          </Grid>}
          <Grid item xs={6} md={3}>
            <Typography color="primary" variant="caption">
              Emissão
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {toDateString(new Date(model.dataEmissao))}
            </Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography color="primary" variant="caption">
              {valorELabel.label}
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              <b>{valorELabel.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
            </Typography>
          </Grid>
        </Grid>
      </DefaultCard>
    </>
  );
};
