import { VariaveisAmbiente } from 'config';
import { FilialFormModel } from 'model/app/forms/filial/filial-form-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePostAdicionarFilial() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postAdicionarFilial = useCallback(
        (contratoId: string, filial: FilialFormModel) =>
            invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/contrato/${contratoId}/filial`,
                data: filial,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postAdicionarFilial,
    };
}
