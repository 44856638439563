import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useDeletePessoaComissao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const deletePessoaComissao = useCallback(
        (pessoaId: string, id: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Pessoa/${pessoaId}/comissao/${id}`,
                method: 'DELETE',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        deletePessoaComissao,
    };
}