import { Button } from '@material-ui/core';
import { FilialFormModel } from 'model/app/forms/filial/filial-form-model';
import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { validarCPFCNPJ } from 'utils/cpfcnpj-validate';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { DefaultFormProps, DefaultFormRefs } from 'views/components/form/utils';


export const FormContratoFilial = forwardRef<
    DefaultFormRefs<FilialFormModel>,
    DefaultFormProps<FilialFormModel>
>(({ loading, onSubmit, ...props }, ref) => {

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        reset,
        setError,
        clearErrors,
        setValue
    } = useForm<FilialFormModel>({
        criteriaMode: 'all',
        mode: 'onBlur' && 'onChange',
    });

    const submitEnviar = useCallback((filial: FilialFormModel) => {
        if (!filial.documento) return

        if (!validarCPFCNPJ(filial.documento)) {
            setError('documento', { type: 'error', message: 'CPF/CNPJ Inválido' })
            return
        }
        clearErrors('documento')
        onSubmit(filial)
    }, [clearErrors, onSubmit, setError])

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(submitEnviar)();
        },
        resetForm: () => {
            reset();
        },
        fillForm: (model: FilialFormModel) => {
            setValue('contratoId', model.contratoId)
            setValue('status', model.status)
            setValue('documento', model.documento)
        },
    }));


    return (
        <form style={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
            <Controller
                name="documento"
                control={control}
                render={({ field }) => (
                    <TextFieldSaurus
                        allowSubmit={false}
                        id="documento"
                        label="CPF/CNPJ da Empresa"
                        tipo='CNPJ_CPF'
                        fullWidth
                        autoComplete={'off'}
                        helperText={
                            touchedFields.documento && errors.documento
                                ? errors.documento.message
                                : undefined
                        }
                        error={Boolean(errors.documento && errors.documento.message)}
                        {...field}
                    />
                )}
            />
            <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
    )
});
