import { guidEmpty } from "utils/guid-empty";

export class FaturaCancelamentoModel {
  constructor(
    public id: string = guidEmpty(),
    public motivo: string = ''
  ) { }
}


