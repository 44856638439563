export class RelatorioSaldoUnitarioModel{
    constructor(
        public x: number = 0,
        public y: number = 0,
        public revenda: string = '',
    ) { }
}

export class RelatorioSaldoModel {
    constructor(
        public name: string = '',
        public data: RelatorioSaldoUnitarioModel[] = []
    ) { }
}