import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from 'data/api/base';

export function useGetRevendaDesempenhoByIdExcel() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getRevendaDesempenhoByIdExcel = useCallback(
        (anoMesApuracao: number, revendaId: string, query?: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/resultados/revendas-desempenho/apuracao-excel/${anoMesApuracao}/${revendaId}/mes?${query || ''}`,
                method: 'GET',
                enviarTokenUsuario: true,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/vnd.ms-excel",
                    "Content-Disposition": "attachment; filename=Comissoes.xls; filename*=UTF-8''Comissoes.xls"
                },
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getRevendaDesempenhoByIdExcel,
    };
}