import { Box, Button, Tooltip, Typography } from "@material-ui/core";
import { useGetPessoaClienteIdeal } from "data/api/gestao/cliente-ideal/get-pessoa-cliente-ideal";
import { CircularLoading } from "../utils";
import { useStyles } from "./cliente-ideal-styles";
import { useCallback, useEffect, useState } from "react";
import { PessoaClienteGetModel, PessoaClienteModel } from "model/api/gestao/cliente-ideal/pessoa-cliente-model";
import { EnumStatusClienteIdeal } from "model/enums/enum-status-cliente-ideal";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { DialogPerguntas } from "../dialog/dialog-perguntas/dialog-perguntas";
import { toDecimalString } from "utils/to-decimal";
import { useGetClienteIdeal } from "data/api/gestao/cliente-ideal/get-cliente-ideal";
import { podeResponderPerguntasInternas } from "./data/clienteIdealAccessData";
import { DialogInfoRespostas } from "../dialog/dialog-info-respostas/dialog-info-respostas";
import { ClienteIdealPerguntaModel } from "model/api/gestao/cliente-ideal/cliente-ideal-pergunta-model";
import { OlhoAbertoIcon } from "../icons";

interface ClienteIdealProps {
    pessoaId: string;
}

export const ClienteIdeal = ({
    pessoaId
}: ClienteIdealProps) => {
    const { tipoUsuario } = useSessaoAtual()
    const { showToast } = useToastSaurus();


    const [open, setOpen] = useState<boolean>(false);

    const retornaStatus = useCallback((pontos: number, maxPontos: number, respostas: PessoaClienteModel[]) => {
        if (respostas.length === 0) return EnumStatusClienteIdeal.NAO_AVALIADO;

        if ((pontos / maxPontos) * 100 < 40) {
            return EnumStatusClienteIdeal.NAO_IDEAL
        }
        if ((pontos / maxPontos) * 100 < 80) {
            return EnumStatusClienteIdeal.INTERMEDIARIO
        }
        return EnumStatusClienteIdeal.IDEAL;
    }, [])

    const { getClienteIdeal, carregando: carregandoClienteIdeal } = useGetClienteIdeal();
    const [model, setModel] = useState<PessoaClienteGetModel>(new PessoaClienteGetModel());
    const [pendenciasValue, setPendenciasValue] = useState<number>(0)
    const classes = useStyles({
        status: retornaStatus(model.pontos, model.maxPontos, model.respostas)
    });

    const [perguntas, setPerguntas] = useState<ClienteIdealPerguntaModel[]>([])

    const { getPessoaClienteIdeal, carregando: carregandoPessoaClienteIdeal } = useGetPessoaClienteIdeal();

    const carregando = carregandoClienteIdeal || carregandoPessoaClienteIdeal

    const getPessoaClienteIdealWrapper = useCallback(async () => {
        try {
            // Get Perguntas Cliente Ideal
            const resClienteIdeal = await getClienteIdeal()
            if (resClienteIdeal.erro) throw resClienteIdeal.erro
            if (!resClienteIdeal.resultado) return

            const res = await getPessoaClienteIdeal(pessoaId);
            if (res.erro) throw res.erro
            if (!res.resultado) return



            const currentUserType = tipoUsuario()
            let pendencia = 0
            const filteredPerguntas = resClienteIdeal.resultado.data.perguntas.filter((item) => {
                return item.ativo
            })
            // Percorre perguntas e verifica se esta presenta nas respostas e calcula respostas pendentes
            for (let i = 0; i < filteredPerguntas.length; i++) {

                const pergunta = filteredPerguntas[i]
                const findInRespostas = res.resultado.data.respostas.find((item) => {
                    return item.perguntaId === pergunta.id
                })

                if (!findInRespostas) {
                    if (pergunta.interna && podeResponderPerguntasInternas.includes(currentUserType)) {
                        pendencia = pendencia + 1
                    } else if (!pergunta.interna) {
                        pendencia = pendencia + 1
                    }
                }
            }
            setPerguntas(filteredPerguntas)
            setPendenciasValue(pendencia)
            setModel(res.resultado.data)
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getClienteIdeal, getPessoaClienteIdeal, pessoaId, showToast, tipoUsuario])

    useEffect(() => {
        if (!open) {
            getPessoaClienteIdealWrapper();
        }
    }, [getPessoaClienteIdealWrapper, open])

    const [openInfoRespostas, setOpenInfoRespostas] = useState(false)

    const handleCloseInfoRespostas = useCallback(() => {
        setOpenInfoRespostas(false)
    }, [])

    const handleOpenInfoRespostas = useCallback(() => {
        setOpenInfoRespostas(true)
    }, [])

    return (
        <Box className={classes.container}>
            {
                model.respostas.length > 0 ? (
                    <Box className={classes.infoRespostasButton}>
                        <Tooltip title="Clique para ver a análise detalhada das respostas" placement="top">
                            <Button aria-label="more" aria-haspopup="true" onClick={handleOpenInfoRespostas}>
                                <OlhoAbertoIcon tipo="BUTTON" />
                            </Button>
                        </Tooltip>
                    </Box>
                ) : null
            }

            {carregando && <CircularLoading tipo='FULLSIZED' />}
            <Box className={classes.roundBox}>
                {model.respostas.length === 0 ? (
                    <Typography align='center' className={classes.semPontuacao}>Sem Pontuação</Typography>
                ) : (
                    <>
                        <Typography align='center' className={classes.porcentagem}>{toDecimalString((model.pontos * 100) / model.maxPontos)}%</Typography>
                        <Typography align='center' className={classes.pontos}>{model.pontos} de {model.maxPontos} Pontos</Typography>

                    </>
                )}
            </Box>
            {
                pendenciasValue > 0 ? (
                    <Typography align="center" display="block">
                        {pendenciasValue} pendências
                    </Typography>
                ) : null
            }
            <Button variant='outlined' color='primary' fullWidth onClick={() => setOpen(true)}>
                {model.respostas.length === 0 ? 'Classificar' : 'Reclassificar'}
            </Button>
            {open && <DialogPerguntas
                pendencias={pendenciasValue}
                aberto={open}
                close={() => setOpen(false)}
                pessoaId={pessoaId}
                model={model}
            />}
            {openInfoRespostas && <DialogInfoRespostas
                close={handleCloseInfoRespostas}
                aberto={openInfoRespostas}
                respostas={model.respostas.map((item) => {
                    const interna = perguntas.find((perguntaItem) => {
                        return item.perguntaId === perguntaItem.id
                    })?.interna
                    
                    return {
                        ...item,
                        interna: interna
                    }
                })}
            />}
        </Box>
    )
}