import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetParametroSistemas() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getParametroSistemas = useCallback(
        (sistemaId: string, newPage: number, query?: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/sistema/${sistemaId}/parametro?page=${newPage}&${query}`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getParametroSistemas,
    };
}
