import { EnumGraficosDashboard } from "model/enums/enum-graficos-dashboard";
import { useMemo, useState } from "react";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus";
import { useThemeQueries } from "views/theme";
import Saldo from "./components/saldo/saldo";
import { RelatorioSearchPropsModel } from "model/api/gestao/relatorios/relatorio-search-props-model";
import { ResultadoRevendaModel } from "model/api/gestao/relatorios/resultado-revenda-model";
import { Grid, Typography } from "@material-ui/core";
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus";
import { GraficosRevendaMock, GraficosRevendaSoftwareHouseMock } from "data/mocks/graficos-mock";
import { contarMesesEntreCompetencias } from "utils/converter-competencia";
import { isEmpty } from "lodash";
import SaldoRevenda from "./components/saldo-revenda/saldo-revenda";
import PosicoesRevenda from "./components/posicoes-revenda/posicoes-revenda";
import CancelamentosRevenda from "./components/cancelamentos-revenda/cancelamentos-revenda";


type Props = {
    resultadoRevenda: ResultadoRevendaModel[];
    searchProps: RelatorioSearchPropsModel;
    isRevenda: boolean;
    carregando: boolean;
}

export const AccordionRevenda = ({ resultadoRevenda, searchProps, isRevenda, carregando, ...props }: Props) => {

    const { theme } = useThemeQueries();

    const [openAccordion, setOpenAccordion] = useState<boolean>(false)

    const [switchGrafico, setSwitchGrafico] = useState<EnumGraficosDashboard>(isRevenda ? EnumGraficosDashboard.NumerosRevenda : EnumGraficosDashboard.Saldo)

    const resultadoFiltrado = useMemo<ResultadoRevendaModel[]>(() => {
        return resultadoRevenda.filter(element => {
            let revendaIsEqual = true;
            let gerenteIsEqual = true;

            if (!isEmpty(searchProps.revendaId)) {
                revendaIsEqual = searchProps.revendaId === element.revendaId
            }
            if (!isEmpty(searchProps.gerenteId)) {
                gerenteIsEqual = searchProps.gerenteId === element.gerenteId
            }
            return (revendaIsEqual && gerenteIsEqual)
        })
    }, [resultadoRevenda, searchProps.gerenteId, searchProps.revendaId])


    const displayGrafico = useMemo(() => {
        switch (switchGrafico) {
            case EnumGraficosDashboard.Saldo:
                return (
                    <Saldo
                        resultadoRevenda={resultadoFiltrado}
                        somenteAtivos={false}
                        meses={contarMesesEntreCompetencias(searchProps.anoMesInicial, searchProps.anoMesFinal)}
                    // quantidade={quantidade}
                    // setQuantidade={setQuantidade}
                    // setStacked={setStacked}
                    // stacked={stacked}
                    />
                )
            case EnumGraficosDashboard.SaldoAtivos:
                return (
                    <Saldo
                        resultadoRevenda={resultadoFiltrado}
                        somenteAtivos={true}
                        meses={contarMesesEntreCompetencias(searchProps.anoMesInicial, searchProps.anoMesFinal)}
                    />
                )
            case EnumGraficosDashboard.NumerosRevenda:
                return (
                    <SaldoRevenda
                        resultadoRevenda={resultadoFiltrado}
                        meses={contarMesesEntreCompetencias(searchProps.anoMesInicial, searchProps.anoMesFinal)}
                    />
                )
            case EnumGraficosDashboard.PosicaoRevenda:
                return (
                    <PosicoesRevenda
                        meses={contarMesesEntreCompetencias(searchProps.anoMesInicial, searchProps.anoMesFinal)}
                        resultadoRevenda={resultadoFiltrado}
                    />
                )
            case EnumGraficosDashboard.NumerosRevendaInativos:
                return (
                    <CancelamentosRevenda
                        meses={contarMesesEntreCompetencias(searchProps.anoMesInicial, searchProps.anoMesFinal)}
                        resultadoRevenda={resultadoFiltrado}
                    />
                )
        }
    }, [resultadoFiltrado, searchProps.anoMesFinal, searchProps.anoMesInicial, switchGrafico])

    if(resultadoFiltrado.length < 1 && !carregando){
        return (
            <Typography variant='h6' align='center'>Não foram encontrados dados.</Typography>
        )
    }

    return (
        <>
            <AccordionSaurus
                labelPrimary="Relatórios"
                tipoExpand="bold"
                noPaperRoot={false}
                heightDivider={2}
                showDivider={openAccordion}
                colorDivider={theme.palette.primary.main}
                colorExpand={theme.palette.primary.main}
                expanded={openAccordion}
                onChange={() => setOpenAccordion(prev => !prev)}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {isRevenda ? (
                            <SelectSaurus
                                label='Relatório'
                                conteudo={GraficosRevendaMock}
                                value={switchGrafico}
                                onChange={(e: any) => {
                                    const item = GraficosRevendaMock.find(element => element.Key === e.target.value)
                                    if (item) {
                                        setSwitchGrafico(item.Key)
                                    }
                                }}
                            />
                        ) : (
                            <SelectSaurus
                                label='Relatório'
                                conteudo={GraficosRevendaSoftwareHouseMock}
                                value={switchGrafico}
                                onChange={(e: any) => {
                                    const item = GraficosRevendaSoftwareHouseMock.find(element => element.Key === e.target.value)
                                    if (item) {
                                        setSwitchGrafico(item.Key)
                                    }
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {openAccordion && displayGrafico}
                    </Grid>
                </Grid>
            </AccordionSaurus>
        </>
    );
};
