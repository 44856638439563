export enum EnumGraficosDashboard {
    Saldo = 1,
    SaldoAtivos = 2,
    CarteiraSaurus = 3,
    TotalValores = 4,
    TotalRegistros = 5,
    SaldoSaurus = 6,
    NumerosRevenda = 7,
    NumerosRevendaInativos = 8,
    PosicaoRevenda = 9,
    AtivosSaurus = 10,
    ReceitaGerente = 11
}