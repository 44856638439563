import { useCallback } from "react";
import { PrivatePageHeader } from "views/components/headers";
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { DesfazerIcon, FiltroIcon, MenuIcon, VoltarIcon } from "views/components/icons";
import { PesquisaClientes } from "./components/pesquisa-clientes/pesquisa-renvededor";
import { useMenuPrincipal } from "services/app";
import { useThemeQueries } from "views";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import { RevendedorListSearchProps } from "../revendedor-list/revendedor-list-search-props";

export interface RevendedorHeaderProps {
  openPesquisa: boolean;
  setOpenPesquisa: (state: boolean) => any;
  setSearchProps: (props: RevendedorListSearchProps) => any;
  titulo: string,
}

export interface LocationProps {
  from: {
    pathname: string,
    page?: number;
  }
}

export const RevendedorHeader = ({
  openPesquisa,
  setOpenPesquisa,
  setSearchProps,
  titulo
}: RevendedorHeaderProps) => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();
  const history = useHistory()
  const { state } = useLocation<LocationProps>()

  const leftArea = useCallback(
    () =>
      isMobile ? (titulo === 'Revendedores' ?
      (
        <ButtonPrivateHeader
          icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
          tooltip="Menu"
          onClick={abrirMenu}
        ></ButtonPrivateHeader>
      ) : <ButtonPrivateHeader
        icon={<VoltarIcon tipo="PRIVATE_HEADER"></VoltarIcon>}
        tooltip="Voltar"
        onClick={() => {
          if (!state?.from?.pathname || isEmpty(state.from.pathname)) {
            history.push('/revendedor')
            return
          }
          if (state.from.page) {
            history.push('/revendedor?page=' + state.from.page)
            return
          }
          if(state.from.pathname.includes('/notas-fiscais-rejeitadas')){
            history.push(state.from.pathname)
            return
          }
          history.goBack()
        }}
      ></ButtonPrivateHeader>) : (titulo !== 'Revendedores') ? (
        <ButtonPrivateHeader
          icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
          tooltip="Voltar"
          onClick={() => {
            if (!state?.from?.pathname || isEmpty(state.from.pathname)) {
              history.push('/revendedor')
              return
            }
            if(state.from.page){
              history.push('/revendedor?page=' + state.from.page)
              return
            }
            if(state.from.pathname.includes('/notas-fiscais-rejeitadas')){
              history.push(state.from.pathname)
              return
            }
            history.goBack()
          }}
        />
      ) : null,
    [isMobile, abrirMenu, titulo, state, history]
  );

  const rightArea = useCallback(
    () => titulo === 'Revendedores' ? (
      <ButtonPrivateHeader
        icon={
          openPesquisa ? (
            <DesfazerIcon tipo="PRIVATE_HEADER"></DesfazerIcon>
          ) : (
            <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>
          )
        }
        tooltip={!openPesquisa ? "Pesquisar" : "Voltar"}
        onClick={() => setOpenPesquisa(!openPesquisa)}
      ></ButtonPrivateHeader>
    ) : null,
    [titulo, openPesquisa, setOpenPesquisa]
  );

  const closePesquisaWrapper = useCallback(() => {
    setOpenPesquisa(false);
  }, [setOpenPesquisa]);

  const onPesquisar = useCallback(
    (props: RevendedorListSearchProps) => {
      setSearchProps(props);
    },
    [setSearchProps]
  );

  const pesquisa = useCallback(() => titulo === 'Revendedores' ? (
    <PesquisaClientes
      isOpened={!isMobile || openPesquisa!}
      onCloseSearch={closePesquisaWrapper}
      onPesquisar={onPesquisar}
    />
  ) : <></>, [closePesquisaWrapper, isMobile, onPesquisar, openPesquisa, titulo])

  return (
    <>
      <PrivatePageHeader
        title={titulo}
        leftArea={leftArea()}
        rightArea={isMobile ? rightArea() : pesquisa()}
        bottomArea={isMobile ? pesquisa() : undefined}
      />
    </>
  );
};
