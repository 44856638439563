import { IndicacoesInteracoesModel } from "model/api/gestao/indicacoes/indicacoes-interacoes-model";
import { DialogSaurus } from "../../dialog-saurus/dialog-saurus";
import { useThemeQueries } from "views/theme";
import { Button, Divider, Grid, Typography } from "@material-ui/core";
import { OkIcon } from "views/components/icons";
import { Controller, useForm } from "react-hook-form";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { IndicacoesInteracoesPostModel } from "model/api/gestao/indicacoes/indicacoes-interacoes-post-model";
import { useCallback, useState } from "react";
import { picker } from "utils/picker";
import { usePostIndicacoesInteracoes } from "data/api/gestao/indicacoes/post-indicacoes-interacoes";
import { CircularLoading } from "views/components/utils";
import { DialogFinalizacaoIndicacao } from "../../dialog-finalizacao-indicacao/dialog-finalizacao-indicacao";
import { useDialogMotivoConfirmacaoIndicacaoValidation } from "./dialog-motivo-confirmacao-indicacao-validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetIndicacoesInteracoes } from "data/api/gestao/indicacoes/get-indicacoes-interacoes";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { guidEmpty } from "utils/guid-empty";
import { toDateStringApi } from "utils/to-date";

interface Props {
    open: boolean;
    id: string;
    model: IndicacoesInteracoesModel;
    onClose: () => void;
    setDialogConclusao: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DialogMotivoConfirmacaoIndicacao = ({
    model,
    open,
    id,
    setDialogConclusao,
    onClose,
}: Props) => {

    //AUX
    const { theme } = useThemeQueries();
    const { showToast } = useToastSaurus();

    //PROVIDERS
    const { usuario } = useSessaoAtual()

    //CHAMADAS DA API
    const { postIndicacoesInteracoes, carregando: carregandoPost } = usePostIndicacoesInteracoes();
    const { getIndicacoesInteracoes, carregando: carregandoGet } = useGetIndicacoesInteracoes();

    //STATES
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const { dialogMotivoConfirmacaoIndicacoValidation } = useDialogMotivoConfirmacaoIndicacaoValidation();

    const carregando = carregandoPost || carregandoGet;

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<IndicacoesInteracoesPostModel>({
        resolver: yupResolver(dialogMotivoConfirmacaoIndicacoValidation),
    })

    const getInteracoesWrapper = useCallback(async () => {
        try {

            const res = await getIndicacoesInteracoes(id, 1, `&pageSize=0`)

            if (res.erro) {
                throw res.erro
            }

            if (!res.resultado) {
                return
            }

        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [getIndicacoesInteracoes, id, showToast])

    const salvar = useCallback(async (valor: IndicacoesInteracoesPostModel) => {
        const modelFormatado = picker<IndicacoesInteracoesPostModel>(valor, new IndicacoesInteracoesPostModel());

        modelFormatado.id = guidEmpty();
        modelFormatado.responsavelId = usuario?.Id ?? '';
        modelFormatado.tipoInteracao = model.tipoInteracao;
        modelFormatado.indicacaoId = id;
        modelFormatado.dataHora = toDateStringApi(new Date());

        const res = await postIndicacoesInteracoes(id, modelFormatado);

        if (res.erro)
            throw res.erro

        setOpenDialog(true);

        setTimeout(() => {
            setOpenDialog(false);
            onClose();
            setDialogConclusao(false)
        }, 5000);


        await getInteracoesWrapper();

        showToast('success', 'Status Atualizado!')

    }, [getInteracoesWrapper, id, model.tipoInteracao, onClose, postIndicacoesInteracoes, setDialogConclusao, showToast, usuario?.Id])

    return (
        <>
            <DialogSaurus
                aberto={open}
                titulo="Motivo"
                tamanho="xs"
                colorTitulo={theme.palette.primary.main}
                centralizarTitulo
                bottomArea={
                    <>
                        <div style={{
                            paddingTop: '8px',
                            paddingBottom: '16px',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                        }}>
                            <Divider variant="fullWidth" style={{ background: theme.palette.grey[400], marginBottom: '8px' }} />
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <form onSubmit={handleSubmit(async (ev) => await salvar(ev))}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            fullWidth
                                        >
                                            <OkIcon tipo="BUTTON_PRIMARY" />
                                            Salvar
                                        </Button>
                                    </form>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="outlined"
                                        onClick={() => onClose()}
                                        fullWidth
                                    >
                                        Voltar
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </>
                }
            >
                {carregando && <CircularLoading tipo="FULLSIZED" />}
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{
                        display: 'flex',
                        textAlign: 'center',
                    }}>
                        <Typography
                            variant="body2"
                            color="textPrimary"
                        >
                            {'Digite alguma observação que julgue necessário (Obrigatório)'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: '8px', paddingBottom: '16px' }}>
                        <Controller
                            control={control}
                            name="descricao"
                            render={({ field }) => (
                                <TextFieldSaurus
                                    label='Obs'
                                    fullWidth
                                    placeholder="Escreva sua observação aqui"
                                    {...field}
                                    allowSubmit
                                    tipo="TEXTO"
                                    multiline
                                    minRows={3}
                                    maxRows={6}
                                    autoComplete="off"
                                    helperText={
                                        errors.descricao
                                            ? errors.descricao.message
                                            : undefined
                                    }
                                    error={Boolean(errors.descricao && errors.descricao.message)}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </DialogSaurus>

            {openDialog && (
                <DialogFinalizacaoIndicacao
                    open={openDialog}
                />
            )}
        </>
    );
}